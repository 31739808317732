import _ from 'lodash'
import { UsersResponse } from '../../api/users/types'
import { UserType, UserTypeKey } from '../../authorization'

export function mapUsers(users: UsersResponse) {
  return _.map(users.items, user => {
    return {
      ..._.omit(user, 'organization'),
      userType: UserType[user.userType as UserTypeKey]
    }
  })
}
