import React from 'react'
import { AssignCompaniesDialog } from '../../../containers/Dialog/AssignCompanies/AssignCompaniesDialog'
import { CompanyPopupDialog } from '../../../containers/Dialog/CompanyPopupDialog'
import { ConfirmDialog } from '../../../containers/Dialog/ConfirmDialog'
import { CreatePortfolioDialog } from '../../../containers/Dialog/CreatePortfolio'
import { DeleteCompaniesDialog } from '../../../containers/Dialog/DeleteCompanies/DeleteCompaniesDialog'
import { DeleteDocumentDialog } from '../../../containers/Dialog/DeleteDocument/DeleteDocumentDialog'
import { DownloadPortfolioDialog } from '../../../containers/Dialog/DownloadPortfolio'
import { EnrichBeforeCampaign } from '../../../containers/Dialog/EnrichBeforeCampaign'
import { EnrichCompaniesDialog } from '../../../containers/Dialog/EnrichCompanies/EnrichCompaniesDialog'
import { EnrichCompaniesDialogData } from '../../../containers/Dialog/EnrichCompanies/types'
import { EnrichPersonDialog } from '../../../containers/Dialog/EnrichPerson'
import { InfoDialog } from '../../../containers/Dialog/InfoDialog'
import { LogoutCancelDialog } from '../../../containers/Dialog/LogoutCancelDialog'
import { MoveCompaniesDialog } from '../../../containers/Dialog/MoveCompanies/MoveCompaniesDialog'
import { PersonPopupDialog } from '../../../containers/Dialog/PersonPopupDialog'
import { ReleaseNotesDialog } from '../../../containers/Dialog/ReleaseNotesDialog'
import { WarningDialog } from '../../../containers/Dialog/WarningDialog'
import { DialogType } from '../../../features/dialog/types'
import { ChangePasswordDialog } from '../../../pages/Account/AccountTab/ChangePasswordDialog'
import { CurrencyDialog } from '../../../pages/Account/AccountTab/Currency/CurrencyDialog'
import { ChangeLanguageDialog } from '../../../pages/Account/AccountTab/Language/ChangeLanguageDialog'
import { GoalDetailDialog } from '../../../pages/Account/Goal/GoalDetailDialog'
import { ResumeQueryDialog as ResumePortfolioQueryDialog } from '../../../pages/Account/PortfolioManagement/ResumeQueryDialog'
import { PdfReportErrorLock } from '../../../pages/CompanyReport/Italy/PdfReportErrorLock'
import { ContactDialog } from '../../../pages/Contact/ContactDialog'
import { ResetPasswordDialog } from '../../../pages/Login/ResetPasswordDialog'
import { LoadQueryDialog as LoadOperationQueryDialog } from '../../../pages/Operations/LoadQuery'
import { SaveQueryDialog as SaveOperationQueryDialog } from '../../../pages/Operations/SaveQuery'
import { PortfolioAddNoteDialog } from '../../../pages/PortfolioManagement/AddNoteDialog'
import { PortfolioChangeNameDialog } from '../../../pages/PortfolioManagement/ChangeNameDialog'
import { DeletePortfolioDialog } from '../../../pages/PortfolioManagement/DeletePortfolioDialog'
import { GenerateSimilarityDialog } from '../../../pages/PortfolioManagement/GenerateSimilarityDialog'
import { LoadQueryDialog as LoadPortfolioQueryDialog } from '../../../pages/PortfolioManagement/LoadQueryDialog'
import { PortfolioErrorLockDialog } from '../../../pages/PortfolioManagement/PortfolioErrorLockDialog'
import { SaveQueryDialog as SavePortfolioQueryDialog } from '../../../pages/PortfolioManagement/SaveQueryDialog'
import { LoadQueryDialog as LoadProspectingQueryDialog } from '../../../pages/Prospecting/LoadQueryDialog'
import { SaveQueryDialog as SaveProspectingQueryDialog } from '../../../pages/Prospecting/SaveQueryDialog'
import {
  AddPortfolioDialog,
  DuplicateCompanyDialog,
  EditCompanyNoteDialog,
  DeleteCompanyDialog,
  MoveCompanyDialog
} from '../../../pages/SalesTool/AssignCampaign/Dialog'
import { ChangeEndDateDialog } from '../../../pages/SalesTool/Campaign/Dialog/ChangeEndDateDialog'
import { ChangeNameDialog } from '../../../pages/SalesTool/Campaign/Dialog/ChangeNameDialog'
import { ChangeNoteDialog } from '../../../pages/SalesTool/Campaign/Dialog/ChangeNoteDialog'
import { ChangeStartDateDialog } from '../../../pages/SalesTool/Campaign/Dialog/ChangeStartDateDialog'
import { StartCampaignDialog } from '../../../pages/SalesTool/Campaign/Dialog/StartCampaignDialog'
import { StopCampaignDialog } from '../../../pages/SalesTool/Campaign/Dialog/StopCampaignDialog'
import { DeleteCampaignDialog } from '../../../pages/SalesTool/CampaignArchive/Dialog/DeleteCampaign'
import { SalestoolDialog } from '../../../pages/SalesTool/Dialog'
import { AssignTaskDialog } from '../../../pages/SalesTool/SaleTasks/Dialog/AssignTask'
import {
  LoadPortfolioDialog as LoadTargetingPortfolioDialog,
  LoadQueryDialog as LoadTargetingQueryDialog,
  SaveQueryDialog as SaveTargetingQueryDialog
} from '../../../pages/Targeting/Dialogs'
import { LoadingDialog } from '../../Dialog/LoadingDialog'
import { DeleteGoalDialog } from '../../../containers/Dialog/DeleteGoal/DeleteGoalDialog'
import { EditGoalDialog } from '../../../containers/Dialog/EditGoal/EditGoalDialog'
import { DeleteProductDialog } from '../../../containers/Dialog/DeleteProduct/DeleteProductDialog'
import { EditProductDialog } from '../../../containers/Dialog/EditProduct/EditProductDialog'
import { DeleteTaskDialog } from '../../../containers/Dialog/DeleteTask/DeleteTaskDialog'
import { EditTaskDialog } from '../../../containers/Dialog/EditTask/EditTaskDialog'
import { SemanticClusterContactFormDialog } from '../../../containers/Dialog/SemanticClusterContactFormDialog'
import { DeleteCustomTrackDialog } from '../../../containers/Dialog/DeleteCustomTrack/DeleteCustomTrackDialog'
import { RenameCustomTrackDialog } from '../../../containers/Dialog/RenameCustomTrack/RenameCustomTrackDialog'
import { DeleteConfigurationDialog } from '../../../containers/Dialog/DeleteConfiguration/DeleteConfigurationDialog'
import { CreateConfigurationDialog } from '../../../containers/Dialog/CreateConfiguration/CreateConfigurationDialog'
import { DeleteAppointmentDialog } from '../../../containers/Dialog/DeleteAppointment/DeleteAppointmentDialog'
import { EnrichPortfolioDialogData } from '../../../containers/Dialog/EnrichPortfolio/types'
import { EnrichPortfolioDialog } from '../../../containers/Dialog/EnrichPortfolio/EnrichPortfolioDialog'
import { SearchInformationDialog } from '../../../containers/Dialog/SearchInformation/SearchInformationDialog'

type Props = {
  dialogType?: DialogType
  dialogData: any
  isLoading: boolean
  close: () => void
}

const DialogRendererComponent = ({ dialogType, close, dialogData, isLoading }: Props) => {
  if (!dialogType) return null
  switch (dialogType) {
    case 'DownloadPortfolio':
      return <DownloadPortfolioDialog close={close} downloadProposal={dialogData} />
    case 'CompanyPopup':
    case 'ReportCompanyPopup':
      return <CompanyPopupDialog close={close} data={dialogData} />
    case 'PersonPopup':
      return <PersonPopupDialog close={close} data={dialogData} />
    case 'AddPortfolioNote':
      return <PortfolioAddNoteDialog data={dialogData} />
    case 'ChangePortfolioName':
      return <PortfolioChangeNameDialog />
    case 'CreatePortfolio':
      return <CreatePortfolioDialog close={close} data={dialogData} />
    case 'DeletePortfolio':
      return <DeletePortfolioDialog />
    case 'LoadProspectingQuery':
      return <LoadProspectingQueryDialog canSaveQuery={dialogData.canSaveQuery} contextData={dialogData.contextData} />
    case 'SaveProspectingQuery':
      return <SaveProspectingQueryDialog close={close} contextData={dialogData.contextData} />
    case 'Contact':
      return <ContactDialog view={dialogData.state} close={close} isLoading={isLoading} />
    case 'ResetPasswordInfo':
      return <ResetPasswordDialog close={close} />
    case 'SalestoolError':
      return <SalestoolDialog close={close} isLoading={isLoading} data={dialogData} />
    case 'StartCampaign':
      return <StartCampaignDialog data={dialogData} />
    case 'StopCampaign':
      return <StopCampaignDialog data={dialogData} />
    case 'DeleteCampaign':
      return <DeleteCampaignDialog data={dialogData} close={close} />
    case 'ChangeCampaignName':
      return <ChangeNameDialog data={dialogData} />
    case 'ChangeCampaignEndDate':
      return <ChangeEndDateDialog data={dialogData} />
    case 'ChangeCampaignStartDate':
      return <ChangeStartDateDialog data={dialogData} />
    case 'ChangeCampaignNote':
    case 'AddCampaignNote':
      return <ChangeNoteDialog data={dialogData} />
    case 'AssignTasks':
      return <AssignTaskDialog close={close} data={dialogData} />
    case 'AddPortfolio':
      return <AddPortfolioDialog close={close} isLoading={isLoading} data={dialogData} />
    case 'EditCompanyNote':
      return <EditCompanyNoteDialog data={dialogData} />
    case 'DuplicateCampaignCompany':
      return <DuplicateCompanyDialog data={dialogData} close={close} />
    case 'DeleteCampaignCompany':
      return <DeleteCompanyDialog close={close} data={dialogData} />
    case 'MoveCampaignCompany':
      return <MoveCompanyDialog close={close} data={dialogData} />
    case 'GoalDetail':
      return <GoalDetailDialog close={close} goalId={dialogData.goalId} />
    case 'EnrichBeforeCampaign':
      return <EnrichBeforeCampaign close={close} data={dialogData} />
    case 'GenerateSimilarity':
      return <GenerateSimilarityDialog close={close} portfolioId={dialogData.portfolioId} />
    case 'EnrichCompanies':
      return <EnrichCompaniesDialog close={close} data={dialogData as EnrichCompaniesDialogData} />
    case 'EnrichPortfolio':
      return <EnrichPortfolioDialog close={close} data={dialogData as EnrichPortfolioDialogData} />
    case 'DeleteCompanies':
      return <DeleteCompaniesDialog close={close} data={dialogData} />
    case 'MoveCompanies':
      return <MoveCompaniesDialog close={close} data={dialogData} />
    case 'AssignCompanies':
      return <AssignCompaniesDialog close={close} data={dialogData} />
    case 'InfoDialog':
      return <InfoDialog close={close} data={dialogData} />
    case 'WarningDialog':
      return <WarningDialog close={close} data={dialogData} />
    case 'DeleteDocument':
      return <DeleteDocumentDialog close={close} data={dialogData} />
    case 'ChangePassword':
      return <ChangePasswordDialog close={close} />
    case 'SaveTargetingQuery':
      return <SaveTargetingQueryDialog close={close} data={dialogData} />
    case 'LoadTargetingQuery':
      return <LoadTargetingQueryDialog close={close} data={dialogData} />
    case 'LoadTargetingPortfolio':
      return <LoadTargetingPortfolioDialog close={close} data={dialogData} />
    case 'ChangeLanguage':
      return <ChangeLanguageDialog close={close} />
    case 'ChangeCurrency':
      return <CurrencyDialog close={close} />
    case 'LoadingDialog':
      return <LoadingDialog data={dialogData} close={close} />
    case 'LogoutCancel':
      return <LogoutCancelDialog close={close} />
    case 'PortfolioErrorLock':
      return <PortfolioErrorLockDialog close={close} />
    case 'PdfErrorLock':
      return <PdfReportErrorLock close={close} data={dialogData} />
    case 'EnrichPerson':
      return <EnrichPersonDialog close={close} data={dialogData} />
    case 'ConfirmDialog':
      return <ConfirmDialog close={close} data={dialogData} />
    case 'ReleaseNotes':
      return <ReleaseNotesDialog close={close} data={dialogData} />
    case 'SavePortfolioQuery':
      return <SavePortfolioQueryDialog close={close} data={dialogData} />
    case 'LoadPortfolioQuery':
      return <LoadPortfolioQueryDialog close={close} data={dialogData} />
    case 'SaveOperationQuery':
      return <SaveOperationQueryDialog close={close} data={dialogData} />
    case 'LoadOperationQuery':
      return <LoadOperationQueryDialog close={close} data={dialogData} />
    case 'ResumePortfolioQuery':
      return <ResumePortfolioQueryDialog close={close} data={dialogData} />
    case 'EditGoal':
      return <EditGoalDialog close={close} data={dialogData} />
    case 'DeleteGoal':
      return <DeleteGoalDialog close={close} data={dialogData} />
    case 'EditProduct':
      return <EditProductDialog close={close} data={dialogData} />
    case 'DeleteProduct':
      return <DeleteProductDialog close={close} data={dialogData} />
    case 'EditTask':
      return <EditTaskDialog close={close} data={dialogData} />
    case 'DeleteTask':
      return <DeleteTaskDialog close={close} data={dialogData} />
    case 'SemanticClusterContactForm':
      return <SemanticClusterContactFormDialog close={close} data={dialogData} />
    case 'DeleteCustomTrack':
      return <DeleteCustomTrackDialog close={close} data={dialogData} />
    case 'RenameCustomTrack':
      return <RenameCustomTrackDialog close={close} data={dialogData} />
    case 'DeleteConfiguration':
      return <DeleteConfigurationDialog close={close} data={dialogData} />
    case 'CreateConfiguration':
      return <CreateConfigurationDialog close={close} />
    case 'DeleteAppointment':
      return <DeleteAppointmentDialog close={close} data={dialogData} />
    case 'SearchInformation':
      return <SearchInformationDialog close={close} />
    default:
      return null
  }
}

// eslint-disable-next-line import/no-default-export
export default DialogRendererComponent
