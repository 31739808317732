import { CustomDimension } from '@jonkoops/matomo-tracker/lib/types'
import _ from 'lodash'
import GA from 'react-ga'
import { UserType } from '../../authorization'
import { ActionWithPayload } from '../../utils/types'
import { RootState } from '../types'
import {
  TrackingActions,
  TRACK_CTA_CLICK,
  TRACK_GROUP_ACTION,
  SEARCH_PROSPECTING_COMPANIES,
  TRACK_PAGE_VIEW,
  TRACK_SEMANTIC_SEARCH
} from './actions'
import { categoryFromFilterReducerPath, Matomo, toTrackableLocation } from './helpers'
import { ActionTrackingMap, EventAction, EventCategory } from './types'

const getDimensions = (state: any) => {
  const dimensions: CustomDimension[] = []
  if (state.user?.organization?.code) {
    dimensions.push({ id: 1, value: state.user.organization.code })
  }
  if (state.user?.user?.userType) {
    dimensions.push({ id: 2, value: UserType[state.user.user.userType] })
  }
  if (state.user?.organization?.name) {
    dimensions.push({ id: 3, value: state.user.organization.name })
  }
  if (state.user?.user?.userCode) {
    dimensions.push({ id: 4, value: state.user.user.userCode })
  }
  if (state.user?.user?.firstName && state.user?.user?.lastName) {
    const fullName = state.user.user.firstName.concat(' ', state.user.user.lastName)
    dimensions.push({ id: 5, value: fullName })
  }
  if (state.user?.user?.email) {
    dimensions.push({ id: 6, value: state.user.user.email })
  }

  return dimensions
}

export const trackSemanticSearch = (
  state: RootState,
  action: ReturnType<typeof TrackingActions.trackSemanticSearch>,
  trackGA: boolean,
  trackMatomo: boolean
) => {
  const { semantic, reducer } = action.payload
  if (!semantic.searchText) return

  const eventValue = `${semantic.searchText}[${semantic.type}]${
    _.join(_.sortBy(_.map(semantic.appliedSynonyms, s => `+${s.text}`)), ',') || ''
  }`

  if (trackGA) {
    GA.event({
      category: categoryFromFilterReducerPath(reducer),
      action: semantic.advanced ? EventAction.SemanticSearchAdvanced : EventAction.SemanticSearch,
      label: eventValue
    })
  }

  if (trackMatomo) {
    Matomo.tracker.trackEvent({
      category: categoryFromFilterReducerPath(reducer),
      action: semantic.advanced ? EventAction.SemanticSearchAdvanced : EventAction.SemanticSearch,
      name: eventValue,
      customDimensions: getDimensions(state)
    })
  }
}

export function trackPageView(
  state: RootState,
  action: ReturnType<typeof TrackingActions.trackPageView>,
  trackGA: boolean,
  trackMatomo: boolean
) {
  const { path } = action.payload
  const page = toTrackableLocation(path)
  if (trackGA) {
    GA.pageview(page)
  }
  if (trackMatomo) {
    Matomo.tracker.trackPageView({ href: page, customDimensions: getDimensions(state) })
  }
}

export function trackBasicSearch(
  state: RootState,
  action: ActionWithPayload<string, { freeText: string }>,
  trackGA: boolean,
  trackMatomo: boolean
) {
  if (trackGA) {
    GA.event({
      category: EventCategory.Search,
      action: EventAction.Search,
      label: action.payload!.freeText
    })
  }

  if (trackMatomo) {
    Matomo.tracker?.trackEvent({
      category: EventCategory.Search,
      action: EventAction.Search,
      name: action.payload!.freeText,
      customDimensions: getDimensions(state)
    })
  }
}

export function trackGroupAction(state: RootState, action: TrackingActions, trackGA: boolean, trackMatomo: boolean) {
  if (action.type !== TRACK_GROUP_ACTION) return

  if (trackGA) {
    GA.event({
      category: action.payload.source,
      action: EventAction.GroupAction,
      label: action.payload.type
    })
  }

  if (trackMatomo) {
    Matomo.tracker?.trackEvent({
      category: action.payload.source,
      action: EventAction.GroupAction,
      name: action.payload.type,
      customDimensions: getDimensions(state)
    })
  }
}

export function trackCTAClick(
  state: RootState,
  action: ReturnType<typeof TrackingActions.trackCTAClick>,
  trackGA: boolean,
  trackMatomo: boolean
) {
  if (action.type !== TRACK_CTA_CLICK) return

  if (trackGA) {
    GA.event({
      category: action.payload.source,
      action: action.payload.type
    })
  }

  if (trackMatomo) {
    Matomo.tracker?.trackEvent({
      category: action.payload.source,
      action: action.payload.type,
      customDimensions: getDimensions(state)
    })
  }
}

export const actionTracking: ActionTrackingMap = {
  [SEARCH_PROSPECTING_COMPANIES]: trackBasicSearch,
  [TRACK_GROUP_ACTION]: trackGroupAction,
  [TRACK_CTA_CLICK]: trackCTAClick,
  [TRACK_PAGE_VIEW]: trackPageView,
  [TRACK_SEMANTIC_SEARCH]: trackSemanticSearch
}
