import { Location, NavigateFunction } from 'react-router-dom'
import { ExtensionManager } from '../../api/config/types'
import { LoginResponse } from '../../api/login/types'
import { ReleaseNoteRequest } from '../../api/releaseNotes/types'
import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'
import { User } from '../sharedTypes'
import { Branch, Organization, Subscription } from './types'

export const TOKEN_LOADED = 'user/TOKEN_LOADED'
export const SET_TOKEN = 'user/SET_TOKEN'
export const SET_USER_DATA = 'user/SET_USER_DATA'
export const SET_USER = 'user/SET_USER'
export const SET_ORGANIZATION = 'user/SET_ORGANIZATION'
export const SET_HAS_PORTFOLIO = 'config/SET_HAS_PORTFOLIO'

export const LOGOUT = 'user/LOGOUT'
export const FORCE_LOGOUT = 'user/FORCE_LOGOUT'
export const CHANGE_LANGUAGE = 'config/CHANGE_LANGUAGE'
export const CHANGE_CURRENCY = 'config/CHANGE_CURRENCY'
export const SET_LANGUAGE = 'config/SET_LANGUAGE'
export const SET_CURRENCY = 'config/SET_CURRENCY'
export const SET_USER_LANGUAGE = 'config/SET_USER_LANGUAGE'
export const SET_USER_CURRENCY = 'config/SET_USER_CURRENCY'
export const REFRESH_TOKEN = 'config/REFRESH_TOKEN'
export const SET_AVAILABLE_REDIRECT = 'config/SET_AVAILABLE_REDIRECT'
export const SET_BLACKLISTED_FEATURES = 'config/SET_BLACKLISTED_FEATURES'
export const SET_HAS_PORTFOLIO_EXCLUSIONS = 'config/SET_HAS_PORTFOLIO_EXCLUSIONS'
export const SET_CUST_COMP_VISIBILITY_ENABLED = 'config/SET_CUST_COMP_VISIBILITY_ENABLED'
export const SET_CUST_COMP_CONFIGURATION_APPLIED = 'config/SET_CUST_COMP_CONFIGURATION_APPLIED'

export const DISPLAY_NEW_RELEASE_NOTES = 'user/FETCH_RELEASE_NOTES'

export const UserActions = {
  tokenLoaded: (data: LoginResponse, navigate: NavigateFunction, callback?: () => void, loginAs = false) =>
    createAction(TOKEN_LOADED, { data, navigate, callback, loginAs }),
  setToken: (token: string, claims: number, userId: string, organizationId: string) =>
    createAction(SET_TOKEN, { token, claims, userId, organizationId }),
  setUserData: (data: {
    hasPortfolio: boolean
    user: User
    originatorUser?: User
    organization: Organization
    instanceCode: string
    branch: Branch
    subscription: Subscription
    extensionsManager: ExtensionManager
  }) => createAction(SET_USER_DATA, { ...data }),
  setUser: (user: User) => createAction(SET_USER, { ...user }),
  setOrganization: (organization: Organization) => createAction(SET_ORGANIZATION, { ...organization }),
  setHasPortfolio: (hasPortfolio: boolean) => createAction(SET_HAS_PORTFOLIO, { hasPortfolio }),
  logout: (clientSideOnly: boolean) => createAction(LOGOUT, { clientSideOnly }),
  forceLogout: () => createAction(FORCE_LOGOUT),
  changeLanguage: (lang: string, reload: boolean, location: Location, navigate: NavigateFunction) =>
    createAction(CHANGE_LANGUAGE, { lang, reload, location, navigate }),
  changeCurrency: (currency: string, reload: boolean, location: Location, navigate: NavigateFunction) =>
    createAction(CHANGE_CURRENCY, { currency, reload, location, navigate }),
  setLanguage: (lang: string) => createAction(SET_LANGUAGE, { lang }),
  setCurrency: (currency: string) => createAction(SET_CURRENCY, { currency }),
  setUserLanguage: (lang: string) => createAction(SET_USER_LANGUAGE, { lang }),
  setUserCurrency: (currency: string) => createAction(SET_USER_CURRENCY, { currency }),
  refreshToken: () => createAction(REFRESH_TOKEN),
  setAvailableRedirects: (availableRedirects: string[]) => createAction(SET_AVAILABLE_REDIRECT, { availableRedirects }),
  setBlacklistedFeatures: (blacklistedFeatures: string[]) =>
    createAction(SET_BLACKLISTED_FEATURES, { blacklistedFeatures }),
  setHasPortfolioExclusions: (hasPortfolioExclusions: boolean) =>
    createAction(SET_HAS_PORTFOLIO_EXCLUSIONS, { hasPortfolioExclusions }),
  setCustCompVisibility: (custCompanyVisibilityAccountEnabled: boolean) =>
    createAction(SET_CUST_COMP_VISIBILITY_ENABLED, { custCompanyVisibilityAccountEnabled }),
  setCustCompConfigurationApplied: (customerCompanyConfigurationApplied: boolean) =>
    createAction(SET_CUST_COMP_CONFIGURATION_APPLIED, { customerCompanyConfigurationApplied }),
  displayNewReleaseNotes: (params: ReleaseNoteRequest) => createAction(DISPLAY_NEW_RELEASE_NOTES, params)
}

export type UserActions = ActionsUnion<typeof UserActions>
