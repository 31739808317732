/* eslint-disable import/no-default-export */
export default {
  DashboardTitle: 'Váš Dashboard',
  DashboardSubtitle: 'Pomocou týchto polí uvidíte všetky svoje údaje na prvý pohľad!',
  OrganizeBox: 'Usporiadať pole',
  ChangeBox: 'Zmeniť',
  RemoveBox: 'Vymazať pole',
  ChooseWhatDisplay: 'Vyberte, čo sa má zobraziť v tomto poli',
  PortfolioTitle: 'Spoločnosti',
  PortfolioSubtitle: 'Stručný prehľad vašich spoločností',
  PortfolioAndCampaignTitle: 'Spoločnosti a kampane',
  PortfolioAndCampaignSubtitle: 'Stručný prehľad vašich spoločností a kampaní',
  AnalysisTitle: 'Portfóliové dotazy, analýzy a prieskumy',
  AnalysisSubtitle: 'Ukážte svoje uložené portfóliové dotazy, analýzy alebo prieskumy',
  SuggestedProspects: 'Navrhovaný prospekt',
  CreatedOn: 'Dátum vytvorenia {{date}}',
  EmptyWidget: {
    SelectPortfolio: {
      BoxTitlePortfolio: 'Vyberte spoločnosti',
      BoxTitlePortfolioAndCampaign: 'Vyberte spoločnosti a kampane',
      Title: 'Portfólio',
      Description: 'Vyberte portfólio z ponuky "Správa portfólia"',
      SelectLabel: 'Vybrať portfólio',
      SelectPlaceholder: 'Kliknutím vyberte portfólio',
      Button: 'Vybrať',
      Banner: {
        Message: 'Ak si nechcete vybrať portfólio, môžete si ho vytvoriť v časti "Správa portfólia"',
        Button: 'Vytvoriť'
      }
    },
    SelectCampaign: {
      BoxTitle: 'Vyberte spoločnosti a kampane',
      Title: 'Kampane',
      Description: 'Vyberte kampaň z ponuky "Obchodné aktivity"',
      SelectLabel: 'Vybrať kampaň',
      SelectPlaceholder: 'Kliknutím vyberte kampaň',
      Button: 'Vybrať',
      Banner: {
        Message: 'Ak nechcete zvoliť kampaň, môžete ju vytvoriť v časti "Obchodné aktivity"',
        Button: 'Vytvoriť'
      }
    },
    SelectProspectingAnalysis: {
      BoxTitle: 'Vyberte portfóliové otázky, analýzy a prieskumy',
      Title: 'Vyhľadávanie',
      Description: 'Vyberte uložené vyhľadávanie',
      SelectLabel: 'Vybrať vyhľadávanie',
      SelectPlaceholder: 'Kliknutím vyberte vyhľadávanie',
      SelectPlaceholderNoQuery: 'Žiadny dopyt neuložený',
      Button: 'Vybrať',
      Banner: {
        Message: 'Ak nechcete zvoliť vyhľadávanie, môžete vytvoriť nové',
        Button: 'Vytvoriť'
      }
    },
    SelectTargetingAnalysis: {
      BoxTitle: 'Vyberte portfóliové otázky, analýzy a prieskumy',
      Title: 'Analýza',
      Description: 'Vyberte uloženú analýzu',
      SelectLabel: 'Vybrať analýzu',
      SelectPlaceholder: 'Kliknutím vyberte analýzu',
      SelectPlaceholderNoQuery: 'Žiadny dopyt neuložený',
      Button: 'Vybrať',
      Banner: {
        Message: 'Ak nechcete zvoliť analýzu, môžete vytvoriť novú',
        Button: 'Vytvoriť'
      }
    },
    SelectPortfolioAnalysis: {
      BoxTitle: 'Vyberte portfóliové otázky, analýzy a prieskumy',
      Title: 'Portfóliové dotazy',
      Description: 'Vyberte dotaz uloženého portfólia',
      SelectLabel: 'Vybrať dotaz na portfólio',
      SelectPlaceholder: 'Kliknutím vyberte dotaz portfólia',
      SelectPlaceholderNoQuery: 'Žiadny dopyt neuložený',
      Button: 'Vybrať',
      Banner: {
        Message: 'Ak nechcete vybrať dotaz portfólia, môžete vytvoriť nový',
        Button: 'Vytvoriť'
      }
    },
    UploadingPortfolio: {
      BoxTitle: 'Nahrávanie portfólia',
      Title: 'Nahrávanie portfólia',
      Description: 'V tomto poli uvidíte zoznam náhľadov',
      BannerMessage: 'Kliknutím na ikonu vpravo hore môžete obsah kedykoľvek zmeniť.'
    }
  },
  PortfolioWidget: {
    NoData: 'Údaje neexistujú.',
    NumberOfCompanies_0: '{{companiesCount}} Spoločnosť',
    NumberOfCompanies_1: '{{companiesCount}} Spoločnosti',
    NumberOfCompanies_2: '{{companiesCount}} Spoločností',
    Monitored: 'Monitorované',
    Actions: 'Akcie',
    Enrich: 'Obohatiť',
    EnrichTooltip: 'Vylepšite údaje týchto spoločností',
    Analyze: 'Analyzovať',
    AnalyzeTooltip: 'Vizualizujte údaje portfólia podľa hlavných premenných',
    Similarity: 'Podobnosť',
    SimilarityTooltip: 'Pre nájdenie nových potenciálnych zákazníkov použite algoritmus podobnosti'
  },
  ProspectingWidget: {
    NoData: 'Údaje neexistujú.',
    NumberOfCompanies_0: '{{companiesCount}} Spoločnosť',
    NumberOfCompanies_1: '{{companiesCount}} Spoločnosti',
    NumberOfCompanies_2: '{{companiesCount}} Spoločností',
    Monitored: 'Monitorovaný',
    MoreFilters_0: '{{filtersCount}} ďalší filter',
    MoreFilters_1: '{{filtersCount}} ďalšie filtre',
    MoreFilters_2: '{{filtersCount}} ďalších filtrov',
    ViewDetailsTooltip: 'Pozrite sa na filtre použité v tomto zozname kandidátov'
  },
  TargetingWidget: {
    NoData: 'Údaje neexistujú.',
    Companies_0: 'Spoločnosť',
    Companies_1: 'Spoločnosti',
    Companies_2: 'Spoločností'
  },
  CampaignWidget: {
    NoData: 'Údaje neexistujú.',
    NumberOfCompanies_0: '{{companiesCount}} Spoločnosť',
    NumberOfCompanies_1: '{{companiesCount}} Spoločnosti',
    NumberOfCompanies_2: '{{companiesCount}} Spoločností'
  },
  AppointmentsWidget: {
    Title: 'Vaše stretnutia',
    NoAppointments: 'Nemáte žiadne schôdzky',
    MoreEvents_0: '{{eventsCount}} ďalšie stretnutie',
    MoreEvents_1: '{{eventsCount}} ďalšie stretnutia',
    MoreEvents_2: '{{eventsCount}} ďalších stretnutí',
    Today: 'Dnes',
    Tomorrow: 'Zajtra'
  },
  LatestDocuments: {
    Title: 'Posledné dokumenty',
    NoDocuments:
      'V tomto poli uvidíte všetky svoje naposledy stiahnuté súbory. Vaše dokumenty budú v úložisku vo vašom profile.',
    Info: 'Tabuľka zobrazuje najnovšie stiahnuté dokumenty',
    Table: {
      Name: 'Názov',
      Type: 'Typ',
      CreationDate: 'Dátum vytvorenia',
      ExpirationDate: 'Dátum vypršania',
      DownloadExcel: 'Excel na stiahnutie',
      Campaign: 'Kampaň',
      Report: 'Report'
    }
  },
  PortfolioQueryWidget: {
    MoreFilters_0: '{{filtersCount}} ďalší filter',
    MoreFilters_1: '{{filtersCount}} ďalšie filtre',
    MoreFilters_2: '{{filtersCount}} ďalších filtrov',
    ViewDetailsTooltip: 'Podívejte se na filtry použité v tomto portfóliu'
  }
}
