import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type RouterContextType = {
  to?: string
  from?: string
}

export const RouterContext = createContext<RouterContextType>({})

export const RouterProvider = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation()
  const [route, setRoute] = useState<RouterContextType>({
    to: pathname
  })

  useEffect(() => {
    if (route.to !== pathname) {
      setRoute(prev => ({ to: pathname, from: prev.to }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
}
