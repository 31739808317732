import { Filter, SynonymItem } from '../../features/filters/types'
import { CompoundExpression } from '../../features/operations/types'
import { CompanyStatus, PortfolioCompanySelectionItem } from '../../features/portfolioCompany/types'
import { CompanyDetailsType, FieldType, MapPositionData, Pagination, UploadStatus, VisibilityType } from '../../types'
import { Dictionary } from '../../utils/types'
import { PortfolioCompanyCell } from '../dashboard/types'
import { SemanticSearchRequest } from '../filters/types'

export type PortfolioCompanyFieldConfig = {
  jsonPath: string
  fieldType: FieldType
  text: string
  visibilityType?: VisibilityType
}

export type PortfolioCompany = {
  margoId: string
  entityId: string
  companyName: string
  isEnriched: boolean
  createdAt: string
  companyUnitId: string
  cells: PortfolioCompanyCell[]
  identificationCode: string
  isSharedWithAreaManager?: boolean
  isSharedWithSales?: boolean
  isHeadquarter?: boolean
}

export type CustomVariable = {
  name: string
  value?: string
  type: 'String' | 'Number' | 'Date'
}

export type PortfolioCompanyDetails = {
  margoId: string
  companyStatus?: string
  salesId?: string
  areaManagerId?: string
  amount?: number
  productId?: string
  tag?: string
  customVariables: CustomVariable[]
}

export type UpdatePortfolioCompanyRequest = {
  margoId: string
  companyDetails: PortfolioCompanyDetails
}

export type PortfolioCompaniesRequest = {
  pageType: CompanyDetailsType
  pagination: Pagination
  isEnrichedQuery: boolean | undefined
  companyPortfolioStatus: string | undefined
  query: CompoundExpression | undefined
  semantic?: SemanticSearchRequest
  excludedPortfoliosIds?: string[]
}

export type PortfolioStatisticsStatusItem = {
  value: CompanyStatus
  count: number
}

export type PortfolioStatistics = {
  totalCount: number
  enrichedCount: number
  statuses: PortfolioStatisticsStatusItem[]
}

export type PortfolioCompanyMapResponseDto = {
  entityId?: string
  geoPosition: {
    lat: number
    lon: number
  }
  count?: number
  companyStatus?: CompanyStatus
}

export type PortfolioCompanyMapDto = {
  position: {
    lat: number
    lng: number
  }
  entityId?: string
  count?: number
  companyStatus?: CompanyStatus
}

export type PortfolioCompaniesResponse = {
  configuration: {
    fields: PortfolioCompanyFieldConfig[]
  }
  data: {
    items: PortfolioCompany[]
    total: number
  }
  statusCompanyFilter: Filter
  statistics: PortfolioStatistics
  semantic?: {
    synonyms: SynonymItem[]
  }
}

export type PortfolioCompaniesForMap = {
  data: {
    items: PortfolioCompanyMapResponseDto[]
  }
  statistics: PortfolioStatistics
}

export type PortfolioCompaniesMapReq = {
  area: {
    northWest: {
      latitude: number
      longitude: number
    }
    southEast: {
      latitude: number
      longitude: number
    }
  }
  zoom: number
  query?: CompoundExpression
  semantic?: SemanticSearchRequest
  suppressClusters?: boolean
  isEnrichedQuery?: boolean
  companyPortfolioStatus: string | undefined
  excludedPortfoliosIds: string[] | undefined
}

export type PortfolioListItem = {
  id: string
  name: string
  hasCustomVariables: boolean
}

export type PortfolioPreview = {
  clientCompaniesCount: number
  competitorCompaniesCount: number
  createdAt: string
  enrichedCompaniesCount: number
  headQuarterCount: number
  headQuarters?: HeadQuartersInfo
  id: string
  isEnriched: boolean
  isMonitored: boolean
  isPortfolioSharedAsWhole: boolean
  isShared: boolean
  isSharedWithUser: boolean
  isExcluded: boolean
  lastUpdated: string
  lastViewByUser: string
  leadCompaniesCount: number
  name: string
  note: string
  ownerName: string
  prospectCompaniesCount: number
  repositoryDocumentMonitoredQueryId?: string
  supplierCompaniesCount: number
  totalCompaniesCount: number
  uploadStatus: UploadStatus
  additionalItems?: Dictionary<string>
}

export type HeadQuartersInfo = {
  client: number
  competitor: number
  lead: number
  prospect: number
  supplier: number
}

export type LinkedCampaign = {
  id: string
  name: string
}

export type GetPortfoliosResponse = {
  totalCount: number
  portfolios: GetPortfoliosPortfolio[]
}

export type BasePortfolio = {
  id: string
  name: string
  note: string
  isEnriched: boolean
  isMonitored: boolean
  isExcluded: boolean
  isShared: boolean
  isSharedWithUser: boolean
  isSharedAsWhole: boolean
  ownerName: string
}

export type GetPortfoliosPortfolio = {
  createdAt: string
  lastUpdated?: string
  lastViewByUser?: string
  portfolioStatus: string
  totalCompaniesCount: number
  enrichedCompaniesCount: number
  linkedCampaigns: LinkedCampaign[]
} & BasePortfolio

export type GetPortfoliosRequest = {
  top: number | undefined
  skip?: number
  nameLike: string | undefined
  shareType: ShareType | undefined
}

export type GetPortfolioCompanyDuplicatesResponse = {
  records: PortfolioCompany[]
  total: number
}

export type ResolvedPortfolioCompanyDuplicate = {
  margoId: string
  product: string
  status: string
}

export enum PortfolioListOrderBy {
  CreatedAtAsc = 'CreatedAtAsc',
  CreatedAtDesc = 'CreatedAtDesc',
  UpdatedAtAsc = 'UpdatedAtAsc',
  UpdatedAtDesc = 'UpdatedAtDesc'
}

export enum PortfolioOwnership {
  Owned = 'Owned',
  Shared = 'Shared',
  Public = 'Public'
}

export type PortfolioSelectListParams = {
  skip?: number
  take?: number
  orderBy?: PortfolioListOrderBy
  minCountOfCompanies?: number
  minCountOfSameStatusCompanies?: number
  withEnrichedCompanies?: boolean
  filterMonitored?: boolean
  ownership?: PortfolioOwnership
}

export type AssociatedPortfolio = {
  id: string
  name: string
}

export type BranchTypeItem = {
  code: string
  text: string
  order: number
  count: number
  hasChildren: boolean
  parentPath: string[]
  domainType: string
  lang: string
}

export type BranchTypesResponseItem = {
  branches: BranchTypeItem[]
  jsonPath: string
}

export type PortfolioGridQuery = {
  semantic: SemanticSearchRequest | undefined
  query: CompoundExpression | undefined
  excludedPortfoliosIds: string[] | undefined
}

export type BaseSelection = {
  portfolioGridQuery: PortfolioGridQuery
  sourceContainerItemId: string
  selectedItems?: PortfolioCompanySelectionItem[]
  itemsToIgnore?: PortfolioCompanySelectionItem[]
}

export type BaseSelectionWithDestination = {
  destinationContainerItemId: string
} & BaseSelection

export type AddLocalUnitsRequest = {
  selection: {
    status: string
    query?: string
    tag?: string
  } & BaseSelectionWithDestination
}

export type Portfolio = {
  monitoredQueryId?: string
  createdAt: Date
  lastUpdate: Date
  lastViewByUser: Date
  status: UploadStatus
  statistic: PortfolioStats
  downloadType?: string
} & BasePortfolio

export type PortfolioStats = {
  total: number
  enriched: number
  headquarter: number
  client: number
  competitor: number
  lead: number
  prospect: number
  supplier: number
  headQuarters: HeadQuartersInfo
}

export enum UserShareType {
  Sales = 'Sales',
  Supervisor = 'Supervisor',
  AreaManager = 'AreaManager'
}

export type BaseUserItem = {
  userId: string
  firstName: string
  lastName: string
  userType: UserShareType
}

export type UserShareItem = {
  isShared: boolean
} & BaseUserItem

export type UserExclusionsItem = {
  isExcluded: boolean
} & BaseUserItem

export enum ShareType {
  MyPortfolios = 'MyPortfolios',
  CreatedByMeAndSharedToOthers = 'CreatedByMeAndSharedToOthers',
  SharedWithUser = 'SharedWithUser'
}

export type DeleteCompaniesRequest = {
  selection: BaseSelectionWithDestination
}

export type MoveCompaniesRequest = {
  selection: {
    excludeCustomVariables: boolean
  } & BaseSelectionWithDestination
}

export type ComparePortfolioVariablesRequest = {
  sourcePortfolioId: string
  targetPortfolioId: string
  compare?: boolean
}

export enum CreatePortfolioFromSelectionChange {
  Move = 'Move',
  Duplicate = 'Duplicate'
}

export type CreatePortfolioFromSelectionRequest = {
  selection: {
    change: CreatePortfolioFromSelectionChange
    portfolioName: string
  } & BaseSelection
}

export type ShareCompaniesRequest = {
  selection: {
    shareWithAreaManagers: boolean
    shareWithSales: boolean
  } & BaseSelectionWithDestination
}

export type UnshareCompaniesRequest = {
  selection: {
    unshareFromAreaManagers: boolean
    unshareFromSales: boolean
  } & BaseSelectionWithDestination
}

export enum AssignEntityActionType {
  Add = 'Add',
  Change = 'Change'
}

export type AssignEntityRequest = {
  selection: {
    productId?: string | null
    salesId?: string | null
    managerId?: string | null
    action: AssignEntityActionType
  } & BaseSelectionWithDestination
}

export type OperationResult = {
  actionContainerId: string
  destinationId: string
  status: string
}

export type PortfolioCompaniesMapReqData = {
  pos: MapPositionData
  query: CompoundExpression | undefined
  semantic: SemanticSearchRequest | undefined
  maxZoom: number
  isEnrichedQuery: boolean | undefined
  companyPortfolioStatus: string | undefined
  excludedPortfoliosIds: string[] | undefined
}

export enum CompanyMatchStatus {
  Rejected = 'Rejected',
  Duplicate = 'Duplicate',
  NotMatched = 'NotMatched',
  Pending = 'Pending'
}

export type InvalidUploadCompany = {
  identificationCode: string
  companyMatchStatus: CompanyMatchStatus
  companyPortfolioStatus: string
}
