import { BasePortfolioQueryDialogData } from '../types'

export enum EnrichBeforeCampaignView {
  Init,
  Recap
}

export type EnrichBeforeCampaignDialogData = {
  portfolioId: string
} & BasePortfolioQueryDialogData
