import { Reducer } from 'redux'
import * as actions from './actions'

export type SaleDetailState = Readonly<{
  canRefreshCampaign: boolean
  hiddenTaskPopupExpirationDate?: string
}>

export const initState: SaleDetailState = {
  canRefreshCampaign: false
}

export const saleDetailReducer: Reducer<SaleDetailState, actions.SaleDetailActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE:
      return {
        ...state,
        hiddenTaskPopupExpirationDate: action.payload
      }
    case actions.REFRESH_CAMPAIGN_STATUS_CHANGED:
      return {
        ...state,
        canRefreshCampaign: action.payload
      }
    default:
      return state
  }
}
