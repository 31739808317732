import { batch, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { getCompanyKeys } from '../../../api/companies'
import { companyReportKeys } from '../../../api/companyReport/common'
import { getPortfolioKeys } from '../../../api/portfolio'
import { getProspectingKeys } from '../../../api/prospecting'
import { getOperationsKeys } from '../../../api/operations'
import { TrackingActions } from '../../../features/analytics/actions'
import { EventCategory, GroupActionType } from '../../../features/analytics/types'
import { DialogActions } from '../../../features/dialog/actions'
import { PortfolioCompaniesActions } from '../../../features/portfolioCompany/actions'
import { queryClient } from '../../../features/queryclient'
import { EnrichCompaniesDialogData, EnrichSource } from './types'
import { ApplicationModule } from '../../../types'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateDialogData: (data: Partial<EnrichCompaniesDialogData>) =>
    dispatch(DialogActions.updateDialogData('EnrichCompanies', data)),
  enrichCompanies: (companyUnitIds: string[]) => dispatch(PortfolioCompaniesActions.enrichCompanies(companyUnitIds)),
  trackGroupAction: (source: EventCategory, type: GroupActionType) =>
    dispatch(TrackingActions.trackGroupAction(source, type)),
  showErrorLockDialog: () => dispatch(DialogActions.showDialog('PortfolioErrorLock'))
})

export const useAfterEnrichmentHandler = (data: EnrichCompaniesDialogData, close: () => void) => {
  const dispatch = useDispatch()
  const { enrichCompanies, trackGroupAction } = mapDispatchToProps(dispatch)

  // IMPORTANT:
  // TODO: refactor when all will be removed from stored
  // in a meantime, this is curried from previous implementation
  return () => {
    switch (data.source) {
      case EnrichSource.PortfolioDetailGroupAction: {
        trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.Enrichment)
        close()
        break
      }
      case EnrichSource.Report: {
        const { companyUnitId, identificationCode } = data.params

        queryClient.invalidateQueries(companyReportKeys.reportDataForCompany(identificationCode, companyUnitId))
        queryClient.invalidateQueries(getProspectingKeys.ProspectingCompanies())
        queryClient.invalidateQueries(getCompanyKeys.CompaniesByQueryFilter(ApplicationModule.Prospecting))
        enrichCompanies([companyUnitId])
        break
      }
      case EnrichSource.PortfolioDetail:
      case EnrichSource.PortfolioDetailMap: {
        const { companyUnitId, portfolioId } = data.params
        enrichCompanies([companyUnitId])
        queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(portfolioId))
        break
      }
      case EnrichSource.Prospecting: {
        queryClient.invalidateQueries(getProspectingKeys.ProspectingCompanies())
        queryClient.invalidateQueries(getCompanyKeys.CompaniesByQueryFilter(ApplicationModule.Prospecting))
        break
      }
      case EnrichSource.Operations: {
        const { companyUnitId } = data.params
        enrichCompanies([companyUnitId])
        break
      }
      case EnrichSource.Search: {
        const { companyUnitId } = data.params
        batch(() => {
          enrichCompanies([companyUnitId])
          queryClient.invalidateQueries(getProspectingKeys.ProspectingCompanies())
          queryClient.invalidateQueries(getCompanyKeys.CompaniesByQueryFilter(ApplicationModule.Prospecting))
        })
        break
      }
      default: {
        const { companyUnitId } = data.params
        enrichCompanies([companyUnitId])
      }
    }

    queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompanies())
    queryClient.invalidateQueries(getOperationsKeys.OperationsCompanies())
  }
}
