import React, { useCallback } from 'react'
import { Form } from 'antd3'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Input } from '../../../components/Input'
import { Checkbox } from '../../../components/Checkbox'
import { Dialog } from '../../../components/Dialog'
import { Text } from '../../../components/Text'
import { Button } from '../../../components/Button'
import { useGetFilterQueriesAsDocument } from '../../../api/query'
import { QueryType } from '../../../types'
import { Select, Option } from '../../../components/Select'
import { Stack } from '../../../components/Stack'
import { AddToDasboardWrapper, Buttons } from './components'
import { Radio, RadioGroup } from '../../../components/Radio'
import { SubmitMethod } from './types'
import { validateSaveQueryData } from './utils'
import { checkQueryName } from '../../../utils/query'
import { FETCH_QUERIES_TOP } from '../../../features/query/constants'

type Props = {
  canAddWidget: boolean
  loading: boolean
  submitting: boolean
  queryName: string
  queryNameError: string | undefined
  addToDashboard: boolean
  currentSaveMethod: SubmitMethod
  setCurrentSaveMethod: (option: SubmitMethod) => void
  setQueryId: (queryId: string) => void
  queryId: string | undefined
  setQueryName: (queryName: string) => void
  setAddToDashboard: (checked: boolean) => void
  submit: () => void
  close: () => void
  setNameError: (value: string | undefined) => void
}

export const SaveQueryDialog = ({
  loading,
  submitting,
  canAddWidget,
  queryName,
  queryNameError,
  currentSaveMethod,
  setCurrentSaveMethod,
  addToDashboard,
  setQueryName,
  setQueryId,
  queryId,
  setAddToDashboard,
  submit,
  close,
  setNameError
}: Props) => {
  const { t } = useTranslation(['prospecting', 'common', 'error'])
  const queryTypes = [QueryType.ProspectingQueries]

  const { isLoading, data: items } = useGetFilterQueriesAsDocument({
    documentTypes: queryTypes,
    top: FETCH_QUERIES_TOP
  })

  const isCreateNew = currentSaveMethod === SubmitMethod.CreateNew
  const isUpdateExisting = currentSaveMethod === SubmitMethod.Update

  const onInputChangeHandler = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setNameError(checkQueryName(t, target.value))
      setQueryName(target.value)
    },
    [setNameError, setQueryName, t]
  )

  return (
    <Dialog
      title={t('SaveQuery.DialogTitle')}
      width={900}
      visible
      loading={loading || submitting}
      onCancel={close}
      footer={
        <Stack gap="auto" center>
          <AddToDasboardWrapper>
            {isCreateNew && (
              <>
                <Text size="M" type="primary" bold block>
                  {canAddWidget ? t('SaveQuery.ConfirmMessage') : t('SaveQuery.MaximumWidgetCountReached')}
                </Text>
                <Checkbox
                  disabled={!canAddWidget || loading || submitting}
                  checked={addToDashboard}
                  onChange={evt => setAddToDashboard(evt.target.checked)}
                >
                  <Text type="inherit" size="M">
                    {t('SaveQuery.Fields.AddToDashboard')}
                  </Text>
                </Checkbox>
              </>
            )}
          </AddToDasboardWrapper>
          <Buttons gap="1em">
            <Button disabled={submitting} onClick={close}>
              {t('common:Cancel')}
            </Button>
            <Button
              type="primary"
              disabled={
                submitting ||
                queryNameError !== undefined ||
                !validateSaveQueryData(currentSaveMethod, queryName, queryId)
              }
              onClick={submit}
            >
              {t('common:Save')}
            </Button>
          </Buttons>
        </Stack>
      }
    >
      <Stack orientation="vertical" gap={40}>
        <Text size="M" block>
          {t('SaveQuery.Description')}
        </Text>
        <RadioGroup
          disabled={submitting}
          value={currentSaveMethod}
          onChange={e => setCurrentSaveMethod(e.target.value)}
        >
          <Radio value={SubmitMethod.Update}>
            <Text size="S" type="primary" bold>
              {t('SaveQuery.UpdateExisting')}
            </Text>
          </Radio>

          <Radio value={SubmitMethod.CreateNew}>
            <Text size="S" type="primary" bold>
              {t('SaveQuery.CreateNew')}
            </Text>
          </Radio>
        </RadioGroup>

        <Form.Item validateStatus={queryNameError ? 'error' : 'success'} help={queryNameError}>
          <Text size="S" type="primary" bold>
            {t('SaveQuery.Fields.QueryName')}
          </Text>
          {isUpdateExisting && (
            <Select
              loading={isLoading}
              placeholder={t('SaveQuery.Fields.QueryName')}
              value={queryId}
              onChange={value => {
                const selectedQueryName = _.find(items, { id: value as string })?.name ?? ''
                setQueryName(selectedQueryName)
                setQueryId(value as string)
              }}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            >
              {_.map(items, i => (
                <Option key={i.id}>{i.name}</Option>
              ))}
            </Select>
          )}
          {isCreateNew && (
            <Input
              disabled={submitting}
              placeholder={t('SaveQuery.Fields.QueryNamePlaceholder')}
              value={queryName}
              onChange={onInputChangeHandler}
            />
          )}
        </Form.Item>
      </Stack>
    </Dialog>
  )
}
