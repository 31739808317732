import { SelectionUnit } from '../../../api/enrichment/types'
import { ApplicationModule } from '../../../types'
import { BasePortfolioQueryDialogData } from '../types'

export type FormState = {
  targetPortfolio: EnrichCompanyTargetPortfolio
  targetPortfolioId?: string
  newPortfolioName: string
}

export enum EnrichCompaniesViewType {
  Recap = 'Recap',
  AssignPortfolio = 'AssignPortfolio',
  Done = 'Done',
  Fail = 'Fail',
  NoEnrichment = 'NoEnrichment'
}

export enum EnrichSource {
  Search,
  Prospecting,
  ProspectingMap,
  Targeting,
  PortfolioDetail,
  PortfolioDetailMap,
  PortfolioDetailGroupAction,
  Report,
  ReportPopup,
  Operations
}

type BaseDialogData<TSource extends EnrichSource, TParams extends object> = {
  source: TSource
  params: TParams
  onEnrichmentDone?: () => void
}

export type EnrichSingleCompanyParams = {
  companyUnitId: string
  identificationCode: string
  companyName?: string
  portfolioId?: string
  applicationModule?: ApplicationModule
}

export type EnrichPortfolioCompanyParams = {
  margoId: string
  companyUnitId: string
  portfolioId: string
  applicationModule?: ApplicationModule
}

export type EnrichPortfolioSelectionParams = {
  portfolioId: string
  isSelectAll: boolean
  companyUnitsToEnrich: SelectionUnit[]
  companyUnitsToIgnore: SelectionUnit[]
  applicationModule?: ApplicationModule
} & BasePortfolioQueryDialogData

export type EnrichSingleCompanyDialogData = BaseDialogData<
  | EnrichSource.Search
  | EnrichSource.Prospecting
  | EnrichSource.ProspectingMap
  | EnrichSource.Report
  | EnrichSource.ReportPopup
  | EnrichSource.Operations,
  EnrichSingleCompanyParams
>

export type EnrichPortfolioCompanyDialogData = BaseDialogData<
  EnrichSource.PortfolioDetail | EnrichSource.PortfolioDetailMap,
  EnrichPortfolioCompanyParams
>

export type EnrichPortfolioSelectionDialogData = BaseDialogData<
  EnrichSource.PortfolioDetailGroupAction,
  EnrichPortfolioSelectionParams
>

export type EnrichCompaniesDialogData =
  | EnrichSingleCompanyDialogData
  | EnrichPortfolioCompanyDialogData
  | EnrichPortfolioSelectionDialogData

export enum EnrichCompanyTargetPortfolio {
  NewPortfolio = 'NewPortfolio',
  ExistingPortfolio = 'ExistingPortfolio'
}

export type CompanyEnrichProposal = {
  withoutPortfolio: number
  companiesCount: number
  remainingCreditsAfterCompaniesEnrichment: number
  requiredCreditsToEnrichCompanies: number
  currentCredits: number
  companiesToEnrichCount: number
  enrichedCompaniesCount: number
}
