import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { ReactComponent as BellIconSvg } from '../../assets/icons/bell.svg'
import { ReactComponent as BellNewIconSvg } from '../../assets/icons/new.svg'

export const Divider = styled.div`
  height: 40px;
  margin: 0 10px;
  flex: 0 0 auto;
  width: 1px;
  opacity: 0.2;
  background-color: ${({ theme }) => theme.colors.white};
`

export const HorizontalDivider = styled.div`
  height: 1px;
  margin: 20px 40px;
  background-color: rgba(255, 255, 255, 0.4);
`

export const IconButton = styled.div<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 40px;
  padding: 0 8px;
  fill: ${({ theme }) => theme.colors.white};
  background-color: transparent;

  :hover {
    fill: ${({ theme }) => theme.colors.pumpkinOrange};
  }
`

export const LinkMenuButton = styled(Link)`
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  height: 40px;
  padding: 0 10px 0 0;
  align-items: center;
  fill: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme }) => theme.colors.white};
  word-break: none;
  flex: 0 0 auto;

  span {
    padding-left: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    fill: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
  }
`
type BellIconProps = {
  hasNewNotifications: boolean
}

export const getBellIconCmp = ({ hasNewNotifications }: BellIconProps) =>
  hasNewNotifications ? BellNewIconSvg : BellIconSvg

export const BellIcon = (p: BellIconProps) => {
  const BellIconCmp = getBellIconCmp(p)
  return <BellIconCmp />
}

export const RightMenuBar = styled.div<{ isSearchOpen: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: ${({ isSearchOpen }) => (isSearchOpen ? '0 40px' : '0 40px 0 auto')};
  width: ${({ isSearchOpen }) => (isSearchOpen ? '100%' : 'auto')};

  @media only screen and (max-width: 320px) {
    margin: ${({ isSearchOpen }) => (isSearchOpen ? '0 10px' : '0 20px 0 auto')};
  }

  > * + * {
    margin-left: 8px;
  }
`

export const LinkBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

export const InfoWrapper = styled.div`
  flex: 1 1 auto;
  padding: 10px 0;
  margin-left: 15px;
`

export const MenuDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dustyBlue};
  margin: 0 15px;
`
