import axios from 'axios'
import { SortItem } from '../../types'
import { UrlConfig } from '../types'
import { AddGoalRequest, GoalResponse, GoalsResponse } from './types'

export const ADD_GOAL_URL: UrlConfig = { url: '/api/goals', method: 'post' }

export const getGoals = (skip?: number, top?: number, sorting?: SortItem) =>
  axios
    .get<GoalsResponse>('/api/goals', {
      params: {
        top,
        skip,
        sort: sorting ? [sorting] : undefined
      }
    })
    .then(resp => resp.data)

export const getGoal = (goalId: string) => axios.get<GoalResponse>(`/api/goals/${goalId}`).then(resp => resp.data)

export const editGoal = (goalId: string, name: string) => axios.put<GoalResponse>(`/api/goals/${goalId}`, { name })

export const deleteGoal = (goalId: string) => axios.delete(`/api/goals/${goalId}`)

export const validateGoal = (goalName: string) =>
  axios.post('/api/goals/validation', { name: goalName }, { allowedErrorStatuses: [400] })

export const validateGoalTasks = (taskNames: string[]) =>
  axios.post('/api/goals/tasks-validation', { tasks: taskNames }, { allowedErrorStatuses: [400] })

export const addGoal = (goal: AddGoalRequest) => axios.post('/api/goals', goal)
