import 'moment/locale/cs'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/sk'
import 'moment/locale/fr'
import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { DefaultTheme, ThemeProvider } from 'styled-components/macro'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { NotificationPopupContainer } from '../../containers/Notifications'
import { queryClient } from '../../features/queryclient'
import { persistor, store } from '../../features/store'
import { RouterProvider } from '../../features/navigation/RouterContext'
import { gridTheme, theme } from '../../utils/theme'
import { history } from '../../utils/history'
import { Loading } from '../Loading'
import AppContainer from './App'
import { ViewportSizeContextProvider } from './ViewportSizeContext'
import { RouteChangeTracker } from './RouteChangeTracker'
import { EventListeners } from './EventListeners'

export const Root = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme as DefaultTheme}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <GridThemeProvider gridTheme={gridTheme}>
          <QueryClientProvider client={queryClient}>
            <ViewportSizeContextProvider>
              {/*
              Use history router until all sagas are refactored and history is not needed anymore -> then swap to BrowserRouter
              - https://github.com/remix-run/react-router/issues/8264
              - https://github.com/remix-run/react-router/issues/9422
              */}
              {/* @ts-expect-error */}
              <HistoryRouter history={history}>
                <RouterProvider>
                  <RouteChangeTracker>
                    <EventListeners />
                    <NotificationPopupContainer />
                    <AppContainer />
                  </RouteChangeTracker>
                </RouterProvider>
              </HistoryRouter>
            </ViewportSizeContextProvider>
          </QueryClientProvider>
        </GridThemeProvider>
      </PersistGate>
    </ThemeProvider>
  </Provider>
)
