import _ from 'lodash'
import {
  deselectNodeMultiselectClassic,
  nodeSelectionStateMultiselectClassic,
  selectNodeMultiselectClassic
} from './multiselectClassic'
import {
  deselectNodeMultiselectMultiValue,
  nodeSelectionStateMultiselectMultiValue,
  selectNodeMultiselectMultiValue
} from './multiselectMultiValue'
import { deselectNodeSelectClassic, nodeSelectionStateClassic, selectNodeSelectClassic } from './selectClassic'
import { KeyTree, NodeSelectionState, Select, SelectionType, TreeKeySelection } from './types'

export const nodeSelectionState = (
  keySelection: TreeKeySelection[],
  keySelectionItem: TreeKeySelection,
  selectionType: SelectionType
): NodeSelectionState => {
  switch (selectionType) {
    case SelectionType.MultiselectClassic:
      return nodeSelectionStateMultiselectClassic(keySelection, keySelectionItem)
    case SelectionType.MultiselectMultiValue:
      return nodeSelectionStateMultiselectMultiValue(keySelection, keySelectionItem)
    default:
      return nodeSelectionStateClassic(keySelection, keySelectionItem)
  }
}

export const selectNode = (
  tree: KeyTree,
  keySelection: TreeKeySelection[],
  select: Select,
  selectionType: SelectionType
) => {
  const keySelectionItem: TreeKeySelection = _.pick(select, ['key', 'parentPath'])

  if (select.selected) {
    switch (selectionType) {
      case SelectionType.SelectClassic:
        return selectNodeSelectClassic(keySelection, keySelectionItem)
      // Only for FilterSelection
      case SelectionType.MultiselectMultiValue:
        return selectNodeMultiselectMultiValue(tree, keySelection, keySelectionItem)
      default:
        return selectNodeMultiselectClassic(tree, keySelection, keySelectionItem)
    }
  }

  switch (selectionType) {
    case SelectionType.SelectClassic:
      return deselectNodeSelectClassic(keySelection, keySelectionItem)
    // Only for FilterSelection
    case SelectionType.MultiselectMultiValue:
      return deselectNodeMultiselectMultiValue(tree, keySelection, keySelectionItem)
    default:
      return deselectNodeMultiselectClassic(tree, keySelection, keySelectionItem)
  }
}
