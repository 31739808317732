import React from 'react'
import { AddPortfolioDialog } from './AddPortfolioDialog'
import { AddPortfolioViewState } from '../../types'

type Props = {
  data: {
    state: AddPortfolioViewState
    campaignId: string
    portfolioIds: string[]
  }
  isLoading: boolean
  close: () => void
}

export const AddPortfolioDialogContainer = ({ isLoading, close, data }: Props) => {
  const { state, campaignId, portfolioIds } = data
  return (
    <AddPortfolioDialog
      isLoading={isLoading}
      view={state}
      close={close}
      campaignId={campaignId}
      currentPortfolios={portfolioIds}
    />
  )
}
