import _ from 'lodash'
import { useGetPortfolioCustomVariablesTypes } from '../../../../../api/portfolio'
import { useGetCampaignCustomVariablesTypes } from '../../../../../api/salestool'

export const useIsCustomVarStructureChanged = (campaignId: string, currentPortfolioId?: string): boolean => {
  const { data: campaignTypes } = useGetCampaignCustomVariablesTypes(campaignId)
  const { data: portfolioTypes } = useGetPortfolioCustomVariablesTypes(currentPortfolioId)

  if (_.isEmpty(campaignTypes) && _.isEmpty(portfolioTypes)) return false

  return !_.isEqual(campaignTypes, portfolioTypes)
}
