import Radio from 'antd3/lib/radio'
import React from 'react'
import { Query } from '../../../features/query/types'
import { QueryType } from '../../../types'
import { ClaimsWrapper } from '../../../components/ClaimsWrapper'
import { Claim } from '../../../authorization'
import { containsQuery } from '../utils'

type QueryRadioProps = {
  value: QueryType
  options: Query[]
  children: React.ReactNode
}
export const QueryRadio: React.FC<QueryRadioProps> = ({ children, value, options }) => {
  const content = (
    <Radio value={value} disabled={!containsQuery(value, options)}>
      {children}
    </Radio>
  )

  const claim = (
    {
      [QueryType.PortfolioQueries]: Claim.PortfolioManagement,
      [QueryType.TargetingQueries]: Claim.Targeting,
      [QueryType.ProspectingQueries]: Claim.Prospecting
    } as Record<QueryType, Claim>
  )[value]

  return <ClaimsWrapper claims={claim}>{content}</ClaimsWrapper>
}
