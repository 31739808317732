/* eslint-disable class-methods-use-this */
import _ from 'lodash'
import { Action, combineReducers, Reducer } from 'redux'
import { createTransform, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import localStorage from 'redux-persist/lib/storage'
import { FilterStorage } from '../utils/storage'
import { configReducer } from './config/reducer'
import { currenciesReducer } from './currencies'
import { dialogReducer } from './dialog/reducer'
import { initState as FiltersInitState, withFiltering } from './filters/reducer'
import { FilterStateWrapper, ReducerWithFiltering } from './filters/types'
import { CLEAR_STORE } from './globalActions'
import { loadingReducer } from './loading/reducer'
import { notificationsReducer } from './notifications/reducer'
import { portfolioCompanyReducer } from './portfolioCompany/reducer'
import {
  withCompaniesTable,
  withCompaniesWithIdTable,
  WithIdTableState,
  WithTableDataState
} from './portfolioCompany/withTable/reducer'
import { portfolioCompanyDuplicatesReducer } from './portfolioCompanyDuplicates/reducer'
import {
  initState as PortfolioDetailViewInitState,
  portfolioDetailPageReducer,
  PortfolioDetailPageState
} from './portfolioDetailPage/reducer'
import { initState as prospectingInitState, prospectingReducer, ProspectingState } from './prospecting/reducer'
import { queryReducer } from './query/reducer'
import { salestoolTableReducer } from './salestool/table/reducer'
import {
  initState as SalestoolAssignmentsInitState,
  salestoolAssignmentsReducer,
  SalestoolAssignmentsState
} from './salestoolAssignmentsPage/reducer'
import { saleDetailReducer } from './salestoolSaleDetail/reducer'
import { tableReducer } from './tables/reducer'
import { initState as TargetingInitState, targetingReducer, TargetingState } from './targeting/reducer'
import { userReducer } from './user/reducer'

const userPersistedReducer = persistReducer(
  {
    key: 'user',
    storage: localStorage,
    whitelist: ['token', 'selectedLanguage', 'selectedCurrency', 'claims', 'userId', 'organizationId', 'products']
  },
  userReducer
)

const portfolioDetailFilters = withFiltering<PortfolioDetailPageState>(
  portfolioDetailPageReducer,
  'views.portfolioDetail',
  PortfolioDetailViewInitState
)

const portfolioDetailFiltersCompanies = withCompaniesWithIdTable<ReducerWithFiltering<PortfolioDetailPageState>>(
  portfolioDetailFilters,
  'views.portfolioDetail',
  'entityId',
  { ...PortfolioDetailViewInitState, ...FiltersInitState }
)

const persistedPortfolioDetailReducer = persistReducer<
  ReducerWithFiltering<PortfolioDetailPageState> & WithIdTableState
>(
  {
    key: 'portfolio-detail-view',
    storage: new FilterStorage(),
    whitelist: ['filters', 'viewType', 'visibleColumns', 'companyStatusFilter'],
    transforms: [
      createTransform((out, key) => {
        if (key === 'filters') {
          const omitted = _.omit(out, ['loading', 'filterGroups', 'filters', 'open'])
          return _.set(omitted, 'domainData', {})
        }
        return out
      })
    ],
    stateReconciler: autoMergeLevel2
  },
  portfolioDetailFiltersCompanies
)

const prospectingReducerWithFilters = withFiltering<ProspectingState>(
  prospectingReducer,
  'prospecting',
  prospectingInitState
)

const persistedProspectingReducer = persistReducer<ReducerWithFiltering<ProspectingState> & WithTableDataState>(
  {
    key: 'prospecting',
    storage: new FilterStorage(),
    whitelist: ['filters', 'viewType', 'visibleColumns', 'companyStatusFilter', 'funnelData'],
    transforms: [
      createTransform((out, key) => {
        if (key === 'filters') {
          const omitted = _.omit(out, ['loading', 'filterGroups', 'filters', 'open'])
          return _.set(omitted, 'domainData', {})
        }
        return out
      })
    ],
    stateReconciler: autoMergeLevel2
  },
  withCompaniesTable<ReducerWithFiltering<ProspectingState>>(prospectingReducerWithFilters, 'prospecting', 'entityId', {
    ...FiltersInitState,
    ...prospectingInitState
  })
)

const salestoolAssignmentsFilters = withFiltering<SalestoolAssignmentsState>(
  salestoolAssignmentsReducer,
  'views.salestoolAssignments',
  SalestoolAssignmentsInitState
)

const persistedSalestoolAssignmentsReducer = persistReducer<ReducerWithFiltering<SalestoolAssignmentsState>>(
  {
    key: 'salestool-assignments-view',
    storage: new FilterStorage(),
    whitelist: ['filters', 'viewType', 'visibleColumns', 'companyStatusFilter'],
    transforms: [
      createTransform((out, key) => {
        if (key === 'filters') {
          const omitted = _.omit(out, ['loading', 'filterGroups', 'filters'])
          return _.set(omitted, 'domainData', {})
        }
        return out
      })
    ],
    stateReconciler: autoMergeLevel2
  },
  salestoolAssignmentsFilters as Reducer<SalestoolAssignmentsState & FilterStateWrapper, Action<any>>
)

const persistedSaleDetailReducer = persistReducer(
  {
    key: 'sale-detail-view',
    storage: localStorage,
    whitelist: ['hiddenTaskPopupExpirationDate']
  },
  saleDetailReducer
)

const targetingReducerWithFilters = withFiltering<TargetingState>(targetingReducer, 'targeting', TargetingInitState)

const persistedTargetingReducer = persistReducer<ReducerWithFiltering<TargetingState>>(
  {
    key: 'targeting',
    storage: new FilterStorage(),
    whitelist: [
      'filters',
      'viewType',
      'companyStatusFilter',
      'statusCompanyFilter',
      'selectedPortfolios',
      'targetingFilters',
      'isQuerySaved',
      'aggregationField',
      'activeChartModal'
    ],
    transforms: [
      createTransform((out, key) => {
        if (key === 'filters') {
          const omitted = _.omit(out, ['loading', 'filterGroups', 'filters', 'open'])
          return _.set(omitted, 'domainData', {})
        }
        return out
      })
    ],
    stateReconciler: autoMergeLevel2
  },
  // @ts-ignore
  targetingReducerWithFilters
)

const appReducer = combineReducers({
  config: configReducer,
  currencies: currenciesReducer,
  user: userPersistedReducer,
  portfolioCompany: portfolioCompanyReducer,
  portfolioCompanyDuplicates: portfolioCompanyDuplicatesReducer,
  prospecting: persistedProspectingReducer,
  query: queryReducer,
  notifications: notificationsReducer,
  loading: loadingReducer,
  dialog: dialogReducer,
  views: combineReducers({
    portfolioDetail: persistedPortfolioDetailReducer,
    salestoolAssignments: persistedSalestoolAssignmentsReducer,
    salestoolTable: salestoolTableReducer,
    saleDetail: persistedSaleDetailReducer
  }),
  tables: tableReducer,
  targeting: persistedTargetingReducer
})

export const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    // only keep "config" state and reset everything else
    return appReducer({ config: state?.config } as any, action)
  }
  return appReducer(state, action)
}
