import _ from 'lodash'
import { SemanticSearchType, SemanticSearch } from '../types'
import { SynonymItem } from '../../../api/filters/types'

export const init: SemanticSearch = {
  synonyms: [],
  appliedSynonyms: [],
  searchText: '',
  type: SemanticSearchType.All,
  advanced: false,
  not: false
}

export const change = (semanticSearch: SemanticSearch, searchText: string) => {
  return {
    semanticSearch: {
      ...semanticSearch,
      searchText,
      synonyms: init.synonyms,
      appliedSynonyms: init.appliedSynonyms
    }
  }
}

export const setAppliedSynonyms = (semanticSearch: SemanticSearch, appliedSynonyms: SynonymItem[]) => {
  return {
    semanticSearch: {
      ...semanticSearch,
      appliedSynonyms
    }
  }
}

export const setType = (semanticSearch: SemanticSearch, type: SemanticSearchType) => {
  return {
    semanticSearch: {
      ...semanticSearch,
      type,
      synonyms: init.synonyms,
      appliedSynonyms: init.appliedSynonyms
    }
  }
}

export const setAdvanced = (semanticSearch: SemanticSearch, advanced: boolean) => {
  return {
    semanticSearch: {
      ...semanticSearch,
      advanced
    }
  }
}

export const setNot = (semanticSearch: SemanticSearch, not: boolean) => {
  return {
    semanticSearch: {
      ...semanticSearch,
      not
    }
  }
}

export const update = (semanticSearch: SemanticSearch, synonyms: SynonymItem[]) => {
  return {
    semanticSearch: {
      ...semanticSearch,
      synonyms: _.uniqBy(_.concat(semanticSearch.synonyms, synonyms), 'text')
    }
  }
}
