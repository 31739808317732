import _ from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetGoal } from '../../../api/goals'
import { Dialog } from '../../../components/Dialog'
import { GoalDetailTable } from './GoalDetailTable'
import { Text } from '../../../components/Text'
import { ExtraLargeSpin } from '../../../components/Loading/Spin'
import { DialogActions } from '../../../features/dialog/actions'
import { getColumns } from './helpers'

type Props = {
  close: () => void
  goalId: string
}

export const GoalDetailDialog = ({ close, goalId }: Props) => {
  const { t } = useTranslation(['goal', 'common', 'error'])
  const dispatch = useDispatch()

  const { data: goal, isLoading } = useGetGoal(goalId)

  const tasks = _.map(goal?.tasks, task => {
    return { ...task, position: task.position + 1 }
  })

  const openEditTask = useCallback(
    (taskId: string, taskName: string) => dispatch(DialogActions.openEditTaskDialog({ taskId, taskName, goalId })),
    [dispatch, goalId]
  )

  const openDeleteTask = useCallback(
    (taskId: string, taskName: string) => dispatch(DialogActions.openDeleteTaskDialog({ taskId, taskName, goalId })),
    [dispatch, goalId]
  )

  if (isLoading) return <ExtraLargeSpin delay={150} />
  if (goal === undefined) return null

  return (
    <Dialog
      visible
      title={goal.name}
      okText={t('common:Back')}
      onOk={close}
      onCancel={close}
      loading={isLoading}
      width={900}
    >
      <Text size="M" block text={t('account:Goal.Desc1')} />
      <Text size="M" margin={{ bottom: 20 }} block text={t('account:Goal.Desc2')} />
      <GoalDetailTable
        loading={isLoading}
        dataSource={tasks}
        columns={getColumns(t)}
        openEditTask={openEditTask}
        openDeleteTask={openDeleteTask}
      />
    </Dialog>
  )
}
