/* eslint-disable lodash/prefer-lodash-method */
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AutoComplete as AntAutoComplete } from 'antd3'
import { TFunction } from 'i18next'
import { BaseSelectRef } from 'rc-select'
import * as Components from '../../Search/components'
import { IconPosition } from '../../Search/types'
import { BasicTooltip } from '../../Tooltip'
import { IconButton } from '../StyledComponents'
import { useGetSuggestions } from '../../../api/suggestions/queries'
import { DropdownWrapper, HeaderSearchWrapper } from './styled'
import {
  DEFAULT_SUGGESTION_SIZE,
  getUriByIdFromSuggestions,
  shouldDisplayCurrentSearchAndSuggestions,
  useGetOptions
} from './helpers'
import { getShowFreeSearchSuggestions } from '../../../features/config/selectors'
import { TEXT_FIELDS_DEBOUNCE_WAIT } from '../../../features/filters/constants'
import { Text } from '../../Text'
import { ERROR_TOO_LONG, ERROR_TOO_SHORT, MAX_SEARCH_LENGTH, MIN_SEARCH_LENGTH } from '../../../pages/Search/constants'

const valueErrorOption = (key: string, text: string) => {
  return (
    <AntAutoComplete.Option key={key} disabled>
      <Text color="error" bold className="error">
        {text}
      </Text>
    </AntAutoComplete.Option>
  )
}

const valueTooLongOption = (t: TFunction) => [
  valueErrorOption(ERROR_TOO_LONG, t('header:SearchErrorTooLong', { maxLimit: MAX_SEARCH_LENGTH }))
]
const valueTooShortOption = (t: TFunction) => [
  valueErrorOption(ERROR_TOO_SHORT, t('header:SearchErrorTooShort', { minLimit: MIN_SEARCH_LENGTH }))
]

type Props = {
  isOpen: boolean
  placeholder: string
  onSubmit: (value: string) => void
  onChange: (value: string) => void
  toggleSearch: () => void
  debounceTime?: number
}

export const HeaderSearch = ({
  isOpen,
  placeholder,
  onSubmit,
  onChange,
  toggleSearch: onSearchClick,
  debounceTime = TEXT_FIELDS_DEBOUNCE_WAIT
}: Props) => {
  const { t } = useTranslation(['common', 'header'])
  const navigate = useNavigate()

  const inputRef = useRef<BaseSelectRef>(null)
  const [value, setValue] = useState<string | undefined>(undefined)
  const [focusRequested, setFocusRequested] = useState(false)
  const [visibleOptions, setVisibleOptions] = useState<JSX.Element[]>([])

  const debouncedSetValue = useMemo(
    () => _.debounce(newValue => setValue(newValue as string), debounceTime),
    [debounceTime]
  )
  useEffect(() => {
    return debouncedSetValue.cancel()
  }, [debouncedSetValue])

  const isValueTooLong = value && value.length > MAX_SEARCH_LENGTH

  const options = useGetOptions(value)

  useEffect(() => {
    const visibleOptionsToDisplay = isValueTooLong ? valueTooLongOption(t) : options
    setVisibleOptions(visibleOptionsToDisplay)
  }, [isValueTooLong, options, t])

  const onKeyDown = (event: { key: any }) => {
    const isEnterPressed = event.key === 'Enter'
    const isTextTooShort = value && value.length > 0 && value.length < MIN_SEARCH_LENGTH
    const isNoOptionAvailable = options.length === 0

    if (isEnterPressed && isTextTooShort && isNoOptionAvailable) {
      const tooShortOption = valueTooShortOption(t)
      setVisibleOptions(tooShortOption)
    }
  }

  const shouldShowSuggestions = useSelector(getShowFreeSearchSuggestions)
  const { data: suggestions } = useGetSuggestions(
    { query: value!, size: DEFAULT_SUGGESTION_SIZE },
    { enabled: shouldDisplayCurrentSearchAndSuggestions(value) && shouldShowSuggestions }
  )

  const getUriById = (id: string) => getUriByIdFromSuggestions(id, suggestions)

  const clearSearch = useCallback(() => {
    setTimeout(() => {
      onSubmit('')
      setValue(undefined)
    }, 5)
  }, [onSubmit])

  useEffect(() => {
    if (value) onChange(value)
  }, [value, onChange])

  useEffect(() => {
    if (!focusRequested) return
    if (isOpen) inputRef!.current!.focus()
    setFocusRequested(false)
  }, [focusRequested, isOpen])

  useEffect(() => {
    if (!isOpen) clearSearch()
  }, [isOpen, clearSearch])

  useEffect(() => {
    if (!isOpen) return
    setTimeout(() => {
      setFocusRequested(true)
    }, 400)
  }, [isOpen])

  const onValueSelect = (selectedValue: unknown, selectedOption: unknown) => {
    const reactElement = selectedOption as React.ReactHTMLElement<any>
    const key = reactElement?.key
    const uri = _.isString(key) ? getUriById(key) : undefined

    if (uri) {
      onSearchClick()
      navigate({ pathname: uri })
    } else {
      onSubmit(selectedValue as string)
    }
  }

  return (
    <>
      <HeaderSearchWrapper>
        <Components.SearchWrapper type="primary" open={isOpen}>
          {isOpen && (
            <>
              <Components.SearchButton
                onClick={onSearchClick}
                position={IconPosition.Left}
                open={isOpen}
                role="button"
                aria-label="search"
              >
                <Components.SearchIcon />
              </Components.SearchButton>
              <Components.StyledAutoComplete
                ref={inputRef}
                defaultOpen
                defaultActiveFirstOption
                autoFocus
                backfill
                size="large"
                placeholder={placeholder}
                // eslint-disable-next-line react-native/no-inline-styles
                dropdownStyle={{
                  // Same z-index as whole main menu
                  zIndex: 44000,
                  position: 'fixed',
                  top: '60px',
                  backgroundColor: '#1a508a'
                }}
                onSearch={debouncedSetValue}
                onSelect={onValueSelect}
                onKeyDown={onKeyDown}
                dropdownRender={(menu: React.ReactNode) => <DropdownWrapper>{menu}</DropdownWrapper>}
              >
                {visibleOptions}
              </Components.StyledAutoComplete>
            </>
          )}
        </Components.SearchWrapper>
      </HeaderSearchWrapper>
      {!isOpen && (
        <BasicTooltip placement="bottom" title={t('Search')}>
          <IconButton onClick={onSearchClick} data-testid="search-icon" role="button" aria-label="open search">
            <Components.SearchIcon />
          </IconButton>
        </BasicTooltip>
      )}
    </>
  )
}
