import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Col, Row } from '../Grid'
import { Statistic } from './Statistic'
import { CreditStatistic } from './CreditStatistic'

const RowStyled = styled(Row)`
  margin: -10px -10px 0;

  > ${Col} {
    padding: 10px;
    display: flex;
    flex: 1 1 auto;
  }
`

type Props = {
  enrichedCompanies: number
  usedCredits: number
  remainingCredits: number
}

export const EnrichDoneStatistic = ({ enrichedCompanies, usedCredits, remainingCredits }: Props) => {
  const { t } = useTranslation('portfolio')
  return (
    <RowStyled>
      <Col col={12}>
        <Statistic
          type="success"
          count={enrichedCompanies}
          text={t('Enrichment.CompaniesSuccesfullyEnriched', { count: enrichedCompanies })}
        />
      </Col>
      <Col col={12}>
        <CreditStatistic
          used={usedCredits}
          usedText={t('Enrichment.CreditsWillBeUsed', { count: usedCredits })}
          remaining={remainingCredits}
          remainingText={t('Enrichment.CreditsRemaining', { count: remainingCredits })}
        />
      </Col>
    </RowStyled>
  )
}
