import React from 'react'
import { NotificationEventContainer } from './NotificationEventContainer'
import { PushEventContainer } from './PushEventContainer'
import { useLocaleNumberFormatter } from '../../hooks/useLocaleNumberFormatter'

export const NotificationPopupContainer = () => {
  const { formatNumber } = useLocaleNumberFormatter()
  return (
    <>
      <NotificationEventContainer formatNumber={formatNumber} />
      <PushEventContainer />
    </>
  )
}
