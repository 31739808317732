import _ from 'lodash'
import i18next from 'i18next'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { changeLanguage } from '../../../../api/users'
import { Dialog } from '../../../../components/Dialog'
import { getAvailableLanguages } from '../../../../features/config/selectors'
import { getLanguage } from '../../../../features/user/selectors'
import { UserActions } from '../../../../features/user/actions'
import { ComponentProps } from '../../../../types'
import { Text } from '../../../../components/Text'
import { Radio } from '../../../../components/Radio'
import { HookRadioGroup, HookForm } from '../../../../components/Form'

type DialogProps = ComponentProps<typeof Dialog>

type Props = {
  close: () => void
}

type FormValues = {
  language: string
}

export const ChangeLanguageDialog = ({ close }: Props) => {
  const { t } = useTranslation(['account', 'common'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const userLanguage = useSelector(getLanguage)
  const availableLanguages = useSelector(getAvailableLanguages)

  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    defaultValues: {
      language: userLanguage || i18next.language
    }
  })

  const mutation = useMutation(() => changeLanguage(watch('language')), {
    onSuccess: () => {
      dispatch(UserActions.setUserLanguage(watch('language')))
      dispatch(UserActions.changeLanguage(watch('language'), true, location, navigate))
      close()
    }
  })

  const props: DialogProps = {
    loading: mutation.isLoading,
    visible: true,
    width: 900,
    title: t('account:Account.Language.ChangeLanguageTitle'),
    footer: '',
    onCancel: close,
    cancelButtonProps: { disabled: mutation.isLoading },
    cancelText: t('common:Cancel'),
    onOk: () => mutation.mutate(),
    okButtonProps: { disabled: mutation.isLoading },
    okText: t('common:Save')
  }

  return (
    <Dialog {...props}>
      <Text block margin={{ bottom: 20 }}>
        {t('Account.Language.ChangeLanguage')}
      </Text>
      <HookForm handleSubmit={handleSubmit} onSubmit={mutation.mutate} isSubmitting={formState.isSubmitting}>
        <HookRadioGroup name="language" control={control}>
          {_.map(_.toPairs(availableLanguages), ([key, langName]) => {
            return (
              <Radio key={key} value={key}>
                <Text primary bold>
                  {langName.full}
                </Text>
              </Radio>
            )
          })}
        </HookRadioGroup>
        {/* enables submit on enter */}
        <input type="submit" hidden />
      </HookForm>
    </Dialog>
  )
}
