import { SemanticSearchRequest } from '../../../api/filters/types'
import { CompoundExpression } from '../../../features/operations/types'

export type EnrichPortfolioDialogData = {
  portfolioId: string
  expression?: CompoundExpression
  semantic?: SemanticSearchRequest
  excludedPortfoliosIds?: string[]
}

export enum EnrichViewState {
  None = 'None',
  EnrichStatistic = 'EnrichStatistic',
  EnrichDone = 'EnrichDone'
}
