import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CancelToken } from 'axios'
import _ from 'lodash'
import { getSaveListOrderBy, getFunnelCounters, getSimilarityQuery, getTargetingFiltersForPortfolio } from './api'
import { FunnelCountersRequest } from './types'
import { cacheAllTheTime } from '../../features/queryclient'
import { FilterQueryDto } from '../filters/types'
import { SimilarityType } from '../../features/filters/types'

export const getProspectingKeys = {
  All: () => [{ level1: 'prospecting' }] as const,
  GetFunnelCounters: (data: FunnelCountersRequest) =>
    [{ ...getProspectingKeys.All()[0], level2: 'getFunnelCounters', data }] as const,
  OrderByOptions: () => [{ ...getProspectingKeys.All()[0], level2: 'useOrderByOptions' }] as const,
  ProspectingCompanies: () => [{ ...getProspectingKeys.All()[0], level2: 'getProspectingCompanies' }] as const,

  ProspectingMapCompanies: () => [{ ...getProspectingKeys.ProspectingCompanies()[0], level3: 'mapCompanies' }] as const,
  SimilarityQuery: (similarity: SimilarityType | undefined) => [
    { ...getProspectingKeys.All()[0], level2: 'similarityQuery', similarity }
  ],
  GetTargetingFiltersForPortfolio: (
    portfolioIds: string[] | undefined,
    companyStatus: string,
    cancelToken?: CancelToken
  ) =>
    [
      {
        ...getProspectingKeys.All()[0],
        level2: 'getTargetingFiltersForPortfolio',
        portfolioIds,
        companyStatus,
        cancelToken
      }
    ] as const
}

export const useGetFunnelCounters = (data: FunnelCountersRequest, enabled: boolean) =>
  useQuery(
    getProspectingKeys.GetFunnelCounters(data),
    ({ queryKey: [queryKeys] }) => getFunnelCounters(queryKeys.data).then(x => x.data),
    { enabled }
  )

export const useSaveListOrderByOptions = () => useQuery(getProspectingKeys.OrderByOptions(), getSaveListOrderBy)

export const useGetSimilarityQuery = (
  similarity: SimilarityType | undefined,
  options?: UseQueryOptions<
    FilterQueryDto | undefined,
    unknown,
    FilterQueryDto,
    ReturnType<typeof getProspectingKeys.SimilarityQuery>
  >
) => {
  return useQuery(
    getProspectingKeys.SimilarityQuery(similarity),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.similarity && queryKeys.similarity.portfolioId) {
        return getSimilarityQuery({
          similarityFilterPortfolioIds: [queryKeys.similarity.portfolioId],
          companyPortfolioStatus: queryKeys.similarity.status,
          data: queryKeys.similarity.data
        }).then(res => res.data)
      }
      return undefined
    },
    {
      ...cacheAllTheTime,
      ...options
    }
  )
}

export const useGetTargetingFiltersForPortfolioQuery = (
  portfolioIds: string[] | undefined,
  companyStatus: string,
  options?: UseQueryOptions<
    FilterQueryDto | undefined,
    unknown,
    FilterQueryDto,
    ReturnType<typeof getProspectingKeys.GetTargetingFiltersForPortfolio>
  >,
  cancelToken?: CancelToken
) => {
  return useQuery(
    getProspectingKeys.GetTargetingFiltersForPortfolio(portfolioIds, companyStatus, cancelToken),
    () => {
      if (portfolioIds === undefined || _.isEmpty(portfolioIds)) return undefined

      return getTargetingFiltersForPortfolio(portfolioIds, companyStatus, cancelToken).then(res => res.data)
    },
    {
      ...cacheAllTheTime,
      ...options
    }
  )
}
