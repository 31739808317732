import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DialogActions } from '../../features/dialog/actions'
import { getPortfolioDownloadKeys } from './queries'
import { shareCustomTrack, editCustomTrackDataPackets } from './api'

export const useShareCustomTrack = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { t } = useTranslation(['account'])

  const { mutate, isLoading } = useMutation(
    ({ customTrackId, isShared }: { customTrackId: string; isShared: boolean }) =>
      shareCustomTrack(customTrackId, isShared),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPortfolioDownloadKeys.All())
      },
      onError: () => {
        dispatch(
          DialogActions.openInfoDialog({
            title: t('PortfolioManagement.CustomTracks.ShareCustomTrackErrorDialog.Title'),
            text: t('PortfolioManagement.CustomTracks.ShareCustomTrackErrorDialog.Text')
          })
        )
      }
    }
  )

  return {
    mutate,
    isSubmitting: isLoading
  }
}

export const useEditCustomTrack = (back: () => void) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { t } = useTranslation(['account'])

  const { mutate, isLoading } = useMutation(
    ({
      customTrackId,
      dataPackets,
      jsonPaths
    }: {
      customTrackId: string
      dataPackets: string[]
      jsonPaths: string[]
    }) => editCustomTrackDataPackets(customTrackId, dataPackets, jsonPaths),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPortfolioDownloadKeys.All())
        back()
        dispatch(
          DialogActions.openInfoDialog({
            title: t('account:PortfolioManagement.CustomTracks.EditCustomTrack'),
            text: t('account:PortfolioManagement.CustomTracks.EditSuccessMessage')
          })
        )
      },
      onError: () => {
        dispatch(
          DialogActions.openInfoDialog({
            title: t('account:PortfolioManagement.CustomTracks.EditCustomTrack'),
            text: t('account:PortfolioManagement.CustomTracks.EditErrorMessage')
          })
        )
      }
    }
  )

  return {
    mutate,
    isSubmitting: isLoading
  }
}
