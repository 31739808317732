import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { AxiosError } from 'axios'
import { AppointmentTargetRequest, GetAppointmentsRequest, GetAppointmentTargetResponse } from './types'
import { getAppointments, getAppointmentsTarget, getAppointment } from './api'

export const getAppointmentKeys = {
  All: () => [{ level1: 'appointments' }] as const,

  GetAppointments: () => [{ ...getAppointmentKeys.All()[0], level2: 'getAppointments' }] as const,
  GetAppointmentsWithRequest: (request: GetAppointmentsRequest) =>
    [{ ...getAppointmentKeys.GetAppointments()[0], request }] as const,

  GetAppointmentsTarget: ({ matchingTerm, companyUnitId, countryCode }: AppointmentTargetRequest) =>
    [
      { ...getAppointmentKeys.All()[0], level2: 'getAppointmentsTarget', matchingTerm, companyUnitId, countryCode }
    ] as const,

  GetAppointment: (appointmentId: string) =>
    [{ ...getAppointmentKeys.All()[0], level2: 'getAppointment', appointmentId }] as const
}

export const useGetAppointments = (request: GetAppointmentsRequest) => {
  const { isLoading, isFetchingNextPage, data, hasNextPage, fetchNextPage } = useInfiniteQuery(
    getAppointmentKeys.GetAppointmentsWithRequest(request),
    ({ queryKey: [queryKeys], pageParam = 0 }) => getAppointments(queryKeys.request, pageParam),
    {
      staleTime: 60 * 5000, // to prevent unnecessary fetch when going back from detail/edit to list
      getNextPageParam: (lastResp, allPages) => {
        const count = _.sum(_.map(allPages, p => p.appointments.length))
        return (lastResp?.totalCount ?? 0) <= count ? undefined : count
      }
    }
  )

  return {
    data: _.flatMap(_.compact(data?.pages), p => p.appointments),
    totalCount: _.last(data?.pages)?.totalCount ?? 0,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  }
}

export const useGetAppointmentsTarget = (
  { matchingTerm, companyUnitId, countryCode }: AppointmentTargetRequest,
  disabled: boolean | undefined,
  onSuccess: (resp: GetAppointmentTargetResponse) => void,
  onError: (error: AxiosError, matchingTerm: string | undefined) => void
) =>
  useQuery(
    getAppointmentKeys.GetAppointmentsTarget({
      matchingTerm: companyUnitId ? undefined : matchingTerm,
      companyUnitId,
      countryCode
    }),
    ({ queryKey: [queryKeys] }) =>
      getAppointmentsTarget(queryKeys.matchingTerm, queryKeys.companyUnitId, queryKeys.countryCode),
    {
      enabled: !disabled && !!((matchingTerm && countryCode) || companyUnitId),
      onSuccess,
      onError: (error: AxiosError) => onError(error, matchingTerm)
    }
  )

export const useGetAppointment = (appointmentId: string, onError?: () => void) =>
  useQuery(
    getAppointmentKeys.GetAppointment(appointmentId),
    ({ queryKey: [queryKeys] }) => getAppointment(queryKeys.appointmentId),
    {
      onError
    }
  )
