import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '../../../components/Dialog/Dialog'
import { Text } from '../../../components/Text'
import { DownloadProposal } from '../../../api/portfolioDownload/types'
import { EnrichDoneStatistic } from '../../../components/Statistic/EnrichDoneStatistic'

type Props = {
  close: () => void
  downloadProposal: DownloadProposal
}

export const DownloadPortfolioDialog = ({ close, downloadProposal }: Props) => {
  const { t } = useTranslation(['portfolio', 'common', 'error'])
  const {
    selectedNotEnrichedCompaniesCount,
    requiredCreditsToEnrichPortfolioCompanies,
    remainingCreditsAfterPortfolioCompaniesEnrichment,
    savedCustomTrackName
  } = downloadProposal
  return (
    <Dialog
      visible
      footer
      title={t('Actions.Download')}
      okText={t('common:Ok')}
      onOk={close}
      onCancel={close}
      loading={false}
      width={900}
    >
      <Text size="L" block text={t('portfolio:Download.Dialog1')} />
      <Text size="L" block margin={{ bottom: 20 }} text={t('portfolio:Download.Dialog2')} />
      <EnrichDoneStatistic
        enrichedCompanies={requiredCreditsToEnrichPortfolioCompanies === 0 ? 0 : selectedNotEnrichedCompaniesCount}
        usedCredits={requiredCreditsToEnrichPortfolioCompanies}
        remainingCredits={remainingCreditsAfterPortfolioCompaniesEnrichment}
      />
      {savedCustomTrackName !== undefined && (
        <div className="text">
          <Text size="M" type="primary" bold block margin={{ top: 20 }}>
            &quot;{savedCustomTrackName}&quot; {t('portfolio:Download.SaveSuccess')}
          </Text>
          <Text size="M" block>
            {t('portfolio:Download.SaveSuccessDescription')}
          </Text>
        </div>
      )}
    </Dialog>
  )
}
