import styled from 'styled-components/macro'
import { CenteredRow } from '../Grid'
import { NavProps as ImportedNavProps } from './WizardNav'

export { Wizard } from './Wizard'
export { WizardNav } from './WizardNav'

export type NavProps = ImportedNavProps

export const WizardNavigationWrapper = styled(CenteredRow)`
  &&& {
    margin-top: 40px !important;
  }
`
