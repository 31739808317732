/* eslint-disable import/no-default-export */
export default {
  Support: {
    Email: 'info@crif.com',
    Phone: '+39 051 4176111'
  },
  PrivacyPolicy: 'Informativa sulla privacy',
  CookiesPolicy: 'Informativa sul utilizzo dei Cookies',
  Contact: 'Contattaci'
}
