import { TFunction } from 'i18next'
import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { Trans } from 'react-i18next'
import { generatePath, matchPath, Location, NavigateFunction } from 'react-router-dom'
import { Dispatch } from 'redux'
import { companyReportKeys } from '../api/companyReport/common'
import { getCompanyKeys } from '../api/companies'
import { getPortfolioKeys } from '../api/portfolio'
import { getProspectingKeys } from '../api/prospecting'
import { getOperationsKeys } from '../api/operations'
import { getSalestoolKeys } from '../api/salestool'
import { getFiltersKeys } from '../api/filters'
import { FilterStructureModuleRequest } from '../api/filters/types'
import { getLatestDocumenstWidgetPageSize } from '../features/config/selectors'
import { FileActions } from '../features/files/actions'
import { Notification, PushEvent } from '../features/notifications/types'
import { queryClient } from '../features/queryclient'
import { SalestoolTableActions } from '../features/salestool/table/actions'
import { RootState } from '../features/types'
import { getUserId } from '../features/user/selectors'
import * as ROUTES from '../routes'
import { toLocalizedDateFormat } from './formatters'
import { generateRefreshUrl } from './helpers'
import { FormatNumberFunction } from './types'
import {
  getHasRingsWarningFromLocalStorageForUser,
  setHasRingsWarningToLocalStorageForUser
} from '../components/Alert/utils'
import { ApplicationModule } from '../types'
import { getDashboardKeys } from '../api/dashboard/queries'
import { DocumentType } from '../features/documents/types'

const possibleNotificationTypes: Notification['type'][] = [
  'PM001',
  'PM002',
  'PM003',
  'PM004',
  'PM005',
  'PM006',
  'PM007',
  'PM008',
  'PM009',
  'PM010',
  'PM011',
  'PM012',
  'PM013',
  'PM014',
  'PM014_0',
  'PM015',
  'PM015_0',
  'PM017',
  'PM017_0',
  'PM019',
  'PM020',
  'PM021',
  'PM022',
  'PM023',
  'PM024',
  'PM025',
  'PM026',
  'PM027',
  'LL001',
  'ST001',
  'ST002',
  'ST003',
  'ST004',
  'ST005',
  'ST006',
  'ST007',
  'ST009',
  'ST010',
  'ST011',
  'PR001',
  'PR002',
  'PR003',
  'PR004',
  'PR005',
  'PR006',
  'PR007',
  'TR001',
  'TR002',
  'TA001',
  'TA002',
  'DO002',
  'OP001',
  'OP002',
  'OP003',
  'OP004'
]

export function notificationText(t: TFunction, notification: Notification, formatNumber: FormatNumberFunction) {
  if (_.includes(possibleNotificationTypes, notification.type)) {
    switch (notification.type) {
      case 'PM001':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          count: notification.data.numberOfUploadedCompanies
        })
      case 'PM003':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          portfolioName: notification.data.portfolioName ? `${notification.data.portfolioName}: ` : '',
          count: notification.data.ceasedUnmatched
        })
      case 'PM004':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          portfolioName: notification.data.portfolioName ? `${notification.data.portfolioName}: ` : '',
          count: notification.data.rejected
        })
      case 'PM009':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          count: notification.data.movedCompanies,
          notCount: notification.data.notMovedCompanies
        })
      case 'PM005':
        return t(`notification:${notification.type}`, {
          exportType: t(`portfolio:Download.${notification.data.exportType}`),
          portfolioName: notification.data.portfolioName
        })
      case 'PM027':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          portfolioName: notification.data.portfolioName ? `${notification.data.portfolioName}: ` : '',
          count: notification.data.invalidCompanies.length
        })
      case 'PR006':
        return (
          <Trans
            i18nKey="notification:PR006"
            components={[<b key="portfolio-name" />]}
            values={{
              portfolioName: notification.data.portfolioName,
              exclusionWarning:
                notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : ''
            }}
          />
        )
      case 'PR003':
      case 'PR007':
      case 'TR001':
      case 'PM010':
      case 'PM019':
      case 'ST001':
      case 'ST002':
      case 'ST003':
      case 'ST009':
      case 'ST010':
      case 'ST011':
      case 'LL001':
      case 'OP001':
      case 'OP002':
      case 'OP004':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          count: notification.data.numberOfCompanies
        })
      case 'OP003':
        return (
          <Trans
            i18nKey="notification:OP003"
            components={[<b key="portfolio-name" />]}
            values={{
              portfolioName: notification.data.portfolioName,
              exclusionWarning:
                notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : ''
            }}
          />
        )
      case 'ST004':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          startDate: toLocalizedDateFormat(notification.data.startDate)
        })
      case 'ST005':
        return t(`notification:${notification.type}`, {
          ...notification.data,
          endDate: toLocalizedDateFormat(notification.data.endDate)
        })
      case 'PM013':
        return t(`notification:${notification.type}`, {
          ...notification.data
        })
      case 'DO002':
        return t(`notification:${notification.type}`, {
          ...(notification.data?.pdfExportData ?? {})
        })
      case 'PM014': {
        const part1 = t(`notification:${notification.type}Part1`, {
          ...notification.data,
          count: notification.data.numberOfCompanies
        })
        const part2 = t(`notification:${notification.type}Part2`, {
          ...notification.data,
          count: notification.data.numberOfDuplicates
        })

        return notification.data.numberOfDuplicates > 0 ? part1.concat(part2) : part1
      }
      default: {
        const data = _.reduce(
          notification.data,
          (acc, value, key) => {
            if (_.isFinite(value)) _.set(acc, key, formatNumber(value))
            return acc
          },
          {
            ...notification.data
          }
        )

        return t(`notification:${notification.type}`, { ...data })
      }
    }
  }
  return t('notification:MissingMessage', { type: notification.type })
}

export function notificationSection(t: TFunction, notification: Notification | PushEvent) {
  const tWithNamespace = (key: string) => t(`notification:${key}`)
  // fallthrough intentional
  switch (notification.type) {
    case 'PM001':
    case 'PM002':
    case 'PM003':
    case 'PM004':
    case 'PM005':
    case 'PM006':
    case 'PM007':
    case 'PM008':
    case 'PM009':
    case 'PM010':
    case 'PM011':
    case 'PM012':
    case 'PM013':
    case 'PM014':
    case 'PM015':
    case 'PM017':
    case 'PM014_0':
    case 'PM015_0':
    case 'PM017_0':
    case 'PM020':
    case 'PM021':
    case 'PM022':
    case 'PM023':
    case 'PM024':
    case 'PM025':
    case 'PM026':
    case 'PM027':
    case 'LL001':
    case 'UploadPortfolioProgress':
    case 'DuplicatePortfolioProgress':
    case 'ExportPortfolioProgress':
    case 'DeleteCompanyFromPortfolioProgress':
    case 'MoveFromPortfolioSelectionProgress':
    case 'CreatePortfolioFromSelectionProgress':
    case 'EnrichCompanyFromPortfolioProgress':
    case 'ShareCompaniesProgress':
    case 'UnshareCompaniesProgress':
    case 'AssignSalesProgress':
    case 'AssignAreaManagerProgress':
    case 'AssignProductProgress':
    case 'AddLocalUnitsToPortfolioProgress':
      return tWithNamespace('Portfolio')
    case 'PM019':
    case 'ST001':
    case 'ST002':
    case 'ST003':
    case 'ST004':
    case 'ST005':
    case 'ST006':
    case 'ST007':
    case 'ST009':
    case 'ST010':
    case 'ST011':
    case 'CreateCampaignProgress':
    case 'AssignCampaignProgress':
    case 'SetCompaniesTaskProgress':
    case 'ExportCampaignProgress':
    case 'AddPortfolioTaskProgress':
    case 'UpdateCampaignByPortfolioTaskProgress':
      return tWithNamespace('SalesTool')
    case 'PR001':
    case 'PR002':
    case 'PR003':
    case 'PR004':
    case 'PR005':
    case 'PR006':
    case 'PR007':
    case 'ProspectingSaveListProgress':
    case 'ProspectingUpdateListProgress':
      return tWithNamespace('Prospecting')
    case 'TA001':
    case 'TA002':
    case 'TR001':
    case 'TR002':
    case 'TargetingSaveListProgress':
    case 'TargetingUpdateListProgress':
      return tWithNamespace('Targeting')
    case 'DO002':
    case 'PdfGenerateProgress':
      return tWithNamespace('DownloadPDF')
    case 'OP001':
    case 'OP002':
    case 'OP003':
    case 'OP004':
      return tWithNamespace('Operations')
    default:
      return 'For this notification type there is no message'
  }
}

export function notificationActionUrl(notification: Notification | PushEvent): Partial<Location> | undefined {
  // fallthrough intentional
  switch (notification.type) {
    case 'PM001':
    case 'PM011':
    case 'PR001':
    case 'PR002':
    case 'PR003':
    case 'PR004':
    case 'PR005':
    case 'PR006':
    case 'PR007':
    case 'TR001':
    case 'TR002':
    case 'PM007':
    case 'PM014':
    case 'PM015':
    case 'PM017':
    case 'PM014_0':
    case 'PM015_0':
    case 'PM017_0':
    case 'PM021':
    case 'PM023':
    case 'PM024':
    case 'LL001':
    case 'OP001':
    case 'OP002':
    case 'OP003':
      return {
        pathname: generatePath(ROUTES.PORTFOLIO, { portfolioId: notification.data.portfolioId }),
        state: { resetFilters: true }
      }
    case 'PM022':
      return notification.data.isPortfolioShared
        ? {
            pathname: generatePath(ROUTES.PORTFOLIO, { portfolioId: notification.data.portfolioId }),
            state: { resetFilters: true }
          }
        : undefined
    case 'PM009':
      return {
        pathname: generatePath(ROUTES.PORTFOLIO, { portfolioId: notification.data.targetPortfolioId }),
        state: { resetFilters: true }
      }
    case 'PM019':
    case 'ST001':
    case 'ST003':
    case 'ST004':
    case 'ST005':
    case 'ST006':
    case 'ST009':
    case 'ST010':
    case 'ST011':
      return { pathname: generatePath(ROUTES.SALESTOOL_CAMPAIGN_DETAIL, { campaignId: notification.data.campaignId }) }
    case 'PM002':
    case 'PM003':
    case 'PM004':
    case 'PM005':
    case 'PM006':
    case 'TA001':
    case 'TA002':
      return { pathname: ROUTES.ACCOUNT_PORTFOLIO_MANAGEMENT }
    case 'PM027':
      return {
        pathname: ROUTES.MANAGE_UPLOAD_ERRORS,
        state: {
          documentId: notification.data.documentId,
          portfolioId: notification.data.portfolioId,
          invalidCompanies: notification.data.invalidCompanies
        }
      }
    case 'ST007':
      // GLOB-2923: workaround until GLOB-204 is finished
      return window.location
    case 'DO002':
      return { pathname: '#' }
    case 'PM008':
    case 'PM012':
    case 'ST002':
    case 'UploadPortfolioProgress':
    case 'DuplicatePortfolioProgress':
    case 'ExportPortfolioProgress':
    default:
      return undefined
  }
}

export function notificationActionCallback(
  notification: Notification,
  dispatch: Dispatch,
  navigate: NavigateFunction,
  route?: Partial<Location>
) {
  switch (notification.type) {
    case 'PM003':
    case 'PM004':
    case 'PM005':
    case 'ST007':
    case 'DO002': {
      const { documentId } = notification.data
      dispatch(FileActions.downloadDocument(documentId, navigate))
      break
    }
    default:
  }
  if (route) {
    navigate(
      {
        pathname: route.pathname,
        search: route.search
      },
      { state: route.state }
    )
  }
}

const changeHandlerPortfolioData = (portfolioId: string) => {
  queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(portfolioId))
  queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(portfolioId))
}
const debouncePortfolioData = _.debounce(portfolioId => changeHandlerPortfolioData(portfolioId), 2000)

const changeHandlerPortfolioFetch = (dispatch: Dispatch, portfolioId: string) => {
  queryClient.invalidateQueries(
    getFiltersKeys.FilterStructureForModuleAndEntity(FilterStructureModuleRequest.PortfolioManagement, portfolioId)
  )
}

const handleHasRingsWarning = (hasRingsWarningValue: boolean, userId: string) => {
  if (hasRingsWarningValue) {
    const hasRingsWarning = getHasRingsWarningFromLocalStorageForUser(userId)
    if (hasRingsWarning) {
      if (moment() > moment(hasRingsWarning.expiration)) {
        setHasRingsWarningToLocalStorageForUser(hasRingsWarningValue, userId)
      }
    } else {
      setHasRingsWarningToLocalStorageForUser(hasRingsWarningValue, userId)
    }
  }
}

export const debouncePortfolioFetch = _.debounce(
  (dispatch, portfolioId) => changeHandlerPortfolioFetch(dispatch, portfolioId),
  2000
)

export function notificationCallback(
  notification: Notification,
  dispatch: Dispatch,
  navigate: NavigateFunction,
  location: Location,
  state: RootState
): void {
  // fallthrough intentional
  switch (notification.type) {
    case 'PM014':
    case 'PM015':
    case 'PM017':
    case 'PM014_0':
    case 'PM015_0':
    case 'PM017_0':
      queryClient.invalidateQueries(getFiltersKeys.DomainNodesData())
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.portfolioId))
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(notification.data.portfolioId))
      break
    case 'PM007':
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(notification.data.portfolioId))
      break
    case 'PM008':
      if (location.pathname === generatePath(ROUTES.PORTFOLIO, { portfolioId: notification.data.portfolioId })) {
        queryClient.invalidateQueries(getFiltersKeys.DomainNodesData())
      }
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.portfolioId))
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(notification.data.portfolioId))
      break
    case 'PM009': {
      const userId = getUserId(state)

      queryClient.resetQueries(getPortfolioKeys.PortfolioById(notification.data.sourcePortfolioId))
      queryClient.resetQueries(getPortfolioKeys.PortfolioById(notification.data.targetPortfolioId))
      queryClient.invalidateQueries(
        getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.sourcePortfolioId)
      )
      queryClient.invalidateQueries(
        getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.targetPortfolioId)
      )
      handleHasRingsWarning(notification.data.hasRingsWarning, userId)
      break
    }
    case 'OP004':
    case 'PM010':
    case 'PM020': {
      const { data } = notification
      const { pathname } = location
      const userId = getUserId(state)

      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(data.portfolioId))
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(data.portfolioId))
      queryClient.invalidateQueries(getOperationsKeys.OperationsCompanies())

      if (pathname === generatePath(ROUTES.PORTFOLIO, { portfolioId: data.portfolioId })) {
        queryClient.invalidateQueries(
          getFiltersKeys.FilterStructureForModuleAndEntity(
            FilterStructureModuleRequest.PortfolioManagement,
            data.portfolioId
          )
        )
      }
      handleHasRingsWarning(notification.data.hasRingsWarning, userId)
      break
    }
    case 'PM021':
    case 'PM022':
    case 'PM023':
    case 'PM024': {
      const { data, type } = notification
      const { pathname } = location

      if (notification.type === 'PM021') {
        queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      }
      if (notification.type === 'PM022' && !notification.data.isPortfolioShared) {
        queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      } else if (type === 'PM021') {
        queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      }

      if (pathname === generatePath(ROUTES.PORTFOLIO, { portfolioId: data.portfolioId })) {
        if (notification.type === 'PM022' && !notification.data.isPortfolioShared) {
          queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
          navigate({ pathname: ROUTES.PORTFOLIO_MANAGEMENT })
        } else {
          debouncePortfolioFetch(dispatch, data.portfolioId)
        }
      }

      debouncePortfolioData(data.portfolioId)

      break
    }
    case 'PM025':
    case 'PM026': {
      const { data } = notification
      const { pathname } = location

      if (data.portfolioId && pathname === generatePath(ROUTES.PORTFOLIO, { portfolioId: data.portfolioId })) {
        queryClient.invalidateQueries(
          getFiltersKeys.FilterStructureForModuleAndEntity(
            FilterStructureModuleRequest.PortfolioManagement,
            data.portfolioId
          )
        )
      } else if (!data.portfolioId && matchPath({ path: ROUTES.PORTFOLIO }, pathname)) {
        navigate({ pathname: generateRefreshUrl(pathname) })
      }
      if (data.portfolioId) {
        queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(data.portfolioId))
        queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(data.portfolioId))
      }
      break
    }
    case 'PM001':
    case 'PM005':
    case 'LL001': {
      const { data } = notification
      const { pathname } = location

      if (pathname === generatePath(ROUTES.PORTFOLIO, { portfolioId: data.portfolioId })) {
        queryClient.invalidateQueries(
          getFiltersKeys.FilterStructureForModuleAndEntity(
            FilterStructureModuleRequest.PortfolioManagement,
            data.portfolioId
          )
        )
      }

      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(data.portfolioId))
      if (notification.type === 'PM001') {
        queryClient.invalidateQueries(getPortfolioKeys.Options())
        queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      }
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(data.portfolioId))
      break
    }
    case 'PM012': {
      const { data } = notification

      queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      queryClient.resetQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(data.portfolioId))
      break
    }
    case 'PM013': {
      const { data } = notification
      const { pathname } = location

      queryClient.resetQueries(getDashboardKeys.GetUserDashboard())

      if (pathname === generatePath(ROUTES.PORTFOLIO, { portfolioId: data.portfolioId })) {
        navigate({ pathname: ROUTES.PORTFOLIO_MANAGEMENT })
      }
      queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.portfolioId))
      break
    }
    case 'PR002':
    case 'PR007':
    case 'TR002': {
      const userId = getUserId(state)
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(notification.data.portfolioId), {
        refetchType: 'all'
      })
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.portfolioId), {
        refetchType: 'all'
      })
      handleHasRingsWarning(notification.data.hasRingsWarning, userId)
      break
    }
    case 'PR003':
    case 'PR006':
    case 'TR001':
    case 'OP001':
    case 'OP002':
    case 'OP003': {
      const { pathname } = location
      const userId = getUserId(state)

      if (pathname === ROUTES.PROSPECTING_ANALYSIS || pathname === ROUTES.SEARCH) {
        queryClient.invalidateQueries(getProspectingKeys.ProspectingCompanies())
        queryClient.invalidateQueries(getCompanyKeys.CompaniesByQueryFilter(ApplicationModule.Prospecting))
      }

      const pathMatch = matchPath({ path: ROUTES.COMPANY_REPORT, end: true }, pathname)
      if (pathMatch) {
        const { identificationCode, companyUnitId } = pathMatch.params
        queryClient.invalidateQueries(getProspectingKeys.ProspectingCompanies())
        queryClient.invalidateQueries(getCompanyKeys.CompaniesByQueryFilter(ApplicationModule.Prospecting))
        if (identificationCode && companyUnitId)
          queryClient.invalidateQueries(companyReportKeys.reportDataForCompany(identificationCode, companyUnitId))
      }
      queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      queryClient.invalidateQueries(getOperationsKeys.OperationsCompanies())
      queryClient.invalidateQueries(getFiltersKeys.DomainCodesTree(), { refetchType: 'all' })
      handleHasRingsWarning(notification.data.hasRingsWarning, userId)
      break
    }
    case 'PM019':
    case 'ST001': {
      queryClient.invalidateQueries(getFiltersKeys.DomainCodesTree(), { refetchType: 'all' })
      queryClient.invalidateQueries(getSalestoolKeys.GetCampaigns())
      break
    }
    case 'ST003':
    case 'ST009':
    case 'ST011': {
      const { data } = notification
      const { pathname } = location

      const assignmentsPath = generatePath(ROUTES.SALESTOOL_CAMPAIGN_ASSIGN, { campaignId: data.campaignId })

      queryClient.invalidateQueries(getSalestoolKeys.GetCampaignCompaniesForCampaignId(data.campaignId))
      queryClient.invalidateQueries(getSalestoolKeys.GetCampaign(data.campaignId))

      if (pathname === assignmentsPath) {
        queryClient.invalidateQueries(getFiltersKeys.DomainNodesData())
        queryClient.invalidateQueries(
          getFiltersKeys.FilterStructureForModuleAndEntity(FilterStructureModuleRequest.SalesTool, data.campaignId)
        )
        dispatch(SalestoolTableActions.clearPendingAssignments())
      }

      break
    }
    case 'ST010':
    case 'ST006': {
      const { data } = notification
      const { pathname } = location

      queryClient.invalidateQueries(getSalestoolKeys.GetCampaignStats(data.campaignId))
      const assignmentsPath = generatePath(ROUTES.SALESTOOL_CAMPAIGN_ASSIGN, { campaignId: data.campaignId })
      if (pathname === assignmentsPath) {
        queryClient.invalidateQueries(getFiltersKeys.DomainNodesData())
        queryClient.invalidateQueries(
          getFiltersKeys.FilterStructureForModuleAndEntity(FilterStructureModuleRequest.SalesTool, data.campaignId)
        )
        dispatch(SalestoolTableActions.clearPendingAssignments())
      }
      break
    }

    case 'PM003':
    case 'PM004': {
      const { pathname } = location
      const top = getLatestDocumenstWidgetPageSize(state)
      const userId = getUserId(state)
      if (pathname === ROUTES.DASHBOARD) {
        queryClient.invalidateQueries(
          getDashboardKeys.GetInfiniteDocuments(userId, top, DocumentType.ExportedPortfolio)
        )
      } else {
        queryClient.resetQueries(getDashboardKeys.GetInfiniteDocuments(userId, top, DocumentType.ExportedPortfolio))
      }
      break
    }
    case 'PR001':
    case 'PR004':
    case 'PR005': {
      const userId = getUserId(state)

      queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(notification.data.portfolioId))
      queryClient.invalidateQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(notification.data.portfolioId))
      queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios())
      queryClient.invalidateQueries(getOperationsKeys.OperationsCompanies())
      handleHasRingsWarning(notification.data.hasRingsWarning, userId)
      break
    }
    case 'PM002':
    case 'PM006':
    case 'PM011':
    case 'ST002':
    case 'TA001':
    case 'TA002':
    default:
    // do nothing
  }
}
