import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Progress } from 'antd3'
import * as Drawer from './DrawerStyledComponents'
import { PushEvent } from '../../features/notifications/types'
import { NotificationTitle, NotificationTextStyled } from './NotificationPopup/Progress'
import { NotificationSection } from './NotificationSection'
import { theme } from '../../utils/theme'

const isFinished = (progressNumber: number) => progressNumber >= 100

type ProgressData = {
  key: string
  progressNumber: number
  message: string
}

type Props = {
  pushEvent: PushEvent
}

export const getProgressData = (pushEvent: PushEvent, t: TFunction): ProgressData | null => {
  switch (pushEvent.type) {
    case 'UploadPortfolioProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.UploadProgress')
          : t('notification:PushEvent.UploadProgressFinished')
      }
    case 'DeleteCompanyFromPortfolioProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.DeleteCompaniesProgress')
          : t('notification:PushEvent.DeleteCompaniesProgressFinished')
      }
    case 'MoveFromPortfolioSelectionProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.MoveCompaniesProgress')
          : t('notification:PushEvent.MoveCompaniesProgressFinished')
      }
    case 'EnrichCompanyFromPortfolioProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.EnrichCompaniesProgress')
          : t('notification:PushEvent.EnrichCompaniesProgressFinished')
      }
    case 'CreatePortfolioFromSelectionProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.CreatePortfolioFromSelectionProgress')
          : t('notification:PushEvent.CreatePortfolioFromSelectionProgressFinished')
      }
    case 'DuplicatePortfolioProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.DuplicateProgress')
          : t('notification:PushEvent.DuplicateProgressFinished')
      }
    case 'ShareCompaniesProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.ShareCompaniesProgress')
          : t('notification:PushEvent.ShareCompaniesProgressFinished')
      }
    case 'UnshareCompaniesProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.UnshareCompaniesProgress')
          : t('notification:PushEvent.UnshareCompaniesProgressFinished')
      }
    case 'AssignSalesProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.AssignSalesProgress')
          : t('notification:PushEvent.AssignSalesProgressFinished')
      }
    case 'AssignAreaManagerProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.AssignAreaManagerProgress')
          : t('notification:PushEvent.AssignAreaManagerProgressFinished')
      }
    case 'AssignProductProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.AssignProductProgress')
          : t('notification:PushEvent.AssignProductProgressFinished')
      }
    case 'ExportPortfolioProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.ExportPortfolioProgress', { portfolioName: pushEvent.data.portfolioName })
          : t('notification:PushEvent.ExportPortfolioProgressFinished', {
              portfolioName: pushEvent.data.portfolioName
            })
      }
    case 'ProspectingSaveListProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.ProspectingSaveListProgress')
          : t('notification:PushEvent.ProspectingSaveListProgressFinished')
      }
    case 'ProspectingUpdateListProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.ProspectingUpdateListProgress')
          : t('notification:PushEvent.ProspectingUpdateListProgressFinished')
      }
    case 'TargetingSaveListProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.TargetingSaveListProgress')
          : t('notification:PushEvent.TargetingSaveListProgressFinished')
      }
    case 'TargetingUpdateListProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.TargetingUpdateListProgress')
          : t('notification:PushEvent.TargetingUpdateListProgressFinished')
      }
    case 'CreateCampaignProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.CreateCampaignProgress')
          : t('notification:PushEvent.CreateCampaignProgressFinished')
      }
    case 'AssignCampaignProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.AssignCampaignProgress')
          : t('notification:PushEvent.AssignCampaignProgressFinished')
      }
    case 'SetCompaniesTaskProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.SetCompaniesTaskProgress')
          : t('notification:PushEvent.SetCompaniesTaskProgressFinished')
      }
    case 'AddPortfolioTaskProgress':
    case 'UpdateCampaignByPortfolioTaskProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.UpdateCampaignTaskProgress')
          : t('notification:PushEvent.UpdateCampaignTaskProgressFinished')
      }
    case 'ExportCampaignProgress':
      return {
        key: `${pushEvent.id}&${pushEvent.data.containerId}`,
        progressNumber: pushEvent.data.progress,
        message: !isFinished(pushEvent.data.progress)
          ? t('notification:PushEvent.ExportCampaignProgress')
          : t('notification:PushEvent.ExportCampaignProgressFinished')
      }
    case 'PdfGenerateProgress':
      return {
        key: `${pushEvent.id}`,
        progressNumber: -1,
        message: t('notification:PushEvent.PdfGenerateProgress', { ...pushEvent.data })
      }
    case 'AddLocalUnitsToPortfolioProgress':
      return {
        key: pushEvent.id,
        progressNumber: pushEvent.data.progress,
        message: t('notification:PushEvent.AddLocalUnitsToPortfolioProgress')
      }
    default:
      return null
  }
}

export const DrawerNotificationProgress = ({ pushEvent }: Props) => {
  const { t } = useTranslation()
  const progressData = getProgressData(pushEvent, t)
  if (!progressData) return null
  const finished = isFinished(progressData.progressNumber)
  const isWithPercentage = progressData.progressNumber !== -1
  return (
    <Drawer.NotificationWrapper>
      <Drawer.DescriptionWrapper>
        <Drawer.NotificationDate notification={pushEvent} />
        <NotificationSection t={t} notification={pushEvent} type={Drawer.TEXT_COLOR} bold block />
        {isWithPercentage ? (
          <Drawer.ProgressMessageWrapper>
            {finished && <Drawer.CheckIcon />}
            <NotificationTitle>{`${progressData.progressNumber}%`}</NotificationTitle>
            <NotificationTextStyled>{progressData.message}</NotificationTextStyled>
          </Drawer.ProgressMessageWrapper>
        ) : (
          <NotificationTextStyled>{progressData.message}</NotificationTextStyled>
        )}

        {isWithPercentage ? (
          <Progress
            status="active"
            showInfo={false}
            percent={progressData.progressNumber}
            strokeColor="#ffffff"
            strokeWidth={4}
          />
        ) : (
          <Progress
            status="active"
            showInfo={false}
            percent={100}
            strokeColor={{
              from: theme.colors.main,
              to: theme.colors.mainPressed
            }}
            strokeWidth={4}
          />
        )}
      </Drawer.DescriptionWrapper>
      <Drawer.NoIcon />
    </Drawer.NotificationWrapper>
  )
}
