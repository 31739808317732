import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../Text'
import { InfoWrapper } from './styled'

export const CompanyPopupNotFound: React.FC = () => {
  const { t } = useTranslation('companyReport')

  return (
    <InfoWrapper>
      <Text text={t('CompanyPopup.Title')} size="L" padding="15px 0 0 0" block primary />
      <Text text={t('CompanyPopup.CompanyNotFound')} size="M" padding="15px 0 0 0" block />
    </InfoWrapper>
  )
}
