import axios, { CancelToken } from 'axios'
import { EnrichPortfolioSelectionParams } from '../../containers/Dialog/EnrichCompanies/types'
import {
  CompanyEnrichmentProposalRequest,
  CompanyEnrichmentProposalResponse,
  CompanyUnitEnrichmentProposalResponse,
  PortfolioEnrichmentProposalRequest,
  PortfolioEnrichmentProposalResponse,
  SelectionEnrichmentRequest,
  TargetActionType,
  EnrichmentResp,
  NewPortfolioEnrichmentRequest,
  ExistingPortfolioEnrichmentRequest
} from './types'
import { EnrichCompaniesResponse } from '../companies/types'
import { ApplicationModule } from '../../types'

export const enrichSingleCompanyInExistingPortfolio = (
  companyUnitId: string,
  alreadyExistingPortfolioId?: string,
  applicationModule?: ApplicationModule
) => {
  return axios.post<EnrichmentResp>(
    '/api/ProspectingActions/enrich-single-in-existing-portfolio',
    {
      alreadyExistingPortfolioId,
      companyUnitId,
      applicationModule
    },
    { allowedErrorStatuses: true }
  )
}

export const enrichSingleCompanyInNewPortfolio = (
  companyUnitId: string,
  targetPortfolioName: string,
  applicationModule?: ApplicationModule
) => {
  return axios.post<EnrichmentResp>(
    '/api/ProspectingActions/enrich-single-into-new-portfolio',
    {
      targetPortfolioName,
      companyUnitId,
      applicationModule
    },
    { allowedErrorStatuses: true }
  )
}

export const enrichSingleCompany = (
  companyUnitId: string,
  targetPortfolioId?: string,
  portfolioName?: string,
  isAlreadyInPortfolio = false,
  applicationModule?: ApplicationModule
) => {
  if (isAlreadyInPortfolio || targetPortfolioId)
    return enrichSingleCompanyInExistingPortfolio(companyUnitId, targetPortfolioId, applicationModule)

  if (portfolioName) return enrichSingleCompanyInNewPortfolio(companyUnitId, portfolioName, applicationModule)

  return Promise.reject(
    new Error('Portfolio is not in portfolio, and portfolionName or targetPortfolioId was not specified')
  )
}

export const enrichMultipleCompany = (data: EnrichPortfolioSelectionParams) => {
  const {
    isSelectAll,
    companyUnitsToEnrich,
    companyUnitsToIgnore,
    portfolioId,
    semantic,
    expression,
    excludedPortfoliosIds
  } = data
  return axios.post('/api/PortfolioActions/enrich-from-portfolio-selection', {
    selection: {
      portfolioGridQuery: isSelectAll ? { semantic, query: expression, excludedPortfoliosIds } : undefined,
      targetActionType: 'None',
      selectedItems: !isSelectAll ? companyUnitsToEnrich : undefined,
      itemsToIgnore: isSelectAll ? companyUnitsToIgnore : undefined,
      sourceContainerItemId: portfolioId,
      destinationContainerItemId: portfolioId
    }
  } as SelectionEnrichmentRequest)
}

export const getPortfolioEnrichmentProposal = (
  portfolioId: string,
  data: PortfolioEnrichmentProposalRequest,
  cancelToken?: CancelToken
) =>
  axios.post<PortfolioEnrichmentProposalResponse>(
    `/api/PortfolioEnrichment/portfolio/${portfolioId}/enrichment-proposal`,
    data,
    { cancelToken }
  )

export const enrichPortfolio = (portfolioId: string, enrichmentType: TargetActionType = TargetActionType.None) => {
  const data: SelectionEnrichmentRequest = {
    selection: {
      portfolioGridQuery: {
        query: undefined,
        semantic: undefined,
        excludedPortfoliosIds: undefined
      },
      sourceContainerItemId: portfolioId,
      destinationContainerItemId: portfolioId,
      targetActionType: enrichmentType
    }
  }
  return axios.post('/api/PortfolioActions/enrich-from-portfolio-selection', data)
}
export const addEnrichCompaniesRequest = (requestData: ExistingPortfolioEnrichmentRequest) =>
  axios.post<EnrichCompaniesResponse>('/api/PortfolioActions/add-companies', requestData).then(res => res.data)

export const enrichPortfolioCompanies = (companyIds: string[]) =>
  axios.put('/api/PortfolioEnrichment/portfolio-company/enrich', companyIds)

export const getEnrichmentProposal = (data: CompanyEnrichmentProposalRequest, cancelToken?: CancelToken) =>
  axios.post<CompanyEnrichmentProposalResponse>('/api/PortfolioEnrichment/companies-enrichment-proposal', data, {
    cancelToken
  })

export const createEnrichCompaniesRequest = (requestData: NewPortfolioEnrichmentRequest) => {
  return axios.post<EnrichCompaniesResponse>('/api/PortfolioActions/create', requestData).then(res => res.data)
}

export const getCompaniesUnitsEnrichmentProposal = (ids: string[], cancelToken?: CancelToken) =>
  axios.post<CompanyUnitEnrichmentProposalResponse>(
    '/api/PortfolioEnrichment/companies-units-enrichment-proposal',
    { companyUnitsIds: ids },
    { cancelToken }
  )
