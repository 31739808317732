import _ from 'lodash'
import styled, { css } from 'styled-components/macro'

const toNumber = (num?: number | string) => {
  if (!num) return 0
  if (_.isNumber(num)) return `${num}px`
  return num
}

export enum JustifyContentValue {
  Start = 'flex-start',
  End = 'flex-end',
  Center = 'center'
}

type Props = {
  orientation?: 'horizontal' | 'vertical'
  justify?: JustifyContentValue
  fullHeight?: boolean
  center?: boolean
  padding?: number | string
  gap?: number | string
  clickable?: boolean
}

export const stackMixin = ({
  orientation = 'horizontal',
  justify,
  fullHeight,
  center,
  padding,
  gap,
  clickable
}: Props) => css`
  width: ${orientation === 'horizontal' ? '100%' : 'initial'};
  height: ${fullHeight ? '100%' : 'initial'};
  position: relative;
  display: flex;
  flex-direction: ${orientation === 'horizontal' ? 'row' : 'column'};
  ${justify && `justify-content: ${justify};`}
  ${center && 'align-items: center;'};
  padding: ${toNumber(padding)};
  ${gap &&
  `
    && > * + *:not(.right) {
      margin-${orientation === 'horizontal' ? 'left' : 'top'}: ${toNumber(gap)};
    }
  `}
  ${orientation === 'horizontal' &&
  `
    && > .right {
      margin-left: auto;
    }
  `}
  ${clickable &&
  `
    cursor: pointer;
  `}
`

export const Stack = styled.div`
  ${stackMixin}
`
