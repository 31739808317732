import { css } from 'styled-components/macro'

export const hiddenScrollMixin = css`
  -ms-overflow-style: none; /* Edge, IE */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    width: 0; /* Chrome */
  }
`

export const ellipsisMixin = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
