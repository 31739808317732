import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { EnrichCreditStatistics } from '../../../components/Statistic/EnrichCreditStatistic'
import { Text } from '../../../components/Text'
import { CompanyEnrichProposal, EnrichSource } from './types'
import { getCompanyName } from '../../../features/config/selectors'
import { getHasPortfolioExclusions } from '../../../features/user/selectors'

type Props = {
  enrichmentInfo?: CompanyEnrichProposal
  source: EnrichSource
}

export const EnrichCompaniesRecap = ({ enrichmentInfo, source }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const companyName = useSelector(getCompanyName)
  const hasPortfolioExclusions = useSelector(getHasPortfolioExclusions)
  return (
    <>
      {hasPortfolioExclusions && source === EnrichSource.PortfolioDetailGroupAction ? (
        <div>
          <Text text={t('Enrich.RecapDesc', { companyName })} />
          <Text text={t('portfolio:CompanyGroupActions.HiddenCompanyMessage')} block padding="0 0 20px 0" />
        </div>
      ) : (
        <Text text={t('Enrich.RecapDesc', { companyName })} block padding="0 0 20px 0" />
      )}

      {enrichmentInfo && (
        <EnrichCreditStatistics
          inDialog
          totalCompaniesText={t('Enrich.CompaniesToBeEnriched', { count: enrichmentInfo.companiesCount })}
          enrichedCompanies={enrichmentInfo.enrichedCompaniesCount}
          notEnrichedCompanies={enrichmentInfo.companiesToEnrichCount}
          totalCompanies={enrichmentInfo.companiesCount}
          remainingCredits={enrichmentInfo.remainingCreditsAfterCompaniesEnrichment}
          usedCredits={enrichmentInfo.requiredCreditsToEnrichCompanies}
        />
      )}
    </>
  )
}
