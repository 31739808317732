import React from 'react'
import { Form } from 'antd3'

type Props = {
  error?: string
  children?: React.ReactNode
}

export const FormError = ({ error, children }: Props) => (
  <Form.Item validateStatus={error ? 'error' : 'success'} help={error}>
    {children}
  </Form.Item>
)
