import _ from 'lodash'
import { ArrayCompare, KeyTree, NodeSelectionState, TreeKeySelection, TreePath } from './types'
import { compareArrays, getKeyTreeNode, popFromPath, pushToPath } from './utils'

export const nodeSelectionStateMultiselectClassic = (
  keySelection: TreeKeySelection[],
  keySelectionItem: TreeKeySelection
): NodeSelectionState => {
  let result = NodeSelectionState.NotSelected
  const nodePath = pushToPath(keySelectionItem.parentPath, keySelectionItem.key)
  if (!nodePath) return result

  _.forEach(keySelection, item => {
    const itemPath = pushToPath(item.parentPath, item.key)
    if (compareArrays(nodePath, itemPath) === ArrayCompare.STARTSWITH) {
      result = NodeSelectionState.Selected
      return false
    }
    if (compareArrays(itemPath, nodePath) === ArrayCompare.STARTSWITH) {
      result = NodeSelectionState.Intermediate
      return false
    }
    return undefined
  })
  return result
}

const checkForParentSelection = (tree: KeyTree, keySelection: TreeKeySelection[], pathToCheck: TreePath) => {
  if (!pathToCheck) {
    return keySelection
  }

  const newKeySelection = [...keySelection]
  for (let index = 0; index < _.size(pathToCheck); index += 1) {
    const currentPath = _.slice(pathToCheck, 0, index + 1)
    const currentNode = getKeyTreeNode(tree, currentPath)

    const indexToDelete = _.findIndex(newKeySelection, { key: pathToCheck[index] })

    if (indexToDelete !== -1) {
      delete newKeySelection[indexToDelete]
      if (pathToCheck.length !== currentPath.length) {
        // eslint-disable-next-line lodash/prefer-map
        _.forEach(_.keys(currentNode), key => {
          const childInSelection = _.find(newKeySelection, { key })
          if (!childInSelection) {
            newKeySelection.push({
              key,
              parentPath: currentPath
            })
          }
        })
      }
    }
  }

  return _.compact(newKeySelection)
}

/* To keep backward compatibility, we need to support two types of selection for deselection.
When whole subtree is selected,
1. but only parent is saved
2. and all descendants are stored as well

See test cases for details.
 */
export const deselectNodeMultiselectClassic = (
  tree: KeyTree,
  keySelection: TreeKeySelection[],
  keySelectionItemToDeselect: TreeKeySelection
) => {
  const newKeySelection = [...keySelection]
  const stack = [keySelectionItemToDeselect]

  while (!_.isEmpty(stack)) {
    const current = stack.pop()
    if (current) {
      const indexToDelete = _.findIndex(newKeySelection, current)
      if (indexToDelete !== -1) {
        delete newKeySelection[indexToDelete]
      }
      const nodePath = pushToPath(current.parentPath, current.key)
      const children = getKeyTreeNode(tree, nodePath)
      // eslint-disable-next-line lodash/prefer-map
      _.forEach(_.keys(children), childKey => {
        stack.push({ key: childKey, parentPath: nodePath })
      })
    }
  }

  return checkForParentSelection(
    tree,
    _.compact(newKeySelection),
    keySelectionItemToDeselect.parentPath?.concat(keySelectionItemToDeselect.key) || []
  )
}

export const areChildrenSelected = (
  node: KeyTree,
  nodePath: TreePath,
  keySelection: TreeKeySelection[],
  excludedKeySelection?: TreeKeySelection[]
) => {
  let result = true
  _.forEach(_.keys(node), childKey => {
    const childKeySelection = {
      key: childKey,
      parentPath: nodePath
    }
    result =
      !!_.find(keySelection, childKeySelection) ||
      (!_.isNil(excludedKeySelection) && !!_.find(excludedKeySelection, childKeySelection))
    if (!result) return false
    return undefined
  })
  return result
}

export const deselectDescendants = (node: KeyTree, nodePath: TreePath, keySelection: TreeKeySelection[]) => {
  let newKeySelection = keySelection
  if (node) {
    _.forEach(_.keys(node), childKey => {
      const grandChildren = node[childKey]
      if (grandChildren) {
        newKeySelection = deselectDescendants(grandChildren, pushToPath(nodePath, childKey), newKeySelection)
      }
      newKeySelection = _.reject(newKeySelection, selectionItem =>
        _.isEqual(selectionItem, {
          key: childKey,
          parentPath: nodePath
        })
      )
      newKeySelection = [...newKeySelection]
    })
  }
  return [...newKeySelection]
}

export const selectNodeMultiselectClassic = (
  tree: KeyTree,
  keySelection: TreeKeySelection[],
  keySelectionItemToSelect: TreeKeySelection
): TreeKeySelection[] => {
  const parent = getKeyTreeNode(tree, keySelectionItemToSelect.parentPath)
  const nodePath = pushToPath(keySelectionItemToSelect.parentPath, keySelectionItemToSelect.key)
  const node = getKeyTreeNode(tree, nodePath)
  if (!parent) {
    return [...keySelection]
  }
  let newKeySelection = [...keySelection]
  if (node) {
    newKeySelection = deselectDescendants(node, nodePath, newKeySelection)
  }
  const parentKey = _.last(keySelectionItemToSelect.parentPath)
  if (
    areChildrenSelected(parent, keySelectionItemToSelect.parentPath, newKeySelection, [keySelectionItemToSelect]) &&
    parentKey
  ) {
    return selectNodeMultiselectClassic(tree, newKeySelection, {
      key: parentKey,
      parentPath: popFromPath(keySelectionItemToSelect.parentPath)
    })
  }

  return [...newKeySelection, keySelectionItemToSelect]
}
