import React from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { Step, StepChecked } from './Step'
import { toInt } from '../../utils/helpers'

const StepsWrapper = styled.div`
  position: relative;
  margin: 20px 0;
`

const LineWrapper = styled.div`
  position: absolute;
  margin: 0 12px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

const Line = styled.div<{ active: number; width: number }>`
  width: ${({ width }) => width}%;
  height: 4px;
  background-color: ${({ theme: { colors }, active }) => (active ? colors.main : colors.whiteGray)};
`

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

type Props = {
  count: number
  current: number
}

export const Steps = ({ count, current }: Props) => {
  const lineCount = _.max([count - 1, 0])!
  const lineWidth = 100 / lineCount
  return (
    <StepsWrapper>
      <LineWrapper>
        {_.times(lineCount, idx => (
          <Line key={idx} active={toInt(idx < current)} width={lineWidth} />
        ))}
      </LineWrapper>
      <StepWrapper>
        {_.times(count, idx =>
          idx < current ? <StepChecked key={idx} /> : <Step key={idx} active={toInt(idx === current)} />
        )}
      </StepWrapper>
    </StepsWrapper>
  )
}
