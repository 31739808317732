import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { getFiltersKeys } from '../../../api/filters'
import { changePortfolioName, getPortfolioKeys, validateNewPortfolioName } from '../../../api/portfolio'
import OkImage from '../../../assets/images/ok.svg'
import { CenterChildren } from '../../../components/Center'
import { Dialog } from '../../../components/Dialog'
import { Col, Row } from '../../../components/Grid'
import * as dialogSelectors from '../../../features/dialog/selectors'
import { PortfolioChangeNameViewState } from '../../../features/portfolio/types'
import { queryClient } from '../../../features/queryclient'
import { validateNameSynchronous } from '../../../utils/validators'
import { HookForm, InputField } from '../../../components/Form'
import { Text } from '../../../components/Text'

type Props = {
  view: PortfolioChangeNameViewState
  close: () => void
}

const CenterText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`

const BoldText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
`

const Label = styled.div`
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.33;
  color: ${({ theme: { colors } }) => colors.main};
`

export const PortfolioChangeNameDialog = ({ view, close }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const { portfolioId } = useSelector(dialogSelectors.getVisibleDialogData)
  const [viewState, setViewState] = useState(view)

  const { control, handleSubmit, formState, watch } = useForm({
    mode: 'onChange'
  })
  const { isSubmitting, isValidating, errors } = formState

  const { mutate: mutateChangePortfolioName, isLoading } = useMutation(
    () => changePortfolioName(portfolioId, watch('portfolioName')),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPortfolioKeys.All())
        setViewState(PortfolioChangeNameViewState.ChangeNameDone)
      }
    }
  )

  const onChangeNameDone = useCallback(() => {
    queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(portfolioId))
    queryClient.invalidateQueries(getPortfolioKeys.Options())
    queryClient.invalidateQueries(getFiltersKeys.DomainNodesData(), { refetchType: 'all' })
    close()
  }, [portfolioId, close])

  const asyncValidatePortfolioName = async (value: any) => {
    const validationResponse = await validateNewPortfolioName(value)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(true)(watch('portfolioName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  const dialogProps = {
    destroyOnClose: false,
    width: 1160,
    visible: true,
    loading: isLoading
  }

  if (viewState === PortfolioChangeNameViewState.ChangeNameView) {
    return (
      <Dialog
        {...dialogProps}
        title={t('portfolio:Management.PortfolioName.ChangePortfolioName')}
        footer=""
        cancelText={t('common:Cancel')}
        okText={t('common:Save')}
        okButtonProps={{
          disabled: !watch('portfolioName') || isLoading || isValidating || !_.isEmpty(errors),
          loading: isValidating
        }}
        onOk={() => mutateChangePortfolioName()}
        onCancel={close}
      >
        <HookForm handleSubmit={handleSubmit} onSubmit={mutateChangePortfolioName} isSubmitting={isSubmitting}>
          <Text block margin={{ bottom: 40 }}>
            {t('portfolio:Management.PortfolioName.EnterARepresentativeName')}
          </Text>
          <Label>{t('portfolio:PortfolioName')}</Label>
          <InputField
            hideLabel
            control={control}
            formState={formState}
            name="portfolioName"
            label={t('portfolio:PortfolioName')}
            placeholder={t('portfolio:Management.PortfolioName.InsertPortfolioName')}
            validators={{
              validateNameSynchronous: validateNameSynchronous(true),
              asyncValidatePortfolioName
            }}
          />
        </HookForm>
      </Dialog>
    )
  }
  if (viewState === PortfolioChangeNameViewState.ChangeNameDone) {
    return (
      <Dialog
        {...dialogProps}
        title={t('portfolio:Management.PortfolioName.ChangePortfolioName')}
        footer=""
        okText={t('common:Ok')}
        onOk={onChangeNameDone}
        onCancel={onChangeNameDone}
      >
        <Row>
          <Col col={6}>
            <CenterText>
              <div>
                {t('portfolio:Management.PortfolioName.NameChangedCorrectly')}
                <BoldText>&nbsp;{watch('portfolioName')}</BoldText>
              </div>
            </CenterText>
          </Col>
          <Col col={6}>
            <CenterChildren>
              <img src={OkImage} alt="ok" />
            </CenterChildren>
          </Col>
        </Row>
      </Dialog>
    )
  }
  return null
}
