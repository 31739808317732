import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '../../../components/Dialog'
import { WarningDialogData } from './types'
import { StyledText } from '../components'

type Props = {
  close: () => void
  data: WarningDialogData
}

export const WarningDialogComponent = ({ close, data }: Props) => {
  const { t } = useTranslation(['common', 'portfolio', 'salestool'])
  const { title, text, onClose: onCloseAction } = data

  const onClose = onCloseAction
    ? () => {
        close()
        onCloseAction()
      }
    : close

  return (
    <Dialog loading={false} visible title={title} okText={t('common:Ok')} onOk={onClose} onCancel={onClose} footer>
      <StyledText>{text}</StyledText>
    </Dialog>
  )
}

export const WarningDialog = React.memo(WarningDialogComponent)
