import React from 'react'
import { Progress as AntdProgress } from 'antd3'
import { ProgressProps } from 'antd3/lib/progress'
import styled, { ThemeConsumer } from 'styled-components/macro'

type Props = {
  maxTextSize?: string
} & ProgressProps

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledProgress = styled(({ maxTextSize, ...rest }) => <AntdProgress {...rest} />)`
  &.ant-progress-show-info {
    .ant-progress-outer {
      ${({ maxTextSize }) => `
      margin-right: calc(-${maxTextSize} - 8px);
      padding-right: calc(${maxTextSize} + 8px);
    `}
    }
  }

  .ant-progress-text {
    display: inline-flex;
    justify-content: flex-end;
    color: ${({ theme }) => theme.colors.grayishBrown};
    width: ${({ maxTextSize }) => maxTextSize} !important;
  }
`

export const Progress = ({ maxTextSize, ...props }: Props) => {
  return (
    <ThemeConsumer>
      {theme => <StyledProgress strokeColor={theme.colors.main} {...props} maxTextSize={maxTextSize || '2em'} />}
    </ThemeConsumer>
  )
}
