import _ from 'lodash'
import React from 'react'
// @ts-ignore
import ProductFruits from 'react-product-fruits'
import { useSelector } from 'react-redux'
import { getSelectedLanguage, getUserAccountInfo } from '../../features/user/selectors'

type Props = {
  productFruitsKey: string
}

export const ProductFruitsTool = ({ productFruitsKey }: Props) => {
  const accountInfo = useSelector(getUserAccountInfo)
  const selectedLanguage = useSelector(getSelectedLanguage)

  const userInfo = {
    username: accountInfo.userCode
  }

  return (
    <>
      {!_.isEmpty(userInfo.username) && selectedLanguage && (
        <ProductFruits
          projectCode={productFruitsKey}
          language={_.toLower(selectedLanguage?.substring(0, 2))}
          {...userInfo}
        />
      )}
    </>
  )
}
