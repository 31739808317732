import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteCompanyDialogData } from './types'
import { TrackingActions } from '../../../../../features/analytics/actions'
import { EventCategory, CallToActionType } from '../../../../../features/analytics/types'
import PortfolioImage from '../../../../../assets/images/portfolio-management-small.svg'
import { StyledDialog, StyledDialogBody, ContainerItem, BoldText } from '../../../../../containers/Dialog/components'
import { deleteCompany, getSalestoolKeys } from '../../../../../api/salestool'

type Props = {
  data: DeleteCompanyDialogData
  close: () => void
}

export const DeleteCompanyDialog = ({ data, close }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'salestool'])
  const queryClient = useQueryClient()

  const {
    mutate: mutateDeleteCompany,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(() => deleteCompany(data.campaignCompanyId), {
    onSuccess: () => {
      queryClient.resetQueries(getSalestoolKeys.AllBasedOnCampaignId(data.campaignId))
      dispatch(TrackingActions.trackCTAClick(EventCategory.SalesTool, CallToActionType.DeleteCompany))
    }
  })

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('salestool:AssignCampaign.Dialog.DeleteCompany.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) {
          return mutateDeleteCompany()
        }
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {(isIdle || isLoading) && (
            <div>
              <BoldText>{t('salestool:AssignCampaign.Dialog.DeleteCompany.Text')}</BoldText>
            </div>
          )}
          {isSuccess && <p>{t('salestool:AssignCampaign.Dialog.DeleteCompany.Success')}</p>}
          {isError && <p>{t('salestool:AssignCampaign.Dialog.DeleteCompany.Error')}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
