import _ from 'lodash'
import { PersonResponseItem } from '../../api/person/types'
import { ReportSection } from '../report/types'
import { PersonReportSection } from './types'

export function filterSections(sections: ReportSection[], data: PersonResponseItem) {
  const toRemove = new Set<string>()

  if (_.isEmpty(data?.affiliates)) {
    toRemove.add(PersonReportSection.PartnersList)
  }

  const filteredSections = _.reject([...sections], item => item.visibility !== 'always' && toRemove.has(item.title))
  return filteredSections
}
