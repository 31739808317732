import { SynonymItem } from '../../features/filters/types'
import { CompoundExpression } from '../../features/operations/types'
import { SemanticSearchRequest } from '../filters/types'

export type AggregationField = 'CountOfCompanies' | 'Amount' | string
export const COUNT_OF_COMPANIES = 'CountOfCompanies'
export const AMOUNT = 'Amount'

export enum TargetingCompanyStatusFilter {
  totalCompanies = 'totalCompanies',
  activeCompanies = 'activeCompanies',
  otherCompanies = 'otherCompanies'
}

export enum ChartViewType {
  AreaChartMultiChoice = 'AreaChartMultiChoice',
  BarChart = 'BarChart',
  HistogramChart = 'HistogramChart',
  GroupedBarChart = 'GroupedBarChart',
  PieChart = 'PieChart',
  TreeMap = 'TreeMap',
  Map = 'Map'
}

export type RequestCommon = {
  targetingQuery?: CompoundExpression
  query?: CompoundExpression
  ignoredPortfolios?: string[]
  selectedPortfolios?: string[]
  semantic?: SemanticSearchRequest
}

export type StatisticsRequest = RequestCommon

export type PortfolioConfigRequest = Partial<RequestCommon>

export type ChartDataRequest = {
  chartCode: string | undefined
  aggregationField: AggregationField
  statusCompanyFilter?: TargetingCompanyStatusFilter
} & RequestCommon

export type StatisticsResponse = {
  totalCompanies: number
  activeCompanies: number
  otherCompanies: number
  semantic?: {
    synonyms: SynonymItem[]
  }
}

export type MapDistribution = {
  count: number
  description: string
  sub?: MapDistributionData
}

export type MapDistributionData = {
  [key: string]: MapDistribution
}

export type PortfolioFilterConfig = {
  fieldId: string
  fieldValue: string
  labelKey: string
}

export type PortfolioConfigResponse = {
  aggregationFields: PortfolioFilterConfig[]
  customFilters: PortfolioFilterConfig[]
}

export enum TrendsOptions {
  turnover = 'turnover',
  employees = 'employee',
  new = 'New',
  active = 'Active',
  ceased = 'Ceased'
}

export type Aggregations = AggregationField | 'allCompaniesAnalysis' | 'noAnalytics' | 'noMastercard'
export enum SlotHeight {
  Default = 'Default',
  Big = 'Big'
}
export enum SlotWidth {
  Half = 'Half',
  Full = 'Full'
}
export type ScreenSettings = {
  position: number
  width: SlotWidth
}

export type ChartCode = string

export type LayoutConfigDto = {
  chartCode: ChartCode
  chartType: ChartViewType
  isAvailableForMacroAnalysis: boolean
  slot: SlotHeight
  screenBig: ScreenSettings
  screenMedium: ScreenSettings
  visibilityPerAggregationFields: Aggregations[]
}

export type LayoutConfigResponse = {
  charts: LayoutConfigDto[]
}

export type Metadata = {
  x: string[]
  y: string[]
  colors: string[]
  columnNames: string[]
  settings?: {
    hasLegend: boolean
    showTickLabel: boolean
    showIdInTable: boolean
    showCountryLevelInTable: boolean
    hideNotDefinedInChart: boolean
  }
}

export type MapChartMetadata = Omit<Metadata, 'columnNames'> & {
  multiLevelColumnNames?: string[][]
  defaultZoomLevel?: string
  maxZoomLevel?: string
  minZoomLevel?: string
}

export type BarChartItem = {
  id: string
  label: string
  count: number
  value: number
}

export type HistogramChartItem = {
  id: string
  label: string
  count: number
  value: number
  from: number
  to: number
}

export type AreaMultiChoiceChartSubItem = {
  id: string
  label: string
  value: number
  count: number
}

export type GroupedBarChartSubItem = {
  id: string
  description: string
  label: string
  value: number
  count: number
}

export type GroupedBarChartItem = {
  id: string
  label: string
  data: GroupedBarChartSubItem[]
}

export type AreaMultiChoiceChartItem = {
  id: string
  label: string
  data: AreaMultiChoiceChartSubItem[]
}

export type DistributionChartItem = {
  id: string
  label: string
  value: number
  count: number
}

export type MapChartItem = {
  count: number
  description: string
  sub: Record<string, MapChartItem>
}

export type MapDistributionItem = Record<string, MapChartItem>

export type WithChartLabel = { chartLabel?: string }
export type WithChartTooltip = { chartTooltip?: string }
export type ChartDesc = WithChartLabel & WithChartTooltip

// RESPONSES

export type ChartResponse<
  TType extends ChartViewType = ChartViewType,
  TData extends object = object,
  TMeta extends object = Metadata
> = {
  chartType: TType
  data: TData
  metadata?: TMeta
}

export type AreaMultiChoiceChartResponse = ChartResponse<
  ChartViewType.AreaChartMultiChoice,
  { buckets: AreaMultiChoiceChartItem[] } & ChartDesc
>

export type BarChartResponse = ChartResponse<ChartViewType.BarChart, { buckets: BarChartItem[] } & ChartDesc>

export type HistogramChartResponse = ChartResponse<
  ChartViewType.HistogramChart,
  { buckets: HistogramChartItem[] } & ChartDesc
>

export type GroupedBarChartResponse = ChartResponse<
  ChartViewType.GroupedBarChart,
  { buckets: GroupedBarChartItem[] } & ChartDesc
>

export type PieChartResponse = ChartResponse<ChartViewType.PieChart, { buckets: DistributionChartItem[] } & ChartDesc>

export type MapChartResponse = ChartResponse<
  ChartViewType.Map,
  { mapDistribution: MapDistributionItem } & ChartDesc,
  MapChartMetadata
>

export type TreeMapChartResponse = ChartResponse<
  ChartViewType.TreeMap,
  { buckets: DistributionChartItem[] } & ChartDesc
>

export type ChartDataResponse =
  | AreaMultiChoiceChartResponse
  | BarChartResponse
  | HistogramChartResponse
  | GroupedBarChartResponse
  | PieChartResponse
  | MapChartResponse
  | TreeMapChartResponse

export type ExportDataRequest = {
  chartCodes: string[]
  aggregationField: string
  exportName?: string
  query?: CompoundExpression
  ignoredPortfolios?: string[]
  selectedPortfolios?: string[]
  semantic?: SemanticSearchRequest
}

export type ExportDataResponse = {
  id: string
  userId?: string
  name?: string
  type?: string
  extension?: string
  isDeleted?: boolean
  mimeType?: string
  createdAt?: string
  expirationDate?: string
  lastUseDate?: string
  associatedEntityId?: string
}
