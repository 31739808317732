import axios, { CancelToken } from 'axios'
import { CompanyDetailsType } from '../../types'
import { CampaignCompaniesRes } from '../salestool/types'
import { CampaignCompanyPopupDataRequest, CompanyData, CompanyPopupDataRequest } from './types'

export const getPortfolioCompanyPopupData = (
  { companyId, pageType = CompanyDetailsType.Map }: CompanyPopupDataRequest,
  cancelToken?: CancelToken
) =>
  axios.get<CompanyData>(`/api/portfolioCompany/${companyId}/portfolio-company-detail`, {
    params: { pageType },
    cancelToken
  })

export const getProspectingCompanyPopupData = (
  { companyId, pageType = CompanyDetailsType.Map }: CompanyPopupDataRequest,
  cancelToken?: CancelToken
) =>
  axios.get<CompanyData>(`/api/Prospecting/${companyId}/company-detail`, {
    params: { pageType },
    cancelToken
  })

export const getCampaignCompanyPopupData = (
  campaignId: string,
  data: CampaignCompanyPopupDataRequest,
  cancelToken?: CancelToken
) => axios.post<CampaignCompaniesRes>(`api/SalesTool/campaign/${campaignId}/campaign-companies`, data, { cancelToken })
