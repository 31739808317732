import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companyReportKeys } from '../../../../../api/companyReport/common'
import { addCompanyNote, deleteCompanyNote, useGetCompanyNotes, getSalestoolKeys } from '../../../../../api/salestool'
import KoImage from '../../../../../assets/images/ko.svg'
import { Text } from '../../../../../components/Text'
import { Dialog } from '../../../../../components/Dialog'
import { EditCompanyNoteDialogForm } from './EditCompanyNoteDialogForm'
import { CompanyNotes } from '../../../../../components/CompanyNotes'
import { BaseDialog } from '../../../BaseDialog'
import { DialogActions } from '../../../../../features/dialog/actions'
import { getMaxNotesNumber } from '../../../../../features/config/selectors'
import { HookForm } from '../../../../../components/Form'
import { getUserId } from '../../../../../features/user/selectors'

type Props = {
  data: {
    companyId: string
    campaignId: string
  }
}

const getErrorTranslationString = (
  isCompanyNotesError: boolean,
  isAddNoteError: boolean,
  isDeleteNoteError: boolean
) => {
  if (isCompanyNotesError) return 'FetchCompanyNotes'
  if (isAddNoteError) return 'AddCompanyNote'
  if (isDeleteNoteError) return 'DeleteCompanyNote'
  return 'ErrorPage.Text'
}

export const EditCompanyNoteDialog = ({ data: { companyId, campaignId } }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const { control, handleSubmit, formState, getValues, reset } = useForm({ mode: 'onChange' })
  const { isSubmitting, isValid } = formState
  const maxNotesNumber = useSelector(getMaxNotesNumber)
  const userId = useSelector(getUserId)
  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const {
    data: companyNotes,
    isLoading: isCompanyNotesLoading,
    isError: isCompanyNotesError
  } = useGetCompanyNotes(companyId)

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(companyReportKeys.campaignsAll())
    queryClient.invalidateQueries(getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId))
    queryClient.invalidateQueries(getSalestoolKeys.GetCompanyNotes(companyId))
  }, [campaignId, companyId, queryClient])

  const {
    mutate: mutateAddNote,
    isLoading: isAddNoteLoading,
    isError: isAddNoteError
  } = useMutation(
    (note: string) => {
      return addCompanyNote(companyId, note)
    },
    {
      onSuccess
    }
  )

  const {
    mutate: mutateDeleteNote,
    isLoading: isDeleteNoteLoading,
    isError: isDeleteNoteError
  } = useMutation(
    (noteId: string) => {
      return deleteCompanyNote(companyId, noteId)
    },
    {
      onSuccess
    }
  )
  const isLoading = isCompanyNotesLoading || isAddNoteLoading || isDeleteNoteLoading

  const props = {
    destroyOnClose: false,
    width: 1024,
    visible: true,
    loading: isLoading,
    onCancel: closeDialog,
    footer: '',
    title: t('AssignCampaign.Dialog.AddCompanyNoteTitle'),
    className: 'company-note-dialog',
    bodyStyle: {
      maxHeight: '75vh',
      overflow: 'auto'
    }
  }

  if (isCompanyNotesError || isAddNoteError || isDeleteNoteError) {
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={closeDialog}>
        <BaseDialog
          content={t(`error:${getErrorTranslationString(isCompanyNotesError, isAddNoteError, isDeleteNoteError)}`)}
          image={<img src={KoImage} alt="error" />}
        />
      </Dialog>
    )
  }

  const notesLimitReached = companyNotes && companyNotes.length >= maxNotesNumber
  return (
    <HookForm handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
      <Dialog
        {...props}
        cancelText={t('common:Cancel')}
        okText={t('common:Add')}
        okButtonProps={{ disabled: !isValid || isLoading || notesLimitReached }}
        onOk={() => {
          mutateAddNote(getValues('note'))
          reset()
        }}
      >
        <Text block margin={{ bottom: 20 }}>
          {t('AssignCampaign.Dialog.AddCompanyNote')}
        </Text>
        <CompanyNotes userId={userId} notes={companyNotes ?? []} onDelete={mutateDeleteNote} />
        {!notesLimitReached && <EditCompanyNoteDialogForm control={control} formState={formState} />}
      </Dialog>
    </HookForm>
  )
}
