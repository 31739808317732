import { DOWNLOAD_PORTFOLIO_COSX_REPORT_TYPE } from '../../features/portfolio/constants'
import { convertDateTimeFromBackendWithoutTimezone } from '../../utils/formatters'
import { Portfolio, PortfolioPreview } from './types'

export const mapPortfolioResponse = (p: PortfolioPreview): Portfolio => ({
  id: p.id,
  name: p.name,
  note: p.note,
  isEnriched: p.isEnriched,
  isShared: p.isShared,
  isSharedWithUser: p.isSharedWithUser,
  isExcluded: p.isExcluded,
  ownerName: p.ownerName,
  isSharedAsWhole: p.isPortfolioSharedAsWhole,
  isMonitored: p.isMonitored,
  monitoredQueryId: p.repositoryDocumentMonitoredQueryId,
  createdAt: convertDateTimeFromBackendWithoutTimezone(p.createdAt).toDate(),
  lastUpdate: convertDateTimeFromBackendWithoutTimezone(p.lastUpdated).toDate(),
  lastViewByUser: convertDateTimeFromBackendWithoutTimezone(p.lastViewByUser).toDate(),
  status: p.uploadStatus,
  statistic: {
    total: p.totalCompaniesCount,
    enriched: p.enrichedCompaniesCount,
    client: p.clientCompaniesCount,
    competitor: p.competitorCompaniesCount,
    lead: p.leadCompaniesCount,
    prospect: p.prospectCompaniesCount,
    supplier: p.supplierCompaniesCount,
    headquarter: p.headQuarterCount,
    headQuarters: {
      client: p.headQuarters?.client ?? 0,
      competitor: p.headQuarters?.competitor ?? 0,
      lead: p.headQuarters?.lead ?? 0,
      prospect: p.headQuarters?.prospect ?? 0,
      supplier: p.headQuarters?.supplier ?? 0
    }
  },
  downloadType: p.additionalItems !== undefined ? p.additionalItems[DOWNLOAD_PORTFOLIO_COSX_REPORT_TYPE] : undefined
})
