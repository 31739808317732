import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SelectedPortfolio } from '../../../../features/targeting/types'
import { useGetPortfolioList } from '../../../../api/portfolio'
import { Row } from '../../../../components/Grid'
import { StyledSelect, Option } from '../../../../components/Select'
import { ItemCounter } from '../../../../components/ItemCounter'
import { Text } from '../../../../components/Text'
import { SmallSpin } from '../../../../components/Loading/Spin'
import { WizardNav, NavProps, WizardNavigationWrapper } from '../../../../components/Wizard'
import { useLoadPortfolioStatus } from '../../MarketAnalysisWizard/useLoadPortfolioStatus'
import { getMinCountOfSameStatusCompaniesForAnalyze } from '../../../../features/config/selectors'
import { PortfolioListOrderBy } from '../../../../api/portfolio/types'

type Props = {
  close: () => void
  selectedPortfolios: SelectedPortfolio[] | null
  setSelectedPortfolios: (portfolios: SelectedPortfolio[]) => void
} & NavProps

export const LoadPortfolioStepOne = ({
  close,
  selectedPortfolios,
  setSelectedPortfolios,
  currentStep,
  totalSteps,
  ...rest
}: Props) => {
  const { t } = useTranslation(['targeting', 'common'])
  const minCountOfSameStatusCompaniesForAnalyze = useSelector(getMinCountOfSameStatusCompaniesForAnalyze)
  const { data: portfolios, isLoading: portfoliosLoading } = useGetPortfolioList({
    orderBy: PortfolioListOrderBy.UpdatedAtDesc,
    minCountOfSameStatusCompanies: minCountOfSameStatusCompaniesForAnalyze
  })

  const { clearSelectedStatus } = useLoadPortfolioStatus()
  return (
    <>
      <Row>
        <ItemCounter title item={currentStep} totalItems={totalSteps} text={t('LoadPortfolio.DialogSubTitle')} />
      </Row>
      <Text className="selection-label" size={12} primary bold block>
        {t('MarketAnalysisWizard.StepOne.UploadedPortfolio')}
      </Text>
      <StyledSelect
        mode="multiple"
        placeholder={t('common:Select')}
        disabled={portfoliosLoading}
        value={
          !_.isEmpty(portfolios) && !_.isEmpty(selectedPortfolios)
            ? _.map(selectedPortfolios, selectedPortfolio => selectedPortfolio.id)
            : undefined
        }
        loading={portfoliosLoading}
        notFoundContent={portfoliosLoading ? SmallSpin : undefined}
        showSearch
        optionFilterProp="title"
        onChange={value => {
          setSelectedPortfolios(
            _.compact(
              _.map(value as string[], key => {
                const selectedPortfolio = _.find(portfolios, { id: key })
                if (selectedPortfolio === undefined) return undefined
                return {
                  id: key,
                  name: selectedPortfolio.name
                } as SelectedPortfolio
              })
            )
          )
          clearSelectedStatus()
        }}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      >
        {_.map(portfolios, ({ name, id }) => (
          <Option key={id} title={name} value={id}>
            {name}
          </Option>
        ))}
      </StyledSelect>
      <WizardNavigationWrapper>
        <WizardNav {...rest} previousStep={close} nextDisabled={_.isEmpty(selectedPortfolios)} />
      </WizardNavigationWrapper>
    </>
  )
}
