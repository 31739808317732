import React from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import _ from 'lodash'
import { Spin } from 'antd3'
import styled from 'styled-components/macro'

export const StyledForm = styled.form`
  display: block;
  width: 100%;
`

type Props = {
  disableEnterSubmit?: boolean
  handleSubmit: UseFormHandleSubmit<any>
  isSubmitting: boolean
  onSubmit?: () => void
  onFailure?: () => void
  children: React.ReactNode
}

export const HookForm: React.FC<Props> = ({
  children,
  disableEnterSubmit,
  handleSubmit,
  isSubmitting,
  onSubmit: onFormSubmit,
  onFailure
}) => {
  const onSubmit = (_data: any, evt: any) => {
    if (evt && _.isFunction(evt.preventDefault)) {
      evt.preventDefault()
      if (onFormSubmit) onFormSubmit()
    }
  }

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit, onFailure)}
      onKeyPress={event => {
        if (disableEnterSubmit && event.which === 13 /* Enter */) {
          event.preventDefault()
        }
      }}
    >
      <Spin spinning={isSubmitting}>{children}</Spin>
    </StyledForm>
  )
}
