import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../types'
import { paramSelector } from '../../utils/selectors'
import { getNumberOfNotificationInDrawer } from '../config/selectors'
import { PushEventProgressDataResponse } from '../../api/notification/types'

type WithLimit = { limit?: number }

type WithNotificationId = { notificationId: string }

const selectState = (state: RootState) => state.notifications

export const getNotifications = createSelector(selectState, state => state.notifications)

export const getLastNotificationId = createSelector(selectState, state => state.lastNotificationId)

export const hasNewNotifications = createSelector(selectState, state => state.hasNewNotifications)

export const getNotificationsWithLimit = createSelector(
  getNotifications,
  paramSelector<WithLimit>(),
  (notifications, { limit }) => _.take(notifications, limit)
)

export const getNotificationsForDrawer = createSelector(
  getNotifications,
  getNumberOfNotificationInDrawer,
  (notifications, limit) => _.take(notifications, limit)
)

export const getLoadedNotificationsCount = createSelector(getNotifications, notifications => notifications.length)

export const getNumberOfNotifications = createSelector(selectState, state => state.numberOfNotifications)

export const getNumberOfNotificationsToDisplay = createSelector(
  selectState,
  state => state.numberOfNotificationsToDisplay
)

export const getPushEvents = createSelector(selectState, state => state.pushEvents)

export const getProgressPushEvents: (state: RootState) => PushEventProgressDataResponse[] = createSelector(
  selectState,
  state =>
    _.filter(state.pushEvents, ({ type }) => {
      return _.includes(
        [
          'UploadPortfolioProgress',
          'DuplicatePortfolioProgress',
          'ExportPortfolioProgress',
          'DeleteCompanyFromPortfolioProgress',
          'MoveFromPortfolioSelectionProgress',
          'ShareCompaniesProgress',
          'UnshareCompaniesProgress',
          'CreatePortfolioFromSelectionProgress',
          'EnrichCompanyFromPortfolioProgress',
          'AssignSalesProgress',
          'AssignAreaManagerProgress',
          'AssignProductProgress',
          'ProspectingSaveListProgress',
          'ProspectingUpdateListProgress',
          'TargetingSaveListProgress',
          'TargetingUpdateListProgress',
          'CreateCampaignProgress',
          'AssignCampaignProgress',
          'AddPortfolioTaskProgress',
          'UpdateCampaignByPortfolioTaskProgress',
          'SetCompaniesTaskProgress',
          'ExportCampaignProgress',
          'AddLocalUnitsToPortfolioProgress'
        ],
        type
      )
    }) as PushEventProgressDataResponse[]
)

export const getNotificationsToDisplay = createSelector(
  getNotifications,
  getNumberOfNotificationsToDisplay,
  (notifications, numberOfNotifications) => _.take(notifications, numberOfNotifications)
)

export const getNotification = createSelector(
  getNotifications,
  paramSelector<WithNotificationId>(),
  (notifications, { notificationId }) => _.find(notifications, ['id', notificationId])
)
