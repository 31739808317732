import { TFunction } from 'i18next'

enum CountryCode {
  Italy = 'ita',
  CzSk = 'czsk',
  Germany = 'deu',
  FarEast = 'fareast',
  Swiss = 'che'
}

export const getSearchByCompaniesItems = (t: TFunction, countryCode: string, visibilityCountry?: string) => {
  if (countryCode === CountryCode.Swiss) {
    if (visibilityCountry === 'ch') {
      return [
        t('search:SearchInformationDialog.Companies.Swiss.VatCode'),
        t('search:SearchInformationDialog.Companies.Swiss.IdentificationNumber'),
        t('search:SearchInformationDialog.Companies.Swiss.CompanyName'),
        t('search:SearchInformationDialog.Companies.Swiss.Website'),
        t('search:SearchInformationDialog.Companies.Swiss.LegacyCHNumber')
      ]
    }
    if (visibilityCountry === 'at') {
      return [
        t('search:SearchInformationDialog.Companies.Swiss.CompanyName'),
        t('search:SearchInformationDialog.Companies.Swiss.VatCode'),
        t('search:SearchInformationDialog.Companies.Swiss.Website'),
        t('search:SearchInformationDialog.Companies.Swiss.CompanyBookNumber')
      ]
    }
  }

  if (countryCode === CountryCode.FarEast)
    return [
      t('search:SearchInformationDialog.Companies.Swiss.CompanyName'),
      t('search:SearchInformationDialog.Companies.Swiss.Website'),
      t('search:SearchInformationDialog.Companies.Fareast.CompanyId')
    ]

  if (countryCode === CountryCode.Germany)
    return [
      t('companyReport:CompanyData.CompanyName'),
      t('search:SearchInformationDialog.Companies.Germany.VatCode'),
      t('search:SearchInformationDialog.Companies.Germany.TaxId'),
      t('search:SearchInformationDialog.Companies.Germany.Onr'),
      t('search:SearchInformationDialog.Companies.Germany.Website')
    ]

  if (countryCode === CountryCode.Italy)
    return [
      t('companyReport:CompanySummary.VatCode'),
      t('companyReport:CompanySummary.TaxCode'),
      t('companyReport:CompanySummary.CrifNumber'),
      t('companyReport:CompanyData.CompanyName'),
      t('companyReport:ContactsAndSocial.Website'),
      t('search:SearchInformationDialog.Companies.CommercialDisplay')
    ]

  // Default is ITA behavior until all countries behavior will be specified
  return [
    t('companyReport:CompanySummary.VatCode'),
    t('companyReport:CompanySummary.TaxCode'),
    t('companyReport:CompanySummary.CrifNumber'),
    t('companyReport:CompanyData.CompanyName'),
    t('companyReport:ContactsAndSocial.Website'),
    t('search:SearchInformationDialog.Companies.CommercialDisplay')
  ]
}
