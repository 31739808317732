import { PositionDate } from '../../api/person/types'
import {
  ReportSection,
  ReportValueTypeCode,
  ReportValueTypeValue,
  WithLabelDescriptionOptional,
  WithPartnerType
} from '../report/types'

export type PersonInfo = {
  name: ReportValueTypeValue | undefined
  surname: ReportValueTypeValue | undefined
  gender: ReportValueTypeCode | undefined
  taxCode: ReportValueTypeValue | undefined
  residence: ReportValueTypeValue | undefined
  domicile: ReportValueTypeValue | undefined
  birthDate: ReportValueTypeValue | undefined
  birthPlace: ReportValueTypeValue | undefined
  birthCountry: ReportValueTypeCode | undefined
}

export type PositionCompanyInfo = WithPartnerType & {
  companyName: ReportValueTypeValue | undefined
  taxCode: ReportValueTypeValue | undefined
}

export type ActivePositionInfo = {
  role: ReportValueTypeCode | undefined
  from: PositionDate | undefined
}

export type PastPositionInfo = ActivePositionInfo & { to: PositionDate | undefined }

export type PersonActivePositionGrouped = PositionCompanyInfo & {
  positions: ActivePositionInfo[]
}

export type PersonPastPositionGrouped = PositionCompanyInfo & {
  positions: PastPositionInfo[]
}

export type PersonPositions = WithLabelDescriptionOptional & {
  current: PersonActivePositionGrouped[]
  past: PersonPastPositionGrouped[]
}

export type PersonPartnerInfo = WithPartnerType & {
  amount: ReportValueTypeValue | undefined
  companyName: ReportValueTypeValue | undefined
  ownedSharesType: ReportValueTypeCode | undefined
  taxCode: ReportValueTypeValue | undefined
}

export type Person = {
  id: string
  sections: ReportSection[]
  name: string
  surname: string
  isEnriched: boolean
  info: PersonInfo
  positions: PersonPositions
  partners: PersonPartnerInfo[]
}

export enum PersonReportSection {
  IdentificationData = 'IdentificationData',
  Positions = 'Positions',
  PartnersList = 'PartnersList'
}
