import { Select as AntSelect } from 'antd3'
import { SelectProps } from 'antd3/lib/select'
import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as DownArrow } from '../../assets/icons/down.svg'
import { IsModalContext } from '../Dialog'

export const { Option } = AntSelect

const SelectWrapper = styled.div`
  position: relative;

  .ant-select {
    width: 100%;
    display: block;
  }

  .ant-select-selection {
    height: 40px;
    border: none;
    background-color: ${({ theme }) => theme.colors.paleGrey};

    .ant-select-selection__placeholder,
    .ant-select-selection-selected-value {
      font-size: 14px;
      line-height: 1.71;
      color: ${({ theme }) => theme.colors.brownGray};
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      right: 0;
      margin-top: -12px;
    }

    .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center;
      height: 40px !important;
    }

    // override display flex to hide default title when search text is filled
    .ant-select-selection-selected-value[style*='display: none'] {
      display: none !important;
    }

    .ant-select-selection__rendered {
      min-height: 40px;
      height: auto;
      margin-left: 16px;
      margin-right: 40px;
    }

    .anticon-loading svg {
      fill: ${({ theme }) => theme.colors.main};
    }
  }

  .down-arrow {
    position: absolute;
    top: 0;
    right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: transform ${({ theme }) => theme.animation.duration} ease;
  }

  .ant-select-open + .down-arrow {
    transform: rotate(180deg);
  }
`

// eslint-disable-next-line react/display-name
export const Select = React.forwardRef<any, React.PropsWithChildren<SelectProps>>(
  ({ className, loading, value, ...props }, ref) => {
    const isModal = React.useContext(IsModalContext)

    return (
      <SelectWrapper className={className}>
        <AntSelect
          loading={loading}
          showArrow={false}
          value={value}
          data-value={value}
          {...props}
          dropdownClassName={`${props.dropdownClassName ? props.dropdownClassName : ''} ${
            isModal ? ' dialog-dropdown-select' : ''
          }`}
          ref={ref}
        />
        {!loading && (
          <div className="down-arrow">
            <DownArrow />
          </div>
        )}
      </SelectWrapper>
    )
  }
)

export const StyledSelect = styled(Select)`
  .ant-select-selection {
    min-height: 40px;
    height: auto;
    display: block;
    align-items: center;

    .ant-select-selection__rendered {
      width: 80%;
    }

    .ant-select-selection-selected-value {
      height: 30px;
    }

    .ant-select-search__field {
      height: 24px;
    }

    .ant-select-search__field__mirror {
      padding-left: 5px;
    }
  }

  && .ant-select-disabled .ant-select-selection__choice {
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
    cursor: not-allowed;
  }

  && .ant-select-selection__choice {
    height: 24px;
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
    padding-right: 21px;
    border-radius: 4px;
    border: none;

    .ant-select-selection__choice__content {
      line-height: 22px;
    }

    .ant-select-selection__choice__remove {
      top: 2px;
      svg {
        width: 15px;
        height: 15px;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`
