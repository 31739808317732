/* eslint-disable import/no-default-export */
export default {
  ExcelFileType: 'Vybraný soubor je neplatný. Nahrajte soubor Excel (s příponou .xls nebo .xlsx).',
  FileNotEmpty: 'Soubor nemůže být prázdný.',
  Forbidden: 'Zakázané',
  ExcludeCustomVariablesError: 'Struktura vlastních proměnných v tomto portfoliu není kompatibilní s původní.',
  Length: 'Délka „{{label}}“ je minimálně {{min}} písmen a maximálně {{max}} písmen',
  LengthLong: 'Maximální délka je {{max}} znaků',
  LengthShort: 'Minimální délka je {{min}} znaků',
  MissingClaims: 'Chybí vám nároky: {{claims}}.',
  NoSpecialCharacters: '„{{label}}“ nemůže obsahovat speciální znaky',
  NoSpecialCharactersWoLabel: 'Pole nesmí obsahovat speciální znaky',
  MaxTagsCount: 'Maximální počet poskytnutých značek: 20',
  NotEmpty: 'Pole nemůže být prázdné.',
  CannotStartWithSpace: 'Název nemůže začínat mezerou',
  PortfolioCreationError:
    'Upozornění! Chyba při vytváření portfolia. Zkontrolujte vstupní soubor a zkuste jej nahrát znovu.',
  PortfolioCreationFromListError: 'Upozornění! Chyba při vytváření portfolia. Vložte seznam ID a zkuste nahrát znovu.',
  PortfolioImportCompaniesLessThen:
    'Šablona překračuje maximální povolený počet řádků {{maxCompaniesCount}}, zkontrolujte to a zkuste to znovu.',
  PortfolioImportCompaniesNotEmpty: 'Nahraný soubor je neplatný. Ujistěte se, že obsahuje alespoň jednu společnost.',
  PortfolioImportHasCustomVariableSheet: 'V nahraném souboru chybí list vlastních proměnných.',
  PortfolioImportCustomVariableNames: 'Vlastní proměnné nahrávaného souboru musí mít jedinečný název.',
  PortfolioImportValidStructure:
    'Nahraný soubor je neplatný. Ujistěte se, že použitá struktura šablon je stejná jako navrhovaná struktura.',
  Required: 'Pole "{{label}}" je povinné',
  RequiredWithoutFieldName: 'Pole je povinné',
  ServerError: 'Chyba serveru',
  MinimumLength8Characters: 'Heslo musí mít délku alespoň 8 znaků',
  MustContainLetter: 'Heslo musí obsahovat alespoň jedno písmeno a jednu číslici',
  MustContainNumber: 'Heslo musí obsahovat alespoň jedno písmeno a jednu číslici',
  MustContainSpecialCharacter: 'Heslo musí obsahovat alespoň jednu číslici a jeden speciální znak',
  CannotContainUsername: 'Heslo nesmí obsahovat jméno uživatele',
  CannotBeSameAsOldPassword: 'Heslo nemůže být stejné jako staré heslo',
  MustContainCapitalLetter: 'Heslo musí obsahovat velká a malá písmena',
  MustContainLowercaseLetter: 'Heslo musí obsahovat velká a malá písmena',
  CannotContainMoreThan2EqualCharactersInRow: 'Heslo musí obsahovat maximálně 3 stejné znaky po sobě',
  CannotContainMoreThan3EqualCharactersInRow: 'Heslo musí obsahovat maximálně 3 stejné znaky po sobě',
  CannotBeTheSameAsLast6Passwords: 'Heslo nesmí být stejné jako posledních šest použitých hesel',
  UniqueName: 'Existující název. Prosím, zadejte jiný název.',
  Warning: 'Upozornění',
  ForbiddenText: 'Je nám líto, nemáte oprávnění k přístupu na tuto stránku.',
  PortfolioDownloadError: 'Upozornění! Chyba stahování portfolia. Prosím zkuste to znovu.',
  PortfolioUpdateError:
    'Upozornění! Chyba aktualizace portfolia. Zkontrolujte vstupní soubor a zkuste jej aktualizovat znovu.',
  PortfolioLockErrorTitle: 'Potvrdit operaci',
  PortfolioLockError: 'Operaci nelze dokončit, protože v portfoliu běží jiná akce.',
  PdfReportLockErrorTitle: 'Potvrdit operaci',
  PdfReportLockError: 'Probíhá vytváření PDF souboru pro společnost {{companyName}}, počkejte na dokončení operace.',
  CouldNotValidatePortfolioName: 'Chyba serveru. Nepodařilo se ověřit název portfolia.',
  MinMax: 'Hodnota musí být mezi {{min}} a {{max}}',
  OnlyIntegers: 'Jsou povolena pouze celá čísla.',
  ProspectingSaveListError: 'Upozornění! Chyba uložení seznamu. Zkontrolujte vybrané vstupy a zkuste to znovu.',
  NotEmail: 'Pole „{{label}}“ nemá platný formát e-mailu.',
  MustAgreeWithPrivacyPolicy: 'Musíte odsouhlasit pravidla ochrany osobních údajů.',
  ContactError: 'Je nám líto, ale vaši zprávu jsme nemohli zpracovat.',
  ResetPasswordError: 'Je nám líto, ale nemohli jsme resetovat heslo.',
  DuplicatesNotAllowed: 'Duplicity nejsou povoleny.',
  CreateGoal: 'Je nám líto, ale nepodařilo se nám vytvořit váš cíl.',
  SharePortfolio: 'Je nám líto, ale nemohli jsme sdílet portfolio.',
  OnlyNumbersOrSpace: '{{Label}} může obsahovat pouze čísla nebo mezery',
  CampaignNameAlreadyExist: 'Název kampaně již existuje. Prosím vyberte jiný název.',
  CampaignNameTooShort: 'Název kampaně musí mít mezi 5 a 35 písmeny',
  CampaignNameTooLong: 'Název kampaně musí mít mezi 5 a 35 písmeny',
  CampaignEmpty: 'Název kampaně je povinný',
  CampaignInvalidName: 'Název kampaně nemůže obsahovat speciální znaky',
  UniqueGoalName: 'Název cíle již existuje. Prosím, zadejte jiný název.',
  UniqueTaskName: 'Stávající název. Zadejte prosím jiný název.',
  StartCampaignError: 'Při zastavování kampaně se vyskytla chyba. Zkuste to prosím později.',
  StopCampaignError: 'Při spouštění kampaně se vyskytla chyba. Zkuste to prosím později.',
  DuplicateCompanyError: 'Společnost nelze duplikovat: status i produkt již byly přiřazeny.',
  DeleteCampaign: 'Při odstraňování kampaně se vyskytla chyba. Zkuste to prosím později.',
  ChangeCampaignName: 'Při změně názvu kampaně se vyskytla chyba. Zkuste to prosím později.',
  ChangeEndDate: 'Při změně data ukončení se vyskytla chyba. Zkuste to prosím později.',
  ChangeStartDate: 'Při změně data začátku se vyskytla chyba. Zkuste to prosím později.',
  ChangeCampaignNote: 'Při změně poznámky se vyskytla chyba. Zkuste to prosím později.',
  AddCompanyNote: 'Při přidávání poznámky se vyskytla chyba. Zkuste to prosím později.',
  DeleteCompanyNote: 'Při odstraňování poznámky se vyskytla chyba. Zkuste to prosím později.',
  FetchCompanyNotes: 'Při načítání poznámek se vyskytla chyba. Zkuste to prosím později.',
  AssignTask: 'Při přidělování vybrané úlohy se vyskytla chyba. Zkuste to prosím později.',
  CampaignDownloadError: 'Při stahování Excel souboru došlo k chybě. Zkuste to prosím později.',
  RenewCampaign: 'Obnovení této kampaně není možné, protože neobsahuje platné portfolio.',
  AssignCampaignError: 'Při ukládání vašich úloh se vyskytla chyba. Zkuste to prosím později.',
  AssignCampaignErrorCompaniesDuplicated:
    'Při ukládání vašich úloh se vyskytla chyba. Existuje jedna nebo více duplicitních společností.',
  FetchCampaigns: 'Při načítání kampaní se vyskytla chyba. Zkuste to prosím později.',
  SaveCampaign: 'Při ukládání kampaně se vyskytla chyba. Zkuste to prosím později.',
  DuringCampaignCreation: 'Došlo k chybě a nelze dokončit proces vytváření kampaně. Zkuste to prosím později.',
  FetchCompanies: 'Při načítání společností se vyskytla chyba.',
  EnrichCompany: 'Je nám líto, ale obohacení společností selhalo.',
  NotFound: {
    Title: 'Nenalezeno',
    Text: 'Litujeme, stránka, kterou jste navštívili, neexistuje.',
    BackHome: 'Zpět domů',
    DocumentNotFound: 'Dokument již není k dispozici.',
    PortfolioNotFound: 'Portfolio již není k dispozici.',
    CampaignNotFound: 'Kampaň již není k dispozici.',
    GoBack: 'Zpět',
    QueryNotFound: 'Dotaz není k dispozici.',
    ReportLoadFailed: 'Report není k dispozici nebo neexistuje.',
    Section: 'Sekce není k dispozici',
    SectionForCountry: 'Tato sekce není k dispozici pro vaši zemi ({{country}}) ',
    EnrichmentNotPossible: 'Obohacení není možné.',
    EnrichmentNotPossibleBody:
      'Společnost, kterou hledáte, není obohacitelná, protože může patřit do portfolia skrytého jiným uživatelem vaší organizace.',
    ReportNotAvailable: 'Zpráva není k dispozici.',
    ReportNotAvailableBody:
      'Firma, kterou hledáte, není přístupná, protože může patřit do portfolia skrytého jiným uživatelem ve vaší organizaci.'
  },
  ErrorPage: {
    Text: 'Došlo k neočekávané chybě.'
  },
  ConfigGuardErrorPage: {
    Title: 'Omlouváme se',
    Text: 'Konfiguraci aplikace se nepodařilo načíst.',
    Reload: 'Znovu načíst konfiguraci',
    Logout: 'Odhlásit'
  },
  CreateProduct: 'Je nám líto, ale nemohli jsme vytvořit váš produkt.',
  UniqueProductName: 'Název produktu již existuje. Prosím, zadejte jiný název.',
  PasswordSameAsOld: 'Heslo nemůže být stejné jako staré heslo',
  PasswordCouldNotBeChanged: 'Heslo nebylo možné změnit. Zkontrolujte prosím, zda heslo splňuje pravidla.',
  InvalidOldPassword: 'Zadané heslo není správné.',
  UsernameDoesMatch: 'Zosobněné uživatelské jméno je stejné jako uživatelské jméno',
  PasswordContainsUsername: 'Heslo nemůže obsahovat uživatelské jméno',
  PasswordCharacterAndDigit: 'Heslo musí obsahovat nejméně jedno písmeno a jednu číslici',
  PasswordMultipleCharacters: 'Heslo nemůže obsahovat tři po sobě následující stejné znaky',
  PasswordDoesNotMatch: 'Heslo se neshoduje',
  DisallowedPassword: 'Heslo není povoleno',
  SelectAtLeastOneCompany: 'Musíte vybrat alespoň jednu společnost.',
  TargetingLoadPortfolio: 'Chyba při načítání vybraného portfolia',
  TargetingLoadPortfolioDescription: 'Prosím, zkuste to znovu později, nebo vybrané portfolio nemusí být platné.',
  TargetingLoadQuery: 'Chyba při načítání vybraného dotazu',
  TargetingLoadQueryDescription: 'Prosím, zkuste to znovu později, nebo vybraný dotaz nemusí být platný.',
  TargetingDownloadAnalysisError: 'Vyskytla se chyba a vaši analýzu není možné stáhnout.',
  GenericMessage: 'Při zpracování vašeho dotazu se vyskytla chyba.',
  LoginSE: 'Vyskytla se chyba při přihlášení, zkuste to znovu později.',
  TooManyWordsInFilter:
    'Byl překročen maximální počet 1 000 slov, které je možné zadat současně ({{wordCount}} / 1 000)',
  NoEmptyValues: 'Dotaz nesmí obsahovat prázdné hodnoty.',
  MinValueIsRequired: '"Min" hodnota je povinná',
  MaxValueIsRequired: '"Max" hodnota je povinná',
  MatchingTermDoesNotExist: '{{matchingTerm}} neexistuje',
  CountryRequired: 'Prosím, vyberte zemi',
  R_24: 'Aktualizace společnosti by způsobila duplicitu. Společnost se stejnou "Subjekt v portofliu" a "Produkt" již v portfoliu existuje.',
  1005: 'Maximální počet značek překročil povolený limit 20.'
}
