import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LoadQueryDialog } from '../../../containers/Query/LoadQueryDialog'
import { DialogActions } from '../../../features/dialog/actions'
import { OrderByOption, SemanticSearch } from '../../../features/filters/types'
import { CompoundExpression } from '../../../features/operations/types'
import { Query } from '../../../features/query/types'
import { prospectingQueriesEnabled } from '../../../features/user/selectors'
import { QueryType } from '../../../types'
import { SaveBeforeLoadDialog } from './SaveBeforeLoadDialog'

type Props = {
  canSaveQuery: boolean
  contextData: {
    orderBy: OrderByOption[]
    expression: CompoundExpression
    semanticSearch?: SemanticSearch
    excludedPortfoliosIds?: string[]
    selectedPortfoliosIds?: string[]
    setQuerySaved: (saved: boolean) => void
  }
}

export const LoadQueryDialogContainer = ({ canSaveQuery, contextData }: Props) => {
  const [saveSkipped, setSaveSkipped] = useState(false)
  const prospectingQueriesAllowed = useSelector(prospectingQueriesEnabled)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const close = () => dispatch(DialogActions.closeDialog())

  const submit = (query?: Query) => {
    contextData.setQuerySaved(true)
    navigate(
      { pathname: window.location.pathname },
      {
        replace: true,
        state: {
          shouldPreloadFilters: false,
          query,
          semanticPreFilled: undefined
        }
      }
    )
    close()
  }

  if (!saveSkipped && canSaveQuery && prospectingQueriesAllowed) {
    return (
      <SaveBeforeLoadDialog
        saveQuery={() => {
          close()
          dispatch(DialogActions.showDialogWithData('SaveProspectingQuery', { contextData }))
        }}
        skipSaveQuery={() => setSaveSkipped(true)}
        close={close}
      />
    )
  }
  return <LoadQueryDialog submit={submit} close={close} defaultQueryType={QueryType.ProspectingQueries} />
}
