import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { RadioChangeEvent } from 'antd3/lib/radio'
import { getCompanyName } from '../../../features/config/selectors'
import { useGetPortfolioList, validateNewPortfolioName } from '../../../api/portfolio'
import { Option } from '../../../components/Select'
import { Radio } from '../../../components/Radio'
import { Text } from '../../../components/Text'
import { EnrichCompanyTargetPortfolio, FormState } from './types'
import { HookForm, InputField, SelectField } from '../../../components/Form'
import { validateNameSynchronous, required } from '../../../utils/validators'
import { PortfolioOwnership } from '../../../api/portfolio/types'

const FormRow = styled.div`
  margin-top: 20px;
`

type Props = {
  form: UseFormReturn<FormState, object>
  submitForm: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export const AssignOrCreatePortfolio: React.FC<Props> = ({ form, submitForm }) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const companyName = useSelector(getCompanyName)
  const { formState, control, watch, handleSubmit } = form
  const { isSubmitting } = formState
  const { data: portfolios, isLoading: portfoliosLoading } = useGetPortfolioList({
    filterMonitored: true,
    ownership: PortfolioOwnership.Owned
  })
  const disableUpdatePortfolio = !portfoliosLoading && _.isEmpty(portfolios)

  const asyncValidatePortfolioName = async (value: any) => {
    const validationResponse = await validateNewPortfolioName(value)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(true)(watch('newPortfolioName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  return (
    <Controller
      control={form.control}
      name="targetPortfolio"
      render={({ field }) => {
        const { value: targetPortfolio, onChange, ...rest } = field
        const onChangeRadio = (e: RadioChangeEvent) => {
          if (form.formState.touchedFields.newPortfolioName) form.trigger()
          onChange(e)
        }
        return (
          <HookForm handleSubmit={handleSubmit} onSubmit={submitForm} isSubmitting={isSubmitting}>
            <Text text={t('Enrich.PortfolioInfo1')} block />
            <Text text={t('Enrich.PortfolioInfo2', { companyName })} block />
            <FormRow>
              <Radio
                disabled={disableUpdatePortfolio || form.formState.isValidating}
                {...rest}
                onChange={onChangeRadio}
                checked={targetPortfolio === EnrichCompanyTargetPortfolio.ExistingPortfolio}
                value={EnrichCompanyTargetPortfolio.ExistingPortfolio}
              >
                {t('Enrich.SelectPortfolio')}
              </Radio>
              <SelectField
                label={t('Enrich.UploadedPortfolio')}
                control={control}
                formState={formState}
                name="targetPortfolioId"
                loading={portfoliosLoading}
                onChange={() => form.trigger('targetPortfolioId')}
                required={targetPortfolio === EnrichCompanyTargetPortfolio.ExistingPortfolio}
                disabled={targetPortfolio === EnrichCompanyTargetPortfolio.NewPortfolio}
                validators={
                  targetPortfolio === EnrichCompanyTargetPortfolio.ExistingPortfolio
                    ? {
                        required
                      }
                    : {}
                }
              >
                {_.map(portfolios, portfolio => (
                  <Option key={portfolio.id} value={portfolio.id}>
                    {portfolio.name}
                  </Option>
                ))}
              </SelectField>
            </FormRow>
            <FormRow>
              <Radio
                {...rest}
                onChange={onChangeRadio}
                checked={targetPortfolio === EnrichCompanyTargetPortfolio.NewPortfolio}
                value={EnrichCompanyTargetPortfolio.NewPortfolio}
              >
                {t('Enrich.CreateNewPortfolio')}
              </Radio>
              <InputField
                control={form.control}
                formState={formState}
                label={t('Enrich.PortfolioName')}
                name="newPortfolioName"
                validators={
                  targetPortfolio === EnrichCompanyTargetPortfolio.NewPortfolio
                    ? {
                        validateNameSynchronous: validateNameSynchronous(true),
                        asyncValidatePortfolioName
                      }
                    : {}
                }
                disabled={targetPortfolio === EnrichCompanyTargetPortfolio.ExistingPortfolio}
              />
            </FormRow>
          </HookForm>
        )
      }}
    />
  )
}
