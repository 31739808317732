import { CompanyStatusInfo } from './types'

export const MAXIMUM_BASE_DOWNLOAD = 100000
export const MAXIMUM_MARKETING_DOWNLOAD = 100000
export const MAXIMUM_COMPLETE_AND_CUSTOM_DOWNLOAD = 100000

export const PORTFOLIO_MANAGEMENT_PREVIEW_COMPANIES_SIZE = 3
export const PORTFOLIO_COMPANIES_PAGE_SIZE = 20

export const DOWNLOAD_PORTFOLIO_COSX_REPORT_TYPE = 'cosXReportType'

export const companyStatuses: CompanyStatusInfo[] = [
  { status: 'client', tKey: 'common:Client' },
  { status: 'competitor', tKey: 'common:Competitor' },
  { status: 'lead', tKey: 'common:Lead' },
  { status: 'prospect', tKey: 'common:Prospect' },
  { status: 'supplier', tKey: 'common:Supplier' }
]
