import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import _ from 'lodash'
import { rootSaga } from './sagas'
import { rootReducer } from './reducers'
import { applyAxiosInterceptors } from './axios/interceptors'
import { SignalR } from '../utils/signalR'
import { analyticsMiddleware } from './analytics/middleware'
import { actionTracking } from './analytics/tracking'
import { checkValidSession } from '../utils/session'

if (process.env.NODE_ENV !== 'development') {
  checkValidSession()
}

const sagaMiddleware = createSagaMiddleware()

const actionSanitizer = (action: any) => {
  if (_.startsWith(action.type, 'persist')) {
    return { ...action, payload: '<<LONG_DATA>>' }
  }
  return action
}

export const store = createStore(
  rootReducer,
  composeWithDevTools({
    actionSanitizer
  })(applyMiddleware(sagaMiddleware, analyticsMiddleware(actionTracking)))
)

SignalR.setStore(store)

export const persistor = persistStore(store)

applyAxiosInterceptors(store)

// @ts-ignore
sagaMiddleware.run(rootSaga, persistor)
