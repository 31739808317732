import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getSalestoolKeys, useGetCampaign, useGetProductsList } from '../../../../../api/salestool'
import { Select, Option } from '../../../../../components/Select'
import { Row, Col } from '../../../../../components/Grid'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import { Text } from '../../../../../components/Text'
import { Dialog } from '../../../../../components/Dialog'
import { DuplicateCompanyViewState } from '../../types'
import { getCompanyStatus } from '../../../../../features/config/selectors'
import { BaseDialog } from '../../../BaseDialog'
import { Product } from '../../../../../features/salestool/types'
import { Dictionary } from '../../../../../utils/types'
import { queryClient } from '../../../../../features/queryclient'

const SelectionRow = styled(Row)`
  .selection-error {
    .ant-select {
      .ant-select-selection {
        border: 1px solid ${({ theme }) => theme.colors.watermelon};
        :focus {
          box-shadow: 0 0 0 2px rgba(255, 60, 101, 0.2);
        }
      }
    }
  }
  .ant-select {
    .ant-select-selection-selected-value {
      color: ${({ theme }) => theme.colors.main};
    }
  }
`

type Props = {
  isLoading: boolean
  view: DuplicateCompanyViewState
  companyId: string
  companyName: string
  campaignId: string
  error?: string
  validate: ({ status, product }: { status: string; product: string }) => void
  onClickYes: () => void
  onClickSave: (status: string, product: string) => void
  close: () => void
  isCompanyDuplicationAllowed: boolean | string
}

export const DuplicateCompanyNoteDialog = ({
  isLoading,
  view,
  companyName,
  campaignId,
  onClickYes,
  onClickSave,
  validate,
  error,
  close,
  isCompanyDuplicationAllowed
}: Props) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [selectedProduct, setSelectedProduct] = useState<string>('')

  const { t } = useTranslation(['salestool', 'common'])

  const { data: productsList } = useGetProductsList<Dictionary<Product>>({
    select: data => _.keyBy(data.items, 'id')
  })
  const companyStatusList = useSelector(getCompanyStatus)

  const { data: campaign } = useGetCampaign(campaignId)

  const { DuplicateCompanyDone, DuplicateCompanyView, DuplicateCompanyViewSelection, DuplicateCompanyError } =
    DuplicateCompanyViewState

  useEffect(() => {
    if (selectedStatus && view === DuplicateCompanyViewSelection)
      validate({ status: selectedStatus, product: selectedProduct })
  }, [DuplicateCompanyViewSelection, selectedProduct, selectedStatus, validate, view])

  const duplicateCompanyHandler = () => onClickSave(selectedStatus, selectedProduct)

  const closeAndReload = () => {
    close()
    queryClient.invalidateQueries(getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId))
    queryClient.invalidateQueries(getSalestoolKeys.GetCampaignStats(campaignId))
  }

  const props = {
    spinDelay: 200,
    destroyOnClose: false,
    width: 800,
    visible: true,
    loading: isLoading,
    title: t('AssignCampaign.Dialog.DuplicateCompanyTitle'),
    footer: '',
    onCancel: view === DuplicateCompanyDone ? closeAndReload : close
  }

  if (view === DuplicateCompanyView) {
    return (
      <Dialog
        {...props}
        title={t('AssignCampaign.Dialog.DuplicateCompanyTitle')}
        footer=""
        cancelText={t('common:Cancel')}
        okText={t('common:Yes')}
        onOk={onClickYes}
      >
        <Text block margin={{ bottom: 20 }}>
          {t('AssignCampaign.Dialog.DuplicateCompanyConfirm', { companyName })}
        </Text>
      </Dialog>
    )
  }

  if (view === DuplicateCompanyViewSelection) {
    const showError = () => (isCompanyDuplicationAllowed === false ? 'selection-error' : undefined)

    return (
      <Dialog
        {...props}
        cancelText={t('common:Cancel')}
        okText={t('common:Save')}
        okButtonProps={{ disabled: isLoading || !isCompanyDuplicationAllowed }}
        onOk={duplicateCompanyHandler}
      >
        <Row>
          <Col>
            <Text block margin={{ bottom: 20 }}>
              {t('AssignCampaign.Dialog.DuplicateCompanySelection')}
            </Text>
          </Col>
        </Row>
        <SelectionRow padding="20px 0">
          <Col md={6}>
            <div>
              <Text>{t('AssignCampaign.Dialog.DuplicateCompanyStatus')}</Text>
              <Select
                dropdownClassName="dialog-select-menu"
                placeholder={t('AssignCampaign.Dialog.DuplicateCompanySelectStatus')}
                onSelect={value => setSelectedStatus(value as string)}
                className={showError()}
                value={selectedStatus}
              >
                {_.map(companyStatusList, compStatus => (
                  <Option key={compStatus.id} value={_.trim(compStatus.tKey, 'common:')}>
                    {t(compStatus.tKey)}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <Text>{t('AssignCampaign.Dialog.DuplicateCompanyProduct')}</Text>
              <Select
                dropdownClassName="dialog-select-menu"
                placeholder={t('AssignCampaign.Dialog.DuplicateCompanySelectProduct')}
                onSelect={value => setSelectedProduct(value as string)}
                className={showError()}
                showSearch
                optionFilterProp="title"
                value={selectedProduct}
              >
                {_.map(productsList, product => (
                  <Option key={product.id} title={product.name} value={product.id}>
                    {product.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </SelectionRow>
        {isCompanyDuplicationAllowed === false && (
          <Row>
            <Col>
              <Text type="danger">{t('error:DuplicateCompanyError')}</Text>
            </Col>
          </Row>
        )}
      </Dialog>
    )
  }

  if (view === DuplicateCompanyDone) {
    const campaignName = campaign?.campaignName ?? ''
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={closeAndReload}>
        <BaseDialog
          content={t('AssignCampaign.Dialog.DuplicateCompanyDone', { companyName, campaignName })}
          image={<img src={OkImage} alt="ok" />}
        />
      </Dialog>
    )
  }

  if (view === DuplicateCompanyError) {
    const errorMessage = error === 'MissingPortfolio' ? 'DuplicateCampaignMissingPortfolio' : 'DuplicateCampaign'
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={close}>
        <BaseDialog content={t(`AssignCampaign.Errors.${errorMessage}`)} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  return null
}
