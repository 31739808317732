import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '../../../components/Dialog'
import { Text } from '../../../components/Text'
import { ConfirmDialogData } from './types'

type Props = {
  close: () => void
  data: ConfirmDialogData
}

export const ConfirmDialog = ({ close, data }: Props) => {
  const { t } = useTranslation('common')

  const { title, text, description, confirmText, cancelText, confirmAction, closeOnConfirm = true } = data

  return (
    <Dialog
      loading={false}
      visible
      title={title}
      cancelText={cancelText ?? t('common:Cancel')}
      okText={confirmText ?? t('common:Confirm')}
      onOk={() => {
        confirmAction()
        if (closeOnConfirm) close()
      }}
      onCancel={close}
      footer
    >
      <Text block>{text}</Text>
      {description && (
        <Text size="S" block margin={{ top: 20 }}>
          {description}
        </Text>
      )}
    </Dialog>
  )
}
