import { Checkbox as AntCheckbox } from 'antd3'
import styled, { css } from 'styled-components/macro'

export const checkboxMixin = css`
  .ant-checkbox-wrapper {
    height: 18px;
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      border-radius: 4px;
      border-color: ${({ theme }) => theme.colors.paleGrey} !important;
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.colors.main} !important;
        background-color: ${({ theme }) => theme.colors.main};
      }

      &.ant-checkbox-disabled {
        .ant-checkbox-inner {
          border-color: ${({ theme }) => theme.colors.disabled} !important;
          background-color: ${({ theme }) => theme.colors.disabled};
        }

        ::after {
          display: none;
        }
      }

      ::after {
        display: none;
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.colors.paleGrey};
        box-shadow: 0 4px 8px 0 rgba(0, 60, 125, 0.2);
      }
    }
  }
`

export const Checkbox = styled(AntCheckbox)`
  ${checkboxMixin}
`

export const CheckboxBase = styled(AntCheckbox)``
