/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: "Type d'analyse",
    StartAnalysis: "Pour démarrer l'analyse, sélectionner le type d'analyse à traiter",
    MarketAnalysisBtn: 'Démarrer nouvelle analyse de marché',
    LoadQuery: 'Charger requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    PortfolioQuery: 'Recherche Gestion des portefeuilles',
    TargetingQuery: 'Ciblage de requête',
    ProspectingQuery: 'Requête de prospection',
    ChooseQuery: 'Choisir quelle requête charger',
    MarketAnalysisBtnTooltip:
      'Visualiser et segmenter un ensemble de sociétés en fonction des principales variables et élaborer votre cible',
    FromTargeting: "Charger requête d'analyse de marché",
    FromTargetingTooltip: 'Ouvrir une requête enregistrée dans le module Analyser marché',
    FromProspecting: 'Charger requête de prospection',
    FromProspectingTooltip: 'Ouvrir une requête enregistrée dans le module Prospection'
  },
  ChartLabels: {
    Company: 'Société',
    Company_plural: 'Sociétés',
    ChartDetail: 'Détails du graphique',
    PercentageOfCompanies: '% de sociétés',
    NumberOfCompanies: 'n° de sociétés',
    NumberOfEmployees: "n° d'employés",
    Amount: 'Montant',
    Risk: 'Niveau de risque',
    Employees: 'Employés',
    Revenue: "Chiffre d'affaire",
    RevenueCz: "Chiffre d'affaire",
    RevenueSk: "Chiffre d'affaire",
    Other: 'Autre',
    Years: 'Années',
    Status: 'Statut',
    HiddenNDNotice: 'Attention : ce graphique ne montre pas la catégorie de sociétés sans données'
  },
  Header: {
    Targeting: 'Analyser marché',
    Title: 'Analyse de marché',
    LoadQuery: 'Charger requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    SaveQuery: 'Enregistrer requête',
    SaveQueryTooltip: 'Enregistrer cette requête dans le module Analyser marché',
    Download: 'Télécharger PDF/Excel',
    DownloadTooltip: 'Télécharger un rapport de cette requête sur votre dispositif',
    LoadPortfolio: 'Charger portefeuille',
    LoadPortfolioTooltip: 'Ouvrir et analyser un portefeuille',
    SendToProspecting: 'Envoyer requête vers Prospection',
    SendToProspectingTooltip: 'Afficher la liste des sociétés appartenant à cette requête',
    SendToProspectingFromPortfolioTooltip:
      'Afficher les entreprises prospects qui correspondent aux critères de recherche',
    SendToProspectingMessage: "Pour activer ce bouton, appliquer un filtre à l'analyse",
    SaveList: 'Enregistrer liste',
    SaveListTooltip: 'Enregistrer cette liste de sociétés dans le portefeuille'
  },
  Filters: {
    CompanyCount: 'Société',
    CompanyCount_plural: 'Sociétés',
    ActiveCompanyCount: 'Actif',
    ActiveCompanyCount_plural: 'Actif',
    OtherCompanyCount: 'Autre',
    StatusSelectLabel: 'Choisir statut',
    ShowBySelectLabel: 'Afficher par',
    AggregationFieldAmount: 'Montant',
    AggregationFieldNumCompanies: 'Décompte de sociétés'
  },
  Search: {
    SemanticSearch: 'Recherche sémantique',
    SearchPlaceholder: 'Rechercher',
    All: 'Tous',
    OfficialData: 'Données officielles',
    Website: 'Web & Réseaux sociaux',
    Synonyms: 'Synonymes',
    Advanced: 'Recherche sémantique avancée',
    AdvancedSwitch: 'Avancée',
    AdvancedTooltip:
      "La Recherche sémantique avancée permet d'utiliser plusieurs mots liés par des opérateurs logiques (AND, OR, NOT). Pour l'activer, placer le curseur sur ON. Télécharger le document pour plus d'informations.",
    DownloadSsGuide: 'Télécharger le guide de recherche sémantique'
  },
  DomainFilter: {
    SaveAndClose: 'Enregistrer filtres & fermer'
  },
  SaveQuery: {
    DialogTitle: 'Enregistrer recherche',
    Description:
      "Saisir le nom de sauvegarde de la recherche ou sélectionner une requête existante pour l'écraser en écriture.",
    InputLabel: 'Nom de requête',
    InputPlaceholder: 'Nom de recherche',
    UpdateExisting: 'Actualiser existante',
    CreateNew: 'Créer nouveau',
    AddToDashboardConfirmMessage: 'Voulez-vous ajouter une requête au tableau de bord ?',
    AddToDashboardCheckboxText: 'Afficher dans le tableau de bord',
    MaximumWidgetCountReached: 'Le nombre maximum de cases du tableau de bord est atteint.',
    SavedQuery: 'enregistré correctement.',
    SavedQueryAndDashboard: 'enregistré et ajouté dans le tableau de bord.',
    SavedQueryDescription: 'La requête enregistrée se trouve dans le répertoire relatif à la zone de référence.'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Choisir quelle requête de prospection charger',
    IntroSectionTitleTargeting: 'Choisir quelle requête de ciblage charger',
    IntroSectionDescription: 'Une recherche déjà lancée peut être reprise.',
    DialogTitle: 'Charger recherche enregistrée',
    DialogSubTitle: 'Sélectionner recherche enregistrée',
    DialogDescription:
      "Choisir l'analyse à poursuivre, il est possible de reprendre l'analyse à partir de celles enregistrées.",
    SelectLabel: 'Requête enregistrée',
    Load: 'Charger',
    LoadingMessage: 'Chargement des analyses',
    InvalidPortfolioTitle: 'Portefeuille non valable',
    InvalidPortfolioMessage:
      "Le portefeuille enregistré dans la requête n'est plus valable.\nVotre analyse se basera sur toutes les sociétés présentes dans la base de données de {{companyName}}.",
    TargetingRadio: 'Ciblage de requête',
    ProspectingRadio: 'Recherche de prospection'
  },
  LoadPortfolio: {
    DialogTitle: 'Charger portefeuille',
    DialogSubTitle: 'Sélectionner un portefeuille',
    Description: "Choisir sur quel portefeuille exécuter l'analyse.",
    SelectLabel: 'Portefeuille enregistré',
    Load: 'Charger',
    LoadingMessage: 'Chargement de portefeuille'
  },
  SaveBeforeContinuing: {
    Question: 'Voulez-vous enregistrer la recherche de marché avant de charger la recherche ?',
    Description:
      "Enregistrer la recherche de marché dans une nouvelle avant de charger la requête afin d'éviter toute perte de données.",
    OkBtn: 'Enregistrer recherche',
    CancelBtn: 'Omettre enregistrement'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: 'Sélectionner le portefeuille à analyser',
      Description:
        "Vous pouvez traiter l'analyse d'un ou plusieurs de vos portefeuilles ou visualiser l'analyse agrégée de toutes les entreprises du système {{companyName}}.",
      PortfolioOption: 'Sélectionner portefeuille',
      AggregateOption: 'Toutes les sociétés dans le système de {{companyName}}',
      UploadedPortfolio: 'Portefeuille chargé'
    },
    StepTwo: {
      Header: 'Choisir le statut à analyser',
      DescriptionLine1: 'Sélectionner le statut initial des sociétés à analyser.',
      DescriptionLine2: "Pendant l'analyse, le statut est modifiable à l'aide des filtres.",
      Status: 'Statut'
    }
  },
  Download: {
    ModalTitle: 'Télécharger PDF/Excel',
    SelectType: 'Sélectionner format',
    SelectTypeDescription:
      "Télécharger le rapport complet sur l'analyse en deux formats différents ; sélectionner le type de liste à télécharger.",
    DownloadAnalysis: 'Télécharger vos analyses',
    Pdf: 'PDF',
    PdfDescription: 'Télécharger au format .pdf',
    PdfRecap: 'Un fichier contenant tous les résultats de cette analyse est téléchargé au format PDF.',
    Excel: 'Excel',
    ExcelDescription: 'Télécharger au format .xlsx',
    ExcelRecapLine1: 'Un fichier contenant tous les résultats de cette analyse est téléchargé sous format de liste.',
    ExcelRecapLine2: 'Il sera enregistré dans la section « Analyser marché » de votre compte.',
    CompanyInPortfolio: 'Société dans ce portefeuille',
    CompanyInPortfolio_plural: 'Sociétés dans ce portefeuille',
    CompanyInAnalysis: 'Société analysée',
    CompanyInAnalysis_plural: 'Sociétés analysées',
    DownloadPdf: 'Télécharger PDF',
    DownloadPdfPleaseWait: 'Attendre la génération du rapport PDF',
    DownloadAllChartsMustBeLoaded:
      'Pour télécharger le rapport, attendre la fin du téléchargement de tous les graphiques',
    DownloadPdfDisabledIE:
      "Téléchargement PDF non pris en charge sur cette version d'IE. Actualiser le navigateur pour le télécharger.",
    PdfPage: {
      Title: "Rapport d'analyse",
      CompanyPortfolioStatus: 'Statut des sociétés dans portefeuille',
      DataShownBy: 'Données affichées par',
      ExcludedPortfolios: 'Portefeuille exclus'
    }
  },
  UnavailableForMobile:
    "La page à laquelle vous tenez d'accéder est disponible uniquement sur tablettes et ordinateurs portables.",
  ChartFetchError: 'Une erreur est survenue durant le chargement de ce graphique.',
  ChartFetchRetry: 'Réessayer',
  ChartEmpty: 'Aucune donnée pour ce graphique',
  BasedOnHeadquarter: 'Les chiffres affichés sont relatifs au siège administratif',
  VisualizeAs: {
    List: 'Visualiser en liste',
    Map: 'Visualiser sur carte'
  },
  WarningRespectingLocalUnits: {
    Title: 'Enrichissement',
    Text: "Les sociétés à bureau de type « Siège administratif », « Siège administratif et siège social » et « Siège social » ont été enrichies (si sélectionnées). Pour enrichir des sociétés à bureau de type « Unité locale », activer l'extension Unités locales en contactant le service client."
  }
}
