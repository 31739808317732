import _ from 'lodash'
import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useLoadCampaignCompanyPopupData } from '../../api/companyPopup'
import { CampaignCompanyPopupDataRequest } from '../../api/companyPopup/types'
import { CampaignCompaniesRes } from '../../api/salestool/types'
import { getSalestoolRedirectState } from '../../features/companyReport/utils'
import { CompoundExpression, FieldOperator } from '../../features/operations/types'
import { getDefaultRootExpression, getEmptyFieldExpression } from '../../features/operations/utils'
import * as routes from '../../routes'
import { CompanyDetailsType } from '../../types'
import { formatField } from '../../utils/helpers'
import { Col, Row } from '../Grid'
import { LinkTitle, Text } from '../Text'
import { CompanyPopupLoading } from './CompanyPopupLoading'
import { CompanyPopupNotFound } from './CompanyPopupNotFound'
import { HeaderCol, HeaderRow, InfoWrapper, SmallHeader } from './styled'

const formatCompanyFields = (response: CampaignCompaniesRes) => {
  const { configuration, data } = response
  const { cells } = _.head(data) || {}
  const companyFieldMap = _.keyBy(cells, 'columnId')
  return _.map(configuration.fields, field => ({
    label: field.text,
    value: formatField(field.fieldType, companyFieldMap[field.jsonPath]?.text ?? companyFieldMap[field.jsonPath]?.value)
  }))
}

const getCompanyUnitIdQuery = (companyId: string): CompoundExpression => {
  const expression = getDefaultRootExpression()
  const companyUnitIdExpressionFilter = getEmptyFieldExpression('companyUnitId', companyId, FieldOperator.Equals)

  return {
    ...expression,
    childExpressions: _.concat(expression.childExpressions, companyUnitIdExpressionFilter)
  }
}

type Props = {
  companyId: string
  openCompanyReport?: (companyUnitId: string, identificationCode: string) => void
}

type MatchParams = {
  campaignId: string
}

const CampaignCompanyPopupFC: React.FC<Props> = ({ companyId, openCompanyReport }) => {
  const params = useParams()
  const { campaignId } = params as MatchParams

  const query = getCompanyUnitIdQuery(companyId)

  const requestData: CampaignCompanyPopupDataRequest = {
    pageType: CompanyDetailsType.Map,
    query
  }

  const { data: companyData, isLoading } = useLoadCampaignCompanyPopupData(campaignId, requestData)
  const company = _.head(companyData?.data)

  if (isLoading) {
    return <CompanyPopupLoading />
  }
  if (!companyData || !company) {
    return <CompanyPopupNotFound />
  }

  const filteredRows = _.reject(formatCompanyFields(companyData), row => !row.value)

  return (
    <InfoWrapper>
      <HeaderRow>
        <HeaderCol>
          <LinkTitle
            level={3}
            to={
              openCompanyReport
                ? undefined
                : {
                    pathname: generatePath(routes.COMPANY_REPORT, {
                      companyUnitId: company.companyUnitId,
                      identificationCode: company.identificationCode
                    })
                  }
            }
            state={openCompanyReport ? undefined : getSalestoolRedirectState(campaignId)}
            onClick={
              openCompanyReport ? () => openCompanyReport(company.companyUnitId, company.identificationCode) : undefined
            }
          >
            {company.companyName}
          </LinkTitle>
        </HeaderCol>
      </HeaderRow>
      {_.map(filteredRows, row => {
        return (
          <Row key={`comp-popup-detail-${row.label}`} padding="25px 0 0 0">
            <Col col={12}>
              <SmallHeader primary size="S" bold text={row.label} />
              <Text size="M" text={row.value} />
            </Col>
          </Row>
        )
      })}
    </InfoWrapper>
  )
}

export const CampaignCompanyPopup = React.memo(CampaignCompanyPopupFC)
