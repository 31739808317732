import { GeoJsonProperties } from 'geojson'
import _ from 'lodash'
import { CompanyFieldConfig } from '../../../api/companyPopup/types'
import { PortfolioCompany } from '../../portfolioCompany/types'
import { formatField } from '../../../utils/helpers'
import { IMapCountryCustomizations, Field } from './types'
import { MapChartMetadata } from '../../../api/targeting/types'

export const mapConfig = {
  level1Key: 'COD_REG',
  level2Key: 'COD_PRO',
  dataKey: 'count',
  regionNameKey: 'NAME'
}

export abstract class MapCountryCustomization implements IMapCountryCustomizations {
  minZoomLevel: number

  maxZoomLevel: number

  constructor(mapConfiguration?: MapChartMetadata) {
    this.minZoomLevel = mapConfiguration?.minZoomLevel ? parseInt(mapConfiguration?.minZoomLevel, 10) : 5
    this.maxZoomLevel = mapConfiguration?.maxZoomLevel ? parseInt(mapConfiguration?.maxZoomLevel, 10) : 7
  }

  // eslint-disable-next-line class-methods-use-this
  formatCompanyFields = (company?: PortfolioCompany, fields?: CompanyFieldConfig[]): Field<any>[] => {
    if (!company || !fields) return []

    return _.map(fields, field => {
      const fieldValue = _.get(company.fields, field.jsonPath)
      const cellValue = _.get(company.cells, field.jsonPath)

      return {
        label: field.text,
        value: formatField(field.fieldType, cellValue ?? fieldValue)
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getGeoJsonType = (zoom: number): string => (zoom === 7 ? 'provinces' : 'regions')

  getMaxZoom = (withGeoData: boolean): number => (withGeoData ? this.maxZoomLevel : 19)

  getMinZoom = (withGeoData: boolean): number => (withGeoData ? this.minZoomLevel : 1)

  // eslint-disable-next-line class-methods-use-this
  getPathFromGeoJsonFeature = (featureProps: GeoJsonProperties): string | undefined => {
    if (featureProps === null) return undefined

    const { level1Key, level2Key } = mapConfig
    const regionCode = _.get(featureProps, `${level1Key}`)
    const provinceCode = _.get(featureProps, `${level2Key}`)

    return provinceCode ? `${regionCode}.sub.${_.toLower(provinceCode)}` : `${regionCode}`
  }
}
