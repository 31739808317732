import _ from 'lodash'
import React from 'react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputProps as AntInputProps } from 'antd'
import { TextAreaProps as AntTextAreaProps } from 'antd/es/input'
import { getError, getErrorMessage } from '../../features/form/utils'
import { FormError } from '../../utils/validators'
import { withLabelRequired } from '../../utils/helpers'
import { Input, Password, TextArea } from '../Input'
import { FormItem } from './FormField'

export type HookTextAreaProps<T extends FieldValues> = {
  noResize?: boolean
} & UseControllerProps<T> &
  AntTextAreaProps

export const HookTextArea = <T extends FieldValues>({ control, name, rules, ...props }: HookTextAreaProps<T>) => (
  <Controller control={control} name={name} rules={rules} render={({ field }) => <TextArea {...field} {...props} />} />
)

export type HookInputProps<T extends FieldValues> = {
  password?: boolean
} & UseControllerProps<T> &
  AntInputProps

export const HookInput = <T extends FieldValues>({
  control,
  name,
  rules,
  password,
  onChange,
  ...restProps
}: HookInputProps<T>) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field: { value, onChange: fieldOnChange, ref, onBlur } }) => {
      if (password) {
        return (
          <Password
            value={value as string}
            ref={ref}
            onBlur={onBlur}
            onChange={e => {
              fieldOnChange(e)
              if (onChange) onChange(e)
            }}
            {...restProps}
          />
        )
      }
      return (
        <Input
          value={value as string}
          ref={ref}
          onBlur={onBlur}
          onChange={e => {
            fieldOnChange(e)
            if (onChange) onChange(e)
          }}
          {...restProps}
        />
      )
    }}
  />
)

export type InputFieldProps<T extends FieldValues = any> = {
  placeholder?: string
  icon?: React.ReactNode
  autoComplete?: string
  hideLabel?: boolean
  required?: boolean
  label?: string
  displayHelp?: boolean
  formState: FormState<any>
  validators?: Record<string, (value: any) => Promise<FormError | FormError[]> | FormError | FormError[] | undefined>
} & HookInputProps<T>

export const InputField = <T extends FieldValues>({
  hideLabel,
  label: inputLabel,
  required,
  name,
  formState,
  rules,
  validators,
  disabled,
  displayHelp = true,
  ...restProps
}: InputFieldProps<T>) => {
  const { t } = useTranslation('error')

  const { errors, isSubmitting } = formState
  const errorMessage = getError(errors, name)

  let rulesOverride
  if (validators) {
    rulesOverride = _.reduce(
      validators,
      (acc, v, key) => ({ ...acc, [key]: getErrorMessage(v, inputLabel ?? '', t) }),
      {}
    )
  }

  const isDisabled = disabled || isSubmitting

  const label = required && inputLabel ? withLabelRequired(inputLabel) : inputLabel

  return (
    <FormItem
      className="rf-field"
      label={!hideLabel ? label : undefined}
      colon={false}
      validateStatus={displayHelp && errorMessage ? 'error' : 'success'}
      help={displayHelp ? errorMessage : undefined}
    >
      <HookInput<T>
        name={name}
        aria-label={inputLabel}
        {...restProps}
        rules={validators ? { validate: { ...rulesOverride } } : rules}
        disabled={isDisabled}
      />
    </FormItem>
  )
}
