import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../components/Text'
import { Dialog } from '../../../components/Dialog'

type Props = {
  close: () => void
}

export const PortfolioErrorLockDialog = ({ close }: Props) => {
  const { t } = useTranslation(['common', 'error'])

  return (
    <Dialog
      visible
      loading={false}
      okText={t('common:Ok')}
      onCancel={close}
      onOk={close}
      footer=""
      title={t('error:PortfolioLockErrorTitle')}
    >
      <Text>{t('error:PortfolioLockError')}</Text>
    </Dialog>
  )
}
