import axios from 'axios'
import { OrderByDirection } from '../types'
import {
  GetAppointmentsRequest,
  AppointmentItem,
  GetAppointmentsResponse,
  GetAppointmentTargetResponse,
  CreateAppointmentRequest,
  BaseAppointmentRequest
} from './types'

export const getAppointments = (request: GetAppointmentsRequest, skip: number): Promise<GetAppointmentsResponse> =>
  axios
    .get<GetAppointmentsResponse>('/api/appointments', {
      params: {
        filter: {
          companyUnitId: request.companyUnitId,
          from: request.from,
          to: request.to,
          excludeExpired: request.excludeExpired
        },
        top: request.top,
        skip,
        Sort: [
          {
            property: 'Start',
            order: OrderByDirection.Asc
          }
        ]
      }
    })
    .then(res => res.data ?? { appointments: [], totalCount: 0 })

export const getAppointmentsTarget = (matchingTerm?: string, companyUnitId?: string, countryCode?: string) =>
  axios
    .get<GetAppointmentTargetResponse>('/api/appointments/target', {
      params: { matchingTerm, companyUnitId, countryCode }
    })
    .then(res => res.data)

export const getAppointment = (appointmentId: string) =>
  axios.get<AppointmentItem>(`/api/appointments/${appointmentId}`).then(res => res.data)

export const deleteAppointments = (id: string, notifyRecipients: boolean) =>
  axios.delete(`/api/appointments/${id}`, {
    data: { notifyRecipients }
  })

export const createAppointment = (request: CreateAppointmentRequest) =>
  axios.post<string>('/api/appointments', request).then(res => res.data)

export const editAppointment = (appointmentId: string, request: BaseAppointmentRequest) =>
  axios.put<string>(`api/appointments/${appointmentId}`, request)
