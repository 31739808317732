import React from 'react'
import _ from 'lodash'
import StepWizard, { StepWizardProps, StepChange } from 'react-step-wizard'
import { Row, Col } from '../Grid'
import { Steps } from '../Steps'
import { ConditionallyVisible } from '../HideFeature'

type Props = {
  stepCount?: number
  onChangeCallback?: (activeStep: number) => void
} & StepWizardProps

type State = {
  step: number
}

export class Wizard extends React.Component<Props, State> {
  state = {
    step: this.props.initialStep || 0 // eslint-disable-line react/destructuring-assignment
  }

  onChangeStep = ({ activeStep }: StepChange) => {
    this.setState({
      step: Math.max(activeStep - 1, 0)
    })
    const { onChangeCallback } = this.props
    if (onChangeCallback) onChangeCallback(activeStep)
  }

  render() {
    const { children, stepCount = _.size(children), ...props } = this.props
    const { step } = this.state
    return (
      <>
        <ConditionallyVisible condition={stepCount > 1}>
          <Row>
            <Col>
              <Steps count={stepCount} current={step} />
            </Col>
          </Row>
        </ConditionallyVisible>
        <Row>
          <Col>
            <StepWizard {...props} onStepChange={this.onChangeStep}>
              {children}
            </StepWizard>
          </Col>
        </Row>
      </>
    )
  }
}
