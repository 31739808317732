import _ from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useFetchPerson } from '../../api/person/queries'
import { DialogActions } from '../../features/dialog/actions'
import { PersonPopupDialogDataGeneral } from '../../features/dialog/dataTypes'
import * as routes from '../../routes'
import { toLocalizedDateFormat } from '../../utils/formatters'
import { Col, Row } from '../Grid'
import { Switch } from '../Switch'
import { LinkTitle, Text } from '../Text'

const InfoWrapper = styled.div`
  width: 546px;
  max-width: 100%;

  &.loading {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
`

const HeaderRow = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryLightPink};
`

const HeaderCol = styled(Col)`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 56px;
  padding-right: 50px;
`

type PopupRowProps = {
  label: string
  value: React.ReactNode
  extra?: React.ReactNode
}

const PopupRow = ({ label, value, extra }: PopupRowProps) => {
  const hasExtra = !_.isNil(extra)
  return (
    <Row padding="25px 0 0 0">
      <Col col={12} md={hasExtra ? 8 : 12}>
        <Text size="S" primary bold block margin={{ bottom: 5 }} text={label} />
        <Text size="M" text={value} />
      </Col>
      {hasExtra && (
        <Col col={12} md={4}>
          {extra}
        </Col>
      )}
    </Row>
  )
}

type Props = PersonPopupDialogDataGeneral

// personData contains person information from report which this popup is shown for
export const PersonPopup: React.FC<Props> = ({ personId, personData, openPersonReport }) => {
  const { t } = useTranslation('personReport')
  const dispatch = useDispatch()

  // only to fetch the enrichment status (if the person is not enriched, no other data are retrieved)
  const { isLoading, data } = useFetchPerson(personId)
  const isEnriched = data?.isEnriched ?? false

  const enrichPerson = useCallback(() => {
    if (isEnriched) return
    dispatch(DialogActions.openEnrichPersonDialog(personId, generatePath(routes.PERSON_REPORT, { personId })))
  }, [dispatch, personId, isEnriched])

  const onClick = useCallback(() => {
    if (openPersonReport) openPersonReport(personId)
    dispatch(DialogActions.closeDialog())
  }, [dispatch, personId, openPersonReport])

  return (
    <InfoWrapper>
      <HeaderRow>
        <HeaderCol>
          <LinkTitle
            level={3}
            to={openPersonReport ? undefined : { pathname: generatePath(routes.PERSON_REPORT, { personId }) }}
            onClick={onClick}
          >
            {personData.name}
          </LinkTitle>
        </HeaderCol>
      </HeaderRow>
      <PopupRow label={t('Popup.TaxCode')} value={personData.taxCode} />
      <PopupRow label={t('Popup.BirthTown')} value={personData.birthTown} />
      <PopupRow
        label={t('Popup.BirthDate')}
        value={toLocalizedDateFormat(personData.birthDate)}
        extra={
          <Switch
            checked={isEnriched}
            checkedChildren={t('Popup.Enriched')}
            unCheckedChildren={t('Popup.Enrich')}
            size="large"
            onClick={enrichPerson}
            disabled={isLoading}
            loading={isLoading}
          />
        }
      />
    </InfoWrapper>
  )
}
