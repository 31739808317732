import React from 'react'
import styled from 'styled-components/macro'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { getThemeColorByName } from '../../utils/helpers'
import { useLocaleNumberFormatter } from '../../hooks/useLocaleNumberFormatter'

const StatisticWrapper = styled(Stack)<{ color?: string }>`
  padding: 22px 24px;
  border-radius: 4px;
  background-color: ${({ theme, color }) => getThemeColorByName(theme, color) || theme.colors.paleGrey};
`

export type StatisticType = 'primary' | 'success' | 'main'

const getColors = (type?: StatisticType) => {
  switch (type) {
    case 'success':
      return {
        color: 'darkMint',
        countColor: 'white',
        textColor: 'white'
      }
    case 'main':
      return {
        color: 'main',
        countColor: 'white',
        textColor: 'white'
      }
    default:
      return {
        color: 'paleGrey',
        countColor: undefined,
        textColor: 'grayishBrown'
      }
  }
}

type Props = {
  type?: StatisticType
  count: number | string
  text: string
}

export const StatisticComponent = ({ type, count, text }: Props) => {
  const { color, countColor, textColor } = getColors(type)
  const { formatNumber } = useLocaleNumberFormatter()
  return (
    <StatisticWrapper className="stat" center gap={8} color={color}>
      <Text size={24} primary bold text={formatNumber(count)} color={countColor} aria-label="statistics count" />
      <Text size="M" primary text={text} color={textColor} aria-label="statistics title" />
    </StatisticWrapper>
  )
}

export const Statistic = React.memo(StatisticComponent)
