import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

type TooltipProps = AntTooltipProps & {
  fillParent?: boolean
}

// span required to avoid Function components cannot be given refs warning
const TooltipContent = styled.span<{ fillParent?: boolean }>`
  display: ${({ fillParent }) => (fillParent ? 'block' : 'inline')};
  width: ${({ fillParent }) => (fillParent ? '100%' : 'auto')};
`

export const BasicTooltip = ({ children, fillParent, ...rest }: TooltipProps) => (
  <AntTooltip showArrow={false} {...rest}>
    <TooltipContent fillParent={fillParent}>{children}</TooltipContent>
  </AntTooltip>
)

export const Tooltip = ({ overlayClassName, ...rest }: TooltipProps) => (
  <BasicTooltip {...rest} overlayClassName={overlayClassName ? `info-tooltip ${overlayClassName}` : 'info-tooltip'} />
)

const StyledTooltip = styled<React.ComponentType<TooltipProps>>(BasicTooltip)`
  overflow: hidden;
`

const DisabledChildrenWrapper = styled.div`
  cursor: not-allowed;
  > * {
    pointer-events: none;
  }
`

/**
 * Workaround for the Tooltip issue: "mouseleave don't trigger on *disabled* inputs or button"
 * that is discovered on the most browsers except IE:
 * Chrome https://bugs.chromium.org/p/chromium/issues/detail?id=120132
 * Mozila https://bugs.chromium.org/p/chromium/issues/detail?id=536905
 * Discussed there https://github.com/react-component/tooltip/issues/18
 */
export const BasicTooltipForDisabledChilds: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  ...props
}) => {
  // eslint-disable-next-line lodash/prefer-lodash-method
  const handledChildren = React.Children.map(children, element => {
    if (React.isValidElement(element) && element?.props?.disabled) {
      return <DisabledChildrenWrapper>{children}</DisabledChildrenWrapper>
    }
    return element
  })

  return <StyledTooltip {...props}>{handledChildren}</StyledTooltip>
}
