import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button'
import { Text } from '../Text'
import { BasicTooltipForDisabledChilds } from '../Tooltip'
import { WizardNavWrapper } from './components'

export type NavProps = {
  // WizardNav passes props in "unusual" way
  // eslint-disable-next-line react/no-unused-prop-types
  totalSteps?: number
  // eslint-disable-next-line react/no-unused-prop-types
  currentStep?: number
  previousDisabled?: boolean
  previousHidden?: boolean
  nextDisabled?: boolean
  nextLoading?: boolean
  previousStep?: () => void
  nextStep?: () => void
  nextStepText?: string
  previousStepText?: string
  nextStepTextTooltipText?: string
  message?: string
  middleStepDisabled?: boolean
  middleStepHidden?: boolean
  middleStep?: () => void
  middleStepText?: string
}

export const WizardNav = ({
  previousDisabled,
  nextDisabled,
  previousStep,
  nextStep,
  previousHidden = false,
  nextLoading,
  nextStepText,
  previousStepText,
  nextStepTextTooltipText,
  message,
  middleStepDisabled,
  middleStepHidden = true,
  middleStep,
  middleStepText
}: NavProps) => {
  const { t } = useTranslation('common')
  return (
    <WizardNavWrapper>
      {message ? <Text>{message}</Text> : undefined}

      <div className="nav-buttons">
        {!previousHidden && (
          <Button data-testid="wizard-prevbtn" className="btn-back" disabled={previousDisabled} onClick={previousStep}>
            {previousStepText || t('Back')}
          </Button>
        )}
        {!middleStepHidden && (
          <Button
            data-testid="wizard-middlebtn"
            className="btn-back"
            disabled={middleStepDisabled}
            onClick={middleStep}
          >
            {middleStepText}
          </Button>
        )}
        <BasicTooltipForDisabledChilds title={nextStepTextTooltipText}>
          <Button
            data-testid="wizard-nextbtn"
            type="primary"
            disabled={nextDisabled}
            loading={nextLoading}
            onClick={nextStep}
          >
            {nextStepText || t('Continue')}
          </Button>
        </BasicTooltipForDisabledChilds>
      </div>
    </WizardNavWrapper>
  )
}
