import { Dispatch } from 'redux'
import { DialogType } from '../../../features/dialog/types'
import { DialogActions } from '../../../features/dialog/actions'

export const disableTransitions = (disable?: boolean) => ({
  transitionName: disable ? 'none' : undefined,
  maskTransitionName: disable ? 'none' : undefined
})

export const clearDialogData = (dispatch: Dispatch, dialogType: DialogType) => {
  dispatch(DialogActions.setDialogData(dialogType, undefined))
}
