import debounce from 'debounce-promise'
import { validateGoal } from './api'
import { parseErrors } from '../../features/form/utils'
import { NAME_VALIDATION_DEBOUNCE_TIME } from '../../features/constants'
import { takeLatestPromise } from '../../utils/promise'

export const validateNewGoalName = debounce(
  takeLatestPromise(async (newGoalName: string) => {
    try {
      await validateGoal(newGoalName)
    } catch (e: any) {
      const errors = parseErrors(e)
      return errors.name
    }
    return undefined
  }),
  NAME_VALIDATION_DEBOUNCE_TIME
)
