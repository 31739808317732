/* eslint-disable import/no-default-export */
export default {
  DashboardTitle: 'Dashboard',
  DashboardSubtitle: 'Usa i box per vedere tutti i tuoi dati in primo piano!',
  OrganizeBox: 'Organizza',
  ChangeBox: 'Modificare',
  RemoveBox: 'Elimina box',
  ChooseWhatDisplay: 'Scegli cosa visualizzare nel box',
  PortfolioTitle: 'Portafogli',
  PortfolioSubtitle: 'I tuoi portafogli in sintesi',
  PortfolioAndCampaignTitle: 'Portafogli e campagne',
  PortfolioAndCampaignSubtitle: 'I tuoi portafogli e campagne in sintesi',
  AnalysisTitle: 'Query portafoglio, analisi e ricerche',
  AnalysisSubtitle: 'Anteprima delle tue query portafoglio, analisi o ricerche salvate',
  SuggestedProspects: 'Prospect suggeriti',
  CreatedOn: 'Creato il {{date}}',
  EmptyWidget: {
    SelectPortfolio: {
      BoxTitlePortfolio: 'Seleziona un portafoglio',
      BoxTitlePortfolioAndCampaign: 'Seleziona un portafoglio o una campagna',
      Title: 'Portafoglio',
      Description: 'Seleziona un portafoglio dalla sezione "Gestione Portafoglio"',
      SelectLabel: 'Seleziona un portafoglio',
      SelectPlaceholder: 'Seleziona un portafoglio',
      Button: 'Seleziona',
      Banner: {
        Message: 'Se non vuoi selezionare un portafoglio, puoi crearne uno nuova dalla sezione "Gestione Portafoglio"',
        Button: 'Crea'
      }
    },
    SelectCampaign: {
      BoxTitle: 'Seleziona un portafoglio o una campagna',
      Title: 'Campagne',
      Description: 'Seleziona una campagna da "Gestione Vendite"',
      SelectLabel: 'Seleziona campagna',
      SelectPlaceholder: 'Clicca per selezionare una campagna',
      Button: 'Seleziona',
      Banner: {
        Message: 'Se non vuoi selezionare una campagna, creane una nuova da "Gestione Vendite"',
        Button: 'Crea'
      }
    },
    SelectProspectingAnalysis: {
      BoxTitle: 'Seleziona query portafoglio, analisi e ricerche',
      Title: 'Ricerche',
      Description: 'Seleziona una ricerca salvata',
      SelectLabel: 'Seleziona ricerca',
      SelectPlaceholder: 'Clicca per selezionare una ricerca',
      SelectPlaceholderNoQuery: 'Nessuna ricerca salvata',
      Button: 'Seleziona',
      Banner: {
        Message: 'Se non vuoi scegliere una ricerca, creane una nuova',
        Button: 'Crea'
      }
    },
    SelectTargetingAnalysis: {
      BoxTitle: 'Seleziona query portafoglio, analisi e ricerche',
      Title: 'Analisi',
      Description: "Seleziona un'analisi salvata",
      SelectLabel: 'Seleziona analisi',
      SelectPlaceholder: "Clicca per selezionare un'analisi",
      SelectPlaceholderNoQuery: 'Nessuna ricerca salvata',
      Button: 'Seleziona',
      Banner: {
        Message: "Se non vuoi scegliere un'analisi, creane una nuova",
        Button: 'Crea'
      }
    },
    SelectPortfolioAnalysis: {
      BoxTitle: 'Seleziona query portafoglio, analisi e ricerche',
      Title: 'Query portafoglio',
      Description: 'Seleziona una query portafoglio salvata',
      SelectLabel: 'Seleziona query portafoglio',
      SelectPlaceholder: 'Clicca per selezionare una query portafoglio',
      SelectPlaceholderNoQuery: 'Nessuna ricerca salvata',
      Button: 'Seleziona',
      Banner: {
        Message: 'Se non vuoi scegliere una query portafoglio, creane una nuova',
        Button: 'Crea'
      }
    },
    UploadingPortfolio: {
      BoxTitle: 'Caricamento del portafolio',
      Title: 'Caricamento del portafolio',
      Description: "In questo box, vedrai un'anteprima della lista",
      BannerMessage: "Cambia il contenuto ogni volta che lo desideri, cliccando sull'icona in alto a sinistra"
    }
  },
  PortfolioWidget: {
    NoData: 'I dati non esistono.',
    NumberOfCompanies: '{{companiesCount}} Impresa',
    NumberOfCompanies_plural: '{{companiesCount}} Imprese',
    Monitored: 'Monitorato',
    Actions: 'Azioni',
    Enrich: 'Arricchire',
    EnrichTooltip: 'Ottieni dati aggiuntivi su queste imprese',
    Analyze: 'Analizza portfolio',
    AnalyzeTooltip: 'Visualizza i dati del portafoglio rispetto alle principali variabili',
    Similarity: 'Genera similarity',
    SimilarityTooltip: "Applica l'algoritmo di similarità per trovare nuovi prospect"
  },
  ProspectingWidget: {
    NoData: 'I dati non esistono.',
    NumberOfCompanies: '{{companiesCount}} Impresa',
    NumberOfCompanies_plural: '{{companiesCount}} Imprese',
    Monitored: 'Monitorata',
    MoreFilters: '{{filtersCount}} altro filtro',
    MoreFilters_plural: '{{filtersCount}} altri filtri',
    ViewDetailsTooltip: 'Vedi i filtri applicati a questa lista Trova clienti'
  },
  TargetingWidget: {
    NoData: 'I dati non esistono.',
    Companies: 'Impresa',
    Companies_plural: 'Imprese'
  },
  CampaignWidget: {
    NoData: 'I dati non esistono.',
    NumberOfCompanies: '{{companiesCount}} Impresa',
    NumberOfCompanies_plural: '{{companiesCount}} Imprese'
  },
  AppointmentsWidget: {
    Title: 'I tuoi appuntamenti',
    NoAppointments: 'Non hai appuntamenti',
    MoreEvents: '{{eventsCount}} altro appuntamento',
    MoreEvents_plural: '{{eventsCount}} altri appuntamenti',
    Today: 'Oggi',
    Tomorrow: 'Domani'
  },
  LatestDocuments: {
    Title: 'Ultimi documenti',
    NoDocuments:
      "In questo box tu vedrai tutti i tuoi ultimi downloads. I tuoi documenti saranno disponibili nel repository all'interno del profilo.",
    Info: 'La sezione mostra gli ultimi documenti scaricati',
    Table: {
      Name: 'Nome',
      Type: 'Tipo',
      CreationDate: 'Data di creazione',
      ExpirationDate: 'Data scadenza',
      DownloadExcel: 'Downloaded Excel',
      Campaign: 'Campagna',
      Report: 'Report'
    }
  },
  PortfolioQueryWidget: {
    MoreFilters: '{{filtersCount}} altro filtro',
    MoreFilters_plural: '{{filtersCount}} altri filtri',
    ViewDetailsTooltip: 'Vedi i filtri applicati a questo Portafoglio'
  }
}
