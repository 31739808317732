/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Back: 'Retour',
  Continue: 'Continuer',
  Search: 'Rechercher',
  Cancel: 'Annuler',
  Ok: 'Ok',
  Save: 'Enregistrer',
  Delete: 'Supprimer',
  Move: 'Déplacer',
  Edit: 'Modifier',
  Rename: 'Renommer',
  Add: 'Ajouter',
  Note: 'Note',
  Select: 'Sélectionner',
  Hide: 'Masquer',
  Show: 'Afficher',
  Visualise: 'Visualiser',
  HideDetail: 'Masquer les détails',
  ShowDetail: 'Afficher détails',
  Duplicate: 'Dupliquer',
  Download: 'Télécharger',
  Collapse: 'Réduire',
  Loading: 'Chargement',
  // COMPANY STATUS
  Prospect: 'Prospect',
  Lead: 'Principal',
  Client: 'Client',
  Supplier: 'Fournisseur',
  Competitor: 'Concurrent',
  RangeSelected: 'Plage sélectionnée',
  Close: 'Fermer',
  ViewDetails: 'Voir détails',
  SearchResults: {
    ResultsFor: 'Résultats pour',
    SendToSemanticSearch: 'Envoyer à la recherche sémantique',
    SendToSemanticSearchToolTip: 'Rechercher le mot-clé dans la recherche sémantique'
  },
  NumericRange: {
    InvalidValue: 'Formule non valable.',
    IntegersOnly: 'Seuls les nombres entiers sont admis.',
    MinGreaterThanMax: 'Valeur minimum supérieure à la valeur maximum.',
    MaxLessThanMin: 'Valeur maximum inférieure à la valeur minimum.',
    MinOutOfrange: 'Valeur minimum hors plage.',
    MaxOutOfrange: 'Valeur maximum hors plage.'
  },
  DateRange: {
    From: 'De',
    To: 'À'
  },
  DomainFilter: {
    SaveAndClose: 'Enregistrer filtres & fermer',
    SeeAll: 'Tous les {{count}} filtres',
    DeleteAll: 'Supprimer tous',
    Multiselection: 'sélection multiple'
  },
  MapFilter: {
    ChoosePoint: 'Choisir un point directement sur la carte',
    Range: 'Plage',
    SelectedCircle: '{{distance}} de {{address}}',
    SelectedPolygon: 'Zone sélectionnée sur la carte',
    Address: 'Adresse',
    CompanyName: 'Nom de la société',
    FindMe: 'Trouver sur la carte',
    SearchFromHere: 'Démarrer la recherche à partir de ce point',
    GeolocationErrorTitle: "Une erreur s'est produite",
    GeolocationError: "Cette option n'est pas disponible actuellement."
  },
  Reset: 'Réinitialiser',
  SelectAll: 'Sélectionner tous',
  DeselectAll: 'Désélectionner tous',
  DoYouWantToContinue: 'Voulez-vous continuer ?',
  NotSwitch: 'Pas',
  Yes: 'Oui',
  No: 'Non',
  On: 'Marche',
  Off: 'Arrêt',
  LoadMore: 'Charger plus',
  SeeAll: 'Voir tous',
  HideAll: 'Cacher tout',
  Filters: {
    NoFilters: "Aucun filtre n'a été sélectionné !",
    Select: 'Sélectionner filtre',
    Filters: 'Filtres',
    SemanticClustersInfo: 'Essayez le nouveau filtre Margo Semantic Clusters.',
    SemanticClustersDeepActionInfo: 'Affinez votre recherche avec le nouveau filtre Margo Semantic Clusters',
    EditFilters: 'Modifier les filtres pour actualiser la liste',
    StartNewSearch: 'Démarrer nouvelle recherche',
    ResetFilters: 'Réinitialiser tous les filtres',
    UploadList: 'Charger liste',
    UploadListTooltip: 'Saisir une ou plusieurs valeurs séparées par un espace.'
  },
  InsertTheNumber: 'Saisir le numéro',
  OrderBy: 'Classé par',
  BackToLogin: 'Retour à la connexion',
  SimpleUsefulPowerful: 'Simple, utile et puissant.',
  MargoWasSurprise: 'Margò réserve une merveilleuse surprise à mon équipe et moi-même.',
  Targeting: 'Analyser marché',
  Prospecting: 'Trouver des prospects',
  PortfolioManagement: 'Gestion des portefeuilles',
  Actions: 'Actions',
  GroupActions: {
    GroupActions: 'Actions de groupe',
    Actions: 'Actions',
    Select: 'Sélectionner',
    ToggleTooltip:
      "Basculez les actions de groupe pour sélectionner les entreprises souhaitées en appliquant la coche ou effectuez une sélection multiple. Vous pouvez ensuite enregistrer les sociétés sélectionnées à partir du menu Actions. Vous ne pourrez sélectionner aucun appel à l'action.",
    ButtonDisabledByGroupActions:
      "En activant les actions de groupe, vous ne pourrez sélectionner aucun appel à l'action. Pour utiliser ces boutons, désactivez le commutateur Actions de groupe.",
    DeactivateToggleTooltip:
      "Désactivez les actions de groupe pour pouvoir utiliser les boutons d'appel à l'action en haut de la page."
  },
  Confirm: 'Confirmer',
  IsEnriched: 'Enrichi',
  Logout: 'Déconnexion',
  You: 'Vous',
  Legend: 'Légende',
  Enrich: {
    Enrichment: 'Enrichissement',
    RecapDesc:
      "L'enrichissement de la société avec les données de {{companyName}} permet le téléchargement de ces informations et leur actualisation régulière.",
    CompaniesToBeEnriched: 'Société à enrichir',
    CompaniesToBeEnriched_plural: 'Sociétés à enrichir',
    PortfolioInfo1:
      "Cette société n'est pas présente dans la section Gestion du portefeuille. L'ajouter au portefeuille.",
    PortfolioInfo2: "En enregistrant les données de {{companyName}}, la société s'est déjà enrichie.",
    SelectPortfolio: 'Sélectionner portefeuille',
    UploadedPortfolio: 'Portefeuille chargé',
    CreateNewPortfolio: 'Créer nouveau portefeuille',
    PortfolioName: 'Nom du portefeuille',
    EnrichmentComplete: 'Enrichissement terminé'
  },
  ConfigGuardErrorPage: {
    Reload: 'Recharger',
    Subtitle: "Impossible de charger la configuration d'application.",
    Title: 'Nous sommes désolés'
  },
  Uploaded: 'Chargé',
  Date: 'Date',
  Page: 'Page',
  Validating: 'En cours de validation',
  AutoLogout: {
    Title: 'Déconnexion automatique',
    Text: 'En raison de votre inactivité, vous serez automatiquement déconnecté dans {{count}} secondes',
    Text_plural: 'En raison de votre inactivité, vous serez automatiquement déconnecté dans {{count}} secondes'
  },
  ForceLogout: {
    Title: 'Utilisateur déconnecté',
    Desc: "Votre utilisateur a été déconnecté parce que la session s'est terminée ou qu'une nouvelle session a été lancée via un autre navigateur ou un autre appareil."
  },
  ImprintPolicy: 'Mentions légales',
  PrivacyPolicy: 'Politique de confidentialité',
  CookiesPolicy: 'Politique en matière de cookies',
  SelectDate: 'Sélectionner date',
  queries: {
    loadQuery: {
      dialogTitle: 'Charger une recherche enregistrée',
      OkBtn: 'Charger',
      title: 'Sélectionner recherche enregistrée',
      description:
        "Choisir l'analyse à poursuivre, il est possible de reprendre l'analyse à partir de celles enregistrées",
      portfolioRadio: 'Requête de gestion des portefeuilles',
      targetingRadio: 'Requête analyser marché',
      prospectingRadio: 'Requête de prospection',
      selectTitle: 'Requête enregistrée'
    }
  },
  SaveList: {
    CreateTagTitle: 'Créer étiquette',
    CreateTagLabel: 'Créer étiquette (option)',
    CreateTagDesc: 'Chaque société est définie par une étiquette à créer',
    InsertTag: 'Insérer une étiquette',
    SaveList: 'Enregistrer liste',
    SaveOrCreate: 'Souhaitez-vous ajouter la liste à un portefeuille ou en créer un nouveau ?',
    SelectPortfolio: 'Sélectionner portefeuille',
    SaveListDesc:
      'La liste sera automatiquement enregistrée sous le statut « Prospect » dans la section « Gestion des portefeuilles »',
    UploadedPortfolio: 'Portefeuille actualisé',
    CreateNew: 'Créer nouveau',
    SelectNumberOfCompanies:
      "Sélectionner le nombre de sociétés à ajouter au portefeuille et les classer selon l'un des critères de la liste.",
    NumberOfCompanies: 'Nombre de sociétés',
    BackToFullList: 'Retour à la liste complète',
    SavingFullList: 'Enregistrement en cours de la liste complète des sociétés',
    OrderByOptions: {
      Turnover: "Chiffre d'affaire",
      TurnoverEur: "Chiffre d'affaire (EUR)",
      TurnoverCzk: "Chiffre d'affaire (CZK)",
      NOfEmployees: "N° d'employés",
      EmployeeRange: 'Plage de salariés',
      Ebit: 'Ebit'
    },
    SendToSalesInfo: "Avant d'envoyer une liste à l'outil de vente, créer un portefeuille à partir de la liste.",
    Enrichment: {
      Enrichment: 'Enrichissement',
      EnrichmentComplete: 'Enrichissement terminé',
      EnrichmentDescription:
        "L'enrichissement de la société avec les données de {{companyName}} permet le téléchargement de ces informations et leur actualisation régulière",
      CompaniesInList: 'Société dans cette liste',
      CompaniesInList_plural: 'Sociétés dans cette liste',
      CompaniesEnriched: 'Société déjà enrichie',
      CompaniesEnriched_plural: 'Sociétés déjà enrichies',
      CompaniesNotEnriched: 'Société pas enrichie',
      CompaniesNotEnriched_plural: 'Sociétés pas enrichies',
      CreditsWillBeUsed: 'Appel utilisé',
      CreditsWillBeUsed_plural: 'Appels utilisés',
      CreditsRemaining: 'Appel restant',
      CreditsRemaining_plural: 'Appels restants'
    },
    SaveListMonitorDesc:
      'En cas de portefeuille surveillé, la requête enregistrée est disponible dans la section « Prospection » de votre compte.',
    Tag: 'Étiquette'
  },
  ExcludePortfolio: {
    BasedOn: 'Sur la base de',
    SelectedPortfolios: 'Portefeuilles sélectionnés',
    AllCompanyInCrifIta: 'Toutes les sociétés italiennes',
    AllCompanyInCrifDeu: 'Toutes les sociétés allemandes',
    AllCompanyInCrifCzsk: 'Toutes les sociétés disponibles',
    AllCompanyInCrifFareast: 'Toutes les sociétés disponibles',
    AllCompanyInCrifChe: 'Toutes les sociétés suisses',
    AllCompanyInCrifAut: 'Toutes les sociétés autrichiennes',
    AllCompanyInCrifCheAut: 'Toutes les sociétés autrichiennes et suisses',
    ExcludePortfolio: 'Exclure un portefeuille',
    ExcludeCampaign: 'Exclure la campagne',
    ExcludeEnrichedCompanies: 'Exclure les entreprises enrichies',
    Configuration: 'la configurazione impostata sulla tua utenza',
    AndConfiguration: 'e la configurazione impostata sulla tua utenza'
  },
  User: {
    Role: {
      Supervisor: 'Superviseur',
      AreaManager: 'Responsable de secteur',
      Sales: 'Commercial'
    },
    UserName: 'Nom utilisateur'
  }
}
