import { TFunction } from 'i18next'
import { ColumnProps } from 'antd3/lib/table'
import { formatField } from '../../../utils/helpers'
import { TableColumn } from '../../../types'
import { compareValues, createPropComparator } from '../../../utils/comparators'
import { GoalTask } from '../../../api/goals/types'

export const addRender = <T>(col: TableColumn<T>): TableColumn<T> => {
  return { ...col, render: value => formatField(col.type, value) }
}

const createSorter = createPropComparator<GoalTask>()

export const getColumns = (t: TFunction): ColumnProps<GoalTask>[] => [
  {
    dataIndex: 'position',
    title: t('account:Goal.Number'),
    sorter: createSorter('position', compareValues),
    defaultSortOrder: 'ascend'
  },
  {
    dataIndex: 'name',
    title: t('account:Goal.Task')
  }
]
