import { AxiosError } from 'axios'
import { WithErrorCode } from '../../api/common/types'
import { ErrorMessageCode } from './types'

export const getReportErrorHandler = (
  e: AxiosError<WithErrorCode>,
  setErrorMessageCode: (errorCode: ErrorMessageCode) => void
) => {
  if (e?.response?.status === 404 && e?.response?.data?.errorCode?.code !== undefined) {
    if (e?.response?.data?.errorCode?.code === 'HIDCMP') setErrorMessageCode(ErrorMessageCode.HiddenCompany)
    if (e?.response?.data?.errorCode?.code === 'NOTFND') setErrorMessageCode(ErrorMessageCode.NotExists)
  }
}
