import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteConfigurationDialogData } from './types'
import { deleteCustomerCompanyVisibility } from '../../../api/customerCompanyVisibility'
import { getConfigurationKeys } from '../../../api/customerCompanyVisibility/queries'
import { BoldText, ContainerItem, StyledDialog, StyledDialogBody } from '../components'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'

type Props = {
  data: DeleteConfigurationDialogData
  close: () => void
}

export const DeleteConfigurationDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()

  const {
    mutate: mutateDeleteConfigurations,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(
    () => {
      return deleteCustomerCompanyVisibility(data.configurationId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getConfigurationKeys.All())
        queryClient.invalidateQueries([getConfigurationKeys.GetCustomerCompaniesVisibility()])
      }
    }
  )
  const okButtonText = isIdle || isLoading ? t('common:Continue') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:Configuration.DeleteConfiguration.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) {
          return mutateDeleteConfigurations()
        }
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {(isIdle || isLoading) && (
            <div>
              <BoldText>{t('account:Configuration.DeleteConfiguration.Subtitle')}</BoldText>
              <p>{t('account:Configuration.DeleteConfiguration.Body')}</p>
            </div>
          )}
          {isSuccess && <p>{t('account:Configuration.DeleteConfiguration.Success')}</p>}
          {isError && <p>{t('account:Configuration.DeleteConfiguration.Error')}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
