import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Table as AntTable } from 'antd3'
import { checkboxMixin } from '../Checkbox'

export const baseTableStyle = css<{ rowHeight?: number }>`
  width: 100%;

  .whitespace-nowrap {
    white-space: nowrap !important;
  }

  .ant-table-column-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: ${({ theme }) => theme.colors.main};
  }

  ${({ rowHeight }) => (rowHeight ? `.ant-table-tbody tr { height: ${rowHeight}px; }` : '')}

  .ant-table-tbody td {
    font-size: 12px;
    line-height: 1.33;
    color: ${({ theme }) => theme.colors.grayishBrown};
  }

  .ant-table-thead th {
    background-color: transparent;
  }

  ${checkboxMixin}

  .ant-table.ant-table-empty div.ant-table-body {
    overflow-x: visible !important;
    overflow-y: visible !important;
  }

  .ant-table-selection-column {
    .ant-table-header-column {
      .ant-table-selection {
        display: none;
      }
    }
  }
`

export const StyledTable = styled(AntTable)`
  ${baseTableStyle}
` as React.ComponentType as new <T>() => AntTable<T>
