import axios from 'axios'
import { Persistor } from 'redux-persist'
import { all, call, put, select } from 'redux-saga/effects'
import { ERROR } from '../routes'
import { history } from '../utils/history'
import { rootSaga as configSagas } from './config/sagas'
import { canRedirectOnError } from './config/selectors'
import { rootSaga as currenciesSaga } from './currencies'
import { DialogActions } from './dialog/actions'
import { rootSaga as templateSagas } from './files/sagas'
import { rootSaga as notificationsRootSaga } from './notifications/sagas'
import { rootSaga as portfolioCompanyDuplicateSagas } from './portfolioCompanyDuplicates/sagas'
import { rootSaga as portfolioDetailViewRootSaga } from './portfolioDetailPage/sagas'
import { rootSaga as userSagas } from './user/sagas'

function* allSagas(persistor?: Persistor) {
  yield all([
    userSagas(persistor),
    currenciesSaga(),
    configSagas(),
    portfolioCompanyDuplicateSagas(),
    templateSagas(),
    notificationsRootSaga(),
    portfolioDetailViewRootSaga()
  ])
}

export const getRootSaga = (sagas: any) =>
  function* saga(persistor?: Persistor) {
    while (true) {
      try {
        yield call(sagas, persistor)
      } catch (e: any) {
        if (!axios.isCancel(e) && !e.isAxiosError) {
          // eslint-disable-next-line no-console
          console.error('Root saga crashed, restarting...', e)
          const canRedirect: ReturnType<typeof canRedirectOnError> = yield select(canRedirectOnError)
          if (canRedirect) {
            yield put(DialogActions.closeDialog())
            history.push(ERROR)
          } else throw e
        }
      }
    }
  }

export const rootSaga = getRootSaga(allSagas)
