/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: "Per avviare l'analisi è necessario selezionare il tipo di analisi che si desidera elaborare",
    TypeOfAnalysis: 'Tipo di analisi',
    FromTargeting: 'Carica analisi di mercato',
    FromTargetingTooltip: 'Apri una ricerca salvata nel modulo Analizza mercato',
    FromProspecting: 'Carica ricerca da trova clienti',
    FromProspectingTooltip: 'Apri una ricerca salvata nel modulo Trova clienti',
    NewResearch: 'Inizia nuova ricerca',
    NewResearchTooltip: "Trova clienti a partire dall'intero database aziende di Margò",
    PortfolioQuery: 'Ricerca da Portafoglio imprese',
    TargetingQuery: 'Ricerca da Analizza mercato',
    ProspectingQuery: 'Ricerca da Trova clienti',
    LoadQuery: 'Carica ricerca',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SelectOne: 'Seleziona',
    SavedQuery: 'Ricerca salvata',
    ChooseQuery: 'Scegli quale ricerca vuoi caricare',
    ChooseQueryTargeting: 'Scegli quale ricerca di Targeting vuoi caricare',
    YouCanResumeResearch: 'Puoi recuperare una ricerca già iniziata.'
  },
  Prospecting: 'Trova clienti',
  Actions: {
    LoadQuery: 'Carica ricerca',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SaveQuery: 'Salva ricerca',
    SaveQueryTooltip: 'Salva questa ricerca nel modulo Trova clienti',
    Download: 'Download Excel',
    DownloadTooltip: 'Scarica la lista di imprese arricchite sul tuo dispositivo',
    SaveList: 'Salva lista',
    SaveListTooltip: 'Salva questa lista di imprese in un portafoglio',
    SaveAndMonitor: 'Salva e monitora lista',
    SaveAndMonitorTooltip:
      'Salva questa imprese in un portafoglio che si aggiorna dinamincamente quando nuove imprese entrano nel perimetro di ricerca',
    SendToSales: 'Invia lista al Gestione Vendite',
    SendToSalesTooltip: 'Assegna queste imprese alla forza vendita e pianifica una campagna commerciale'
  },
  Indexes: {
    Penetration: 'Indice di Penetrazione',
    PenetrationTooltip:
      'Misura la % di imprese in un portafoglio rispetto a quelle generate dal Similarity. Un valore maggiore indica una migliore penetrazione sul mercato potenziale.',
    Opportunity: 'Indice di Opportunità',
    OpportunityTooltip:
      'Misura la % di imprese generate dal Similarity non presenti nel portafoglio. Un valore maggiore indica un maggior numero di potenziali clienti.'
  },
  CompaniesInList: 'Imprese nella lista',
  CompaniesInList_plural: 'Imprese nella lista',
  Search: {
    SemanticSearch: 'Ricerca semantica',
    SearchPlaceholder: 'Cerca',
    All: 'Tutti',
    OfficialData: 'Dati ufficiali',
    Website: 'Web & Social',
    Synonyms: 'Sinonimi',
    Advanced: 'Ricerca semantica avanzata',
    AdvancedSwitch: 'Avanzata',
    AdvancedTooltip:
      'Con la Ricerca semantica avanzata puoi utilizzare più parole collegate da operatori logici (AND, OR, NOT). Per abilitarla attiva lo switch su ON. Scarica il documento con le specifiche per maggiori informazioni. ',
    DownloadSsGuide: 'Scarica la guida per la Ricerca Semantica'
  },
  EmptySearch: {
    History: {
      Title: 'Nessun filtro',
      Subtitle: 'Qui puoi vedere quanto ciascun filtro influenza la tua ricerca'
    },
    Banner: {
      Title: 'Inizia a cercare ultizzando i tuoi filtri preferiti',
      Subtitle:
        'Prima di iniziare la ricerca, assicurati di aver selezionato tutti i filtri desiderati. Il sistema genererà una lista di prospect ideali, sulla base dei filtri selezionati.'
    }
  },
  LoadQuery: {
    DialogTitle: 'Carica una ricerca salvata',
    SaveBefore: {
      Question: 'Vuoi salvare la ricerca di prospect prima di caricare una ricerca?',
      Description:
        'Salva la tua ricerca di prospect prima di caricare la tua ricerca, per evitare di perdere informazioni.',
      OkBtn: 'Salva ricerca',
      CancelBtn: 'Salta il salvataggio'
    }
  },
  SaveQuery: {
    DialogTitle: 'Salva ricerca',
    Description: 'Inserisci un nome da dare alla tua analisi o seleziona una ricerca salvata per sovrascriverla.',
    ConfirmMessage: 'Vuoi aggiungere la ricerca nella tua dashboard?',
    UpdateExisting: 'Aggiorna esistente',
    CreateNew: 'Crea nuova',
    MaximumWidgetCountReached: "È stato raggiunto il numero massimo di box all'interno della Dashboard.",
    Fields: {
      QueryName: 'Nome ricerca',
      QueryNamePlaceholder: 'Nome ricerca',
      AddToDashboard: 'Mostra in dashboard'
    },
    SavedQuery: 'salvato correttamente',
    SavedQueryAndDashboard: 'salvata e aggiunta nella dashboard',
    SavedQueryDescription: 'Troverai la ricerca salvata nel repository della relativa sezione'
  },
  DomainFilter: {
    SaveAndClose: 'Salva filtri e chiudi'
  },
  LoadMore: 'Carica altro',
  LocalUnits: {
    NoLocalUnits: {
      Title: 'Aggiungi Unità Locali',
      Text: 'Contattare il team vendite di Margò per attivare la funzionalità Unità Locali.'
    }
  },
  UnavailableForMobile: 'La pagina alla quale stai cercando di accedere è disponibile per tablet e desktop.',
  WarningRespectingLocalUnits: {
    Title: 'Arricchimento',
    Text: 'Sono state arricchite le imprese con Tipo di sede "Sede amministrativa", "Sede amministrativa e Sede legale" e "Sede legale" (se selezionate). Per arricchire le aziende con Tipo di sede "Unità locale", attiva l\'add-on Unità locali contattando il customer service.'
  }
}
