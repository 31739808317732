import React from 'react'
import { Dropdown, Menu } from 'antd3'
import { ButtonType, ButtonSize } from 'antd3/lib/button'
import { DropDownProps } from 'antd3/lib/dropdown'
import { ClickParam } from 'antd3/lib/menu'
import styled from 'styled-components/macro'
import { Button } from './Button'
import { Omit } from '../../utils/types'
import { ReactComponent as DownArrow } from '../../assets/icons/down.svg'
import { LoadingOverlayContainer, Size } from '../Loading'
import { ConditionallyVisible } from '../HideFeature'

const DropdownWrapper = styled.div`
  .down-arrow {
    transition: transform ${({ theme }) => theme.animation.duration} ease;
  }

  .ant-dropdown-open .down-arrow {
    transform: rotate(180deg);
  }
`

const ButtonStyled = styled(Button)`
  width: 100%;

  &&& > div {
    display: flex;
    justify-content: space-between;
  }
`

const Loading = styled(LoadingOverlayContainer)`
  position: absolute;
`

const MenuStyled = styled(Menu)`
  min-height: 30px;
  overflow: hidden;
`

type Props = {
  type?: ButtonType
  size?: ButtonSize
  dropdownClassName?: string
  text: string
  onClickItem?: (param: ClickParam) => void
  onClickHeader?: () => void
  loading?: boolean
  MenuComponent?: React.ComponentType<any>
  children: React.ReactNode
} & Omit<DropDownProps, 'overlay'>

export const DropdownMenuButton = ({
  type,
  size,
  text,
  onClickItem,
  onClickHeader,
  children,
  className,
  dropdownClassName,
  loading,
  MenuComponent = MenuStyled,
  ...rest
}: Props) => (
  <DropdownWrapper className={className}>
    <Dropdown
      // @ts-ignore
      onClick={onClickHeader}
      trigger={['click']}
      className={dropdownClassName}
      placement="bottomRight"
      {...rest}
      overlay={
        <MenuComponent onClick={onClickItem}>
          <ConditionallyVisible condition={!!loading}>
            <Loading size={Size.Small} loading />
          </ConditionallyVisible>
          {children}
        </MenuComponent>
      }
    >
      <ButtonStyled type={type} size={size} iconRight={<DownArrow className="down-arrow" />}>
        {text}
      </ButtonStyled>
    </Dropdown>
  </DropdownWrapper>
)
