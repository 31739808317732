import React from 'react'
import { useTranslation } from 'react-i18next'
import { BoldText, ContainerItem, StyledDialog, StyledDialogBody } from '../components'
import OkImage from '../../../assets/images/ok.svg'

type Props = {
  close?: () => void
}

export const CreateConfigurationDialog = ({ close }: Props) => {
  const { t } = useTranslation(['common', 'account'])

  const okButtonText = t('common:Ok')

  return (
    <StyledDialog
      visible
      title={t('account:Configuration.ConfigureMargoTitle')}
      okText={okButtonText}
      loading={false}
      onOk={close}
      onCancel={close}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          <div>
            <BoldText>{t('account:Configuration.AddConfiguration.ConfigurationSuccessful')}</BoldText>
            <p>{t('account:Configuration.AddConfiguration.ConfigurationSuccessfulDescription')}</p>
          </div>
        </ContainerItem>
        <div>
          <img src={OkImage} alt="configuration" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
