import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { getUserType } from '../../features/user/selectors'
import { getVisibleClaims } from '../../features/config/selectors'
import { Claim, UserType } from '../../authorization'

type CheckParams = {
  required: number
  current: number
  inclusive?: boolean
}

const check = ({ required, current, inclusive = false }: CheckParams): boolean => {
  return inclusive ? _.gt(current & required, 0) : _.isEqual(current & required, required)
}

type Props = {
  inclusiveClaims?: boolean
  claims?: Claim
  userTypes?: UserType
  children: React.ReactNode
}

export const ClaimsWrapper = ({ inclusiveClaims, claims, userTypes, children }: Props) => {
  const visibleClaims = useSelector(getVisibleClaims)
  const userType = useSelector(getUserType)

  if (!_.isUndefined(claims) && !check({ required: claims, current: visibleClaims, inclusive: inclusiveClaims })) {
    return null
  }

  if (!_.isUndefined(userTypes) && !check({ required: userTypes, current: userType, inclusive: true })) {
    return null
  }

  return <>{children}</>
}
