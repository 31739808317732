import styled from 'styled-components/macro'
import { Col, Row } from '../Grid'
import { Spin } from '../Loading'
import { Text } from '../Text'

export const Loading = styled(Spin)`
  padding-top: 10px;
`

export const InfoWrapper = styled.div`
  width: 546px;
  max-width: 100%;

  &.loading {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
  }
`

export const SmallHeader = styled(Text)`
  width: 100%;
  margin-bottom: 5px;
  display: block;
`

export const HeaderRow = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryLightPink};
`

export const HeaderCol = styled(Col)`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 56px;
  padding-right: 50px;
`
