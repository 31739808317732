import styled from 'styled-components/macro'
import { Select } from '../../../../../components/Select'
import { Radio } from '../../../../../components/Radio'
import { Text } from '../../../../../components/Text'

export const StyledSelect = styled(Select)`
  .ant-select-selection-selected-value {
    color: ${({ theme }) => theme.colors.main} !important;
  }
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.main};
`

export const StyledRadio = styled(Radio)`
  width: 100%;
`
