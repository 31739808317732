import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { DuplicateCompanyNoteDialog } from './DuplicateCompanyDialog'
import { DuplicateCompanyViewState } from '../../types'
import { duplicateCompany, validateDuplicateCompany } from '../../../../../api/salestool'
import { DuplicateCompanyReq, UpdateResponseDto } from '../../../../../api/salestool/types'

type Props = {
  data: {
    state: DuplicateCompanyViewState
    companyId: string
    companyName: string
    campaignId: string
    error?: Error
  }
  close: () => void
}

export const DuplicateCompanyDialogContainer = ({ close, data }: Props) => {
  const { companyId, companyName, campaignId } = data

  const [error, setError] = React.useState<string>()
  const [viewState, setViewState] = React.useState(DuplicateCompanyViewState.DuplicateCompanyView)
  const [isCompanyDuplicationAllowed, setIsCompanyDuplicationAllowed] = React.useState<string | boolean>('')

  const duplicateMutation = useMutation<AxiosResponse<UpdateResponseDto>, AxiosError<string>, DuplicateCompanyReq>(
    reqData => duplicateCompany(companyId, reqData),
    {
      onSuccess: () => {
        setViewState(DuplicateCompanyViewState.DuplicateCompanyDone)
      },
      onError: e => {
        setViewState(DuplicateCompanyViewState.DuplicateCompanyError)
        setError(e.response?.data)
      }
    }
  )

  const validationMutation = useMutation<AxiosResponse<boolean>, AxiosError<string>, DuplicateCompanyReq>(
    reqData => validateDuplicateCompany(companyId, reqData),
    {
      onSuccess: response => setIsCompanyDuplicationAllowed(response.data),
      onError: e => {
        setViewState(DuplicateCompanyViewState.DuplicateCompanyError)
        setError(e.response?.data)
      }
    }
  )

  const saveDuplicatedCompany = (status: string, product: string) => {
    const reqData = {
      entityId: companyId,
      status,
      product
    }
    duplicateMutation.mutate(reqData)
  }

  const validateAssignments = React.useCallback(
    ({ status, product }: { status: string; product: string }) => {
      const reqData = {
        entityId: companyId,
        status,
        product
      }
      validationMutation.mutate(reqData)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId]
  )

  const closeAndReset = () => {
    close()
    setIsCompanyDuplicationAllowed('')
  }

  const changeDialogStatus = () => {
    setViewState(DuplicateCompanyViewState.DuplicateCompanyViewSelection)
  }

  return (
    <DuplicateCompanyNoteDialog
      isLoading={duplicateMutation.isLoading || validationMutation.isLoading}
      view={viewState}
      companyId={companyId}
      companyName={companyName}
      campaignId={campaignId}
      onClickSave={saveDuplicatedCompany}
      onClickYes={changeDialogStatus}
      validate={validateAssignments}
      error={error}
      close={closeAndReset}
      isCompanyDuplicationAllowed={isCompanyDuplicationAllowed}
    />
  )
}
