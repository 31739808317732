import { useQuery } from '@tanstack/react-query'
import { fetchPerson } from '.'
import { mapPersonResponseToPerson } from '../../features/personReport/mappers'

export const getPersonKeys = {
  All: () => [{ level1: 'person' }] as const,

  FetchPerson: (personId: string) => [{ ...getPersonKeys.All()[0], level2: 'fetchPerson', personId }] as const
}

export const useFetchPerson = (personId: string) =>
  useQuery(getPersonKeys.FetchPerson(personId), ({ queryKey: [queryKeys] }) => fetchPerson(queryKeys.personId), {
    select: data => mapPersonResponseToPerson(personId, data[0])
  })
