export type WithPagination = { top?: number; skip?: number }

export type Pagination = {
  size: number
  position: number
}

export enum CompanyDetailsType {
  Dashboard = 'Dashboard',
  FullDetails = 'FullDetails',
  Map = 'Map',
  MapFilter = 'MapFilter',
  Preview = 'Preview',
  Search = 'SearchResult'
}

export type MapPosition = {
  lat: number
  lng: number
}

export type MapPositionData = {
  center: MapPosition
  bounds?: {
    northWest: MapPosition
    southEast: MapPosition
  }
  zoom: number
}

// Queries are obsolete and replaced by OperationQueries
export enum QueryType {
  ExportedPortfolio = 'ExportedPortfolio',
  Queries = 'Queries',
  OperationQueries = 'OperationQueries',
  TargetingQueries = 'TargetingQueries',
  ProspectingQueries = 'ProspectingQueries',
  PortfolioQueries = 'PortfolioQueries',
  MonitoredQueries = 'MonitoredQueries',
  OfficeVisibility = 'OfficeVisibility',
  UserVisibility = 'UserVisibility'
}

export enum DashboardItemType {
  Portfolio = 'Portfolio',
  FilterQuery = 'FilterQuery',
  Campaign = 'SalesTool'
}

export enum SaveListType {
  NewPortfolio = 'NewPortfolio',
  ExistingPortfolio = 'ExistingPortfolio'
}

export enum SaveListNewPortfolioType {
  Full = 'Full',
  Partial = 'Partial'
}

export enum ApplicationModule {
  PortfolioManagement = 'PortfolioManagement',
  Prospecting = 'Prospecting',
  Salestool = 'Salestool',
  Targeting = 'Targeting',
  CompanyReport = 'CompanyReport',
  Operations = 'Operations'
}

export enum UploadStatus {
  Created = 'Created',
  Started = 'Started',
  Done = 'Done',
  Deleted = 'Deleted'
}

export type SortItem = {
  property: string
  order: 'Asc' | 'Desc'
}

export type ViewportDimensions = {
  viewportHeight: number
  viewportWidth: number
  isExtraSmall: boolean
  isMobile: boolean
  isTablet: boolean
  isMedium: boolean
  isLarge: boolean
  isExtraLarge: boolean
}

export type Range<T> = {
  min: T
  max: T
}

export type NumericRange = Range<number>

export type Error = {
  message?: string
  stack?: string
}

export enum LoadingStatus {
  NotLoaded,
  IsLoading,
  Error,
  Success
}

export type PromisedType<T> = T extends (...params: any[]) => Promise<infer R>
  ? R
  : T extends Promise<infer R>
  ? R
  : never

export type GeneratedType<T> = T extends (...params: any[]) => Generator<any, infer R, any>
  ? R
  : T extends Generator<any, infer R, any>
  ? R
  : never

export type SvgComponentProps = React.SVGProps<SVGSVGElement> & { title?: string }

export type SvgComponent = React.FunctionComponent<SvgComponentProps>

export type ComponentProps<C> = C extends React.FC<infer P> ? P : C extends React.ComponentType<infer P> ? P : never

export type WithDataTestId = {
  'data-testid'?: string
}

export enum PolicyDocument {
  PrivacyPolicy = 'PrivacyPolicy',
  CookiesPolicy = 'CookiesPolicy',
  ImprintPolicy = 'ImprintPolicy'
}
