/// emitting values only from the most recently emitted promises
export const takeLatestPromise = <TFunc extends (...args: any) => Promise<any>>(func: TFunc) => {
  let latestPromise: Promise<Awaited<ReturnType<typeof func>>> | null = null

  return (...args: Parameters<typeof func>) => {
    const promise: Promise<Awaited<ReturnType<typeof func>>> = func(...args)
    latestPromise = promise
    return promise.then(
      (value: Awaited<ReturnType<typeof func>>) => (promise === latestPromise ? value : latestPromise!),
      reason => (promise === latestPromise ? Promise.reject(reason) : latestPromise!)
    )
  }
}
