import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { useMutation } from '@tanstack/react-query'
import styled from 'styled-components/macro'
import { Controller, useForm } from 'react-hook-form'
import { EventCategory, GroupActionType, CallToActionType } from '../../../features/analytics/types'
import { Option } from '../../../components/Select'
import { MoveCompaniesDialogData } from './types'
import { TrackingActions } from '../../../features/analytics/actions'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'
import { SelectField } from '../../../components/Form/Select'
import { moveCompanies, useComparePortfolioVariables, useGetPortfolioList } from '../../../api/portfolio'
import { remapSelectionUnits } from './utils'
import { selectAllRows } from '../../../features/portfolioCompany/withTable/actions'
import { CheckboxField } from '../../../components/Form/Checkbox'
import { Button } from '../../../components/Button'
import { Stack } from '../../../components/Stack'
import { Text } from '../../../components/Text'
import { useViewportSize } from '../../../hooks/useViewportSize'
import { StyledDialog, StyledDialogBody, StackCustomVariables, Wrapper } from '../components'
import { required } from '../../../utils/validators'
import { convertCompoundExpressionForBackend } from '../../../features/filters/utils'
import { PortfolioListOrderBy, PortfolioOwnership } from '../../../api/portfolio/types'

export const StyledContentWrapper = styled.div`
  display: block;
  width: 100%;
`

type Props = {
  close: () => void
  data: MoveCompaniesDialogData
}

export type MoveCompaniesFormState = {
  targetPortfolioId?: string
  excludeCustomVariables?: boolean
}

export const MoveCompaniesDialog = ({ close, data }: Props) => {
  const dispatch = useDispatch()
  const { isMobile } = useViewportSize()
  const { t } = useTranslation(['common', 'portfolio'])

  const { control, handleSubmit, formState, setValue, trigger, watch } = useForm<MoveCompaniesFormState>({
    mode: 'onChange',
    defaultValues: {}
  })

  const { data: isPortfolioInConflict } = useComparePortfolioVariables(data.portfolioId, watch('targetPortfolioId'))

  useEffect(() => {
    if (!formState.dirtyFields?.targetPortfolioId || isPortfolioInConflict === undefined) return
    setValue('excludeCustomVariables', isPortfolioInConflict)
    trigger()
  }, [isPortfolioInConflict, formState.dirtyFields?.targetPortfolioId, setValue, trigger])

  const { isValid } = formState

  const isSaveButtonDisabled = !isValid || (isPortfolioInConflict && !watch('excludeCustomVariables'))

  const { data: portfolios, isLoading: portfoliosLoading } = useGetPortfolioList({
    skip: 0,
    ownership: PortfolioOwnership.Owned,
    orderBy: PortfolioListOrderBy.UpdatedAtDesc,
    minCountOfCompanies: 0
  })

  const {
    mutate: moveNewCompanies,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(
    (form: MoveCompaniesFormState) => {
      return moveCompanies({
        selection: {
          excludeCustomVariables: !!form.excludeCustomVariables,
          portfolioGridQuery: {
            semantic: data.semantic,
            query: convertCompoundExpressionForBackend(data.expression),
            excludedPortfoliosIds: data.excludedPortfoliosIds
          },
          selectedItems: !data.isSelectAll ? remapSelectionUnits(data.selectedCompanies) : undefined,
          itemsToIgnore: data.isSelectAll ? remapSelectionUnits(data.deSelectedCompanies) : undefined,
          sourceContainerItemId: data.portfolioId,
          destinationContainerItemId: form.targetPortfolioId!
        }
      })
    },
    {
      onSuccess: () => {
        dispatch(selectAllRows('views.portfolioDetail')(false, data.portfolioId))
        if (data.isGroupAction) {
          dispatch(TrackingActions.trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.MoveCompany))
        } else {
          dispatch(TrackingActions.trackCTAClick(EventCategory.PortfolioDetail, CallToActionType.MoveCompany))
        }
      }
    }
  )

  const submit = handleSubmit(form => {
    if (isError || isSuccess) {
      return close()
    }
    return moveNewCompanies(form)
  })

  const okButtonText = isSuccess || isIdle || isLoading ? t('common:Cancel') : t('common:Ok')

  const dialogFooter = isIdle && (
    <Stack center orientation={isMobile ? 'vertical' : 'horizontal'} gap="1.2em">
      <StackCustomVariables isMobile>
        <Text size="M" type="primary" bold block>
          {t('portfolio:ExcludeCustomVariablesConfirm')}
        </Text>

        <div>
          <Controller
            name="excludeCustomVariables"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <CheckboxField name={name} formState={formState} onChange={onChange} checked={value} control={control}>
                {t('portfolio:ExcludeCustomVariables')}
              </CheckboxField>
            )}
          />
        </div>
      </StackCustomVariables>
      <Wrapper isMobile>
        <Button onClick={close}>{okButtonText}</Button>
        <Button type="primary" disabled={isSaveButtonDisabled} onClick={submit}>
          {t('common:Save')}
        </Button>
      </Wrapper>
    </Stack>
  )

  return (
    <StyledDialog
      loading={portfoliosLoading || isLoading}
      visible
      title={t('portfolio:CompanyGroupActions:ActionItems:MoveCompany')}
      footer={dialogFooter}
      okText={t('common:Ok')}
      onOk={close}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
    >
      <StyledDialogBody>
        <StyledContentWrapper>
          {(isIdle || isLoading) && (
            <>
              <p>
                <b>{t('portfolio:CompanyGroupActions:ActionItems:MoveCompany')}</b>
              </p>
              <p>
                <i>{t('portfolio:CompaniesMove:ConfirmMove', { count: data.selectedCompanies.length })}</i>
              </p>
              <p>{t('portfolio:CompaniesMove:SelectPortfolio')}</p>
              <SelectField
                name="targetPortfolioId"
                control={control}
                formState={formState}
                showSearch
                validators={{ required }}
                optionFilterProp="title"
                placeholder={t('common:Select')}
                loading={portfoliosLoading}
              >
                {_.map(_.reject(portfolios, { id: data.portfolioId }), portfolioOption => (
                  <Option key={portfolioOption.id} value={portfolioOption.id} title={portfolioOption.name}>
                    {portfolioOption.name}
                  </Option>
                ))}
              </SelectField>
              {isPortfolioInConflict && <Text color="error">{t('error:ExcludeCustomVariablesError')}</Text>}
            </>
          )}
          {isSuccess && <p>{t('portfolio:CompaniesMove:MoveSuccess')}</p>}
          {isError && <p>{t('portfolio:DialogError')}</p>}
        </StyledContentWrapper>
        <img src={PortfolioImage} alt="portfolio" />
      </StyledDialogBody>
    </StyledDialog>
  )
}
