import styled from 'styled-components/macro'
import { Stack } from '../../../components/Stack'

export const Buttons = styled(Stack)`
  width: auto;
`
export const AddToDasboardWrapper = styled.div`
  text-align: left;
  > * {
    text-align: left;
  }
`
