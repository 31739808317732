import debounce from 'debounce-promise'
import { validateTask } from './api'
import { parseErrors } from '../../features/form/utils'
import { NAME_VALIDATION_DEBOUNCE_TIME } from '../../features/constants'
import { takeLatestPromise } from '../../utils/promise'

export const validateNewTaskName = debounce(
  takeLatestPromise(async (goalId: string, newTaskName: string) => {
    try {
      await validateTask(goalId, newTaskName)
    } catch (e: any) {
      const errors = parseErrors(e)
      return errors.name
    }
    return undefined
  }),
  NAME_VALIDATION_DEBOUNCE_TIME
)
