import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Text, ellipsisMixin } from '../Text'
import { Stack, JustifyContentValue } from '../Stack'
import { gridTheme, FOOTER_HEIGHT } from '../../utils/theme'
import { ReactComponent as MailIcon } from '../../assets/icons/mail.svg'
import { ReactComponent as PhoneIcon } from '../../assets/icons/normal.svg'
import logoLarge from '../../assets/images/big.svg'
import logoSmall from '../../assets/images/icon.svg'
import { CONTACT, PRIVACY_POLICY, COOKIES_POLICY, IMPRINT_POLICY } from '../../routes'
import { isLoggedIn } from '../../features/user/selectors'
import { DialogActions } from '../../features/dialog/actions'
import { ContactViewType } from '../../pages/Contact/ContactDialog/types'
import { HiddenFeature } from '../HideFeature'
import { getCompanyName } from '../../features/config/selectors'
import { useBranding } from '../../hooks/useBranding'

const Logo = styled(Link)`
  display: block;
  width: 188px;
  height: ${FOOTER_HEIGHT}px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${logoLarge});

  ${media.sm`
    width: 120px;
    background-image: url(${logoSmall});
  `}

  ${media.lg`
    width: 188px;
    background-image: url(${logoLarge});
  `}
`

const TextLink = styled(Link)`
  ${ellipsisMixin}
  display: block;
  padding: 28px 32px;

  color: ${({ theme }) => theme.colors.white};
  fill: currentColor;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    padding-top: 19px;
    padding-bottom: 19px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.pumpkinOrange};
  }

  ${Text.Style} {
    font-weight: bold;
    color: inherit;
  }
  svg {
    fill: inherit;
  }
`

const HideableText = styled(Text)`
  ${media.sm`
    display: none;
  `}

  ${media.md`
    display: block;
  `}
`

const Divider = styled.div`
  display: none;
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: rgba(255, 255, 255, 0.4);
`

export const FooterWrapper = styled.div`
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.navy}, ${({ theme }) => theme.colors.main});
  z-index: 3;
  .show-sm {
    display: none;
  }
  @media (max-width: ${gridTheme.breakpoints.xl - 1}px) {
    .hide-lg {
      display: none;
    }
  }

  @media (max-width: ${gridTheme.breakpoints.md - 1}px) {
    padding-right: 20px;
  }

  @media (max-width: ${gridTheme.breakpoints.sm - 1}px) {
    padding: 0 16px 50px 16px;

    ${Stack} {
      justify-content: center;
      &.links {
        flex-direction: column;
      }
    }

    ${Logo} {
      margin: 0 auto;
    }

    ${TextLink} {
      margin: 0 !important;
      text-align: center;
    }

    ${Divider} {
      display: block;
    }
    .hide-sm {
      display: none;
    }
    .show-sm {
      display: flex;
    }
  }
`

type Props = {
  forTwoColumnLayout?: boolean
}

export const Footer = ({ forTwoColumnLayout }: Props) => {
  const { t } = useTranslation('footer')
  const companyName = useSelector(getCompanyName)
  const { policyDocuments } = useBranding()

  const loggedIn = useSelector(isLoggedIn)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openContactDialog = () => dispatch(DialogActions.showDialogWithData('Contact', { state: ContactViewType.INIT }))

  return (
    <FooterWrapper id="footer">
      <Stack className="links">
        <Stack className="links" justify={JustifyContentValue.Center}>
          <HiddenFeature>
            <TextLink className="hide-sm" to="/">
              <Stack gap="1em">
                <MailIcon />
                <HideableText ellipsis className={forTwoColumnLayout ? 'hide-lg' : ''}>
                  {t('Support.Email')}
                </HideableText>
              </Stack>
            </TextLink>
          </HiddenFeature>
          <HiddenFeature>
            <TextLink className="hide-sm" to="/">
              <Stack gap="1em">
                <PhoneIcon />
                <HideableText ellipsis className={forTwoColumnLayout ? 'hide-lg' : ''}>
                  {t('Support.Phone')}
                </HideableText>
              </Stack>
            </TextLink>
          </HiddenFeature>
          <Divider className="hide-sm" />
          {policyDocuments.ImprintPolicy && (
            <TextLink to={IMPRINT_POLICY}>
              <Text ellipsis>{t('ImprintPolicy')}</Text>
            </TextLink>
          )}
          {loggedIn && policyDocuments.PrivacyPolicy && (
            <TextLink to={PRIVACY_POLICY}>
              <Text ellipsis>{t('PrivacyPolicy')}</Text>
            </TextLink>
          )}
          {policyDocuments.CookiesPolicy && (
            <TextLink to={COOKIES_POLICY}>
              <Text ellipsis>{t('CookiesPolicy')}</Text>
            </TextLink>
          )}
          <TextLink
            to={CONTACT}
            onClick={e => {
              e.preventDefault()
              if (loggedIn) {
                openContactDialog()
              } else {
                navigate({ pathname: CONTACT })
              }
            }}
          >
            <Text ellipsis>{t('Contact', { companyName })}</Text>
          </TextLink>
          <HiddenFeature>
            <Divider className="show-sm" />
            <Stack className="show-sm">
              <TextLink to="/">
                <Stack gap="1em">
                  <MailIcon />
                </Stack>
              </TextLink>
              <TextLink to="/">
                <Stack gap="1em">
                  <PhoneIcon />
                </Stack>
              </TextLink>
            </Stack>
          </HiddenFeature>
        </Stack>
      </Stack>
    </FooterWrapper>
  )
}
