import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useGetCampaign, useUpdateCampaign } from '../../../../../api/salestool'
import { Dialog } from '../../../../../components/Dialog'
import { Text } from '../../../../../components/Text'
import { CampaignStatus } from '../../../../../features/salestool/types'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import SalesToolImage from '../../../../../assets/images/sales-tool.svg'
import { BaseDialog } from '../../../BaseDialog'
import { toLocalizedDateFormat } from '../../../../../utils/formatters'
import { DialogActions } from '../../../../../features/dialog/actions'

type Props = {
  data: {
    campaignId: string
  }
}

export const StartCampaignDialog = ({ data: { campaignId } }: Props) => {
  const { t } = useTranslation(['salestool', 'common'])
  const dispatch = useDispatch()

  const { data: campaign } = useGetCampaign(campaignId)

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const closeAndReload = () => {
    closeDialog()
    window.location.reload()
  }

  const {
    mutate: mutateStartCampaign,
    isLoading,
    isSuccess,
    isError,
    isIdle
  } = useUpdateCampaign(campaignId, {
    status: CampaignStatus.Active
  })

  const props = {
    destroyOnClose: false,
    title: t('StartCampaignBtn'),
    footer: '',
    okText: t(isIdle ? 'common:Continue' : 'common:Ok'),
    cancelText: isIdle ? t('common:Cancel') : undefined,
    onCancel: isSuccess ? closeAndReload : closeDialog,
    width: 1024,
    visible: true,
    loading: isLoading
  }

  if (isSuccess) {
    const formattedDate = campaign?.startDate ? toLocalizedDateFormat(campaign?.startDate) : ''
    const campaignName = campaign?.campaignName ?? ''
    const successText = (
      <>
        <Text block primary bold>
          {t('Management.Campaign.StartCampaignDoneDialogText1', { campaignName, startDate: formattedDate })}
        </Text>
        <Text block>{t('Management.Campaign.StartCampaignDialogText2')}</Text>
        <Text block bold>
          {t('Management.Campaign.StartCampaignDoneDialogText2')}
        </Text>
      </>
    )
    return (
      <Dialog {...props} onOk={closeAndReload}>
        <BaseDialog content={successText} image={<img src={OkImage} alt="ok" />} />
      </Dialog>
    )
  }

  if (isError) {
    const failText = <Text block>{t('error:StartCampaignError')}</Text>
    return (
      <Dialog {...props} onOk={closeDialog}>
        <BaseDialog content={failText} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  const startText = (
    <>
      <Text block primary bold>
        {t('Management.Campaign.StartCampaignDialogText1')}
      </Text>
      <Text block>{t('Management.Campaign.StartCampaignDialogText2')}</Text>
      <Text block bold>
        {t('Management.Campaign.StartCampaignDialogText3')}
      </Text>
    </>
  )
  return (
    <Dialog {...props} onOk={() => mutateStartCampaign()}>
      <BaseDialog content={startText} image={<img src={SalesToolImage} alt="salesTool" />} />
    </Dialog>
  )
}
