import _ from 'lodash'
import { FilterReducerPath } from './types'
import { FilterStructureModuleRequest, DomainItemsPerLevel, DomainNodesDataResponse } from '../../api/filters/types'
import { TreeKeySelection } from '../../utils/tree'

export const getFilterStructureModuleRequest = (reducer: FilterReducerPath) => {
  switch (reducer) {
    case 'views.portfolioDetail':
      return FilterStructureModuleRequest.PortfolioManagement
    case 'prospecting':
      return FilterStructureModuleRequest.Prospecting
    case 'views.salestoolAssignments':
      return FilterStructureModuleRequest.SalesTool
    default:
      return FilterStructureModuleRequest.Targeting
  }
}

export const mapSemanticClusterResponseToTreeKeySelection = (dataPerLevel: DomainItemsPerLevel[]) =>
  _.reduce(
    dataPerLevel,
    (acc: TreeKeySelection[], levelResponse) =>
      _.concat(
        acc,
        _.map(levelResponse.items, item => {
          return { key: item.code, parentPath: item.parentPath }
        })
      ),
    []
  )

export const mapDomainsNodeDataToTreeKeySelection = (
  domainsNodeData: DomainNodesDataResponse | undefined
): TreeKeySelection[] =>
  _.map(domainsNodeData, domainNodeData => {
    return { key: domainNodeData.code, parentPath: domainNodeData.parentPath }
  })
