import React from 'react'
import { FormState, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextAreaInputField } from '../../../../../components/Form/TextArea'
import { Text } from '../../../../../components/Text'
import { getErrorMessage } from '../../../../../features/form/utils'
import { length } from '../../../../../utils/validators'

type Props = {
  formState: FormState<any>
} & Pick<UseControllerProps, 'control'>

export const EditCompanyNoteDialogForm = ({ control, formState }: Props) => {
  const { t } = useTranslation(['salestool', 'error'])
  const name = 'note'
  const label = 'Company note'

  return (
    <>
      <Text>{t('AssignCampaign.Dialog.AddCompanyNoteTextAreaLabel')}</Text>
      <TextAreaInputField
        hideLabel
        name={name}
        label={label}
        placeholder={t('AssignCampaign.Dialog.AddCompanyNoteTextAreaPlaceholder')}
        rows={4}
        noResize
        control={control}
        formState={formState}
        rules={{
          validate: {
            length: getErrorMessage(length({ min: 3, max: 250 }), label, t)
          }
        }}
      />
    </>
  )
}
