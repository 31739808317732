import moment from 'moment'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import { DatePicker } from '../../../../../components/DatePicker'
import { Dialog } from '../../../../../components/Dialog'
import { Text } from '../../../../../components/Text'
import { BaseDialog } from '../../../BaseDialog'
import { useUpdateCampaign } from '../../../../../api/salestool'
import { formatDateTime, getLocalizedDateFormatPattern, toLocalizedDateFormat } from '../../../../../utils/formatters'
import { BoldText, DatePickerWrapper } from '../components'
import { DialogActions } from '../../../../../features/dialog/actions'

type Props = {
  data: {
    campaignName: string
    campaignId: string
    createdAt: string
    endDate: string
    startDate: string
  }
}

export const ChangeStartDateDialog = ({ data: { campaignId, campaignName, createdAt, endDate, startDate } }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const dispatch = useDispatch()

  const [newStartDate, setNewStartDate] = useState<string>('')

  const getDate = (date: moment.Moment | null) => {
    const dateString = date && formatDateTime(date)
    setNewStartDate(dateString === null ? '' : dateString)
  }

  const disabledDate = (current: moment.Moment | null): boolean =>
    current ? current >= moment(endDate).endOf('day') || current < moment(createdAt).startOf('day') : false

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const { isIdle, isLoading, isSuccess, isError, mutate } = useUpdateCampaign(campaignId, { startDate: newStartDate })

  const props = {
    destroyOnClose: false,
    width: 600,
    visible: true,
    loading: isLoading,
    onCancel: closeDialog
  }

  if (isIdle || isLoading) {
    return (
      <Dialog
        {...props}
        title={t('Management.Campaign.ChangeStartDate')}
        footer=""
        cancelText={t('common:Cancel')}
        okText={t('common:Continue')}
        okButtonProps={{ disabled: !newStartDate }}
        onOk={() => mutate()}
      >
        <DatePickerWrapper>
          <Text block>{t('Management.Campaign.ChangeStartDateDescription')}</Text>
          <DatePicker
            defaultValue={moment(startDate)}
            format={getLocalizedDateFormatPattern()}
            style={{ width: 250 }} // eslint-disable-line
            dropdownClassName="dialog-date-picker"
            onChange={getDate}
            disabledDate={disabledDate}
          />
        </DatePickerWrapper>
      </Dialog>
    )
  }

  if (isSuccess) {
    return (
      <Dialog
        {...props}
        title={t('Management.Campaign.ChangeStartDate')}
        footer=""
        okText={t('common:Ok')}
        onOk={closeDialog}
      >
        <BaseDialog
          content={
            <>
              <BoldText>{campaignName}&nbsp;</BoldText>
              {t('Management.Campaign.ChangeStartDateSuccess')}
              <BoldText>&nbsp;{toLocalizedDateFormat(newStartDate)}</BoldText>
            </>
          }
          image={<img src={OkImage} alt="ok" />}
        />
      </Dialog>
    )
  }

  if (isError) {
    return (
      <Dialog
        {...props}
        title={t('Management.Campaign.ChangeStartDate')}
        footer=""
        okText={t('common:Ok')}
        onOk={closeDialog}
      >
        <BaseDialog content={<Text>{t('error:ChangeStartDate')}</Text>} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  return null
}
