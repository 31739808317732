import { useQuery } from '@tanstack/react-query'
import { getUsers } from './api'
import { UserTypeKey } from '../../authorization'
import { mapUsers } from '../../features/users/mappers'
import { Channel } from '../types'

export const usersKeys = {
  All: () => [{ level1: 'users' }] as const,
  GetUsers: (userType: UserTypeKey, channel: Channel | undefined) =>
    [{ ...usersKeys.All()[0], level2: 'getUsers', userType, channel }] as const
}

export const useGetUsers = (userType: UserTypeKey, channel?: Channel, onError?: () => void) =>
  useQuery(
    usersKeys.GetUsers(userType, channel),
    ({ queryKey: [queryKeys] }) => getUsers(queryKeys.userType, queryKeys.channel).then(mapUsers),
    {
      onError
    }
  )
