import _ from 'lodash'
import { getFieldIdFromExpression } from '../../components/ContextFilters/utils'
import { Filter, FilterType, SemanticSearchType } from '../filters/types'
import { SearchType } from '../filters/types/mapFilter'
import { isCompoundExpression } from './guards'
import {
  CompoundExpression,
  CompoundOperator,
  FieldExpression,
  HierarchyExpression,
  Expression,
  FieldOperator,
  NumericRangeExpression,
  Interval,
  DateRangeExpression,
  SemanticExpression,
  GeoDistanceExpression,
  GeoPolygonExpression
} from './types'

export const getDefaultRootExpression = (condition = CompoundOperator.And, groupId?: string): CompoundExpression => ({
  $type: 'CompoundExpression',
  childExpressions: [],
  condition,
  groupId
})

export const getEmptyFieldExpression = (
  fieldId?: string,
  value?: string | number | boolean | string[] | null,
  operator?: FieldOperator
): FieldExpression => ({
  $type: 'FieldExpression',
  fieldId,
  operator,
  value
})

export const getEmptyHierarchyExpression = (
  fieldId?: string,
  singleSelectionTargetJsonPath?: string,
  isMultiSelection?: boolean,
  childExpressions?: FieldExpression[],
  exactSearch?: boolean
): HierarchyExpression => ({
  $type: 'HierarchyExpression',
  condition: CompoundOperator.Or,
  childExpressions,
  additionalData: {
    $type: 'HierarchyAdditionalData',
    fieldId,
    isMultiSelection,
    singleSelectionTargetJsonPath,
    exactSearch
  }
})

export const getEmptyNumericRangeExpression = (
  fieldId?: string,
  minValue?: number,
  maxValue?: number
): NumericRangeExpression => ({
  $type: 'NumericRangeExpression',
  fieldId,
  minValue,
  maxValue,
  interval: Interval.LeftClosedRightClosed
})

export const getEmptyDateRangeExpression = (
  fieldId?: string,
  minValue?: string,
  maxValue?: string
): DateRangeExpression => ({
  $type: 'DateRangeExpression',
  fieldId,
  minValue,
  maxValue,
  interval: Interval.LeftClosedRightClosed
})

export const getEmptySemanticExpression = (semanticSearchType: SemanticSearchType): SemanticExpression => ({
  $type: 'SemanticExpression',
  type: semanticSearchType,
  search: ''
})

export const getEmptyGeoDistanceExpression = (
  fieldId: string,
  searchType: SearchType,
  operator: FieldOperator = FieldOperator.In
): GeoDistanceExpression => ({
  $type: 'GeoDistanceExpression',
  fieldId,
  additionalData: {
    $type: 'GeoDistanceMetadata',
    searchType
  },
  operator
})

export const getEmptyGeoPolygonExpression = (fieldId: string, searchType: SearchType): GeoPolygonExpression => ({
  $type: 'GeoPolygonExpression',
  fieldId,
  additionalData: {
    $type: 'GeoDistanceMetadata',
    searchType
  }
})

export const addNewCompoundExpression = (expression: CompoundExpression): CompoundExpression => {
  return { ...expression, childExpressions: [...expression.childExpressions, getDefaultRootExpression()] }
}

export const addNewFieldExpression = (
  expression: CompoundExpression,
  fieldId?: string,
  value?: string | number | boolean | string[] | null,
  operator?: FieldOperator
): CompoundExpression => {
  return {
    ...expression,
    childExpressions: [...expression.childExpressions, getEmptyFieldExpression(fieldId, value, operator)]
  }
}

export const addNewHierarchyExpression = (
  expression: CompoundExpression,
  fieldId?: string,
  singleSelectionTargetJsonPath?: string,
  isMultiSelection?: boolean,
  childExpressions?: FieldExpression[]
): CompoundExpression => {
  return {
    ...expression,
    childExpressions: [
      ...expression.childExpressions,
      getEmptyHierarchyExpression(fieldId, singleSelectionTargetJsonPath, isMultiSelection, childExpressions)
    ]
  }
}

export const updateChildExpression = (
  expression: CompoundExpression,
  updatedExpression: Expression,
  indexToUpdate: number
) => {
  return {
    ...expression,
    childExpressions: _.map(expression.childExpressions, (c, i) => {
      if (i === indexToUpdate) return updatedExpression
      return c
    })
  }
}

export const getChildExpressionById = (expression: CompoundExpression, fieldId: string): Expression | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const e of expression.childExpressions) {
    if (isCompoundExpression(e)) {
      const childExpression = getChildExpressionById(e, fieldId)
      if (childExpression) return childExpression
    }
    const id = getFieldIdFromExpression(e)
    if (id === fieldId) return e
  }
  return undefined
}

export const getCompoundExpressionById = (expression: CompoundExpression, groupId: string) => {
  return _.find(_.filter(expression.childExpressions, isCompoundExpression), { groupId })
}

export const getOrCreateCompoundExpressionById = (expression: CompoundExpression, groupId: string) => {
  return getCompoundExpressionById(expression, groupId) ?? getDefaultRootExpression(CompoundOperator.Or, groupId)
}

const updateCompoundExpression = (
  expression: CompoundExpression,
  groupId: string,
  newExpression: CompoundExpression
) => {
  const childExpressionIndex = _.findIndex(
    expression.childExpressions,
    e => isCompoundExpression(e) && e.groupId === groupId
  )
  if (childExpressionIndex === -1) {
    return {
      ...expression,
      childExpressions: [...expression.childExpressions, newExpression]
    }
  }
  return updateChildExpression(expression, newExpression, childExpressionIndex)
}

export const addExpressionBasedOnFilterDefinition = (
  expression: CompoundExpression,
  filterDefinition: Filter,
  expressionToAdd?: Expression
): CompoundExpression => {
  if (filterDefinition.orGroup !== undefined) {
    const orGroupExpression = getOrCreateCompoundExpressionById(expression, filterDefinition.orGroup)
    const updatedExpression = addExpressionBasedOnFilterDefinition(
      orGroupExpression,
      _.omit(filterDefinition, 'orGroup'),
      expressionToAdd
    )
    return updateCompoundExpression(expression, filterDefinition.orGroup, updatedExpression)
  }

  if (expressionToAdd) {
    return { ...expression, childExpressions: [...expression.childExpressions, expressionToAdd] }
  }

  if (
    filterDefinition.type === FilterType.BooleanToggle ||
    filterDefinition.type === FilterType.Text ||
    filterDefinition.type === FilterType.DateRange ||
    filterDefinition.type === FilterType.Map ||
    filterDefinition.type === FilterType.LongRange ||
    filterDefinition.type === FilterType.NumericRange
  )
    return addNewFieldExpression(expression, filterDefinition.fieldId, null)

  if (filterDefinition.type === FilterType.Domain || filterDefinition.type === FilterType.ListAsDomain)
    return addNewHierarchyExpression(
      expression,
      filterDefinition.fieldId,
      filterDefinition.parameters.singleSelectionTargetJsonPath,
      true,
      []
    )

  return expression
}

export const removeChildExpression = (expression: CompoundExpression, indexToRemove: number) => {
  return {
    ...expression,
    childExpressions: _.reject(expression.childExpressions, (__, i) => i === indexToRemove)
  }
}

export const removeChildExpressionByFieldId = (expression: CompoundExpression, fieldId: string): CompoundExpression => {
  return {
    ...expression,
    childExpressions: _.compact(
      _.map(expression.childExpressions, e => {
        if (isCompoundExpression(e)) {
          const updatedExpression = removeChildExpressionByFieldId(e, fieldId)
          return _.isEmpty(updatedExpression.childExpressions) ? undefined : updatedExpression
        }
        return getFieldIdFromExpression(e) === fieldId ? undefined : e
      })
    )
  }
}

export const updateChildExpressionByFieldId = (
  expression: CompoundExpression,
  fieldId: string,
  expressionToUpdate: Expression
): CompoundExpression => {
  return {
    ...expression,
    childExpressions: _.map(expression.childExpressions, e => {
      if (isCompoundExpression(e)) {
        return updateChildExpressionByFieldId(e, fieldId, expressionToUpdate)
      }
      return getFieldIdFromExpression(e) === fieldId ? expressionToUpdate : e
    })
  }
}

export const textOperators = [
  FieldOperator.Equals,
  FieldOperator.NotEquals,
  FieldOperator.NotExists,
  FieldOperator.Exists
] as const

export const domainOperators = [FieldOperator.IsOneOf, FieldOperator.IsNotOneOf] as const

export const rangeOperators = [
  FieldOperator.Equals,
  FieldOperator.NotEquals,
  FieldOperator.Greater,
  FieldOperator.Less,
  FieldOperator.Ge,
  FieldOperator.Le,
  FieldOperator.NotExists,
  FieldOperator.Exists,
  FieldOperator.Between
] as const

export const mapOperators = [FieldOperator.In, FieldOperator.NotIn] as const

export const operatorContainsNull = (operator?: FieldOperator) => {
  return operator === FieldOperator.NotExists || operator === FieldOperator.Exists
}

export const resetExpressionValue = (expression: Expression): Expression | undefined => {
  if (isCompoundExpression(expression)) {
    const newExpression = {
      ...expression,
      childExpressions: _.compact(_.map(expression.childExpressions, resetExpressionValue))
    }
    return _.isEmpty(newExpression.childExpressions) ? undefined : newExpression
  }
  return undefined
}

export const resetCompoundExpressionValues = (expression: CompoundExpression): CompoundExpression => {
  return { ...expression, childExpressions: [] }
}

export const resetExpressionValueById = (expression: CompoundExpression, id: string): CompoundExpression => ({
  ...expression,
  childExpressions: _.compact(
    _.map(expression.childExpressions, e => {
      if (isCompoundExpression(e)) {
        return resetExpressionValueById(e, id)
      }
      if (id === getFieldIdFromExpression(e)) {
        return resetExpressionValue(e)
      }
      return e
    })
  )
})
