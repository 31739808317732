import React from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { DeleteCompaniesDialogData } from './types'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'
import { StyledDialog, StyledDialogBody, ContainerItem, BoldText } from '../components'
import { deleteCompanies } from '../../../api/portfolio'
import { remapSelectionUnits } from './utils'
import { TrackingActions } from '../../../features/analytics/actions'
import { DialogActions } from '../../../features/dialog/actions'
import { EventCategory, GroupActionType, CallToActionType } from '../../../features/analytics/types'
import { selectAllRows } from '../../../features/portfolioCompany/withTable/actions'
import { convertCompoundExpressionForBackend } from '../../../features/filters/utils'

export type DeleteCompaniesFormBaseProps = {
  close: () => void
  data: DeleteCompaniesDialogData
}

export const DeleteCompaniesDialog = ({ close, data }: DeleteCompaniesFormBaseProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'portfolio'])

  const {
    mutate: mutateDeleteCompanies,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(
    () => {
      return deleteCompanies({
        selection: {
          portfolioGridQuery: {
            semantic: data.semantic,
            query: convertCompoundExpressionForBackend(data.expression),
            excludedPortfoliosIds: data.excludedPortfoliosIds
          },
          selectedItems: !data.isSelectAll ? remapSelectionUnits(data.selectedCompanies) : undefined,
          itemsToIgnore: data.isSelectAll ? remapSelectionUnits(data.deSelectedCompanies) : undefined,
          sourceContainerItemId: data.portfolioId,
          destinationContainerItemId: data.portfolioId
        }
      })
    },
    {
      onSuccess: () => {
        dispatch(selectAllRows('views.portfolioDetail')(false, data.portfolioId))
        if (data.isGroupAction) {
          dispatch(TrackingActions.trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.DeleteCompany))
        } else {
          dispatch(TrackingActions.trackCTAClick(EventCategory.PortfolioDetail, CallToActionType.DeleteCompany))
        }
      },

      onError: (e: AxiosError) => {
        if (e?.response?.status === 423) {
          dispatch(DialogActions.showDialog('PortfolioErrorLock'))
        }
      }
    }
  )

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('portfolio:CompanyGroupActions:ActionItems:DeleteCompany')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) {
          return mutateDeleteCompanies()
        }
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      footer
    >
      <StyledDialogBody>
        <ContainerItem>
          {(isIdle || isLoading) && (
            <>
              <BoldText>{t('portfolio:CompanyDeletion:ConfirmDelete')}</BoldText>
              <p>{t('portfolio:Management:DeletePortfolio:DataWillRemain')}</p>
            </>
          )}
          {isSuccess && <p>{t('portfolio:CompanyDeletion:DeleteSuccess')}</p>}
          {isError && <p>{t('portfolio:DialogError')}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
