import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Dialog } from '../../../components/Dialog'
import { DeletePortfolioViewState } from '../../../features/portfolio/types'
import { CenterChildren } from '../../../components/Center'
import * as dialogSelectors from '../../../features/dialog/selectors'
import OkImage from '../../../assets/images/ok.svg'
import QuestionImage from '../../../assets/images/portfolio-management-small.svg'
import { gridTheme } from '../../../utils/theme'

type Props = {
  isLoading: boolean
  view: DeletePortfolioViewState
  deletePortfolio: () => void
  reload: () => void
  close: () => void
}

const CenterText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
`

const BoldText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
`

const ContainerItem = styled.div`
  flex: 1 1 auto;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    &:not(:first-child) {
      padding-top: 40px;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    flex-direction: column;
  }
`

export const DeletePortfolioDialog = ({ isLoading, view, deletePortfolio, reload, close }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const { portfolioName } = useSelector(dialogSelectors.getVisibleDialogData)
  const props = {
    destroyOnClose: false,
    width: 1160,
    visible: true,
    loading: isLoading,
    footer: '',
    title: t('portfolio:Management.DeletePortfolio.DeletePortfolio')
  }

  const onDeletePortfolioDone = useCallback(() => {
    reload()
  }, [reload])

  if (view === DeletePortfolioViewState.DeletePortfolioView) {
    return (
      <Dialog
        {...props}
        cancelText={t('common:Cancel')}
        okText={t('common:Delete')}
        onOk={() => {
          deletePortfolio()
        }}
        onCancel={close}
      >
        <Container>
          <ContainerItem>
            <CenterText>
              <BoldText>{t('portfolio:Management.DeletePortfolio.AreYouSure', { name: portfolioName })}</BoldText>
              <div>{t('portfolio:Management.DeletePortfolio.DataWillRemain')}</div>
            </CenterText>
          </ContainerItem>
          <ContainerItem>
            <CenterChildren>
              <img src={QuestionImage} alt="question" />
            </CenterChildren>
          </ContainerItem>
        </Container>
      </Dialog>
    )
  }
  if (view === DeletePortfolioViewState.DeletePortfolioDone) {
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={onDeletePortfolioDone} onCancel={onDeletePortfolioDone}>
        <Container>
          <ContainerItem>
            <CenterText>
              <BoldText>
                {t('portfolio:Management.DeletePortfolio.PortfolioDeletedCorrectly', { name: portfolioName })}
              </BoldText>
              <div>{t('portfolio:Management.DeletePortfolio.DataWillRemain')}</div>
            </CenterText>
          </ContainerItem>
          <ContainerItem>
            <CenterChildren>
              <img src={OkImage} alt="ok" />
            </CenterChildren>
          </ContainerItem>
        </Container>
      </Dialog>
    )
  }
  return null
}
