import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { SelectValue } from 'antd3/lib/select'
import { useGetPortfolioConfiguration } from '../../../../api/targeting/queries'
import { Row } from '../../../../components/Grid'
import { Select, Option } from '../../../../components/Select'
import { ItemCounter } from '../../../../components/ItemCounter'
import { Text } from '../../../../components/Text'
import { WizardNav, NavProps, WizardNavigationWrapper } from '../../../../components/Wizard'
import { SmallSpin } from '../../../../components/Loading/Spin'
import { useLoadPortfolioStatus } from '../../MarketAnalysisWizard/useLoadPortfolioStatus'
import { SelectedPortfolio } from '../../../../features/targeting/types'

type Props = {
  onSubmit: () => void
  selectedPortfolios: SelectedPortfolio[] | null
} & NavProps

export const LoadPortfolioStepTwo = ({ onSubmit, currentStep, totalSteps, selectedPortfolios, ...rest }: Props) => {
  const { t } = useTranslation(['targeting', 'common'])
  const [isStatusSelected, setIsStatusSelected] = useState(false)

  const { setSelectedStatus, selectedStatus } = useLoadPortfolioStatus()
  const { data: portfolioConfig, isFetching: isPortfolioConfigFetching } = useGetPortfolioConfiguration(
    _.map(selectedPortfolios, selectedPortfolio => selectedPortfolio.id)
  )

  const setStatus = (value: SelectValue) => {
    setSelectedStatus(value as string)
    setIsStatusSelected(true)
  }

  return (
    <>
      <Row>
        <ItemCounter title item={currentStep} totalItems={totalSteps} text={t('MarketAnalysisWizard.StepTwo.Header')} />
      </Row>
      <Row padding="8px 0 28px 0">
        <div>
          <Text size="L" block>
            {t('MarketAnalysisWizard.StepTwo.DescriptionLine1')}
          </Text>
          <Text size="L" block>
            {t('MarketAnalysisWizard.StepTwo.DescriptionLine2')}
          </Text>
        </div>
      </Row>
      <Text className="selection-label" size={12} primary bold block>
        {t('MarketAnalysisWizard.StepTwo.Status')}
      </Text>
      <Select
        placeholder={t('common:Select')}
        disabled={isPortfolioConfigFetching}
        loading={isPortfolioConfigFetching}
        notFoundContent={isPortfolioConfigFetching ? SmallSpin : undefined}
        onChange={setStatus}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={selectedStatus?.value}
      >
        {_.map(portfolioConfig?.customFilters, ({ fieldValue, labelKey }) => (
          <Option key={fieldValue} value={fieldValue}>
            {labelKey}
          </Option>
        ))}
      </Select>
      <WizardNavigationWrapper>
        <WizardNav {...rest} nextDisabled={isStatusSelected === false} nextStep={onSubmit} />
      </WizardNavigationWrapper>
    </>
  )
}
