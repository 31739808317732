/* eslint-disable import/no-default-export */
export default {
  Support: {
    Email: 'info@crif.com',
    Phone: '+39 051 41 76 111'
  },
  ImprintPolicy: 'Mentions légales',
  PrivacyPolicy: 'Politique de confidentialité',
  CookiesPolicy: 'Politique en matière de cookies',
  Contact: 'Nous contacter'
}
