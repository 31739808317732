export type WithValueOptional<T = string> = {
  value?: T
}

export type WithListValueOptional<T = string> = {
  values?: T[]
}

export type WithLabelDescriptionOptional = {
  labelDescription?: string
}

export type WithCodeOptional = {
  code?: string
}

export type WithDomainOptional = {
  domain?: string
}

export type WithDescriptionOptional = {
  description?: string
}

export type ReportValueTypeValue<T = string> = WithLabelDescriptionOptional & WithValueOptional<T>

export type ReportListValueType<T = string> = WithLabelDescriptionOptional & WithListValueOptional<T>

export type ReportValueTypeCode = WithLabelDescriptionOptional &
  WithCodeOptional &
  WithDomainOptional &
  WithDescriptionOptional

export type ReportValueTypeGeneral<T = string> = ReportValueTypeCode & WithValueOptional<T>

export type ThreeYearValue<T = string> = WithLabelDescriptionOptional & {
  values: [T | undefined, T | undefined, T | undefined]
}

export enum PartnerType {
  Unknown,
  Company,
  Person
}

export type WithPartnerType = {
  type: PartnerType
}

export type ReportSectionVisibility = 'always' | 'enriched'

export type ReportSection = {
  title: string
  path?: string
  position: 'top' | 'bottom'
  visibility: ReportSectionVisibility
}
