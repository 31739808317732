import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Text } from '../../../components/Text'
import { EnrichCreditStatistics } from '../../../components/Statistic/EnrichCreditStatistic'
import { Dialog } from '../../../components/Dialog'
import { getCompanyName } from '../../../features/config/selectors'
import { enrichPortfolio, useGetPortfolioEnrichmentProposal } from '../../../api/enrichment'
import { EnrichPortfolioDialogData, EnrichViewState } from './types'

type Props = {
  close: () => void
  data: EnrichPortfolioDialogData
}

export const EnrichPortfolioDialog = ({ close, data }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const companyName = useSelector(getCompanyName)

  const [view, setView] = React.useState(EnrichViewState.None)

  const { data: proposal, isLoading } = useGetPortfolioEnrichmentProposal(
    data.portfolioId,
    {
      isSelectAll: true,
      semantic: data.semantic,
      expression: data.expression,
      excludedPortfoliosIds: data.excludedPortfoliosIds
    },
    {
      onSettled: () => setView(EnrichViewState.EnrichStatistic)
    }
  )

  const mutation = useMutation(() => enrichPortfolio(data.portfolioId), {
    onSettled: close
  })

  if (proposal && view === EnrichViewState.EnrichStatistic) {
    const canBuy = proposal.remainingCreditsAfterPortfolioCompaniesEnrichment >= 0
    return (
      <Dialog
        visible
        destroyOnClose={false}
        width={900}
        loading={isLoading || mutation.isLoading}
        onCancel={close}
        title={t('portfolio:Enrichment.Enrichment')}
        footer={t('portfolio:Enrichment.PortfolioEnrichment')}
        cancelText={t('Cancel')}
        okText={t('Continue')}
        okButtonProps={{
          disabled: !canBuy
        }}
        onOk={canBuy ? () => mutation.mutate() : undefined}
      >
        <Text block margin={{ top: 20, bottom: 40 }}>
          {t('portfolio:Enrichment.EnrichmentDescription', { companyName })}
        </Text>
        <EnrichCreditStatistics
          totalCompanies={proposal.selectedCompaniesCount}
          enrichedCompanies={proposal.selectedEnrichedCompaniesCount}
          notEnrichedCompanies={proposal.selectedCompaniesCount - proposal.selectedEnrichedCompaniesCount}
          usedCredits={proposal.requiredCreditsToEnrichPortfolioCompanies}
          remainingCredits={proposal.remainingCreditsAfterPortfolioCompaniesEnrichment}
          inDialog
        />
      </Dialog>
    )
  }
  return null
}
