import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { editTask } from '../../../api/tasks'
import { validateNewTaskName } from '../../../api/tasks/validators'
import { getGoalsKeys } from '../../../api/goals'
import { EditTaskDialogData } from './types'
import { HookForm } from '../../../components/Form'
import { InputField } from '../../../components/Form/Input'
import { Text } from '../../../components/Text'
import { StyledDialog } from '../components'
import { validateNameSynchronous } from '../../../utils/validators'

export type EditTaskFormState = {
  taskName?: string
}

type Props = {
  data: EditTaskDialogData
  close: () => void
}

export const EditTaskDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()
  const { control, handleSubmit, formState, watch } = useForm<EditTaskFormState>({
    mode: 'onChange'
  })

  const { isSubmitting, errors, isValidating } = formState

  const {
    mutate: mutateEditTask,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(() => editTask(data.taskId, watch('taskName')!), {
    onSuccess: () => {
      queryClient.invalidateQueries(getGoalsKeys.GetGoal(data.goalId), { refetchType: 'all' })
    }
  })

  const asyncValidateTaskName = async (value: any) => {
    const validationResponse = await validateNewTaskName(data.goalId, value as string)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(false)(watch('taskName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:Goal.EditTask.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={isIdle || isLoading ? t('common:Save') : t('common:Ok')}
      onOk={() => {
        if (isIdle) {
          return mutateEditTask()
        }
        return close()
      }}
      okButtonProps={{
        disabled: !watch('taskName') || isLoading || isValidating || !_.isEmpty(errors),
        loading: isValidating
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <HookForm handleSubmit={handleSubmit} onSubmit={mutateEditTask} isSubmitting={isSubmitting}>
        {(isIdle || isLoading) && (
          <>
            <Text block margin={{ bottom: 40 }}>
              {t('account:Goal.EditTask.Body')}
            </Text>
            <InputField
              name="taskName"
              label={t('account:Goal.EditTask.NameInput')}
              placeholder={t('account:Goal.EditTask.NameInput')}
              defaultValue={data.taskName}
              control={control}
              formState={formState}
              validators={{
                validateNameSynchronous: validateNameSynchronous(false),
                asyncValidateTaskName
              }}
            />
          </>
        )}
        {isSuccess && <p>{t('account:Goal.EditTask.Success')}</p>}
        {isError && <p>{t('account:Goal.EditTask.Error')}</p>}
      </HookForm>
    </StyledDialog>
  )
}
