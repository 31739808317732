import { useEffect, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationWithDate, PushEvent, PushEventBase } from '../../features/notifications/types'
import { NotificationActions } from '../../features/notifications/actions'
import { getPushEvents } from '../../features/notifications/selectors'
import { UserActions } from '../../features/user/actions'
import { PushEventProgressDataResponse } from '../../api/notification/types'

const isProgressPushEvent = (
  pushEvent: PushEvent
): pushEvent is PushEventBase & NotificationWithDate & PushEventProgressDataResponse =>
  'data' in pushEvent && 'progress' in pushEvent.data

const unhandledPushEventError = (pushEvent: PushEvent): never => {
  throw new Error(`Unhandled push event type ${pushEvent.type}`)
}

export const PushEventContainer = () => {
  const dispatch = useDispatch()
  const pushEvents = useSelector(getPushEvents)

  const progressEventHandler = useCallback(
    (pushEvent: PushEvent) => {
      if (isProgressPushEvent(pushEvent) && pushEvent.data.progress >= 100) {
        dispatch(NotificationActions.removePushEvents([pushEvent]))
      }
    },
    [dispatch]
  )

  const eventHandlers: Record<PushEvent['type'], (pushEvent: PushEvent) => void> = useMemo(
    () => ({
      ForceLogoutUser: () => dispatch(UserActions.forceLogout()),
      UploadPortfolioProgress: progressEventHandler,
      DuplicatePortfolioProgress: progressEventHandler,
      ExportPortfolioProgress: progressEventHandler,
      DeleteCompanyFromPortfolioProgress: progressEventHandler,
      MoveFromPortfolioSelectionProgress: progressEventHandler,
      ShareCompaniesProgress: progressEventHandler,
      UnshareCompaniesProgress: progressEventHandler,
      AssignSalesProgress: progressEventHandler,
      AssignAreaManagerProgress: progressEventHandler,
      AssignProductProgress: progressEventHandler,
      CreatePortfolioFromSelectionProgress: progressEventHandler,
      EnrichCompanyFromPortfolioProgress: progressEventHandler,
      ProspectingSaveListProgress: progressEventHandler,
      ProspectingUpdateListProgress: progressEventHandler,
      TargetingSaveListProgress: progressEventHandler,
      TargetingUpdateListProgress: progressEventHandler,
      CreateCampaignProgress: progressEventHandler,
      AssignCampaignProgress: progressEventHandler,
      AddPortfolioTaskProgress: progressEventHandler,
      UpdateCampaignByPortfolioTaskProgress: progressEventHandler,
      SetCompaniesTaskProgress: progressEventHandler,
      ExportCampaignProgress: progressEventHandler,
      PdfGenerateProgress: progressEventHandler,
      AddLocalUnitsToPortfolioProgress: progressEventHandler,
      MoveCompaniesProgress: progressEventHandler
    }),
    [dispatch, progressEventHandler]
  )

  useEffect(() => {
    _.forEach(pushEvents, pushEvent => {
      const handler = eventHandlers[pushEvent.type] ?? unhandledPushEventError
      handler(pushEvent)
    })
  }, [pushEvents, eventHandlers])

  return null
}
