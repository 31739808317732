import _ from 'lodash'
import { Reducer } from 'redux'
import { CompaniesListRow, AssignmentsData, CompanyStatusDict } from './types'
import {
  getUpdatedData,
  isAllCompaniesTab,
  updateAssignmentsDataSingle,
  updateAssignmentsDataWithoutStatus,
  updateAssignmentsDataWithStatus,
  NO_STATUS
} from './helpers'
import * as actions from './actions'

export type SalestoolTableState = {
  isSelectAllActive: boolean
  selectedRows: string[]
  table: {
    rows: CompaniesListRow[]
  }
  total: number | undefined
  companiesAssignmentStatus: CompanyStatusDict
  assignmentsByStatus: AssignmentsData
  pendingAssignments?: AssignmentsData
  loadingAssignments: string[]
}

export const initState: SalestoolTableState = {
  isSelectAllActive: false,
  selectedRows: [],
  table: {
    rows: []
  },
  total: undefined,
  companiesAssignmentStatus: {},
  assignmentsByStatus: {
    assigned: { assignments: {} },
    notAssigned: { assignments: {} },
    [NO_STATUS]: { assignments: {} }
  },
  loadingAssignments: []
}

export const salestoolTableReducer: Reducer<SalestoolTableState, actions.SalestoolTableActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case actions.SET_TABLE_CONTENT: {
      const { isSale } = action.payload
      const { data, total } = action.payload.data

      const { selectedRows, rows, companiesAssignmentStatus } = getUpdatedData(
        state.selectedRows,
        state.isSelectAllActive,
        isSale,
        data
      )

      return {
        ...state,
        selectedRows,
        companiesAssignmentStatus,
        table: {
          rows
        },
        total
      }
    }
    case actions.ASSIGN_ITEM_TO_COMPANIES: {
      const { type, value, currentTab } = action.payload

      // Don't consider status, always write to the same key (NO_STATUS)
      if (!currentTab) {
        const currentData = state.assignmentsByStatus[NO_STATUS]
        const assignmentsWithoutStatus = updateAssignmentsDataWithoutStatus(
          state.isSelectAllActive,
          state.selectedRows,
          currentData.assignments,
          type,
          value
        )

        return {
          ...state,
          assignmentsByStatus: {
            ...state.assignmentsByStatus,
            [NO_STATUS]: {
              assignments: assignmentsWithoutStatus
            }
          }
        }
      }

      // Write to the appropriate key depending on status (selected tab)
      const assignmentsWithStatus = updateAssignmentsDataWithStatus({
        currentStatus: currentTab,
        isSelectAllActive: state.isSelectAllActive,
        selectedItems: state.selectedRows,
        visibleCompanies: state.table.rows,
        currentAssignmentsByStatus: state.assignmentsByStatus,
        companiesStatusDict: state.companiesAssignmentStatus,
        type,
        value
      })

      return {
        ...state,
        assignmentsByStatus: assignmentsWithStatus
      }
    }
    case actions.ASSIGN_ITEM_TO_COMPANY: {
      const { type, value, entityId, currentTab } = action.payload
      // Get the correct status by entityId, if viewing all companies
      // If no tab is provided, default to NO_STATUS
      const status = isAllCompaniesTab(currentTab) ? state.companiesAssignmentStatus[entityId] : currentTab || NO_STATUS
      const currentData = state.assignmentsByStatus[status]
      return {
        ...state,
        assignmentsByStatus: {
          ...state.assignmentsByStatus,
          [status]: updateAssignmentsDataSingle(currentData, entityId, type, value)
        }
      }
    }
    case actions.MOVE_ASSIGNMENTS_PENDING: {
      const currentPendingAssignments = state.assignmentsByStatus
      const previousPendingAssignments = state.pendingAssignments || {}
      return {
        ...state,
        assignmentsByStatus: initState.assignmentsByStatus,
        pendingAssignments: _.merge(previousPendingAssignments, currentPendingAssignments)
      }
    }
    case actions.TOGGLE_SELECT_ALL_ROWS:
      return {
        ...state,
        isSelectAllActive: !state.isSelectAllActive,
        selectedRows: state.isSelectAllActive ? [] : _.map(state.table.rows, 'entityId')
      }
    case actions.SET_SELECTED_ROWS: {
      const selectedRows = action.payload
      const allSelected = selectedRows.length === state.total
      const noneSelected = selectedRows.length === 0
      return {
        ...state,
        selectedRows,
        isSelectAllActive: allSelected || (noneSelected && state.isSelectAllActive ? false : state.isSelectAllActive)
      }
    }
    case actions.ASSIGNMENT_LOADING: {
      const { id, loading } = action.payload
      return {
        ...state,
        loadingAssignments: loading
          ? [...state.loadingAssignments, id]
          : _.reject(state.loadingAssignments, loadingId => loadingId === id)
      }
    }
    case actions.RESET_CONTENT:
      return {
        ...state,
        isSelectAllActive: false,
        assignmentsByStatus: initState.assignmentsByStatus,
        companiesAssignmentStatus: initState.companiesAssignmentStatus,
        table: {
          ...initState.table
        },
        total: undefined,
        pendingAssignments: undefined,
        loadingAssignments: []
      }
    case actions.CLEAR_PENDING_ASSIGNMENTS:
      return {
        ...state,
        pendingAssignments: undefined
      }
    default:
      return state
  }
}
