/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  ChooseNameForCompaniesList: 'Vyberte název seznamu společností',
  EnterARepresentativeName: 'Zadejte reprezentativní název portfolia, které nahráváte.',
  EnterARepresentativeNameCompaniesList: 'Vložte název seznamu společností, který ukládáte.',
  ItWillIdentifyCompaniesList: 'Pomůže vám to jej později identifikovat.',
  PortfolioName: 'Název portfolia',
  InsertNameForPortfolio: 'Zadejte název portfolia',
  UploadPortfolio: {
    StandardTitle: 'Nahrát portfolio',
    UploadPortfolioOrConnect: 'Nahrajte portfolio nebo připojte CRM své společnosti, abyste mohli pokračovat',
    UploadOrConnect: 'Nahrajte portfolio nebo připojte své firemní CRM',
    UploadFromPlatform: 'Nahrát z platformy',
    UploadAPortfolio: 'Nahrajte portfolio',
    SelectFromPc: 'Vybrat z počítače',
    DownloadTemplateDescription1: 'Stáhnout šablonu',
    DownloadTemplateDescription2:
      'Zadejte údaje o svých klientských společnostech tak, jak je uvedeno v návodu. Zachovejte strukturu tabulek.',
    DownloadTemplateDescription3: 'V dalším kroku nahrajte soubor',
    DownloadTemplate: 'Stáhnout šablonu',
    ChooseHowToUploadPortfolio: 'Prosím vyberte soubor',
    SelectPortfolioFromPc: 'Vybrat portfolio z počítače',
    YouCanLoad: 'Můžete načíst další portfolio, kdykoli budete chtít. Portfolia se budou načítat odděleně!',
    ToEnsureTheCorrectLoading:
      'Pro zajištění správného načítání společností se ujistěte, že jste použili poskytnutou šablonu',
    ChooseHowToUploadForUpdate: 'Vyberte, jak aktualizovat seznam společností',
    SelectCompanyListFromPC: 'Vybrat seznam společností z počítače',
    ToEnsureTheCorrectLoadingForUpdate:
      'Chcete-li zajistit správné načtení seznamu společností, ujistěte se, že jste použili šablonu poskytnutou společností Margo. Stáhněte si základní seznam z funkce "Stáhnout portfolio", zkopírujte a vložte hodnoty do souboru šablony, aktualizujte jej a nahrajte vyplněnou šablonu v této sekci.',
    SelectFile: 'Vybrat soubor',
    SelectFileFromComputer: 'Vybrat požadovaný soubor',
    ChoosePortfolioName: 'Zvolit název portfolia',
    ItWillDistinguish: 'Pomůže vám to odlišit jej od ostatních portfolií!',
    WrapperPage: {
      TypeOfUpload: 'Typ nahrávání',
      TypeOfUploadDescription: 'Vyberte typ nahrávání, které chcete provést: standardní nebo zjednodušené',
      StandardUpload: 'Standardní nahrávání',
      StandardUploadTooltip: 'Nahrajte společnosti do Margo prostřednictvím předdefinované šablony',
      SimplifiedUpload: 'Zjednodušené nahrávání',
      SimplifiedUploadTooltip: 'Nahrajte seznam Identifikačních kódů přímo na web zjednodušeným způsobem'
    },
    Simplified: {
      Title: 'Zjednodušeně nahrát portfolio',
      FirstStepTitle: 'Vložte nebo zadejte identifikační kódy',
      UploadWarning: 'VAROVÁNÍ: Zavřením této stránky nebo krokem zpět bez uložení budou všechny změny ztraceny',
      UploadPlaceholder: 'Vložte nebo zadejte platný daňový kód/DIČ/číslo CRIF oddělené mezerou',
      Description1: 'Vložte nebo zadejte platný daňový kód/DIČ/číslo CRIF oddělené mezerou',
      Description2: 'Klikněte na tlačítko "Pokračovat" pro další kroky',
      Description3:
        'Potenciální chyby budou hlášeny v "tabulce chyb", přístupné kliknutím na "Spravovat chybu" v souvisejícím oznámení',
      IdentificationLengthErrorMessage: 'Identifikační kód musí mít alespoň {{minLength}} znaků.',
      ChooseStatus: {
        Title: 'Vyberte status, který chcete použít',
        Description1: 'Vyberte počáteční status společností, které chcete nahrát.',
        Description2: 'Stejný status společnosti bude aplikován na celé portfolio.'
      }
    },
    ManageErrors: {
      Title: 'Zobrazit a opravit chyby',
      Description1: 'Upravte nebo odstraňte nesprávné řádky',
      Description2: 'Kliknutím na tlačítko "Pokračovat" nahrajte soubor',
      Description3: 'V portfoliu naleznete opravené společnosti',
      IdentificationCodeTitle: 'Daňový kód/DIČ/Číslo CRIF',
      CompanyMatchStatusTitle: 'Stav nahrávání',
      StatusDescriptionTitle: 'Popis stavu',
      DownloadList: 'Stáhnout seznam',
      CompanyMatchStatus: {
        Rejected: 'Odmítnuto',
        RejectedDescription: 'Daňový kód/DIČ/Číslo CRIF není platné',
        Duplicate: 'Duplikát',
        DuplicateDescription: 'Daňový kód/DIČ/Číslo CRIF je duplikát',
        NotMatched: 'Nenalezeno',
        NotMatchedDescription: 'Daňový kód/DIČ/Číslo CRIF je platné, ale v databázi nebyla nalezena žádná shoda',
        Pending: 'Čeká na ověření',
        PendingDescription: 'Daňový kód/DIČ/Číslo CRIF bylo změněno. Kliknutím na Pokračovat jej ověřte'
      }
    }
  },
  ExcludeCustomVariables: 'Vyloučit vlastní proměnné',
  ExcludeCustomVariablesConfirm:
    'Chcete stěhovat společnosti bez vlastních proměnných? Pokud ano, budou vlastní proměnné z původního portfolia odstraněny.',
  Management: {
    Header: 'Správa portfolia',
    Upload: 'Nahrát portfolio',
    UploadTooltip: 'Nahrajte společnosti do Margo prostřednictvím předdefinované šablony',
    UploadTooltipWithSimplified: 'Nahrajte seznam Identifikačních kódů standardním nebo zjednodušeným způsobem',
    ShowCorporate: 'Prohlédnout podnikové portfolio',
    SearchPortfolioTooltip: 'Hledat portfolio',
    SearchPortfolioPlaceholder: 'Vyhledat název portfolia',
    SearchHeader: 'Správa portfolia - Výsledky vyhľadávania',
    PortfolioTabs: {
      MyPortfolios: 'Moje portfolia',
      SharedPortfolios: 'Sdílená portfolia',
      ReceivedPortfolios: 'Přijatá portfolia'
    },
    PortfolioName: {
      ChangePortfolioName: 'Změnit název portfolia',
      EnterARepresentativeName: 'Zadejte reprezentativní název portfolia',
      InsertPortfolioName: 'Zadejte název portfolia',
      NameChangedCorrectly: 'Název byl změněn v'
    },
    PortfolioNote: {
      AddNote: 'Přidat poznámku',
      EditNote: 'Upravit poznámku',
      AddANoteToDescribe: 'Přidat poznámku o charakteru portfolia',
      AddNotePlaceholder: 'Přidat poznámku',
      AddNoteFieldLabel: 'Poznámka'
    },
    DeletePortfolio: {
      DeletePortfolio: 'Vymazat portfolio',
      AreYouSure: 'Opravdu chcete smazat {{name}}?',
      PortfolioDeletedCorrectly: '{{Name}} bylo smazáno.',
      DataWillRemain: 'Veškeré údaje o společnosti, které jste stáhli, zůstanou ve vašem úložišti.'
    },
    Table: {
      NumberOfCompanies: 'Počet společností',
      EnrichedCompanies: 'Obohacené společnosti',
      CreationDate: 'Datum vytvoření',
      LastUpdateDate: 'Datum poslední aktualizace',
      CampaignsWithThisPortfolio: 'Kampaně v portfoliu'
    },
    Menu: {
      ChangeName: 'Změnit jméno',
      DuplicatePortfolio: 'Duplicitní portfolio',
      DeletePortfolio: 'Vymazat portfolio',
      AddNote: 'Přidat poznámku',
      EditNote: 'Upravit poznámku'
    },
    Message: {
      Header: 'Kliknutím na "Nahrát portfolio" nahrajete své první portfolio!',
      Description:
        'Zde vidíte již uložené seznamy s navrženými prospekty. Klikněte na název seznamu, abyste mohli analyzovat a spravovat společnosti, které obsahuje.'
    },
    UploadComplete: 'Nahrávání je dokončeno!',
    CompaniesUploadedCorrectly: 'Společnosti byly úspěšně nahrány',
    CeasedCompaniesNotMatched: 'Neaktivní společnosti nebo společnosti bez shody nebyly nahrány',
    ErrorsDueWrongData: 'Chyby z důvodu nesprávných údajů',
    Download: 'Stáhnout',
    ListOfCeasedCompanies: 'Seznam neaktivních společností',
    ListOfErrors: 'Seznam chyb',
    Stats: {
      Companies_0: 'Společnost',
      Companies_1: 'Společnosti',
      Companies_2: 'Společností',
      AllCompanies: 'Všechny společnosti',
      EnrichedCompanies_0: 'Obohacená společnost',
      EnrichedCompanies_1: 'Obohacené společnosti',
      EnrichedCompanies_2: 'Obohacených společností',
      Enriched_0: 'Obohacená',
      Enriched_1: 'Obohacené',
      Enriched_2: 'Obohacených',
      NotEnriched_0: 'Neobohacená',
      NotEnriched_1: 'Neobohacené',
      NotEnriched_2: 'Neobohacených',
      Prospect_0: 'Prospekt',
      Prospect_1: 'Prospekty',
      Prospect_2: 'Prospektů',
      Lead_0: 'Lead',
      Lead_1: 'Leadi',
      Lead_2: 'Leadů',
      Client_0: 'Klient',
      Client_1: 'Klienti',
      Client_2: 'Klientů',
      Supplier_0: 'Dodavatel',
      Supplier_1: 'Dodavatelé',
      Supplier_2: 'Dodavatelů',
      Competitor_0: 'Konkurent',
      Competitor_1: 'Konkurenti',
      Competitor_2: 'Konkurentů',
      LastUpdate: 'Poslední aktualizace'
    },
    Enrich: 'Obohatit',
    EnrichTooltip: 'Vylepšete data těchto společností',
    Analyze: 'Analyzovat portfolio',
    AnalyzeTooltip: 'Vizualizujte data portfolia podle hlavních proměnných',
    Similarity: 'Vygenerovat podobnost',
    SimilarityTooltip: 'Chcete-li najít nové potenciální zákazníky, použijte algoritmus podobnosti',
    SelectSource: 'Načíst společnosti ze šablon',
    UpdatePortfolio: 'Aktualizovat portfolio'
  },
  Table: {
    companyName: 'Název společnosti',
    lastUpdateDate: 'Poslední aktualizace',
    companyUnitTypeCode: 'Právní forma společnosti',
    companyStatusCode: 'Status',
    employeeNumber: 'Počet zaměstnanců',
    companyPortfolioStatus: 'Stát',
    sale: 'Sales',
    Sale: 'Sales',
    '[tags]': 'Značky',
    startDate: 'Datum začátku',
    revenue: 'Příjmy',
    turnover: 'Obrat',
    isRating4: 'Hodnocení',
    Empty: 'Nenašli jsme žádné společnosti, které odpovídají vašemu vyhledávání',
    isEnriched: 'Je obohacena'
  },
  SelectColumns: 'Vybrat sloupce',
  LoadMore: 'Načíst více',
  MapDescription: 'Náhled v mapě zobrazuje jednotlivé společnosti bez ohledu na jejich status v portfoliu',
  CompanyGroupActions: {
    Title: 'Skupinové akce',
    Actions: 'Akce',
    AreaManager: 'Area Manažer',
    Sales: 'Sales',
    Products: 'Produkty',
    Select: 'Vybrat',
    NotAssigned: 'Nepřidělený',
    SelectAreaManager: 'Vybrat Area manažera',
    SelectSales: 'Vybrat obchodníka',
    SelectProducts: 'Vybrat produkt',
    ActionItems: {
      DuplicateCompany: 'Duplikovat společnost',
      DeleteCompany: 'Vymazat společnost',
      MoveCompany: 'Přesuňte společnost do jiného portfolia',
      Enrich: 'Obohatit',
      CreatePortfolio: 'Vytvořit portfolio',
      DownloadExcel: 'Stáhnout Excel',
      AddLocalUnits: 'Add Local Units',
      ShareCompanies: 'Sdílet společnosti',
      UnshareCompanies: 'Přestat sdílet společnosti'
    },
    CreatePortfolio: {
      Title: 'Vytvořit portfolio',
      InputDescription: 'Zadejte název portfolia, které vytváříte',
      InsertPortfolioName: 'Vložit název portfolia'
    },
    HiddenCompanyMessage: 'Operace nebude provedena pro společnosti patřící do skrytých portfolií.'
  },
  Actions: {
    ViewCampaigns: 'Zobrazit všechny související kampaně',
    LoadQuery: 'Načíst dotaz',
    LoadQueryTooltip: 'Otevřete uložený dotaz',
    SaveQuery: 'Uložit dotaz',
    SaveQueryTooltip: 'Uložte tento dotaz do modulu Správa portfolia',
    Download: 'Stáhnout portfolio',
    DownloadTooltip: 'Stáhněte si portfolio ve formátu aplikace Excel do svého zařízení',
    Analyze: 'Analyzovat portfolio',
    AnalyzeTooltip: 'Vizualizujte data portfolia podle hlavních proměnných',
    Similarity: 'Vygenerovat podobnost',
    SimilarityTooltip: 'Chcete-li najít nové potenciální zákazníky, použijte algoritmus podobnosti',
    Campaign: 'Vytvořit kampaň',
    CampaignTooltip: 'Přiřaďte společnosti k prodejnímu týmu a naplánujte komerční kampaň',
    Update: 'Aktualizovat portfolio',
    UpdateTooltip: 'Přidejte do tohoto portfolia nové společnosti nebo aktualizujte vlastní proměnné',
    LocalUnits: 'Add Local Units',
    LocalUnitsTooltip: 'Přidejte do tohoto portfolia pobočky připojené k centrále'
  },
  Enriched: 'Obohacené',
  Enrich: 'Obohatit',
  Monitored: 'Monitorované',
  Close: 'Zavřít',
  Filters: 'Filtry',
  UserFilters: 'Uživatelské filtry',
  Download: {
    DownloadPortfolio: 'Stáhnout portfolio',
    SelectTypeOfList: 'Vybrat typ seznamu',
    SelectTypeOfListDescription: 'Vyberte typ seznamu, který chcete stáhnout.',
    DownloadCost: 'Náklady za stažení budou stejné pro každý typ seznamu.',
    Base: 'Základní',
    BaseDescription: 'Seznam bude obsahovat základní soubor údajů týkajících se společností',
    Complete: 'Kompletní',
    CompleteDescription: 'Seznam bude obsahovat kompletní soubor údajů týkajících se společností',
    ExceededMaximumCompanies: 'Překročen maximální počet společností ke stažení',
    Marketing: 'Marketing',
    MarketingDescription: 'Seznam bude obsahovat standardní soubor údajů týkajících se společností',
    Standard: 'Marketing',
    Custom: 'Vlastní',
    CustomDescription:
      'Seznam si můžete přizpůsobit tak, abyste si mohli stáhnout a/nebo uložit pouze sadu dat, která potřebujete',
    CustomTracks: 'Uložené stopy',
    CustomTracksDefaultSelection: 'Není vybrána žádná vlastní stopa',
    SaveCustomTrack: 'Uložit vlastní stopu',
    SaveCustomTrackQuestion: 'Chcete uložit tuto sadu filtrů jako vlastní stopu?',
    SaveCustomTrackTitle: 'Uložit vlastní stopu',
    SaveCustomTrackDescription:
      'Vložte název, který chcete použít k uložení vlastní stopy, nebo vyberte existující a přepište jej.',
    SaveCustomTrackName: 'Vlastní název stopy',
    SaveCustomTrackUpdateExisting: 'Aktualizovat stávající',
    SaveCustomTrackCreateNew: 'Vytvořit novú',
    DownloadExample: 'Stáhnout příklad',
    Download: 'Stáhnout',
    ForEveryCompanyInList: 'pro každou společnost v seznamu',
    ToDownloadYouNeedToPurchase: 'Chcete-li stáhnout seznam, musíte si zakoupit údaje společnosti {{companyName}}',
    YouAreDownloading:
      'Stahujete Excel obsahující všechny požadované údaje společnosti {{companyName}}. Uloží se do sekce "Správa portfolia" ve Vašem účtu.',
    CustomExport: {
      StepTitle: 'Vybrat údaje',
      Description1: 'Vyberte data, která chcete zahrnout do svého stahování',
      Description2: 'Náklady za stažení budou stejné pro každý typ seznamu',
      DataPacket: 'Datový balíček',
      DataPacketSearchPlaceholder: 'Najděte datové pakety nebo konkrétní pole'
    },
    Dialog1: 'Požadovaný soubor se zpracovává, může to trvat několik minut.',
    Dialog2: 'Průběh akce můžete sledovat v sekci Notifikace.',
    SaveSuccess: 'uložen jako vlastní stopa',
    SaveSuccessDescription: 'Stopu najdete uloženou v úložišti souvisejícím s referenční oblastí.'
  },
  Enrichment: {
    PortfolioEnrichment: 'Obohacení společností může trvat několik minut. Pokračovat v operaci?',
    Enrichment: 'Obohacování',
    EnrichmentComplete: 'Obohacení bylo dokončeno',
    EnrichmentDescription:
      'Obohacením společnosti o údaje společnosti {{companyName}} se tyto informace stáhnou a budou pravidelně aktualizovány',
    CompaniesInPortfolio_0: 'Společnost v tomto portfoliu',
    CompaniesInPortfolio_1: 'Společnosti v tomto portfoliu',
    CompaniesInPortfolio_2: 'Společností v tomto portfoliu',
    CompaniesEnriched_0: 'Společnost, která již byla obohacena',
    CompaniesEnriched_1: 'Společnosti, které již byly obohaceny',
    CompaniesEnriched_2: 'Společností, které již byly obohaceny',
    CompaniesNotEnriched_0: 'Neobohacená společnost',
    CompaniesNotEnriched_1: 'Neobohacené společnosti',
    CompaniesNotEnriched_2: 'Neobohacených společností',
    CreditsWillBeUsed_0: 'Použitá jednotka',
    CreditsWillBeUsed_1: 'Použité jednotky',
    CreditsWillBeUsed_2: 'Použitých jednotek',
    CreditsRemaining_0: 'Zbývající jednotka',
    CreditsRemaining_1: 'Zbývající jednotky',
    CreditsRemaining_2: 'Zbývajících jednotek',
    CompaniesSuccesfullyEnriched_0: 'Obohacená společnost',
    CompaniesSuccesfullyEnriched_1: 'Obohacené společnosti',
    CompaniesSuccesfullyEnriched_2: 'Obohacených společností'
  },
  CompanyDeletion: {
    ConfirmDelete: 'Opravdu chcete smazat společnost?',
    DeleteSuccess: 'Společnost byla vymazána. Možná budete muset počkat několik sekund, než se změny projeví.'
  },
  CompaniesMove: {
    ConfirmMove_0:
      'Společnost, kterou jste vybrali, bude odstraněna z aktuálního portfolia a přidána do jiného portfolia. Vyberte prosím jedno portfolio z níže uvedeného seznamu.',
    ConfirmMove_1:
      'Společnosti, které jste vybrali, budou odstraněny z aktuálního portfolia a přidány do jiného portfolia. Vyberte prosím jedno portfolio z níže uvedeného seznamu.',
    ConfirmMove_2:
      'Společnosti, které jste vybrali, budou odstraněny z aktuálního portfolia a přidány do jiného portfolia. Vyberte prosím jedno portfolio z níže uvedeného seznamu.',
    MoveSuccess: 'Probíhá operace přesunu, může to trvat několik minut.',
    SelectPortfolio: 'Vybrat portfolio'
  },
  CompanyAssignment: {
    ConfirmOperation: 'Potvrdit operaci',
    ProductAssignment:
      'Přiřazení produktu {{product}} vybraným společnostem může trvat několik minut. Pokračovat v operaci?',
    SalesAssignment:
      'Přiřazení obchodníka {{sales}} vybraným společnostem může trvat několik minut. Pokračovat v operaci?',
    AreaManagerAssignment:
      'Přiřazení Area manažera {{manager}} k vybraným společnostem může trvat několik minut. Pokračovat v operaci?',
    ProductUnassignment: 'Zrušení přiřazení vybraným společnostem může trvat několik minut. Pokračovat v operaci?',
    SalesUnassignment: 'Zrušení přiřazení vybraným společnostem může trvat několik minut. Pokračovat v operaci?',
    AreaManagerUnassignment: 'Zrušení přiřazení vybraným společnostem může trvat několik minut. Pokračovat v operaci?'
  },
  DialogError: 'Při zpracování požadavku došlo k chybě.',
  Credits: 'Rings',
  LoadQuery: {
    DialogTitle: 'Načíst uložené vyhledávání',
    SaveBefore: {
      Question: 'Chcete před načtením vyhledávání, uložit svoje vyhledávání?',
      Description: 'Před načtením dotazu uložte svoje vyhledávání, aby nedošlo ke ztrátě dat.',
      OkBtn: 'Uložit vyhledávání',
      CancelBtn: 'Přeskočit uložení'
    }
  },
  SaveQuery: {
    DialogTitle: 'Uložit vyhledávání',
    Description:
      'Zadejte název, který chcete použít k uložení výzkumu, nebo vyberte existující dotaz, který chcete přepsat.',
    Fields: {
      QueryName: 'Název dotazu',
      QueryNamePlaceholder: 'Název vyhledávání',
      AddToDashboard: 'Ukázat v dashboardu'
    },
    ConfirmMessage: 'Chcete přidat dotaz na váš dashboard?',
    SavedQuery: 'uloženo správně',
    SavedQueryAndDashboard: 'uloženo a přidáno do dashboardu',
    SavedQueryDescription: 'Své dotazy najdete v úložišti podle referenční oblasti'
  },
  DomainFilter: {
    SaveAndClose: 'Uložit filtry a zavřít'
  },
  ManageDuplicates: {
    Title: 'Spravovat duplikáty',
    Description: 'K duplikování společnosti je třeba změnit její status nebo produkt',
    GroupActions: 'Skupinové akce',
    Duplicates_0: '{{Count}} duplikát',
    Duplicates_1: '{{Count}} duplikáty',
    Duplicates_2: '{{Count}} duplikátů',
    ShowOK: 'Zobrazit OK',
    HideOK: 'Skrýt OK',
    SelectColumns: 'Vybrat sloupce'
  },
  GenerateSimilarity: {
    Title: 'Vygenerovat podobnost: vyberte reprezentativní status',
    Description: 'Pro zahájení analýzy musíte zvolit typ statusu, který chcete zpracovat',
    DropdownPlaceholder: 'Vybrat status',
    GotoActionButton: 'vybrat'
  },
  Campaign: {
    YouNeedToEnrich: 'Pro spuštění kampaně je třeba obohatit všechny společnosti v portfoliu.'
  },
  CreatingPortfolio: 'Vytváření portfolia',
  UpdatingPortfolio: 'Aktualizace portfolia',
  Share: {
    SharePortfolioTitle: 'Sdílet portfolio',
    Subtitle: 'Vybrat uživatele',
    Description: 'Vyberte uživatele, se kterými chcete sdílet portfolio',
    Success: 'Aktualizace sdílení byla dokončena.',
    ShareCompany: 'Sdílet společnost',
    DeleteSharing: 'Odstranit sdílení',
    SharedCompanies: 'Sdílené společnosti',
    SharedPortfolio: 'Sdílené portfolio',
    SharePortfolio: 'Sdílení portfolia',
    ShareCompanies: {
      Title: 'Sdílet společnosti',
      Subtitle: 'Vybrat uživatele',
      Description: 'Vyberte uživatele, se kterými chcete sdílet společnosti',
      WithAreaManager: 'Sdílet společnosti s Area manažerem',
      WithSales: 'Sdílet společností s obchodníkem',
      ConfirmTitle: 'Potvrdit operaci',
      ConfirmSubtitle: 'Aktualizování sdílení s vybraným uživatelem.',
      ConfirmDescription: 'Může to trvat několik minut. Pokračovat v operaci?',
      Check: 'Kontrola, zda jsou všechny společnosti validní pro sdílení.',
      CheckFail: 'Musíte přiřadit vybrané společnosti.'
    },
    UnshareCompanies: {
      Title: 'Přestat sdílet společnosti',
      Subtitle: 'Vybrat uživatele',
      Description: 'Vyberte uživatele, se kterými chcete ukončit sdílení',
      WithAreaManager: 'Přestat sdílet společnosti s Area manažerem',
      WithSales: 'Přestat sdílet společnosti s obchodníkem',
      ConfirmTitle: 'Potvrdit operaci',
      ConfirmSubtitle: 'Aktualizuje se ukončení sdílení s vybraným uživatelem',
      ConfirmDescription: 'Může to trvat několik minut. Pokračovat v operaci?',
      Check: 'Kontrola, zda jsou všechny společnosti validní pro ukončení sdílení.',
      CheckFail: 'Musíte přiřadit vybrané společnosti.'
    }
  },
  Exclude: {
    Title: 'Vyloučit portfolio',
    ExcludedPortfolio: 'Skryté portfolio',
    Description: 'Vyberte uživatele, kteří obdrží vybrané portfolio ve vyloučení',
    Success: 'Sdílení výjimek proběhlo správně.',
    Error: 'Je nám líto, ale toto portfolio jsme nemohli sdílet s vyloučením.'
  },
  AddLocalUnits: {
    Title: 'Add Local Units',
    TooManyCompanies: 'Resize the portfolio to {{maxCompanies}} companies maximum in order to use this functionality.',
    NoCompanySelected: 'No company selected. Please add at least one Headquarter.',
    NoHeadquarter: 'The selected portfolio already contains Local Units. Please add at least one Headquarter.',
    NoHeadquarterSelected: 'The selected companies are already Local Units. Please add at least one Headquarter.',
    Enrich: {
      Title: 'To add local units you need to purchase {{companyName}} company data',
      Description:
        'You are adding local units to a portfolio containing all the {{companyName}} data requested. It will be saved in the "Use" section of your account.'
    },
    ChooseBranch: {
      Title: 'Choose the branch you want to add.',
      Description: 'Select the branch type to be added.',
      All: 'All the Branch types',
      DeleteAll: 'Delete all'
    },
    ChooseStatus: {
      Title: 'Choose the status you want to add.',
      Description: 'Select the initial status of the companies to be added.'
    },
    CreateTag: {
      Title: 'Create tag.',
      Description: 'Each company will be defined by a tag that you create.',
      Label: 'Create tag (optional)',
      Placeholder: 'Insert tag'
    }
  },
  EditPortfolioCompany: {
    Title: 'Upravit hodnoty polečnosti',
    Subtitle: 'Změnit hodnoty',
    Description:
      'Vyberte, která pole chcete upravit, a vložte novou hodnotu do pole. V případě duplicitních společností se status společnosti a produkt neaktualizují.',
    CompanyStatus: 'Subjekt v portofliu',
    Amount: 'Množství',
    AreaManager: 'Area Manager',
    Sales: 'Obchodník',
    Product: 'Produkt',
    Tags: 'Značky',
    Save: 'Uložit',
    Error: 'Chyba',
    ErrorDescription: 'Data se nepodařilo načíst. Prosím zkuste to znovu později.',
    SaveSuccessTitle: 'Změny uloženy',
    SaveSuccessText: 'Změny byly uloženy správně.',
    SaveErrorTitle: 'Chyba',
    SaveErrorText: 'Při ukládání změn došlo k chybě. Prosím zkuste to znovu později.'
  }
}
