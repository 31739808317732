import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../../types'
import { PortfolioCompany, PortfolioCompanySelectionItem } from '../types'
import { ConcreteReducer } from './actions'
import { TableDataState, WithIdTableState } from './reducer'
import { getPortfolioCompanies, getPortfolioCompaniesOrder } from '../selectors'
import { formatPortfolioCompanyToPortfolioSelectionItem } from '../mappers'
import { PortfolioCompanyTableData } from '../../../components/Table/PortfolioCompanyTable'
import { paramSelector } from '../../../utils/selectors'
import { PortfolioStats } from '../../../api/portfolio/types'

type WithReducer = { reducer: ConcreteReducer }
type WithDataIndex = { dataIndex: keyof PortfolioCompany }
type WithPortfolioIdOptional = { portfolioId?: string }

const selectStateByReducer = (state: RootState, { reducer }: WithReducer): WithIdTableState | undefined =>
  _.get(state, reducer)

const selectState = createSelector(
  selectStateByReducer,
  paramSelector<WithPortfolioIdOptional>(),
  (state, { portfolioId }): TableDataState | undefined => {
    // @ts-ignore
    return _.isEmpty(portfolioId) ? state : _.get(state, ['portfolioCompany', portfolioId!])
  }
)

export const getCompanyIds = createSelector(selectState, (state): string[] => state?.companyIds || [])

export const getCompanies = createSelector(
  getPortfolioCompanies,
  getCompanyIds,
  (portfolioCompanies, ids): Record<string, PortfolioCompany> => _.pick(portfolioCompanies, ids)
)

export const getTableRows = createSelector(getCompanies, getPortfolioCompaniesOrder, (companies, companiesOrder) => {
  const orderedCompanies = _.map(
    _.filter(companiesOrder, entityId => _.has(companies, entityId)),
    entityId => _.get(companies, entityId)
  )
  return _.map<PortfolioCompany, PortfolioCompanyTableData>(orderedCompanies, ({ fields, ...rest }) => ({
    ...rest,
    ...fields
  }))
})

export const getRowKey = createSelector(selectStateByReducer, (state): string => state?.rowKey || '')

export const areAllSelected = createSelector(selectState, state => (state ? state.allSelected : false))

export const getSelectedRowKeys = createSelector(selectState, (state): string[] => state?.selectedRowKeys || [])

export const getDeselectedRowKeys = createSelector(
  getRowKey,
  getTableRows,
  getSelectedRowKeys,
  (rowKey, tableRows, selectedRowKeys): string[] => {
    const rowKeys = _.map(tableRows, row => row[rowKey])
    return _.reject(rowKeys, key => _.includes(selectedRowKeys, key))
  }
)

export const getSelectedHeadquartersCount = createSelector(
  getRowKey,
  getCompanies,
  areAllSelected,
  getSelectedRowKeys,
  getDeselectedRowKeys,
  paramSelector<{ statistics: PortfolioStats }>(),
  // eslint-disable-next-line max-params
  (rowKey, companies, isSelectAll, selected, deselected, statistics) => {
    if (_.isNil(statistics)) return 0
    if (isSelectAll) {
      const deselectedCount = _.reduce(
        companies,
        (acc: number, curr) => (curr.isHeadquarter && _.includes(deselected, _.get(curr, rowKey)) ? acc + 1 : acc),
        0
      )
      return statistics.statistics.headquarter - deselectedCount
    }
    return _.reduce(
      companies,
      (acc: number, curr) => (curr.isHeadquarter && _.includes(selected, _.get(curr, rowKey)) ? acc + 1 : acc),
      0
    )
  }
)

const formatCompanySelectionItems = (
  selectedKeys: string[],
  companies: Record<string, PortfolioCompany>,
  { dataIndex }: WithDataIndex
) =>
  _.reduce(
    companies,
    (acc: PortfolioCompanySelectionItem[], row) => {
      if (row[dataIndex] && _.includes(selectedKeys, row[dataIndex])) {
        return [...acc, formatPortfolioCompanyToPortfolioSelectionItem(row)]
      }
      return acc
    },
    []
  )

export const getSelectedRowItems = createSelector(
  getSelectedRowKeys,
  getCompanies,
  paramSelector<WithDataIndex>(),
  formatCompanySelectionItems
)

export const getDeselectedRowItems = createSelector(
  getDeselectedRowKeys,
  getCompanies,
  paramSelector<WithDataIndex>(),
  formatCompanySelectionItems
)
