/* eslint-disable import/no-default-export */
export default {
  DashboardTitle: 'Váš Dashboard',
  DashboardSubtitle: 'Pomocí těchto polí uvidíte všechna svá data na první pohled!',
  OrganizeBox: 'Uspořádat pole',
  ChangeBox: 'Změnit',
  RemoveBox: 'Vymazat pole',
  ChooseWhatDisplay: 'Vyberte, co se má zobrazit v tomto poli',
  PortfolioTitle: 'Společnosti',
  PortfolioSubtitle: 'Stručný přehled vašich společností',
  PortfolioAndCampaignTitle: 'Společnosti a kampaně',
  PortfolioAndCampaignSubtitle: 'Stručný přehled vašich společností a kampaní',
  AnalysisTitle: 'Portfolio dotazy, analýzy a výzkumy',
  AnalysisSubtitle: 'Náhled uložených dotazů, analýz a výzkumů portfolia',
  SuggestedProspects: 'Navrhovaný prospekt',
  CreatedOn: 'Datum vytvoření {{date}}',
  EmptyWidget: {
    SelectPortfolio: {
      BoxTitlePortfolio: 'Vyberte společnosti',
      BoxTitlePortfolioAndCampaign: 'Vyberte společnosti a kampaně',
      Title: 'Portfolio',
      Description: 'Vyberte portfolio z nabídky "Správa portfolia"',
      SelectLabel: 'Vybrat portfolio',
      SelectPlaceholder: 'Kliknutím vyberete portfolio',
      Button: 'Vybrat',
      Banner: {
        Message: 'Pokud si nechcete vybrat portfolio, můžete si jej vytvořit v části "Správa portfolia"',
        Button: 'Vytvořit'
      }
    },
    SelectCampaign: {
      BoxTitle: 'Vyberte společnosti a kampaně',
      Title: 'Kampaně',
      Description: 'Vyberte kampaň z nabídky "Obchodní aktivity"',
      SelectLabel: 'Vybrat kampaň',
      SelectPlaceholder: 'Kliknutím vyberte kampaň',
      Button: 'Vybrat',
      Banner: {
        Message: 'Pokud nechcete zvolit kampaň, můžete ji vytvořit v části "Obchodní aktivity"',
        Button: 'Vytvořit'
      }
    },
    SelectProspectingAnalysis: {
      BoxTitle: 'Vybrat portfoliové dotazy, analýzy a výzkumy',
      Title: 'Vyhledávání',
      Description: 'Vyberte uložené vyhledávání',
      SelectLabel: 'Vybrat vyhledávání',
      SelectPlaceholder: 'Kliknutím vyberete vyhledávání',
      SelectPlaceholderNoQuery: 'Žádný uložený dotaz',
      Button: 'Vybrat',
      Banner: {
        Message: 'Pokud nechcete vybrat vyhledávání, můžete vytvořit nové',
        Button: 'Vytvořit'
      }
    },
    SelectTargetingAnalysis: {
      BoxTitle: 'Vybrat portfoliové dotazy, analýzy a výzkumy',
      Title: 'Analýza',
      Description: 'Vyberte uloženou analýzu',
      SelectLabel: 'Vybrat analýzu',
      SelectPlaceholder: 'Kliknutím vyberete analýzu',
      SelectPlaceholderNoQuery: 'Žádný uložený dotaz',
      Button: 'Vybrat',
      Banner: {
        Message: 'Pokud nechcete vybrat analýzu, můžete vytvořit novou',
        Button: 'Vytvořit'
      }
    },
    SelectPortfolioAnalysis: {
      BoxTitle: 'Vybrat portfoliové dotazy, analýzy a výzkumy',
      Title: 'Portfoliový dotaz',
      Description: 'Vybrat dotaz uloženého portfolia',
      SelectLabel: 'Vybrat dotaz na portfolio',
      SelectPlaceholder: 'Kliknutím vyberte dotaz portfolia',
      SelectPlaceholderNoQuery: 'Žádný uložený dotaz',
      Button: 'Vybrat',
      Banner: {
        Message: 'Pokud nechcete vybrat dotaz portfolia, můžete vytvořit nový',
        Button: 'Vytvořit'
      }
    },
    UploadingPortfolio: {
      BoxTitle: 'Nahrávání portfolia',
      Title: 'Nahrávání portfolia',
      Description: 'V tomto poli uvidíte seznam náhledů',
      BannerMessage: 'Kliknutím na ikonu vpravo nahoře můžete obsah kdykoliv změnit.'
    }
  },
  PortfolioWidget: {
    NoData: 'Údaje neexistují.',
    NumberOfCompanies_0: '{{companiesCount}} Společnost',
    NumberOfCompanies_1: '{{companiesCount}} Společnosti',
    NumberOfCompanies_2: '{{companiesCount}} Společností',
    Monitored: 'Monitorované',
    Actions: 'Akce',
    Enrich: 'Obohatit',
    EnrichTooltip: 'Vylepšete data těchto společností',
    Analyze: 'Analyzovat',
    AnalyzeTooltip: 'Vizualizujte data portfolia podle hlavních proměnných',
    Similarity: 'Podobnost',
    SimilarityTooltip: 'Chcete-li najít nové potenciální zákazníky, použijte algoritmus podobnosti'
  },
  ProspectingWidget: {
    NoData: 'Údaje neexistují.',
    NumberOfCompanies_0: '{{companiesCount}} Společnost',
    NumberOfCompanies_1: '{{companiesCount}} Společnosti',
    NumberOfCompanies_2: '{{companiesCount}} Společností',
    Monitored: 'Monitorován',
    MoreFilters_0: '{{filtersCount}} další filtr',
    MoreFilters_1: '{{filtersCount}} další filtry',
    MoreFilters_2: '{{filtersCount}} dalších filtrů',
    ViewDetailsTooltip: 'Podívejte se na filtry použité v tomto seznamu kandidátů'
  },
  TargetingWidget: {
    NoData: 'Údaje neexistují.',
    Companies_0: 'Společnost',
    Companies_1: 'Společnosti',
    Companies_2: 'Společností'
  },
  CampaignWidget: {
    NoData: 'Údaje neexistují.',
    NumberOfCompanies_0: '{{companiesCount}} Společnost',
    NumberOfCompanies_1: '{{companiesCount}} Společnosti',
    NumberOfCompanies_2: '{{companiesCount}} Společností'
  },
  AppointmentsWidget: {
    Title: 'Vaše schůzky',
    NoAppointments: 'Nemáte žádné schůzky',
    MoreEvents_0: '{{eventsCount}} další schůzka',
    MoreEvents_1: '{{eventsCount}} další schůzky',
    MoreEvents_2: '{{eventsCount}} dalších schůzek',
    Today: 'Dnes',
    Tomorrow: 'Zítra'
  },
  LatestDocuments: {
    Title: 'Poslední dokumenty',
    NoDocuments:
      'V tomto poli uvidíte všechny své naposledy stažené soubory. Vaše dokumenty budou v úložišti ve vašem profilu.',
    Info: 'Tabulka zobrazuje nejnovější stažené dokumenty',
    Table: {
      Name: 'Název',
      Type: 'Typ',
      CreationDate: 'Datum vytvoření',
      ExpirationDate: 'Datum expirace',
      DownloadExcel: 'Excel na stáhnutí',
      Campaign: 'Kampaň',
      Report: 'Report'
    }
  },
  PortfolioQueryWidget: {
    MoreFilters_0: '{{filtersCount}} další filtr',
    MoreFilters_1: '{{filtersCount}} další filtry',
    MoreFilters_2: '{{filtersCount}} dalších filtrů',
    ViewDetailsTooltip: 'Podívejte se na filtry použité v tomto portfoliu'
  }
}
