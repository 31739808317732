/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Il tuo account',
  Navigation: {
    Account: 'Account',
    SavedReport: 'Report salvati',
    PortfolioManagement: 'Portafoglio Imprese',
    Targeting: 'Analizza Mercato',
    Prospecting: 'Trova Clienti',
    Operations: 'Operations',
    SalesTool: 'Sales Tool',
    Products: 'Prodotti',
    GoalsTasks: 'Obiettivo e Attività',
    ReleaseNotes: 'Ultime Novità',
    Configuration: 'Configurazione',
    Appointments: 'Appuntamenti'
  },
  Goal: {
    AddGoal: 'Aggiungi un obiettivo',
    AddGoalTooltip: 'Crea un goal da scegliere nelle campagne commerciali',
    EditGoal: {
      Title: "Modifica l'obiettivo",
      Body: "Inserisci un nome rappresentativo per l'obiettivo",
      NameInput: "Nome dell'obiettivo",
      Success: 'Obiettivo modificato correttamente.',
      Error: 'Si è verificato un errore durante la modifica.'
    },
    DeleteGoal: {
      Title: "Elimina l'obiettivo",
      Subtitle: 'Sei sicuro di voler eliminare {{goalName}}?',
      Body: "L'azione verrà eseguita solo se l'obiettivo non è stato assegnato a nessuna campagna attiva; in caso contrario, l'azione sarà negata.",
      Success: 'Obiettivo eliminato correttamente.',
      Error: "Si è verificato un errore durante l'eliminazione.",
      ErrorAssignedToActiveCampaign:
        "Non è possibile eliminare l'obiettivo selezionato, poiché risulta assegnato ad una campagna attiva.",
      ErrorActiveActionsForSameOrganization:
        "Non è stato possibile completare l'operazione in quanto altre azioni sono in corso. Riprova fra qualche minuto."
    },
    EditTask: {
      Title: "Modifica l'attività",
      Body: "Inserisci un nome rappresentativo per l'attività",
      NameInput: "Nome dell'attività",
      Success: 'Attività modificata correttamente',
      Error: 'Si è verificato un errore durante la modifica'
    },
    DeleteTask: {
      Title: "Elimina l'attività",
      Subtitle: 'Sei sicuro di voler eliminare {{taskName}}?',
      Body: "L'azione verrà eseguita solo se l'obiettivo non è stato assegnato a nessuna campagna attiva; in caso contrario, l'azione sarà negata.",
      Success: 'Attività eliminata correttamente',
      Error: "Si è verificato un errore durante l'eliminazione",
      ErrorAssignedToActiveCampaign:
        "Non è possibile eliminare l'attività selezionata, poiché ci sono aziende in campagne attive a cui è stata assegnata l'attività menzionata. Per procedere con l'eliminazione, rimuovere l'assegnazione all'interno della campagna e riprovare a rimuovere l'attività.",
      ErrorActiveActionsForSameOrganization:
        "Non è stato possibile completare l'operazione in quanto altre azioni sono in corso. Riprova fra qualche minuto."
    },
    CreateGoal: "Crea l'obiettivo",
    NameDesc: "Inserisci un nome rappresentativo dell'obiettivo che stai creando.",
    GoalNamePlaceholder: "Inserisci il nome dell'obiettivo",
    GoalNameLabel: "Nome dell'Obiettivo",
    TasksHeader: 'Scegli le attività per raggiungere il tuo obiettivo',
    TaskDesc:
      'Inserisci tutte le attività necessarie per raggiungere questo obiettivo (min 3, max 10). I numeri più bassi identificano un basso livello di completamento del goal',
    TaskPlaceholder: "Inserisci il nome dell'attività",
    TaskNumber: 'Attività numero',
    LastTaskDesc: "* Questa attività sarà considerata l'ultima della campagna",
    AddTask: 'Aggiungi attività',
    Number: 'Numero',
    Task: 'Obiettivo',
    Desc1: "Il numero vicino all'attività identifica il livello di completamento dell'obiettivo.",
    Desc2: "Numeri più bassi identificano un basso tasso di completamento dell'obiettivo.",
    Table: {
      Name: 'Nome',
      NumberOfTasks: 'N° di attività'
    },
    EmptyText: 'In questa sezione, potrai trovare tutti gli obiettivi e le attività salvate.',
    Visualize: 'Visualizza {{goal}}'
  },
  Queries: {
    Table: {
      Name: 'Ricerca salvata',
      CreatedAt: 'Data salvataggio',
      LastUseDate: 'Data di ultimo utilizzo',
      Monitored: 'Monitorata',
      ResumeQuery: 'Riprendi query',
      Delete: 'Elimina',
      ResumePortfolioQueryTooltip:
        'Accedi al modulo di "Portafoglio Imprese" con i filtri preselezionati da {{queryName}}',
      ResumeProspectingQueryTooltip: 'Accedi al modulo di "Trova Clienti" con i filtri preselezionati da {{queryName}}',
      ResumeTargetingQueryTooltip: 'Accedi al modulo di "Analizza Mercato" con i filtri preselezionati da {{queryName}}'
    },
    EmptyProspectingText: 'In questa sezione troverai tutti la query salvati.',
    EmptyTargetingText: 'In questa sezione troverai tutti la query salvati.',
    DeleteDialog: {
      Title: 'Cancella query',
      Title_plural: 'Cancella ricerche',
      Subtitle: "Conferma per procedere con l'operazione.",
      Body: 'Tutti i dati relativi alla query verranno eliminati.',
      Body_plural: 'Tutti i dati relativi alle ricerche verranno eliminati.',
      Monitored: 'In caso la query sia monitorata il portafoglio relativo non verrà più aggiornato',
      Success: 'Il query è stato cancellato con successo.',
      Success_plural: 'Le ricerche sono state eliminate correttamente.',
      Error: "Si è verificato un errore durante l'eliminazione del query.",
      Error_plural: "Si è verificato un errore durante l'eliminazione dei queries.",
      MonitoredQueryError:
        'Alcune delle ricerche selezionate non possono essere eliminate poiché monitorate. Per eliminare tali ricerche, cancellare i relativi portafogli monitorati.'
    },
    ResumePortfolioQueryDialog: {
      Title: 'Scegli il portafoglio su cui applicare la query',
      Text: 'Scegli un portafoglio fra quelli a disposizione.'
    },
    Actions: {
      EmptyDeleteSelectionTitle: 'Elimina queries',
      EmptySelectionText: 'Devi selezionare almeno un query',
      Delete: 'Elimina'
    }
  },
  Products: {
    Table: {
      Name: 'Prodotti',
      CreatedAt: 'Data di inserimento'
    },
    AddProduct: 'Crea il prodotto',
    AddProductTooltip: 'Crea un prodotto da assegnare nelle campagne commerciali',
    EditProduct: {
      Title: 'Modifica il nome del prodotto',
      Body: 'Inserisci un nome rappresentativo per il prodotto',
      NameInputLabel: 'Nome del prodotto',
      NameInputPlaceholder: 'Nome del prodotto',
      Success: 'Prodotto modificato correttamente.',
      Error: 'Si è verificato un errore durante la modifica.'
    },
    DeleteProduct: {
      Title: 'Elimina il prodotto',
      Subtitle: 'Sei sicuro di voler eliminare {{productName}}?',
      Body: "L'azione verrà eseguita solo se il prodotto non è stato assegnato a nessuna campagna attiva e/o portafoglio; in caso contrario, l'azione sarà negata.",
      Success: 'Prodotto eliminato correttamente.',
      Error: "Si è verificato un errore durante l'eliminazione.",
      ErrorAssignedToActiveCampaign:
        'Non è possibile eliminare il prodotto selezionato, poiché risulta assegnato ad una campagna attiva e/o a un portafoglio.',
      ErrorActiveActionsForSameOrganization:
        "Non è stato possibile completare l'operazione in quanto altre azioni sono in corso. Riprova fra qualche minuto."
    },
    EmptyText: 'Hin questa sezione troverai tutti i prodotti salvati.',
    CreateProduct: 'Crea il prodotto',
    NameDesc: "Inserisci un nome rappresentativo dell'prodotto che stai creando.",
    ProductNamePlaceholder: 'Inserisci il nome del prodotto',
    ProductNameLabel: 'Nome del prodotto'
  },
  PortfolioManagement: {
    EmptyText: 'Qui troverai tutti i documenti salvati nella sezione Portafoglio Imprese',
    Documents: 'Documenti',
    DocumentsTable: {
      Name: 'Documento',
      CreatedAt: 'Data salvata',
      MimeType: 'Estensione'
    },
    Queries: 'Ricerche',
    DeleteDialog: {
      Body: 'Vuoi cancellare il documento?',
      Body_plural: 'Vuoi eliminare i documenti?',
      Title: 'Elimina documento',
      Title_plural: 'Delete documenti',
      Subtitle: "Conferma per procedere con l'operazione.",
      Success: 'Il documento è stato cancellato con successo.',
      Success_plural: 'I documenti sono stati eliminati correttamente.',
      Error: "Si è verificato un errore durante l'eliminazione del documento.",
      Error_plural: "Si è verificato un errore durante l'eliminazione dei documenti."
    },
    Actions: {
      EmptySelectionText: 'Devi selezionare almeno un documento',
      EmptyDeleteSelectionTitle: 'Elimina documenti',
      Delete: 'Elimina',
      Download: 'Scarica',
      ShareTrack: 'Condividi tracciato'
    },
    CustomTracks: {
      Title: 'Tracciati Custom',
      EditCustomTrack: 'Modifica Tracciato Custom',
      EditSuccessMessage: 'Tracciato custom modificato con successo.',
      EditErrorMessage: 'Si è verificato un errore durante la modifica del tracciato custom.',
      VisualiseCustomTrack: 'Visualizza Tracciato Custom',
      Table: {
        TrackName: 'Nome tracciato',
        SaveDate: 'Data salvataggio',
        LastUseDate: 'Data di ultimo utilizzo',
        OwnerFullName: 'Nome creatore',
        ModuleOfOrigin: 'Modulo di provenienza'
      },
      DeleteDialog: {
        Body: 'Sei sicuro di voler eliminare il tracciato custom?',
        Body_plural: 'Sei sicuro di voler eliminare i tracciati custom?',
        Title: 'Elimina il tracciato custom',
        Title_plural: 'Elimina i tracciati custom',
        Subtitle: "Conferma per procedere con l'operazione.",
        Success: 'Il tracciato custom è stato cancellato con successo.',
        Success_plural: 'I tracciati custom sono stati cancellati con successo.',
        Error: 'Si è verificato un errore durante la cancellazione del tracciato custom.',
        Error_plural: 'Si è verificato un errore durante la cancellazione dei tracciati custom.'
      },
      RenameDialog: {
        Title: 'Modifica il nome del tracciato custom',
        Body: 'Inserisci un nome rappresentativo per il tracciato custom',
        NameInput: 'Nome tracciato custom',
        Success: 'Tracciato custom modificato correttamente.',
        Error: 'Si è verificato un errore durante la modifica al nome del tracciato custom.'
      },
      EmptyDialog: {
        Title: 'Elimina il tracciato custom',
        Text: 'Per proseguire è necessario selezionare almeno un tracciato custom.'
      },
      ShareCustomTrackErrorDialog: {
        Title: 'Condivisione tracciato custom',
        Text: 'Si è verificato un errore durante la condivisione del tracciato custom.'
      }
    }
  },
  Operations: {
    EmptyText: 'In questa sezione troverai tutti la query salvati.',
    Table: {
      ResumePortfolioQueryTooltip: 'Accedi al modulo di "Operations" con i filtri preselezionati da {{queryName}}'
    }
  },
  Info: {
    User: 'Utente',
    LoginAs: 'Login per conto di un altro utente',
    Level: 'Ruolo',
    Office: 'Ufficio',
    Subscription: 'Abbonamento'
  },
  Account: {
    Password: {
      Password: 'Password',
      Current: 'Attuale',
      ChangePasswordTitle: 'Modifica password',
      ChangePassword: 'Cambia password',
      EnterPassword: 'Inserisci una nuova password',
      OldPassword: 'Vecchia password',
      NewPassword: 'Nuova password',
      ConfirmPassword: 'Conferma password'
    },
    Language: {
      Language: 'Lingua',
      Current: 'Attuale',
      ChangeLanguageTitle: 'Scegli lingua',
      ChangeLanguage: 'Scegli lingua per i tuoi contenuti'
    },
    Currency: {
      Title: 'Valuta',
      Current: 'Corrente',
      ChangeCurrencyTitle: 'Cambio valuta',
      ChangeCurrency: 'Cambiare la valuta del contenuto'
    },
    Subscription: {
      Title: 'Il mio abbonamento (rings)',
      Available: 'Disponibile',
      AvailableTooltip: "Ring residui dell'abbonamento spendibili sulla tua utenza",
      Used: 'Usato',
      UsedTooltip: 'Ring spesi dalla tua utenza',
      Total: 'Totale',
      TotalTooltip: 'Ring acquistati per la tua utenza',
      Validity: 'Validità',
      ValidityInfo: 'Da {{from}} a {{to}}',
      CreditInfoWithoutCompanyName: " per aggiornare l'abbonamento o acquistare più rings",
      Error: 'Non è stato possibile caricare i limiti di consumo.'
    },
    Contact: {
      Title: 'Contatti',
      User: 'Utente',
      Email: 'Email',
      UpdateInfoWithoutCompanyName: ' per cambiare i tuoi contatti'
    }
  },
  TargetingTab: {
    SavedQuery: 'Saved query',
    DownloadedDocuments: 'Downloaded documents'
  },
  QueryDetail: {
    VisualiseQuery: 'Visualizza query',
    Monitored: 'Monitorata'
  },
  ReleaseNotes: {
    Title: 'Ultime Novità',
    NewNote: 'Nuova nota di rilascio',
    NoteTitle: 'Titolo',
    NoteDescription: 'Breve descrizione',
    NoteContent: 'Contenuto',
    WhatsNew: 'Ultime novità',
    GoToNewsPage: "Vai alla pagine Ultime Novità per scoprire cos'altro c'è di nuovo"
  },
  Configuration: {
    ConfigureMargoTitle: 'Configura Margò',
    CreateOfficeConfiguration: 'Crea configurazione ufficio',
    CreateUserConfiguration: 'Crea configurazione utente',
    SearchOfficePlaceholder: 'Cerca per nome Ufficio',
    SearchUserPlaceholder: 'Cerca per nome Utente',
    EmptyText: 'In questa sezione troverai le configurazioni salvate.',
    ViewConfigurationTitle: 'Configurazione per',
    Actions: {
      Delete: 'Elimina',
      VisualiseData: 'Visualise Data',
      Edit: 'Edit'
    },
    AddConfiguration: {
      OfficeTitle: 'Seleziona uno o più uffici',
      OfficeDescription:
        "Scegli uno o più uffici per i quali intendi creare una configurazione.\nStai creando una configurazione per l'intero ufficio, che viene applicata a tutti gli utenti presenti e futuri. Puoi modificare la configurazione in ogni momento.",
      UsersTitle: 'Seleziona uno o più utenti',
      UsersDescription:
        "Scegli uno o più utenti per i quali intendi creare una configurazione.\nSe l'utente appartiene ad un ufficio già configurato, la configurazione utente sovrascriverà quella dell'ufficio.",
      ParametersTitle: 'Seleziona i parametri',
      ParametersDescription:
        "Seleziona i parametri per cui verranno filtrate le aziende.\nSe hai selezionato un utente appartenente a un ufficio configurato, di seguito l'elenco presenta i filtri applicati all'ufficio.\nPuoi sovrascrivere la configurazione ufficio solo per gli utenti selezionati, modificando o eliminando tutti i filtri.",
      FullMargoLabel: 'Disabilita tutti i filtri e mostra una Margò completa',
      ConfigurationSuccessful: 'Configurazione avvenuta con successo',
      ConfigurationSuccessfulDescription: 'Puoi modificare in ogni momento la configurazione dal tuo account'
    },
    DeleteConfiguration: {
      Title: 'Elimina configurazione',
      Subtitle: 'Sei sicuro di voler eliminare la configurazione?',
      Body: "La visibilità dell'utente non sarà più limitata ai parametri selezionati durante la configurazione.",
      Success: 'Configurazione eliminata',
      Error: 'There was error while deleting configuration.'
    },
    Table: {
      UserName: 'Nome Utente',
      OfficeName: 'Nome Ufficio',
      Creator: 'Creatore della configurazione',
      UpdatedAt: 'Data ultimo aggiornamento'
    },
    Offices: 'Uffici',
    Users: 'Utenti',
    UserTabTitle: 'Utente',
    OfficeTabTitle: 'Ufficio',
    AppliedFilterTabTitle: 'Filtri applicati',
    AlreadyConfigured: 'Già configurato',
    FullMargoLabelForView: "Nessun filtro applicato: l'utente visualizza la Margò completa"
  }
}
