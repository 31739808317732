import React from 'react'
import { useTranslation } from 'react-i18next'
import { UseControllerProps, UseControllerReturn, UseFormWatch } from 'react-hook-form'
import styled from 'styled-components/macro'
import { getPasswordsMustMatchValidator, getPasswordsMustNotMatchValidator, required } from '../../../utils/validators'
import { InputField } from '../../../components/Form'
import { Text } from '../../../components/Text'
import { Stack } from '../../../components/Stack'
import { InfoTooltip } from '../../../components/Info'
import { useGetPasswordRules } from '../../Login/LoginForm/utils'

const TextStyled = styled(Text)`
  flex-grow: 1;
`

export type FormState = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

type Props = {
  watch: UseFormWatch<FormState>
  control: UseControllerProps<FormState>['control']
  formState: UseControllerReturn['formState']
  validationError?: string
}

export const ChangePasswordDialogForm = ({ control, formState, watch, validationError }: Props) => {
  const { t, i18n } = useTranslation(['account', 'login'])

  const passwordRules = useGetPasswordRules(t, i18n)

  return (
    <>
      <Stack orientation="horizontal">
        <TextStyled size="M" block lineHeight={1.5}>
          {t('login:PasswordExpiration90Days')}
        </TextStyled>
        <InfoTooltip className="info" placement="left">
          {passwordRules}
        </InfoTooltip>
      </Stack>
      <InputField
        password
        autoComplete="new-password"
        name="oldPassword"
        label={t('Account.Password.OldPassword')}
        control={control}
        formState={formState}
        validators={{ required }}
      />
      <InputField
        password
        autoComplete="new-password"
        name="newPassword"
        label={t('Account.Password.NewPassword')}
        control={control}
        formState={formState}
        validators={{ required, areEqual: getPasswordsMustNotMatchValidator(watch('oldPassword')) }}
      />
      <InputField
        password
        autoComplete="new-password"
        name="confirmPassword"
        label={t('Account.Password.ConfirmPassword')}
        control={control}
        formState={formState}
        validators={{ required, areEqual: getPasswordsMustMatchValidator(watch('newPassword')) }}
      />
      {validationError && <Text type="danger">{validationError}</Text>}
    </>
  )
}
