import { useLeafletContext } from '@react-leaflet/core'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { useLoadCompanyPopupData } from '../../api/companyPopup'
import { CompanyFieldConfig } from '../../api/companyPopup/types'
import { EnrichSource } from '../../containers/Dialog/EnrichCompanies/types'
import { getRedirectStateBasedOnModule } from '../../features/companyReport/utils'
import { getCountryCode } from '../../features/config/selectors'
import { DialogActions } from '../../features/dialog/actions'
import { CompanyPopupDialogDataGeneral } from '../../features/dialog/dataTypes'
import { getCountryCustomization } from '../../features/map/CountryCustomization'
import { PortfolioCompaniesActions } from '../../features/portfolioCompany/actions'
import { getCompany } from '../../features/portfolioCompany/selectors'
import { PortfolioCompany } from '../../features/portfolioCompany/types'
import { useAppSelector } from '../../hooks/useAppSelector'
import * as routes from '../../routes'
import { ApplicationModule } from '../../types'
import { Col, Row } from '../Grid'
import { ConditionallyVisible } from '../HideFeature'
import { Switch } from '../Switch'
import { LinkTitle, Text } from '../Text'
import { CompanyPopupLoading } from './CompanyPopupLoading'
import { CompanyPopupNotFound } from './CompanyPopupNotFound'
import { mapCompanyDataToPortfolioCompany } from './mappers'
import { HeaderCol, HeaderRow, InfoWrapper, SmallHeader } from './styled'

type MapPopupcontrolProps = {
  onCompanyLoad?: () => void
  company: PortfolioCompany | undefined
  fields: CompanyFieldConfig[] | undefined
}

const MapPopupControl = ({ onCompanyLoad, company, fields }: MapPopupcontrolProps) => {
  const leafletContext = useLeafletContext()

  React.useEffect(() => {
    const popup = leafletContext.overlayContainer?.getPopup()
    if (popup) {
      popup.update()
    }

    if (onCompanyLoad) {
      onCompanyLoad()
    }
  }, [company, fields, leafletContext.overlayContainer, onCompanyLoad])

  return null
}

type Props = CompanyPopupDialogDataGeneral & {
  isMapPopup?: boolean
  onCompanyLoad?: () => void
}

const CompanyPopupFC: React.FC<Props> = ({
  companyId,
  portfolioId,
  module,
  openCompanyReport,
  onCompanyLoad,
  isMapPopup = false
}) => {
  const { t } = useTranslation('portfolio')
  const dispatch = useDispatch()

  const company = useAppSelector(state => getCompany(state, { entityId: companyId }))
  const countryCode = useAppSelector(state => _.toLower(getCountryCode(state) ?? 'ita'))

  const { data: popupData, status } = useLoadCompanyPopupData(companyId, module)

  const fields = React.useMemo(() => popupData?.configuration?.fields, [popupData])

  const enrichCompany = React.useCallback(() => {
    if (!fields || !company) return

    const { companyUnitId, identificationCode } = company
    if (!companyUnitId || !company || company.isEnriched) return

    if (module === ApplicationModule.PortfolioManagement) {
      dispatch(
        DialogActions.openEnrichCompaniesDialog({
          params: {
            companyUnitId,
            margoId: company.entityId,
            portfolioId: portfolioId!,
            applicationModule: module
          },
          source: EnrichSource.PortfolioDetailMap
        })
      )
    } else if (module === ApplicationModule.Prospecting) {
      dispatch(
        DialogActions.openEnrichCompaniesDialog({
          params: {
            companyUnitId,
            identificationCode,
            applicationModule: module
          },
          source: EnrichSource.ProspectingMap
        })
      )
    } else if (module === ApplicationModule.CompanyReport) {
      dispatch(
        DialogActions.openEnrichCompaniesDialog({
          params: {
            companyUnitId,
            identificationCode,
            applicationModule: module
          },
          source: EnrichSource.ReportPopup
        })
      )
    } else if (module === ApplicationModule.Operations) {
      dispatch(
        DialogActions.openEnrichCompaniesDialog({
          params: {
            companyUnitId,
            identificationCode,
            applicationModule: module
          },
          source: EnrichSource.Operations
        })
      )
    }
  }, [dispatch, module, portfolioId, company, fields])

  React.useEffect(() => {
    if (status !== 'success' || !popupData) return
    dispatch(PortfolioCompaniesActions.updatePortfolioCompanies([mapCompanyDataToPortfolioCompany(popupData)]))
  }, [dispatch, status, popupData])

  if (status === 'loading') {
    return <CompanyPopupLoading />
  }
  if (!fields || !company) {
    return <CompanyPopupNotFound />
  }

  const cc = getCountryCustomization(countryCode)
  const formattedFields = cc.formatCompanyFields(company, fields)
  const filteredRows = _.reject(formattedFields, row => !row.value)

  return (
    <InfoWrapper>
      {isMapPopup && <MapPopupControl onCompanyLoad={onCompanyLoad} company={company} fields={fields} />}
      <HeaderRow>
        <HeaderCol>
          <LinkTitle
            level={3}
            to={
              openCompanyReport
                ? undefined
                : {
                    pathname: generatePath(routes.COMPANY_REPORT, {
                      companyUnitId: company.companyUnitId,
                      identificationCode: company.identificationCode
                    })
                  }
            }
            state={openCompanyReport ? undefined : getRedirectStateBasedOnModule(module, portfolioId)}
            onClick={
              openCompanyReport ? () => openCompanyReport(company.companyUnitId, company.identificationCode) : undefined
            }
          >
            {company.name}
          </LinkTitle>
        </HeaderCol>
      </HeaderRow>
      {_.map(filteredRows, (row, index) => {
        const isLast = index === filteredRows.length - 1
        return (
          <Row key={`comp-popup-detail-${row.label}`} padding="25px 0 0 0">
            <Col md={isLast ? 8 : 12} sm={12}>
              <SmallHeader primary size="S" bold text={row.label} />
              <Text size="M" text={row.value} />
            </Col>
            <ConditionallyVisible condition={isLast}>
              <Col md={4} sm={12}>
                <Switch
                  checked={company.isEnriched}
                  checkedChildren={t('Enriched')}
                  unCheckedChildren={t('Enrich')}
                  size="large"
                  onClick={enrichCompany}
                />
              </Col>
            </ConditionallyVisible>
          </Row>
        )
      })}
    </InfoWrapper>
  )
}

export const CompanyPopup = React.memo(CompanyPopupFC)
