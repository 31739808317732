import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import { TargetingActions } from '../../../../features/targeting/actions'
import { SelectedPortfolio } from '../../../../features/targeting/types'
import { getPortfoliosList, getSelectedPortfolios } from '../../../../features/targeting/selectors'
import { LoadPortfolioDialog } from './LoadPortfolioDialog'
import { useSaveBeforeContinuing, SaveBeforeContinuingDialog } from '../SaveBeforeContinuing'
import { DialogPropsType } from '../types'
import { clearDialogData } from '../helpers'
import { COUNT_OF_COMPANIES } from '../../../../api/targeting/types'
import * as a from '../../../../features/targeting/actions'
import { TARGETING_MARKET_ANALYSIS } from '../../../../routes'
import { targetingQueriesEnabled } from '../../../../features/user/selectors'
import { useTargetingContext } from '../../../../features/targeting/context'

export const LoadPortfolioDialogContainer = ({ close, data }: DialogPropsType) => {
  const { t } = useTranslation(['targeting', 'common', 'error'])
  const dispatch = useDispatch()
  const { excludedPortfoliosIds } = useTargetingContext()
  const navigate = useNavigate()
  const location = useLocation()

  const originalPortfolios = useSelector(getSelectedPortfolios)

  const [saveSkipped, dialogProps] = useSaveBeforeContinuing('LoadTargetingPortfolio', data.contextData)
  const [selectedPortfolios, setSelectedPortfolios] = useState<SelectedPortfolio[] | null>(originalPortfolios)

  const closeAndClearDialogData = () => {
    close()
    clearDialogData(dispatch, 'LoadTargetingPortfolio')
  }

  const submit = () => {
    if (selectedPortfolios) {
      navigate({ pathname: location.pathname }, { replace: true, state: { ...location.state, query: undefined } })
      dispatch(TargetingActions.setSelectedPortfolios(selectedPortfolios))
      dispatch(a.TargetingActions.setAggregationField(COUNT_OF_COMPANIES))

      navigate({ pathname: TARGETING_MARKET_ANALYSIS })
      close()

      // TODO: disabled for now, to avoid charts reloading after submit
      // dispatch(
      //   DialogActions.showDialogWithData('LoadingDialog', {
      //     message: t('LoadPortfolio.LoadingMessage'),
      //     transition: false
      //   })
      // )
    }
  }

  const portfolios = useSelector(getPortfoliosList)

  const targetingQueriesAllowed = useSelector(targetingQueriesEnabled)

  if (!saveSkipped && data.canSaveQuery && targetingQueriesAllowed) {
    return <SaveBeforeContinuingDialog {...dialogProps} />
  }

  return (
    <LoadPortfolioDialog
      t={t}
      portfolios={_.reject(portfolios, ({ id }) => _.includes(excludedPortfoliosIds, id))}
      loading={false}
      close={closeAndClearDialogData}
      selectedPortfolios={selectedPortfolios}
      setSelectedPortfolios={setSelectedPortfolios}
      noTransition={saveSkipped || _.get(data, 'noTransition', false)}
      error={_.get(data, 'error')}
      onSubmit={submit}
    />
  )
}
