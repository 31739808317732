/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Název společnosti',
  Actions: 'Akce',
  DownloadPDF: 'Stáhnout PDF',
  AddToCampaign: 'Přidat do kampaně',
  CompanyData: {
    CompanyName: 'Název společnosti',
    Title: 'Náhled',
    CompanyId: 'IČO',
    Town: 'Město',
    OfficeClassification: 'Typ pobočky',
    Ateco2007: 'Primární Ateco (2007)',
    NaceCode: 'Převažující činnost (NACE)',
    LegalForm: 'Právní forma',
    LastUpdate: 'Poslední aktualizace',
    TaxCode: 'IČ',
    VatCode: 'DIČ',
    ZipCity: 'PSČ, Město',
    MainIndustrialSectors: 'Hlavní průmyslová odvětví'
  },
  CompanySummary: {
    Evaluation: 'Hodnocení',
    Profile: 'Profil',
    AffidabilityScore: 'Skóre důvěryhodnosti',
    CompanyGroup: 'Skupina společností',
    PublicContracts: 'Veřejné zakázky',
    LocalUnits: 'Pobočky',
    CompanyStatus: 'Status společnosti',
    Title: 'Přehled společnosti',
    Address: 'Adresa',
    Town: 'Město',
    State: 'Stát',
    TaxCode: 'IČ',
    VatCode: 'DIČ',
    CrifNumber: 'CRIF Number',
    CCIAAREA: 'CCIA / REA',
    TotalNumberBranches: 'Počet poboček',
    TotalNumberLocalUnit: 'Počet poboček',
    DetailedLegalForm: 'Detail právní formy',
    Ateco2007: 'Primární Ateco (2007)',
    SIC: 'SIC',
    NACE: 'NACE',
    StartDate: 'Datum začátku',
    IncorporationDate: 'Datum založení',
    RegistrationCamComDate: 'Datum registrace v ochodním rejstříku',
    HoldingCompanyName: 'Název holdingové společnosti',
    NationalParentCompany: 'Národní mateřská společnost',
    TenderWon3y: 'Vyhrané veřejné soutěže (poslední 3 roky)',
    TenderParticipated3y: 'Účast na veřejných soutěžích (poslední 3 roky)',
    TenderValueWon3y: 'Hodnota vyhraných veřejných soutěží (poslední 3 roky)',
    TypeOffice: 'Typ pobočky',
    CountOffice: 'Počet poboček podle typu',
    Turnover: 'Obrat',
    GroupTurnover: 'Skupinový obrat',
    Estimated: 'Odhadovaný',
    Profit: 'ZISK',
    Employees: 'Zaměstnanci',
    Mol: 'MOL',
    DetectionDate: 'Datum rozvahy',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'IČ DPH skupiny',
    MainIndustrialSectors: 'Hlavní průmyslová odvětví',
    SecondaryIndustrialSectors: 'Sekundární průmyslová odvětví',
    StartOfBusiness: 'Začátek podnikání',
    DateOfFoundation: 'Datum založení',
    TotalNumberOfVehicles: 'Celkový počet vozidel',
    NumberOfPassengerCars: 'Počet osobních automobilů',
    CarpoolNumberOfLorries: 'Vozový park - počet nákladních vozidel',
    tlcFix: 'Výdaje na TLC (telefon) (CZK)',
    tlcMob: 'Výdaje na TLC (mobilní telefon) (CZK)',
    tlcFixTrend: 'Trend výdajů na TLC (telefon) (%)',
    tlcMobTrend: 'Trend výdajů na TLC (mobilní telefon) (%)',
    Grants: 'Granty',
    BankInformation: 'Bankovní spojení',
    ImportExport: 'Import/Export',
    Vehicles: 'Vozidla',
    ratingScore: 'Rating skóre'
  },
  FinancialStatement: {
    Title: 'Finanční výkaz',
    Subtitle: 'Hlavní rozpočtové ukazatele za poslední 3 roky',
    Text: 'Kompletní detaily, položky rozvahy jsou k dispozici po stažení kompletního seznamu.',
    ProfitAndLossStatement: 'Položky z výsledovky (výkonnost)',
    BalanceSheet: 'Položky rozvahy (finanční stabilita)',
    RentabilityIndicators: 'Ukazatele rentability',
    EconomicData: 'Ekonomické údaje',
    FinancialData: 'Finanční údaje',
    FinancialRatios: 'Rozpočtové ukazatele',
    ProductionValue: 'Hodnota výroby',
    ProductionCosts: 'Náklady výroby',
    BalanceOfRevenues: 'Rovnováha výnosů a finančních nákladů',
    ProfitLoss: 'Zisk / Ztráta',
    CashFlow: 'Cash Flow',
    TotalAssets: 'Celková aktiva',
    FixedAssets: 'Stálá aktiva',
    TangAssets: 'Hmotný majetek',
    CurrentAssets: 'Oběžný majetek',
    TotalLiabilities: 'Pasiva celkem',
    Liabilities: 'Pasiva',
    ShareholdersEquity: 'Vlastní kapitál',
    Provisions: 'Rezervy',
    DebtRatio: 'Ukazatel zadluženosti',
    PFN: 'PFN',
    TotalCredits: 'Pohledávky celkem',
    TotalDebts: 'Závazky celkem',
    NetWorth: 'Čisté jmění',
    EBITDA: 'EBITDA',
    ROE: 'ROE',
    ROI: 'ROI',
    ROS: 'ROS',
    TotalEmployees: 'Celkový počet zaměstnanců',
    Turnover: 'Obrat',
    Profit: 'Zisk',
    RegisterCapital: 'Základní kapitál',
    BalanceSheetRatios: 'Rozvahové koeficienty',
    Sales: 'Tržby',
    Cost: 'Náklady',
    FinancialAssets: 'Finanční aktiva',
    BalanceSheetProfitLost: 'Zisk / ztráta z rozvahy (+/-)',
    Equity: 'Základní kapitál',
    LiquidityRatio: 'Likvidita',
    ReturnOnTotalCapital: 'Návratnost celkového kapitálu',
    BalanceSheetRatio: 'Rozvahový koeficient',
    TurnoverAndTurnoverTrend: 'Obrat a trend obratu',
    EmployeeEmployeetrend: 'Zaměstnanci a trend zaměstnanců',
    RequestBalance: 'Vyžádat zůstatek'
  },
  People: {
    Title: 'Lidé',
    From: 'Od',
    Amount: 'Částka',
    Exponents: 'Exponenti',
    Shareholders: 'Akcionáři',
    Partners: 'Přidružená společnost',
    SupervisoryBoard: 'Kontrolní a statutární orgány',
    Owners: 'Vlastníci',
    OwnershipInterests: 'Majetkové účasti ve společnostech',
    Years: 'let',
    OfficialSourceData: 'Údaje z oficiálního zdroje',
    UnofficialSourceData: 'Údaje z neoficiálního zdroje',
    DateOfBirth: 'Datum narození',
    TypeOfConnection: 'Typ vazby',
    GoToLinkedin: 'Přejít na LinkedIn',
    Visualize: {
      PersonData: 'Zobrazit osobní data',
      CompanyData: 'Zobrazit data společnosti'
    }
  },
  ContactsAndSocial: {
    Title: 'Kontakty a sociální sítě',
    Social: 'Sociální sítě',
    RepresentativeCompany: 'Představitel společnosti',
    CertifiedMail: 'Certifikovaný e-mail',
    PublicEmail: 'Veřejný e-mail',
    Website: 'Website URL',
    Fax: 'Fax',
    Telephone: 'Telefon',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Načíst více',
    ManagerContacts: 'Kontakty na manažery'
  },
  Analytics: {
    Title: 'Analytika',
    Seasonalit: 'Skóre sezónnosti',
    Propensity: 'Skóre tendence',
    AnalyticsMarketing: 'Analytika Marketing',
    AnalyticsFinance: 'Analytika Finance',
    AnalyticsMarketingMobile: 'Marketing',
    AnalyticsFinanceMobile: 'Finance',
    SeasonalitWinter: 'Zima',
    SeasonalitSpring: 'Jaro',
    SeasonalitSummer: 'Léto',
    SeasonalitAutumn: 'Podzim',
    PropensityShortTerm: 'Krátkodobý',
    PropensityMiddTerm: 'Střednědobý',
    PropensityCarLeas: 'Pronájem automobilu',
    PropensityInstrLeas: 'Pronájem vybavení',
    PropensityRealEstateLeas: 'Pronájem nemovitostí',
    PropensityFactoring: 'Faktoring',
    Internazionalization: 'Skóre internacionalizace',
    Social: 'Sociální skóre',
    DigitalAttitude: 'Skóre digitalizace',
    Innovation: 'Skóre inovace',
    Supplier: 'Skóre dodavatele',
    EstimatedTurnover: 'Odhadovaný obrat',
    AnalyticsMarketingTooltip: 'Analytika Marketing',
    AnalyticsFinanceTooltip: 'Analytika Finance',
    AnalyticsMarketingMobileTooltip: 'Marketing',
    AnalyticsFinanceMobileTooltip: 'Finance',
    SeasonalitTooltip:
      'Ukazuje roční období, ve kterém je pravděpodobné, že se společnosti zavážou ke krátkodobému úvěru. Pomáhá identifikovat prospekty nebo klienty a plánovat obchodní kampaně na určité období.',
    PropensityTooltip:
      'Ukazuje tendenci společnosti vzít si úvěrový produkt: krátkodobý úvěr, dlouhodobý úvěr, faktoring, leasing (auto, vybavení a stroje, nemovitosti). Skóre sklonu každého produktu je seřazené ve 3 úrovních. (1 = vysoká, 2 = střední, 3 = nízká).',
    InternazionalizationTooltip:
      'Ukazuje tendenci společnosti provádět mezinárodní obchod. Skóre se pohybuje od 1 (vysoká tendence) do 5 (nízká tendence).',
    SocialTooltip: 'Skóre sociální',
    DigitalAttitudeTooltip:
      'Odhaduje digitální spřízněnost společnosti, sklon k přijímání digitálních procesů (např. digitální přístup) a schopnost reagovat na digitální marketingové kampaně. Skóre se pohybuje od 1 (velký sklon) do 5 (nízký sklon) spřízněnosti.',
    InnovationTooltip:
      'Odhaduje úroveň inovace společnosti, která koreluje s jejím růstem. Skóre se pohybuje od 1 (vysoká inovace) do 5 (nízká inovace).',
    SupplierTooltip: 'Skóre dodavatele',
    EstimatedTurnoverTooltip:
      'Pro společnosti, které nemají účetní závěrku, byl vypracován model odhadu, který je schopen rekonstruovat nejnovější obrat.',
    TelcoFixScore: 'Skóre spotřeby pro telefon',
    TelcoMobScore: 'Skóre spotřeby pro mobilní telefon'
  },
  Mastercard: {
    Title: 'Maloobchod',
    Business: 'Obchod',
    BusinessTooltip:
      'Ukazuje obrat společnosti na základě 12ti-měsíčního trendu. Skóre se pohybuje od 1 (vysoký) do 3 (nízký).',
    Competitiveness: 'Konkurenceschopnost',
    CompetitivenessTooltip:
      'Ukazuje potenciál společnosti na základě konkurenčního prostředí, v němž působí. Pokud je vysoká, znamená to, že patří mezi nejlepší ve svém průmyslu a geografické oblasti. Skóre se pohybuje od 1 (vysoké) do 3 (nízké).',
    Digitalization: 'Digitalizace',
    DigitalizationTooltip:
      'Ukazuje úroveň rozvoje digitálních kanálů společnosti. Skóre se pohybuje od 1 (vysoká) do 3 (nízká).',
    Premium: 'Premium',
    PremiumTooltip:
      'Ukazuje vliv zákazníků, kteří vynakládají vysoké výdaje v rámci společnosti, ve srovnání s průměrem v daném odvětví. Pokud je tato hodnota vysoká, znamená to, že tato společnost přitahuje transakce nad průměrem v porovnání s partnerskými společnosťami. Skóre se pohybuje od 1 (vysoký) do 3 (nízký).',
    CommercialPotential: 'Obchodní potenciál',
    CommercialPotentialTooltip:
      'Ukazuje obchodní potenciál zeměpisné oblasti ve srovnání s průměry v odvětví. Skóre se pohybuje od 1 (vysoký) do 3 (nízký).',
    CompetitionLevel: 'Úroveň konkurence',
    CompetitionLevelTooltip:
      'Ukazuje koncentraci společností ve stejném odvětví v zeměpisné oblasti, kde společnost působí. Skóre se pohybuje od 1 (vysoké) do 3 (nízké).',
    Performance: 'Výkon',
    Restart: 'Restart'
  },
  Portfolio: {
    Title: 'Portfolio',
    NumberOfCompanies: 'Počet společností',
    CreationDate: 'Datum vytvoření',
    LastUpdateDate: 'Datum poslední aktualizace'
  },
  SalesTransaction: {
    Title: 'Zpětná vazba o prodeji',
    Name: 'Název',
    Product: 'Produkt',
    Sales: 'Prodej',
    Task: 'Úkol',
    startDate: 'Datum začátku',
    EndDate: 'Datum ukončení',
    Notes: 'Poznámky'
  },
  UnlockView: {
    Title: 'Chcete-li odemknout tuto část, zakupte si údaje společnosti {{companyName}}',
    MissingPortfoliosTitle: 'Zde najdete všechny portfolia, v nichž je společnost přítomna',
    MissingSalesTransactionsTitle: 'Zde najdete všechny kampaně, ve kterých je společnost přítomna',
    Subtitle: 'Tuto sekci můžete odemknout zakoupením údajů společnosti {{companyName}}',
    Button: 'Odemknout údaje společnosti {{companyName}}',
    Tooltip: 'Prosím, uveďte informační zprávu ...'
  },
  ModalWindow: {
    Notes: 'Poznámky',
    Title: 'Můžete přidat až pět poznámek k této společnosti',
    InsertNote: 'Vložte novou poznámku (max. 250 znaků)',
    NewNote: 'Novou poznámku napište sem',
    Add: 'Přidat',
    AreYouSure: 'Opravdu chcete smazat tuto poznámku?',
    Yes: 'Ano',
    No: 'Ne'
  },
  CompanyProfile: 'Profil společnosti',
  CompanyProfileMobile: 'Společnost',
  FinancialStatementSummary: 'Shrnutí účetní závěrky',
  FinancialStatementMobile: 'Finanční',
  Contacts: {
    Title: 'Kontakty'
  },
  News: 'Novinky',
  Close: 'Zavřít',
  LocalUnits: {
    Title: 'Místní jednotky',
    StartDate: 'Datum začátku'
  },
  Score: {
    Title: 'Vlastní index',
    Tabs: {
      Index: 'Vlastní index',
      Relevant: 'Relevantní události',
      Telco: 'Analytika Telco',
      Analytics: 'Analytické nástroje'
    }
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'PNRR Score Investment',
      Mission: 'Mission',
      Component: 'Component',
      Scope: 'Scope',
      Investment: 'Investment'
    }
  },
  CustomIndexes: {
    Title: 'Vlastní index',
    Tabs: {
      Index: 'Vlastní index',
      Warnings: 'Varování'
    },
    warningCzSkDescription:
      'Barva varovné informace indikuje její závažnost: méně závažné negativní informace jsou zobrazeny žlutou, vysoce závažné negativní informace červenou.',
    cribisIndexTooltip:
      'Vyjadřuje stabilitu subjektu spočtenou na základě finančních a/nebo nefinančních kritérií. Hodnocení dosahuje hodnot ve škále a1 (výjimečně stabilní subjekt) až e (velmi nestabilní subjekt nebo subjekt v insolvenci).'
  },
  CompanyPopup: {
    Title: 'Systémová zpráva',
    CompanyNotFound: 'Pro vybranou společnost nebylo možné obnovit žádná data'
  },
  PersonPopup: {
    Title: 'Systémová zpráva',
    Desc: 'Pro vybranou osobu nebylo možné obnovit žádná data'
  },
  PdfPopup: {
    Title: 'Potvrzení operace',
    TextEnriched:
      'Jakmile bude vytvořen PDF soubor, dostanete notifikaci a budete si ho moci stáhnout a uložit. Pokračovat v operaci?',
    TextNotEnriched:
      'Pro stažení PDF je třeba nejprve obohatit vybranou společnost. Proces může trvat několik minut. Pokračujte potvrzením.'
  },
  Appointments: {
    Title: 'Schůzky'
  }
}
