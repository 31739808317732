import React from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { Title, Text } from '../Text'
import { FormatNumberFunction } from '../../utils/types'

export const ItemCounterWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const TotalItemText = styled(Text)`
  color: ${({ theme: { colors } }) => colors.brownGray};
  margin-right: 5px;
`

type ItemCounterProps = {
  item?: number
  totalItems?: number
  text: string
  hideNumbers?: boolean
  title?: boolean
  formatNumber?: FormatNumberFunction
}

export const ItemCounter = ({
  item,
  totalItems,
  text,
  hideNumbers,
  title,
  formatNumber = _.identity
}: ItemCounterProps) => (
  <ItemCounterWrapper>
    {!hideNumbers && (
      <>
        <Title text={formatNumber(item)} />
        <TotalItemText>/{formatNumber(totalItems)}</TotalItemText>
      </>
    )}
    {title ? <Title>{text}</Title> : <Text>{text}</Text>}
  </ItemCounterWrapper>
)
