import _ from 'lodash'
import { NodeSelectionState, TreeKeySelection } from './types'
import { hasNodeKeySelection } from './utils'

export const nodeSelectionStateClassic = (
  keySelection: TreeKeySelection[],
  keySelectionItem: TreeKeySelection
): NodeSelectionState => {
  return hasNodeKeySelection(keySelection, keySelectionItem)
    ? NodeSelectionState.Selected
    : NodeSelectionState.NotSelected
}

export const selectNodeSelectClassic = (
  keySelection: TreeKeySelection[],
  keySelectionItemToSelect: TreeKeySelection
) => {
  return [...keySelection, keySelectionItemToSelect]
}

export const deselectNodeSelectClassic = (
  keySelection: TreeKeySelection[],
  keySelectionItemToDeselect: TreeKeySelection
) => {
  return _.reject(keySelection, keySelectionItem => _.isEqual(keySelectionItemToDeselect, keySelectionItem))
}
