/* eslint-disable import/no-default-export */
export default {
  Username: 'Username',
  Password: 'Password',
  Login: 'Login',
  LoginErrorMessage: 'The user or password entered is incorrect',
  SignInMessage: 'Sign in to Margò',
  EnterDetails: 'Enter your details below',
  InsertUserName: 'Insert your username',
  InsertUserNamaInBehalf: 'On behalf of',
  InsertPassword: 'Insert your password',
  ForgotPassword: 'Forgot your password?',
  ResetPasswordDialog: {
    Title: 'Forgot Password',
    Description:
      'Please contact our customer service to reset your password by email <0>{{email}}</0> or by phone {{phone}}.',
    Email: 'customerserviceitaly@cribis.com',
    Phone: '800 821058'
  },
  ResetPasswordForm: {
    Description:
      'Insert your Username and the email address associated to your account. An email will be sent to you with the link to complete the reset password.',
    SuccessDesription: 'An email with the link for resetting the password has been sent to you. Go to the login page.',
    Email: 'Email',
    ResetPassword: 'Reset Password',
    ReturnToLoginpage: 'Return to login page',
    InsertUserName: 'Insert your username',
    InsertEmail: 'Insert your email',
    InvalidUsernameOrPassword:
      'The password has not been reset. Username/email invalid. Please, retry or contact the customer service.',
    ResetFailed: 'The reset password failed.'
  },
  RenewPasswordForm: {
    Title: 'Reset password',
    Description: 'Your password has expired. For accessing into Margò services, please change your password.',
    OldPassword: 'Old password',
    NewPassword: 'New password',
    ConfirmPassword: 'Confirm new password',
    ChangePassword: 'Change password',
    PasswordChangeSuccessTitle: 'Password changed',
    PasswordChangeSuccessText: 'Password has been changed successfully',
    RenewPasswordFailed: 'The password entered is incorrect'
  },
  PasswordExpiration90Days: 'The password will remain valid for 90 days',
  PasswordRules_ita: `The password must be at least 8 characters long
  The password must contain at least one letter and one number
  The password cannot contain more than 3 consecutive identical characters
  The password must not contain the User Id`,
  PasswordRules_deu: `The password must be at least 8 characters long
  The password must contain at least one letter and one number
  The password cannot contain more than 3 consecutive identical characters
  The password must not contain the User Id
  The password must not be the same as the last six passwords used`,
  PasswordRules_czsk: `The password must be at least 8 characters long
  The password cannot contain more than 3 consecutive identical characters
  The password must contain uppercase and lowercase letters
  The password must contain at least one number and one special character
  The password must not contain a username
  The password must not be the same as the last six passwords used`,
  PasswordRules_fareast: `The password must be between 8 and 25 characters (letters and numbers)
  The password must contain at least one capital letter and one number
  The password cannot contain 2 consecutive identical characters
  The password must not contain the username
  The password must not be the same as the last 10 passwords used`,
  PasswordRules_che: `The password minimum length is 8 characters and maximum length is 25 characters
  The password must contain at least one letter and one digit
  The password cannot be the same as the old password and it cannot contain the username
  The password must have at least one capital letter
  The password cannot contain 3 consecutive identical characters`,
  Cs: {
    Title: 'User already logged in',
    Info: 'Your user is already logged in, proceeding you will end the active session. All background operations will be kept.',
    Question: 'Do you want to close existing session and start new one?',
    NewSession: 'Start new session'
  },
  Sso: {
    error: 'There was problem with login by method "Single Sign On".',
    back: 'Sign in by username and password'
  }
}
