/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: 'Type of analysis',
    StartAnalysis: 'To start the analysis you need to select the type of analysis you want to process',
    MarketAnalysisBtn: 'Start new market analysis',
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    PortfolioQuery: 'Portfolio Management search',
    TargetingQuery: 'Targeting Query',
    ProspectingQuery: 'Prospecting Query',
    ChooseQuery: 'Choose which query you want to load',
    MarketAnalysisBtnTooltip:
      'Visualize and segment a set of companies according to the main variables and build your target',
    FromTargeting: 'Load market analysis query',
    FromTargetingTooltip: 'Open a query saved in the Analyze market module',
    FromProspecting: 'Load prospecting query',
    FromProspectingTooltip: 'Open a query saved in the Prospecting module'
  },
  ChartLabels: {
    Company: 'Company',
    Company_plural: 'Companies',
    ChartDetail: 'Chart detail',
    PercentageOfCompanies: '% of companies',
    NumberOfCompanies: 'n° of companies',
    NumberOfEmployees: 'n° of employees',
    Amount: 'Amount',
    Risk: 'Risk level',
    Employees: 'Employees',
    Revenue: 'Turnover',
    RevenueCz: 'Turnover',
    RevenueSk: 'Turnover',
    Other: 'Other',
    Years: 'Years',
    Status: 'Status',
    HiddenNDNotice: 'Notice: this chart does not show the company class with no data'
  },
  Header: {
    Targeting: 'Analyze market',
    Title: 'Market Analysis',
    LoadQuery: 'Load query',
    LoadQueryTooltip: 'Open a saved query',
    SaveQuery: 'Save query',
    SaveQueryTooltip: 'Save this query in the Analyze market module',
    Download: 'Download PDF/Excel',
    DownloadTooltip: 'Download a report of this query on your device',
    LoadPortfolio: 'Load portfolio',
    LoadPortfolioTooltip: 'Open and analyze a portfolio',
    SendToProspecting: 'Send query to Prospecting',
    SendToProspectingTooltip: 'Display the list of companies that belong to this query',
    SendToProspectingFromPortfolioTooltip: 'Show prospect companies that match the search criteria',
    SendToProspectingMessage: 'To enable this button, apply a filter to your analysis',
    SaveList: 'Save list',
    SaveListTooltip: 'Save this companies list into a portfolio'
  },
  Filters: {
    CompanyCount: 'Company',
    CompanyCount_plural: 'Companies',
    ActiveCompanyCount: 'Active',
    ActiveCompanyCount_plural: 'Active',
    OtherCompanyCount: 'Other',
    StatusSelectLabel: 'Choose status',
    ShowBySelectLabel: 'Show by',
    AggregationFieldAmount: 'Amount',
    AggregationFieldNumCompanies: 'Count of companies'
  },
  Search: {
    SemanticSearch: 'Semantic search',
    SearchPlaceholder: 'Search',
    All: 'All',
    OfficialData: 'Official data',
    Website: 'Web & Social',
    Synonyms: 'Synonyms',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Advanced',
    AdvancedTooltip:
      'With the Advanced semantic search you can use multiple words linked by logical operators (AND, OR, NOT). To enable it, turn the switch to ON. Download the document for more informations.',
    DownloadSsGuide: 'Download semantic search guide'
  },
  DomainFilter: {
    SaveAndClose: 'Save filters & close'
  },
  SaveQuery: {
    DialogTitle: 'Save research',
    Description: 'Insert the name you want to use to save your research or select an existing query to override it.',
    InputLabel: 'Query name',
    InputPlaceholder: 'Research name',
    UpdateExisting: 'Update existing',
    CreateNew: 'Create New',
    AddToDashboardConfirmMessage: 'Do you want to add the query in your dashboard?',
    AddToDashboardCheckboxText: 'Show in dashboard',
    MaximumWidgetCountReached: 'The maximum number of boxes in the Dashboard has been reached.',
    SavedQuery: 'saved correctly.',
    SavedQueryAndDashboard: 'saved and added in dashboard.',
    SavedQueryDescription: 'You will find the query saved in the repository related to the reference area.'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Choose which Prospecting query you want to load',
    IntroSectionTitleTargeting: 'Choose which Targeting query you want to load',
    IntroSectionDescription: 'You can resume a research that you have already started.',
    DialogTitle: 'Load saved research',
    DialogSubTitle: 'Select saved search',
    DialogDescription:
      'Choose which analysis you want to continue, you can resume the analysis from one of the saved ones.',
    SelectLabel: 'Saved query',
    Load: 'Load',
    LoadingMessage: 'Loading your analysis',
    InvalidPortfolioTitle: 'Invalid portfolio',
    InvalidPortfolioMessage:
      'The portfolio saved in the query is not valid anymore.\nYour analysis will be based on all the companies in the {{companyName}} database.',
    TargetingRadio: 'Targeting query',
    ProspectingRadio: 'Prospecting search'
  },
  LoadPortfolio: {
    DialogTitle: 'Load portfolio',
    DialogSubTitle: 'Select a portfolio',
    Description: 'Choose on which portfolio you want to perform your analysis.',
    SelectLabel: 'Saved portfolio',
    Load: 'Load',
    LoadingMessage: 'Loading portfolio'
  },
  SaveBeforeContinuing: {
    Question: 'Do you want to save your market search before loading the search?',
    Description: 'Save your market search in a new one before loading the query to avoid losing data.',
    OkBtn: 'Save search',
    CancelBtn: 'Skip save'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: 'Select the portfolio you want to analyze',
      Description:
        'You can process the analysis from one or more of your portfolios or view the aggregated analysis of all the companies in the {{companyName}} system.',
      PortfolioOption: 'Select portfolio',
      AggregateOption: 'All companies in the {{companyName}} System',
      UploadedPortfolio: 'Uploaded portfolio'
    },
    StepTwo: {
      Header: 'Choose the status you want to analyze',
      DescriptionLine1: 'Select the initial status of the companies to be analyzed.',
      DescriptionLine2: 'During the analysis you can change the status using filters.',
      Status: 'Status'
    }
  },
  Download: {
    ModalTitle: 'Download PDF/Excel',
    SelectType: 'Select format',
    SelectTypeDescription:
      'You can download the complete report about your analysis in two different form; select the type of list you want to download.',
    DownloadAnalysis: 'Download your analysis',
    Pdf: 'PDF',
    PdfDescription: 'Download in .pdf format',
    PdfRecap: 'You are downloading a file containing all the result of this analysis as a pdf.',
    Excel: 'Excel',
    ExcelDescription: 'Download in .xlsx format',
    ExcelRecapLine1: 'You are downloading a file containing all the result of this analysis as a list.',
    ExcelRecapLine2: 'It will be saved in the "Analyze Market" section of your account.',
    CompanyInPortfolio: 'Company in this portfolio',
    CompanyInPortfolio_plural: 'Companies in this portfolio',
    CompanyInAnalysis: 'Analyzed company',
    CompanyInAnalysis_plural: 'Analyzed companies',
    DownloadPdf: 'Download PDF',
    DownloadPdfPleaseWait: 'Please wait while your PDF report is being generated',
    DownloadAllChartsMustBeLoaded: 'To download the report, please wait for all charts to have been loaded',
    DownloadPdfDisabledIE: 'Download PDF not supported on this version of IE. To download it, update your browser.',
    PdfPage: {
      Title: 'Analysis report',
      CompanyPortfolioStatus: 'Companies status in portfolio',
      DataShownBy: 'Data shown by',
      ExcludedPortfolios: 'Excluded portfolios'
    }
  },
  UnavailableForMobile: 'The page you are trying to access is only available for tablets and desktops.',
  ChartFetchError: 'An error occurred while loading this chart.',
  ChartFetchRetry: 'Retry',
  ChartEmpty: 'No data for this chart',
  BasedOnHeadquarter: 'The numbers shown are related to the administrative headquarter',
  VisualizeAs: {
    List: 'Visualize as list',
    Map: 'Visualize on map'
  },
  WarningRespectingLocalUnits: {
    Title: 'Enrichment',
    Text: 'Companies with Office type "Administrative Headquarter", "Administrative Headquarter and Registered office" and "Registered office" have been enriched (if selected). To enrich companies with Office type "Local unit", please activate the Local Units addon by contacting the customer service.'
  }
}
