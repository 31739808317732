import _ from 'lodash'
import { PersonResponseItem, AffiliateInfo } from '../../api/person/types'
import { PartnerType } from '../report/types'
import { PERSON_REPORT_SECTIONS } from './constants'
import { Person, PersonActivePositionGrouped, PersonPastPositionGrouped, PersonPartnerInfo } from './types'
import { filterSections } from './utils'

type MappedPositions = {
  current: Record<string, PersonActivePositionGrouped>
  past: Record<string, PersonPastPositionGrouped>
}

export const mapPersonResponseToPerson = (personId: string, data: PersonResponseItem): Person => {
  const allPositions = [...(data.rolesPowers?.activeRolesPowers || []), ...(data.rolesPowers?.ceasedRolesPowers || [])]

  const mappedPositions = _.reduce(
    allPositions,
    (acc: MappedPositions, val) => {
      const key = val.taxCode?.value
      if (!key) return acc
      if (val.to?.value) {
        const curr = acc.past[key]
        if (!curr) {
          acc.past[key] = {
            type: PartnerType.Company,
            taxCode: val.taxCode,
            companyName: val.companyName,
            positions: [{ role: val.role, from: val.from, to: val.to }]
          }
        } else {
          curr.positions.push({ role: val.role, from: val.from, to: val.to })
        }
      } else {
        const curr = acc.current[key]
        if (!curr) {
          acc.current[key] = {
            type: PartnerType.Company,
            taxCode: val.taxCode,
            companyName: val.companyName,
            positions: [{ role: val.role, from: val.from }]
          }
        } else {
          curr.positions.push({ role: val.role, from: val.from })
        }
      }
      return acc
    },
    { current: {}, past: {} }
  )

  return {
    id: personId,
    sections: filterSections(PERSON_REPORT_SECTIONS, data),
    name: data.profile?.name?.value || '',
    surname: data.profile?.surname?.value || '',
    isEnriched: data.isEnriched?.value || false,
    info: {
      name: data.profile?.name,
      surname: data.profile?.surname,
      gender: data.profile?.gender,
      taxCode: data.profile?.taxCode,
      residence: data.profile?.residence,
      domicile: data.profile?.domicile,
      birthDate: data.profile?.birthDate,
      birthPlace: data.profile?.birthPlace,
      birthCountry: data.profile?.birthCountry
    },
    partners: _.map<AffiliateInfo, PersonPartnerInfo>(data.affiliates, item => ({
      type: PartnerType.Company,
      amount: item.amount,
      companyName: item.companyName,
      ownedSharesType: item.ownedSharesType,
      taxCode: item.taxCode
    })),
    positions: {
      labelDescription: data.rolesPowers?.labelDescription,
      current: _.values(mappedPositions.current),
      past: _.values(mappedPositions.past)
    }
  }
}
