import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { TreeNodeValue } from 'antd3/lib/tree-select/interface'
import { changeSemanticCluster } from '../../../api/emails'
import { Dialog } from '../../../components/Dialog'
import { HookForm } from '../../../components/Form'
import { InputField } from '../../../components/Form/Input'
import { Text } from '../../../components/Text'
import { DialogActions } from '../../../features/dialog/actions'
import { length, required, requiredWithoutFieldName } from '../../../utils/validators'
import { SemanticClusterContactFormDialogData } from './types'
import { CheckboxField } from '../../../components/Form/Checkbox'
import { Link } from '../../../components/Markdown/components'
import { PRIVACY_POLICY } from '../../../routes'
import { filterTreeNode, TreeSelect } from '../../../components/TreeSelect'
import { SEMANTIC_CLUSTERS_FILTER } from '../../../features/filters/constants'
import { useGetDomainNodesDataNotEmpty } from '../../../api/filters'
import { mapSemanticDomainNodesDataToTreeData } from './utils'

const FormRow = styled.div`
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
`

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.main} !important;
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
`

export type Props = {
  data: SemanticClusterContactFormDialogData
  close: () => void
}

export type SemanticClusterContactFormState = {
  companyName: string
  identificationCode: string
  proposedClusterValue: string
  proposedClusterLabel: string
  source: string
  agreeWithPrivacyPolicy: boolean
}

export const SemanticClusterContactFormDialog = ({ data, close }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'portfolio', 'contact', 'companyReport'])

  const { control, handleSubmit, formState, watch, setValue } = useForm<SemanticClusterContactFormState>({
    mode: 'onChange',
    defaultValues: {
      companyName: data.companyName,
      identificationCode: data.identificationCode
    }
  })
  const { isSubmitting, isValid } = formState

  const { data: semanticDomainNodesData, isLoading: isGetSemanticDataLoading } = useGetDomainNodesDataNotEmpty({
    filterHierarchy: SEMANTIC_CLUSTERS_FILTER,
    useSingleSelection: false,
    includeEmpty: true,
    ignoredPortfolios: [],
    query: undefined,
    targetingQuery: undefined,
    includeZero: true
  })

  const semanticTreeData = useMemo(
    () => mapSemanticDomainNodesDataToTreeData(semanticDomainNodesData),
    [semanticDomainNodesData]
  )

  const noClusterAssigned = _.isEmpty(data.currentCluster)
  const textKey = noClusterAssigned ? 'TextNoClusterAssigned' : 'Text'

  const { mutate: mutateSendEmail, isLoading } = useMutation(
    () => {
      return changeSemanticCluster({
        companyName: data.companyName,
        identificationCode: watch('identificationCode')!,
        crifNumber: data.crifNumber,
        currentCluster: noClusterAssigned ? 'No Semantic Cluster assigned to this company.' : data.currentCluster,
        proposedClusterValue: watch('proposedClusterValue')!,
        proposedClusterLabel: watch('proposedClusterLabel')!,
        source: watch('source')!
      })
    },
    {
      onSuccess: () => {
        const text = (
          <>
            <b>{t('companyReport:CompanySummary.SemanticCluster.ConfirmationDialogWarning')}</b>
            <br />
            <br />
            {t('companyReport:CompanySummary.SemanticCluster.ConfirmationDialogText')}
          </>
        )

        dispatch(
          DialogActions.showDialogWithData('WarningDialog', {
            title: t('companyReport:CompanySummary.SemanticCluster.ConfirmationDialogTitle'),
            text,
            onClose: close
          })
        )
      },
      onError: () => {
        dispatch(
          DialogActions.showDialogWithData('WarningDialog', {
            title: t('error:ServerError'),
            text: t('error:ErrorPage.Text'),
            onClose: close
          })
        )
      }
    }
  )

  return (
    <Dialog
      loading={isLoading}
      visible
      destroyOnClose
      width={700}
      title={t('contact:ContactUs')}
      footer=""
      onCancel={close}
      cancelText={t('common:Cancel')}
      cancelButtonProps={{ disabled: isLoading }}
      okText={t('contact:Send')}
      onOk={() => {
        mutateSendEmail()
      }}
      okButtonProps={{ disabled: !watch('companyName') || isLoading || !isValid || !watch('proposedClusterValue') }}
    >
      <HookForm handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
        <>
          <Text block margin={{ bottom: 40 }}>
            {t(`companyReport:CompanySummary.SemanticCluster.${textKey}`)}
          </Text>
          <InputField
            name="companyName"
            label={t('companyReport:CompanySummary.SemanticCluster.CompanyName')}
            control={control}
            formState={formState}
            disabled
          />
          <InputField
            name="identificationCode"
            label={t('companyReport:CompanySummary.SemanticCluster.IdentificationCode')}
            control={control}
            formState={formState}
            disabled
          />
          <StyledText>{`${t('companyReport:CompanySummary.SemanticCluster.SemanticCluster')}`}</StyledText>
          <TreeSelect
            treeData={semanticTreeData}
            onChange={(_value: TreeNodeValue, label: string[]) => {
              setValue('proposedClusterValue', _value.toString())
              setValue('proposedClusterLabel', label[0])
            }}
            loading={isGetSemanticDataLoading}
            filterTreeNode={filterTreeNode}
            showCheckedStrategy="SHOW_ALL"
            showSearch
            placeholder={t('companyReport:CompanySummary.SemanticCluster.SemanticClusterPlaceholder')}
          />
          <InputField
            name="source"
            label={t('companyReport:CompanySummary.SemanticCluster.Source')}
            control={control}
            formState={formState}
            placeholder={t('companyReport:CompanySummary.SemanticCluster.SourcePlaceholder')}
            validators={{
              required,
              length: length({ min: 5, max: 256 })
            }}
          />
          <FormRow>
            <Controller
              name="agreeWithPrivacyPolicy"
              control={control}
              render={({ field: { value, name } }) => (
                <CheckboxField
                  name={name}
                  formState={formState}
                  checked={value}
                  control={control}
                  required
                  validators={{
                    requiredWithoutFieldName
                  }}
                >
                  <>
                    <Text>{`${t('contact:IAgreeWith')} `}</Text>
                    <Link href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                      {t('contact:PrivacyPolicy')}
                    </Link>
                  </>
                </CheckboxField>
              )}
            />
          </FormRow>
        </>
      </HookForm>
    </Dialog>
  )
}
