import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { hasSemanticClusters } from '../../../features/user/selectors'
import { Button } from '../../../components/Button'
import { HookForm } from '../../../components/Form'
import { Option } from '../../../components/Select'
import { PortfolioStats } from '../../../api/portfolio/types'
import { SelectField } from '../../../components/Form/Select'
import { Switch } from '../../../components/Switch'
import { Text } from '../../../components/Text'
import { FormFieldsWrapper, SwitchWrapper, SelectWrapper } from './components'

type Props = {
  onStatusSelect: (statusId: string, includeSemanticClusters: boolean | undefined) => void
  statuses?: (keyof PortfolioStats)[]
}

export const GenerateSimilarityDialogForm = ({ onStatusSelect, statuses }: Props) => {
  const { t } = useTranslation(['portfolio', 'common'])
  const { control, getValues, handleSubmit, formState, watch } = useForm()
  const { isSubmitting } = formState
  const semanticClustersAllowed = useSelector(hasSemanticClusters)

  const [includeSemanticClusters, setIncludeSemanticClusters] = useState<boolean | undefined>(
    semanticClustersAllowed ? false : undefined
  )
  const statusValue = getValues('status')

  const OnButtonClick = useCallback(() => {
    onStatusSelect(statusValue, includeSemanticClusters)
  }, [onStatusSelect, statusValue, includeSemanticClusters])

  const isStatusSelected = !!watch('status', false)
  return statuses ? (
    <HookForm handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
      <FormFieldsWrapper>
        <SelectWrapper>
          <SelectField
            name="status"
            control={control}
            formState={formState}
            rules={{ required: true }}
            hideLabel
            label={t('portfolio:Table.companyStatusCode')}
            placeholder={t('portfolio:GenerateSimilarity.DropdownPlaceholder')}
          >
            {_.map(statuses, s => (
              <Option key={s} value={s}>
                {t(`common:${_.capitalize(s)}`)}
              </Option>
            ))}
          </SelectField>
        </SelectWrapper>
        <Button type="primary" onClick={OnButtonClick} disabled={!isStatusSelected}>
          {t('portfolio:GenerateSimilarity.GotoActionButton')}
        </Button>
      </FormFieldsWrapper>
      {semanticClustersAllowed && (
        <SwitchWrapper>
          <Text size="M">{t('portfolio:GenerateSimilarity.IncludeSemanticClusters')}</Text>
          <Switch
            checked={includeSemanticClusters}
            checkedChildren={t('common:On')}
            unCheckedChildren={t('common:Off')}
            size="large"
            onChange={checked => setIncludeSemanticClusters(checked)}
          />
        </SwitchWrapper>
      )}
    </HookForm>
  ) : null
}
