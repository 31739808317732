import { useQuery } from '@tanstack/react-query'
import { GetPortfolioNotificationsRequest, PortfolioNotification } from './types'
import { getPortfolioNotifications } from './api'

export const getNotificationKeys = {
  All: () => [{ level1: 'notifications' }] as const,
  GetPortfolioNotifications: (request: GetPortfolioNotificationsRequest) =>
    [
      {
        ...getNotificationKeys.All()[0],
        level2: 'getPortfolioNotifications',
        request
      }
    ] as const
}

export const useGetPortfolioNotifications = (
  enabled: boolean,
  request: GetPortfolioNotificationsRequest,
  onSuccess: (data: PortfolioNotification[]) => void
) =>
  useQuery(
    getNotificationKeys.GetPortfolioNotifications(request),
    ({ queryKey: [queryKeys] }) => getPortfolioNotifications(queryKeys.request),
    { enabled, onSuccess }
  )
