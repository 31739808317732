// eslint-disable-next-line import/no-default-export
export default {
  title: 'Operácie',
  CompaniesInTheList: 'Spoločností v zozname',
  Editor: {
    AddRule: 'Pridať pravidlo',
    AddGroup: 'Pridať skupinu',
    DeleteGroup: 'Odstrániť skupinu',
    StartNewSearch: 'Začať nové vyhľadávanie'
  },
  ShowQuery: 'Zobraziť dopyt',
  NoMobileDesc: 'Stránka, na ktorú sa pokúšate dostať, je k dispozícii iba pre tablety a počítače.',
  Search: 'Hľadať',
  Actions: {
    LoadQuery: 'Načítať dopyt',
    LoadQueryTooltip: 'Otvorte uložený dopyt',
    SaveQuery: 'Uložiť dopyt',
    SaveQueryTooltip: 'Uložte tento dopyt do Operations',
    Download: 'Stiahnuť Excel',
    DownloadTooltip: 'Stiahnite si obohatený zoznam spoločností do svojho zariadenia',
    SaveList: 'Uložit zoznam',
    SaveListTooltip: 'Uložte zoznam týchto spoločností do portfólia'
  },
  SaveQuery: {
    DialogTitle: 'Uložiť vyhľadávanie',
    Description:
      'Zadajte názov, ktorý chcete použiť na uloženie výskumu, alebo vyberte existujúci dotaz, ktorý chcete prepísať.',
    InputLabel: 'Názov dopytu',
    InputPlaceholder: 'Názov vyhľadávania',
    UpdateExisting: 'Aktualizovať existujúce',
    CreateNew: 'Vytvor nový',
    SavedQuery: 'správne uložené.',
    SavedQueryDescription: 'Dopyt nájdete uložený v úložisku súvisiacom s referenčnou oblasťou.'
  },
  filters: {
    selectGroup: 'Vyberte skupinu',
    selectFilter: 'Vyberte filter',
    booleanTrue: 'Pravda',
    booleanFalse: 'Nepravda',
    booleanEmpty: 'Nie je k dispozícií',
    searchField: 'Vyhľadať filtre',
    searchText: 'Hľadať text',
    operatorsEquals: 'Rovná sa',
    operatorsNotEquals: 'Nerovná sa',
    operatorsGreater: 'Väčšie ako',
    operatorsLower: 'Menšie ako',
    operatorsGreaterOrEqual: 'Väčšie alebo rovné',
    operatorsLowerOrEqual: 'Menšie alebo rovné',
    operatorsNotExists: 'Prázdne',
    operatorsExists: 'Neprázdne',
    operatorsBetween: 'Medzi',
    operatorsIsOneOf: 'Vnútri',
    operatorsIsNotOneOf: 'Nie vo vnútri',
    operatorsIn: 'Vnútri',
    operatorsNotIn: 'Nie vo vnútri',
    operatorsContains: 'Obsahuje',
    operatorsNotContains: 'Neobsahuje',
    fromDate: 'Dátum od',
    toDate: 'Dátum do',
    minValue: 'Minimálna hodnota',
    maxValue: 'Maximálna hodnota'
  },
  CustomizeQuery: {
    Title: 'Prispôsobte svoj dotaz.',
    Message: 'Kliknutím na „Pridať pravidlo“ spustíte prieskum. Vyberte operátorov, zvoľte príslušné filtre a potvrďte.'
  }
}
