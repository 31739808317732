import React, { useCallback } from 'react'
import { Form } from 'antd3'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { useGetFilterQueriesAsDocument } from '../../../api/query/queries'
import { FETCH_QUERIES_TOP } from '../../../features/query/constants'
import { Input } from '../../../components/Input'
import { Dialog } from '../../../components/Dialog'
import { Stack } from '../../../components/Stack'
import { Text } from '../../../components/Text'
import { Button } from '../../../components/Button'
import { Radio, RadioGroup } from '../../../components/Radio'
import { Select, Option } from '../../../components/Select'
import { checkQueryName } from '../../../utils/query'
import { QueryType } from '../../../types/common'
import { SubmitMethod } from './types'
import { validateSaveQueryData } from './utils'

const SaveQueryButtons = styled(Stack)`
  width: auto;
`

type Props = {
  submitting: boolean
  queryName: string
  setQueryName: (queryName: string) => void
  queryNameError: string | undefined
  currentSaveMethod: SubmitMethod
  setCurrentSaveMethod: (option: SubmitMethod) => void
  setQueryId: (queryId: string) => void
  queryId: string | undefined
  submit: () => void
  close: () => void
  setNameError: (value: string | undefined) => void
}

export const SaveQueryDialog = ({
  submitting,
  close,
  submit,
  setQueryName,
  currentSaveMethod,
  queryName,
  queryNameError,
  setQueryId,
  queryId,
  setCurrentSaveMethod,
  setNameError
}: Props) => {
  const { t } = useTranslation(['operations', 'common', 'error'])

  const { data, isLoading } = useGetFilterQueriesAsDocument({
    documentTypes: [QueryType.Queries, QueryType.OperationQueries],
    top: FETCH_QUERIES_TOP
  })

  const onInputChangeHandler = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setNameError(checkQueryName(t, target.value))
      setQueryName(target.value)
    },
    [setNameError, setQueryName, t]
  )

  const isCreateNew = currentSaveMethod === SubmitMethod.CreateNew
  const isUpdateExisting = currentSaveMethod === SubmitMethod.Update
  const dialogFooter = (
    <Stack gap="auto" center>
      <SaveQueryButtons gap="1em">
        <Button disabled={submitting} onClick={close}>
          {t('common:Cancel')}
        </Button>
        <Button
          type="primary"
          disabled={
            submitting || queryNameError !== undefined || !validateSaveQueryData(currentSaveMethod, queryName, queryId)
          }
          onClick={submit}
        >
          {t('common:Save')}
        </Button>
      </SaveQueryButtons>
    </Stack>
  )

  return (
    <Dialog
      title={t('SaveQuery.DialogTitle')}
      width={900}
      visible
      loading={submitting}
      onCancel={close}
      footer={dialogFooter}
    >
      <Stack orientation="vertical" gap={40}>
        <Text size="M" block>
          {t('SaveQuery.Description')}
        </Text>
        <RadioGroup
          disabled={submitting}
          value={currentSaveMethod}
          onChange={e => setCurrentSaveMethod(e.target.value)}
        >
          <Radio value={SubmitMethod.Update}>
            <Text size="S" type="primary" bold>
              {t('SaveQuery.UpdateExisting')}
            </Text>
          </Radio>

          <Radio value={SubmitMethod.CreateNew}>
            <Text size="S" type="primary" bold>
              {t('SaveQuery.CreateNew')}
            </Text>
          </Radio>
        </RadioGroup>

        <Form.Item validateStatus={queryNameError ? 'error' : 'success'} help={queryNameError}>
          <Text size="S" type="primary" bold block>
            {t('SaveQuery.InputLabel')}
          </Text>
          {isUpdateExisting && (
            <Select
              loading={isLoading}
              placeholder={t('SaveQuery.InputPlaceholder')}
              value={queryId}
              onChange={value => {
                const qName = _.find(data, { id: value as string })?.name ?? ''
                setQueryName(qName)
                setQueryId(value as string)
              }}
            >
              {_.map(data, i => (
                <Option key={i.id}>{i.name}</Option>
              ))}
            </Select>
          )}
          {isCreateNew && (
            <Input
              disabled={submitting}
              placeholder={t('SaveQuery.InputPlaceholder')}
              value={queryName}
              onChange={onInputChangeHandler}
            />
          )}
        </Form.Item>
      </Stack>
    </Dialog>
  )
}
