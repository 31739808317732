import React from 'react'
import {
  Input as AntInput,
  InputNumber as AntInputNumber,
  InputNumberProps as AntInputNumberProps,
  InputProps as AntInputProps
} from 'antd'
import styled, { css } from 'styled-components/macro'
import _ from 'lodash'
import { isInRange } from '../../utils/validators'
import { formatNumberThousands } from '../../utils/formatters'
import { useLocaleNumberFormatter } from '../../hooks/useLocaleNumberFormatter'
import { NumericRange } from '../../types'

export type InputProps = AntInputProps

export const inputStyles = (removeHeight = false) => css`
  border-color: ${({ theme }) => theme.colors.paleGrey};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.brownGray};
  ${!removeHeight ? 'height: 40px' : ''};
  box-shadow: none;

  :hover {
    border-color: ${({ theme }) => theme.colors.paleGrey};
    box-shadow: 0 4px 8px 0 rgba(0, 60, 125, 0.2);
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.main};
    box-shadow: none;
  }

  input:focus {
    border: 1px solid ${({ theme }) => theme.colors.main};
  }

  .has-error &.ant-input {
    border-color: ${({ theme }) => theme.colors.watermelon};
    background-color: ${({ theme }) => theme.colors.paleGrey};

    :hover {
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }
  }
`

export const StyledInput = styled(AntInput)`
  &.ant-input {
    ${inputStyles()}
  }
`

const StyledInputNumber = styled(AntInputNumber)`
  .has-error &.ant-input-number {
    border-color: ${({ theme }) => theme.colors.watermelon};
    background-color: ${({ theme }) => theme.colors.paleGrey};

    :hover {
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }
    input:focus {
      border: none;
    }
  }
  width: auto;

  ${inputStyles()}
`

const InputWithIconWrapper = styled.div`
  position: relative;

  .icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`

type OurInputProps = {
  icon?: React.ReactNode
} & InputProps

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<any, OurInputProps>(({ icon, ...rest }, ref) => {
  if (icon) {
    return (
      <InputWithIconWrapper>
        <StyledInput {...rest} ref={ref} />
        {icon}
      </InputWithIconWrapper>
    )
  }

  return <StyledInput {...rest} ref={ref} />
})

const isPartialValidNumber = (stringNumber: string, decimalSeparator: string) => {
  const normalizedSeparator = decimalSeparator === '.' ? '\\.' : decimalSeparator
  // /${normalizedSeparator}\.(0*)$/ => if the number contains . with zeroes after, then it is partial
  return stringNumber === '-' || new RegExp(`${normalizedSeparator}(0*)$`).test(stringNumber) || stringNumber === ''
}

type InputNumberProps = Omit<AntInputNumberProps, 'onChange' | 'value'> & {
  onChange: (value: number, isPartial?: boolean) => void
  valueFromExpression: number
  value?: number
  range?: NumericRange
  shortenValueIfPossible?: boolean
}

export const InputNumber: React.FC<InputNumberProps> = ({
  value,
  onChange,
  onPressEnter,
  onBlur,
  range,
  shortenValueIfPossible,
  className,
  disabled,
  valueFromExpression
}) => {
  const { formatNumber, parseNumber, decimalSeparator } = useLocaleNumberFormatter()

  const [isFocused, setIsFocused] = React.useState(false)

  const isNumberValid = React.useCallback(
    (stringNumber: string) => {
      if (isPartialValidNumber(stringNumber, decimalSeparator)) return true
      const parsedValue = parseNumber(stringNumber)
      return !Number.isNaN(parsedValue)
    },
    [parseNumber, decimalSeparator]
  )

  const onChangeCallback = React.useCallback(
    (v: number | string | null) => {
      const val = v as number

      if (isNumberValid(val?.toString())) {
        const isPartial = isPartialValidNumber(val?.toString(), decimalSeparator)
        if (!isPartial) {
          if (!range) {
            onChange(val)
          } else if (isInRange(range, val)) {
            onChange(val)
          } else if (val > range.max) {
            onChange(range.max)
          } else {
            onChange(range.min)
          }
        }
      } else {
        onChange(val, !_.isNull(val))
      }
    },
    [onChange, isNumberValid, decimalSeparator, range]
  )

  const onPressEnterCallback = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (isNumberValid(value?.toString() ?? '')) {
        if (onPressEnter) onPressEnter(e)
      }
    },
    [value, isNumberValid, onPressEnter]
  )

  const onFocusCallback = React.useCallback(() => {
    setIsFocused(true)
  }, [])

  const onBlurCallback = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false)
      if (isNumberValid(value?.toString() ?? '')) {
        if (onBlur) onBlur(e)
      }
      onChange(valueFromExpression)
    },
    [isNumberValid, value, onChange, valueFromExpression, onBlur]
  )

  return (
    <StyledInputNumber
      className={className}
      min={Number.MIN_SAFE_INTEGER}
      max={Number.MAX_SAFE_INTEGER}
      disabled={disabled}
      controls={false}
      decimalSeparator={decimalSeparator}
      value={isFocused || !shortenValueIfPossible ? value : formatNumberThousands(value, formatNumber)}
      onChange={onChangeCallback}
      onPressEnter={onPressEnterCallback}
      onBlur={onBlurCallback}
      onFocus={onFocusCallback}
      formatter={(v, { input }) => {
        if (!isFocused && shortenValueIfPossible) return formatNumberThousands(value, formatNumber)
        if (_.isEmpty(input) && shortenValueIfPossible) return ''
        return formatNumber(v)
      }}
      parser={v =>
        parseNumber(
          _.replace(v?.toString() ?? '', /(?!^)-/g, '')
            .replace(/[a-zA-Z]/g, '')
            .replace(/\.+/g, '.')
            .replace(/,+/g, ',')
        )
      }
    />
  )
}

export const Password = styled(AntInput.Password)`
  border-style: none;
  box-shadow: none;

  ::before {
    display: none;
  }

  &.ant-input-password .ant-input {
    border-radius: 4px;
    padding: 4px 11px;
    ${inputStyles()}
  }
`

type TextAreaAdditionalProps = {
  noResize?: boolean
}

export const TextArea = styled(AntInput.TextArea).withConfig<TextAreaAdditionalProps>({
  shouldForwardProp: p => p !== 'noResize'
})`
  &.ant-input {
    height: auto;
    ${inputStyles(true)}
  }

  ${({ noResize }) => (noResize ? 'resize: none;' : '')}
`
