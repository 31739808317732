import _ from 'lodash'
import { ArrayCompare, KeyTree, NodeSelectionState, TreeKeySelection, TreePath } from './types'
import { compareArrays, getKeyTreeNode, hasNodeKeySelection, pushToPath } from './utils'

export const nodeSelectionStateMultiselectMultiValue = (
  keySelection: TreeKeySelection[],
  keySelectionItem: TreeKeySelection
): NodeSelectionState => {
  let result = NodeSelectionState.NotSelected
  const nodePath = pushToPath(keySelectionItem.parentPath, keySelectionItem.key)
  if (!nodePath) return result
  _.forEach(keySelection, item => {
    const itemPath = pushToPath(item.parentPath, item.key)
    if (compareArrays(nodePath, itemPath, true) === ArrayCompare.EQUAL) {
      result = NodeSelectionState.Selected
      return false
    }
    if (compareArrays(itemPath, nodePath) === ArrayCompare.STARTSWITH) {
      result = NodeSelectionState.Intermediate
    }
    return undefined
  })
  return result
}

const removeParentPath = (keySelection: TreeKeySelection[], parentPath: string[]) => {
  const newKeySelection = [...keySelection]
  for (let index = 0; index < _.size(parentPath); index += 1) {
    const currentPath = _.slice(parentPath, 0, _.size(parentPath) - index)
    const indexToRemove = _.findIndex(keySelection, {
      key: _.last(currentPath),
      parentPath: _.slice(currentPath, 0, _.size(currentPath) - 1)
    })
    delete newKeySelection[indexToRemove]
  }
  return _.compact(newKeySelection)
}

export const deselectNodeMultiselectMultiValue = (
  tree: KeyTree,
  keySelection: TreeKeySelection[],
  keySelectionItemToDeselect: TreeKeySelection
) => {
  const newKeySelection = [...keySelection]
  const stack = [keySelectionItemToDeselect]

  while (!_.isEmpty(stack)) {
    const current = stack.pop()
    if (current) {
      const indexToDelete = _.findIndex(newKeySelection, current)
      delete newKeySelection[indexToDelete]
      const nodePath = pushToPath(current.parentPath, current.key)
      const children = getKeyTreeNode(tree, nodePath)
      // eslint-disable-next-line lodash/prefer-map
      _.forEach(_.keys(children), childKey => {
        stack.push({ key: childKey, parentPath: nodePath })
      })
    }
  }

  return removeParentPath(_.compact(newKeySelection), keySelectionItemToDeselect.parentPath || [])
}

const checkForParentSelection = (
  tree: KeyTree,
  keySelection: TreeKeySelection[],
  parentPath: TreePath
): TreeKeySelection[] => {
  const newKeySelection = [...keySelection]

  for (let index = 0; index < _.size(parentPath); index += 1) {
    const currentPath = _.slice(parentPath, 0, _.size(parentPath) - index)
    const currentNode = getKeyTreeNode(tree, currentPath)
    const children = _.map(_.keys(currentNode), key => ({
      key,
      parentPath: currentPath
    }))
    const allChildrenIncluded = _.every(children, child => _.find(newKeySelection, child))
    if (allChildrenIncluded) {
      newKeySelection.push({
        key: _.last(currentPath) as string,
        parentPath: _.slice(currentPath, 0, _.size(currentPath) - 1)
      })
    } else {
      break
    }
  }

  return newKeySelection
}

export const selectNodeMultiselectMultiValue = (
  tree: KeyTree,
  keySelection: TreeKeySelection[],
  keySelectionItemToSelect: TreeKeySelection
) => {
  const newKeySelection = [...keySelection]
  const stack = [keySelectionItemToSelect]

  while (!_.isEmpty(stack)) {
    const current = stack.pop()
    if (current) {
      if (!hasNodeKeySelection(newKeySelection, current)) {
        newKeySelection.push(current)
      }
      const currentNodePath = pushToPath(current?.parentPath, current?.key)
      const currentNode = getKeyTreeNode(tree, currentNodePath)
      // eslint-disable-next-line lodash/prefer-map
      _.forEach(_.reverse(_.keys(currentNode)), childKey => {
        stack.push({ key: childKey, parentPath: currentNodePath })
      })
    }
  }
  return checkForParentSelection(tree, newKeySelection, keySelectionItemToSelect.parentPath)
}
