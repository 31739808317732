import _ from 'lodash'
import React from 'react'
import { FieldValues, FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getError, getErrorMessage } from '../../features/form/utils'
import { FormError } from '../../utils/validators'
import { FormItem } from './FormField'
import { HookTextArea, HookTextAreaProps } from './Input'

type FieldProps<T extends FieldValues = any> = {
  label?: string
  required?: boolean
  hideLabel?: boolean
  formState: FormState<any>
  validators?: Record<string, (value: any) => Promise<FormError | FormError[]> | FormError | FormError[] | undefined>
} & HookTextAreaProps<T>

export const TextAreaInputField = <T extends FieldValues>({
  hideLabel,
  label,
  required,
  name,
  formState,
  rules,
  validators,
  disabled,
  ...rest
}: FieldProps<T>) => {
  const { t } = useTranslation('error')

  const { errors, isSubmitting } = formState
  const errorMessage = getError(errors, name)

  let rulesOverride
  if (validators) {
    rulesOverride = _.reduce(validators, (acc, v, key) => ({ ...acc, [key]: getErrorMessage(v, label ?? '', t) }), {})
  }

  const isDisabled = disabled || isSubmitting

  return (
    <FormItem
      className="rf-field"
      label={!hideLabel ? label : undefined}
      colon={false}
      required={required}
      validateStatus={errorMessage ? 'error' : 'success'}
      help={errorMessage}
    >
      <HookTextArea
        name={name}
        aria-label={label}
        {...rest}
        rules={validators ? { validate: { ...rulesOverride } } : rules}
        disabled={isDisabled}
      />
    </FormItem>
  )
}
