import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { generatePath, useNavigate, useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useForm, useFormState } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Dialog } from '../../../components/Dialog'
import {
  EnrichCompaniesDialogData,
  EnrichCompaniesViewType,
  EnrichCompanyTargetPortfolio,
  EnrichSource,
  FormState
} from './types'
import { EnrichCompaniesRecap } from './EnrichCompaniesRecap'
import { EnrichCompanyComplete } from './EnrichCompanyComplete'
import { AssignOrCreatePortfolio } from './AssignOrCreatePortfolio'
import {
  isEnrichPortfolioSelectionData,
  isEnrichSingleCompanyDialogData,
  mapEnrichmentDataBasedOnSource
} from './utils'
import { BaseDialog } from '../../../pages/SalesTool/BaseDialog'
import KoImage from '../../../assets/images/ko.svg'
import { Text } from '../../../components/Text'
import { generateRefreshUrl } from '../../../utils/helpers'
import { COMPANY_REPORT } from '../../../routes'
import { useAfterEnrichmentHandler } from './hooks'
import { enrichMultipleCompany, enrichSingleCompany, useGetRecapData } from '../../../api/enrichment'
import { DialogActions } from '../../../features/dialog/actions'
import { getReportErrorHandler } from '../../../pages/CompanyReport/helpers'
import { ErrorMessageCode } from '../../../pages/CompanyReport/types'
import { ApplicationModule } from '../../../types'

function getBackButtonText(t: TFunction, viewType: EnrichCompaniesViewType) {
  if (viewType === EnrichCompaniesViewType.Recap) return t('common:Cancel')
  if (viewType === EnrichCompaniesViewType.AssignPortfolio) return t('common:Back')
  return undefined
}

type Props = {
  close: () => void
  data: EnrichCompaniesDialogData
}

export const EnrichCompaniesDialog: React.FC<Props> = ({ close, data }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['common', 'error', 'portfolio'])

  const [viewType, changeViewType] = React.useState(EnrichCompaniesViewType.Recap)
  const [isAlreadyInPortfolio, setIsAlreadyInPortoflio] = React.useState(false)
  const [errorMessageCode, setErrorMessageCode] = React.useState(ErrorMessageCode.None)

  const {
    data: enrichmentInfo,
    isLoading: enrichmentInfoLoading,
    isSuccess
  } = useGetRecapData(data, {
    onError: error => getReportErrorHandler(error, setErrorMessageCode),
    select: response => mapEnrichmentDataBasedOnSource(data.source, response)
  })
  const afterEnrichmentHandler = useAfterEnrichmentHandler(data, close)

  React.useEffect(() => {
    if (errorMessageCode !== ErrorMessageCode.None) {
      changeViewType(EnrichCompaniesViewType.NoEnrichment)
    }
    if (isSuccess && enrichmentInfo) {
      if (enrichmentInfo.remainingCreditsAfterCompaniesEnrichment < 0 || enrichmentInfo.withoutPortfolio > 0) {
        setIsAlreadyInPortoflio(false)
      } else {
        setIsAlreadyInPortoflio(true)
      }
    }
  }, [errorMessageCode, isSuccess, enrichmentInfo])

  const form = useForm<FormState>({
    defaultValues: {
      targetPortfolio: EnrichCompanyTargetPortfolio.ExistingPortfolio
    },
    mode: 'onChange'
  })
  const { isValid } = useFormState({ control: form.control })
  const mutation = useMutation<any, any, FormState>(
    async formData => {
      if (isEnrichSingleCompanyDialogData(data)) {
        return enrichSingleCompany(
          data.params.companyUnitId,
          formData.targetPortfolioId ??
            (data?.params?.applicationModule === ApplicationModule.PortfolioManagement ? data.params.portfolioId : ''),
          formData.newPortfolioName,
          isAlreadyInPortfolio,
          data?.params?.applicationModule ?? ApplicationModule.Prospecting
        )
      }
      if (isEnrichPortfolioSelectionData(data)) {
        return enrichMultipleCompany(data.params)
      }
      return Promise.reject(new Error('error'))
    },
    {
      onSuccess: () => {
        changeViewType(EnrichCompaniesViewType.Done)
        afterEnrichmentHandler()
      },
      onError: error => {
        if (error?.response?.status === 423) {
          dispatch(DialogActions.showDialog('PortfolioErrorLock'))
          return
        }
        changeViewType(EnrichCompaniesViewType.Fail)
      }
    }
  )

  const submitForm = form.handleSubmit(d => mutation.mutate(d))
  const closeAction = () => {
    close()
    if (viewType !== EnrichCompaniesViewType.Done) return
    if (data.onEnrichmentDone) data.onEnrichmentDone()
    if (data.source === EnrichSource.Search) {
      navigate({ pathname: generateRefreshUrl(location.pathname), search: location.search }, { state: location.state })
    }
    if (data.source === EnrichSource.ReportPopup) {
      navigate({ pathname: generatePath(COMPANY_REPORT, data.params) }, { state: location.state })
    }
  }
  const next = async () => {
    if (viewType === EnrichCompaniesViewType.Recap) {
      if (!isAlreadyInPortfolio) {
        changeViewType(EnrichCompaniesViewType.AssignPortfolio)
      } else if (enrichmentInfo?.companiesToEnrichCount === 0) {
        changeViewType(EnrichCompaniesViewType.Done)
        afterEnrichmentHandler()
      } else {
        submitForm()
      }
    }
    if (viewType === EnrichCompaniesViewType.AssignPortfolio) {
      submitForm()
    }
    if (
      viewType === EnrichCompaniesViewType.Done ||
      viewType === EnrichCompaniesViewType.Fail ||
      viewType === EnrichCompaniesViewType.NoEnrichment
    )
      closeAction()
  }
  const back = () => {
    if (viewType === EnrichCompaniesViewType.Recap) {
      closeAction()
    } else if (viewType === EnrichCompaniesViewType.AssignPortfolio) {
      changeViewType(EnrichCompaniesViewType.Recap)
    }
  }
  const okButtonDisabled =
    (enrichmentInfo && enrichmentInfo.remainingCreditsAfterCompaniesEnrichment < 0) || !isValid || enrichmentInfoLoading
  let okButtonText = isAlreadyInPortfolio ? t('common:Confirm') : t('common:Continue')
  if (viewType === EnrichCompaniesViewType.NoEnrichment) {
    okButtonText = t('common:Ok')
  }

  return (
    <Dialog
      loading={enrichmentInfoLoading || mutation.isLoading}
      visible
      destroyOnClose
      width={800}
      onCancel={closeAction}
      onOk={next}
      title={
        viewType === EnrichCompaniesViewType.NoEnrichment
          ? t('error:NotFound.EnrichmentNotPossible')
          : t('common:Enrich.Enrichment')
      }
      footer={
        viewType !== EnrichCompaniesViewType.Fail && viewType !== EnrichCompaniesViewType.NoEnrichment
          ? t('common:DoYouWantToContinue')
          : ''
      }
      cancelText={getBackButtonText(t, viewType)}
      okText={okButtonText}
      okButtonProps={{ disabled: okButtonDisabled }}
      cancelButtonProps={{ onClick: back }}
    >
      {viewType === EnrichCompaniesViewType.Recap && (
        <EnrichCompaniesRecap enrichmentInfo={enrichmentInfo} source={data.source} />
      )}
      {viewType === EnrichCompaniesViewType.AssignPortfolio && (
        <AssignOrCreatePortfolio form={form} submitForm={submitForm} />
      )}
      {viewType === EnrichCompaniesViewType.Done && <EnrichCompanyComplete enrichmentInfo={enrichmentInfo} t={t} />}
      {viewType === EnrichCompaniesViewType.Fail && (
        <BaseDialog
          image={<img src={KoImage} alt="ko" />}
          content={<Text size="L" text={t('error:EnrichCompany')} />}
        />
      )}
      {viewType === EnrichCompaniesViewType.NoEnrichment && (
        <BaseDialog
          image={<img src={KoImage} alt="ko" />}
          content={
            <Text
              size="L"
              text={
                errorMessageCode === ErrorMessageCode.HiddenCompany
                  ? t('error:NotFound.EnrichmentNotPossibleBody')
                  : t('error:NotFound.ReportLoadFailed')
              }
            />
          }
        />
      )}
    </Dialog>
  )
}
