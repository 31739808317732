import _ from 'lodash'
import { MapPositionData } from '../../types'
import { CampaignCompaniesMapReq, GetCampaignResponse } from '../../api/salestool/types'
import { Campaign, TaskStatistic } from '../salestool/types'
import { Task } from './types'
import { GoalTask } from '../../api/goals/types'
import { CompoundExpression } from '../operations/types'

export const mapCampaign = (data: GetCampaignResponse): Campaign => ({
  id: data.id,
  campaignName: data.campaignName,
  createdAt: data.createdAt,
  startDate: data.startDate,
  endDate: data.endDate,
  status: data.status,
  conversionRate: data.conversionRate,
  portfolios: _.map(data.portfolios, p => p.name),
  portfolioIds: _.map(data.portfolios, p => p.id),
  note: data.note,
  createUser: '',
  userDistinguishName: data.userDistinguishName,
  enableSalesPerformanceChart: data.enableSalesPerformanceChart,
  goalId: data.goalId,
  hasCustomVariables: data.hasCustomVariables
})

export const createCampaignCompaniesMapReq = (
  pos: MapPositionData,
  maxZoom: number,
  query: CompoundExpression | undefined
): CampaignCompaniesMapReq => {
  const request: CampaignCompaniesMapReq = {
    area: {
      northWest: {
        latitude: pos.bounds?.northWest.lat ?? 0,
        longitude: pos.bounds?.northWest.lng ?? 0
      },
      southEast: {
        latitude: pos.bounds?.southEast.lat ?? 0,
        longitude: pos.bounds?.southEast.lng ?? 0
      }
    },
    suppressClusters: pos.zoom >= maxZoom,
    zoom: pos.zoom,
    query
  }
  return request
}

export const mapTasksToObj = (tasks: TaskStatistic[] | undefined) =>
  _.reduce(
    tasks,
    (acc: Record<string, Task>, { taskId, taskName, position, count }: TaskStatistic) => ({
      ...acc,
      [taskId]: {
        id: taskId,
        name: taskName,
        count,
        position
      } as Task
    }),
    {}
  )

export const mapGoalTasksToObj = (tasks: GoalTask[] | undefined) =>
  _.reduce(
    tasks,
    (acc: Record<string, Task>, { id, name, position }: GoalTask) => ({
      ...acc,
      [id]: {
        id,
        name,
        count: 0, // TODO refactor after release - it is not used from this function
        position
      } as Task
    }),
    {}
  )
