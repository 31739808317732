import React from 'react'
import styled from 'styled-components/macro'
import { Dialog } from './Dialog'

export const FullPageDialogWrapper = styled(Dialog)<{ hideBodyPadding?: boolean }>`
  && {
    position: fixed !important;
    top: 0;
    padding: 0;
    margin: 0;
    background-color: #0000;
    width: 100vw !important;
    max-width: none;
    left: 0;

    .dialog-body-wrapper {
      padding: 0;
      height: 100%;
    }

    .ant-modal-close-x {
      display: none;
    }

    .ant-modal {
      position: static;
    }

    .ant-modal-content {
      border-top: 0;
      overflow: auto;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-spin-container {
      height: 100vh;
      min-height: 200px;
    }

    ${({ hideBodyPadding }) =>
      hideBodyPadding &&
      `
      .ant-card-body {
        padding: 0;
      }
    `}
  }
`

type Props = {
  children: React.ReactNode
  visible: boolean
  destroyOnClose?: boolean
  hideBodyPadding?: boolean
}

export const FullPageDialog = ({ children, visible, destroyOnClose, hideBodyPadding }: Props) => {
  return (
    <FullPageDialogWrapper
      loading={false}
      visible={visible}
      destroyOnClose={destroyOnClose}
      hideBodyPadding={hideBodyPadding}
    >
      {children}
    </FullPageDialogWrapper>
  )
}
