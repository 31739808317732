import axios from 'axios'
import { CompoundExpression } from '../../features/operations/types'
import {
  BaseUserItem,
  CustomerCompanyVisibilityBranch,
  CustomerCompanyVisibilityQuery,
  CustomerCompanyVisibilityResponse,
  DeleteConfigurationRequest,
  GetCustCompanyVisibilityRequest,
  GetUsersByDistinguishNameRequest,
  ConfigurationVisibilityType,
  CompanyVisibilities,
  CustomerCompanyVisibilityStructure
} from './types'

export const getCustomerCompaniesVisibility = (params?: GetCustCompanyVisibilityRequest) =>
  axios.get<CustomerCompanyVisibilityResponse>('/api/CustomerCompanyVisibility', { params }).then(resp => resp.data)

export const deleteCustomerCompanyVisibility = (configurationId: string) =>
  axios.delete<DeleteConfigurationRequest>(`/api/CustomerCompanyVisibility/${configurationId}`)

export const editCustomerCompanyVisibility = (configurationId: string, query?: CompoundExpression) =>
  axios.put<CustomerCompanyVisibilityResponse>(`/api/CustomerCompanyVisibility/${configurationId}`, {
    query
  })

export const getCustomerCompanyVisibilityQuery = (configurationId: string) =>
  axios.get<CustomerCompanyVisibilityQuery>(`/api/CustomerCompanyVisibility/${configurationId}`)

export const getCustomerCompanyVisibilityBranches = () =>
  axios.get<CustomerCompanyVisibilityBranch[]>('/api/CustomerCompanyVisibility/branches').then(res => res.data)

export const getUsersByDistinguishName = (params: GetUsersByDistinguishNameRequest) =>
  axios.get<BaseUserItem[]>('/api/CustomerCompanyVisibility/users', { params }).then(resp => resp.data)

export const getCompanyVisibilityFiltersDefinitions = () =>
  axios
    .get<CustomerCompanyVisibilityStructure[]>('/api/CustomerCompanyVisibility/filters-definitions')
    .then(resp => resp.data)

export const postCustomerCompanyVisibility = (
  queryType: ConfigurationVisibilityType,
  companyVisibilities: CompanyVisibilities[],
  query?: CompoundExpression
) =>
  axios.post('/api/CustomerCompanyVisibility', {
    queryType,
    companyVisibilities,
    query
  })
