import _ from 'lodash'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { SortItem } from '../../types'
import { getGoals, getGoal } from './api'
import { cacheAllTheTime } from '../../features/queryclient'

export const getGoalsKeys = {
  All: () => [{ level1: 'goals' }] as const,

  GetGoals: () => [{ ...getGoalsKeys.All()[0], level2: 'getGoals' }] as const,

  GetInfiniteGoalsWithParameters: (top: number, sorting: SortItem | undefined) =>
    [{ ...getGoalsKeys.GetGoals()[0], level3: 'getInfiniteGoals', top, sorting }] as const,

  GetGoal: (goalId: string | undefined) => [{ ...getGoalsKeys.All()[0], level2: 'getGoal', goalId }] as const
}

export const useGetGoals = () => useQuery(getGoalsKeys.GetGoals(), () => getGoals())

export const useGetInfiniteGoals = (top: number, sorting?: SortItem) =>
  useInfiniteQuery(
    getGoalsKeys.GetInfiniteGoalsWithParameters(top, sorting),
    ({ queryKey: [queryKeys], pageParam = 0 }) => getGoals(pageParam * queryKeys.top, queryKeys.top, queryKeys.sorting),
    {
      getNextPageParam: (lastPage, allPages) => {
        const count = _.sum(_.map(allPages, p => p.items.length))
        return lastPage.totalCount <= count ? undefined : allPages.length
      }
    }
  )

export const useGetGoal = (goalId: string | undefined) =>
  useQuery(
    getGoalsKeys.GetGoal(goalId),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.goalId === undefined) return undefined
      return getGoal(queryKeys.goalId)
    },
    {
      ...cacheAllTheTime
    }
  )
