import { Button } from 'antd3'
import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import UploadIcon from '../../assets/icons/upload.svg'

const HiddenFileInput = styled.input`
  display: none;
`

const Upload = styled.img`
  font-size: 24px;
`

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectFile?: string
  disabled?: boolean
}

export const FileInput: React.FC<Props> = ({ selectFile, onChange, disabled }) => {
  const fileInput = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    if (!disabled && fileInput) {
      fileInput?.current?.click()
    }
  }

  return (
    <>
      <HiddenFileInput type="file" accept=".xlsx, .xls" ref={fileInput} onChange={onChange} />

      <Button
        type={!selectFile ? 'link' : undefined}
        className="upload-button"
        size="large"
        onClick={handleUploadClick}
      >
        {selectFile} <Upload src={UploadIcon} />
      </Button>
    </>
  )
}
