import { ActionWithPayload } from '../../utils/types'
import { RootState } from '../types'

export type ActionTrackingFunction<P = any> = (
  state: RootState,
  action: ActionWithPayload<any, P>,
  trackGA: boolean,
  trackMatomo: boolean
) => void
export type ActionTrackingMap = Record<string, ActionTrackingFunction>

export enum EventCategory {
  Prospecting = 'Prospecting',
  PortfolioDetail = 'PortfolioDetail',
  SalesTool = 'SalesTool',
  Targeting = 'Targeting',
  Operations = 'Operations',
  Search = 'Search',
  Pnrr = 'Pnrr',
  Appointments = 'Appointments',
  Unknown = 'Unknown'
}

export enum EventAction {
  Search = 'Search',
  SemanticSearch = 'SemanticSearch',
  SemanticSearchAdvanced = 'SemanticSearchAdvanced',
  GroupAction = 'GroupAction',
  Filtering = 'Filtering'
}

export enum GroupActionType {
  Enrichment = 'GroupActionEnrichment',
  DeleteCompany = 'GroupActionDeleteCompany',
  SavePortfolio = 'GroupActionSavePortfolio',
  DownloadExcel = 'GroupActionDownloadExcel',
  AddLocalUnits = 'GroupActionAddLocalUnits',
  SaveList = 'GroupActionSaveList',
  AssignAreaManager = 'GroupActionAssignAreaManager',
  AssignSales = 'GroupActionAssignSales',
  AssignProduct = 'GroupActionAssignProducts',
  MoveCompany = 'GroupActionMoveCompany'
}

export enum CallToActionType {
  CreateCampaign = 'CreateCampaign',
  ManageCampaign = 'ManageCampaign',
  SharePortfolio = 'SharePortfolio',
  ExcludePortfolio = 'ExcludePortfolio',
  CreateOffer = 'CreateOffer',
  ContactCustomerOnline = 'ContactCustomerOnline',
  DownloadExcel = 'DownloadExcel',
  DownloadPdf = 'DownloadPdf',
  AddLocalUnits = 'AddLocalUnits',
  SaveList = 'SaveList',
  SaveAndMonitorList = 'SaveAndMonitorList',
  MoveCompany = 'MoveCompany',
  DeleteCompany = 'DeleteCompany',
  GenerateSimilarity = 'GenerateSimilarity',
  GenerateSimilarityWithSemanticCluster = 'GenerateSimilarityWithSemanticCluster',
  AnalyzePortfolio = 'AnalyzePortfolio',
  SemanticClusterLabelPrefill = 'SemanticClusterLabelPrefill',
  SemanticClusterSelection = 'SemanticClusterSelection',

  // PNRR
  PnrrWizard = 'PnrrWizard',
  EasyFinanceSendPortfolio = 'EasyFinanceSendPortfolio',

  // Appointments
  CreateAppointment = 'CreateAppointment',
  EditAppointment = 'EditAppointment'
}
