import { useMutation, useQueryClient } from '@tanstack/react-query'
import { archiveCampaign, updateCampaign } from './api'
import { getSalestoolKeys } from './queries'
import { ArchiveCampaignRequest, UpdateCampaignRequest } from './types'

export const useUpdateCampaign = (campaignId: string, request: UpdateCampaignRequest) => {
  const queryClient = useQueryClient()
  return useMutation(() => updateCampaign(campaignId, request), {
    onSuccess: () => {
      queryClient.invalidateQueries(getSalestoolKeys.GetCampaign(campaignId))
    }
  })
}

export const useArchiveCampaign = (campaignId: string, request: ArchiveCampaignRequest) => {
  const queryClient = useQueryClient()
  return useMutation(() => archiveCampaign(campaignId, request), {
    onSuccess: () => {
      queryClient.invalidateQueries(getSalestoolKeys.GetCampaign(campaignId))
    }
  })
}
