import _ from 'lodash'
import React, { useContext } from 'react'
import { init } from './filters/case-functions/semanticSearch'
import { SemanticSearch } from './filters/types'
import { CompoundExpression } from './operations/types'
import { getDefaultRootExpression } from './operations/utils'

// TODO: move to better place
export type BasedOn = {
  id?: string
  name?: string
}

export type CommonModuleContextType = {
  expression: CompoundExpression
  setExpression: React.Dispatch<React.SetStateAction<CompoundExpression>>
  orderByKeys?: string[]
  setOrderByKeys: (orderByKeys: string[] | undefined) => void
  excludedPortfoliosIds?: string[]
  setExcludedPortfoliosIds: (ids: string[]) => void
  selectedPortfoliosIds?: string[]
  setSelectedPortfoliosIds: (ids: string[]) => void
  semanticSearch: SemanticSearch
  setSemanticSearch: (semantic: SemanticSearch) => void
  querySaved: boolean
  setQuerySaved: (saved: boolean) => void
  basedOn?: BasedOn
  setBasedOn: (basedOn: BasedOn | undefined) => void
}

export const DefaultCommonModuleContext = {
  expression: getDefaultRootExpression(),
  setExpression: _.noop,
  orderByKeys: undefined,
  setOrderByKeys: _.noop,
  excludedPortfoliosIds: [],
  setExcludedPortfoliosIds: _.noop,
  selectedPortfoliosIds: [],
  setSelectedPortfoliosIds: _.noop,
  semanticSearch: init,
  setSemanticSearch: _.noop,
  querySaved: false,
  setQuerySaved: _.noop,
  basedOn: undefined,
  setBasedOn: _.noop
}

export const CommonModuleContext = React.createContext<CommonModuleContextType>(DefaultCommonModuleContext)

export const resetCommonModuleContext = (context: CommonModuleContextType) => {
  context.setQuerySaved(DefaultCommonModuleContext.querySaved)
  context.setBasedOn(DefaultCommonModuleContext.basedOn)
  context.setOrderByKeys(DefaultCommonModuleContext.orderByKeys)
  context.setExpression(DefaultCommonModuleContext.expression)
  context.setExcludedPortfoliosIds(DefaultCommonModuleContext.excludedPortfoliosIds)
  context.setSelectedPortfoliosIds(DefaultCommonModuleContext.selectedPortfoliosIds)
  context.setSemanticSearch(DefaultCommonModuleContext.semanticSearch)
}

export const useCommonModuleContext = () => {
  return useContext(CommonModuleContext)
}
