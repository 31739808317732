/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Back: 'Back',
  Continue: 'Continue',
  Search: 'Search',
  Cancel: 'Cancel',
  Ok: 'Ok',
  Save: 'Save',
  Delete: 'Delete',
  Move: 'Move',
  Edit: 'Edit',
  Rename: 'Rename',
  Add: 'Add',
  Note: 'Note',
  Select: 'Select',
  Hide: 'Hide',
  Show: 'Show',
  Visualise: 'Visualise',
  HideDetail: 'Hide detail',
  ShowDetail: 'Show detail',
  Duplicate: 'Duplicate',
  Download: 'Download',
  Collapse: 'Reduce',
  Loading: 'Loading',
  // COMPANY STATUS
  Prospect: 'Prospect',
  Lead: 'Lead',
  Client: 'Client',
  Supplier: 'Supplier',
  Competitor: 'Competitor',
  RangeSelected: 'Range selected',
  Close: 'Close',
  ViewDetails: 'View details',
  SearchResults: {
    ResultsFor: 'Results for',
    ExcludeHiddenPortfolio: ', excluding companies belonging to hidden portfolios.',
    SendToSemanticSearch: 'Send to Semantic Search',
    SendToSemanticSearchToolTip: 'Search for the keyword in the Semantic Search'
  },
  NumericRange: {
    DataNotAvailable: 'Data not available for the companies in scope',
    InvalidValue: 'Value is not valid.',
    IntegersOnly: 'Only integers are allowed.',
    MinGreaterThanMax: 'Mininum value is greater than maximum.',
    MaxLessThanMin: 'Maximum value is lower than minimum.',
    MinOutOfrange: 'Minimum value is out of range.',
    MaxOutOfrange: 'Maximum value is out of range.'
  },
  DateRange: {
    From: 'From',
    To: 'To'
  },
  DomainFilter: {
    SaveAndClose: 'Save filters & close',
    SeeAll: 'All {{count}} filters',
    DeleteAll: 'Delete all',
    Multiselection: 'multiselection',
    SearchExact: 'Search exact description'
  },
  MapFilter: {
    ChoosePoint: 'Choose a point directly on the map',
    Range: 'Range',
    SelectedCircle: '{{distance}} from {{address}}',
    SelectedPolygon: 'Area selected on map',
    Address: 'Address',
    CompanyName: 'Company Name',
    FindMe: 'Find me on map',
    SearchFromHere: 'Search starting from this point',
    GeolocationErrorTitle: 'An error has occurred',
    GeolocationError: 'This option is not available at the moment.'
  },
  Reset: 'Reset',
  SelectAll: 'Select all',
  DeselectAll: 'Deselect all',
  DoYouWantToContinue: 'Do you want to continue?',
  NotSwitch: 'Not',
  Yes: 'Yes',
  No: 'No',
  On: 'On',
  Off: 'Off',
  LoadMore: 'Load more',
  SeeAll: 'See all',
  HideAll: 'Collapse',
  Filters: {
    NoFilters: "You haven't selected any filters!",
    Select: 'Select filter',
    Filters: 'Filters',
    SemanticClustersInfo: 'Try the new Margo Semantic Clusters Filter',
    SemanticClustersDeepActionInfo: 'Refine your search with new Margo Semantic Clusters filter',
    EditFilters: 'Edit filters to update the list',
    StartNewSearch: 'Start new search',
    ResetFilters: 'Reset all filters',
    UploadList: 'Upload list',
    UploadListTooltip: 'Insert one or more values separated by a space.',
    MostPopularFilters: 'Most popular filters',
    MostPopularFiltersText: 'Click here to activate them'
  },
  InsertTheNumber: 'Insert the number',
  OrderBy: 'Order by',
  BackToLogin: 'Back to login',
  SimpleUsefulPowerful: 'Simple, useful and powerful.',
  MargoWasSurprise: 'Margò was a wonderful surprise for me and my team.',
  Targeting: 'Analyze market',
  Prospecting: 'Find prospects',
  PortfolioManagement: 'Portfolio management',
  Actions: 'Actions',
  GroupActions: {
    GroupActions: 'Group Actions',
    Actions: 'Actions',
    Select: 'Select',
    ToggleTooltip:
      'Toggle Group Actions to select the companies you want by applying the checkmark, or make a multiple selection. You can then save the selected companies from the Actions menu. You will not be able to select any Call to Action.',
    ButtonDisabledByGroupActions:
      'By activating Group Actions, you will not be able to select any Call to Action. To use these buttons turn off the Group Actions switch.',
    DeactivateToggleTooltip: 'Deactivate Group Actions to be able to use Call to Action buttons at the top of the page.'
  },
  Confirm: 'Confirm',
  IsEnriched: 'Enriched',
  Logout: 'Logout',
  You: 'You',
  Legend: 'Legend',
  Enrich: {
    Enrichment: 'Enrichment',
    RecapDesc:
      'By enriching the company with {{companyName}} data, these information will be downloaded and will be updated periodically.',
    CompaniesToBeEnriched: 'Company to be enriched',
    CompaniesToBeEnriched_plural: 'Companies to be enriched',
    PortfolioInfo1: 'This company is not present in Portfolio management section. Add it to portfolio.',
    PortfolioInfo2: 'You are saving the {{companyName}} data, the company will be already enriched.',
    SelectPortfolio: 'Select portfolio',
    UploadedPortfolio: 'Uploaded portfolio',
    CreateNewPortfolio: 'Create new portfolio',
    PortfolioName: 'Portfolio name',
    EnrichmentComplete: 'Enrichment complete'
  },
  ConfigGuardErrorPage: {
    Reload: 'Reload',
    Subtitle: 'Application config could not be loaded.',
    Title: 'We are sorry'
  },
  Uploaded: 'Uploaded',
  Date: 'Date',
  Page: 'Page',
  Validating: 'Validating',
  AutoLogout: {
    Title: 'Automatic logout',
    Text: 'Because of your inactivity you will be automaticaly logged out in {{count}} second',
    Text_plural: 'Because of your inactivity you will be automaticaly logged out in {{count}} seconds'
  },
  ForceLogout: {
    Title: 'User logged out',
    Desc: 'Your user was logged out because the session ended or a new session was initiated via another browser or device.'
  },
  ImprintPolicy: 'Impressum',
  PrivacyPolicy: 'Privacy policy',
  CookiesPolicy: 'Cookies policy',
  SelectDate: 'Select date',
  queries: {
    loadQuery: {
      dialogTitle: 'Load saved search',
      OkBtn: 'Load',
      title: 'Select saved search',
      description: 'Choose which analysis you want to continue, you can resume the analysis from one of the saved ones',
      portfolioRadio: 'Portfolio Management query',
      targetingRadio: 'Analyze Market query',
      prospectingRadio: 'Prospecting query',
      selectTitle: 'Saved query'
    }
  },
  SaveList: {
    CreateTagTitle: 'Create tag',
    CreateTagLabel: 'Create tag (optional)',
    CreateTagDesc: 'Each company will be defined by a tag that you create',
    InsertTag: 'Insert tag',
    SaveList: 'Save list',
    SaveOrCreate: 'Do you want to add the list to a portfolio or create a new one?',
    SelectPortfolio: 'Select portfolio',
    SaveListDesc: 'The list will be saved automatically with the status "Prospect" in the "Portfolio Management" area',
    UploadedPortfolio: 'Updated portfolio',
    CreateNew: 'Create new',
    SelectNumberOfCompanies:
      'Select the number of companies you want to add to your portfolio and order them by one of the criteria in the list.',
    NumberOfCompanies: 'Number of companies',
    BackToFullList: 'Back to full list',
    SavingFullList: 'You are saving the full list of companies',
    OrderByOptions: {
      Turnover: 'Turnover',
      TurnoverEur: 'Turnover (EUR)',
      TurnoverCzk: 'Turnover (CZK)',
      NOfEmployees: 'N° of employees',
      EmployeeRange: 'Employee Range',
      Ebit: 'Ebit'
    },
    SendToSalesInfo: 'Before sending list to sales tool you need to create portfolio from the list.',
    Enrichment: {
      Enrichment: 'Enrichment',
      EnrichmentComplete: 'Enrichment completed',
      EnrichmentDescription:
        'By enriching the company with the {{companyName}} data, these information will be downloaded and will be updated periodically',
      CompaniesInList: 'Company in this list',
      CompaniesInList_plural: 'Companies in this list',
      CompaniesEnriched: 'Company already enriched',
      CompaniesEnriched_plural: 'Companies already enriched',
      CompaniesNotEnriched: 'Company not enriched',
      CompaniesNotEnriched_plural: 'Companies not enriched',
      CreditsWillBeUsed: 'Ring used',
      CreditsWillBeUsed_plural: 'Rings used',
      CreditsRemaining: 'Remaining ring',
      CreditsRemaining_plural: 'Remaining rings'
    },
    SaveListMonitorDesc:
      'In case of monitored portfolio you can find the query saved in the "Prospecting" section of your account.',
    Tag: 'Tag'
  },
  ExcludePortfolio: {
    BasedOn: 'Based on',
    SelectedPortfolios: 'Selected portfolios',
    AllCompanyInCrifIta: 'All Italian companies',
    AllCompanyInCrifDeu: 'All German companies',
    AllCompanyInCrifCzsk: 'All available companies',
    AllCompanyInCrifFareast: 'All available companies',
    AllCompanyInCrifChe: 'All Swiss companies',
    AllCompanyInCrifAut: 'All Austrian companies',
    AllCompanyInCrifCheAut: 'All Austrian and Swiss companies',
    ExcludePortfolio: 'Exclude portfolio',
    ExcludeCampaign: 'Exclude campaign',
    ExcludeEnrichedCompanies: 'Exclude enriched companies',
    ExcludeHiddenPortfolio: 'excluding those reported in hidden portfolios',
    HiddenPortfolioBasedOn: 'Based on',
    Configuration: 'the configuration set on your account',
    AndConfiguration: 'and the configuration set on your account'
  },
  User: {
    Role: {
      Supervisor: 'Supervisor',
      AreaManager: 'Area Manager',
      Sales: 'Sales'
    },
    UserName: 'User name'
  }
}
