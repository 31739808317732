import { useInfiniteQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { getDocuments } from './api'
import { DocumentType } from '../../features/documents/types'
import { TableColumnSorting } from '../../features/tables/types'

export const getDocumentsKeys = {
  All: () => [{ level1: 'documents' }] as const,
  GetDocumentsByTypes: (documentTypes: DocumentType[]) =>
    [{ ...getDocumentsKeys.All()[0], level2: 'getDocuments', documentTypes }] as const,
  GetDocumentsWithData: (documentTypes: DocumentType[], top: number, sort: TableColumnSorting | undefined) =>
    [{ ...getDocumentsKeys.GetDocumentsByTypes(documentTypes)[0], top, sort }] as const
}

export const useGetDocuments = (
  documentTypes: DocumentType[],
  top: number,
  sort?: TableColumnSorting,
  enabled?: boolean
) =>
  useInfiniteQuery(
    getDocumentsKeys.GetDocumentsWithData(documentTypes, top, sort),
    ({ queryKey: [queryKeys], pageParam = 0 }) => {
      return getDocuments({
        top: queryKeys.top,
        skip: pageParam,
        'filter.types': queryKeys.documentTypes,
        sort: queryKeys.sort
          ? [
              {
                property: queryKeys.sort.field,
                order: queryKeys.sort.order === 'ascend' ? 'Asc' : 'Desc'
              }
            ]
          : undefined
      })
    },
    {
      getNextPageParam: (lastResp, allPages) => {
        const count = _.sum(_.map(allPages, 'items.length'))
        return lastResp?.totalCount <= count ? undefined : count
      },
      refetchOnMount: true,
      enabled
    }
  )
