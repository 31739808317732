import React from 'react'
import { Dropdown, Menu } from 'antd3'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { ReactComponent as Triangle } from '../../assets/icons/triangle-down.svg'
import { UserActions } from '../../features/user/actions'
import { getHasMulticurrencySupport, getAvailableCurrenciesSelector } from '../../features/currencies/selectors'
import { getSelectedCurrency } from '../../features/user/selectors'

export const StyledTriangleIcon = styled(Triangle).withConfig<{ primary?: boolean }>({
  shouldForwardProp: p => p !== 'primary'
})`
  width: 8px;
  height: 9px;
  display: inline-block;
  margin-left: 8px;
  fill: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
`

export const StyledCurrencyButton = styled(Button).withConfig<{ primary?: boolean }>({
  shouldForwardProp: p => p !== 'primary'
})`
  flex: 0 0 auto;
  align-items: center;
  && {
    color: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
    :hover,
    :focus {
      color: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
    }
  }
`

type Props = {
  primary?: boolean
  className?: string
}

export const CurrencySwitch = ({ primary, className }: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const isMulticurrencySupported = useSelector(getHasMulticurrencySupport)
  const currencies = useSelector(getAvailableCurrenciesSelector)
  const currentCurrencyKey = useSelector(getSelectedCurrency)
  if (!isMulticurrencySupported) return null
  const currentCurrency = _.find(currencies, { code: currentCurrencyKey })

  const menu = (
    <Menu onClick={({ key }) => dispatch(UserActions.changeCurrency(key, true, location, navigate))}>
      {_.map(currencies, value => (
        <Menu.Item key={value.code}>{value.symbol}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlayClassName="language-switch" className={className} overlay={menu} trigger={['click', 'hover']}>
      <StyledCurrencyButton
        ghost={primary}
        primary={primary}
        type="link"
        textSize="S"
        iconRight={<StyledTriangleIcon primary={primary} />}
      >
        {currentCurrency?.symbol}
      </StyledCurrencyButton>
    </Dropdown>
  )
}
