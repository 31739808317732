import _ from 'lodash'
import styled from 'styled-components/macro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Menu } from 'antd3'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import { ReactComponent as Triangle } from '../../assets/icons/triangle-down.svg'
import { UserActions } from '../../features/user/actions'
import { getAvailableLanguages } from '../../features/config/selectors'

export const StyledTriangleIcon = styled(Triangle).withConfig<{ primary?: boolean }>({
  shouldForwardProp: p => p !== 'primary'
})`
  width: 8px;
  height: 9px;
  display: inline-block;
  margin-left: 8px;
  fill: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
`

export const StyledLanguageButton = styled(Button).withConfig<{ primary?: boolean }>({
  shouldForwardProp: p => p !== 'primary'
})`
  flex: 0 0 auto;
  align-items: center;
  && {
    color: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
    :hover,
    :focus {
      color: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
    }
  }
`

type Props = {
  primary?: boolean
  className?: string
}

export const LanguageSwitch = ({ primary, className }: Props) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const availableLanguages = useSelector(getAvailableLanguages)

  const currentLang = availableLanguages[i18n.language]?.short
  const menu = (
    <Menu onClick={({ key }) => dispatch(UserActions.changeLanguage(key, true, location, navigate))}>
      {_.map(availableLanguages, (value, key) => (
        <Menu.Item key={key}>{value.short}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlayClassName="language-switch" className={className} overlay={menu} trigger={['click', 'hover']}>
      <StyledLanguageButton
        ghost={primary}
        primary={primary}
        type="link"
        textSize="S"
        iconRight={<StyledTriangleIcon primary={primary} />}
      >
        {currentLang}
      </StyledLanguageButton>
    </Dropdown>
  )
}
