import axios, { CancelTokenSource, AxiosInstance } from 'axios'
import _ from 'lodash'
import { Dictionary } from '../../utils/types'

export class RequestBatchesManager {
  private static batches: Dictionary<CancelTokenSource> = {}

  static add(batchId: string) {
    if (!this.batches[batchId]) {
      this.cancelPreviousRequestAndDeleteToken(batchId)
      const cancelTokenSource = axios.CancelToken.source()
      this.batches[batchId] = cancelTokenSource
      return cancelTokenSource
    }
    return this.batches[batchId]
  }

  static cancelAllRequests() {
    _.forEach(_.values(this.batches), cancelTokenSource => {
      cancelTokenSource.cancel()
    })
    this.batches = {}
  }

  private static cancelPreviousRequestAndDeleteToken(currentBatchId: string) {
    const batchEntries = _.toPairs(this.batches)
    const batchesToCancel = _.filter(batchEntries, ([batchId]) => batchId !== currentBatchId)
    _.forEach(batchesToCancel, ([batchId, cancelTokenSource]) => {
      cancelTokenSource.cancel()
      _.unset(this.batches, [batchId])
    })
  }
}

export const setAuthorizationHeader = (axiosInstance: AxiosInstance, token?: string) => {
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.headers.Authorization = token ?? null
}

export const ACCEPT_CURRENCY_HEADER = 'X-Accept-Currency'

export const setCurrency = (axiosInstance: AxiosInstance, currency = '') => {
  if (currency === '') {
    // eslint-disable-next-line no-param-reassign
    delete axiosInstance.defaults.headers[ACCEPT_CURRENCY_HEADER]
    return
  }
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.headers[ACCEPT_CURRENCY_HEADER] = currency
}
