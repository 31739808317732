import { CommonModuleContextType } from '../context'

export enum CampaignStatus {
  ToAssign = 'ToAssign',
  ToStart = 'ToStart',
  Active = 'Active',
  Closed = 'Closed',
  Stopped = 'Stopped',
  Expired = 'Expired'
}

export const campaignStatusArchived = 'Archived'

export enum ViewIntegrationStatus {
  NotAlreadyDone = 'NotAlreadyDone',
  InProgress = 'InProgress',
  Ok = 'Ok',
  Ko = 'Ko'
}

export enum ContactClientActionType {
  apparoundCreateOffer = 'apparoundCreateOffer',
  inventiaContactCustomer = 'inventiaContactCustomer'
}

export enum ServerIntegrationStatus {
  None = 'None',
  Created = 'Created',
  InProgress = 'InProgress',
  Notified = 'Notified',
  Failed = 'Failed',
  Expired = 'Expired',
  Complete = 'Complete'
}

export type TaskStatistic = {
  taskId: string
  taskName: string
  count: number
  percentage: number
  position: number
}

export type SalesStatistics = {
  salesId: string
  saleName: string
  taskId: string
  taskName: string
  position: number
  count: number
  lastUpdate: string
  percentage: number
}

export type CampaignStatistics = {
  assignedCompaniesCount: number
  companiesCount: number
  salesStatistics: SalesStatistics[]
  tasksStatistics: TaskStatistic[]
  unassignedCompaniesCount: number
}

export type Campaign = {
  id: string
  campaignName: string
  createdAt: string
  startDate: string
  endDate: string
  status: CampaignStatus
  conversionRate: number
  createUser: string
  portfolios: string[]
  portfolioIds: string[]
  note: string
  userDistinguishName: string
  enableSalesPerformanceChart: boolean
  goalId: string
  hasCustomVariables: boolean
}

export type CompanyNote = {
  createUser?: string
  createTimestamp?: string
  id: string
  note: string
  createUserId: string
}

export type CampaignCompany = {
  entityId: string
  margoId: string
  companyUnitId: string
  companyName: string
  companyState?: string
  address?: string
  saleUserId?: string
  amount?: number
  currentTask?: string
  notes: CompanyNote[]
  // TODO remove fields and swap to cells because it is obsolete
  cells: Record<string, any>
  fields: Record<string, any>
  identificationCode: string
  campaignCompanyId: string
}

export type Product = {
  id: string
  name: string
  createdAt?: string
}

export type Sale = {
  id: string
  username: string
  name: string
  isActive?: boolean
}

export type SalesToolViewType = 'campaigns' | 'sales'

export enum ErrorTypes {
  renewCampaign = 'RenewCampaign',
  saveCampaign = 'SaveCampaign',
  duringCampaignCreation = 'DuringCampaignCreation',
  fetchCompanies = 'FetchCompanies',
  saveAssignmentsGeneric = 'AssignCampaignError',
  saveAssignmentsDuplicate = 'AssignCampaignErrorCompaniesDuplicated',
  fetchCampaigns = 'FetchCampaigns'
}

export type SalestoolContextTypePure = {}

export type SalestoolContextType = SalestoolContextTypePure & CommonModuleContextType
