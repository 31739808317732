import debounce from 'debounce-promise'
import { validateCampaign } from './api'
import { NAME_VALIDATION_DEBOUNCE_TIME } from '../../features/constants'
import { takeLatestPromise } from '../../utils/promise'

export const validateNewCampaignName = debounce(
  takeLatestPromise(async (newCampaignName: string) => {
    const res = await validateCampaign({ campaignName: newCampaignName })
    if (!res.data.isValid) {
      const errors = res.data.resultDescription
      return errors
    }
    return undefined
  }),
  NAME_VALIDATION_DEBOUNCE_TIME
)
