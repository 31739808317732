/* eslint-disable import/no-default-export */
export default {
  Support: {
    Email: 'helpdesk.sk@crif.com',
    Phone: '+421 (02) 5920 7537'
  },
  PrivacyPolicy: 'Zásady ochrany osobných údajov',
  CookiesPolicy: 'Pravidlá používania cookies',
  Contact: 'Kontaktujte nás'
}
