import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSaveBeforeContinuing, SaveBeforeContinuingDialog } from '../SaveBeforeContinuing'
import { DialogPropsType } from '../types'
import { LoadQueryDialog } from '../../../../containers/Query/LoadQueryDialog'
import { QueryType } from '../../../../types'
import { Query } from '../../../../features/query/types'
import { TARGETING_MARKET_ANALYSIS } from '../../../../routes'

export const LoadQueryDialogContainer = ({
  data: { setQuerySaved, contextData, canSaveQuery },
  close
}: DialogPropsType) => {
  const navigate = useNavigate()

  const [saveSkipped, dialogProps] = useSaveBeforeContinuing('LoadTargetingQuery', contextData)

  const submit = (query?: Query) => {
    if (!query?.id) return

    setQuerySaved(true)
    navigate({ pathname: TARGETING_MARKET_ANALYSIS }, { state: { query } })
    close()
  }

  if (!saveSkipped && canSaveQuery) {
    return <SaveBeforeContinuingDialog {...dialogProps} />
  }

  return <LoadQueryDialog submit={submit} close={close} defaultQueryType={QueryType.TargetingQueries} />
}
