import { createSelector } from 'reselect'
import { RootState } from '../types'

const selectState = (state: RootState) => state.views.saleDetail

export const getHiddenTaskPopupExpirationDate = createSelector(
  selectState,
  state => state.hiddenTaskPopupExpirationDate
)

export const canRefreshCampaign = createSelector(selectState, state => state.canRefreshCampaign)
