import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import { Text } from '../Text'
import { InfoBanner } from './InfoBanner'
import ProspectingImage from '../../assets/icons/prospecting.svg'

const BannerWrapper = styled(InfoBanner)`
  min-height: 144px;
  overflow: hidden;
  padding: 24px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lg`
    display: block;
    padding-top: 24px;
  `}
  .image {
    margin-top: 40px;
    ${media.lg`
      position: absolute;
      left: 54px;
      bottom: -30px;
    `}
    ${media.xl`
      left: 114px;
    `}
  }
  .message {
    text-align: center;
    margin-top: 32px;
    ${media.lg`
      margin-top: 0;
      position: absolute;
      max-width: 400px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  }
`

type Props = {
  boldText?: string
  normalText?: string
  className?: string
}

export const NoDataBanner = ({ boldText, normalText, className }: Props) => {
  return (
    <BannerWrapper alignIcon="topRight" className={className}>
      <div className="message">
        {!!boldText && (
          <Text size="M" bold block color="white">
            {boldText}
          </Text>
        )}
        {!!normalText && (
          <Text size="M" block color="white">
            {normalText}
          </Text>
        )}
      </div>
      <img alt="prospecting" src={ProspectingImage} className="image" />
    </BannerWrapper>
  )
}

const ReportNoDataBannerWrapper = styled.div`
  .image {
    ${media.lg`
        left: 15px;
      `}
    ${media.xl`
        left: 40px;
      `}
  }
`

export const ReportNoDataBanner = (props: Props) => (
  <ReportNoDataBannerWrapper>
    <NoDataBanner {...props} />
  </ReportNoDataBannerWrapper>
)
