import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ProspectingFilterActions } from '../../../features/filters/actions'
import { SimilarityType } from '../../../features/filters/types'
import { Query } from '../../../features/query/types'
import { PROSPECTING_ANALYSIS } from '../../../routes'
import { Dialog } from '../../../components/Dialog'
import { Text } from '../../../components/Text'
import { GenerateSimilarityDialogForm } from './GenerateSimilarityDialogForm'
import { usePortfolioDetail, usePortfolio } from '../../../features/portfolioManagement/portfolioDetail'
import { TrackingActions } from '../../../features/analytics/actions'
import { EventCategory, CallToActionType } from '../../../features/analytics/types'
import { hasSemanticClusters } from '../../../features/user/selectors'

type Props = {
  portfolioId: string
  close: () => void
}

export const GenerateSimilarityDialog = ({ close, portfolioId }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const navigate = useNavigate()
  const { portfolio } = usePortfolio(portfolioId)
  const { statuses, stats: portfolioStats, isLoading } = usePortfolioDetail(portfolioId)
  const semanticClustersAllowed = useSelector(hasSemanticClusters)
  const dialogTitle = semanticClustersAllowed
    ? t('portfolio:GenerateSimilarity.TitleClusterAllowed')
    : t('portfolio:GenerateSimilarity.Title')

  const dispatch = useDispatch()
  const props = {
    destroyOnClose: false,
    width: 1160,
    visible: true,
    onCancel: close
  }
  return (
    <Dialog {...props} loading={isLoading} title={dialogTitle}>
      <Text>{t('portfolio:GenerateSimilarity.Description')}</Text>
      <GenerateSimilarityDialogForm
        statuses={statuses}
        onStatusSelect={(status: string, includeSemanticClusters: boolean | undefined) => {
          dispatch(ProspectingFilterActions.resetSelectedFilterGroups())
          const query: Partial<Query> = { name: `${portfolio?.name} (${status})` }
          const similarity: SimilarityType = {
            portfolioId,
            status,
            companies: _.get(_.find(portfolioStats?.statuses, { value: _.capitalize(status) }), 'count'),
            data: includeSemanticClusters
              ? {
                  includeSemanticClusters
                }
              : undefined
          }
          dispatch(
            TrackingActions.trackCTAClick(
              EventCategory.PortfolioDetail,
              includeSemanticClusters
                ? CallToActionType.GenerateSimilarityWithSemanticCluster
                : CallToActionType.GenerateSimilarity
            )
          )
          navigate({ pathname: PROSPECTING_ANALYSIS }, { state: { query, similarity } })
          close()
        }}
      />
    </Dialog>
  )
}
