import React from 'react'
import styled from 'styled-components/macro'
import { Col, Row } from '../../components/Grid'
import { CenterChildren } from '../../components/Center'

const CenterText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  > div {
    width: 100%;
  }
`

const BoldText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
`

type Props = {
  content: JSX.Element | string
  image: JSX.Element
  boldText?: string
}

const BaseDialogComponent = ({ content, boldText, image }: Props) => {
  return (
    <Row>
      <Col xs={12} xsOrder={2} col={7}>
        <CenterText>
          <div>
            {content}
            {boldText && <BoldText>&nbsp;{boldText}</BoldText>}
          </div>
        </CenterText>
      </Col>
      <Col xs={12} xsOrder={1} col={5}>
        <CenterChildren>{image}</CenterChildren>
      </Col>
    </Row>
  )
}

export const BaseDialog = React.memo(BaseDialogComponent)
