import React from 'react'
import { WithT } from 'i18next'
import styled from 'styled-components/macro'
import { Text } from '../Text'
import { Notification } from '../../features/notifications/types'
import { notificationText } from '../../utils/notifications'
import { Type } from '../../utils/theme'
import { FormatNumberFunction } from '../../utils/types'
import { useLocaleNumberFormatter } from '../../hooks/useLocaleNumberFormatter'

const StyledText = styled(Text)`
  max-width: 100%;
  white-space: pre-line;
`

type NotificationsProps = {
  notification: Notification
  type?: Type
  block?: boolean
  formatNumber: FormatNumberFunction
  fallbackTheme?: any
  notificationTextSuffix?: string
} & WithT

export const NotificationTextWithoutFormatter = ({
  notification,
  type,
  block,
  fallbackTheme,
  formatNumber,
  notificationTextSuffix,
  t
}: NotificationsProps) => {
  const text = notificationText(t, notification, formatNumber)
  return (
    <StyledText fallbackTheme={fallbackTheme} type={type} size="S" block={block}>
      {notificationTextSuffix !== undefined ? `${text}\n${notificationTextSuffix}` : text}
    </StyledText>
  )
}

export const NotificationText = (props: Omit<NotificationsProps, 'formatNumber'>) => {
  const { formatNumber } = useLocaleNumberFormatter()
  return <NotificationTextWithoutFormatter {...props} formatNumber={formatNumber} />
}
