import React from 'react'
import styled from 'styled-components/macro'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { Col, Row } from '../Grid'
import { gridTheme } from '../../utils/theme'
import { useLocaleNumberFormatter } from '../../hooks/useLocaleNumberFormatter'

type StyleType = 'primary' | 'danger'

const CreditRow = styled(Row)<{ type: StyleType }>`
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: 16px 0;
  border-radius: 4px;
  background-color: ${({ theme, type }) => (type === 'primary' ? theme.colors.main : theme.colors.watermelon)};

  > ${Col} {
    padding: 8px 24px;
  }

  .remaining {
    ${Text.Style} {
      color: ${({ theme, type }) => (type === 'primary' ? theme.colors.dustyBlue : theme.colors.white)};
    }
  }

  ::after {
    position: absolute;
    left: 50%;
    top: 12px;
    bottom: 12px;
    display: block;
    content: '';
    width: 1px;
    background-color: ${({ theme, type }) => (type === 'primary' ? theme.colors.dustyBlue : theme.colors.white)};
  }

  @media only screen and (max-width: ${gridTheme.breakpoints.xs}px) {
    ${Col} {
      max-width: 100%;
    }

    ::after {
      top: 50%;
      left: 24px;
      right: 24px;
      width: auto;
      height: 1px;
    }
  }
`

type Props = {
  used: number
  usedText: string
  remaining: number
  remainingText: string
}

export const CreditStatistic = ({ used, usedText, remaining, remainingText }: Props) => {
  const { formatNumber } = useLocaleNumberFormatter()
  return (
    <CreditRow className="credit-stat" type={remaining >= 0 ? 'primary' : 'danger'}>
      <Col col={6}>
        <Stack center gap={8}>
          <Text size={24} bold color="white" text={formatNumber(used)} aria-label="statistics count" />
          <Text size="M" ellipsis color="white" text={usedText} aria-label="statistics title" />
        </Stack>
      </Col>
      <Col className="remaining" col={6}>
        <Stack center gap={8}>
          <Text size={24} bold text={formatNumber(remaining)} aria-label="remaining count" />
          <Text size="M" ellipsis text={remainingText} aria-label="remaining title" />
        </Stack>
      </Col>
    </CreditRow>
  )
}
