import React, { useCallback } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FieldValues, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateCampaign, getSalestoolKeys } from '../../../../../api/salestool'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import { Dialog } from '../../../../../components/Dialog'
import { BaseDialog } from '../../../BaseDialog'
import { DialogActions } from '../../../../../features/dialog/actions'
import { AddNoteDialogForm } from '../../../../../components/Form/AddNoteDialogForm'

type Props = {
  data: {
    campaignId: string
    type: string
    currentNote: string
  }
}

export const ChangeCampaignNoteDialog = ({ data: { type, campaignId, currentNote } }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const editing = type !== 'addNote'
  const { control, formState, watch } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: { note: currentNote }
  })
  const noteValue = watch('note')
  const { isValid, isDirty } = formState

  const {
    mutate: mutateChangeNote,
    isLoading,
    isSuccess,
    isError
  } = useMutation((note: string) => updateCampaign(campaignId, { note }), {
    onSuccess: () => queryClient.invalidateQueries(getSalestoolKeys.GetCampaign(campaignId))
  })

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const onOkClick = () => {
    mutateChangeNote(noteValue)
  }

  const props = {
    destroyOnClose: false,
    width: 1160,
    visible: true,
    loading: isLoading,
    onCancel: closeDialog,
    title: editing ? t('Management.Campaign.ChangeCampaignNote') : t('Management.Campaign.AddCampaignNote'),
    footer: ''
  }

  if (isSuccess) {
    const noteResult = editing ? 'NoteChangedCorrectly' : 'NoteAddedCorrectly'
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={closeDialog}>
        <BaseDialog
          content={t(`Management.Campaign.${_.isEmpty(noteValue) ? 'NoteRemovedCorrectly' : noteResult}`)}
          boldText={noteValue}
          image={<img src={OkImage} alt="ok" />}
        />
      </Dialog>
    )
  }

  if (isError) {
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={closeDialog}>
        <BaseDialog content={t('error:ChangeCampaignNote')} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  return (
    <Dialog
      {...props}
      key="note"
      cancelText={t('common:Cancel')}
      okText={t('common:Continue')}
      okButtonProps={{ disabled: !isValid || !isDirty || isLoading }}
      onOk={onOkClick}
    >
      <AddNoteDialogForm
        name="note"
        text={t('Management.Campaign.AddCampaignNoteDescription')}
        label={t('CampaignDetail.NoteLabel')}
        labelComponent
        placeholder={t('Management.Campaign.InsertCampaignNote')}
        editing={editing}
        currentNote={currentNote}
        control={control}
        formState={formState}
      />
    </Dialog>
  )
}
