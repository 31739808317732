import { GeoJsonProperties } from 'geojson'
import _ from 'lodash'
import { MapCountryCustomization } from './CountryCustomization'
import { IMapCountryCustomizations } from './types'

export const mapConfig = {
  level1Key: 'region',
  level2Key: 'district',
  level3Key: 'country',
  dataKey: 'count',
  regionNameKey: 'NAME'
}

export class MapCountryCustomizationCzsk extends MapCountryCustomization implements IMapCountryCustomizations {
  // eslint-disable-next-line class-methods-use-this
  getGeoJsonType = (zoom: number) => {
    if (zoom <= 5) return 'country'
    if (zoom <= 6) return 'regions'
    return 'district'
  }

  getMaxZoom = (withGeoData: boolean) => (withGeoData ? this.maxZoomLevel ?? 8 : 19)

  getMinZoom = (withGeoData: boolean) => (withGeoData ? this.minZoomLevel ?? 5 : 1)

  // eslint-disable-next-line class-methods-use-this
  getPathFromGeoJsonFeature = (featureProps: GeoJsonProperties) => {
    if (featureProps === null) return undefined

    const { level1Key, level2Key, level3Key, dataKey } = mapConfig
    const districtCode = _.lowerFirst(featureProps[level2Key])
    const regionCode = _.lowerFirst(featureProps[level1Key])
    const countryCode = _.toLower(featureProps[level3Key])

    let key: string
    if (districtCode) {
      key = `${countryCode}.sub.${regionCode}.sub.${districtCode}`
    } else if (regionCode) {
      key = `${countryCode}.sub.${regionCode}`
    } else {
      key = `${countryCode}`
    }

    key += `.${dataKey}`
    return key
  }
}
