import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { Dialog } from '../../../components/Dialog'
import { EnrichBeforeCampaignDialogData, EnrichBeforeCampaignView } from './types'
import { Text } from '../../../components/Text'
import { EnrichBeforeCampaignRecap } from './EnrichBeforeCampaignRecap'
import { TargetActionType } from '../../../api/enrichment/types'
import { enrichPortfolio } from '../../../api/enrichment'

type Props = {
  close: () => void
  data: EnrichBeforeCampaignDialogData
}

export const EnrichBeforeCampaign = ({ close, data }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const [recapLoading, setRecapLoading] = useState<boolean>(false)
  const [view, setView] = useState(EnrichBeforeCampaignView.Init)

  const mutation = useMutation(() => enrichPortfolio(data.portfolioId, TargetActionType.CreateCampaign), {
    onSettled: close
  })

  const onOk = useCallback(() => {
    if (view === EnrichBeforeCampaignView.Init) {
      setView(EnrichBeforeCampaignView.Recap)
    } else if (view === EnrichBeforeCampaignView.Recap) {
      mutation.mutate()
    }
  }, [mutation, view])

  const loading = mutation.isLoading || recapLoading

  return (
    <Dialog
      loading={loading}
      visible
      destroyOnClose
      width={800}
      onCancel={close}
      title={t('portfolio:Actions.Campaign')}
      footer={t('common:DoYouWantToContinue')}
      cancelText={t('common:Cancel')}
      okText={t('common:Confirm')}
      okButtonProps={{ disabled: mutation.isLoading }}
      cancelButtonProps={{ disabled: mutation.isLoading }}
      onOk={onOk}
    >
      {view === EnrichBeforeCampaignView.Init && (
        <Text text={t('portfolio:Campaign.YouNeedToEnrich')} block padding="20px 0 0 0" />
      )}
      {view === EnrichBeforeCampaignView.Recap && (
        <EnrichBeforeCampaignRecap dialogData={data} setRecapLoading={setRecapLoading} />
      )}
    </Dialog>
  )
}
