import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchAvailableCountries } from './api'
import { AvailableCountry } from './types'

export const getConfigKeys = {
  All: () => [{ level1: 'config' }] as const,

  AvailableCountries: (instanceCode: any, language: string, isUserLoggedIn: boolean) =>
    [{ ...getConfigKeys.All()[0], level2: 'availableCountries', instanceCode, language, isUserLoggedIn }] as const
}

export const useGetAvailableCountries = (
  instanceCode: any,
  language: string,
  isUserLoggedIn: boolean,
  options?: UseQueryOptions<
    AvailableCountry[] | undefined,
    unknown,
    AvailableCountry[],
    ReturnType<typeof getConfigKeys.AvailableCountries>
  >
) =>
  useQuery(
    getConfigKeys.AvailableCountries(instanceCode, language, isUserLoggedIn),
    ({ queryKey: [queryKeys] }) =>
      fetchAvailableCountries(queryKeys.instanceCode, { allowedErrorStatuses: [400, 401, 403, 404, 500] }),
    options
  )
