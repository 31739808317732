/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  PM001: 'Impresa caricata correttamente. Caricamento del portafoglio {{portfolioName}} completato!',
  PM001_plural: 'Imprese caricate correttamente. Caricamento del portafoglio {{portfolioName}} completato!',
  PM002: 'Vai al dettaglio degli errori di caricamento del portafoglio {{portfolioName}}',
  PM003: '{{portfolioName}} impresa non importata perché cessata o non individuata',
  PM003_plural: '{{portfolioName}} imprese non importate perché cessate o non individuate',
  PM004: '{{portfolioName}} impresa non importata a causa di dati errati o incompleti',
  PM004_plural: '{{portfolioName}} imprese non importate a causa di dati errati o incompleti',
  PM005: 'Scaricamento tracciato {{exportType}} del portafoglio {{portfolioName}} completato',
  PM006: "Scaricamento PDF dell'impresa {{companyName}} completato",
  PM007: 'Duplicazione di {{numberOfCompanies}} imprese nel portafoglio {{portfolioName}} completata',
  PM008: 'Eliminazione di {{numberOfCompanies}} imprese nel portafoglio {{portfolioName}} completata',
  PM009:
    'Spostamento di {{count}} impresa dal portafoglio {{sourcePortfolioName}} al portafoglio {{targetPortfolioName}} eseguito correttamente.',
  PM009_plural:
    'Spostamento di {{count}} imprese dal portafoglio {{sourcePortfolioName}} al portafoglio {{targetPortfolioName}} eseguito correttamente.',
  PM010: 'Arricchimento di {{numberOfCompanies}} imprese nel portafoglio {{portfolioName}} completato',
  PM011: 'Condivisione di {{companyNumber}} imprese del portafoglio {{portfolioName}} con {{userName}} completata',
  PM012: '{{grantorName}} ha condiviso il portafoglio {{portfolioName}}',
  PM013: 'Rimozione condivisione {{portfolioName}} completata',
  PM014Part1:
    'Assegnazione di {{productName}} a {{numberOfCompanies}} impresa eseguito correttamente al portafoglio {{portfolioName}}.',
  PM014Part1_plural:
    'Assegnazione di {{productName}} a {{numberOfCompanies}} imprese eseguito correttamente al portafolio {{portfolioName}}.',
  PM014Part2:
    " L'assegnazione del prodotto non è stata completata per {{numberOfDuplicates}} impresa a causa di duplicazione della riga.",
  PM014Part2_plural:
    " L'assegnazione del prodotto non è stata completata per {{numberOfDuplicates}} imprese a causa di duplicazione delle righe.",
  PM015: 'Assegnazione di {{salesName}} a {{numberOfCompanies}} imprese completata',
  PM017: 'Assegnazione di {{managerName}} a {{numberOfCompanies}} imprese completata',
  PM014_0: 'Rimozione assegnazione di {{numberOfCompanies}} imprese in {{portfolioName}} completata',
  PM015_0: 'Rimozione assegnazione di {{numberOfCompanies}} imprese in {{portfolioName}} completata',
  PM017_0: 'Rimozione assegnazione di {{numberOfCompanies}} imprese in {{portfolioName}} completata',
  PM019: 'La campagna {{campaignName}} con {{numberOfCompanies}} impresa è stata creata',
  PM019_plural: 'La campagna {{campaignName}} con {{numberOfCompanies}} imprese è stata creata',
  PM020: 'Arricchimento di {{numberOfCompanies}} imprese completato',
  PM021: '{{initiatorUserName}} ha condiviso {{numberOfCompanies}} imprese',
  PM022: '{{initiatorUserName}} ha annullato la condivisione di {{numberOfCompanies}} imprese',
  PM023: 'Aggiornamento condivisione completato',
  PM024: 'Aggiornamento condivisione completato',
  PM025: '{{initiatorUserName}} ha condiviso {{numberOfCompanies}} imprese con {{numberOfSalesUsers}} utenti',
  PM026:
    '{{initiatorUserName}} ha interrotto la condivisione di {{numberOfCompanies}} imprese con {{numberOfSalesUsers}} utenti',
  PM027: '{{portfolioName}} impresa non importata a causa di dati errati o incompleti',
  PM027_plural: '{{portfolioName}} imprese non importate a causa di dati errati o incompleti',
  LL001: 'Aggiunta delle Unità Locali. Il portafoglio è stato aggiornato con {{numberOfCompanies}} Unità Locali.',
  ST001: 'La campagna {{campaignName}} con {{numberOfCompanies}} impresa è stata creata',
  ST001_plural: 'La campagna {{campaignName}} con {{numberOfCompanies}} imprese è stata creata',
  ST002: '{{userName}} ha rimosso {{numberOfCompanies}} impresa dalla campagna {{campaignName}}',
  ST002_plural: '{{userName}} ha rimosso {{numberOfCompanies}} imprese dalla campagna {{campaignName}}',
  ST003: '{{userName}} ha assegnato {{numberOfCompanies}} impresa alla campagna {{campaignName}}',
  ST003_plural: '{{userName}} ha assegnato {{numberOfCompanies}} imprese alla campagna {{campaignName}}',
  ST004: 'La campagna {{campaignName}} inizierà in data {{startDate}}',
  ST005: 'La campagna {{campaignName}} terminerà in data {{endDate}}',
  ST006: 'Aggiunta del portafoglio {{portfolioName}} alla campagna {{campaignName}} completata',
  ST007: 'Scaricamento excel della campagna {{campaignName}} completato',
  ST009: 'Nuova assegnazione a {{numberOfCompanies}} impresa per la campagna {{campaignName}} completata.',
  ST009_plural: 'Nuove assegnazioni a {{numberOfCompanies}} imprese per la campagna {{campaignName}} completata.',
  ST010: 'Aggiunta di {{numberOfCompanies}} impresa alla campagna {{campaignName}} completata.',
  ST010_plural: 'Aggiunta di {{numberOfCompanies}} imprese alla campagna {{campaignName}} completata.',
  ST011:
    'Assegnazione del task {{taskName}} a {{numberOfCompanies}} impresa per la campagna {{campaignName}} completata.',
  ST011_plural:
    'Assegnazione del task {{taskName}} a {{numberOfCompanies}} imprese per la campagna {{campaignName}} completata.',
  PR001: '{{numberOfCompanies}} nuove imprese sono state inserite nel portafoglio {{portfolioName}}',
  PR002:
    'Inserimento e arricchimento di {{numberOfCompanies}} company nel portafoglio {{portfolioName}} eseguito correttamente',
  PR003: 'Creazione del portafoglio {{portfolioName}} con {{numberOfCompanies}} imprese arricchite completata',
  PR004:
    'Inserimento e arricchimento di {{numberOfCompanies}} imprese nel portafoglio monitorato {{portfolioName}} completato',
  PR005:
    'Creazione del portafoglio monitorato {{portfolioName}} e inserimento di {{numberOfCompanies}} imprese arricchite completata',
  PR006: 'Creazione del portafoglio arricchito <0>{{portfolioName}}</0> completata. {{exclusionWarning}}',
  PR007:
    'Inserimento e arricchimento di {{numberOfCompanies}} company nel portafoglio {{portfolioName}} eseguito correttamente',
  TR001: 'Creazione del portafoglio {{portfolioName}} con {{numberOfCompanies}} imprese arricchite completata',
  TR002:
    'Inserimento e arricchimento di {{numberOfCompanies}} company nel portafoglio {{portfolioName}} eseguito correttamente',
  TA001: 'Scaricamento excel da Targeting completato',
  TA002: 'Scaricamento PDF da Targeting completato',
  DO002: "Il documento per l'impresa {{companyName}} è stato creato ed è pronto per il download.",
  PM002Event1: 'Imprese cessate non aggiornate',
  PM002Event2:
    'Errori dovuti a dati errati. Puoi caricare il template successivamente, nella sezione Gestione Portfolio',
  OP001:
    'Creazione del portafolio {{portfolioName}} con {{numberOfCompanies}} company arricchite eseguita correttamente',
  OP001_plural:
    'Creazione del portafolio {{portfolioName}} con {{numberOfCompanies}} company arricchite eseguita correttamente',
  OP002:
    'Inserimento e arricchimento di {{numberOfCompanies}} company nel portafoglio {{portfolioName}} eseguito correttamente',
  OP003: 'Il tuo portafoglio arricchito {{portfolioName}} è stato creato correttamente. {{exclusionWarning}}',
  OP004: 'Arricchimento di {{numberOfCompanies}} imprese nel portafoglio {{portfolioName}} completato',
  MissingMessage: 'Notification message is missing for type {{type}}.',
  ExcludedCompaniesWarning: 'Le imprese appartenenti a portafogli nascosti non sono state elaborate.',
  SalesTool: 'Gestione Vendite',
  Portfolio: 'Portafoglio Imprese',
  Prospecting: 'Trova Clienti',
  Operations: 'Operations',
  Targeting: 'Analizza Mercato',
  DownloadPDF: 'Download PDF',
  NotificationArchive: {
    Title: 'Notifiche',
    Description: 'In questa sezione trovi l’elenco delle notifiche ricevute',
    Notifications: '{{notificationsNumber}} Notifiche',
    Welcome: 'Benvenuto in Margò:',
    NoNotifications: 'questa è la sezione delle notifiche e  verrà popolato non appena verranno fatte delle azioni.'
  },
  Table: {
    Header: {
      Date: 'Data',
      Section: 'Sezione',
      Message: 'Messaggio'
    }
  },
  NotificationPopup: {
    Open: 'Aprire',
    ManageErrors: 'Gestisci errori',
    DownloadList: 'Scarica lista',
    DownloadFile: 'Scarica file'
  },
  PushEvent: {
    UploadProgress: 'Il tuo portafoglio si sta caricando',
    UploadProgressFinished: 'Caricamento completato',
    MoveCompaniesProgress: 'Spostamento delle imprese selezionate in corso',
    MoveCompaniesProgressFinished: 'Spostamento delle imprese completato',
    DeleteCompaniesProgress: 'Eliminazione delle imprese selezionate in corso',
    DeleteCompaniesProgressFinished: 'Eliminazione delle imprese selezionate completata',
    EnrichCompaniesProgress: 'Arricchimento delle imprese selezionate in corso',
    EnrichCompaniesProgressFinished: 'Arricchimento delle imprese selezionate completato',
    CreatePortfolioFromSelectionProgress: 'Creazione di un portafoglio dalle imprese selezionate in corso',
    CreatePortfolioFromSelectionProgressFinished: 'Creazione di un portafoglio dalle imprese selezionate completato',
    DuplicateProgress: 'Il tuo portafoglio si sta duplicando',
    DuplicateProgressFinished: 'Duplica completata',
    ShareCompaniesProgress: 'Condivisione delle imprese in corso',
    ShareCompaniesProgressFinished: 'Condivisione completata',
    UnshareCompaniesProgress: 'La condivisione delle imprese è appena stata interrotta',
    UnshareCompaniesProgressFinished: 'La condivisione delle imprese è stata interrotta',
    AssignSalesProgress: 'Assegnazione ai Venditori in corso',
    AssignSalesProgressFinished: 'Assegnazione ai Venditori completata',
    AssignAreaManagerProgress: 'Assegnazione agli Area Manager in corso',
    AssignAreaManagerProgressFinished: 'Assegnazione agli Area Manager completata',
    AssignProductProgress: 'Assegnazione Prodotti in corso',
    AssignProductProgressFinished: 'Assegnazione Prodotti completata',
    ProspectingSaveListProgress: 'Creazione di un portafoglio da Trova Clienti in corso',
    ProspectingSaveListProgressFinished: 'Creazione di un portafoglio completata',
    TargetingSaveListProgress: 'Creazione di un portafoglio da Analizza mercato in corso',
    TargetingSaveListProgressFinished: 'Creazione di un portafoglio completata',
    ProspectingUpdateListProgress: 'Aggiornamento di un portafoglio da Trova Clienti in corso',
    ProspectingUpdateListProgressFinished: 'Aggiornamento di un Analizza mercato completata',
    TargetingUpdateListProgress: 'Aggiornamento di un portafoglio da Analizza mercato in corso',
    TargetingUpdateListProgressFinished: 'Aggiornamento di un portafoglio completata',
    CreateCampaignProgress: 'Creazione campagna in corso',
    CreateCampaignProgressFinished: 'Creazione campagna completata',
    AssignCampaignProgress: 'Assegnazione campagna in corso',
    AssignCampaignProgressFinished: 'Assegnazione campagna completata',
    UpdateCampaignTaskProgress: 'La tua campagna si sta aggiornando',
    UpdateCampaignTaskProgressFinished: 'La tua campagna è stata aggiornata',
    SetCompaniesTaskProgress: 'Impostazione dei task aziendali in corso',
    SetCompaniesTaskProgressFinished: 'Impostazione dei task aziendali completata',
    ExportCampaignProgress: 'Esportazione campagna in corso',
    ExportCampaignProgressFinished: 'Esportazione campagna completata',
    ExportPortfolioProgress: 'Il tuo portfolio {{portfolioName}} è in preparazione per il download',
    ExportPortfolioProgressFinished: 'Il download del portfolio {{portfolioName}} è pronto',
    PdfGenerateProgress: "Creazione del PDF per l'impresa {{companyName}} in corso.",
    AddLocalUnitsToPortfolioProgress: 'Aggiunta delle Unità Locali. Aggiornamento del portafoglio in corso.'
  },
  Banner: {
    RingsWarningMessage:
      'Attenzione! Il tuo credito residuo è inferiore 10%. Controlla <1>il tuo account</1> o contatta il tuo sales account.'
  }
}
