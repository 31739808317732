// eslint-disable-next-line import/no-default-export
export default {
  Close: 'Zavřít',
  Popup: {
    TaxCode: 'IČ',
    BirthDate: 'Datum narození',
    BirthTown: 'Místo narození',
    Enriched: 'Obohacený',
    Enrich: 'Obohatit'
  },
  NotEnriched: {
    Title: 'Chcete-li odemknout tuto sekci, zakupte si údaje společnosti {{companyName}}',
    Message: 'Tuto sekci můžete odemknout zakoupením údajů společnosti {{companyName}}',
    Action: 'Odemknout údaje společnosti {{companyName}}'
  },
  IdentificationData: {
    Title: 'Identifikační údaje',
    TaxCode: 'IČ',
    BirthDate: 'Datum narození',
    BirthPlace: 'Místo narození',
    BirthCountry: 'Země narození',
    Sex: 'Pohlaví',
    Address: 'Adresa'
  },
  Positions: {
    Title: 'Aktivní a bývalé pozice',
    ActivePositions: {
      Title: 'Aktivní pozice'
    },
    PastPositions: {
      Title: 'Bývalé pozice'
    },
    From: 'Od',
    To: 'Do'
  },
  PartnersList: {
    Title: 'Seznam partnerů',
    Amount: 'Suma'
  },
  Enrich: {
    Title: 'Obohatit osobu',
    Desc: 'Obohacením osoby o údaje společnosti {{companyName}} se tyto informace stáhnou a budou se pravidelně aktualizovat',
    Success: 'Obohacení bylo úspěšné',
    CreditsWillBeUsed: 'Použité jednotky',
    CreditsRemaining: 'Zbývající jednotky',
    ProposalFailed: 'Došlo k problému s kontrolou dostupných kreditů pro tuto operaci.'
  }
}
