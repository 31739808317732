import React from 'react'
import styled from 'styled-components/macro'
import { Title } from '../Text'
import { ConditionallyVisible } from '../HideFeature'
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg'

const NavigationHeaderWrapper = styled.div`
  position: relative;

  .icon {
    position: absolute;
    left: -7px;
    cursor: pointer;
  }

  .title {
    padding: 0 20px;
  }
`

type Props = {
  title: string
  onBack?: () => void
}

export const NavigationHeader = ({ title, onBack }: Props) => (
  <NavigationHeaderWrapper>
    <ConditionallyVisible condition={!!onBack}>
      <LeftIcon className="icon" onClick={onBack} />
    </ConditionallyVisible>
    <Title className="title" level={3} center ellipsis>
      {title}
    </Title>
  </NavigationHeaderWrapper>
)
