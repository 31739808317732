import _ from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSalestoolKeys, saveTaskAssignment, saveTasksAssignment } from '../../../../api/salestool'
import KoImage from '../../../../assets/images/ko.svg'
import OkImage from '../../../../assets/images/ok.svg'
import SalesToolImage from '../../../../assets/images/sales-tool.svg'
import { UserType } from '../../../../authorization'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { Dialog } from '../../../../components/Dialog'
import { Stack } from '../../../../components/Stack'
import { Text } from '../../../../components/Text'
import { getAssignment } from '../../../../features/salestool/table/selectors'
import { AssignmentType } from '../../../../features/salestool/table/types'
import { SaleDetailActions } from '../../../../features/salestoolSaleDetail/actions'
import { getHiddenTaskPopupExpirationDate } from '../../../../features/salestoolSaleDetail/selectors'
import { RootState } from '../../../../features/types'
import { getUserType } from '../../../../features/user/selectors'
import { useLocaleNumberFormatter } from '../../../../hooks/useLocaleNumberFormatter'
import { ComponentProps } from '../../../../types'
import { BaseDialog } from '../../BaseDialog'
import { AssignTaskViewState } from '../types'
import { getExpirationDateFromNow } from './helpers'
import { SingleAssignTaskDialogData, MultipleAssignTaskDialogData } from './types'
import { DialogActions } from '../../../../features/dialog/actions'
import { SalestoolTableActions } from '../../../../features/salestool/table/actions'
import { SalestoolAssignmentsViewType } from '../../../../features/salestoolAssignmentsPage/types'
import { queryClient } from '../../../../features/queryclient'

const dialogFooterStyle = { width: 'auto' }

type Props = {
  data: SingleAssignTaskDialogData | MultipleAssignTaskDialogData
  close: () => void
}

const { AssignTaskView, AssignTaskDoneView, AssignTaskErrorView } = AssignTaskViewState

export const AssignTaskDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const dispatch = useDispatch()

  const { formatNumber } = useLocaleNumberFormatter()

  const [hidePopup, setHidePopup] = React.useState(false)

  const userType = useSelector(getUserType)
  const lastAssignedTask = useSelector((state: RootState) =>
    getAssignment(state, {
      companyId: data.quantity === 'single' ? data.companyId : undefined,
      assignmentType: AssignmentType.task
    })
  )
  const hiddenTaskPopupExpirationDate = useSelector(getHiddenTaskPopupExpirationDate)

  const shouldBeDisplayed = hiddenTaskPopupExpirationDate ? moment().isAfter(hiddenTaskPopupExpirationDate) : true

  const setAssignTaskDialogData = useCallback(
    (view: AssignTaskViewState, quantity?: string, companyId?: string) =>
      dispatch(
        DialogActions.setDialogData('AssignTasks', {
          view,
          quantity,
          companyId
        })
      ),
    [dispatch]
  )

  const onError = useCallback(() => {
    setAssignTaskDialogData(AssignTaskViewState.AssignTaskErrorView)
  }, [setAssignTaskDialogData])

  const { mutate: mutateSaveTask, isLoading: isLoadingSaveTask } = useMutation(
    () => {
      const singleAssignData = data as SingleAssignTaskDialogData
      dispatch(SalestoolTableActions.setLoadingAssignment(singleAssignData.companyId, true))
      return saveTaskAssignment(singleAssignData.campaignId, singleAssignData.companyId, { taskId: data.taskId })
    },
    {
      onSuccess: () => {
        const singleAssignData = data as SingleAssignTaskDialogData
        setAssignTaskDialogData(AssignTaskViewState.AssignTaskDoneView, data.quantity, singleAssignData.companyId)
        dispatch(
          SalestoolTableActions.assignItemToCompany(
            AssignmentType.task,
            singleAssignData.taskId,
            singleAssignData.companyId,
            SalestoolAssignmentsViewType.ALL
          )
        )
        queryClient.resetQueries(getSalestoolKeys.GetCampaignStats(singleAssignData.campaignId))
      },
      onError,
      onSettled: () => {
        const singleAssignData = data as SingleAssignTaskDialogData
        dispatch(SalestoolTableActions.setLoadingAssignment(singleAssignData.companyId, false))
      }
    }
  )

  const { mutate: mutateSaveMultipleTasks, isLoading: isLoadingSaveMultipleTasks } = useMutation(
    () => {
      const multipleAssignData = data as MultipleAssignTaskDialogData
      return saveTasksAssignment({
        selection: {
          salesToolGridQuery: {
            query: multipleAssignData.query
          },
          taskId: multipleAssignData.taskId,
          selectedItems: multipleAssignData.selectedItems,
          itemsToIgnore: multipleAssignData.itemsToIgnore,
          sourceContainerItemId: multipleAssignData.campaignId,
          destinationContainerItemId: multipleAssignData.campaignId
        }
      })
    },
    {
      onSuccess: () => {
        setAssignTaskDialogData(AssignTaskViewState.AssignTaskDoneView, data.quantity)
      },
      onError
    }
  )

  const isLoading = isLoadingSaveTask || isLoadingSaveMultipleTasks

  const onOkHandler = () => {
    if (hidePopup) {
      const expirationDate = getExpirationDateFromNow()
      dispatch(SaleDetailActions.setHiddenTaskPopupExpirationDate(expirationDate))
    }
    if (data.quantity === 'single') mutateSaveTask()
    else mutateSaveMultipleTasks()
  }

  React.useEffect(() => {
    // Directly call the backend when the modal has been hidden
    if (data.view === AssignTaskView && !shouldBeDisplayed) {
      onOkHandler()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // If the modal is hidden, destroy it when done
    if (data.view === AssignTaskDoneView && !shouldBeDisplayed) {
      close()
    }
  }, [data.view, shouldBeDisplayed, close])

  const dialogFooter = (
    <Stack gap="auto" center>
      <div>
        <Checkbox disabled={isLoading} checked={hidePopup} onChange={evt => setHidePopup(evt.target.checked)}>
          <Text size="M">{t('SaleDetail.AssignTaskDialogHidePopupCheckbox')}</Text>
        </Checkbox>
      </div>
      <Stack gap="1em" style={dialogFooterStyle}>
        <Button disabled={isLoading} onClick={close}>
          {t('common:Cancel')}
        </Button>
        <Button type="primary" disabled={isLoading} onClick={onOkHandler}>
          {t('common:Continue')}
        </Button>
      </Stack>
    </Stack>
  )

  const props: ComponentProps<typeof Dialog> = {
    destroyOnClose: false,
    title: t('CampaignDetail.Dialog.AssignTaskTitle'),
    footer: data.view === AssignTaskView ? dialogFooter : '',
    okText: data.view === AssignTaskView ? undefined : t('common:Ok'),
    onCancel: close,
    width: 1024,
    visible: true,
    loading: isLoading
  }

  if (data.view === AssignTaskView && shouldBeDisplayed) {
    let assignText: JSX.Element
    if (data.quantity === 'single') {
      const originalTask = lastAssignedTask
        ? _.get(data.tasksList, [lastAssignedTask, 'name'], data.oldTaskName)
        : data.oldTaskName
      assignText = (
        <>
          <Text block primary bold>
            {t(`SaleDetail.AssignTaskDialogText1${originalTask ? '' : '_noPrevTask'}`)}
          </Text>
          <Text block>
            {originalTask
              ? t('SaleDetail.AssignTaskDialogText2', {
                  originalTask,
                  newTask: data.taskName
                })
              : t('SaleDetail.AssignTaskDialogText2_noPrevTask', { newTask: data.taskName })}
          </Text>
        </>
      )
    } else {
      assignText = (
        <>
          <Text block primary bold>
            {t('SaleDetail.AssignTaskDialogText1_noPrevTask')}
          </Text>
          <Text block>
            {t('SaleDetail.AssignTasksDialogText', {
              newTask: data.taskName,
              count: data.count,
              fCount: formatNumber(data.count)
            })}
          </Text>
        </>
      )
    }
    return (
      <Dialog {...props}>
        <BaseDialog content={assignText} image={<img src={SalesToolImage} alt="salesTool" />} />
      </Dialog>
    )
  }

  if (data.view === AssignTaskDoneView && shouldBeDisplayed) {
    let successText: JSX.Element
    if (data.quantity === 'single') {
      const originalTask = lastAssignedTask
        ? _.get(data.tasksList, [lastAssignedTask, 'name'], data.oldTaskName)
        : data.oldTaskName
      successText = (
        <>
          <Text block primary bold>
            {t(`SaleDetail.AssignTaskDoneDialogText1${originalTask ? '' : '_noPrevTask'}`)}
          </Text>
          {userType === UserType.Sales && <Text block>{t('SaleDetail.AssignTaskDoneDialogText2')}</Text>}
        </>
      )
    } else {
      successText = (
        <>
          <Text block primary bold>
            {t('SaleDetail.AssignTaskMultipleDoneDialogText1')}
          </Text>
          {userType === UserType.Sales && <Text block>{t('SaleDetail.AssignTaskMultipleDoneDialogText2')}</Text>}
        </>
      )
    }
    return (
      <Dialog {...props} onOk={close}>
        <BaseDialog content={successText} image={<img src={OkImage} alt="ok" />} />
      </Dialog>
    )
  }

  if (data.view === AssignTaskErrorView) {
    const failText = <Text block>{t('error:AssignTask')}</Text>
    return (
      <Dialog {...props} onOk={close}>
        <BaseDialog content={failText} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  return null
}
