/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: "Pour démarrer l'analyse, sélectionner le type d'analyse à traiter",
    TypeOfAnalysis: "Type d'analyse",
    LoadQuery: 'Charger requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    NewResearch: 'Démarrer nouvelle recherche',
    NewResearchTooltip:
      'Rechercher des prospects à travers toutes les sociétés présentes dans la base de données Margo',
    SelectOne: 'Sélectionner requête',
    SavedQuery: 'Requête enregistrée',
    PortfolioQuery: 'Requête de gestion des portefeuilles',
    TargetingQuery: 'Requête Analyser marché',
    ProspectingQuery: 'Requête de prospection',
    ChooseQuery: 'Choisir quelle requête charger',
    YouCanResumeResearch: 'Une recherche déjà lancée peut être reprise.'
  },
  Prospecting: 'Prospection',
  Actions: {
    LoadQuery: 'Charger requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    SaveQuery: 'Enregistrer requête',
    SaveQueryTooltip: 'Enregistrer cette requête dans le module Prospection',
    Download: 'Télécharger Excel',
    DownloadTooltip: 'Télécharger la liste enrichie de sociétés sur votre dispositif',
    SaveList: 'Enregistrer liste',
    SaveListTooltip: 'Enregistrer cette liste de sociétés dans le portefeuille',
    SaveAndMonitor: 'Enregistrer & surveiller la liste',
    SaveAndMonitorTooltip:
      "Enregistrer cette liste de sociétés dans le portefeuille qui s'actualise de façon dynamique lorsque de nouvelles sociétés rentrent dans le périmètre de recherche",
    SendToSales: 'Envoyer la liste à Outil de vente',
    SendToSalesTooltip: 'Affecter les sociétés aux équipes commerciales et planifier une campagne publicitaire'
  },
  Indexes: {
    Penetration: 'Indice de pénétration',
    PenetrationTooltip:
      "Il mesure le % de sociétés du portefeuille comparé à celles générées par analogie. Une valeur plus élevée indique une meilleure pénétration d'un potentiel marché.",
    Opportunity: "Indice d'opportunité",
    OpportunityTooltip:
      'Il mesure le % de sociétés générées par analogie non incluses dans le portefeuille. Une valeur plus élevée indique un plus grand nombre de clients potentiels.'
  },
  CompaniesInList: 'Société dans la liste',
  CompaniesInList_plural: 'Sociétés dans la liste',
  Search: {
    SemanticSearch: 'Recherche sémantique',
    SearchPlaceholder: 'Rechercher',
    All: 'Tous',
    OfficialData: 'Données officielles',
    Website: 'Web & Réseaux sociaux',
    Synonyms: 'Synonymes',
    Advanced: 'Recherche sémantique avancée',
    AdvancedSwitch: 'Avancée',
    AdvancedTooltip:
      "La Recherche sémantique avancée permet d'utiliser plusieurs mots liés par des opérateurs logiques (AND, OR, NOT). Pour l'activer, placer le curseur sur ON. Télécharger le document pour plus d'informations.",
    DownloadSsGuide: 'Télécharger le guide de recherche sémantique'
  },
  EmptySearch: {
    History: {
      Title: 'Aucun filtre',
      Subtitle: 'La façon dont chaque filtre affecte la recherche est visible ici'
    },
    Banner: {
      Title: 'Démarrer la recherche en utilisation vos filtres préférés',
      Subtitle:
        "Avant de démarrer la recherche, s'assurer d'avoir sélectionné les filtres les plus utiles. La plateforme génère une liste de prospects idéaux sur la base des filtres sélectionnés."
    }
  },
  LoadQuery: {
    DialogTitle: 'Charger une recherche enregistrée',
    SaveBefore: {
      Question: 'Voulez-vous enregistrer la recherche de prospects avant de charger la recherche ?',
      Description:
        "Enregistrer la recherche de prospects dans une nouvelle avant de charger la requête afin d'éviter toute perte de données",
      OkBtn: 'Enregistrer recherche',
      CancelBtn: 'Omettre enregistrement'
    }
  },
  SaveQuery: {
    DialogTitle: 'Enregistrer recherche',
    Description:
      "Saisir le nom de sauvegarde de la recherche ou sélectionner une requête existante pour l'écraser en écriture.",
    ConfirmMessage: 'Voulez-vous ajouter une requête au tableau de bord ?',
    UpdateExisting: 'Actualiser existante',
    CreateNew: 'Créer nouvelle',
    MaximumWidgetCountReached: 'Le nombre maximum de cases du tableau de bord est atteint.',
    Fields: {
      QueryName: 'Nom de requête',
      QueryNamePlaceholder: 'Nom de recherche',
      AddToDashboard: 'Afficher dans le tableau de bord'
    },
    SavedQuery: 'enregistré correctement',
    SavedQueryAndDashboard: 'enregistré et ajouter dans le tableau de bord',
    SavedQueryDescription: 'La requête enregistrée se trouve dans le répertoire relatif à la zone de référence'
  },
  DomainFilter: {
    SaveAndClose: 'Enregistrer filtres & fermer'
  },
  LoadMore: 'Charger plus',
  LocalUnits: {
    NoLocalUnits: {
      Title: 'Ajouter unités locales',
      Text: "Contacter l'équipe commerciale de Margò afin d'activer la fonctionnalité Unité locale."
    }
  },
  UnavailableForMobile:
    "La page à laquelle vous tenez d'accéder est disponible uniquement sur tablettes et ordinateurs portables.",
  WarningRespectingLocalUnits: {
    Title: 'Enrichissement',
    Text: "Les sociétés à bureau de type « Siège administratif », « Siège administratif et siège social » et « Siège social » ont été enrichies (si sélectionnées). Pour enrichir des sociétés à bureau de type « Unité locale », activer l'extension Unités locales en contactant le service client."
  }
}
