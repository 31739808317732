import React from 'react'
import { useTranslation } from 'react-i18next'
import PortfolioImage from '../../../assets/images/portfolio-management.svg'
import { Dialog } from '../../../components/Dialog'
import { Text } from '../../../components/Text'
import { Content } from './styled'

type Props = {
  saveQuery: () => void
  skipSaveQuery: () => void
  close: () => void
}

export const SaveBeforeLoadDialog: React.FC<Props> = ({ saveQuery, skipSaveQuery, close }) => {
  const { t } = useTranslation('portfolio')

  return (
    <Dialog
      title={t('LoadQuery.DialogTitle')}
      width={900}
      visible
      loading={false}
      footer=""
      cancelText={t('LoadQuery.SaveBefore.CancelBtn')}
      cancelButtonProps={{ onClick: skipSaveQuery }}
      onCancel={close}
      okText={t('LoadQuery.SaveBefore.OkBtn')}
      onOk={saveQuery}
    >
      <Content>
        <div className="text">
          <Text size="M" type="primary" bold block>
            {t('LoadQuery.SaveBefore.Question')}
          </Text>
          <Text size="M" block>
            {t('LoadQuery.SaveBefore.Description')}
          </Text>
        </div>
        <img src={PortfolioImage} alt="" />
      </Content>
    </Dialog>
  )
}
