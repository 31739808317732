import React from 'react'
import styled from 'styled-components/macro'
import { toLocalizedDateFormat } from '../../utils/formatters'
import { Text } from '../Text'
import { NotificationWithDate } from '../../features/notifications/types'
import { ReactComponent as Check } from '../../assets/icons/check-circle.svg'

export const TEXT_COLOR = 'ghost'

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 28px 8px 28px 24px;
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.dustyBlue};
`
export const NotificationWrapperClickable = styled(NotificationWrapper)`
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.17);
  }
`

export const DescriptionWrapper = styled.div`
  flex: 1 1 100%;
  overflow: hidden;
`

export const DateTextStyled = styled(Text)`
  display: block;
  margin: 0 0 8px 0;
`

export const ProgressMessageWrapper = styled.div`
  display: flex;
  width: 90%;
  margin-top: 1.5em;
  fill: ${({ theme }) => theme.colors.white};
`

export const NotificationDate = ({ notification }: { notification: NotificationWithDate }) => {
  return (
    <DateTextStyled bold type="dusty">
      {toLocalizedDateFormat(notification.dateTime)}
    </DateTextStyled>
  )
}

export const CheckIcon = styled(Check)`
  margin: auto 10px auto 0;
`

export const NoIcon = styled.div`
  width: 29px;
`
