import React from 'react'
import { RadioGroupProps } from 'antd3/lib/radio/interface'
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'
import { Radio } from '../Radio'

type HookRadioProps<T extends FieldValues> = UseControllerProps<T> & RadioGroupProps

export const HookRadioGroup = <T extends FieldValues>({
  control,
  name,
  rules,
  onChange,
  defaultValue,
  shouldUnregister,
  ...restProps
}: HookRadioProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, onChange: fieldOnChange } }) => {
        return (
          <Radio.Group
            onChange={e => {
              fieldOnChange(e.target.value)
              onChange?.(e)
            }}
            value={value}
            {...restProps}
          />
        )
      }}
    />
  )
}
