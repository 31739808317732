import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { deleteTask } from '../../../api/tasks'
import { getGoalsKeys } from '../../../api/goals'
import { DeleteTaskDialogData } from './types'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'
import { StyledDialog, StyledDialogBody, ContainerItem, BoldText } from '../components'
import { WithErrorCode } from '../../../api/common/types'

type Props = {
  data: DeleteTaskDialogData
  close: () => void
}

export const DeleteTaskDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()
  const [errorMessageCode, setErrorMessageCode] = useState<string>('Error')

  const {
    mutate: mutateDeleteTask,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation<AxiosResponse, AxiosError<WithErrorCode>>(() => deleteTask(data.taskId), {
    onSuccess: () => {
      queryClient.invalidateQueries(getGoalsKeys.GetGoals())
      queryClient.invalidateQueries(getGoalsKeys.GetGoal(data.goalId))
    },
    onError: (e: AxiosError<WithErrorCode>) => {
      if (e?.response?.status === 403) {
        if (e?.response?.data?.errorCode?.code === 'ActiveCampaignsError')
          setErrorMessageCode('ErrorAssignedToActiveCampaign')
        if (e?.response?.data?.errorCode?.code === 'ActiveActionsError')
          setErrorMessageCode('ErrorActiveActionsForSameOrganization')
      }
    }
  })

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:Goal.DeleteTask.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) {
          return mutateDeleteTask()
        }
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {(isIdle || isLoading) && (
            <div>
              <BoldText>{t('account:Goal.DeleteTask.Subtitle', { taskName: data.taskName })}</BoldText>
              <p>{t('account:Goal.DeleteTask.Body')}</p>
            </div>
          )}
          {isSuccess && <p>{t('account:Goal.DeleteTask.Success')}</p>}
          {isError && <p>{t(`account:Goal.DeleteTask.${errorMessageCode}`)}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
