import React from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import { useBranding } from '../../hooks/useBranding'
import { useViewportSize } from '../../hooks/useViewportSize'
import { canSeeSalesTool } from '../../features/config/selectors'
import { NotificationActions } from '../../features/notifications/actions'
import { hasNewNotifications as hasNewNotificationsSelector } from '../../features/notifications/selectors'
import logoLarge from '../../assets/images/big.svg'
import logoSmall from '../../assets/images/icon.svg'
import { Claim } from '../../authorization'
import * as routes from '../../routes'
import { gridTheme, HEADER_HEIGHT } from '../../utils/theme'
import { ClaimsWrapper } from '../ClaimsWrapper'
import { DrawerHeaderContainer } from '../Drawer'
import { ConditionallyVisible } from '../HideFeature'
import { DrawerNotifications } from '../Notification'
import { VisibleDrawer } from './types'
import { AccountInfo } from './AccountInfo'
import { NavigationMenu, MobileMenu } from './Menu'
import { ExpandedSearchBar } from './ExpandedSearchBar'
import { RightMenuBar } from './StyledComponents'
import { IconBar } from './IconBar'
import { HeaderSearch } from './HeaderSearch/HeaderSearch'
import { freeSearchEnabled, isLoggedIn } from '../../features/user/selectors'
import { AppointmentMenu } from './AppointmentMenu'

const MIN_SEARCH_LENGTH = 3

export const MainMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.navy}, ${({ theme }) => theme.colors.main});
  z-index: 44000;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    .header-menu-wrapper,
    .pc,
    .tablet {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }

  ${media.sm`
    .header-menu-wrapper,
    .pc,
    .mobile {
      display: none;
    }
    .tablet {
      display: flex;
    }
  `}

  ${media.md`
    .tablet,
    .mobile {
      display: none;
    }
    .header-menu-wrapper,
    .pc {
      display: flex;
    }
  `}
`

const Logo = styled(Link)`
  float: left;
  height: ${HEADER_HEIGHT}px;
  min-width: 188px;
  background-image: url(${logoLarge});
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    min-width: 80px;
    background-image: url(${logoSmall});
  }
`

export const MainMenu = () => {
  const { t } = useTranslation('header')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile, isMedium } = useViewportSize()
  const { allowedModules } = useBranding()

  const isUserLoggedIn = useSelector(isLoggedIn)
  const hasNewNotifications = useSelector(hasNewNotificationsSelector)
  const freeSearchAllowed = useSelector(freeSearchEnabled)
  const hasAccessSalesTool = useSelector(canSeeSalesTool)

  const canSeeCampaigns = hasAccessSalesTool && allowedModules.SalesTool

  const [isSearchOpen, setSearchOpen] = React.useState(false)
  const [currentDrawer, setVisibleDrawer] = React.useState(VisibleDrawer.None)
  const [searchText, setSearchText] = React.useState('')

  const setHasNewNotifications = React.useCallback(
    (value: boolean) => {
      dispatch(NotificationActions.setHasNewNotifications(value))
    },
    [dispatch]
  )

  const goToSearchPage = React.useCallback(
    (text: string) => {
      const trimmedSearchText = _.trim(text)
      navigate({ pathname: routes.SEARCH, search: `?query=${encodeURIComponent(trimmedSearchText)}` })
    },
    [navigate]
  )

  const toggleSearch = React.useCallback(() => {
    if (!isUserLoggedIn) return
    setSearchOpen(!isSearchOpen)
    setVisibleDrawer(VisibleDrawer.None)
  }, [isSearchOpen, isUserLoggedIn])

  const handleSearch = React.useCallback(
    (text: string) => {
      const trimmedSearchText = _.trim(text)
      if (trimmedSearchText.length < MIN_SEARCH_LENGTH) return
      goToSearchPage(trimmedSearchText)
      toggleSearch()
    },
    [goToSearchPage, toggleSearch]
  )

  return (
    <>
      <MainMenuWrapper>
        {(!isMobile || !isSearchOpen) && <Logo to={routes.DASHBOARD} />}
        {!isSearchOpen && <NavigationMenu hideLabels={isMedium} />}
        <RightMenuBar isSearchOpen={isSearchOpen}>
          <ClaimsWrapper claims={Claim.Prospecting}>
            {freeSearchAllowed && (
              <HeaderSearch
                isOpen={isSearchOpen}
                placeholder={t(canSeeCampaigns ? 'FreeSearchPlaceholderWithCampaigns' : 'FreeSearchPlaceholder')}
                onChange={setSearchText}
                onSubmit={handleSearch}
                toggleSearch={toggleSearch}
              />
            )}
          </ClaimsWrapper>
          <ConditionallyVisible
            condition={isSearchOpen}
            fallback={
              <IconBar
                hasNewNotifications={hasNewNotifications}
                setHasNewNotifications={setHasNewNotifications}
                drawer={currentDrawer}
                setDrawer={setVisibleDrawer}
              />
            }
          >
            <ExpandedSearchBar toggleSearch={toggleSearch} searchText={searchText} />
          </ConditionallyVisible>
        </RightMenuBar>
      </MainMenuWrapper>
      {isUserLoggedIn && (
        <>
          <DrawerHeaderContainer
            backgroundColor="main"
            onClose={() => setVisibleDrawer(VisibleDrawer.None)}
            visible={currentDrawer === VisibleDrawer.Appointment}
          >
            <AppointmentMenu />
          </DrawerHeaderContainer>

          <DrawerHeaderContainer
            onClose={() => setVisibleDrawer(VisibleDrawer.None)}
            visible={currentDrawer === VisibleDrawer.Notification}
          >
            <DrawerNotifications onClose={() => setVisibleDrawer(VisibleDrawer.None)} />
          </DrawerHeaderContainer>

          <DrawerHeaderContainer
            backgroundColor="main"
            onClose={() => setVisibleDrawer(VisibleDrawer.None)}
            visible={currentDrawer === VisibleDrawer.Account}
          >
            <AccountInfo />
          </DrawerHeaderContainer>
        </>
      )}
      <DrawerHeaderContainer
        backgroundColor="main"
        onClose={() => setVisibleDrawer(VisibleDrawer.None)}
        className="mobile"
        mobileMenu
        visible={currentDrawer === VisibleDrawer.MobileMenu}
      >
        <MobileMenu
          drawer={currentDrawer}
          hasNewNotifications={hasNewNotifications}
          setDrawer={setVisibleDrawer}
          setHasNewNotifications={setHasNewNotifications}
        />
      </DrawerHeaderContainer>
    </>
  )
}
