import _ from 'lodash'
import { AppointmentColor, AppointmentListItem } from '../../api/appointment/types'

const appointmentColors = new Map<AppointmentColor, string>([
  [AppointmentColor.Green, '#61bd4f'],
  [AppointmentColor.Yellow, '#f2d600'],
  [AppointmentColor.Orange, '#ff9f1a'],
  [AppointmentColor.Red, '#eb5a46'],
  [AppointmentColor.Violet, '#c377e0'],
  [AppointmentColor.Blue, '#0079bf'],
  [AppointmentColor.LightBlue, '#00c2e0'],
  [AppointmentColor.Turquoise, '#51e898'],
  [AppointmentColor.Pink, '#ff78cb'],
  [AppointmentColor.Black, '#344563']
])

export const getAppointmentColorHex = (color: AppointmentColor): string =>
  appointmentColors.get(color) ?? (appointmentColors.get(AppointmentColor.Black) as string)

export const isExpired = (now: moment.Moment, appointment: AppointmentListItem) => now.isAfter(appointment.end)

const isAppointmentInRange = (appointment: AppointmentListItem, rangeFrom: moment.Moment, rangeTo: moment.Moment) =>
  rangeFrom.isSameOrBefore(appointment.end) && rangeTo.isSameOrAfter(appointment.start)

export const getAppointmentsInRange = (
  appointments: AppointmentListItem[],
  rangeFrom: moment.Moment,
  rangeTo: moment.Moment,
  maxCount: number
) =>
  _.take(
    _.filter(appointments, appointment => isAppointmentInRange(appointment, rangeFrom, rangeTo)),
    maxCount
  )
