/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Back: 'Indietro',
  Continue: 'Continua',
  Search: 'Cerca',
  Cancel: 'Annulla',
  Ok: 'Ok',
  Save: 'Salva',
  Delete: 'Elimina',
  Move: 'Sposta',
  Edit: 'Modifica',
  Rename: 'Rinomina',
  Add: 'Aggiungi',
  Note: 'Note',
  Select: 'Seleziona',
  Hide: 'Nascondi',
  Show: 'Mostra',
  Visualise: 'Visualizza',
  HideDetail: 'Nascondi dettagli',
  ShowDetail: 'Mostra dettagli',
  Duplicate: 'Duplica',
  Download: 'Scarica',
  Collapse: 'Riduci',
  Loading: 'Caricamento',
  // COMPANY STATUS
  Prospect: 'Prospect',
  Lead: 'Lead',
  Client: 'Cliente',
  Supplier: 'Fornitore',
  Competitor: 'Competitor',
  RangeSelected: 'Range selezionato',
  Close: 'Chiudi',
  ViewDetails: 'Visualizza dettagli',
  SearchResults: {
    ResultsFor: 'Risultati per',
    ExcludeHiddenPortfolio: ' ad esclusione delle imprese presenti in portafoglio nascosti.',
    SendToSemanticSearch: 'Invia alla Ricerca Semantica',
    SendToSemanticSearchToolTip: 'Cerca la keyword nella Ricerca Semantica'
  },
  NumericRange: {
    DataNotAvailable: 'Informazioni non disponibili per le aziende analizzate',
    InvalidValue: 'Il valore inserito non è valido.',
    IntegersOnly: 'Inserire un valore intero.',
    MinGreaterThanMax: 'Il valore minimo è maggiore del massimo.',
    MaxLessThanMin: 'Il valore massimo è minore del minimo.',
    MinOutOfrange: 'Il valore minimo è fuori scala.',
    MaxOutOfrange: 'Il valore massimo è fuori scala.'
  },
  DateRange: {
    From: 'Da',
    To: 'A'
  },
  DomainFilter: {
    SaveAndClose: 'Salva filtri e chiudi',
    SeeAll: 'Tutti i {{count}} filtri',
    DeleteAll: 'Cancella tutti',
    Multiselection: 'multiselezione',
    SearchExact: 'Cerca descrizione esatta'
  },
  MapFilter: {
    ChoosePoint: 'Seleziona un punto direttamente sulla mappa',
    Range: 'Range',
    SelectedCircle: '{{distance}} da {{address}}',
    SelectedPolygon: 'Area selezionata su mappa',
    Address: 'Indirizzo',
    CompanyName: 'Ragione Sociale',
    FindMe: 'Trovami su mappa',
    SearchFromHere: 'Ricerca a partire da questo punto',
    GeolocationErrorTitle: 'Si è verificato un errore',
    GeolocationError: 'Questa opzione non è al momento disponibile.'
  },
  Reset: 'Resetta',
  SelectAll: 'Seleziona tutto',
  DeselectAll: 'Deseleziona tutto',
  DoYouWantToContinue: 'Vuoi procedere?',
  NotSwitch: 'Not',
  Yes: 'Sì',
  No: 'No',
  On: 'On',
  Off: 'Off',
  LoadMore: 'Carica altro',
  SeeAll: 'Mostra tutto',
  HideAll: 'Nascondi',
  Filters: {
    NoFilters: 'Non hai selezionato alcun filtro!',
    Select: 'Seleziona filtro',
    Filters: 'Filtri',
    SemanticClustersInfo: 'Prova il nuovo filtro Classi Settoriali Margò',
    SemanticClustersDeepActionInfo: 'Affina la tua ricerca con il nuovo filtro Classi Settoriali Margò',
    EditFilters: "Modifica i filtri per aggiornare l'elenco",
    StartNewSearch: 'Inizia una nuova ricerca',
    ResetFilters: 'Resetta tutti i filtri',
    UploadList: 'Carica lista',
    UploadListTooltip: 'Inserisci uno o più valori separati da spazio.',
    MostPopularFilters: 'Filtri più popolari',
    MostPopularFiltersText: 'Clicca qui per attivarli'
  },
  InsertTheNumber: 'Inserisci il numero',
  OrderBy: 'Ordina per',
  BackToLogin: 'Torna al login',
  SimpleUsefulPowerful: 'Semplice, utile e potente.',
  MargoWasSurprise: 'Margò è stata una meravigliosa sorpresa per me e la mia squadra.',
  Targeting: 'Analizza mercato',
  Prospecting: 'Trova clienti',
  PortfolioManagement: 'Portafoglio imprese',
  Actions: 'Azioni',
  GroupActions: {
    GroupActions: 'Azioni di gruppo',
    Actions: 'Azioni',
    Select: 'Seleziona',
    ToggleTooltip:
      'Attiva le Azioni di gruppo per selezionare le compagnie che vuoi applicando la spunta o facendo una selezione multipla. Potrai poi salvare le compagnie selezionate dal menu Azioni. Non sarà possibile selezionare alcun comando.',
    ButtonDisabledByGroupActions:
      "Attivando le Azioni di gruppo, non sarà possibile selezionare alcun comando. Per utilizzare questi pulsanti disattivare l'interruttore delle Azioni di gruppo.",
    DeactivateToggleTooltip: 'Disattiva le Azioni di gruppo per poter usare i comandi in cima alla pagina.'
  },
  Confirm: 'Conferma',
  IsEnriched: 'Arricchito',
  Logout: 'Esci',
  You: 'Tu',
  Legend: 'Legenda',
  Enrich: {
    Enrichment: 'Arricchimento',
    RecapDesc:
      "Arricchendo l'impresa con dati {{companyName}}, queste informazioni saranno scaricate e aggiornate periodicamente",
    CompaniesToBeEnriched: 'Impresa da arricchire',
    CompaniesToBeEnriched_plural: 'Impresa da arricchire',
    PortfolioInfo1: 'Questa impresa non è presente nella sezione Gestione del portafoglio. Aggiungilo al portafoglio.',
    PortfolioInfo2: 'Stai salvando i dati {{companyName}}, la impresa sarà già arricchita.',
    SelectPortfolio: 'Seleziona portafoglio',
    UploadedPortfolio: 'Portafoglio caricato',
    CreateNewPortfolio: 'Crea nuovo portafoglio',
    PortfolioName: 'Nome del portafoglio',
    EnrichmentComplete: 'Arricchimento completo'
  },
  ConfigGuardErrorPage: {
    Reload: 'Ricaricare',
    Subtitle: "Impossibile caricare la configurazione dell'applicazione.",
    Title: "Ci scusiamo per l'inconveniente"
  },
  Uploaded: 'Uploaded',
  Date: 'Data',
  Page: 'Pagina',
  Validating: 'Convalida',
  AutoLogout: {
    Title: 'Logout automatico',
    Text: 'A causa della tua inattività verrai disconnesso automaticamente in {{count}} secondi'
  },
  ForceLogout: {
    Title: 'Utente disconnesso',
    Desc: 'Sei stato sloggato perché la sessione è scaduta o una nuova sessione è stata iniziata in un altro browser o dispositivo'
  },
  PrivacyPolicy: 'Informativa sulla privacy',
  CookiesPolicy: 'Informativa sul utilizzo dei Cookies',
  SelectDate: 'Seleziona una data',
  queries: {
    loadQuery: {
      dialogTitle: 'Carica una ricerca salvata',
      OkBtn: 'Carica',
      title: 'Seleziona la ricerca salvata ',
      description: 'Scegli quale analisi intendi continuare; puoi ripartire da una tra quelle salvate',
      portfolioRadio: 'Ricerca da Portafoglio imprese',
      targetingRadio: 'Ricerca da Analizza mercato',
      prospectingRadio: 'Ricerca da Trova clienti',
      selectTitle: 'Ricerche salvate'
    }
  },
  SaveList: {
    CreateTagTitle: 'Crea tag',
    CreateTagLabel: 'Crea tag (opzionale)',
    CreateTagDesc: 'Ogni impresa verrà definita da un tag creato da te',
    InsertTag: 'Inserisci tag',
    SaveList: 'Salva lista',
    SaveOrCreate: 'Vuoi aggiungere la lista a un portafoglio specifico o vuoi crearne uno nuovo?',
    SelectPortfolio: 'Seleziona portafoglio',
    SaveListDesc: 'La lista verrà salvata automaticamente come prospect nella sezione "Gestione Portafoglio"',
    UploadedPortfolio: 'Portafogli caricati',
    CreateNew: 'Crea nuovo',
    SelectNumberOfCompanies:
      'Seleziona il numero di imprese che vuoi aggiungere al tuo portafoglio e ordinale in base ad uno dei criteri in elenco.',
    NumberOfCompanies: 'Numero di imprese',
    BackToFullList: "Torna indietro all'intera lista",
    SavingFullList: "Stai salvando l'intera lista di imprese",
    OrderByOptions: {
      Turnover: 'Turnover',
      NOfEmployees: 'N° di dipendenti',
      Ebit: 'Ebit'
    },
    SendToSalesInfo:
      "Prima di inviare l'elenco allo strumento di vendita è necessario creare un portfolio dall'elenco.",
    Enrichment: {
      Enrichment: 'Arricchimento',
      EnrichmentComplete: 'Arricchimento completato',
      EnrichmentDescription:
        "Arricchendo l'impresa con dati {{companyName}}, queste informazioni saranno scaricate e aggiornate periodicamente",
      CompaniesInList: 'Imprese in questa lista',
      CompaniesEnriched: 'Imprese già arricchite',
      CompaniesNotEnriched: 'Imprese non arricchite',
      CreditsWillBeUsed: 'Rings usati',
      CreditsRemaining: 'Rings residui'
    },
    SaveListMonitorDesc:
      'In caso di portafoglio monitorato è possibile trovare la query di tracciamento salvata nel proprio account'
  },
  ExcludePortfolio: {
    BasedOn: 'Basato su',
    SelectedPortfolios: 'Portafogli selezionati',
    AllCompanyInCrifIta: 'Tutte le imprese Italiane',
    AllCompanyInCrifDeu: 'Tutte le imprese tedesche',
    AllCompanyInCrifCzsk: 'Imprese ceche e slovacche',
    AllCompanyInCrifChe: 'Tutte le imprese Svizzere',
    AllCompanyInCrifAut: 'Tutte le imprese Austriache',
    AllCompanyInCrifCheAut: 'Tutte le imprese Austriache e Svizzere',
    ExcludePortfolio: 'Escludi portafoglio',
    ExcludeCampaign: 'Escludi campagna',
    ExcludeEnrichedCompanies: 'Escludi imprese arricchite',
    ExcludeHiddenPortfolio: 'ad esclusione di quelle presenti in portafogli nascosti',
    Configuration: 'la configurazione impostata sulla tua utenza',
    AndConfiguration: 'e la configurazione impostata sulla tua utenza'
  },
  User: {
    Role: {
      Supervisor: 'Supervisor',
      AreaManager: 'Area Manager',
      Sales: 'Sales'
    },
    UserName: 'Nome Utente'
  }
}
