import { rootReducer } from './reducers'
import { StateType } from '../utils/types'

export type RootState = StateType<typeof rootReducer>

export const PortfolioStatusFieldValueId = 'customFilters.0.fieldValue'

export const CompanyHqFilterData = {
  fieldId: 'companyUnitTypeCode',
  value: 'HQ'
}
