import { ReactComponent as ItIcon } from '../../assets/icons/it.svg'
import { ReactComponent as GbIcon } from '../../assets/icons/gb.svg'
import { ReactComponent as DeIcon } from '../../assets/icons/de.svg'
import { ReactComponent as SkIcon } from '../../assets/icons/sk.svg'
import { ReactComponent as CzIcon } from '../../assets/icons/cz.svg'
import { ReactComponent as FrIcon } from '../../assets/icons/fr.svg'

type LanguageIconProps = {
  langKey: string
}

export const getLanguageIconCmp = ({ langKey }: LanguageIconProps) => {
  switch (langKey) {
    case 'en-GB':
      return GbIcon
    case 'it-IT':
      return ItIcon
    case 'de-DE':
      return DeIcon
    case 'sk-SK':
      return SkIcon
    case 'cs-CZ':
      return CzIcon
    case 'fr-FR':
      return FrIcon
    default:
      return GbIcon
  }
}
