import React from 'react'
import { FormState, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { TextAreaInputField } from '.'
import { Text } from '../Text'
import { getErrorMessage } from '../../features/form/utils'
import { emptyOrLength, length } from '../../utils/validators'

const Label = styled.div`
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.33;
  color: ${({ theme: { colors } }) => colors.main};
`

type Props = {
  formState: FormState<any>
  editing: any
  currentNote: any
  name: string
  text: string
  label: string
  labelComponent?: boolean
  placeholder: string
} & Pick<UseControllerProps, 'control'>

export const AddNoteDialogForm = ({
  control,
  editing,
  formState,
  currentNote,
  name,
  text,
  label,
  labelComponent,
  placeholder
}: Props) => {
  const { t } = useTranslation('error')

  return (
    <>
      {!editing && <Text>{text}</Text>}
      {labelComponent ? <Label>{label}</Label> : null}
      <TextAreaInputField
        defaultValue={editing ? currentNote : null}
        hideLabel
        name={name}
        label={label}
        placeholder={placeholder}
        rows={4}
        noResize
        control={control}
        formState={formState}
        rules={
          editing
            ? {
                validate: {
                  length: getErrorMessage(emptyOrLength({ min: 3, max: 250 }), name, t)
                }
              }
            : {
                validate: {
                  length: getErrorMessage(length({ min: 3, max: 250 }), name, t)
                }
              }
        }
      />
    </>
  )
}
