import axios, { CancelToken } from 'axios'
import { PersonResponse } from './types'

export const fetchPerson = (id: string, cancelToken?: CancelToken) =>
  axios
    .post<PersonResponse>(
      '/api/Report/get-data',
      {
        identificationCode: id,
        reportDataType: 'PersonReport'
      },
      {
        allowedErrorStatuses: [403],
        cancelToken
      }
    )
    .then(resp => resp.data)

export const fetchPersonEnrichmentProposal = async (identificationCode: string, cancelToken?: CancelToken) =>
  axios.post<number>(
    '/api/Report/get-data',
    {
      identificationCode,
      reportDataType: 'PersonReport',
      requestBody: { enrichmentProposal: true }
    },
    {
      cancelToken
    }
  )

export const enrichPerson = (identificationCode: string, cancelToken?: CancelToken) =>
  axios.post(
    '/api/Report/get-data',
    {
      identificationCode,
      reportDataType: 'PersonReport',
      requestBody: { doEnrichment: true }
    },
    {
      allowedErrorStatuses: true,
      cancelToken
    }
  )
