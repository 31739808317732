import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getFreeSearchSuggestions } from './api'
import { SuggestionsRequest, SuggestionsResponse } from './types'

export const getSuggestionsKeys = {
  All: () => [{ level1: 'suggestions' }] as const,
  FreeSearchSuggestions: () => [{ ...getSuggestionsKeys.All()[0], level2: 'freeSearch' }] as const,
  FreeSearchSuggestionsWithData: (request: SuggestionsRequest) =>
    [{ ...getSuggestionsKeys.FreeSearchSuggestions()[0], request }] as const
}

export const useGetSuggestions = (
  requestData: SuggestionsRequest,
  options?: UseQueryOptions<
    SuggestionsResponse,
    AxiosError,
    SuggestionsResponse,
    ReturnType<typeof getSuggestionsKeys.FreeSearchSuggestionsWithData>
  >
) =>
  useQuery(
    getSuggestionsKeys.FreeSearchSuggestionsWithData(requestData),
    ({ queryKey: [queryKeys] }) => getFreeSearchSuggestions(queryKeys.request),
    options
  )
