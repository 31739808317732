/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  SalesTool: {
    Header: 'Obchodní aktivity',
    Management: {
      Sales: 'Obchodníci',
      SalesSgCzsk: 'Obchodník',
      Campaigns: 'Kampaně',
      SearchCampaign: 'Vyhledat kampaň',
      SearchCampaignLong: 'Vyhledat název kampaně',
      NewCampaign: 'Vytvořit kampaň',
      NewCampaignTooltip: 'Naplánujte komerční kampaň vycházející z portfolia',
      CampaignArchive: 'Archiv kampaní',
      CampaignArchiveTooltip: 'Otevřete  archiv ukončených kampaní a obnovte je',
      CampaignsToAssign: 'Přiřadit',
      CampaignsToStart: 'Zatím nespuštěné',
      CampaignsActive: 'Spuštěné',
      CampaignsArchived: 'Archivované',
      Stats: {
        Companies_0: 'Společnost',
        Companies_1: 'Společnosti',
        Companies_2: 'Společností',
        StartDate: 'Datum začátku',
        EndDate: 'Datum konce',
        EnrichedCompanies: 'Obohacené společnosti',
        Clients: 'Klienti',
        Suppliers: 'Dodavatelé',
        Prospects: 'Prospekty',
        LastUpdate: 'Poslední aktualizace',
        Competitors: 'Konkurenti',
        Lead: 'Lead'
      },
      Table: {
        NumberOfCompanies: 'Počet společností',
        GoalName: 'Název cíle',
        Tasks: 'Úlohy',
        Status: 'Status',
        CampaignPortfolios: 'Portfolia v této kampani'
      },
      PorfolioBox: 'Portfolio v této kampani',
      ConversionRate: '{{percentage}}% konverzní poměr',
      CompletedTasks: '{{percentage}}% ukončených úloh',
      Banner: {
        Title: 'Pro přiřazení seznamu musíte "kliknout" na Název kampaně',
        Subtitle: 'Před zahájením kampaně přiřaďte obchodníky a produkty každé společnosti v seznamu.'
      },
      CreatedAtAscending: 'Datum vzniku (Vzestupně)',
      CreatedAtDescending: 'Datum vzniku (Klesající)',
      StartDateAscending: 'Datum začátku (Vzestupně)',
      StartDateDescending: 'Datum začátku (Klesající)',
      EndDateAscending: 'Datum konce (Vzestupně)',
      EndDateDescending: 'Datum konce (Klesající)'
    },
    AddCampaign: {
      EnterName: 'Vložte reprezentativní jméno kampaně',
      EnterNameDescription: 'Spotřebujete jeden ring na každou kampaň, kterou vytvoříte',
      EnterDates: 'Vyberte datum začátku a konce',
      EnterDatesDescription:
        'Počáteční a konečné datum bude vždy zobrazeno s kampaní a pomůže vám dosáhnout obchodních cílů',
      withoutCustomVariables: 'bez vlastních proměnných',
      withCustomVariables: 's vlastníma proměnnými',
      EnterPortfolio: 'Vyberte portfolio, které chcete připojit ke kampani',
      EnterPortfolioDescription: 'Přiřaďte jedno nebo více portfolií ke kampani, kterou jste právě vytvořili.',
      EnterGoal: 'Vyberte cíl kampaně',
      EnterGoalDescription: 'Vyberte cíl přidělený k popsaným úlohám.',
      NoGoalDescription: 'Dosud není uložen žádný cíl. Vytvořte si jej prosím v sekci <0>Váš účet</0>.',
      InvalidGoal:
        'Zvolený cíl nemá přiřazeny žádné úkoly. Prosím vyberte další cíl nebo upravte v sekci <0>Váš účet</0>.',
      EnterConversionRate: 'Vložte popis a nastavte konverzní poměr',
      EnterConversionRateDescription:
        'Přidejte charakteristický popis kampaně, kterou vytváříte. Dá vám více informací o cíli, který chcete dosáhnout.',
      CampaignCreationPendingTitle: 'Vytvoření kampaně',
      CampaignCreationPendingText:
        'Vaše kampaň se vytváří. Budete přesměrováni do Obchodních aktivit a po dokončení operace dostanete notifikaci.',
      SalesPerformanceChartTitle: 'Chcete zviditelnit graf „Výkon prodeje“ pro uživatele prodeje?',
      SalesPerformanceChartText: 'Povolit graf',
      RestoreDataQuestion: 'Chcete obnovit všechna data spojená s touto kampaní před uplynutím její platnosti?',
      RestoreDataEnable: 'Povolit všechna předchozí data',
      RestoreDataEnableDisabledTooltip:
        'Původní cíl pro tuto kampaň nelze vybrat, protože byl smazán. Vyberte nový z dostupných cílů.'
    }
  },
  ManageCampaign: {
    Header: 'Management prodeje a produktu',
    SalesHeader: 'Management kampaně'
  },
  Management: {
    Message: {
      Header: 'Vytvořte kampaň, přiřaďte ji vašim obchodníkům a spusťte ji',
      SubHeader: 'Pro vytvoření kampaně stiskněte tlačítko "Vytvořit kampaň"',
      Description:
        'Po vytvoření kampaně můžete přiřadit společnosti obchodníkům a začít. Na této stránce naleznete seznam všech vašich kampaní.'
    },
    Campaign: {
      ChangeCampaignName: 'Změnit název kampaně',
      ChangeCampaignNameShort: 'Změnit jméno',
      ChangeCampaignNote: 'Změnit poznámku kampaně',
      AddCampaignNote: 'Přidat poznámku',
      ChangeCampaignNoteDescription: 'Změnit poznámku k popisu konverzního poměru.',
      AddCampaignNoteDescription: 'Vložit poznámku k popisu konverzního poměru.',
      EnterARepresentativeName: 'Vložit odpovídající jméno pro kampaň',
      InsertCampaignName: 'Vložit jméno kampaně',
      InsertCampaignNote: 'Vlotit poznámku',
      NameChangedCorrectly: 'Jméno bylo změněno',
      NoteChangedCorrectly: 'Poznámka byla změněna',
      NoteRemovedCorrectly: 'Poznámka byla odstraněna',
      NoteAddedCorrectly: 'Poznámka byla přidána',
      ChangeEndDate: 'Změnit datum konce',
      ChangeEndDateDescription: 'Změnit aktuální datum ukončení.',
      ChangeEndDateSuccess: 'bude ukončeno dne',
      ChangeStartDate: 'Změnit datum začátku',
      ChangeStartDateDescription: 'Změnit aktuální datum začátku.',
      ChangeStartDateSuccess: 'začne',
      StartCampaignDialogText1: 'Chcete začít s touto kampaní?',
      StartCampaignDialogText2: 'Vývoj celé kampaně uvidíte v přehledu Obchodních aktivit.',
      StartCampaignDialogText3:
        'Po kliknutí na "Pokračovat" dostane každý obchodník notifikaci o "přiřazení společnosti"".',
      StartCampaignDoneDialogText1: '{{campaignName}} začne {{startDate}}.',
      StartCampaignDoneDialogText2: 'Každý obchodník dostane notifikaci o "přiřazení společnosti"',
      StopCampaignDialogText1: 'Chcete ukončit tuto kampaň?',
      StopCampaignDialogText2: 'Bude umístěna do části "Archiv kampaně" a můžete ji kdykoliv "Obnovit".',
      StopCampaignDialogText3: 'Pro zobrazení "Archiv kampaně" se musíte vrátit zpět na dashboard.',
      StopCampaignDoneDialogText1: '{{campaignName}} byla ukončena, najdete ji v části "Archiv kampaně".',
      DeleteCampaignDialogText1: 'Opravdu chceted smazat {{campaignName}}?',
      DeleteCampaignDialogText2: 'Všechny výsledky kampaně budou nyní smazány.',
      DeleteCampaignDoneDialogText1: '{{campaignName}} byla vymazána.'
    }
  },
  AssignCampaign: {
    Header: 'Přiřadit Sales a produkt',
    StartDate: 'Datum začátku:',
    EndDate: 'Datum konce:',
    AddPortfolio: 'Přidejte společnosti/portfolio',
    AddPortfolioTooltip: 'Přidejte do této kampaně nové společnosti',
    SelectColumns: 'Vybrat sloupce',
    GroupActions: 'Skupinová akce',
    SalestoolAssignmentsSelectPlaceholder: 'Vybrat',
    AssignSales: 'Přiřadit obchodníky',
    AssignProduct: 'Přiřadit produkt',
    NotAssigned: 'Nepřiřazen',
    Sales: 'Sales',
    Product: 'Produkt',
    Note: 'Poznámky',
    EditNoteIconTitle: 'Edituj poznámku',
    CompaniesCount_0: 'Společnost',
    CompaniesCount_1: 'Společnosti',
    CompaniesCount_2: 'Společností',
    AssignedCount_0: 'Přiřazená',
    AssignedCount_1: 'Přiřazené',
    AssignedCount_2: 'Přiřazených',
    NotAssignedCount_0: 'Nepřiřazená',
    NotAssignedCount_1: 'Nepřiřazené',
    NotAssignedCount_2: 'Nepřiřazených',
    AppAround: {
      NotAlreadyDone: 'Žádná nabídka zatím nebyla odeslána',
      InProgress: 'Nabídka byla podána {{dateTime}}',
      Ko: 'Chyba v nabídce {{dateTime}}',
      Ok: 'Nabídka odeslána {{dateTime}}'
    },
    Inventia: {
      NotAlreadyDone: 'Nebyly zahájeny žádné hovory',
      InProgress: 'Hovor byl zahájen {{dateTime}}',
      Ko: 'Chyba v hovoru, zapnuto {{dateTime}}',
      Ok: 'Hovor uskutečněn {{dateTime}}'
    },
    Banner: {
      Title: 'Klikněte na "Přiřadit kampaň" pro přiřazení obchodníků a produktů',
      Subtitle:
        'Před zahájením kampaně přiřaďte obchodníky a produkty ke všem společnostem na seznamu. Na konci klikněte na "Uložit" pro dokončení operace, kampaň se spustí po stisknutí "Začít kampaň".'
    },
    WriteYourNote: 'Zde uveďte své poznámky',
    Dialog: {
      SelectAtLeastOneCompany: 'Musíte vybrat alespoň jednu společnost.',
      AssignSaleTitle: 'Přiřadit obchodníka',
      AssignProductTitle: 'Přiřadit produkt',
      AssignSaleOrProductMultiple_0: '{{item}} přiřazen k {{count}} společnosti.',
      AssignSaleOrProductMultiple_1: '{{item}} přiřazen {{count}} společnostem.',
      AssignSaleOrProductMultiple_2: '{{item}} přiřazen {{count}} společnostem.',
      DuplicateCompanyTitle: 'Duplicitní společnost',
      DuplicateCompanyConfirm: 'Opravdu chcete duplikovat {{companyName}}?',
      DuplicateCompanySelection: 'Pro duplikování společnosti je třeba změnit její status nebo produkt.',
      DuplicateCompanyDone: '{{companyName}} správně duplikována v rámci {{campaignName}}.',
      DuplicateCompanySelectProduct: 'Vybrat produkt',
      DuplicateCompanySelectStatus: 'Vybrat status',
      DuplicateCompanyProduct: 'Produkt',
      DuplicateCompanyStatus: 'Status',
      SaveCampaignTitle: 'Uložit kampaň',
      SaveCampaignPending: 'Probíhá ukládání, operace může trvat několik minut. Po dokončení dostanete notifikaci',
      AddCompaniesTitle: 'Přidávání společností',
      AddPortfolioTitle: 'Přidejte společnosti/portfolio',
      AddPortfolio: 'Vybrat portfolio, které chcete přidat',
      AddPortfolioSelectPortfolio: 'Vybrat portfolio',
      AddCompaniesPending:
        'Přidávání společností do kampaně {{campaignName}} probíhá. Na konci operace obdržíte oznámení.',
      AddCompaniesWithCustomVariablesStructureChangedPending:
        'Probíhá přidávání společností do {{campaignName}}. Některé vlastní proměnné nebudou vloženy kvůli neshodě struktury proměnných. Na konci operace obdržíte upozornění.',
      AddPortfolioPending:
        '{{selectedPortfolioName}} se přidává k {{campaignName}}. Po dokončení operace dostanete notifikaci.',
      AddCompanyNoteTitle: 'Poznámky',
      AddCompanyNote: 'K této společnosti můžete přidat 5 poznámek.',
      AddCompanyNoteTextAreaLabel: 'Vložiť novou poznámku (max. 250 znaků)',
      AddCompanyNoteTextAreaPlaceholder: 'Vaše poznámka...',
      AddNewCompaniesFromStartingPortfolio: 'Přidejte nové společnosti z počátečního portfolia',
      AddedCompanies: 'Přidané společnosti',
      AddedPortfolio: 'Přidané portfólia',
      AddPortfolioSelect: 'Vybrat',
      DeleteNoteConfirmation: 'Opravdu chcete smazat tuto poznámku?',
      DeleteCompany: {
        Title: 'Vymazat společnost',
        Text: 'Opravdu chcete odstranit společnost z kampaně?',
        Success: 'Společnost byla úspěšně odstraněna z kampaně.',
        Error: 'Při odstraňování společnosti z kampaně došlo k chybě.'
      },
      MoveCompany: {
        Title: 'Přesunout společnost do jiné kampaně',
        Text: 'Společnost, kterou jste vybrali, bude odstraněna z aktuální kampaně a přidána do jiné kampaně. Vyberte prosím jednu kampaň ze seznamu níže.',
        SelectLabel: 'Vyberte kampaň',
        Footer: 'Obchodník a produkt přiřazený ke společnosti budou smazány',
        Success: 'Společnost byla úspěšně přesunuta do kampaně.',
        Error: 'Při přesunu společnosti do kampaně došlo k chybě.',
        ErrorIncompatibleDestEntity: 'Struktura vlastních proměnných v této kampani není kompatibilní s původní.'
      }
    },
    Errors: {
      DuplicateCampaign: 'Při duplikování společnosti se vyskytla chyba.',
      DuplicateCampaignMissingPortfolio:
        'Společnost nelze duplikovat, protože portfolio, do kterého patří, už neexistuje.',
      AddPortfolio: 'Při přidávání vybraného portfolia se vyskytla chyba.',
      AddCompanies: 'Při přidávání nových společností z počátečního portfolia došlo k chybě.'
    }
  },
  SalesPerformance: {
    Banner: {
      Title: 'Vaše prodejní výsledky',
      Subtitle: 'budou viditelné v tomto poli pro každou úlohu'
    },
    Tooltip: 'Toto pole ukazuje top 5 obchodníků vybrané úlohy',
    Actions: 'Akce',
    TotalProgressOrder: 'Celkový pokrok',
    LastUpdateOrder: 'Poslední aktualizace',
    NumberCompaniesOrder: ' Počet přiřazených společností'
  },
  CampaignDetail: {
    MapBoxTitle: 'Zeměpisná distribuce',
    CompaniesBoxTitle: 'Společnosti v úloze ',
    SalesBoxTitle: 'Výkonnost prodeje',
    NoteLabel: 'Poznámka',
    Task: 'Úloha',
    TasksStatisticsLabel_0: 'společnost',
    TasksStatisticsLabel_1: 'společnosti',
    TasksStatisticsLabel_2: 'společností',
    AssignTask: 'Přiřadit úlohu',
    SalesPerformanceNCompanies: 'Počet společností',
    WaterfallChartTasksCompleted: 'dokončeno',
    WaterfallChartBoxTitle_0: '{{count}} společnost {{percentage}}% dokončeno',
    WaterfallChartBoxTitle_1: '{{count}} společnosti {{percentage}}% dokončeno',
    WaterfallChartBoxTitle_2: '{{count}} společností {{percentage}}% dokončeno',
    AllCompaniesLabel: 'Vše',
    AssignedCompaniesLabel: 'Přiřazeno',
    UnassignedCompaniesLabel: 'Nepřiřazeno',
    Dialog: {
      AssignTaskTitle: 'Přiřadit úlohu'
    },
    ShowSalesDetail: 'Zobrazit detail obchodníka'
  },
  CompaniesOnTask: {
    Void: 'Neplatná',
    Called: 'Kontaktovaná',
    Visited: 'Navštívená',
    Negotiation: 'Vyjednávání',
    Active: 'Aktivní',
    Negative: 'Negativní'
  },
  CompanyDetail: {
    Actions: {
      DownloadPDF: 'Stáhnout PDF',
      AddToCampaign: 'Přidat ke kampani'
    }
  },
  CampaignArchive: {
    Subtitle:
      'Obsahuje zastavené a dokončené kampaně. Po kliku na "Obnovit kampaň" se kampaň obnoví s identickým nastavením (přiřazením) ',
    SubtitleSale: 'Obsahuje zastavené a dokončené kampaně.',
    RenewCampaign: 'Obnovit kampaň',
    DeleteCampaign: 'Smazat'
  },
  EnrichBeforeRenew: 'Before starting the campaign, please enrich the following portfolios:',
  SaleDetail: {
    Area: 'Oblast',
    CampaignsView: 'Přehled kampaní',
    Summary: 'Souhrn',
    UpdateCampaignBtn: 'Aktualizovat kampaň',
    TotalCompanies: 'Společností celkem',
    TotalCampaigns: 'Kampaní celkem',
    ActiveCampaigns: 'Aktivní kampaně',
    ArchivedCampaigns: 'Archivované kampaně',
    CompletedCampaigns: 'Dokončené kampaně',
    AssignTaskDialogText1: 'Chcete změnit úlohu?',
    AssignTaskDialogText1_noPrevTask: 'Chcete přiřadit úlohu?',
    AssignTaskDialogText2: 'Měníte úlohu z "{{originalTask}}" na "{{newTask}}".',
    AssignTaskDialogText2_noPrevTask: 'Přiřazujete "{{newTask}}".',
    AssignTaskDoneDialogText1: 'Úloha byla změněna!',
    AssignTaskDoneDialogText1_noPrevTask: 'Úloha byla přiřazena!',
    AssignTaskDoneDialogText2: 'Váš manažer dostane notifikaci.',
    AssignTaskMultipleDoneDialogText1: 'Vybrané úlohy se přiřazují.',
    AssignTaskMultipleDoneDialogText2: 'Po dokončení dostane váš manažer notifikaci.',
    AssignTasksDialogText_0: 'Přiřazujete "{{newTask}}" k {{fCount}} společnosti.',
    AssignTasksDialogText_1: 'Přiřazujete "{{newTask}}" k {{fCount}} společnostem.',
    AssignTasksDialogText_2: 'Přiřazujete "{{newTask}}" k {{fCount}} společnostem.',
    AssignTaskDialogHidePopupCheckbox: 'Tuto zprávu již nezobrazovat'
  },
  ItemNotAssignedYet: 'Nepřiřazen',
  LoadMore: 'Načíst více',
  DownloadBtn: 'Stáhnout PDF/Excel',
  DownloadBtnTooltip: 'Stáhněte si přehled této kampaně do svého zařízení',
  AssignCampaignBtn: 'Přiřadit kampaň',
  AssignCampaignBtnTooltip: 'Přiřaďte obchodníky a produkty společnostem v této kampani',
  ManageCampaignBtn: 'Spravovat kampaň',
  ManageCampaignBtnTooltip: 'Přiřaďte obchodníky a produkty společnostem v této kampani',
  StartCampaignBtn: 'Začít kampaň',
  StartCampaignBtnTooltip: 'Povolte obchodníkům viditelnost kampaně a sledujte výkon',
  StopCampaignBtn: 'Zastavit kampaň',
  StopCampaignBtnTooltip: 'Ukončete tuto kampaň a archivujte ji',
  DeleteCampaign: 'Vymazat kampaň',
  CampaignName: 'Název kampaně',
  StartDate: 'Začátek kampaně',
  EndDate: 'Konec kampaně',
  Name: 'Název',
  PortfolioLabel: 'Portfolio',
  GoalLabel: 'Vybrat cíl',
  ConversionRateLabel: 'Nastavit procento konverzního poměru',
  Close: 'Zavřít',
  Save: 'Uložit',
  SaveTooltip: 'Uložte změny provedené v této kampani',
  CreateCampaign: 'Vytvořit kampaň',
  RenewCampaign: 'Obnovit kampaň',
  DomainFilter: {
    SaveAndClose: 'Uložit a zavřít'
  },
  Table: {
    Empty: 'Nenašli jsme žádné společnosti, které odpovídají vašemu vyhledávání'
  },
  Errors: {
    CampaignSave: 'Při vytváření kampaně se vyskytla chyba.'
  },
  GenericErrorTitle: 'Vyskytla se chyba',
  Download: {
    PDFHeaderTitle: 'Report kampaně',
    DownloadTitle: 'Stáhnout PDF/Excel',
    DownloadPDF: 'Stáhnout PDF',
    DownloadExcel: 'Stáhnout Excel',
    SelectTypeOfList: 'Stáhnout PDF/Excel',
    SelectTypeOfListDescription:
      'Kompletní report kampaně můžete stáhnout ve dvou různých formách. Vyberte typ, který chcete stáhnout.',
    CompaniesInCampaign: 'Společnosti v kampani',
    AssignedCompanies: 'Přiřazené společnosti',
    ActiveCompanies: 'Společnosti nově "Aktivní"',
    Pdf: 'PDF',
    PdfDescription: 'Stáhnout v .PDF formátu',
    Excel: 'Excel',
    ExcelDescription: 'Stáhnout v .XlS formátu',
    DownloadExcelTitle:
      'Stahujete soubor, který obsahuje všechny {{companyName}} data a výsledky této kampaně v podobě seznamu.',
    DownloadExcelDescription: 'Soubor bude uložen v sekci "Používání".',
    DownloadPDFPleaseWait: 'Počkejte prosím. Váš pdf soubor se generuje.',
    DownloadExcelPendingMessage:
      'Požadovaný soubor se zpracovává, může to trvat několik minut.\nPrůběh akce můžete sledovat v sekci Notifikace.'
  },
  Actions: {
    GoToCampaign: 'Přejít na kampaň'
  },
  EmptyCampaigns: 'Žádná kampaň nenalezena'
}
