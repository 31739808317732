import _ from 'lodash'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { cacheAllTheTime } from '../../features/queryclient'
import { ApplicationModule, PromisedType } from '../../types'
import { getCustomTrack, getCustomTracksList, getExportStructure, getDownloadProposal } from './api'
import { DownloadProposalRequest, GetCustomTracksRequest } from './types'

export const getPortfolioDownloadKeys = {
  All: () => [{ level1: 'portfolioDownload' }] as const,
  GetCustomTracksList: (params: GetCustomTracksRequest) =>
    [{ ...getPortfolioDownloadKeys.All()[0], level2: 'getCustomTracksList', params }] as const,
  GetCustomTracks: (params?: GetCustomTracksRequest) =>
    [{ ...getPortfolioDownloadKeys.All()[0], level2: 'getCustomTracks', params }] as const,
  GetCustomTrack: (customTrackId?: string) =>
    [{ ...getPortfolioDownloadKeys.All()[0], level2: 'getCustomTrack', customTrackId }] as const,
  GetExportStructure: (module: ApplicationModule) =>
    [{ ...getPortfolioDownloadKeys.All()[0], level2: 'getExportStructure', module }] as const,
  GetDownloadProposal: (portfolioId: string, request: DownloadProposalRequest) =>
    [{ ...getPortfolioDownloadKeys.All()[0], level2: 'getDownloadProposal', portfolioId, request }] as const
}

export const useGetCustomTracksList = (
  params: GetCustomTracksRequest,
  options?: UseQueryOptions<
    PromisedType<typeof getCustomTracksList>,
    unknown,
    PromisedType<typeof getCustomTracksList>,
    ReturnType<typeof getPortfolioDownloadKeys.GetCustomTracksList>
  >
) => {
  const { data, isLoading } = useQuery(
    getPortfolioDownloadKeys.GetCustomTracksList(params),
    ({ queryKey: [queryKeys] }) => {
      return getCustomTracksList(queryKeys.params)
    },
    {
      ...options
    }
  )
  return {
    data: data ?? [],
    isLoading,
    hasNextPage: false,
    fetchNextPage: _.noop,
    isFetchingNextPage: false
  }
}

export const useGetCustomTracks = (
  params?: GetCustomTracksRequest,
  options?: UseQueryOptions<
    PromisedType<typeof getCustomTracksList>,
    unknown,
    PromisedType<typeof getCustomTracksList>,
    ReturnType<typeof getPortfolioDownloadKeys.GetCustomTracks>
  >
) => {
  return useQuery(
    getPortfolioDownloadKeys.GetCustomTracks(params),
    ({ queryKey: [queryKeys] }) => {
      return getCustomTracksList(queryKeys.params)
    },
    {
      ...options
    }
  )
}

export const useGetCustomTrack = (
  customTrackId?: string,
  options?: UseQueryOptions<
    PromisedType<typeof getCustomTrack>,
    unknown,
    PromisedType<typeof getCustomTrack>,
    ReturnType<typeof getPortfolioDownloadKeys.GetCustomTrack>
  >
) => {
  return useQuery(
    getPortfolioDownloadKeys.GetCustomTrack(customTrackId),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.customTrackId === undefined) return undefined

      return getCustomTrack(queryKeys.customTrackId)
    },
    {
      ...options
    }
  )
}

export const useGetExportStructure = (
  module: ApplicationModule,
  options?: UseQueryOptions<
    PromisedType<typeof getExportStructure>,
    unknown,
    PromisedType<typeof getExportStructure>,
    ReturnType<typeof getPortfolioDownloadKeys.GetExportStructure>
  >
) =>
  useQuery(
    getPortfolioDownloadKeys.GetExportStructure(module),
    ({ queryKey: [queryKeys] }) => getExportStructure(queryKeys.module),
    {
      ...options
    }
  )

export const useGetDownloadProposal = (portfolioId: string, request: DownloadProposalRequest) =>
  useQuery(
    getPortfolioDownloadKeys.GetDownloadProposal(portfolioId, request),
    ({ queryKey: [queryKeys] }) => getDownloadProposal(queryKeys.portfolioId, queryKeys.request),
    cacheAllTheTime
  )
