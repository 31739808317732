import _ from 'lodash'
import { useQuery, useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query'
import { cacheAllTheTime } from '../../features/queryclient'
import { SortItem } from '../../types'
import {
  getCustomerCompaniesVisibility,
  getCustomerCompanyVisibilityQuery,
  getUsersByDistinguishName,
  getCustomerCompanyVisibilityBranches,
  getCompanyVisibilityFiltersDefinitions
} from './api'
import { CustomerCompanyVisibilityQuery, ConfigurationVisibilityType } from './types'

export const getConfigurationKeys = {
  All: () => [{ level1: 'customerCompanyVisibility' }] as const,
  GetCustomerCompaniesVisibility: () =>
    [{ ...getConfigurationKeys.All()[0], level2: 'getCustomerCompaniesVisibility' }] as const,

  GetInfiniteCustCompVisibilityWithParameters: (
    top: number,
    querytype: ConfigurationVisibilityType,
    sorting: SortItem | undefined,
    searchText: string | undefined
  ) =>
    [
      {
        ...getConfigurationKeys.GetCustomerCompaniesVisibility()[0],
        level3: 'getInfiniteCustCompVisibility',
        top,
        querytype,
        sorting,
        searchText
      }
    ] as const,
  GetBranches: () => [{ ...getConfigurationKeys.All()[0], level2: 'getCustomerCompanyVisibilityBranches' }] as const,
  GetUserByDistinguishName: (distinguishName: string | undefined) =>
    [{ ...getConfigurationKeys.All()[0], level2: 'getUserByBranchOrUserDistinguishName', distinguishName }] as const,
  GetCustomerCompanyVisibilityQuery: (configurationId?: string) =>
    [{ ...getConfigurationKeys.All()[0], level2: 'getCustomerCompanyVisibilityQuery', configurationId }] as const,

  GetCompanyVisibilityFiltersDefinitions: () =>
    [{ ...getConfigurationKeys.All()[0], level2: 'getCompanyVisibilityFiltersDefinitions' }] as const
}

export const useCustomerCompaniesVisibility = () =>
  useQuery(getConfigurationKeys.GetCustomerCompaniesVisibility(), () => getCustomerCompaniesVisibility())

export const useGetInfiniteCustCompVisibility = (
  querytype: ConfigurationVisibilityType,
  top: number,
  searchText: string,
  sorting?: SortItem
) =>
  useInfiniteQuery(
    getConfigurationKeys.GetInfiniteCustCompVisibilityWithParameters(top, querytype, sorting, searchText),
    ({ queryKey: [queryKeys], pageParam = 0 }) =>
      getCustomerCompaniesVisibility({
        type: querytype,
        nameLike: searchText,
        top: queryKeys.top,
        skip: queryKeys.top ? pageParam * queryKeys.top : undefined,
        sortProperty: sorting?.property,
        sortOrder: sorting?.order ? sorting.order : undefined
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const count = _.sum(_.map(allPages, p => p.queries?.length))
        return lastPage.totalCount <= count ? undefined : allPages.length
      },
      refetchOnMount: true
    }
  )

export const useGetBranches = () =>
  useQuery(getConfigurationKeys.GetBranches(), () => getCustomerCompanyVisibilityBranches())

export const useGetUsersByDistinguishName = (distinguishName: string | undefined) =>
  useQuery(
    getConfigurationKeys.GetUserByDistinguishName(distinguishName),
    ({ queryKey: [queryKeys] }) => getUsersByDistinguishName({ distinguishName: queryKeys.distinguishName }),
    {
      enabled: distinguishName !== undefined
    }
  )

export const useGetCustCompVisibilityQuery = (
  configurationId?: string,
  options?: UseQueryOptions<
    CustomerCompanyVisibilityQuery | undefined,
    unknown,
    CustomerCompanyVisibilityQuery,
    ReturnType<typeof getConfigurationKeys.GetCustomerCompanyVisibilityQuery>
  >
) =>
  useQuery(
    getConfigurationKeys.GetCustomerCompanyVisibilityQuery(configurationId),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.configurationId === undefined) return undefined
      return getCustomerCompanyVisibilityQuery(queryKeys.configurationId).then(r => r.data)
    },
    { ...options }
  )

export const useGetCompanyVisibilityFiltersDefinitions = () =>
  useQuery(
    getConfigurationKeys.GetCompanyVisibilityFiltersDefinitions(),
    getCompanyVisibilityFiltersDefinitions,
    cacheAllTheTime
  )
