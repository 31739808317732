import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'

export const SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE = 'view/saleDetail/SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE'
export const REFRESH_CAMPAIGN_STATUS_CHANGED = 'view/saleDetail/REFRESH_CAMPAIGN_STATUS_CHANGED'

export const SaleDetailActions = {
  setHiddenTaskPopupExpirationDate: (date: string) => createAction(SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE, date),
  setRefreshCampaignStatus: (canRefresh: boolean) => createAction(REFRESH_CAMPAIGN_STATUS_CHANGED, canRefresh)
}

export type SaleDetailActions = ActionsUnion<typeof SaleDetailActions>
