import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import KoImage from '../../../assets/images/ko.svg'

import { getCompanyName } from '../../../features/config/selectors'
import { Text } from '../../../components/Text'
import { CreditStatistic } from '../../../components/Statistic/CreditStatistic'
import { Dialog } from '../../../components/Dialog'
import { BaseDialog } from '../../../pages/SalesTool/BaseDialog'
import { usePersonEnrichmentProposal, useEnrichPerson } from './hooks'
import { EnrichPersonDialogData, EnrichPersonDialogView } from './types'

type Props = {
  close: () => void
  data: EnrichPersonDialogData
}

export const EnrichPersonDialog = ({ close, data: { view, personId, redirectTo } }: Props) => {
  const { t } = useTranslation(['common', 'personReport', 'error'])
  const navigate = useNavigate()
  const { loading: proposalIsLoading, enrichmentInfo, proposalFailed } = usePersonEnrichmentProposal(personId)
  const { enrichPerson, isSubmitting: enrichPersonSubmitting } = useEnrichPerson(personId)

  const isLoading = proposalIsLoading || enrichPersonSubmitting

  const companyName = useSelector(getCompanyName)

  const canEnrich = (enrichmentInfo && enrichmentInfo?.remainingCredits >= 0) || !proposalFailed

  const onOk = React.useCallback(() => {
    if (view === EnrichPersonDialogView.Init && canEnrich) enrichPerson(personId)
    if (view === EnrichPersonDialogView.Recap || view === EnrichPersonDialogView.Fail) {
      close()
    }
    if (view === EnrichPersonDialogView.Recap && redirectTo) {
      navigate({ pathname: redirectTo })
    }
  }, [close, view, enrichPerson, canEnrich, redirectTo, navigate, personId])

  return (
    <Dialog
      loading={isLoading}
      visible
      destroyOnClose
      width={800}
      onCancel={close}
      title={t('personReport:Enrich.Title')}
      footer={view === EnrichPersonDialogView.Init ? t('common:DoYouWantToContinue') : ''}
      cancelText={view === EnrichPersonDialogView.Init ? t('common:Cancel') : undefined}
      okText={view === EnrichPersonDialogView.Init ? t('common:Confirm') : t('common:Ok')}
      okButtonProps={{ disabled: isLoading || (view === EnrichPersonDialogView.Init && !canEnrich) }}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={onOk}
    >
      <>
        {view !== EnrichPersonDialogView.Fail && (
          <div>
            {view === EnrichPersonDialogView.Init && (
              <>
                <Text block padding="0 0 20px 0" text={t('personReport:Enrich.Desc', { companyName })} />
                {proposalFailed && <Text block padding="0 0 20px 0" text={t('personReport:Enrich.ProposalFailed')} />}
              </>
            )}
            {view === EnrichPersonDialogView.Recap && (
              <Text block padding="0 0 20px 0" text={t('personReport:Enrich.Success')} />
            )}
            {enrichmentInfo && (
              <CreditStatistic
                remaining={enrichmentInfo.remainingCredits}
                used={enrichmentInfo.usedCredits}
                remainingText={t('personReport:Enrich.CreditsRemaining')}
                usedText={t('personReport:Enrich.CreditsWillBeUsed')}
              />
            )}
          </div>
        )}
        {view === EnrichPersonDialogView.Fail && (
          <BaseDialog
            image={<img src={KoImage} alt="ko" />}
            content={<Text size="L" text={t('error:GenericMessage')} />}
          />
        )}
      </>
    </Dialog>
  )
}
