import styled from 'styled-components/macro'

export const BoldText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
`

export const DatePickerWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  > *:first-child {
    margin-bottom: 20px;
  }
`
