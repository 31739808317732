import { SalestoolSelectionUnit } from '../../../api/salestool/types'
import { Dictionary } from '../../../utils/types'
import { SalestoolAssignmentsViewType } from '../../salestoolAssignmentsPage/types'
import { CampaignCompany, CompanyNote } from '../types'

export enum AssignmentType {
  sale = 'saleUserId',
  product = 'product',
  task = 'task'
}

export enum AssignmentsKeys {
  sale = 'campaignInfo.customerInfo.sales',
  product = 'campaignInfo.customerInfo.product',
  task = 'campaignInfo.customerInfo.task'
}

export type SalesToolTableContent = {
  data: CampaignCompany[]
  total: number
}

export type CompaniesListRow = {
  entityId: string
  companyUnitId: string
  companyName: string
  notes: CompanyNote[]
  identificationCode: string
  campaignCompanyId: string
}

export type Assignment = {
  entityId: string
  saleUserId?: string
  product?: string
  task?: string
}

export type AssignmentData = {
  assignments: Dictionary<Assignment>
  itemsToIgnore?: Dictionary<SalestoolSelectionUnit>
}

export type AssignmentsData = Dictionary<AssignmentData>

export type CompanyStatusDict = Dictionary<'assigned' | 'notAssigned'>

export type TableSelectOption = {
  id: string
  name?: string
  nameTKey?: string
  username?: string
  isActive?: boolean
}

export type RowMenuItem = {
  title: string
  onClick: (record: CompaniesListRow) => void
}

export type AssignmentItemsData = {
  currentStatus: SalestoolAssignmentsViewType
  isSelectAllActive: boolean
  currentAssignmentsByStatus: AssignmentsData
  companiesStatusDict: CompanyStatusDict
  visibleCompanies: CompaniesListRow[]
  type: AssignmentType
  value: string
}

type withSelectedItem = {
  selectedItems: string[]
}

export type AssignmentsDataWithStatus = AssignmentItemsData & withSelectedItem
