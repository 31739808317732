import {
  CompanyUnitEnrichmentProposalResponse,
  PortfolioEnrichmentProposalResponse
} from '../../../api/enrichment/types'
import {
  CompanyEnrichProposal,
  EnrichCompaniesDialogData,
  EnrichPortfolioSelectionDialogData,
  EnrichSingleCompanyDialogData,
  EnrichSource
} from './types'

export const mapCompaniesUnitsResponseToCompanyProposal = (
  res: CompanyUnitEnrichmentProposalResponse
): CompanyEnrichProposal => ({
  ...res,
  withoutPortfolio: res.noPortfolioUnits
})

export const mapPortfolioEnrichResponseToCompanyProposal = (
  res: PortfolioEnrichmentProposalResponse
): CompanyEnrichProposal => ({
  withoutPortfolio: 0,
  companiesCount: res.selectedCompaniesCount,
  companiesToEnrichCount: res.selectedNotEnrichedCompaniesCount,
  enrichedCompaniesCount: res.selectedEnrichedCompaniesCount,
  currentCredits: res.currentCredits,
  remainingCreditsAfterCompaniesEnrichment: res.remainingCreditsAfterPortfolioCompaniesEnrichment,
  requiredCreditsToEnrichCompanies: res.requiredCreditsToEnrichPortfolioCompanies
})

export const mapEnrichmentDataBasedOnSource = (
  source: EnrichSource,
  response: CompanyUnitEnrichmentProposalResponse | PortfolioEnrichmentProposalResponse
): CompanyEnrichProposal => {
  return source === EnrichSource.PortfolioDetailGroupAction
    ? mapPortfolioEnrichResponseToCompanyProposal(response as PortfolioEnrichmentProposalResponse)
    : mapCompaniesUnitsResponseToCompanyProposal(response as CompanyUnitEnrichmentProposalResponse)
}

export const isEnrichSingleCompanyDialogData = (
  data: EnrichCompaniesDialogData
): data is EnrichSingleCompanyDialogData => {
  return !!(data as unknown as EnrichSingleCompanyDialogData)?.params?.companyUnitId
}

export const isEnrichPortfolioSelectionData = (
  data: EnrichCompaniesDialogData
): data is EnrichPortfolioSelectionDialogData => {
  // WARNING: Notice this is complement type to isEnrichSingleCompanyDialogData!!!
  return !(data as unknown as EnrichSingleCompanyDialogData).params?.companyUnitId
}
