import _ from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { changePassword } from '../../../api/users'
import { Dialog } from '../../../components/Dialog'
import { ComponentProps } from '../../../types'
import { ChangePasswordDialogForm, FormState } from './ChangePasswordDialogForm'
import { getValidationError } from '../../Login/LoginForm/utils'
import { LoginError } from '../../../api/users/types'

type DialogProps = ComponentProps<typeof Dialog>

type Props = {
  close: () => void
}

export const ChangePasswordDialog = ({ close }: Props) => {
  const { t, i18n } = useTranslation(['account', 'common', 'login', 'error'])
  const { control, formState, watch, getValues, trigger } = useForm<FormState>({
    defaultValues: { oldPassword: '', newPassword: '', confirmPassword: '' },
    mode: 'onBlur'
  })
  const [validationError, setValidationError] = useState<string>()

  const mutation = useMutation(
    (formValues: FormState) => {
      return changePassword(formValues.oldPassword, formValues.newPassword)
    },
    {
      onSuccess: close,
      onError: (err: AxiosError<LoginError>) => {
        const error = getValidationError(err, t, i18n)
        setValidationError(error)
      }
    }
  )

  const props: DialogProps = {
    loading: mutation.isLoading,
    visible: true,
    width: 900,
    title: t('account:Account.Password.ChangePasswordTitle'),
    footer: '',
    onCancel: close,
    cancelButtonProps: { disabled: mutation.isLoading },
    cancelText: t('common:Cancel'),
    onOk: async () => {
      await trigger()
      if (_.isEmpty(formState.errors)) {
        mutation.mutate(getValues())
      }
    },
    okButtonProps: {
      disabled: _.size(formState.dirtyFields) !== 3 || mutation.isLoading
    },

    okText: t('account:Account.Password.ChangePassword')
  }

  return (
    <Dialog {...props}>
      <ChangePasswordDialogForm
        control={control}
        formState={formState}
        watch={watch}
        validationError={validationError}
      />
    </Dialog>
  )
}
