import { ReportSection } from '../../features/report/types'
import { ApplicationModule } from '../../types/common'

export type CompanyReportProps = {
  companyUnitId: string
  identificationCode: string
  entityItemId: string | undefined
  module: ApplicationModule | undefined
}

export type SectionProps<T> = {
  section: ReportSection
  data: T
  companyUnitId: string
  identificationCode: string
  entityItemId: string | undefined
  module: ApplicationModule | undefined
}

export type RoutesSectionProps<T> = Omit<SectionProps<T>, 'section'>

export type FormatFunction = (arg0: React.ReactText, arg1?: React.ReactText) => React.ReactNode

export enum ErrorMessageCode {
  None = 'None',
  HiddenCompany = 'HiddenCompany',
  NotExists = 'NotExists'
}
