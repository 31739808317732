import React from 'react'
import styled from 'styled-components/macro'
import { Text } from '../Text'
import { SmallSpin } from '../Loading/Spin'
import { Dialog } from './Dialog'

const ContentWrapper = styled.div`
  display: flex;
  padding-top: 40px;
  padding-bottom: 20px;
  justify-content: center;
  .spinner {
    margin-right: 10px;
  }
`

type Props = {
  data: {
    message: string
    transition?: boolean
  }
  close: () => void
}

export const LoadingDialog = ({ data, close }: Props) => {
  const { message, transition = true } = data
  return (
    <Dialog
      visible
      width={400}
      footer={null}
      loading={false}
      closable={false}
      maskClosable
      onCancel={close}
      transitionName={!transition ? 'none' : undefined}
      maskTransitionName={!transition ? 'none' : undefined}
    >
      <ContentWrapper>
        <SmallSpin className="spinner" />
        <Text>{message}</Text>
      </ContentWrapper>
    </Dialog>
  )
}
