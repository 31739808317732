import { TreeNode, TreeNodeNormal } from 'antd3/lib/tree-select/interface'
import _ from 'lodash'
import { DomainItemsPerLevel, DomainNodeDataNotEmptyResponse } from '../../../api/filters/types'

const CLUSTER_PREFIX = 'CL_'

const mapLevelRecursively = (
  levelsData: DomainItemsPerLevel[],
  levelIndex: number,
  parentCode?: string
): TreeNodeNormal[] => {
  const maxLevelIndex = levelsData.length - 1
  const nextLevelIndex = levelIndex + 1

  const levelData = levelsData[levelIndex]?.items ?? []

  return _.map(
    levelIndex > 0 && parentCode !== undefined
      ? _.filter(levelData, childItem => childItem.parentPath[levelIndex - 1] === parentCode)
      : levelData,
    item => ({
      key: item.code,
      value: item.code,
      isLeaf: !item.hasChildren,
      selectable: !item.hasChildren || _.startsWith(item.code, CLUSTER_PREFIX),
      title: item.displayName,
      children: nextLevelIndex <= maxLevelIndex ? mapLevelRecursively(levelsData, nextLevelIndex, item.code) : undefined
    })
  )
}

export const mapSemanticDomainNodesDataToTreeData = (
  domainNodesData: DomainNodeDataNotEmptyResponse | undefined
): TreeNode[] | undefined => {
  if (domainNodesData === undefined) return undefined

  return mapLevelRecursively(domainNodesData.perLevel, 0)
}
