import { GeoJsonProperties } from 'geojson'
import _ from 'lodash'
import { MapCountryCustomization, mapConfig } from './CountryCustomization'
import { IMapCountryCustomizations } from './types'

export { mapConfig }

export class MapCountryCustomizationIta extends MapCountryCustomization implements IMapCountryCustomizations {
  static country = 'it'

  // eslint-disable-next-line class-methods-use-this
  getPathFromGeoJsonFeature = (featureProps: GeoJsonProperties): string | undefined => {
    if (featureProps === null) return undefined

    const { level1Key, level2Key } = mapConfig
    const regionCode = _.toLower(_.get(featureProps, `${level1Key}`))
    const provinceCode = _.toLower(_.get(featureProps, `${level2Key}`))

    return provinceCode
      ? `${MapCountryCustomizationIta.country}.sub.${regionCode}.sub.${provinceCode}`
      : `${MapCountryCustomizationIta.country}.sub.${regionCode}`
  }
}
