import React from 'react'
import { useLoadCompanyUnitId } from '../../api/companyReport/common'
import { CompanyPopupDialogDataReport } from '../../features/dialog/dataTypes'
import { ApplicationModule } from '../../types'
import { CompanyPopup } from './CompanyPopup'
import { CompanyPopupLoading } from './CompanyPopupLoading'
import { CompanyPopupNotFound } from './CompanyPopupNotFound'

type Props = CompanyPopupDialogDataReport & {
  onCompanyLoad?: () => void
}

export const ReportCompanyPopup: React.FC<Props> = ({
  identificationCode,
  companyId,
  openCompanyReport,
  onCompanyLoad
}) => {
  const { data: companyUnitId, isFetching } = useLoadCompanyUnitId(identificationCode, companyId)

  if (isFetching) {
    return <CompanyPopupLoading />
  }
  if (!companyUnitId) {
    return <CompanyPopupNotFound />
  }
  return (
    <CompanyPopup
      companyId={companyUnitId}
      module={ApplicationModule.CompanyReport}
      openCompanyReport={openCompanyReport}
      onCompanyLoad={onCompanyLoad}
    />
  )
}
