import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Text } from '../Text'
import CloseIcon from '../../assets/icons/icon-s-close-normal.svg'

const StyledCloseButton = styled(Text)`
  display: flex;
  position: absolute !important;
  right: 0;
  top: 0;
`

type CloseButtonProps = {
  onClick?: () => void
}

export const CloseButton = ({ onClick }: CloseButtonProps) => {
  const { t } = useTranslation('common')
  return (
    <StyledCloseButton primary bold onClick={onClick}>
      <span>{t('Close')}</span> <img src={CloseIcon} alt="close" />
    </StyledCloseButton>
  )
}
