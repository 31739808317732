import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Dialog } from '../../../components/Dialog'
import { ReleaseNote } from '../../../api/releaseNotes/types'
import { ACCOUNT_RELEASE_NOTES } from '../../../routes'
import { Markdown } from '../../../components/Markdown'
import { getSelectedLanguage } from '../../../features/user/selectors'
import { Notes, StyledLink } from './components'

const Footer = ({ t }: { t: TFunction }) => (
  <StyledLink href={ACCOUNT_RELEASE_NOTES}>{t('ReleaseNotes.GoToNewsPage')}</StyledLink>
)

export const ReleaseNotesDialog = ({ data, close }: { data: ReleaseNote[]; close: () => void }) => {
  const { t } = useTranslation('account')
  const languageCode = useSelector(getSelectedLanguage)
  const topNote = _.head(data)
  const topNoteContent = _.find(topNote?.releaseNoteContents, { languageCode })

  return (
    <Dialog
      loading={false}
      visible
      destroyOnClose
      width={500}
      onCancel={close}
      title={t('ReleaseNotes.WhatsNew')}
      footer={<Footer t={t} />}
    >
      <Notes>
        <Markdown>{topNoteContent?.shortDescription}</Markdown>
      </Notes>
    </Dialog>
  )
}
