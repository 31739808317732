/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: 'To start the analysis you need to select the type of analysis you want to process',
    TypeOfAnalysis: 'Type of analysis',
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    NewResearch: 'Start new search',
    NewResearchTooltip: "Search for prospects through all the companies in Margo's database",
    SelectOne: 'Select query',
    SavedQuery: 'Saved query',
    PortfolioQuery: 'Portfolio Management query',
    TargetingQuery: 'Analyze Market query',
    ProspectingQuery: 'Prospecting Query',
    ChooseQuery: 'Choose which query you want to load',
    YouCanResumeResearch: 'You can resume a research that you have already started.'
  },
  Prospecting: 'Prospecting',
  Actions: {
    LoadQuery: 'Load query',
    LoadQueryTooltip: 'Open a saved query',
    SaveQuery: 'Save query',
    SaveQueryTooltip: 'Save this query in the Prospecting module',
    Download: 'Download Excel',
    DownloadTooltip: 'Download the enriched list of companies on your device',
    SaveList: 'Save list',
    SaveListTooltip: 'Save this companies list into a portfolio',
    SaveAndMonitor: 'Save & monitor list',
    SaveAndMonitorTooltip:
      'Save this companies list into a portfolio that updates dynamically as new companies enter the search perimeter',
    SendToSales: 'Send list to Sales Tool',
    SendToSalesTooltip: 'Assign the companies to the sales team and plan a commercial campaign'
  },
  Indexes: {
    Penetration: 'Penetration Index',
    PenetrationTooltip:
      'It measures the % of portfolio companies compared to the ones generated by Similarity. An higher value indicates a better penetration on the potential market.',
    Opportunity: 'Opportunity Index',
    OpportunityTooltip:
      'It measures the % of companies generated by Similarity not included in the portfolio. An higher value indicates a greater number of potential clients.'
  },
  CompaniesInList: 'Company in the list',
  CompaniesInList_plural: 'Companies in the list',
  Search: {
    SemanticSearch: 'Semantic search',
    SearchPlaceholder: 'Search',
    All: 'All',
    OfficialData: 'Official data',
    Website: 'Web & Social',
    Synonyms: 'Synonyms',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Advanced',
    AdvancedTooltip:
      'With the Advanced semantic search you can use multiple words linked by logical operators (AND, OR, NOT). To enable it, turn the switch to ON. Download the document for more information.',
    DownloadSsGuide: 'Download semantic search guide'
  },
  EmptySearch: {
    History: {
      Title: 'No filters',
      Subtitle: 'Here you can see how each filter affects your research'
    },
    Banner: {
      Title: 'Start research using your favorite filters',
      Subtitle:
        'Before starting the research, make sure you have selected the filters that are most useful for you. The platform will generate a list of ideal prospects based on the selected filters.'
    }
  },
  LoadQuery: {
    DialogTitle: 'Load saved search',
    SaveBefore: {
      Question: 'Do you want to save the search of prospects before loading the search?',
      Description: 'Save your prospects search in a new one before uploading the query to avoid losing data',
      OkBtn: 'Save search',
      CancelBtn: 'Skip save'
    }
  },
  SaveQuery: {
    DialogTitle: 'Save research',
    Description: 'Insert the name you want to use to save your research or select an existing query to override it.',
    ConfirmMessage: 'Do you want to add the query in your dashboard?',
    UpdateExisting: 'Update existing',
    CreateNew: 'Create New',
    MaximumWidgetCountReached: 'The maximum number of boxes in the Dashboard has been reached.',
    Fields: {
      QueryName: 'Query name',
      QueryNamePlaceholder: 'Research name',
      AddToDashboard: 'Show in dashboard'
    },
    SavedQuery: 'saved correctly',
    SavedQueryAndDashboard: 'saved and added in dashboard',
    SavedQueryDescription: 'You will find the query saved in the repository related to the reference area'
  },
  DomainFilter: {
    SaveAndClose: 'Save filters & close'
  },
  LoadMore: 'Load more',
  LocalUnits: {
    NoLocalUnits: {
      Title: 'Add Local Units',
      Text: 'Please, contact Margò Sales team in order to activate the Local Unit functionality.'
    }
  },
  UnavailableForMobile: 'The page you are trying to access is only available for tablets and desktops.',
  WarningRespectingLocalUnits: {
    Title: 'Enrichment',
    Text: 'Companies with Office type "Administrative Headquarter", "Administrative Headquarter and Registered office" and "Registered office" have been enriched (if selected). To enrich companies with Office type "Local unit", please activate the Local Units addon by contacting the customer service.'
  }
}
