import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Dialog } from '../../../components/Dialog'
import { Select, Option } from '../../../components/Select'
import { Text } from '../../../components/Text'
import { Query } from '../../../features/query/types'
import { useGetPortfolioList } from '../../../api/portfolio'
import { PORTFOLIO } from '../../../routes'
import { QueryType } from '../../../types'

type ResumeQueryDialogData = {
  queryId: string
  portfolioId?: string
  queryType: QueryType
}

type Props = {
  data: ResumeQueryDialogData
  close: () => void
}

export const ResumeQueryDialog: React.FC<Props> = ({ data, close }) => {
  const { t } = useTranslation(['account', 'common'])
  const navigate = useNavigate()

  const [selectedPortfolioId, setSelectedPortfolioId] = React.useState<string>()

  const { data: portfolios, isLoading: portfoliosLoading } = useGetPortfolioList({ minCountOfCompanies: 0 })

  const onOk = React.useCallback(() => {
    const query: Pick<Query, 'id' | 'type'> = { id: data.queryId, type: data.queryType }
    navigate({ pathname: generatePath(PORTFOLIO, { portfolioId: selectedPortfolioId }) }, { state: { query } })
    close()
  }, [data.queryId, data.queryType, navigate, selectedPortfolioId, close])

  React.useEffect(() => {
    if (data.portfolioId && !selectedPortfolioId && _.some(portfolios, { id: data.portfolioId }))
      setSelectedPortfolioId(data.portfolioId)
  }, [selectedPortfolioId, data.portfolioId, portfolios])

  return (
    <Dialog
      width={800}
      visible
      loading={portfoliosLoading}
      title={t('Queries.ResumePortfolioQueryDialog.Title')}
      footer
      onCancel={close}
      cancelText={t('common:Cancel')}
      onOk={onOk}
      okButtonProps={{ disabled: !selectedPortfolioId }}
      okText={t('common:Continue')}
    >
      <Text>{t('Queries.ResumePortfolioQueryDialog.Text')}</Text>
      <p />
      <Select
        value={selectedPortfolioId}
        onChange={val => setSelectedPortfolioId(val as string)}
        showSearch
        optionFilterProp="title"
      >
        {_.map(portfolios, p => (
          <Option key={p.id} value={p.id} title={p.name}>
            {p.name}
          </Option>
        ))}
      </Select>
    </Dialog>
  )
}
