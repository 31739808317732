import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { DeleteAppointmentDialogData } from './types'
import { BoldText, ContainerItem, StyledDialog, StyledDialogBody } from '../components'
import { deleteAppointments } from '../../../api/appointment/api'
import { getAppointmentKeys } from '../../../api/appointment/queries'
import { Text } from '../../../components/Text'
import { Switch } from '../../../components/Switch'
import { Stack } from '../../../components/Stack'
import { isExpired } from '../../../features/appointment/utils'

type Props = {
  data: DeleteAppointmentDialogData
  close: () => void
}

export const DeleteAppointmentDialog = ({ data, close }: Props) => {
  const now = moment()
  const isSendEmailEnabled = !isExpired(now, data.appointment)
  const { t } = useTranslation(['common', 'account', 'appointment'])
  const [sendEmail, setSendEmail] = useState<boolean>(isSendEmailEnabled)
  const queryClient = useQueryClient()

  const {
    mutate: mutateDeleteAppointment,
    isError,
    isIdle,
    isLoading
  } = useMutation(
    () => {
      return deleteAppointments(data.appointment.id, sendEmail)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAppointmentKeys.All())
        data.onSuccess?.()
        close()
      }
    }
  )

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('appointment:DeleteAppointment.Title')}
      cancelText={t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) return mutateDeleteAppointment()
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {isIdle && (
            <Stack gap="30px" orientation="vertical">
              <BoldText>{t('appointment:DeleteAppointment.SubTitle')}</BoldText>
              {isSendEmailEnabled && (
                <Stack gap="10px" center>
                  <Text size="M" type="primary">
                    {t('appointment:SendEmailLabel')}
                  </Text>
                  <Switch
                    aria-label={t('appointment:SendEmailLabel')}
                    checked={sendEmail}
                    checkedChildren={t('common:Yes')}
                    unCheckedChildren={t('common:No')}
                    size="large"
                    onClick={setSendEmail}
                  />
                </Stack>
              )}
            </Stack>
          )}
          {isError && <p>{t('error:ErrorPage.Text')}</p>}
        </ContainerItem>
      </StyledDialogBody>
    </StyledDialog>
  )
}
