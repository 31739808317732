import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { editGoal, getGoalsKeys } from '../../../api/goals'
import { validateNewGoalName } from '../../../api/goals/validators'
import { EditGoalDialogData } from './types'
import { HookForm } from '../../../components/Form'
import { InputField } from '../../../components/Form/Input'
import { Text } from '../../../components/Text'
import { StyledDialog } from '../components'
import { validateNameSynchronous } from '../../../utils/validators'

export type EditGoalFormState = {
  goalName?: string
}

type Props = {
  data: EditGoalDialogData
  close: () => void
}

export const EditGoalDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()
  const { control, handleSubmit, formState, watch } = useForm<EditGoalFormState>({
    mode: 'onChange'
  })

  const { isSubmitting, isValidating, errors } = formState

  const {
    mutate: mutateEditGoal,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(() => editGoal(data.goalId, watch('goalName')!), {
    onSuccess: () => {
      queryClient.invalidateQueries(getGoalsKeys.GetGoals())
      queryClient.invalidateQueries(getGoalsKeys.GetGoal(data.goalId))
    }
  })

  const asyncValidateGoalName = async (value: any) => {
    const validationResponse = await validateNewGoalName(value as string)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(false)(watch('goalName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:Goal.EditGoal.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={isIdle || isLoading ? t('common:Save') : t('common:Ok')}
      onOk={() => {
        if (isIdle) {
          return mutateEditGoal()
        }
        return close()
      }}
      okButtonProps={{
        disabled: !watch('goalName') || isLoading || isValidating || !_.isEmpty(errors),
        loading: isValidating
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <HookForm handleSubmit={handleSubmit} onSubmit={mutateEditGoal} isSubmitting={isSubmitting}>
        {(isIdle || isLoading) && (
          <>
            <Text block margin={{ bottom: 40 }}>
              {t('account:Goal.EditGoal.Body')}
            </Text>
            <InputField
              name="goalName"
              label={t('account:Goal.EditGoal.NameInput')}
              placeholder={t('account:Goal.EditGoal.NameInput')}
              defaultValue={data.goalName}
              control={control}
              formState={formState}
              validators={{
                validateNameSynchronous: validateNameSynchronous(false),
                asyncValidateGoalName
              }}
            />
          </>
        )}
        {isSuccess && <p>{t('account:Goal.EditGoal.Success')}</p>}
        {isError && <p>{t('account:Goal.EditGoal.Error')}</p>}
      </HookForm>
    </StyledDialog>
  )
}
