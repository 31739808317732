import _ from 'lodash'
import MatomoTracker from '@jonkoops/matomo-tracker'

import { FilterReducerPath } from '../filters/types'
import { EventCategory } from './types'

export function toTrackableLocation(pathname: string) {
  return _.replace(
    pathname,
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
    '{identifier}'
  )
}

export function categoryFromFilterReducerPath(p: FilterReducerPath): EventCategory {
  switch (p) {
    case 'prospecting':
      return EventCategory.Prospecting
    case 'views.portfolioDetail':
      return EventCategory.PortfolioDetail
    case 'views.salestoolAssignments':
      return EventCategory.SalesTool
    case 'targeting':
      return EventCategory.Targeting
    default:
      return EventCategory.Unknown
  }
}

export class Matomo {
  static tracker: MatomoTracker

  static setMatomo = (urlBase: string | undefined, siteId: number | undefined) => {
    if (!urlBase || !siteId) return
    Matomo.tracker = new MatomoTracker({
      urlBase,
      siteId
    })
  }
}
