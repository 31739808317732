import { Form } from 'antd3'
import { FormItemProps } from 'antd3/lib/form'
import { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'

export const FormItem = styled(Form.Item)<PropsWithChildren<FormItemProps>>`
  && .ant-form-item-label {
    label {
      color: ${({ theme }) => theme.colors.main} !important;
      font-size: 12px;
      font-weight: bold;
    }
  }

  && .ant-form-item-children {
    display: block;
    line-height: normal;
  }

  && .ant-form-explain {
    float: left;
  }
`
