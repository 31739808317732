import { SortOrder } from 'antd3/lib/table'

export enum TableKey {
  CampaignArchive = 'CampaignArchive',
  Products = 'Products',
  TargetingChartDetail = 'TargetingChartDetail'
}

export enum PersistedStateKeys {
  OperationsListViewColumns = 'operationsListViewColumns',
  PortfolioListViewColumns = 'portfolioListViewColumns',
  ProspectingListViewColumns = 'prospectingListViewColumns',
  ProspectingSearchColumns = 'prospectingSearchColumns',
  SalestoolAssignCampaignColumns = 'salestoolAssignCampaignColumns',
  SalestoolTaskTableColumns = 'salestoolTaskTableColumns'
}

export type TableColumnSorting = { field: string; order: SortOrder }
