import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './Button'
import { CenteredRow } from '../Grid'

type Props = {
  isLoading: boolean
  hasNextPage?: boolean
  isFetchingNextPage: boolean
  onClick: () => void
}

export const LoadMoreButton: React.FC<Props> = ({ isLoading, hasNextPage, isFetchingNextPage, onClick }) => {
  const { t } = useTranslation('common')

  return (
    <>
      {!!hasNextPage && (
        <CenteredRow padding="24px 0">
          <Button onClick={onClick} disabled={isLoading || isFetchingNextPage}>
            {t('LoadMore')}
          </Button>
        </CenteredRow>
      )}
    </>
  )
}
