import _ from 'lodash'
import { useInfiniteQuery, UseInfiniteQueryOptions, useQuery } from '@tanstack/react-query'
import { convertCompoundExpressionForBackend } from '../../features/filters/utils'
import { ApplicationModule } from '../../types'
import { ProspectingCompaniesRes } from '../prospecting/types'
import { getLocalUnitActionsAllowed, getCompaniesByQueryFilter, getMapLocationsData } from './api'
import {
  QueryFilterCompaniesRequest,
  QueryLocationsRequest,
  QueryLocationsResponse,
  QueryAllowLocalUnitsActionRequest
} from './types'

export const getCompanyKeys = {
  All: (module: string) => [{ level1: 'companies', module }] as const,
  CompaniesByQueryFilter: (moduleName: ApplicationModule) =>
    [{ ...getCompanyKeys.All(moduleName)[0], level2: 'companies-by-query-filter', moduleName }] as const,
  CompaniesByQueryFilterWithData: (data: QueryFilterCompaniesRequest) =>
    [{ ...getCompanyKeys.CompaniesByQueryFilter(data.moduleName)[0], ...data }] as const,

  MapLocationsData: (moduleName: ApplicationModule) =>
    [{ ...getCompanyKeys.All(moduleName)[0], level2: 'getMapLocationsData' }] as const,
  MapLocationsDataWithRequest: (moduleName: ApplicationModule, request: QueryLocationsRequest) =>
    [{ ...getCompanyKeys.MapLocationsData(moduleName)[0], request }] as const,

  GetLocalUnitActionsAllowedWithData: (data: QueryAllowLocalUnitsActionRequest) =>
    [{ ...getCompanyKeys.All(data.moduleName)[0], level2: 'get-localunitactions-allowed', ...data }] as const
}

export const useGetCompaniesByQueryFilter = (
  data: QueryFilterCompaniesRequest,
  queryOptions: UseInfiniteQueryOptions<ProspectingCompaniesRes> = {} // TODO: fix types
) => {
  const convertedData = { ...data, query: convertCompoundExpressionForBackend(data.query) }

  return useInfiniteQuery(
    getCompanyKeys.CompaniesByQueryFilterWithData(convertedData),
    ({ queryKey: [queryKeys], pageParam = 0 }) => {
      if (!queryKeys.query) return Promise.reject()

      return getCompaniesByQueryFilter({
        pageType: queryKeys.pageType,
        moduleName: queryKeys.moduleName,
        pagination: { ...queryKeys.pagination, position: pageParam },
        query: queryKeys.query,
        excludedPortfoliosIds: queryKeys.excludedPortfoliosIds,
        selectedPortfoliosIds: queryKeys.selectedPortfoliosIds,
        orderBy: queryKeys.orderBy ?? undefined,
        semanticSearch: queryKeys.semanticSearch,
        freeText: queryKeys.freeText,
        freeTextExcludeLocalUnits: queryKeys.freeTextExcludeLocalUnits
      })
    },
    {
      getNextPageParam: (lastResp, allPages) => {
        const count = _.sum(_.map(allPages, 'data.items.length'))
        return lastResp?.data?.total <= count ? undefined : allPages.length
      },
      enabled: queryOptions.enabled ?? true,
      keepPreviousData: queryOptions.keepPreviousData ?? false
    }
  )
}

export const useGetMapLocationsData = <TData>(
  moduleName: ApplicationModule,
  request: QueryLocationsRequest,
  select?: (data: QueryLocationsResponse) => TData,
  enabled = true
) => {
  const convertedRequest = { ...request, query: convertCompoundExpressionForBackend(request.query) }

  return useQuery(
    getCompanyKeys.MapLocationsDataWithRequest(moduleName, convertedRequest),
    ({ queryKey: [queryKeys] }) => getMapLocationsData(queryKeys.request),
    {
      enabled,
      select
    }
  )
}

export const useGetLocalUnitActionsAllowed = (data: QueryAllowLocalUnitsActionRequest) => {
  const convertedData = { ...data }

  return useQuery(getCompanyKeys.GetLocalUnitActionsAllowedWithData(convertedData), ({ queryKey: [queryKeys] }) => {
    if (!queryKeys.query) return Promise.reject()

    return getLocalUnitActionsAllowed({
      query: queryKeys.query,
      moduleName: queryKeys.moduleName,
      semanticSearch: queryKeys.semanticSearch
    })
  })
}
