import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { renameCustomTrack, getPortfolioDownloadKeys } from '../../../api/portfolioDownload'
import { validateNewCustomTrackName } from '../../../api/portfolioDownload/validators'
import { RenameCustomTrackDialogData } from './types'
import { HookForm } from '../../../components/Form'
import { InputField } from '../../../components/Form/Input'
import { Text } from '../../../components/Text'
import { StyledDialog } from '../components'
import { validateNameSynchronous } from '../../../utils/validators'

export type RenameCustomTrackFormState = {
  customTrackName?: string
}

type Props = {
  data: RenameCustomTrackDialogData
  close: () => void
}

export const RenameCustomTrackDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()
  const { control, handleSubmit, formState, watch } = useForm<RenameCustomTrackFormState>({
    mode: 'onChange'
  })

  const { isSubmitting, isValidating, errors } = formState

  const {
    mutate: mutateEditCustomTrack,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(() => renameCustomTrack(data.customTrackId, watch('customTrackName')!), {
    onSuccess: () => {
      queryClient.invalidateQueries(getPortfolioDownloadKeys.All())
    }
  })

  const asyncValidateCustomTrackName = async (value: any) => {
    const validationResponse = await validateNewCustomTrackName(value as string, data.customTrackModule)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(false)(watch('customTrackName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:PortfolioManagement.CustomTracks.RenameDialog.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={isIdle || isLoading ? t('common:Save') : t('common:Ok')}
      onOk={() => {
        if (isIdle) {
          return mutateEditCustomTrack()
        }
        return close()
      }}
      okButtonProps={{
        disabled: !watch('customTrackName') || isLoading || isValidating || !_.isEmpty(errors),
        loading: isValidating
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <HookForm handleSubmit={handleSubmit} onSubmit={mutateEditCustomTrack} isSubmitting={isSubmitting}>
        {(isIdle || isLoading) && (
          <>
            <Text block margin={{ bottom: 40 }}>
              {t('account:PortfolioManagement.CustomTracks.RenameDialog.Body')}
            </Text>
            <InputField
              name="customTrackName"
              label={t('account:PortfolioManagement.CustomTracks.RenameDialog.NameInput')}
              placeholder={t('account:PortfolioManagement.CustomTracks.RenameDialog.NameInput')}
              defaultValue={data.customTrackName}
              control={control}
              formState={formState}
              validators={{
                validateNameSynchronous: validateNameSynchronous(false),
                asyncValidateCustomTrackName
              }}
            />
          </>
        )}
        {isSuccess && <p>{t('account:PortfolioManagement.CustomTracks.RenameDialog.Success')}</p>}
        {isError && <p>{t('account:PortfolioManagement.CustomTracks.RenameDialog.Error')}</p>}
      </HookForm>
    </StyledDialog>
  )
}
