import _ from 'lodash'

import { SelectionUnit } from '../../../api/enrichment/types'

/* picks interface only properties to prevent sending bloated request */
export function remapSelectionUnits(units: SelectionUnit[]): SelectionUnit[] {
  return _.map(units, unit =>
    _.pick(unit, ['entityId', 'companyUnitId', 'identificationCode', 'isEnriched', 'sqlVersion'])
  )
}
