// eslint-disable-next-line import/no-default-export
export default {
  title: 'Operatione',
  CompaniesInTheList: 'Firmen in der Liste',
  Editor: {
    AddRule: 'Regel hinzufügen',
    AddGroup: 'Gruppe hinzufügen',
    DeleteGroup: 'Gruppe löschen',
    StartNewSearch: 'Starten Sie eine neue Suche'
  },
  ShowQuery: 'Anfrage anzeigen',
  NoMobileDesc: 'Die Seite, auf die Sie zuzugreifen versuchen, ist nur für Tablets und Desktops verfügbar.',
  Search: 'Suchtext',
  Actions: {
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Eine gespeicherte Abfrage öffnen',
    SaveQuery: 'Suche speichern',
    SaveQueryTooltip: 'Speichern Sie diese Abfrage im "Operations" Modul',
    Download: 'Excel herunterladen',
    DownloadTooltip: 'Laden Sie die angereicherte Liste der Unternehmen auf Ihr Gerät',
    SaveList: 'als Portfolio speichern',
    SaveListTooltip: 'Speichern Sie diese Unternehmensliste in einem Portfolio'
  },
  SaveQuery: {
    DialogTitle: 'Suche speichern',
    Description:
      'Geben Sie den Namen ein, unter dem Sie Ihre Suche speichern möchten, oder wählen Sie eine vorhandene Abfrage aus, um sie zu überschreiben.',
    InputLabel: 'Name der Abfrage',
    InputPlaceholder: 'Name der Suche',
    UpdateExisting: 'Update verfügbar',
    CreateNew: 'Erstelle neu',
    SavedQuery: 'wurde korrekt gespeichert.',
    SavedQueryDescription: 'Sie finden die Suche gespeichert im Repository bezogen auf das Referenzgebiet.'
  },
  filters: {
    selectGroup: 'Wähle die Gruppe',
    selectFilter: 'Filter auswählen',
    booleanTrue: 'Wahr',
    booleanFalse: 'Falsch',
    booleanEmpty: 'Nicht verfügbar',
    searchField: 'Filter auswählen',
    searchText: 'Suchtext',
    operatorsEquals: 'Ist gleich',
    operatorsNotEquals: 'Nicht gleich',
    operatorsGreater: 'Größer als',
    operatorsLower: 'Niedriger als',
    operatorsGreaterOrEqual: 'Größer oder gleich',
    operatorsLowerOrEqual: 'Niedriger oder gleich',
    operatorsNotExists: 'Null',
    operatorsExists: 'Ist nicht Null',
    operatorsBetween: 'Zwischen',
    operatorsIsOneOf: 'Innerhalb',
    operatorsIsNotOneOf: 'Nicht enthalten',
    operatorsIn: 'Innerhalb',
    operatorsNotIn: 'Nicht enthalten',
    operatorsContains: 'Enthält',
    operatorsNotContains: 'Enthält nicht',
    fromDate: 'Datum aus',
    toDate: 'Datum bis',
    minValue: 'Mindestwert',
    maxValue: 'Höchster Wert'
  },
  CustomizeQuery: {
    Title: 'Passen Sie Ihre Abfrage an.',
    Message:
      'Klicken Sie auf "Regel hinzufügen", um Ihre Recherche zu starten. Wählen Sie die Operatoren aus, wählen Sie Ihre relevanten Filter und bestätigen Sie.'
  },
  WarningRespectingLocalUnits: {
    Title: 'Anreicherung',
    Text: 'Die Abfrage enthält Unternehmen mit dem Bürotyp "Verwaltungssitz", "Verwaltungssitz und Sitz" und "Sitz" (falls ausgewählt). Um Unternehmen mit dem Bürotyp "Lokale Einheit" einzubinden, aktivieren Sie bitte das Addon Lokale Einheiten, indem Sie sich an den Kundenservice wenden.'
  }
}
