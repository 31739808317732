import React from 'react'
import { CompanyPopup, ReportCompanyPopup } from '../../../components/CompanyPopup'
import { Dialog } from '../../../components/Dialog'
import { CompanyPopupDialogData } from '../../../features/dialog/dataTypes'

type Props = {
  close: () => void
  data: CompanyPopupDialogData
}

export const CompanyPopupDialog: React.FC<Props> = ({ close, data }) => {
  return (
    <Dialog visible loading={false} width={900} onCancel={close}>
      {data.type === 'ReportCompanyPopup' ? (
        <ReportCompanyPopup
          identificationCode={data.identificationCode}
          companyId={data.companyId}
          openCompanyReport={data.openCompanyReport}
        />
      ) : (
        <CompanyPopup
          companyId={data.companyId}
          portfolioId={data.portfolioId}
          module={data.module}
          openCompanyReport={data.openCompanyReport}
        />
      )}
    </Dialog>
  )
}
