import { SemanticSearch } from '../../../features/filters/types'
import { CompoundExpression } from '../../../features/operations/types'

export enum SubmitMethod {
  CreateNew,
  Update
}

export type SaveQueryDialogData = {
  portfolioId: string
  setQuerySaved: (saved: boolean) => void
  contextData: {
    expression: CompoundExpression
    semanticSearch?: SemanticSearch
    excludedPortfoliosIds?: string[]
    selectedPortfoliosIds?: string[]
  }
}
