/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  ChooseNameForCompaniesList: 'Choisir le nom de la liste de sociétés',
  EnterARepresentativeName: 'Saisir un nom représentatif du portefeuille en cours de chargement.',
  EnterARepresentativeNameCompaniesList: "Saisir le nom de la liste de sociétés en cours d'enregistrement.",
  ItWillIdentifyCompaniesList: 'Le nom aidera à son identification par la suite.',
  PortfolioName: 'Nom du portefeuille',
  InsertNameForPortfolio: 'Saisir un nom pour le portefeuille',
  UploadPortfolio: {
    StandardTitle: 'Charger portefeuille',
    UploadPortfolioOrConnect:
      'Charger un portefeuille ou se connecter au CRM de votre société pour démarrer une session de travail',
    UploadOrConnect: 'Charger un portefeuille ou se connecter au CRM de votre activité',
    UploadFromPlatform: 'Charger depuis une plateforme',
    UploadAPortfolio: 'Charger un portefeuille',
    SelectFromPc: 'Sélectionner depuis un PC',
    DownloadTemplateDescription1: 'Télécharger le modèle',
    DownloadTemplateDescription2:
      'Saisir les données des sociétés du client comme indiqué dans la feuille fournie en exemple en maintenant la structure des tableaux inchangée',
    DownloadTemplateDescription3: "Charger le fichier à l'étape suivante",
    DownloadTemplateDescription4:
      "Vous ne connaissez pas le numéro d'identification de l'entreprise/l'identifiant CRIF ? Veuillez contacter le service client à <a href='mailto: support.ch@crif.com'>support.ch@crif.com</a> pour procéder à la mise en correspondance manuelle",
    DownloadTemplate: 'Télécharger le modèle',
    ChooseHowToUploadPortfolio: 'Sélectionner votre fichier',
    SelectPortfolioFromPc: 'Sélectionner le portefeuille depuis votre ordinateur',
    YouCanLoad: 'Vous pouvez charger un autre portefeuille à tout moment, ils seront chargés séparément !',
    ToEnsureTheCorrectLoading:
      "Pour assurer le bon chargement des sociétés, s'assurer d'avoir utilisé le modèle fourni",
    ChooseHowToUploadForUpdate: 'Choisir comment charger la liste de société',
    SelectCompanyListFromPC: 'Sélectionner la liste de sociétés depuis votre PC',
    ToEnsureTheCorrectLoadingForUpdate:
      'Pour garantir le bon chargement de la liste des entreprises, assurez-vous d\'avoir utilisé le modèle fourni par Margo. Téléchargez la liste de base à partir de la fonction "Télécharger portefeuille", copiez et collez les valeurs dans le fichier modèle, mettez-la à jour et téléchargez le modèle rempli dans cette section.',
    SelectFile: 'Sélectionner le fichier',
    SelectFileFromComputer: 'Sélectionner le fichier souhaité',
    ChoosePortfolioName: 'Choisir un nom de portefeuille',
    ItWillDistinguish: 'Cela aidera à le distinguer des autres portefeuilles !',
    WrapperPage: {
      TypeOfUpload: 'Type de téléchargement',
      TypeOfUploadDescription:
        'Choisissez le type de téléchargement que vous souhaitez effectuer: standard ou simplifié',
      StandardUpload: 'Téléchargement standard',
      StandardUploadTooltip: 'Charger les sociétés sur Margo via le modèle dédié',
      SimplifiedUpload: 'Téléchargement simplifié',
      SimplifiedUploadTooltip:
        "Téléchargez une liste de codes d'identification directement sur le web, de manière simplifiée"
    },
    Simplified: {
      Title: 'Téléchargement de portefeuille simplifié',
      FirstStepTitle: "Coller ou saisir les codes d'identification",
      UploadWarning:
        'ATTENTION : Si vous fermez cette page ou si vous revenez en arrière sans enregistrer, toutes les modifications seront perdues',
      UploadPlaceholder: 'Collez ou saisissez un code fiscal/code TVA/numéro CRIF valide, séparé par un espace',
      Description1: 'Collez ou saisissez un code fiscal/code TVA/numéro CRIF valide, séparé par un espace',
      Description2: 'Cliquez sur le bouton "Continuer" pour les prochaines étapes',
      Description3:
        'Les erreurs potentielles seront signalées dans le "Tableau des erreurs", accessible en cliquant sur "Gérer les erreurs" dans la notification associée',
      IdentificationLengthErrorMessage: "Le code d'identification doit comporter au moins {{minLength}} caractères.",
      ChooseStatus: {
        Title: 'Choisissez le statut que vous souhaitez appliquer',
        Description1: 'Sélectionnez le statut initial des sociétés à télécharger.',
        Description2: "Le même statut social sera appliqué à l'ensemble du portefeuille."
      }
    },
    ManageErrors: {
      Title: 'Afficher et corriger les erreurs',
      Description1: 'Modifier ou supprimer les lignes erronées',
      Description2: 'Cliquez sur le bouton "Continuer" pour télécharger le fichier',
      Description3: 'Trouver des entreprises fixes dans le portefeuille',
      IdentificationCodeTitle: 'Code fiscal/Code TVA/Numéro CRIF',
      CompanyMatchStatusTitle: 'État du téléchargement',
      StatusDescriptionTitle: "Description de l'état",
      DownloadList: 'Télécharger une liste',
      CompanyMatchStatus: {
        Rejected: 'Rejeté',
        RejectedDescription: "Le code fiscal/code TVA/numéro CRIF n'est pas valide",
        Duplicate: 'Double',
        DuplicateDescription: 'Le code fiscal/code TVA/numéro CRIFIF est un double',
        NotMatched: 'Pas trouvé',
        NotMatchedDescription:
          "Le code fiscal/code TVA/numéro CRIF est valide, mais aucune correspondance n'a été trouvée dans la base de données",
        Pending: 'Validation en attente',
        PendingDescription: 'Le code fiscal/code TVA/numéro CRIF a été modifié. Cliquez sur Continuer pour le valider'
      }
    }
  },
  ExcludeCustomVariables: 'Exclure les variables personnalisables',
  ExcludeCustomVariablesConfirm:
    "Voulez-vous déplacer des sociétés sans Variables personnalisables ? Si oui, les Variables personnalisables du portefeuille d'origine seront supprimées.",
  Management: {
    Header: 'Gestion des portefeuilles',
    Upload: 'Charger portefeuille',
    UploadTooltip: 'Charger les sociétés sur Margo via le modèle dédié',
    UploadTooltipWithSimplified:
      "Télécharger une liste de codes d'identification, en utilisant la méthode standard ou simplifiée",
    ShowCorporate: 'Voir le portefeuille de la société',
    SearchPortfolioTooltip: 'Rechercher un portefeuille',
    SearchPortfolioPlaceholder: 'Rechercher nom de portefeuille',
    SearchHeader: 'Gestion des portefeuilles - Résultats de la recherche de portefeuille',
    PortfolioTabs: {
      MyPortfolios: 'Mes portefeuilles',
      SharedPortfolios: 'Portefeuilles partagés',
      ReceivedPortfolios: 'Portefeuilles reçus'
    },
    PortfolioName: {
      ChangePortfolioName: 'Changer nom de portefeuille',
      EnterARepresentativeName: 'Saisir un nom représentatif du portefeuille',
      InsertPortfolioName: 'Saisir un nom de portefeuille',
      NameChangedCorrectly: 'Nom changé correctement en'
    },
    PortfolioNote: {
      AddNote: 'Ajouter note',
      EditNote: 'Modifier note',
      AddANoteToDescribe: 'Ajouter une note pour décrire le portefeuille',
      AddNotePlaceholder: 'Ajouter note',
      AddNoteFieldLabel: 'Note'
    },
    DeletePortfolio: {
      DeletePortfolio: 'Supprimer portefeuille',
      AreYouSure: 'Voulez-vous vraiment supprimer {{name}} ?',
      PortfolioDeletedCorrectly: '{{name}} supprimé correctement.',
      DataWillRemain: 'Toutes les données de la société téléchargées restent dans votre répertoire'
    },
    Table: {
      NumberOfCompanies: 'Nombre de sociétés',
      EnrichedCompanies: 'Sociétés enrichies',
      CreationDate: 'Date de création',
      LastUpdateDate: 'Date de la dernière actualisation',
      CampaignsWithThisPortfolio: 'Campagnes avec ce portefeuille'
    },
    Menu: {
      ChangeName: 'Changer nom',
      DuplicatePortfolio: 'Dupliquer un portefeuille',
      DeletePortfolio: 'Supprimer portefeuille',
      AddNote: 'Ajouter note',
      EditNote: 'Modifier note'
    },
    Message: {
      Header: 'Cliquer sur « Charger un portefeuille » pour charger votre premier portefeuille !',
      Description:
        "Une liste déjà enregistrée avec le prospect suggéré est visible ici. Cliquer sur le nom et commencer à l'explorer et à gérer les sociétés figurant dans la liste."
    },
    UploadComplete: 'Chargement terminé !',
    CompaniesUploadedCorrectly: 'Sociétés chargées correctement',
    CeasedCompaniesNotMatched: 'Compagnie en cessation ou ne correspondant pas non chargées',
    ErrorsDueWrongData: 'Erreurs dues à des données erronées',
    Download: 'Télécharger',
    ListOfCeasedCompanies: 'Liste de sociétés en cessation',
    ListOfErrors: "Liste d'erreurs",
    Stats: {
      Companies: 'Société',
      Companies_plural: 'Sociétés',
      AllCompanies: 'Toutes les sociétés',
      EnrichedCompanies: 'Société enrichie',
      EnrichedCompanies_plural: 'Sociétés enrichies',
      Enriched: 'Enrichie',
      NotEnriched: 'Pas enrichie',
      Prospect: 'Prospect',
      Prospect_plural: 'Prospects',
      Lead: 'Principal',
      Lead_plural: 'Principaux',
      Client: 'Client',
      Client_plural: 'Clients',
      Supplier: 'Fournisseur',
      Supplier_plural: 'Fournisseurs',
      Competitor: 'Concurrent',
      Competitor_plural: 'Concurrents',
      LastUpdate: 'Dernière actualisation'
    },
    Enrich: 'Enrichir',
    EnrichTooltip: 'Améliore les données de ces sociétés',
    Analyze: 'Analyser un portefeuille',
    AnalyzeTooltip: 'Affiche les données de portefeuille par rapport aux variables principales',
    Similarity: 'Générer analogie',
    SimilarityTooltip: 'Applique un algorithme par analogies pour trouver de nouveaux prospects',
    SelectSource: 'Chargement de sociétés depuis les fichiers modèles en cours',
    UpdatePortfolio: 'Actualiser portefeuille'
  },
  Table: {
    companyName: 'Nom de la société',
    lastUpdateDate: 'Dernière actualisation',
    companyUnitTypeCode: "Type d'unité de société",
    companyStatusCode: 'Statut',
    employeeNumber: "Nombre d'employés",
    companyPortfolioStatus: 'État',
    sale: 'Commercial',
    Sale: 'Commercial',
    '[tags]': 'Étiquettes',
    startDate: 'Date de début',
    revenue: 'Revenus',
    turnover: "Chiffre d'affaire",
    isRating4: 'Notation',
    Empty: "Nous n'avons pas trouvé de sociétés correspondant à votre recherche",
    isEnriched: 'Est enrichie'
  },
  SelectColumns: 'Sélectionner des colonnes',
  LoadMore: 'Charger plus',
  MapDescription: 'La vue en carte montre les sociétés individuelles quel que soit leur statut dans le portefeuille',
  CompanyGroupActions: {
    Title: 'Actions de groupe',
    Actions: 'Actions',
    AreaManager: 'Responsable de secteur',
    Sales: 'Commercial',
    Products: 'Produits',
    Select: 'Sélectionner',
    NotAssigned: 'Non affecté',
    SelectAreaManager: 'Sélectionner responsable de secteur',
    SelectSales: 'Sélectionner commerciaux',
    SelectProducts: 'Sélectionner produit',
    ActionItems: {
      DuplicateCompany: 'Dupliquer une société',
      DeleteCompany: 'Supprimer société',
      MoveCompany: 'Déplacer la société vers un autre portefeuille',
      MoveCompany_plural: 'Déplacer {{count}} sociétés vers un autre portefeuille',
      Enrich: 'Enrichir',
      CreatePortfolio: 'Créer portefeuille',
      DownloadExcel: 'Télécharger portefeuille',
      AddLocalUnits: 'Ajouter unités locales',
      ShareCompanies: 'Partager des sociétés',
      UnshareCompanies: 'Arrêter le partage de sociétés'
    },
    CreatePortfolio: {
      Title: 'Créer portefeuille',
      InputDescription: 'Saisir un nom pour le portefeuille en cours de création',
      InsertPortfolioName: 'Saisir un nom de portefeuille'
    },
    HiddenCompanyMessage: "L'opération ne sera pas réalisée pour les sociétés appartenant à des portefeuilles cachés."
  },
  Actions: {
    ViewCampaigns: 'Voir toutes les campagnes connexes',
    LoadQuery: 'Charger requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    SaveQuery: 'Enregistrer requête',
    SaveQueryTooltip: 'Enregistrer cette requête dans le module Gestion des portefeuilles',
    Download: 'Télécharger portefeuille',
    DownloadTooltip: 'Télécharger le portefeuille en format Excel sur votre dispositif',
    Analyze: 'Analyser un portefeuille',
    AnalyzeTooltip: 'Affiche les données de portefeuille par rapport aux variables principales',
    Similarity: 'Générer analogie',
    SimilarityTooltip: 'Applique un algorithme par analogies pour trouver de nouveaux prospects',
    Campaign: 'Créer campagne',
    CampaignTooltip: 'Affecter les sociétés aux équipes commerciales et planifier une campagne publicitaire',
    Update: 'Actualiser portefeuille',
    UpdateTooltip: 'Ajouter de nouvelles sociétés à ce portefeuille ou actualiser les variables personnalisables',
    LocalUnits: 'Ajouter unités locales',
    LocalUnitsTooltip: 'Ajouter des filiales en lien avec les sièges de ce portefeuille'
  },
  Enriched: 'Enrichie',
  Enrich: 'Enrichir',
  Monitored: 'surveillée',
  Close: 'Fermer',
  Filters: 'Filtres',
  UserFilters: 'Utiliser des filtres',
  Download: {
    DownloadPortfolio: 'Télécharger portefeuille',
    SelectTypeOfList: 'Sélectionner type de liste',
    SelectTypeOfListDescription: 'Sélectionner le type de liste à télécharger.',
    DownloadCost: 'Les coûts de téléchargement sont les mêmes pour chaque type de liste.',
    Base: 'Basique',
    BaseDescription: "La liste contient l'ensemble de base des données relatives aux sociétés",
    Complete: 'Complète',
    CompleteDescription: "La liste contient l'ensemble complet des données relatives aux sociétés",
    ExceededMaximumCompanies: 'Nombre maximum de sociétés téléchargeables dépassé',
    Marketing: 'Marketing',
    MarketingDescription: "La liste contient l'ensemble standard des données relatives aux sociétés",
    Standard: 'Marketing',
    Custom: 'Personnalisé',
    CustomDescription:
      "Vous pouvez personnaliser la liste afin de télécharger et/ou sauvegarder uniquement l'ensemble des données dont vous avez besoin.",
    CustomTracks: 'Pistes sauvegardées',
    CustomTracksDefaultSelection: 'Aucune piste personnalisée sélectionnée',
    SaveCustomTrack: 'Sauvegarder une piste personnalisée',
    SaveCustomTrackQuestion: 'Voulez-vous sauvegarder cet ensemble de filtres sous forme de piste personnalisée ?',
    SaveCustomTrackTitle: 'Sauvegarder une piste personnalisée',
    SaveCustomTrackDescription:
      'Insérez le nom que vous souhaitez utiliser pour enregistrer votre piste personnalisée ou sélectionnez un nom existant pour le remplacer.',
    SaveCustomTrackName: 'Nom de la piste personnalisée',
    SaveCustomTrackUpdateExisting: 'Actualiser existante',
    SaveCustomTrackCreateNew: 'Créer nouvelle',
    DownloadExample: 'Exemple de téléchargement',
    Download: 'Télécharger',
    ForEveryCompanyInList: 'pour chaque société de la liste',
    ToDownloadYouNeedToPurchase: 'Acheter les données de la société {{companyName}} pour pouvoir télécharger la liste',
    YouAreDownloading:
      'Un fichier Excel contenant toutes les données de {{companyName}} demandées est téléchargé. Elles seront enregistrées dans la section « Gestion des portefeuilles » de votre compte.',
    CustomExport: {
      StepTitle: 'Sélectionner des données',
      Description1: 'Sélectionner les données à inclure dans le téléchargement',
      Description2: 'Les coûts de téléchargement sont les mêmes pour chaque type de liste',
      DataPacket: 'Paquet de données',
      DataPacketSearchPlaceholder: 'Rechercher des datapackets ou des champs spécifiques'
    },
    Dialog1: 'Le fichier requis est en cours de traitement et peut prendre plusieurs minutes.',
    Dialog2: "Il est possible de suivre l'avancement depuis le menu des notifications.",
    SaveSuccess: 'sauvegardé comme piste personnalisée',
    SaveSuccessDescription: 'Vous trouverez la piste sauvegardée dans le référentiel relatif à la zone de référence'
  },
  Enrichment: {
    PortfolioEnrichment:
      "Enrichissement des sociétés. Ce processus peut prendre quelques instants. Continuer l'opération ?",
    Enrichment: 'Enrichissement',
    EnrichmentComplete: 'Enrichissement terminé',
    EnrichmentDescription:
      "L'enrichissement de la société avec les données de {{companyName}} permet le téléchargement de ces informations et leur actualisation régulière",
    CompaniesInPortfolio: 'Société dans ce portefeuille',
    CompaniesInPortfolio_plural: 'Sociétés dans ce portefeuille',
    CompaniesEnriched: 'Société déjà enrichie',
    CompaniesEnriched_plural: 'Sociétés déjà enrichies',
    CompaniesNotEnriched: 'Société pas enrichie',
    CompaniesNotEnriched_plural: 'Sociétés pas enrichies',
    CreditsWillBeUsed: 'Appel utilisé',
    CreditsWillBeUsed_plural: 'Appels utilisés',
    CreditsRemaining: 'Appel restant',
    CreditsRemaining_plural: 'Appels restants',
    CompaniesSuccesfullyEnriched: 'Société enrichie',
    CompaniesSuccesfullyEnriched_plural: 'Sociétés enrichies'
  },
  CompanyDeletion: {
    ConfirmDelete: 'Voulez-vous vraiment supprimer la société ?',
    DeleteSuccess:
      'Société correctement supprimée. Vous devrez peut-être attendre quelques secondes pour que les modifications prennent effet.'
  },
  CompaniesMove: {
    ConfirmMove:
      'La société sélectionnée sera retirée du portefeuille actuel et ajoutée à un autre portefeuille. Sélectionner un portefeuille dans la liste ci-après.',
    ConfirmMove_plural:
      'Les sociétés sélectionnées seront retirées du portefeuille actuel et ajoutées à un autre portefeuille. Sélectionner un portefeuille dans la liste ci-après.',
    MoveSuccess: "L'opération de déplacement est en cours processus et peut prendre quelques instants.",
    SelectPortfolio: 'Sélectionner portefeuille'
  },
  CompanyAssignment: {
    ConfirmOperation: 'Confirmer opération',
    ProductAssignment:
      "Affectation du produit {{product}} aux sociétés sélectionnées. Ce processus peut prendre quelques instants. Continuer l'opération ?",
    SalesAssignment:
      "Affectation du commercial {{sales}} aux sociétés sélectionnées. Ce processus peut prendre quelques instants. Continuer l'opération ?",
    AreaManagerAssignment:
      "Affectation du responsable de secteur {{manager}} aux sociétés sélectionnées. Ce processus peut prendre quelques instants. Continuer l'opération ?",
    ProductUnassignment:
      "Non affectation des sociétés sélectionnées. Ce processus peut prendre quelques instants. Continuer l'opération ?",
    SalesUnassignment:
      "Non affectation des sociétés sélectionnées. Ce processus peut prendre quelques instants. Continuer l'opération ?",
    AreaManagerUnassignment:
      "Non affectation des sociétés sélectionnées. Ce processus peut prendre quelques instants. Continuer l'opération ?"
  },
  DialogError: 'Une erreur s’est produite durant le traitement de la requête.',
  Credits: 'Appels',
  LoadQuery: {
    DialogTitle: 'Charger une recherche enregistrée',
    SaveBefore: {
      Question: 'Voulez-vous enregistrer la recherche de prospects avant de charger la recherche ?',
      Description:
        "Enregistrer la recherche de prospects dans une nouvelle avant de charger la requête afin d'éviter toute perte de données",
      OkBtn: 'Enregistrer recherche',
      CancelBtn: 'Omettre enregistrement'
    }
  },
  SaveQuery: {
    DialogTitle: 'Enregistrer recherche',
    Description:
      "Saisir le nom de sauvegarde de la recherche ou sélectionner une requête existante pour l'écraser en écriture.",
    UpdateExisting: 'Actualiser existante',
    CreateNew: 'Créer nouveau',
    Fields: {
      QueryName: 'Nom de requête',
      QueryNamePlaceholder: 'Nom de recherche',
      AddToDashboard: 'Afficher dans le tableau de bord'
    },
    ConfirmMessage: 'Voulez-vous ajouter une requête au tableau de bord ?',
    MaximumWidgetCountReached: 'Le nombre maximum de cases du tableau de bord est atteint.',
    SavedQuery: 'enregistré correctement',
    SavedQueryAndDashboard: 'enregistré et ajouter dans le tableau de bord',
    SavedQueryDescription: 'La requête enregistrée se trouve dans le répertoire relatif à la zone de référence'
  },
  DomainFilter: {
    SaveAndClose: 'Enregistrer filtres & fermer'
  },
  ManageDuplicates: {
    Title: 'Gérer les doublons',
    Description: 'Pour dupliquer une société, il est nécessaire de modifier son statut ou son produit',
    GroupActions: 'Actions de groupe',
    Duplicates: '{{count}} doublon',
    Duplicates_plural: '{{count}} doublons',
    ShowOK: 'Afficher OK',
    HideOK: 'Masquer OK',
    SelectColumns: 'Sélectionner des colonnes'
  },
  GenerateSimilarity: {
    Title: 'Générer analogie : sélectionner un statut représentatif',
    TitleClusterAllowed: 'Générer analogie',
    Description: "Pour démarrer l'analyse, sélectionner le type de statut à traiter",
    DropdownPlaceholder: 'Sélectionner statut',
    GotoActionButton: 'Sélectionner',
    IncludeSemanticClusters: 'Inclure les clusters sémantiques'
  },
  Campaign: {
    YouNeedToEnrich: 'Pour démarrer une campagne, toutes les sociétés du portefeuille doivent être enrichies.'
  },
  CreatingPortfolio: "Création d'un portefeuille",
  UpdatingPortfolio: "Actualisation d'un portefeuille",
  Share: {
    SharePortfolioTitle: 'Partager un portefeuille',
    Subtitle: 'Sélectionner les utilisateurs',
    Description: 'Sélectionner les utilisateurs avec lesquels partager le portefeuille',
    Success: 'Actualisation du partage exécutée correctement.',
    ShareCompany: 'Partager société',
    DeleteSharing: 'Supprimer le partage',
    SharedCompanies: 'Entreprises partagées',
    SharedPortfolio: 'Portefeuille partagé',
    SharePortfolio: 'Partage de portefeuille',
    SharedByOwner: 'Partagé par {{ownerName}}',
    ShareCompanies: {
      Title: 'Partager des sociétés',
      Subtitle: 'Sélectionner les utilisateurs',
      Description: 'Sélectionner les utilisateurs avec lesquels partager les sociétés',
      WithAreaManager: 'Partager des sociétés avec un responsable de secteur',
      WithSales: 'Partager des sociétés avec un commercial',
      ConfirmTitle: 'Confirmer opération',
      ConfirmSubtitle: "Actualisation du partage avec l'utilisateur sélectionné.",
      ConfirmDescription: "Ce processus peut prendre quelques instants. Continuer l'opération ?",
      Check: 'Vérification de la validité de toutes les sociétés au partage.',
      CheckFail: 'Les sociétés sélectionnées doivent être affectées.'
    },
    UnshareCompanies: {
      Title: 'Arrêter le partage de sociétés',
      Subtitle: 'Sélectionner les utilisateurs',
      Description: 'Sélectionner les utilisateurs avec lesquels arrêter de partage des sociétés',
      WithAreaManager: 'Arrêter le partage de sociétés avec le responsable de secteur',
      WithSales: 'Arrêter le partage de sociétés avec le commercial',
      ConfirmTitle: 'Confirmer opération',
      ConfirmSubtitle: "Actualisation de l'arrêt du partage avec l'utilisateur sélectionné",
      ConfirmDescription: "Ce processus peut prendre quelques instants. Continuer l'opération ?",
      Check: "Vérification de la validité de toutes les sociétés à l'arrêt du partage.",
      CheckFail: 'Les sociétés sélectionnées doivent être affectées.'
    }
  },
  Exclude: {
    Title: 'Exclure un portefeuille',
    ExcludedPortfolio: 'Portefeuille exclu',
    Description: 'Sélectionner les utilisateurs qui recevront le portefeuille sélectionné en exclusion',
    Success: 'Le partage des exclusions a été exécuté correctement.',
    Error: "Nous sommes désolés, mais nous n'avons pas pu participer à l'exclusion de ce portefeuille."
  },
  AddLocalUnits: {
    Title: 'Ajouter unités locales',
    TooManyCompanies:
      'Redimensionner le portefeuille à {{maxCompanies}} sociétés maximum pour utiliser cette fonctionnalité.',
    NoCompanySelected: 'Aucune société sélectionnée. Ajouter au moins un Siège.',
    NoHeadquarter: 'Le portefeuille sélectionné contient déjà des Unités locales. Ajouter au moins un Siège.',
    NoHeadquarterSelected: 'Les sociétés sélectionnées sont déjà des Unités Locales. Ajouter au moins un Siège.',
    Enrich: {
      Title: 'Acheter les données de la société {{companyName}} pour pouvoir ajouter des unités locales',
      Description:
        'Des unités locales sont ajoutées à un portefeuille contenant toutes les données de {{companyName}} demandées. Elles seront enregistrées dans la section « Utilisation » de votre compte.'
    },
    ChooseBranch: {
      Title: 'Choisir la filiale à ajouter.',
      Description: 'Sélectionner le type de filiale à ajouter.',
      All: 'Tous les types de filiales',
      DeleteAll: 'Supprimer tous'
    },
    ChooseStatus: {
      Title: 'Choisir le statut à ajouter.',
      Description: 'Sélectionner le statut initial des sociétés à ajouter.'
    },
    CreateTag: {
      Title: 'Créer étiquette.',
      Description: 'Chaque société sera définie par une étiquette à créer.',
      Label: 'Créer étiquette (option)',
      Placeholder: 'Insérer une étiquette'
    }
  },
  EditPortfolioCompany: {
    Title: "Modifier les valeurs de l'entreprise",
    Subtitle: 'Changer les valeurs',
    Description:
      'Les valeurs changent pour les champs Propriété dans le portefeuille et Produit non complété en raison de la présence de la même entreprise dans le portefeuille avec les mêmes valeurs pour ces champs. Les valeurs des autres champs ont été correctement mises à jour.',
    CompanyStatus: 'Property in portfolio',
    Amount: 'Amount',
    AreaManager: 'Area Manager',
    Sales: 'Sales',
    Product: 'Product',
    Tags: 'Tags',
    Save: 'Save',
    Error: 'Erreur',
    ErrorDescription: 'Échec du chargement des données.',
    SaveSuccessTitle: 'Changements sauvegardés',
    SaveSuccessText: 'Les modifications ont été enregistrées correctement.',
    SaveErrorTitle: 'Erreur',
    SaveErrorText: "Une erreur s'est produite lors de l'enregistrement des modifications. Veuillez réessayer plus tard."
  }
}
