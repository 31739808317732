import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { addQueryAsExpression, getQueriesKeys, updateQueryAsExpression } from '../../../api/query'
import { getSalestoolKeys } from '../../../api/salestool'
import { useGetOrderBy } from '../../../features/filters/hooks'
import { getSelectedFilterGroups } from '../../../features/filters/selectors'
import { SemanticSearch } from '../../../features/filters/types'
import { convertSelectedFilterGroupKeysToVisibleFilters, hasCampaignIdsFilter } from '../../../features/filters/utils'
import { ErrorResponse, getFieldError, parseErrors } from '../../../features/form/utils'
import { mapOrderByOptionsToQueryOrderByOptions } from '../../../features/mappers'
import { CompoundExpression } from '../../../features/operations/types'
import { QueryActions } from '../../../features/query/actions'
import { RootState } from '../../../features/types'
import { QueryType } from '../../../types'
import { REDUCER_PATH } from '../constants'
import { SubmitMethod } from './types'
import { getDashboardKeys } from '../../../api/dashboard/queries'

type SaveQueryMutationData = {
  queryId: string | undefined
  queryName: string
  expression: CompoundExpression
  semanticSearch: SemanticSearch | undefined
  excludedPortfoliosIds: string[] | undefined
  selectedPortfoliosIds: string[] | undefined
  currentSaveMethod: SubmitMethod
  portfolioId: string
  setUpdateError: (value: string | undefined) => void
  setNameError: (value: string | undefined) => void
  addToDashboard: boolean
}

export const useSaveQueryMutation = ({
  queryId,
  queryName,
  expression,
  semanticSearch,
  excludedPortfoliosIds,
  selectedPortfoliosIds,
  currentSaveMethod,
  portfolioId,
  setUpdateError,
  setNameError,
  addToDashboard
}: SaveQueryMutationData) => {
  const { t } = useTranslation(['portfolio', 'error', 'common'])
  const dispatch = useDispatch()

  const { orderBy } = useGetOrderBy({ moduleName: 'Portfolio' })

  const selectedFilterGroupKeys = useSelector((state: RootState) =>
    getSelectedFilterGroups(state, { reducer: REDUCER_PATH, entityId: portfolioId })
  )

  const client = useQueryClient()

  const saveQuery = useMutation<{}, AxiosError<ErrorResponse>>(
    () => {
      const request = {
        query: expression,
        excludedPortfoliosIds,
        semanticSearch,
        selectedPortfoliosIds,
        orderBy: mapOrderByOptionsToQueryOrderByOptions(orderBy) ?? undefined,
        visibleFilters: convertSelectedFilterGroupKeysToVisibleFilters(selectedFilterGroupKeys)
      }
      if (currentSaveMethod === SubmitMethod.CreateNew) {
        return addQueryAsExpression({
          ...request,
          queryName,
          type: QueryType.PortfolioQueries,
          addToDashboard,
          associatedEntityId: portfolioId
        })
      }
      if (!queryId) {
        return Promise.reject()
      }
      return updateQueryAsExpression(queryId, request)
    },
    {
      onSuccess: () => {
        const hasExcludedCampaigns = expression && hasCampaignIdsFilter(expression.childExpressions)
        if (currentSaveMethod === SubmitMethod.Update) setUpdateError(undefined)
        else setNameError(undefined)

        dispatch(QueryActions.fetchQueries(QueryType.PortfolioQueries))

        client.resetQueries(getQueriesKeys.All())
        if (hasExcludedCampaigns) {
          client.resetQueries(getSalestoolKeys.GetCampaigns())
        }
        if (addToDashboard) {
          client.resetQueries(getDashboardKeys.GetUserDashboard())
        }
      },
      onError: e => {
        if (currentSaveMethod === SubmitMethod.Update) {
          setUpdateError('error')
          return
        }

        if (e?.response?.status === 409) {
          setNameError(t('error:UniqueName'))
          return
        }
        const queryNameErr = parseErrors(e).queryName
        const label = t('portfolio:SaveQuery.Fields.QueryName')

        const error = getFieldError(queryNameErr, label, t)
        setNameError(error ?? 'error')
      }
    }
  )

  return saveQuery
}
