/* eslint-disable import/no-default-export */
export default {
  ExcelFileType: 'The selected file is invalid. Load an excel file (.xls or .xlsx extension).',
  FileNotEmpty: 'File cannot be empty.',
  Forbidden: 'Forbidden',
  ExcludeCustomVariablesError:
    'The structure of Custom Variables in this portfolio is not compatible with the origin one.',
  Length: 'The "{{label}}" is between a minimum of {{min}} letters and a maximum of {{max}} letters',
  LengthLong: 'The max length is {{max}} characters',
  LengthShort: 'The min length is {{min}} characters',
  MissingClaims: 'You are missing claims: {{claims}}.',
  NoSpecialCharacters: 'The "{{label}}" cannot contain special characters',
  NoSpecialCharactersWoLabel: 'The field cannot contain special characters',
  MaxTagsCount: 'Maximum number of tags provided: 20',
  NotEmpty: 'Field can not be empty.',
  CannotStartWithSpace: 'Name cannot start with space',
  PortfolioCreationError: 'Warning! portfolio creation error. Check the selected file and try uploading again.',
  PortfolioCreationFromListError: 'Warning! Portfolio creation error. Insert the IDs list and try uploading again.',
  PortfolioImportCompaniesLessThen:
    'The template exceeds the maximum number of {{maxCompaniesCount}} rows allowed, please check and try again.',
  PortfolioImportCompaniesNotEmpty: 'The uploaded file is not valid. Make sure there is at least one company.',
  PortfolioImportHasCustomVariableSheet: 'Sheet for custom variables is missing in the uploaded file.',
  PortfolioImportCustomVariableNames: 'Custom variables of the uploaded file have to contain unique label names.',
  PortfolioImportValidStructure:
    'The uploaded file is not valid. Make sure the template structure used is the same as the one proposed.',
  Required: 'The "{{label}}" field is mandatory',
  RequiredWithoutFieldName: 'Field is mandatory',
  ServerError: 'Server error',
  MinimumLength8Characters: 'The password must be at least 8 characters long',
  MustContainLetter: 'The password must contain at least one letter and one number',
  MustContainNumber: 'The password must contain at least one letter and one number',
  MustContainSpecialCharacter: 'The password must contain at least one number and one special character',
  CannotContainUsername: 'The password must not contain the User Id',
  CannotBeSameAsOldPassword: 'The password cannot be the same as the old password',
  MustContainCapitalLetter: 'The password must contain uppercase and lowercase letters',
  MustContainLowercaseLetter: 'The password must contain uppercase and lowercase letters',
  CannotContainMoreThan2EqualCharactersInRow:
    'The password cannot contain more than 3 consecutive identical characters',
  CannotContainMoreThan3EqualCharactersInRow:
    'The password cannot contain more than 3 consecutive identical characters',
  MaximumLength25Characters: 'The password must be between 8 and 25 characters (letters and numbers)',
  CannotBeTheSameAsLast6Passwords: 'The password must not be the same as the last six passwords used',
  CannotBeTheSameAsLast10Passwords: 'The password must not be the same as the last 10 passwords used',
  UniqueName: 'Existing name. Please specify a different name.',
  Warning: 'Warning',
  ForbiddenText: 'Sorry, you are not authorized to access this page.',
  PortfolioDownloadError: 'Warning! Portfolio download error. Please try again.',
  PortfolioUpdateError: 'Warning! Portfolio update error. Check the selected file and try update again.',
  PortfolioLockErrorTitle: 'Confirm operation',
  PortfolioLockError: 'The operation cannot be completed because another action is running on the portfolio.',
  PdfReportLockErrorTitle: 'Confirm operation',
  PdfReportLockError:
    'Creation of the pdf in progress for the company {{companyName}}, wait for the completion of the operation.',
  CouldNotValidatePortfolioName: 'Server error. Could not validate portfolio name.',
  MinMax: 'Value has to be between {{min}} and {{max}}',
  OnlyIntegers: 'Only integers are allowed.',
  ProspectingSaveListError: 'Warning! Save list error. Check the selected inputs and try again.',
  NotEmail: 'The field "{{label}}" does not have valid email format.',
  InvalidUrl: 'The field "{{label}}" does not have valid URL format.',
  InvalidPhone: 'The field "{{label}}" does not have valid phone format.',
  MustAgreeWithPrivacyPolicy: 'You must agree with privacy policy.',
  ContactError: 'We are sorry but we could not process your message.',
  ResetPasswordError: 'We are sorry but we could not reset your password.',
  DuplicatesNotAllowed: 'Duplicates are not allowed.',
  CreateGoal: 'We are sorry but we could not create your goal.',
  SharePortfolio: 'We are sorry but we could not share portfolio.',
  OnlyNumbersOrSpace: 'The {{label}} can contain only numbers or space',
  CampaignNameAlreadyExist: 'Existing campaign name. Please choose a different name',
  CampaignNameTooShort: 'The name of the campaign must be between 5 and 35 letters',
  CampaignNameTooLong: 'The name of the campaign must be between 5 and 35 letters',
  CampaignEmpty: 'The campaign name is mandatory',
  CampaignInvalidName: 'The campaign name cannot contain special characters',
  UniqueGoalName: 'Existing goal name. Please specify a different name.',
  UniqueTaskName: 'Existing name. Please specify a different name',
  StartCampaignError: 'An error occurred while stopping the campaign. Please try again later.',
  StopCampaignError: 'An error occurred while starting the campaign. Please try again later.',
  DuplicateCompanyError:
    'The company can not be duplicated: both the status and the product have already been assigned.',
  DeleteCampaign: 'An error occurred while deleting the campaign. Please try again later.',
  ChangeCampaignName: 'An error occurred while changing the campaign name. Please try again later.',
  ChangeEndDate: 'An error occurred while changing the end date. Please try again later.',
  ChangeStartDate: 'An error occurred while changing the start date. Please try again later.',
  ChangeCampaignNote: 'An error occurred while changing note. Please try again later.',
  AddCompanyNote: 'An error occurred while adding the note. Please try again later.',
  DeleteCompanyNote: 'An error occurred while deleting the note. Please try again later.',
  FetchCompanyNotes: 'An error occurred while fetching notes. Please try again later.',
  AssignTask: 'An error occurred while assigning the selected task. Please try again later.',
  CampaignDownloadError: 'An error occurred while downloading the excel file. Please try again later.',
  RenewCampaign: 'Renewing this campaign is not possible as it does not contain a valid portfolio.',
  AssignCampaignError: 'An error occurred while saving your assignments. Please try again later.',
  AssignCampaignErrorCompaniesDuplicated:
    'An error occurred while saving your assignments. There are one or more companies duplicated.',
  FetchCampaigns: 'An error occurred while fetching the campaigns. Please try again later.',
  SaveCampaign: 'An error occurred while saving the campaign. Please try again later.',
  DuringCampaignCreation:
    'An error occurred and it is not possible to complete the campaign creation process. Please try again later.',
  FetchCompanies: 'An error occurred while fetching the companies.',
  EnrichCompany: 'We are sorry but enrichment of companies failed.',
  NotFound: {
    Title: 'Not found',
    Text: 'Sorry, the page you visited does not exist.',
    BackHome: 'Back home',
    DocumentNotFound: 'Document is not longer available.',
    PortfolioNotFound: 'Portfolio is not longer available.',
    CampaignNotFound: 'Campaign is not longer available.',
    GoBack: 'Back',
    QueryNotFound: 'Query is not available.',
    ReportLoadFailed: 'Report is not available or it does not exists.',
    Section: 'Section is not available',
    SectionForCountry: 'This section is not available for your country ({{country}})',
    ResetPasswordLinkExpired: 'Your password reset link has expired. Please, request a new link in Login page',
    EnrichmentNotPossible: 'Enrichment is not possible.',
    EnrichmentNotPossibleBody:
      'The company you are looking for is not enrichable because it may belong to a portfolio hidden by another user of your organization.',
    ReportNotAvailable: 'Report is not available.',
    ReportNotAvailableBody:
      'The company you are looking for is not accessible because it may belong to a portfolio hidden by another user of your organization.'
  },
  ErrorPage: {
    Text: 'An unexpected error occured.'
  },
  ConfigGuardErrorPage: {
    Title: 'We are sorry',
    Text: 'Application config could not be loaded.',
    Reload: 'Reload configuration',
    Logout: 'Logout'
  },
  CreateProduct: 'We are sorry but we could not create your product.',
  UniqueProductName: 'Existing product name. Please specify a different name.',
  PasswordSameAsOld: 'The password cannot be the same as the old password',
  PasswordCouldNotBeChanged: 'The password could not be changed. Please check the rules.',
  InvalidOldPassword: 'The password entered is incorrect.',
  UsernameDoesMatch: 'The impersonated username is same as the username',
  PasswordContainsUsername: 'The password cannot contain the username',
  PasswordCharacterAndDigit: 'The password must contain at least one letter and one digit',
  PasswordMultipleCharacters: 'The password cannot contain the three consecutive equal characters',
  PasswordDoesNotMatch: "Password doesn't match",
  DisallowedPassword: 'Password is not allowed',
  SelectAtLeastOneCompany: 'You must select at least one company.',
  TargetingLoadPortfolio: 'Error while loading the selected portfolio',
  TargetingLoadPortfolioDescription: 'Please try again later, or the selected portfolio might not be valid',
  TargetingLoadQuery: 'Error while loading the selected query',
  TargetingLoadQueryDescription: 'Please try again later, or the selected query might not be valid',
  TargetingDownloadAnalysisError: 'An error has occurred and your analysis could not be downloaded.',
  GenericMessage: 'There was error while processing your request.',
  LoginSE: 'There is problem with login, try again later.',
  TooManyWordsInFilter:
    'The maximum number of 1,000 words ​​that can be entered simultaneously has been exceeded ({{wordCount}}/1000)',
  FailedToLoadImprintPolicy: 'Impressum could not be loaded, please try again later.',
  FailedToLoadPrivacyPolicy: 'Privacy policy could not be loaded, please try again later.',
  FailedToLoadCookiesPolicy: 'Cookies policy could not be loaded, please try again later.',
  OneOfRequired: 'One of fields "{{field}}" or "{{otherField}}" is required.',
  NoEmptyValues: 'The query must not contain empty values.',
  ProspectingFunnelErrorTitle: 'Error in loading funnel',
  ProspectingFunnelErrorText: 'Error in loading funnel. Please, try again or contact customer service.',
  MinValueIsRequired: '"Min" value is required',
  MaxValueIsRequired: '"Max" value is required',
  MatchingTermDoesNotExist: '{{matchingTerm}} does not exist',
  R_24: 'Update of company would cause duplicity. Company with the same "Property in portfolio" and "Product" already exists in portfolio.',
  1005: 'Maximum number of tags exceeded the allowed limit of 20.',
  CountryRequired: 'Please select country'
}
