import { IMapCountryCustomizations } from './types'
import { MapCountryCustomizationIta } from './CountryCustomizationIta'
import { MapCountryCustomizationCzsk } from './CountryCustomizationCzsk'
import { MapCountryCustomizationDeu } from './CountryCustomizationDeu'
import { MapCountryCustomizationFareast } from './CountryCustomizationFareast'
import { MapCountryCustomizationChe } from './CountryCustomizationChe'
import { MapChartMetadata } from '../../../api/targeting/types'

export const getCountryCustomization = (
  countryCode: string,
  mapSettings?: MapChartMetadata
): IMapCountryCustomizations => {
  switch (countryCode) {
    case 'czsk':
      return new MapCountryCustomizationCzsk(mapSettings)
    case 'deu':
      return new MapCountryCustomizationDeu(mapSettings)
    case 'fareast':
      return new MapCountryCustomizationFareast(mapSettings)
    case 'che':
      return new MapCountryCustomizationChe(mapSettings)
    case 'ita':
    default:
      return new MapCountryCustomizationIta(mapSettings)
  }
}
