import _ from 'lodash'
import { createSelector } from 'reselect'
import { Claim, UserType } from '../../authorization'
import { paramSelector } from '../../utils/selectors'
import { RootState } from '../types'

type WithUserClaim = { claim: Claim }

const getFullName = (firstName?: string, lastName?: string) => _.join([firstName, lastName], ' ')

const selectState = (state: RootState) => state.user

export const getToken = createSelector(selectState, state => state.token)

export const isLoggedIn = createSelector(getToken, token => !!token)

export const hasPortfolio = createSelector(selectState, state => state.hasPortfolio)

export const getSelectedLanguage = createSelector(selectState, state => state.selectedLanguage)

export const getSelectedCurrency = createSelector(selectState, state => state.selectedCurrency)

export const getAvailableRedirects = createSelector(selectState, state => state.availableRedirects)

export const canRedirectToCcxBalanceSheet = createSelector(getAvailableRedirects, redirects =>
  _.includes(redirects, 'CCX_BalanceSheets')
)
export const canRedirectToCcxPurchaseReport = createSelector(getAvailableRedirects, redirects =>
  _.includes(redirects, 'CCX_PurchaseReport')
)
export const canRedirectToCcxCreditCheckReport = createSelector(getAvailableRedirects, redirects =>
  _.includes(redirects, 'CCX_CreditCheckReport')
)

export const getHasPortfolioExclusions = createSelector(selectState, state => state.hasPortfolioExclusions)

export const getBlacklistedFeatures = createSelector(selectState, state => state.blacklistedFeatures)

export const getCustCompanyVisiblityAccountEnabled = createSelector(
  selectState,
  state => state.customerCompanyVisibilityAccountEnabled
)

export const getCustCompanyConfigurationApplied = createSelector(
  selectState,
  state => state.customerCompanyConfigurationApplied
)
// ********************************* Queries *******************************************

export const portfolioQueriesEnabled = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'queries.portfolio')
)

export const targetingQueriesEnabled = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'queries.targeting')
)

export const prospectingQueriesEnabled = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'queries.prospecting')
)

// ********************************* Portfolio *******************************************

export const canSharePortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.sharePortfolio')
)

export const canDeletePortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.deletePortfolio')
)

export const canUpdatePortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.updatePortfolio')
)

export const canUploadPortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.uploadPortfolio')
)

export const canGenerateSimilarity = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.generateSimilarity')
)

export const canCreatePortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.createPortfolio')
)

export const canCreateCampaign = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.createCampaign')
)

export const canDownloadPortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.download')
)

export const canChangePortfolioName = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.changeName')
)

export const canEnrichPortfolio = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.enrich')
)

// ********************************* Portfolio companies *******************************************
export const canDeleteCompany = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.deleteCompany')
)

export const canShareCompany = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.shareCompany')
)

export const canMoveCompany = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'portfolio.moveCompany')
)

// ********************************* Targeting *******************************************

export const targetingSaveListEnabled = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'targeting.saveList')
)

export const targetingFromAllEnabled = createSelector(
  getBlacklistedFeatures,
  features => !_.includes(features, 'targeting.fromAll')
)

// ********************************* Other permissions *******************************************

export const freeSearchEnabled = createSelector(getBlacklistedFeatures, features => !_.includes(features, 'freeSearch'))

export const getCurrentInstance = createSelector(selectState, state => state?.instanceCode)

// Extension Manager
export const hasSurveys = createSelector(selectState, state => state.extensionsManager.hasSurveys)
export const getAvailableItems = createSelector(selectState, state => state.extensionsManager.availableItems)

// claims

export const getUserClaims = createSelector(selectState, state => state.claims)
export const hasUserClaim = createSelector(getUserClaims, paramSelector<WithUserClaim>(), (claims, { claim }) =>
  Boolean(claims & claim)
)
export const hasProspectingClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Prospecting))
export const hasTargetingClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Targeting))
export const hasSalesToolClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.SalesTool))
export const hasPortfolioManagementClaim = createSelector(getUserClaims, claims =>
  Boolean(claims & Claim.PortfolioManagement)
)
export const hasOperationsClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Operations))
export const hasPnrrClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Pnrr))

// user

const getUser = createSelector(selectState, state => state.user)

export const getUserId = createSelector(getUser, userState => userState.id)

export const getUserFirstName = createSelector(getUser, userState => userState.firstName)

export const getUserLastName = createSelector(getUser, userState => userState.lastName)

export const getUserFullName = createSelector(getUserFirstName, getUserLastName, getFullName)

export const getUserCode = createSelector(getUser, userState => userState.userCode)

export const getUserEmail = createSelector(getUser, userState => userState.email)

export const getLanguage = createSelector(getUser, userState => userState.language)

export const getCurrency = createSelector(getUser, userState => userState.currency)

export const getUserType = createSelector(getUser, userState => userState.userType)

export const getImpersonationAllowed = createSelector(getUser, userState => userState.impersonationAllowed)

export const isReleaseNotesEditor = createSelector(getUser, userState => userState.isReleaseNotesEditor)

export const isUserTypeSales = createSelector(getUserType, userType => Boolean(userType & UserType.Sales))

export const isUserTypeSupervisor = createSelector(getUserType, userType => Boolean(userType & UserType.Supervisor))

// organization

const getOrganization = createSelector(selectState, state => state.organization)

export const getOrganizationId = createSelector(getOrganization, organizationState => organizationState.id)

export const getOrganizationDistinguishName = createSelector(
  selectState,
  state => state.user.organizationUserDistinguishName
)

export const getOrganizationName = createSelector(getOrganization, organizationState => organizationState.name)

export const getOrganizationCode = createSelector(getOrganization, organizationState => organizationState.code)

export const getOrganizationCountryCode = createSelector(
  getOrganization,
  organizationState => organizationState.countryCode
)

// originator user

const getOriginator = createSelector(selectState, state => state.originatorUser)

export const hasOriginator = createSelector(getOriginator, originatorState => !!originatorState)

export const getOriginatorUserCode = createSelector(getOriginator, originatorState => originatorState?.userCode)

// branch

const getBranch = createSelector(selectState, state => state.branch)

export const getBranchName = createSelector(getBranch, branchState => branchState.name)

// subscription

export const getSubscription = createSelector(selectState, state => state.subscription)

export const getSubscriptionId = createSelector(getSubscription, subscriptionState => subscriptionState.id)

export const hasBaseList = createSelector(getSubscription, subscriptionState => subscriptionState.hasBaseList)

export const hasMarketingList = createSelector(getSubscription, subscriptionState => subscriptionState.hasMarketingList)

export const hasCompleteList = createSelector(getSubscription, subscriptionState => subscriptionState.hasCompleteList)

export const hasAnalyticsIndexes = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.hasAnalyticsIndexes
)

export const hasPdfReport = createSelector(getSubscription, subscriptionState => subscriptionState.hasPdfReport)

export const hasLocalUnits = createSelector(getSubscription, subscriptionState => subscriptionState.hasLocalUnit)

export const hasRetailIndexes = createSelector(getSubscription, subscriptionState => subscriptionState.hasRetailIndexes)

export const hasSemanticClusters = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.hasSemanticClusters
)

export const getVisibilityCountries = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.visibilityCountries
)

// subscription detail

export const hasPortfolioActions = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.details.hasPortfolioActions
)

export const hasExtensions = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.details.hasExtensions
)

export const hasEasyFinance = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.details.hasEasyFinance
)

// combined

export const getUserAccountInfo = createSelector(
  getUser,
  getBranchName,
  getSubscriptionId,
  (user, branchName, subscriptionId) => ({
    ..._.pick(user, ['userCode', 'userType', 'id']),
    officeName: branchName,
    subscriptionId
  })
)
