import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { addQueryAsExpression, getQueriesKeys, updateQueryAsExpression } from '../../../../api/query'
import { FilterReducerPath, SemanticSearch } from '../../../../features/filters/types'
import { QueryType } from '../../../../types'

import { ErrorResponse, getFieldError, parseErrors } from '../../../../features/form/utils'
import { SubmitMethod } from './types'
import { CompoundExpression } from '../../../../features/operations/types'
import { RootState } from '../../../../features/types'
import { getSelectedFilterGroups } from '../../../../features/filters/selectors'
import {
  convertSelectedFilterGroupKeysToVisibleFilters,
  hasCampaignIdsFilter
} from '../../../../features/filters/utils'
import { QueryActions } from '../../../../features/query/actions'
import { getSelectedPortfolios } from '../../../../features/targeting/selectors'
import { getSalestoolKeys } from '../../../../api/salestool'
import { getDashboardKeys } from '../../../../api/dashboard/queries'

const REDUCER: FilterReducerPath = 'targeting'

type SaveQueryMutationData = {
  queryId: string | undefined
  queryName: string
  expression: CompoundExpression
  semanticSearch: SemanticSearch | undefined
  excludedPortfoliosIds: string[] | undefined
  selectedPortfoliosIds: string[] | undefined
  currentSaveMethod: SubmitMethod
  addToDashboard: boolean
  setUpdateError: (value: string | undefined) => void
  setNameError: (value: string | undefined) => void
}

export const useSaveQueryMutation = ({
  queryId,
  queryName,
  expression,
  semanticSearch,
  excludedPortfoliosIds,
  selectedPortfoliosIds,
  currentSaveMethod,
  addToDashboard,
  setUpdateError,
  setNameError
}: SaveQueryMutationData) => {
  const { t } = useTranslation(['error', 'targeting'])
  const dispatch = useDispatch()

  const selectedFilterGroupKeys = useSelector((state: RootState) =>
    getSelectedFilterGroups(state, { reducer: REDUCER })
  )
  const selectedPortfolios = useSelector(getSelectedPortfolios)
  const singleSelectedPortfolio = selectedPortfolios?.length === 1 ? selectedPortfolios[0] : null

  const client = useQueryClient()

  const saveQuery = useMutation<unknown, AxiosError<ErrorResponse>>(
    () => {
      const request = {
        query: expression,
        excludedPortfoliosIds,
        semanticSearch,
        selectedPortfoliosIds,
        visibleFilters: convertSelectedFilterGroupKeysToVisibleFilters(selectedFilterGroupKeys)
      }
      if (currentSaveMethod === SubmitMethod.CreateNew) {
        return addQueryAsExpression({
          ...request,
          queryName,
          type: QueryType.TargetingQueries,
          addToDashboard,
          associatedEntityId: singleSelectedPortfolio?.id
        })
      }
      if (!queryId) {
        return Promise.reject()
      }
      return updateQueryAsExpression(queryId, request)
    },
    {
      onSuccess: () => {
        const hasExcludedCampaigns = expression && hasCampaignIdsFilter(expression.childExpressions)
        if (currentSaveMethod === SubmitMethod.Update) setUpdateError(undefined)
        else setNameError(undefined)

        dispatch(QueryActions.fetchQueries(QueryType.TargetingQueries))

        client.resetQueries(getQueriesKeys.All())
        if (hasExcludedCampaigns) {
          client.resetQueries(getSalestoolKeys.GetCampaigns())
        }
        if (addToDashboard) {
          client.resetQueries(getDashboardKeys.GetUserDashboard())
        }
      },
      onError: (e: AxiosError<ErrorResponse>) => {
        if (currentSaveMethod === SubmitMethod.Update) {
          setUpdateError('error')
          return
        }

        if (e?.response?.status === 409) {
          setNameError(t('UniqueName'))
          return
        }
        const queryNameErr = parseErrors(e).queryName
        const label = t('prospecting:SaveQuery.Fields.QueryName')

        const error = getFieldError(queryNameErr, label, t)
        setNameError(error ?? 'error')
      }
    }
  )

  return saveQuery
}
