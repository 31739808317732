import styled, { css } from 'styled-components/macro'
import { Radio as AntRadio } from 'antd3'

export const radioButtonMixin = css`
  .ant-radio {
    .ant-radio-inner {
      border-color: ${({ theme }) => theme.colors.paleGrey} !important;
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }

    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.main} !important;
        background-color: ${({ theme }) => theme.colors.white};

        ::after {
          background-color: ${({ theme }) => theme.colors.main};
        }
      }
    }

    &.ant-radio-disabled {
      .ant-radio-inner {
        opacity: 0.4;
      }
    }

    &:hover:not(.ant-radio-disabled) {
      .ant-radio-inner {
        box-shadow: 0 4px 8px 0 rgba(0, 60, 125, 0.2);
      }
    }
  }
`

export const Radio = styled(AntRadio)`
  ${radioButtonMixin}
`

export const RadioGroup = styled(AntRadio.Group)`
  ${radioButtonMixin}
`

export const RadioButton = AntRadio.Button

export const RadioButtonGroup = styled(AntRadio.Group)<{ stretch?: boolean; height?: number; buttonWidth?: number }>`
  &.ant-radio-group {
    ${({ stretch }) => stretch && 'width: 100%;'}
    display: flex;
    /* IE fix */
    flex: 1 0 auto;

    .ant-radio-button-wrapper {
      ${({ height }) => height && `height: ${height}px;`}
      ${({ buttonWidth }) => buttonWidth && `width: ${buttonWidth}px;`}

      /* IE fix */
      flex: 1 0 auto;
      color: ${({ theme }) => theme.colors.main};
      border-color: ${({ theme }) => theme.colors.veryLightPink};
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      &:focus-within {
        outline: none;
      }
      &.ant-radio-button-wrapper-checked {
        border-color: ${({ theme }) => theme.colors.veryLightPink};
        background-color: ${({ theme }) => theme.colors.secondary};
        box-shadow: none;
      }
    }
  }
`
