import { useCallback, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useTheme } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Location } from 'react-router-dom'
import { useBranding } from '../../hooks/useBranding'
import { NotificationActions } from '../../features/notifications/actions'
import { notificationPopup } from '../../components/Notification'
import { getLastNotificationId, getNotifications } from '../../features/notifications/selectors'
import { FileActions } from '../../features/files/actions'
import { notificationCallback } from '../../utils/notifications'
import { getUserType } from '../../features/user/selectors'
import { FormatNumberFunction } from '../../utils/types'
import { RootState } from '../../features/types'
import { canSeeSalesTool } from '../../features/config/selectors'

type Props = {
  formatNumber: FormatNumberFunction
}

export const NotificationEventContainer = (props: Props) => {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const notifications = useSelector(getNotifications)
  const lastNotificationId = useSelector(getLastNotificationId)
  const userType = useSelector(getUserType)
  const hasAccessSalesTool = useSelector(canSeeSalesTool)
  const store = useStore<RootState>()
  const theme = useTheme()
  const { allowedModules } = useBranding()

  const downloadFile = useCallback(
    (documentId: string) => {
      dispatch(FileActions.downloadDocument(documentId, navigate))
    },
    [dispatch, navigate]
  )

  const navigationCallback = useCallback(
    (to: Partial<Location>) => {
      navigate(
        {
          pathname: to.pathname,
          search: to.search
        },
        {
          state: to.state
        }
      )
    },
    [navigate]
  )

  const setLastNotificationId = useCallback(
    (notificationId: string) => {
      dispatch(NotificationActions.setLastNotificationId(notificationId))
    },
    [dispatch]
  )

  useEffect(() => {
    const { formatNumber } = props
    const lastNotificationIndex = _.findIndex(notifications, ['id', lastNotificationId])
    const notificationsToDisplay =
      lastNotificationIndex === -1 ? notifications : _.slice(notifications, 0, lastNotificationIndex)
    _.forEach(notificationsToDisplay, notification => {
      notificationPopup.notificationEvent({
        notification,
        navigationCallback,
        downloadFile,
        fallbackTheme: theme,
        userType,
        hasAccessSalesTool,
        allowedModules,
        formatNumber,
        t
      })
      notificationCallback(notification, dispatch, navigate, location, store.getState())
    })
    if (notificationsToDisplay.length > 0) {
      setLastNotificationId(notifications[0].id)
    }
  }, [
    dispatch,
    downloadFile,
    navigationCallback,
    lastNotificationId,
    location,
    navigate,
    notifications,
    props,
    setLastNotificationId,
    store,
    t,
    theme,
    userType,
    hasAccessSalesTool,
    allowedModules
  ])

  return null
}
