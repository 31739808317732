import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FieldValues, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Dialog } from '../../../components/Dialog'
import { AddNoteDialogForm } from '../../../components/Form/AddNoteDialogForm'
import { isDialogLoading as isDialogBeingLoaded } from '../../../features/loading/selectors'
import { DialogActions } from '../../../features/dialog/actions'
import { addPortfolioNote, getPortfolioKeys } from '../../../api/portfolio'

type Props = {
  data: {
    portfolioId: string
    portfolioNote: string
    editing: boolean
  }
}

export const AddNoteDialog = ({ data: { portfolioId, portfolioNote, editing } }: Props) => {
  const { t } = useTranslation(['common', 'portfolio'])
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const { control, formState, watch } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: { note: portfolioNote }
  })
  const noteValue = watch('note')
  const { isValid, isDirty } = formState
  const isDialogLoading = useSelector(isDialogBeingLoaded)
  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(getPortfolioKeys.PortfolioById(portfolioId))
    closeDialog()
  }, [queryClient, portfolioId, closeDialog])

  const { mutate: mutateAddPortfolioNote, isLoading: isAddNoteLoading } = useMutation(
    (note: string) => addPortfolioNote(portfolioId, note),
    { onSuccess }
  )
  const isLoading = isDialogLoading || isAddNoteLoading

  const props = {
    destroyOnClose: false,
    width: 1160,
    visible: true,
    loading: isLoading,
    onCancel: closeDialog,
    footer: '',
    title: t(`portfolio:Management.PortfolioNote.${editing ? 'EditNote' : 'AddNote'}`)
  }

  return (
    <Dialog
      {...props}
      cancelText={t('common:Cancel')}
      okText={t('common:Save')}
      okButtonProps={{ disabled: !isValid || !isDirty }}
      onOk={() => mutateAddPortfolioNote(noteValue)}
    >
      <AddNoteDialogForm
        name="note"
        text={t('portfolio:Management.PortfolioNote.AddANoteToDescribe')}
        label={t('portfolio:Management.PortfolioNote.AddNoteFieldLabel')}
        placeholder={t('portfolio:Management.PortfolioNote.AddNotePlaceholder')}
        editing={editing}
        currentNote={portfolioNote}
        control={control}
        formState={formState}
      />
    </Dialog>
  )
}
