/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  SalesTool: {
    Header: 'Sales Tool',
    Management: {
      Sales: 'Sales',
      Campaigns: 'Kampagne',
      SearchCampaign: 'Kampagne durchsuchen',
      NewCampaign: 'Kampagne erstellen',
      NewCampaignTooltip: 'Planen Sie eine Kampagne ausgehend von einem Portfolio',
      CampaignArchive: 'Kampagnenarchiv',
      CampaignArchiveTooltip: 'Greifen Sie auf das Archiv abgeschlossener Kampagnen zu und erneuern Sie diese',
      CampaignsToAssign: 'zur Zuweisung',
      CampaignsToStart: 'noch nicht gestartete',
      CampaignsActive: 'gestartete',
      Stats: {
        Companies: 'Unternehmen',
        StartDate: 'Startdatum',
        EndDate: 'Enddatum',
        EnrichedCompanies: 'Angereicherte Unternehmen',
        Clients: 'Kunden',
        Suppliers: 'Lieferanten',
        Prospects: 'Prospects',
        LastUpdate: 'Letzes Update',
        Competitors: 'Wettbewerber',
        Lead: 'Lead'
      },
      Table: {
        NumberOfCompanies: 'Anzahl Unternehmen',
        GoalName: 'Ziel-Name',
        Tasks: 'Aufgabe',
        Status: 'Status',
        CampaignPortfolios: 'Portfolios in dieser Kampagne'
      },
      PorfolioBox: 'Portfolio in dieser Kampagne',
      ConversionRate: '{{percentage}}% Conversion-Rate',
      CompletedTasks: '{{percentage}}% erledigte Aufgaben',
      Banner: {
        Title: 'Sie müssen auf den Kampagnennamen "klicken", um die Liste zuzuweisen',
        Subtitle: 'Weisen Sie vor dem Start der Kampagne jedem Unternehmen der Liste einen Sales und die Produkte zu.'
      },
      CreatedAtAscending: 'Erstellungsdatum (Aufsteigend)',
      CreatedAtDescending: 'Erstellungsdatum (Absteigend)',
      StartDateAscending: 'Startdatum (Aufsteigend)',
      StartDateDescending: 'Startdatum (Absteigend)',
      EndDateAscending: 'Enddatum (Aufsteigend)',
      EndDateDescending: 'Enddatum (Absteigend)'
    },
    AddCampaign: {
      EnterName: 'Geben Sie einen repräsentativen Namen für die Kampagne ein',
      EnterNameDescription: 'Sie verwenden ein Ring pro erstellter Kampagne',
      EnterDates: 'Wählen Sie Start- und Enddatum',
      withoutCustomVariables: 'ohne benutzerdefinierte Variablen',
      withCustomVariables: 'mit benutzerdefinierte Variablen',
      EnterDatesDescription:
        'Start- und Enddatum wird immer im Zusammenhang mit der Kampagne angezeigt und hilft Ihnen und Ihren Sales die Geschäftsziele gezielter zu erreichen',
      EnterPortfolio: 'Wählen Sie das Portfolio aus, das Sie der Kampagne zuordnen möchten',
      EnterPortfolioDescription: 'Verknüpfen Sie ein oder mehrere Portfolios mit der gerade erstellten Kampagne.',
      EnterGoal: 'Wählen Sie ein Kampagnen-Ziel',
      EnterGoalDescription: 'Wählen Sie ein Ziel aus, zur Zuodnung von definierten Aufgaben',
      NoGoalDescription:
        'Es gibt noch kein gespeichertes Ziel. Bitte erstellen Sie im Abschnitt "<0>Ihr Konto</0>" eine.',
      InvalidGoal:
        'Das ausgewählte Ziel hat keine Aufgaben. Bitte wählen Sie ein anderes aus oder bearbeiten Sie es im Abschnitt <0>Ihr Konto</0>',
      EnterConversionRate: 'Geben Sie eine Beschreibung ein und legen Sie die Conversion-Rate fest',
      EnterConversionRateDescription:
        'Verfassen Sie eine Beschreibung der zu erstellenden Kampagne, um weitere Informationen für eine bessere Zielerreichung zu liefern.',
      SpecialCharactersNotAllowed: 'Sonderzeichen im Firmennamen nicht erlaubt',
      CampaignNameAlreadyExist: 'Der Kampagnenname ist bereits vorhanden',
      CampaignInvalidName: 'Der Kampagnenname ist ungültig',
      CampaignCreationPendingTitle: 'Kampagne erstellen',
      CampaignCreationPendingText:
        'Ihre Kampagne wird erstellt. Sie werden an Sales Tool weitergeleitet und erhalten eine Benachrichtigung, wenn der Vorgang abgeschlossen ist.',
      SalesPerformanceChartTitle: 'Möchten Sie das Diagramm "Vertriebsleistung" für Vertriebsbenutzer sichtbar machen?',
      SalesPerformanceChartText: 'Aktivieren Sie das Diagramm',
      RestoreDataQuestion:
        'Möchten Sie alle mit dieser Kampagne verknüpften Daten wiederherstellen, bevor sie abgelaufen ist?',
      RestoreDataEnable: 'Alle vorherigen Daten aktivieren',
      RestoreDataEnableDisabledTooltip:
        'Das ursprüngliche Ziel für diese Kampagne kann aufgrund seiner Löschung nicht ausgewählt werden. Wählen Sie ein neues unter den verfügbaren Zielen aus.'
    }
  },
  ManageCampaign: {
    Header: 'Sales und Produkt verwalten',
    SalesHeader: 'Kampagne verwalten'
  },
  Management: {
    Message: {
      Header: 'Erstellen Sie eine Kampagne, weisen Sie sie Ihrem Sales zu und starten Sie sie',
      SubHeader: 'Um eine Kampagne zu erstellen, klicken Sie auf die Schaltfläche "Kampagne erstellen".',
      Description:
        'Nach dem Erstellen Ihrer Kampagne können Sie die Unternehmen Ihrem Sales zuordnen und dann starten. Auf dieser Seite finden Sie alle Ihre Kampagnen.'
    },
    Campaign: {
      ChangeCampaignName: 'Kampagnen-Namen ändern',
      ChangeCampaignNameShort: 'Name ändern',
      ChangeCampaignNote: 'Kampagnennotiz ändern',
      AddCampaignNote: 'Notiz hinzufügen',
      ChangeCampaignNoteDescription: 'Notiz ändern, um die Conversion-Rate zu beschreiben.',
      AddCampaignNoteDescription: 'Notiz eingeben, um die Conversion-Rate zu beschreiben.',
      EnterARepresentativeName: 'Vergeben Sie einen repräsentativen Kampagnen-Namen',
      InsertCampaignName: 'Kampagnen-Namen vergeben',
      InsertCampaignNote: 'Notiz eingeben',
      NameChangedCorrectly: 'Name korrekt geändert in',
      NoteChangedCorrectly: 'Notiz korrekt geändert',
      NoteAddedCorrectly: 'Notiz korrekt eingeben',
      NoteRemovedCorrectly: 'Hinweis korrekt entfernt',
      ChangeEndDate: 'Enddatum ändern',
      ChangeEndDateDescription: 'Ändern Sie das aktuelle Enddatum.',
      ChangeEndDateSuccess: 'wird enden am',
      ChangeStartDate: 'Startdatum ändern',
      ChangeStartDateDescription: 'Aktuellen Startdatum ändern.',
      ChangeStartDateSuccess: 'startet am',
      StartCampaignDialogText1: 'Möchten Sie diese Kampagne starten?',
      StartCampaignDialogText2: 'Den Fortschritt der gesamten Kampagne sehen Sie in Ihrem Dashbaord im Sales Tool.',
      StartCampaignDialogText3:
        'Wenn Sie auf "Weiter" klicken, erhält jeder Sales Mitarbeiter eine Benachrichtigung über seine zugeordneten Unternehmen.',
      StartCampaignDoneDialogText1: '{{campaignName}} beginnt am {{startDate}}.',
      StartCampaignDoneDialogText2:
        'Wenn Sie auf "Weiter" klicken, erhält jeder Sales Mitarbeiter eine Benachrichtigung über seine zugeordneten Unternehmen.',
      StopCampaignDialogText1: 'Wollen Sie diese Kampagne stoppen?',
      StopCampaignDialogText2:
        'Sie wird im Bereich "Kampagnenarchiv" gespeichert. Sie können sie jederzeit "reaktivieren".',
      StopCampaignDialogText3: 'Um das „Kampagnenarchiv“ anzuzeigen, müssen Sie zum Dashboard zurückkehren.',
      StopCampaignDoneDialogText1: '{{campaignName}} wurde erfolgreich beendet. Sie finden sie im “Kampagnenarchiv”.'
    }
  },
  AssignCampaign: {
    Header: 'Sales und Produkt zuweisen',
    StartDate: 'Startdatum:',
    EndDate: 'Enddatum:',
    AddPortfolio: 'Unternehmen/Portfolio hinzufügen',
    AddPortfolioTooltip: 'Neue Unternehmen zu dieser Kampagne hinzufügen',
    SelectColumns: 'Spalten auswählen',
    GroupActions: 'Gruppenaktionen',
    SalestoolAssignmentsSelectPlaceholder: 'wählen',
    AssignSales: 'Sales zuweisen',
    AssignProduct: 'Produkt zuweisen',
    NotAssigned: 'Nicht zugeordnet',
    Sales: 'Sales',
    Product: 'Produkt',
    Note: 'Notizen',
    EditNoteIconTitle: 'Notiz bearbeiten',
    CompaniesCount: 'Unternehmen',
    AssignedCount: 'zugewiesen',
    NotAssignedCount: 'Nicht zugewiesen',
    AppAround: {
      NotAlreadyDone: 'Es wurde noch kein Angebot gesendet',
      InProgress: 'Ein Angebot wurde bereits abgegeben am {{dateTime}}',
      Ko: 'Fehler beim Angebot auf {{dateTime}}',
      Ok: 'Angebot gesendet auf {{dateTime}}'
    },
    Inventia: {
      NotAlreadyDone: 'Es wurden keine Anrufe eingeleitet',
      InProgress: 'Der Anruf wurde gestartet am {{dateTime}}',
      Ko: 'Fehler im Anruf, an {{dateTime}}',
      Ok: 'Anruf durchgeführt am {{dateTime}}'
    },
    Banner: {
      Title: 'Klicken Sie auf "Kampagne zuweisen", um Sales und Produkte zuzuweisen',
      Subtitle:
        'Weisen Sie vor dem Start der Kampagne jedem Unternehmen in der Liste Sales und Produkte zu. Klicken Sie am Ende auf "Speichern", um den Vorgang abzuschließen. Die Kampagne wird erst gestartet, wenn Sie auf "Kampagne starten" klicken.'
    },
    WriteYourNote: 'Hier Notizen eingeben',
    Dialog: {
      SelectAtLeastOneCompany: 'Sie müssen mindestens eine Firma auswählen.',
      AssignSaleTitle: 'Sales zuweisen',
      AssignProductTitle: 'Produkt zuweisen',
      AssignSaleOrProduct: '{{item}} wurde {{companyName}} korrekt zugewiesen.',
      AssignSaleOrProductMultiple: 'und {{count}} andere Firma',
      AssignSaleOrProductMultiple_plural: 'und {{count}} andere Unternehmen',
      DuplicateCompanyTitle: 'Unternehmen duplizieren',
      DuplicateCompanyConfirm: 'Wirklich duplizieren {{companyName}}?',
      DuplicateCompanySelection:
        'Um ein Unternehmen zu duplizieren, muss sein Status oder sein Produkt geändert werden.',
      DuplicateCompanyDone: '{{companyName}} korrekt in {{campaignName}} dupliziert.',
      DuplicateCompanySelectProduct: 'Produkt auswählen',
      DuplicateCompanySelectStatus: 'Status wählen',
      DuplicateCompanyProduct: 'Produkt',
      DuplicateCompanyStatus: 'Status',
      SaveCampaignTitle: 'Kampagne speichern',
      SaveCampaignPending:
        'Der Speichervorgang wird ausgeführt und kann einige Minuten dauern. Nach Abschluss erhalten Sie eine Benachrichtigung.',
      AddCompaniesTitle: 'Hinzufügen von Unternehmen',
      AddPortfolioTitle: 'Unternehmen/Portfolio hinzufügen',
      AddPortfolio: 'Wählen Sie das Portfolio aus, das Sie hinzufügen möchten',
      AddPortfolioSelectPortfolio: 'Portfolio auswählen',
      AddCompaniesPending:
        'Hinzufügen von Unternehmen zu {{campaignName}} im Gange. Am Ende des Vorgangs erhalten Sie eine Benachrichtigung.',
      AddCompaniesWithCustomVariablesStructureChangedPending:
        'Das Hinzufügen von Unternehmen zu {{campaignName}} wird zurzeit durchgeführt. Einige benutzerdefinierte Variablen werden aufgrund einer abweichenden Variablen-Struktur nicht eingefügt. Nach Beendigung des Vorgangs erhalten Sie eine Benachrichtigung.',
      AddPortfolioPending: '{{selectedPortfolioName}} erfolgreich zu {{campaignName}} hinzugefügt.',
      AddCompanyNoteTitle: 'Notizen',
      AddCompanyNote: 'Sie können dieser Firma bis zu fünf Notzien hinzufügen',
      AddCompanyNoteTextAreaLabel: 'Neue Notiz einfügen (max. 250 Zeichen)',
      AddCompanyNoteTextAreaPlaceholder: 'Ihre Notiz...',
      AddNewCompaniesFromStartingPortfolio: 'Fügen Sie neue Unternehmen aus dem Startportfolio hinzu',
      AddedCompanies: 'Unternehmen hinzugefügt',
      AddedPortfolio: 'Portfolio hinzugefügt',
      AddPortfolioSelect: 'Wählen',
      DeleteNoteConfirmation: 'Möchten Sie diese Notiz wirklich löschen?',
      DeleteCompany: {
        Title: 'Unternehmen löschen',
        Text: 'Möchten Sie das Unternehmen wirklich aus der Kampagne löschen?',
        Success: 'Das Unternehmen wurde erfolgreich aus der Kampagne gelöscht.',
        Error: 'Beim Löschen des Unternehmens aus der Kampagne ist ein Fehler aufgetreten.'
      },
      MoveCompany: {
        Title: 'Unternehmen in eine andere Kampagne verschieben',
        Text: 'Das von Ihnen ausgewählte Unternehmen wird aus der aktuellen Kampagne entfernt und einer anderen Kampagne hinzugefügt. Bitte wählen Sie eine Kampagne aus der folgenden Liste aus.',
        SelectLabel: 'Kampagne auswählen',
        Footer: 'Verkäufer und Produkte, die dem Unternehmen zugeordnet sind, werden gelöscht.',
        Success: 'Unternehmen wurde erfolgreich in die Kampagne verschoben.',
        Error: 'Beim Verschieben des Unternehmens in die Kampagne ist ein Fehler aufgetreten.',
        ErrorIncompatibleDestEntity:
          'Die Struktur der benutzerdefinierten Variablen in dieser Kampagne ist nicht mit der ursprünglichen kompatibel.'
      }
    },
    Errors: {
      DuplicateCampaign: 'Beim Duplizieren des Unternehmens ist ein Fehler aufgetreten.',
      AddPortfolio: 'Beim Hinzufügen des ausgewählten Portfolios ist ein Fehler aufgetreten.',
      AddCompanies: 'Beim Hinzufügen neuer Unternehmen aus dem Basis-Portfolio ist ein Fehler aufgetreten.'
    }
  },
  SalesPerformance: {
    Banner: {
      Title: 'Ihre Sales Performance',
      Subtitle: 'wird für jede einzelne Aufgabe in diesem Feld angezeigt'
    },
    Tooltip: 'In diesem Feld werden die Top 5 Sales für die ausgewählte Aufgabe angezeigt',
    Actions: 'Aktionen',
    TotalProgressOrder: 'Gesamter Fortschritt',
    LastUpdateOrder: 'Letztes Update',
    NumberCompaniesOrder: 'Anzahl der zugewiesenen Unternehmen'
  },
  CampaignDetail: {
    MapBoxTitle: 'Geografische Verteilung',
    CompaniesBoxTitle: 'Unternehmen in Bearbeitung',
    SalesBoxTitle: 'Sales Performance',
    NoteLabel: 'Notiz',
    Task: 'Aufgaben',
    TasksStatisticsLabel: 'Untenehmen',
    TasksStatisticsLabel_plural: 'Unternehmen',
    AssignTask: 'Aufgabe zuweisen',
    SalesPerformanceNCompanies: 'Anzahl Unternehmen',
    WaterfallChartTasksCompleted: 'abgeschlossen',
    WaterfallChartBoxTitle: '{{count}} Unternehmen {{percentage}}% abgeschlossen',
    WaterfallChartBoxTitle_plural: '{{count}} Unternehmen {{percentage}}% abgeschlossen',
    Dialog: {
      AssignTaskTitle: 'Aufgabe zuweisen'
    },
    ShowSalesDetail: 'Verkaufsdetails anzeigen'
  },
  CompaniesOnTask: {
    Void: 'in Bearbeitung',
    Called: 'angerufen',
    Visited: 'besucht',
    Negotiation: 'in Verhandlung',
    Active: 'Aktiv',
    Negative: 'Negativ'
  },
  CompanyDetail: {
    Actions: {
      DownloadPDF: 'PDF herunterladen',
      AddToCampaign: 'Zur Kampagne hinzufügen'
    }
  },
  CampaignArchive: {
    Subtitle:
      'Enthält gestoppte und abgeschlossene Kampagnen. Klicken Sie auf "Kampagne erneuern", um die Kampagne mit derselben Zuordnung neu zu starten',
    SubtitleSale: 'Enthält gestoppte und abgeschlossene Kampagnen.',
    RenewCampaign: 'Kampagne erneuern',
    DeleteCampaign: 'löschen'
  },
  EnrichBeforeRenew: 'Before starting the campaign, please enrich the following portfolios:',
  SaleDetail: {
    Area: 'Bereich',
    CampaignsView: 'Kampagnenansicht',
    Summary: 'Zusammenfassung',
    TotalCompanies: 'Unternehmen insgesamt',
    TotalCampaigns: 'Kampagnen insgesamt',
    ActiveCampaigns: 'Aktive Kampagnen',
    ArchivedCampaigns: 'Archivierte Kampagnen',
    CompletedCampaigns: 'Abgeschlossene Kampagnen',
    AssignTaskDialogText1: 'Wollen Sie die Aufgabe zuweisen?',
    AssignTaskDialogText1_noPrevTask: 'Wollen Sie die Aufgabe zuweisen?',
    AssignTaskDialogText2: 'Sie ändern die Aufgabe von "{{originalTask}}" in "{{newTask}}".',
    AssignTaskDialogText2_noPrevTask: 'Sie weisen "{{newTask}}" zu.',
    AssignTaskDoneDialogText1: 'Aufgabe wurde korrekt geändert!',
    AssignTaskDoneDialogText2: 'Ihr Manager erhält eine Benachrichtigung.',
    AssignTaskMultipleDoneDialogText1: 'Die ausgewählte Aufgabe wurde zugewiesen.',
    AssignTaskMultipleDoneDialogText2: 'Ihr Manager wird mit Abschluss der Aufgabe entsprechend informiert.',
    AssignTasksDialogText: 'Sie weisen "{{newTask}}" {{fCount}}  Unternehmen zu.',
    AssignTasksDialogText_plural: 'Sie weisen "{{newTask}}" {{fCount}}  Unternehmen zu.'
  },
  ItemNotAssignedYet: 'Noch nicht zugewiesen',
  LoadMore: 'Mehr hochladen',
  DownloadBtn: 'PDF/Excel herunterladen',
  DownloadBtnTooltip: 'Laden Sie einen Bericht zu dieser Kampagne auf Ihr Gerät herunter',
  AssignCampaignBtn: 'Kampagne zuweisen',
  AssignCampaignBtnTooltip: 'Weisen Sie den Unternehmen in dieser Kampagne Verkäufe und Produkte zu',
  ManageCampaignBtn: 'Kampagne verwalten',
  ManageCampaignBtnTooltip: 'Weisen Sie den Unternehmen in dieser Kampagne Verkäufe und Produkte zu',
  StartCampaignBtn: 'Kampagne starten',
  StartCampaignBtnTooltip: 'Ermöglichen Sie Kampagnentransparenz für den Verkauf und überwachen Sie die Leistung',
  StopCampaignBtn: 'Kampagne beenden',
  StopCampaignBtnTooltip: 'Stoppen Sie diese Kampagne und archivieren Sie sie',
  CampaignName: 'Kampagnen-Name',
  StartDate: 'Startdatum',
  EndDate: 'Enddatum',
  Name: 'Name',
  PortfolioLabel: 'Portfolio',
  GoalLabel: 'Ziel auswählen',
  ConversionRateLabel: 'Conversion-Rate festlegen',
  Close: 'Schliessen',
  Save: 'Speichern',
  SaveTooltip: 'Speichern Sie die an dieser Kampagne vorgenommenen Änderungen',
  Title: 'Kampagne erstellen',
  DomainFilter: {
    SaveAndClose: 'Speichern und schließen'
  },
  Table: {
    Empty: 'leer'
  },
  Errors: {
    CampaignSave: 'Beim Erstellen der Kampagne ist ein Fehler aufgetreten.'
  },
  Download: {
    PDFHeaderTitle: 'Kampagnenbericht',
    DownloadTitle: 'PDF/Excel herunterladen',
    DownloadPDF: 'PDF herunterladen',
    DownloadExcel: 'Excel herunterladen',
    SelectTypeOfList: 'PDF/Excel herunterladen',
    SelectTypeOfListDescription:
      'Sie können den vollständigen Bericht über die Kampagne in zwei verschiedenen Formen herunterladen und den Listentyp auswählen, den Sie herunterladen möchten.',
    CompaniesInCampaign: 'Unternehmen in dieser Kampagne',
    AssignedCompanies: 'Zugeordnete Unternehmen',
    ActiveCompanies: 'Unternehmen sind nun "aktiv"',
    Pdf: 'PDF',
    PdfDescription: 'Im PDF-Format herunterladen',
    Excel: 'Excel',
    ExcelDescription: 'Im XLS- Format herunterladen',
    DownloadExcelTitle:
      'Sie laden eine Datei mit allen {{companyName}}-Daten und dem Ergebnis dieser Kampagne als Liste herunter.',
    DownloadExcelDescription: 'Es wird im Bereich "Verwendung" Ihres Kontos gespeichert.',
    DownloadPDFPleaseWait: 'Bitte warten Sie. Ihr PDF wird erzeugt.',
    DownloadExcelPendingMessage:
      'Die angeforderte Datei wird verarbeitet und kann einige Minuten dauern.\nSie können den Fortschritt über das Benachrichtigungsmenü verfolgen.'
  },
  Actions: {
    GoToCampaign: 'Kampagne aufrufen'
  }
}
