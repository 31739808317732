import { TFunction } from 'i18next'
import _ from 'lodash'
import { Query } from '../features/query/types'
import { QueryType } from '../types'
import { MAX_QUERY_NAME_LENGTH } from '../features/query/constants'
import { cannotStartWithSpace, noSpecialCharactersSubset, requiredWithoutFieldName } from './validators'

export const filterQueriesByType = (queries: Query[], selectedType: QueryType | undefined) => {
  if (!selectedType) return []
  const selectedTypes = [selectedType]
  if (selectedType === QueryType.ProspectingQueries) {
    // monitored queries are part of prospecting queries
    selectedTypes.push(QueryType.MonitoredQueries)
  }
  return _.filter(queries, q => _.includes(selectedTypes, q.type))
}

export const checkQueryName = (t: TFunction, name: string) => {
  if (requiredWithoutFieldName(name)) {
    return t('error:RequiredWithoutFieldName')
  }

  if (name.length > MAX_QUERY_NAME_LENGTH) {
    return t('error:LengthLong', { max: MAX_QUERY_NAME_LENGTH })
  }

  if (cannotStartWithSpace(name)) {
    return t('error:CannotStartWithSpace')
  }

  if (noSpecialCharactersSubset(name)) {
    return t('error:NoSpecialCharactersWoLabel')
  }

  return undefined
}
