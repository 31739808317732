import React from 'react'
import styled from 'styled-components/macro'
import { DrawerProps } from 'antd3/lib/drawer'
import { Drawer } from 'antd3'
import { media } from 'styled-bootstrap-grid'
import { gridTheme, HEADER_HEIGHT, HEADER_LOGO_WIDTH } from '../../utils/theme'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DrawerStyled = styled(({ backgroundColor, mobileMenu, ...rest }) => <Drawer {...rest} />)`
  &&& .ant-drawer-mask {
    opacity: 0;
    animation: none;
  }

  && .ant-drawer-content-wrapper {
    top: ${HEADER_HEIGHT}px;
    height: auto;
    max-height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: auto;
    width: 320px !important;
  }

  && .ant-drawer-content {
    background-image: linear-gradient(0deg, ${({ theme }) => theme.colors.navy}, ${({ theme }) => theme.colors.main});
    border-radius: 0px 0 3px 3px;
  }

  && .ant-drawer-body {
    padding: 0;
  }

  @media (min-width: ${gridTheme.breakpoints.sm}px) and (max-width: ${gridTheme.breakpoints.md}px) {
    && .ant-drawer-content-wrapper {
      ${({ mobileMenu }) =>
        mobileMenu &&
        `
        width: calc(100vw - ${HEADER_LOGO_WIDTH}px) !important;
        height: calc(100vh - ${HEADER_HEIGHT}px);
      `}
    }
  }

  ${({ mobileMenu }) => media.lg`
    && .ant-drawer-content-wrapper {
      ${mobileMenu && 'display: none'}
    }
  `}
`

type Props = DrawerProps & {
  children: React.ReactNode
  mobileMenu?: boolean
  backgroundColor?: string
}

export const DrawerHeaderContainer = ({ children, mobileMenu, visible, ...rest }: Props) => {
  return (
    <DrawerStyled
      destroyOnClose
      getContainer={false}
      closable={false}
      visible={visible}
      mobileMenu={mobileMenu}
      {...rest}
    >
      {visible && children}
    </DrawerStyled>
  )
}
