import { useGetUserDashboard } from '../../../api/dashboard/queries'
import { getMaxNumberOfWidgets } from '../../../features/config/selectors'
import { useAppSelector } from '../../../hooks/useAppSelector'

export const useCanAddWidget = () => {
  const maxNumberOfWidgets = useAppSelector(getMaxNumberOfWidgets)

  const { data: dashboard, isFetching: isDashboardLoading } = useGetUserDashboard()
  const canAddWidget = (dashboard?.widgets?.length ?? 0) < maxNumberOfWidgets

  return { canAddWidget, isDashboardLoading }
}
