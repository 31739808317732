/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  ChooseNameForCompaniesList: 'Vyberte názov zoznamu spoločností',
  EnterARepresentativeName: 'Zadajte reprezentatívny názov portfólia, ktoré nahrávate.',
  EnterARepresentativeNameCompaniesList: 'Vložte názov zoznamu spoločností, ktorý ukladáte.',
  ItWillIdentifyCompaniesList: 'Názov vám ho pomôže neskôr identifikovať.',
  PortfolioName: 'Názov portfólia',
  InsertNameForPortfolio: 'Zadajte názov svojho portfólia',
  UploadPortfolio: {
    StandardTitle: 'Nahrať portfólio',
    UploadPortfolioOrConnect: 'Nahrajte portfólio alebo pripojte CRM svojej spoločnosti aby ste mohli pokračovať.',
    UploadOrConnect: 'Nahrajte portfólio alebo pripojte svoje obchodné CRM',
    UploadFromPlatform: 'Nahrať z platformy',
    UploadAPortfolio: 'Nahrajte portfólio',
    SelectFromPc: 'Vybrať z PC',
    DownloadTemplateDescription1: 'Stiahnuť šablónu',
    DownloadTemplateDescription2:
      'Zadajte údaje o svojich klientskych spoločnostiach tak, ako je to uvedené v príkladovom hárku, štruktúra tabuliek zachovajte.',
    DownloadTemplateDescription3: 'Nahrajte súbor v ďalšom kroku',
    DownloadTemplate: 'Stiahnuť šablónu',
    ChooseHowToUploadPortfolio: 'Prosím vyberte svoj súbor',

    SelectPortfolioFromPc: 'Vybrať portfólio z vášho počítača',
    YouCanLoad: 'Môžete načítať ďalšie portfólio, kedykoľvek budete chcieť, budú sa načítať oddelene!',
    ToEnsureTheCorrectLoading:
      'Na zaistenie správneho načítania spoločností sa uistite, že ste použili poskytnutú šablónu',
    ChooseHowToUploadForUpdate: 'Vyberte, ako aktualizovať zoznam spoločností',
    SelectCompanyListFromPC: 'Vybrať zoznam spoločností z vášho počítača',
    ToEnsureTheCorrectLoadingForUpdate:
      'Ak chcete zabezpečiť správne načítanie zoznamu spoločností, uistite sa, že ste použili šablónu poskytnutú spoločnosťou Margo. Stiahnite si základný zoznam z funkcie "Stiahnuť portfólio", skopírujte a vložte hodnoty do súboru šablóny, aktualizujte ho a nahrajte vyplnenú šablónu v tejto sekcii.',
    SelectFile: 'Vybrať súbor',
    SelectFileFromComputer: 'Vybrať požadovaný súbor',
    ChoosePortfolioName: 'Zvoliť názov portfólia',
    ItWillDistinguish: 'Pomôže vám ho lepšie odlíšiť od ostatných portfólií!',
    WrapperPage: {
      TypeOfUpload: 'Typ nahrávania',
      TypeOfUploadDescription: 'Vyberte typ nahrávania, ktorý chcete vykonať: štandardné alebo zjednodušené',
      StandardUpload: 'Štandardné nahrávanie',
      StandardUploadTooltip: 'Nahrajte spoločnosti do Margo prostredníctvom preddefinovanej šablóny',
      SimplifiedUpload: 'Zjednodušené nahrávanie',
      SimplifiedUploadTooltip: 'Nahrajte zoznam Identifikačných kódov priamo na web zjednodušeným spôsobom'
    },
    Simplified: {
      Title: 'Zjednodušene nahrať portfólio',
      FirstStepTitle: 'Prilepte alebo zadajte identifikačné kódy',
      UploadWarning: 'UPOZORNENIE: Zatvorením tejto stránky alebo krokom späť bez uloženia sa všetky zmeny stratia',
      UploadPlaceholder: 'Prilepte alebo napíšte platný daňový kód/kód DPH/číslo CRIF oddelené medzerou',
      Description1: 'Prilepte alebo napíšte platný daňový kód/kód DPH/číslo CRIF oddelené medzerou',
      Description2: 'Kliknite na tlačidlo "Pokračovať" pre ďalšie kroky',
      Description3:
        'Možné chyby budú hlásené v "Tabuľke chýb", ktorá je prístupná kliknutím na "Spravovať chybu" v súvisiacom upozornení',
      IdentificationLengthErrorMessage: 'Identifikačný kód musí mať aspoň {{minLength}} znakov.',
      ChooseStatus: {
        Title: 'Vyberte status, ktorý chcete použiť',
        Description1: 'Vyberte počiatočný status spoločností, ktoré chcete nahrať.',
        Description2: 'Rovnaký status spoločnosti bude aplikovaný na celé portfólio.'
      }
    },
    ManageErrors: {
      Title: 'Zobraziť a opraviť chyby',
      Description1: 'Upravte alebo odstráňte nesprávne riadky',
      Description2: 'Kliknutím na tlačidlo "Pokračovať" nahrajte súbor',
      Description3: 'V portfóliu nájdete opravené spoločnosti',
      IdentificationCodeTitle: 'Daňový kód/DIČ/Číslo CRIF',
      CompanyMatchStatusTitle: 'Stav nahrávania',
      StatusDescriptionTitle: 'Popis stavu',
      DownloadList: 'Stiahnutie zoznamu',
      CompanyMatchStatus: {
        Rejected: 'Odmietnuté',
        RejectedDescription: 'Daňový kód/DIČ/Číslo CRIF nie je platné',
        Duplicate: 'Duplikát',
        DuplicateDescription: 'Daňový kód/DIČ/Číslo CRIF je duplikát',
        NotMatched: 'Nenájdené',
        NotMatchedDescription: 'Daňový kód/DIČ/Číslo CRIF je platné, ale v databáze sa nenašla žiadna zhoda',
        Pending: 'Čaká sa na overenie',
        PendingDescription: 'Daňový kód/DIČ/Číslo CRIF sa zmenilo. Kliknutím na tlačidlo Pokračovať ho overte'
      }
    }
  },
  ExcludeCustomVariables: 'Vylúčiť vlastné premenné',
  ExcludeCustomVariablesConfirm:
    'Chcete presúvať spoločnosti bez vlastných premenných? Ak áno, vlastné premenné z pôvodného portfólia budú vymazané.',
  Management: {
    Header: 'Správa portfólia',
    Upload: 'Nahrať portfólio',
    UploadTooltip: 'Nahrajte spoločnosti do Margo prostredníctvom preddefinovanej šablóny',
    UploadTooltipWithSimplified: 'Nahrajte zoznam Identifikačných kódov pomocou štandardnej alebo zjednodušenej metódy',
    ShowCorporate: 'Prezrieť podnikové portfólio',
    SearchPortfolioTooltip: 'Vyhľadať portfolio',
    SearchPortfolioPlaceholder: 'Vyhľadať názov portfolia',
    SearchHeader: 'Správa portfólia - Výsledky vyhľadávania',
    PortfolioTabs: {
      MyPortfolios: 'Moje portfóliá',
      SharedPortfolios: 'Zdieľané portfóliá',
      ReceivedPortfolios: 'Prijaté portfóliá'
    },
    PortfolioName: {
      ChangePortfolioName: 'Zmeniť názov portfólia',
      EnterARepresentativeName: 'Zadajte reprezentatívny názov pre portfólio',
      InsertPortfolioName: 'Vložiť názov portfólia',
      NameChangedCorrectly: 'Názov sa správne zmenil v'
    },
    PortfolioNote: {
      AddNote: 'Pridať poznámku',
      EditNote: 'Upraviť poznámku',
      AddANoteToDescribe: 'Pridanie poznámky k popísaniu portfólia',
      AddNotePlaceholder: 'Pridať poznámku',
      AddNoteFieldLabel: 'Poznámka'
    },
    DeletePortfolio: {
      DeletePortfolio: 'Vymazať portfólio',
      AreYouSure: 'Naozaj chcete vymazať {{name}}?',
      PortfolioDeletedCorrectly: '{{name}} bolo vymazané správne.',
      DataWillRemain: 'Všetky údaje o spoločnosti, ktoré ste stiahli, zostanú vo vašom úložisku.'
    },
    Table: {
      NumberOfCompanies: 'Počet spoločností',
      EnrichedCompanies: 'Obohatené spoločnosti',
      CreationDate: 'Dátum vytvorenia',
      LastUpdateDate: 'Dátum poslednej aktualizácie',
      CampaignsWithThisPortfolio: 'Kampane v portfóliu'
    },
    Menu: {
      ChangeName: 'Zmeniť meno',
      DuplicatePortfolio: 'Duplicitné portfólio',
      DeletePortfolio: 'Vymazať portfólio',
      AddNote: 'Pridať poznámku',
      EditNote: 'Upraviť poznámku'
    },
    Message: {
      Header: 'Kliknutím na "Nahrať portfólio" nahráte svoje prvé portfólio!',
      Description:
        'Tu vidíte zoznam s už uloženými navrhnutými prospektmi. K preskúmaniu a spravovaniu spoločností v zozname kliknite na názov portfólia.'
    },
    UploadComplete: 'Nahrávanie je dokončené!',
    CompaniesUploadedCorrectly: 'Spoločnosti sa nahrali správne',
    CeasedCompaniesNotMatched: 'Neaktívne alebo nezhodujúce sa spoločnosti neboli nahrané',
    ErrorsDueWrongData: 'Chyby z dôvodu nesprávnych údajov',
    Download: 'Stiahnuť',
    ListOfCeasedCompanies: 'Zoznam neaktívnych spoločností',
    ListOfErrors: 'Zoznam chýb',
    Stats: {
      Companies_0: 'Spoločnosť',
      Companies_1: 'Spoločnosti',
      Companies_2: 'Spoločností',
      AllCompanies: 'Všetky spoločnosti',
      EnrichedCompanies_0: 'Obohatená spoločnosť',
      EnrichedCompanies_1: 'Obohatené spoločnosti',
      EnrichedCompanies_2: 'Obohatených spoločností',
      Enriched_0: 'Obohatená',
      Enriched_1: 'Obohatené',
      Enriched_2: 'Obohatených',
      NotEnriched_0: 'Neobohatená',
      NotEnriched_1: 'Neobohatené',
      NotEnriched_2: 'Neobohatených',
      Prospect_0: 'Prospekt',
      Prospect_1: 'Prospekty',
      Prospect_2: 'Prospektov',
      Lead_0: 'Lead',
      Lead_1: 'Leadi',
      Lead_2: 'Leadov',
      Client_0: 'Klient',
      Client_1: 'Klienti',
      Client_2: 'Klientov',
      Supplier_0: 'Dodávateľ',
      Supplier_1: 'Dodávatelia',
      Supplier_2: 'Dodávateľov',
      Competitor_0: 'Konkurent',
      Competitor_1: 'Konkurenti',
      Competitor_2: 'Konkurentov',
      LastUpdate: 'Posledná aktualizácia'
    },
    Enrich: 'Obohatiť',
    EnrichTooltip: 'Vylepšite údaje týchto spoločností',
    Analyze: 'Analyzovať portfólio',
    AnalyzeTooltip: 'Vizualizujte údaje portfólia podľa hlavných premenných',
    Similarity: 'Vygenerovať podobnosť',
    SimilarityTooltip: 'Pre nájdenie nových potenciálnych zákazníkov použite algoritmus podobnosti',
    SelectSource: 'Načítať spoločnosti zo súborov šablón',
    UpdatePortfolio: 'Aktualizovať portfólio'
  },
  Table: {
    companyName: 'Názov spoločnosti',
    lastUpdateDate: 'Posledná aktualizácia',
    companyUnitTypeCode: 'Právna forma spoločnosti',
    companyStatusCode: 'Status',
    employeeNumber: 'Počet zamestnancov',
    companyPortfolioStatus: 'Štát',
    sale: 'Obchodníci',
    Sale: 'Obchodníci',
    '[tags]': 'Značky',
    startDate: 'Dátum začiatku',
    revenue: 'Príjmy',
    turnover: 'Obrat',
    isRating4: 'Hodnotenie',
    Empty: 'Nenašli sme žiadne spoločnosti, ktoré zodpovedajú vášmu vyhľadávaniu',
    isEnriched: 'Je obohatená'
  },
  SelectColumns: 'Vybrať stĺpce',
  LoadMore: 'Načítať viac',
  MapDescription: 'Zobrazenie v mape zobrazuje jednotlivé spoločnosti bez ohľadu na ich status v portfóliu',
  CompanyGroupActions: {
    Title: 'Skupinové akcie',
    Actions: 'Akcie',
    AreaManager: 'Oblastný manažér',
    Sales: 'Predaj',
    Products: 'Produkty',
    Select: 'Vybrať',
    NotAssigned: 'Nepridelený',
    SelectAreaManager: 'Vybrať oblastného manažera',
    SelectSales: 'Vybrať obchodníka',
    SelectProducts: 'Vybrať produkt',
    ActionItems: {
      DuplicateCompany: 'Duplikovať spoločnosť',
      DeleteCompany: 'Vymazať spoločnosť',
      MoveCompany: 'Presuňte spoločnosť do iného portfólia',
      Enrich: 'Obohatiť',
      CreatePortfolio: 'Vytvoriť portfólio',
      DownloadExcel: 'Stiahnuť Excel',
      AddLocalUnits: 'Add Local Units',
      ShareCompanies: 'Zdieľať spoločnosti',
      UnshareCompanies: 'Prestať zdieľať spoločnosti'
    },
    CreatePortfolio: {
      Title: 'Vytvoriť portfólio',
      InputDescription: 'Zadajte názov portfólia, ktoré vytvárate',
      InsertPortfolioName: 'Vložiť názov portfólia'
    },
    HiddenCompanyMessage: 'Operácia sa nevykoná pre spoločnosti patriace do skrytých portfólií.'
  },
  Actions: {
    ViewCampaigns: 'Zobraziť všetky súvisiace kampane',
    LoadQuery: 'Načítať dopyt',
    LoadQueryTooltip: 'Otvorte uložený dotaz',
    SaveQuery: 'Uložiť dopyt',
    SaveQueryTooltip: 'Tento dotaz uložte do modulu Správa portfólia',
    Download: 'Stiahnuť portfólio',
    DownloadTooltip: 'Stiahnite si portfólio vo formáte Excel do svojho zariadenia',
    Analyze: 'Analyzovať portfólio',
    AnalyzeTooltip: 'Vizualizujte údaje portfólia podľa hlavných premenných',
    Similarity: 'Vygenerovať podobnosť',
    SimilarityTooltip: 'Pre nájdenie nových potenciálnych zákazníkov použite algoritmus podobnosti',
    Campaign: 'Vytvoriť kampaň',
    CampaignTooltip: 'Priraďte spoločnosti k predajnému tímu a naplánujte komerčnú kampaň',
    Update: 'Aktualizovať portfólio',
    UpdateTooltip: 'Pridajte do  portfólia nové spoločnosti alebo aktualizujte vlastné premenné',
    LocalUnits: 'Add Local Units',
    LocalUnitsTooltip: 'Pridajte do tohto portfólia pobočky prepojené s centrálou'
  },
  Enriched: 'Obohatené',
  Enrich: 'Obohatiť',
  Monitored: 'Monitorované',
  Close: 'Zavrieť',
  Filters: 'Filtre',
  UserFilters: 'Užívateľské filtre',
  Download: {
    DownloadPortfolio: 'Stiahnuť portfólio',
    SelectTypeOfList: 'Vybrať typ zoznamu',
    SelectTypeOfListDescription: 'Vyberte typ zoznamu, ktorý chcete stiahnuť.',
    DownloadCost: 'Náklady za stiahnutie budú rovnaké pre každý typ zoznamu.',
    Base: 'Základný',
    BaseDescription: 'Zoznam bude obsahovať základný súbor údajov týkajúcich sa spoločností',
    Complete: 'Kompletný',
    CompleteDescription: 'Zoznam bude obsahovať kompletný súbor údajov týkajúcich sa spoločností',
    ExceededMaximumCompanies: 'Prekročili ste maximálny počet spoločností na stiahnutie',
    Marketing: 'Marketing',
    MarketingDescription: 'Zoznam bude obsahovať štandardný súbor údajov týkajúcich sa spoločností',
    Standard: 'Marketing',
    Custom: 'Vlastný',
    CustomDescription:
      'Zoznam si môžete prispôsobiť tak, aby ste si stiahli a/alebo uložili iba súbor údajov, ktoré potrebujete',
    CustomTracks: 'Uložené stopy',
    CustomTracksDefaultSelection: 'Nie je vybratá žiadna vlastná stopa',
    SaveCustomTrack: 'Uložiť vlastnú stopu',
    SaveCustomTrackQuestion: 'Chcete uložiť túto skupinu filtrov ako vlastnú stopu?',
    SaveCustomTrackTitle: 'Uložiť vlastnú stopu',
    SaveCustomTrackDescription:
      'Vložte názov, ktorý chcete použiť na uloženie vlastnej stopy, alebo vyberte existujúci a prepíšte ho.',
    SaveCustomTrackName: 'Vlastný názov stopy',
    SaveCustomTrackUpdateExisting: 'Aktualizovať existujúcu',
    SaveCustomTrackCreateNew: 'Vytvor novú',
    DownloadExample: 'Stiahnuť príklad',
    Download: 'Stiahnuť',
    ForEveryCompanyInList: 'pre každú spoločnosť v zozname',
    ToDownloadYouNeedToPurchase: 'Ak chcete stiahnuť zoznam, musíte si údaje spoločnosti {{companyName}} zakúpiť',
    YouAreDownloading:
      'Sťahujete súbor Excel obsahujúci všetky požadované údaje spoločnosti {{companyName}}. Uloží sa do časti "Správa portfólia" vo vašom účte.',
    CustomExport: {
      StepTitle: 'Vybrať údaje',
      Description1: 'Vyberte údaje, ktoré chcete zahrnúť do svojho sťahovania',
      Description2: 'Náklady za stiahnutie budú rovnaké pre každý typ zoznamu',
      DataPacket: 'Dátový paket',
      DataPacketSearchPlaceholder: 'Nájdite dátové balíky alebo špecifické polia'
    },
    Dialog1: 'Požadovaný súbor sa spracováva a môže to trvať niekoľko minút.',
    Dialog2: 'Progres môžete sledovať v menu notifikácie.',
    SaveSuccess: 'uložená ako vlastná stopa',
    SaveSuccessDescription: 'Stopu nájdete uloženú v úložisku súvisiacom s referenčnou oblasťou.'
  },
  Enrichment: {
    PortfolioEnrichment: 'Obohatenie spoločností. Môže to trvať niekoľko minút. Pokračovať v operácii?',
    Enrichment: 'Obohacovanie',
    EnrichmentComplete: 'Obohatenie ukončené',
    EnrichmentDescription:
      'Obohatením spoločnosti o údaje spoločnosti {{companyName}} sa tieto informácie stiahnu a budú pravidelne aktualizované',
    CompaniesInPortfolio_0: 'Spoločnosť v tomto portfóliu',
    CompaniesInPortfolio_1: 'Spoločnosti v tomto portfóliu',
    CompaniesInPortfolio_2: 'Spoločností v tomto portfóliu',
    CompaniesEnriched_0: 'Spoločnosť, ktorá už bola obohatená',
    CompaniesEnriched_1: 'Spoločnosti, ktoré už boli obohatené',
    CompaniesEnriched_2: 'Spoločností, ktoré už boli obohatené',
    CompaniesNotEnriched_0: 'Spoločnosť neobohatená',
    CompaniesNotEnriched_1: 'Spoločnosti neobohatené',
    CompaniesNotEnriched_2: 'Spoločností neobohatených',
    CreditsWillBeUsed_0: 'Použitá jednotka',
    CreditsWillBeUsed_1: 'Použité jednotky',
    CreditsWillBeUsed_2: 'Použitých jednotiek',
    CreditsRemaining_0: 'Zostávajúca jednotka',
    CreditsRemaining_1: 'Zostávajúce jednotky',
    CreditsRemaining_2: 'Zostávajúcich jednotiek',
    CompaniesSuccesfullyEnriched_0: 'Spoločnosť obohatené',
    CompaniesSuccesfullyEnriched_1: 'Spoločnosti obohatené',
    CompaniesSuccesfullyEnriched_2: 'Spoločností obohatených'
  },
  CompanyDeletion: {
    ConfirmDelete: 'Naozaj chcete vymazať spoločnosť?',
    DeleteSuccess:
      'Spoločnosť bola správne vymazaná. Možno budete musieť počkať niekoľko sekúnd, kým sa zmeny prejavia.'
  },
  CompaniesMove: {
    ConfirmMove_0:
      'Spoločnosť, ktorú ste vybrali, bude odstránená z aktuálneho portfólia a pridaná do iného portfólia. Vyberte si jedno portfólio z nižšie uvedeného zoznamu.',
    ConfirmMove_1:
      'Spoločnosti, ktoré ste vybrali, budú odstránené z aktuálneho portfólia a pridané do iného portfólia. Vyberte si jedno portfólio z nižšie uvedeného zoznamu.',
    ConfirmMove_2:
      'Spoločnosti, ktoré ste vybrali, budú odstránené z aktuálneho portfólia a pridané do iného portfólia. Vyberte si jedno portfólio z nižšie uvedeného zoznamu.',
    MoveSuccess: 'Prebieha operácia presunu, môže to trvať niekoľko minút.',
    SelectPortfolio: 'Vybrať portfólio'
  },
  CompanyAssignment: {
    ConfirmOperation: 'Potvrdiť operáciu',
    ProductAssignment:
      'Priradenie produktu {{product}} vybraným spoločnostiam. Môže to trvať niekoľko minút. Pokračovať v operácii?',
    SalesAssignment:
      'Priradenie obchodníka {{sales}} vybraným spoločnostiam. Môže to trvať niekoľko minút. Pokračovať v operácii?',
    AreaManagerAssignment:
      'Priradenie oblastného manažéra {{manager}} k vybraným spoločnostiam. Môže to trvať niekoľko minút. Pokračovať v operácii?',
    ProductUnassignment:
      'Zrušenie priradenia vybraným spoločnostiam. Môže to trvať niekoľko minút. Pokračovať v operácii?',
    SalesUnassignment:
      'Zrušenie priradenia vybraným spoločnostiam. Môže to trvať niekoľko minút. Pokračovať v operácii?',
    AreaManagerUnassignment:
      'Zrušenie priradenia vybraným spoločnostiam. Môže to trvať niekoľko minút. Pokračovať v operácii?'
  },
  DialogError: 'Pri spracovaní požiadavky sa vyskytla chyba.',
  Credits: 'Rings',
  LoadQuery: {
    DialogTitle: 'Načítať uložené vyhľadávanie',
    SaveBefore: {
      Question: 'Chcete pred načítaním vyhľadávania, uložiť svoje vyhľadávanie?',
      Description: 'Pred načítaním dopytu uložte svoje vyhľadávanie, aby ste predišli strate údajov.',
      OkBtn: 'Uložiť vyhľadávanie',
      CancelBtn: 'Preskočiť uloženie'
    }
  },
  SaveQuery: {
    DialogTitle: 'Uložiť vyhľadávanie',
    Description:
      'Zadajte názov, ktorý chcete použiť na uloženie výskumu, alebo vyberte existujúci dotaz, ktorý chcete prepísať.',
    Fields: {
      QueryName: 'Názov dopytu',
      QueryNamePlaceholder: 'Názov vyhľadávania',
      AddToDashboard: 'Ukázať na dashboarde'
    },
    ConfirmMessage: 'Chcete pridať dopyt na váš dashboard?',
    SavedQuery: 'uložené korektne',
    SavedQueryAndDashboard: 'uložené a pridané do dashboardu',
    SavedQueryDescription: 'Svoje dopyty nájdete v úložisku podľa referenčnej oblasti'
  },
  DomainFilter: {
    SaveAndClose: 'Uložiť filtre a zavrieť'
  },
  ManageDuplicates: {
    Title: 'Spravovať duplikáty',
    Description: 'K duplikovaniu spoločnosti je potrebné zmeniť jej status alebo produkt',
    GroupActions: 'Skupinové akcie',
    Duplicates_0: '{{count}} duplikát',
    Duplicates_1: '{{count}} duplikáty',
    Duplicates_2: '{{count}} duplikátov',
    ShowOK: 'Zobraziť OK',
    HideOK: 'Skryť OK',
    SelectColumns: 'Vybrať stĺpce'
  },
  GenerateSimilarity: {
    Title: 'Vygenerovať podobnosť: vyberte reprezentatívny status',
    Description: 'Pre začatie analýzy musíte zvoliť typ statusu, ktorý chcete spracovať',
    DropdownPlaceholder: 'Vybrať status',
    GotoActionButton: 'Vybrať'
  },
  Campaign: {
    YouNeedToEnrich: 'Na spustenie kampane je potrebné obohatiť všetky spoločnosti v portfóliu.'
  },
  CreatingPortfolio: 'Vytváranie portfólia',
  UpdatingPortfolio: 'Aktualizácia portfólia',
  Share: {
    SharePortfolioTitle: 'Zdieľať portfólio',
    Subtitle: 'Vybrať užívateľov',
    Description: 'Vyberte užívateľov, s ktorými chcete zdieľať portfólio',
    Success: 'Aktualizácia zdieľania bola vykonaná správne.',
    ShareCompany: 'Zdielať spoločnosť',
    DeleteSharing: 'Odstrániť zdieľanie',
    SharedCompanies: 'Zdieľané spoločnosti',
    SharedPortfolio: 'Zdieľané portfólio',
    SharePortfolio: 'Zdieľanie portfólia',
    ShareCompanies: {
      Title: 'Zdieľať spoločnosti',
      Subtitle: 'Vybrať užívateľov',
      Description: 'Vyberte užívateľov, s ktorými chcete zdieľať spoločnosti',
      WithAreaManager: 'Zdieľať spoločnosti s oblastným manažérom',
      WithSales: 'Zdieľať spoločností s obchodníkom',
      ConfirmTitle: 'Potvrdiť operáciu',
      ConfirmSubtitle: 'Aktualizovanie zdieľania s vybraným užívateľom.',
      ConfirmDescription: 'Môže to trvať niekoľko minút. Pokračovať v operácii?',
      Check: 'Kontrola, či sú všetky spoločnosti platné pre zdieľanie.',
      CheckFail: 'Musíte priradiť vybrané spoločnosti.'
    },
    UnshareCompanies: {
      Title: 'Prestať zdieľať spoločnosti',
      Subtitle: 'Vybrať užívateľov',
      Description: 'Vyberte užívateľov, s ktorými chcete ukončiť zdieľanie',
      WithAreaManager: 'Prestať zdieľať spoločnosti s oblastným manažérom',
      WithSales: 'Prestať zdieľať spoločnosti s obchodníkom',
      ConfirmTitle: 'Potvrdiť operáciu',
      ConfirmSubtitle: 'Aktualizuje sa ukončenie zdieľania s vybraným užívateľom',
      ConfirmDescription: 'Môže to trvať niekoľko minút. Pokračovať v operácii?',
      Check: 'Kontrola, či sú všetky spoločnosti platné pre ukončenie zdieľania.',
      CheckFail: 'Musíte priradiť vybrané spoločnosti.'
    }
  },
  Exclude: {
    Title: 'Vylúčiť portfólio',
    ExcludedPortfolio: 'Skryté portfólio',
    Description: 'Vyberte používateľov, ktorí dostanú vybrané portfólio vo vylúčení',
    Success: 'Zdieľanie vylúčení prebehlo správne.',
    Error: 'Je nám ľúto, ale toto portfólio sme nemohli zdieľať s vylúčením.'
  },
  AddLocalUnits: {
    Title: 'Add Local Units',
    TooManyCompanies: 'Resize the portfolio to {{maxCompanies}} companies maximum in order to use this functionality.',
    NoCompanySelected: 'No company selected. Please add at least one Headquarter.',
    NoHeadquarter: 'The selected portfolio already contains Local Units. Please add at least one Headquarter.',
    NoHeadquarterSelected: 'The selected companies are already Local Units. Please add at least one Headquarter.',
    Enrich: {
      Title: 'To add local units you need to purchase {{companyName}} company data',
      Description:
        'You are adding local units to a portfolio containing all the {{companyName}} data requested. It will be saved in the "Use" section of your account.'
    },
    ChooseBranch: {
      Title: 'Choose the branch you want to add.',
      Description: 'Select the branch type to be added.',
      All: 'All the Branch types',
      DeleteAll: 'Delete all'
    },
    ChooseStatus: {
      Title: 'Choose the status you want to add.',
      Description: 'Select the initial status of the companies to be added.'
    },
    CreateTag: {
      Title: 'Create tag.',
      Description: 'Each company will be defined by a tag that you create.',
      Label: 'Create tag (optional)',
      Placeholder: 'Insert tag'
    }
  },
  EditPortfolioCompany: {
    Title: 'Upraviť hodnoty spoločnosti',
    Subtitle: 'Zmeniť hodnoty',
    Description:
      'Vyberte polia, ktoré chcete upraviť, a vložte novú hodnotu do poľa. V prípade duplicitných spoločností sa stav spoločnosti a produkt neaktualizujú.',
    CompanyStatus: 'Subjekt v portofliu',
    Amount: 'Množstvo',
    AreaManager: 'Area Manager',
    Sales: 'Obchodník',
    Product: 'Produkt',
    Tags: 'Značky',
    Save: 'Uložiť',
    Error: 'Chyba',
    ErrorDescription: 'Nepodarilo sa načítať údaje. Skúste neskôr prosím.',
    SaveSuccessTitle: 'Zmeny boli uložené',
    SaveSuccessText: 'Zmeny boli uložené správne.',
    SaveErrorTitle: 'Chyba',
    SaveErrorText: 'Pri ukladaní zmien sa vyskytla chyba. Skúste neskôr prosím.'
  }
}
