import _ from 'lodash'
import React from 'react'
import { DatePicker as AntDatePicker } from 'antd3'
import styled, { css } from 'styled-components/macro'
import { DatePickerProps } from 'antd3/lib/date-picker/interface'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'

export const DatePickerStyled = styled(AntDatePicker)`
  border-color: ${({ theme }) => theme.colors.paleGrey};
  background-color: ${({ theme }) => theme.colors.paleGrey};
  color: ${({ theme }) => theme.colors.brownGray};

  .ant-input.ant-calendar-picker-input {
    border: none;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.paleGrey};

    :focus {
      border: none;
      box-shadow: none;
    }
  }

  .ant-calendar-picker-clear {
    color: ${({ theme }) => theme.colors.main};
    background-color: transparent;
    :hover {
      color: ${({ theme }) => theme.colors.mainPressed};
    }
  }

  .ant-calendar-picker-icon {
    right: 6px;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    cursor: pointer;
    opacity: 1;
    transition: all ${({ theme }) => theme.animation.duration};
  }

  .ant-calendar-picker-input[value=''] + .ant-calendar-picker-icon {
    opacity: 1;
  }

  ${({ allowClear }) =>
    allowClear !== false &&
    css`
      &:hover .ant-calendar-picker-icon {
        opacity: 0;
      }
    `}

  .ant-calendar-input-wrap {
    height: 40px;
  }
  .ant-calendar-input {
    font-family: Lato;
    font-size: 14px;
    line-height: 1.71;
    padding-top: 4px;
  }
`

export const createDisabledTime = (
  actualDate: moment.Moment | null | undefined,
  compareDate: moment.Moment | undefined,
  disableGreater: boolean
): {
  disabledHours?: () => number[]
  disabledMinutes?: () => number[]
} => {
  if (!_.isNil(actualDate) && compareDate && actualDate.isSame(compareDate, 'day')) {
    const minRangeHour = disableGreater ? compareDate.hour() + 1 : 0
    const maxRangeHour = disableGreater ? 24 : compareDate.hour()

    const minRangeMinutes = disableGreater ? compareDate.minute() + 1 : 0
    const maxRangeMinutes = disableGreater ? 60 : compareDate.minute()

    return {
      disabledHours: () => _.range(minRangeHour, maxRangeHour),
      disabledMinutes: () => (actualDate.isSame(compareDate, 'hour') ? _.range(minRangeMinutes, maxRangeMinutes) : [])
    }
  }
  return {}
}

const getDefaultCalendarContainer = (trigger: Element) => trigger.parentNode as HTMLElement

// eslint-disable-next-line react/display-name
export const DatePicker = React.forwardRef<any, DatePickerProps>(
  ({ suffixIcon = <CalendarIcon />, getCalendarContainer = getDefaultCalendarContainer, ...props }, ref) => {
    return <DatePickerStyled getCalendarContainer={getCalendarContainer} suffixIcon={suffixIcon} {...props} ref={ref} />
  }
)

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`
