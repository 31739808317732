import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LoadQueryDialog } from '../../../containers/Query/LoadQueryDialog'
import { DialogActions } from '../../../features/dialog/actions'
import { Query } from '../../../features/query/types'
import { QueryType } from '../../../types'
import { SaveBeforeLoadDialog } from './SaveBeforeLoadDialog'
import { LoadQueryDialogData } from './types'

type Props = {
  data: LoadQueryDialogData
  close: () => void
}

export const LoadQueryDialogContainer: React.FC<Props> = ({
  data: { canSaveQuery, portfolioId, contextData, setQuerySaved },
  close
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [saveSkipped, setSaveSkipped] = React.useState(false)

  const submit = (query?: Query, queryType?: QueryType) => {
    if (!query?.id || !queryType) return
    setQuerySaved(true)
    navigate(
      { pathname: window.location.pathname },
      {
        replace: true,
        state: {
          query: { id: query.id }
        }
      }
    )
    close()
  }

  if (!saveSkipped && canSaveQuery) {
    return (
      <SaveBeforeLoadDialog
        saveQuery={() => {
          close()
          dispatch(DialogActions.openSavePortfolioQueryDialog({ portfolioId, contextData, setQuerySaved }))
        }}
        skipSaveQuery={() => setSaveSkipped(true)}
        close={close}
      />
    )
  }
  return <LoadQueryDialog submit={submit} close={close} defaultQueryType={QueryType.PortfolioQueries} />
}
