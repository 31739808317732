import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addQueryAsExpression, updateQueryAsExpression, getQueriesKeys } from '../../../api/query'
import { ConfirmSaveQueryDialog } from '../../../containers/SaveQuery/ConfirmSaveQueryDialog'
import { ErrorResponse, getFieldError, parseErrors } from '../../../features/form/utils'
import { CompoundExpression } from '../../../features/operations/types'
import { FETCH_QUERIES_TOP } from '../../../features/query/constants'
import { QueryType } from '../../../types'
import { SaveQueryDialog } from './SaveQueryDialog'
import { SubmitMethod } from './types'

type Props = {
  data: {
    query: CompoundExpression
    setQuerySaved: () => void
  }
  close: () => void
}

export const SaveQueryDialogContainer: React.FC<Props> = ({ data, close }) => {
  const { t } = useTranslation(['operations', 'error', 'common'])
  const client = useQueryClient()
  const { query, setQuerySaved } = data

  const [queryName, setQueryName] = useState('')
  const [queryId, setQueryId] = useState<string>()
  const [nameError, setNameError] = useState<string>()
  const [updateError, setUpdateError] = useState<string>()
  const [currentSaveMethod, setCurrentSaveMethod] = useState(SubmitMethod.CreateNew)

  const saveQuery = useMutation<{}, AxiosError<ErrorResponse, any>>(
    () => {
      if (currentSaveMethod === SubmitMethod.CreateNew) {
        if (!query) return Promise.reject()
        return addQueryAsExpression({
          query,
          queryName,
          type: QueryType.OperationQueries,
          addToDashboard: false,
          visibleFilters: []
        })
      }
      if (!queryId || !query) return Promise.reject()
      return updateQueryAsExpression(queryId, {
        query,
        visibleFilters: []
      })
    },
    {
      onSuccess: () => {
        if (currentSaveMethod === SubmitMethod.Update) setUpdateError(undefined)
        else setNameError(undefined)

        client.invalidateQueries(
          getQueriesKeys.GetFilterQueriesAsDocument({
            documentTypes: [QueryType.Queries, QueryType.OperationQueries],
            top: FETCH_QUERIES_TOP
          })
        )
        setQuerySaved()
      },
      onError: e => {
        if (currentSaveMethod === SubmitMethod.Update) {
          setUpdateError('error')
          return
        }

        if (e?.response?.status === 409) {
          setNameError(t('error:UniqueName'))
          return
        }
        const queryNameErr = parseErrors(e).name
        const label = t('operations:SaveQuery.InputLabel')

        const error = getFieldError(queryNameErr, label, t)
        setNameError(error ?? 'error')
      }
    }
  )

  if (saveQuery.isSuccess) {
    return <ConfirmSaveQueryDialog queryName={queryName} close={close} t={t} />
  }

  return (
    <SaveQueryDialog
      submitting={saveQuery.isLoading}
      queryName={queryName}
      queryNameError={currentSaveMethod === SubmitMethod.CreateNew ? nameError : updateError}
      setQueryName={setQueryName}
      setCurrentSaveMethod={setCurrentSaveMethod}
      currentSaveMethod={currentSaveMethod}
      queryId={queryId}
      setQueryId={setQueryId}
      close={close}
      submit={saveQuery.mutate}
      setNameError={setNameError}
    />
  )
}
