import { AxiosError } from 'axios'
import _ from 'lodash'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  CompanyEnrichProposal,
  EnrichCompaniesDialogData,
  EnrichPortfolioSelectionParams,
  EnrichSource
} from '../../containers/Dialog/EnrichCompanies/types'
import { getCompaniesUnitsEnrichmentProposal, getEnrichmentProposal, getPortfolioEnrichmentProposal } from './api'
import {
  CompanyEnrichmentProposalRequest,
  CompanyUnitEnrichmentProposalResponse,
  PortfolioEnrichmentProposalRequest,
  PortfolioEnrichmentProposalResponse
} from './types'
import { WithErrorCode } from '../common/types'

export const getEnrichmentKeys = {
  All: () => [{ level1: 'enrichment' }] as const,
  EnrichmentProposal: () => [{ ...getEnrichmentKeys.All()[0], level2: 'enrichmentProposal' }] as const,
  EnrichmentProposalWithRequest: (request: CompanyEnrichmentProposalRequest) =>
    [{ ...getEnrichmentKeys.EnrichmentProposal()[0], request }] as const,
  CompaniesEnrichmentProposalWithRequest: (request: EnrichCompaniesDialogData) =>
    [{ ...getEnrichmentKeys.EnrichmentProposal()[0], request }] as const,
  PortfolioEnrichmentProposal: (portfolioId: string, data: PortfolioEnrichmentProposalRequest) =>
    [{ ...getEnrichmentKeys.EnrichmentProposal()[0], portfolioId, data }] as const
}

export const useGetEnrichmentProposal = (request: CompanyEnrichmentProposalRequest) => {
  return useQuery(getEnrichmentKeys.EnrichmentProposalWithRequest(request), ({ queryKey: [queryKeys] }) =>
    getEnrichmentProposal(queryKeys.request)
  )
}

const getEnrichmentDataBasedOnSource = (requestData: EnrichCompaniesDialogData) => {
  if (
    requestData.source === EnrichSource.Search ||
    requestData.source === EnrichSource.PortfolioDetail ||
    requestData.source === EnrichSource.PortfolioDetailMap ||
    requestData.source === EnrichSource.Prospecting ||
    requestData.source === EnrichSource.ProspectingMap ||
    requestData.source === EnrichSource.Report ||
    requestData.source === EnrichSource.Operations ||
    requestData.source === EnrichSource.ReportPopup
  )
    return getCompaniesUnitsEnrichmentProposal([requestData.params.companyUnitId])

  if (requestData.source === EnrichSource.PortfolioDetailGroupAction) {
    const {
      portfolioId,
      isSelectAll,
      companyUnitsToEnrich,
      companyUnitsToIgnore,
      semantic,
      expression,
      excludedPortfoliosIds
    } = requestData.params as EnrichPortfolioSelectionParams
    return getPortfolioEnrichmentProposal(portfolioId, {
      isSelectAll,
      semantic,
      expression,
      excludedPortfoliosIds,
      companyUnitIds: !isSelectAll ? _.map(companyUnitsToEnrich, 'companyUnitId') : undefined,
      ignoredCompanyUnitIdsWhenIsSelectAll: isSelectAll ? _.map(companyUnitsToIgnore, 'companyUnitId') : undefined
    })
  }

  throw new Error('Unexpected source for getEnrichmentDataBasedOnSource request')
}

export const useGetRecapData = (
  request: EnrichCompaniesDialogData,
  options?: UseQueryOptions<
    PortfolioEnrichmentProposalResponse | CompanyUnitEnrichmentProposalResponse,
    AxiosError<WithErrorCode>,
    CompanyEnrichProposal,
    ReturnType<typeof getEnrichmentKeys.CompaniesEnrichmentProposalWithRequest>
  >
) =>
  useQuery(
    getEnrichmentKeys.CompaniesEnrichmentProposalWithRequest(request),
    ({ queryKey: [queryKeys] }) => {
      return getEnrichmentDataBasedOnSource(queryKeys.request).then(r => r.data)
    },
    options
  )

export const useGetPortfolioEnrichmentProposal = (
  portfolioId: string,
  data: PortfolioEnrichmentProposalRequest,
  options?: UseQueryOptions<
    PortfolioEnrichmentProposalResponse,
    AxiosError,
    PortfolioEnrichmentProposalResponse,
    ReturnType<typeof getEnrichmentKeys.PortfolioEnrichmentProposal>
  >
) =>
  useQuery(
    getEnrichmentKeys.PortfolioEnrichmentProposal(portfolioId, data),
    ({ queryKey: [queryKeys] }) =>
      getPortfolioEnrichmentProposal(queryKeys.portfolioId, queryKeys.data).then(r => r.data),
    options
  )
