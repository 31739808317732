// eslint-disable-next-line max-classes-per-file
import React from 'react'
import _ from 'lodash'
import { Sticky } from 'react-sticky'
import { HEADER_HEIGHT } from '../../utils/theme'
import { ViewportDimensions } from '../../types'
import { FullPageDialog } from '../Dialog'
import { withViewportSize } from '../App/ViewportSizeContext'
import { FullPageDrawer } from './FullPageDrawer'
import { Drawer as DrawerBase, DrawerProps } from './Drawer'
import { RightDrawer } from './RightDrawer'
import { DrawerHolder } from './DrawerHolder'
import { GAP, OFFSET } from './common'

// react-sticky requires children of Sticky to be a class component
class Drawer extends React.PureComponent<DrawerProps> {
  render() {
    return <DrawerBase {...this.props} />
  }
}

export const DrawerContext = React.createContext({ height: 0 })

const POINT = 148

type Props = {
  buttonOffset?: number
  open: boolean
  fullPageDrawerTitle: string
  extra?: React.ReactNode
  drawerContent: React.ReactNode
  children: React.ReactNode
  onToggle: (isOpen: boolean) => void
  rightOpen?: boolean
  rightDrawerContent?: React.ReactNode
  onRightOverlayClick?: () => void
} & ViewportDimensions

type State = {
  height: number
}

class DrawerContainerComponent extends React.PureComponent<Props, State> {
  state: State = { height: 0 }

  placeholderRef = React.createRef<HTMLDivElement>()

  footer = {} as HTMLElement

  componentDidMount() {
    this.footer = document.getElementById('footer')!
    this.calcHeight()
  }

  componentDidUpdate() {
    this.calcHeight()
  }

  calcHeight = () => {
    setTimeout(() => {
      if (!this.placeholderRef.current) return
      const { viewportHeight } = this.props
      const { top: footerTop } = this.footer.getBoundingClientRect()!

      if (!this.placeholderRef.current) return

      const placeholder = this.placeholderRef.current!.getBoundingClientRect()!
      const placeholderTop = _.max([placeholder.top, HEADER_HEIGHT + GAP])!
      const h1 = footerTop - placeholderTop - 2 * GAP
      const h2 = viewportHeight - HEADER_HEIGHT - 2 * GAP
      const height = _.min([h1, h2])!
      this.setState({ height })
    }, 10)
  }

  render() {
    const {
      buttonOffset,
      open,
      onToggle,
      drawerContent,
      rightDrawerContent,
      rightOpen,
      children,
      onRightOverlayClick,
      isTablet,
      fullPageDrawerTitle,
      extra
    } = this.props
    const { height } = this.state

    let leftDrawer = null
    if (isTablet) {
      leftDrawer = (
        <FullPageDrawer
          open={open}
          fullPageDrawerTitle={fullPageDrawerTitle}
          drawerContent={drawerContent}
          onToggle={onToggle}
          extra={extra}
        />
      )
    } else {
      leftDrawer = (
        <Sticky topOffset={OFFSET} bottomOffset={GAP}>
          {({ style, isSticky, distanceFromBottom }) => {
            let top = style.top as number
            if (isSticky) {
              top = OFFSET
              if (distanceFromBottom < POINT) {
                top = OFFSET - (POINT - distanceFromBottom)
              }
            } else {
              this.calcHeight()
            }
            return (
              <Drawer
                style={{ ..._.omit(style, 'width'), top, transform: style.transform || 'translateZ(0px)' }}
                open={open}
                height={height}
                buttonOffset={buttonOffset || 0}
                extra={extra}
                drawerContent={drawerContent}
                onToggle={onToggle}
              />
            )
          }}
        </Sticky>
      )
    }

    let rightDrawer = null
    if (rightDrawerContent) {
      rightDrawer = isTablet ? (
        <FullPageDialog visible={!!rightOpen}>{rightDrawerContent}</FullPageDialog>
      ) : (
        <RightDrawer
          open={!!rightOpen}
          height={height}
          drawerContent={rightDrawerContent}
          onRightOverlayClick={onRightOverlayClick}
        />
      )
    }

    return (
      <DrawerContext.Provider value={{ height }}>
        <DrawerHolder
          ref={this.placeholderRef}
          open={open}
          leftDrawer={leftDrawer}
          rightDrawer={rightDrawer}
          onToggle={onToggle}
        >
          {children}
        </DrawerHolder>
      </DrawerContext.Provider>
    )
  }
}

export const DrawerContainer = withViewportSize(DrawerContainerComponent)
