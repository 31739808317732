import React, { ReactNode } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { ReactComponent as InfoIcon } from '../../assets/icons/icon-s-info-full-white.svg'

type IconAlignment = 'topLeft' | 'topRight' | 'leftCenter' | 'rightCenter'

type Props = {
  className?: string
  alignIcon: IconAlignment
  children?: ReactNode
}

const InfoBannerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 54px;
  padding: 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pumpkinOrange};
  box-shadow: 0 4px 8px 0 rgba(255, 117, 0, 0.2);

  .info-icon {
    position: absolute;
    fill: ${({ theme }) => theme.colors.pumpkinOrange};

    &.top {
      top: 16px;
    }
    &.left {
      left: 16px;
    }
    &.right {
      right: 16px;
    }
    &.left,
    &.right {
      &.center {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`

export const InfoBanner = ({ className, alignIcon, children }: Props) => {
  const classes = _.join(_.map(_.words(alignIcon), _.toLower), ' ')
  return (
    <InfoBannerWrapper className={className}>
      {children}
      <InfoIcon className={`info-icon ${classes}`} role="img" />
    </InfoBannerWrapper>
  )
}
