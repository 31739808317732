import axios, { CancelToken } from 'axios'
import { ApplicationModule } from '../../types'
import {
  DownloadProposalRequest,
  DownloadProposal,
  ExportStructureResponse,
  BaseCustomTrack,
  CustomTrack,
  NewCustomTrack,
  DeleteCustomTracksRequest,
  DownloadPortfolioSelectionRequest,
  GetCustomTracksRequest
} from './types'

export const getDownloadProposal = (
  portfolioId: string,
  request: DownloadProposalRequest,
  cancelToken: CancelToken | undefined = undefined
) =>
  axios
    .post<DownloadProposal>(`/api/portfolio/${portfolioId}/download-proposal`, request, {
      cancelToken
    })
    .then(resp => resp.data)

export const getExportStructure = (module: ApplicationModule) =>
  axios.get<ExportStructureResponse>(`api/common/export-structure?module=${module}`).then(resp => resp.data)

export const getCustomTracksList = (params?: GetCustomTracksRequest, cancelToken?: CancelToken) =>
  axios.get<BaseCustomTrack[]>('/api/CustomTracks', { cancelToken, params }).then(resp => resp.data)

export const getCustomTrack = (customTrackId: string, cancelToken?: CancelToken) =>
  axios.get<CustomTrack | undefined>(`/api/CustomTracks/${customTrackId}`, { cancelToken }).then(resp => resp.data)

export const editCustomTrackDataPackets = (customTrackId: string, dataPackets: string[], jsonPaths: string[]) =>
  axios.put(`/api/CustomTracks/${customTrackId}/data-packets`, { dataPackets, jsonPaths }).then(resp => resp.data)

export const renameCustomTrack = (customTrackId: string, name: string) =>
  axios.put(`/api/CustomTracks/${customTrackId}/name`, { name }).then(resp => resp.data)

export const deleteCustomTrack = (data: DeleteCustomTracksRequest) =>
  axios.delete('/api/CustomTracks', { data }).then(resp => resp.data)

export const validateCustomTrackName = (request: NewCustomTrack, cancelToken?: CancelToken) =>
  axios
    .post<CustomTrack | undefined>('/api/CustomTracks/validate/name', request, {
      cancelToken,
      allowedErrorStatuses: [400, 409]
    })
    .then(resp => resp.data)

export const createCustomTrack = (request: NewCustomTrack, cancelToken?: CancelToken) =>
  axios
    .post<CustomTrack | undefined>('/api/CustomTracks', request, {
      cancelToken
    })
    .then(resp => resp.data)

export const updateCustomTrack = (request: NewCustomTrack, cancelToken?: CancelToken) => {
  return axios
    .put<CustomTrack | undefined>(`/api/CustomTracks/${request?.id}/data-packets`, request, {
      cancelToken
    })
    .then(res => res.data)
}

export const shareCustomTrack = (id: string, isShared: boolean, cancelToken?: CancelToken) => {
  return axios.put<undefined>(
    `/api/CustomTracks/${id}/share`,
    {
      isShared
    },
    {
      cancelToken
    }
  )
}

export const downloadPortfolioSelection = (data: DownloadPortfolioSelectionRequest) =>
  axios.post('/api/PortfolioActions/download-portfolio-selection', data)
