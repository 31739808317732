import _ from 'lodash'
import { CompanyData } from '../../api/companyPopup/types'
import { PortfolioCompany } from '../../features/portfolioCompany/types'

export const mapCompanyDataToPortfolioCompany = (data: CompanyData): PortfolioCompany => {
  const { company } = data

  return {
    companyUnitId: company.entityId,
    margoId: '',
    entityId: company.entityId,
    // TODO remove fields and swap to cells because it is obsolete
    fields: !_.isNil(company.cells)
      ? _.reduce(
          company.cells,
          (acc: Record<string, any>, cell) => {
            acc[cell.fieldId] = cell.text ?? cell.value
            return acc
          },
          {}
        )
      : company.cells,
    cells: !_.isNil(company.cells)
      ? _.reduce(
          company.cells,
          (acc: Record<string, any>, cell) => {
            acc[cell.fieldId] = cell.text ?? cell.value
            return acc
          },
          {}
        )
      : company.cells,
    isEnriched: company.isEnriched,
    name: company.companyName,
    identificationCode: company.identificationCode,
    portfolioId: ''
  }
}
