import { atom } from 'jotai'
import _ from 'lodash'

export type ChartDataAtom = {
  hasData: boolean
  loaded: boolean
}

type WithChartCode = {
  chartCode: string
}

export const chartsStatusAtom = atom<Record<string, ChartDataAtom>>({})
export const allChartsLoadedAtom = atom(false)
export const allChartsEmptyAtom = atom(true)

export const updateChartsStatusAtom = atom<any, ChartDataAtom & WithChartCode>(
  null,
  (get, set, { chartCode, loaded, hasData }) => {
    const newStatuses = { ...get(chartsStatusAtom), [chartCode]: { loaded, hasData } }
    if (loaded === false) {
      set(chartsStatusAtom, newStatuses)
      return
    }

    const allLoaded = _.every(newStatuses, { loaded: true })
    const allEmpty = _.every(newStatuses, { hasData: false })

    set(allChartsLoadedAtom, allLoaded)
    set(allChartsEmptyAtom, allEmpty)
    set(chartsStatusAtom, newStatuses)
  }
)

export const lastScrollPositionAtom = atom(0)
export const activeChartModalAtom = atom<string | null>(null)
