import { SelectionUnit } from '../../../api/enrichment/types'
import { BasePortfolioQueryDialogData } from '../types'

export enum AssignmentType {
  AssignAreaManager = 'AssignAreaManager',
  AssignSales = 'AssignSales',
  AssignProduct = 'AssignProduct'
}

export type AssignCompaniesDialogData = {
  isSelectAll: boolean
  selectedCompanies: SelectionUnit[]
  deSelectedCompanies: SelectionUnit[]

  entityId: string | undefined // managerId | salesId | productId, depending on assignmentType
  displayName: string
  portfolioId: string

  assignmentType: AssignmentType
} & BasePortfolioQueryDialogData
