/* eslint-disable import/no-default-export */
export default {
  ExcelFileType: 'Fichier sélectionné non valable. Charger un fichier Excel (extension .xls ou .xlsx).',
  FileNotEmpty: 'Le fichier ne peut pas être vide.',
  Forbidden: 'Interdiction',
  ExcludeCustomVariablesError:
    "La structure des variables personnalisables de ce portefeuille est incompatible avec celle d'origine.",
  Length: 'Le « {{label}} » comprend un minimum de {{min}} lettres et un maximum de {{max}} lettres',
  LengthLong: 'Le nombre maxi de caractères est {{max}}',
  LengthShort: 'Le nombre mini de caractères est {{min}}',
  MissingClaims: 'Réclamations omises : {{claims}}.',
  NoSpecialCharacters: 'Le « {{label}} » ne peut pas contenir de caractères spéciaux',
  NoSpecialCharactersWoLabel: 'Le champ ne peut pas contenir de caractères spéciaux',
  MaxTagsCount: "Nombre Maximum d'étiquettes fournies : 20",
  NotEmpty: 'Ce champ ne peut rester vide.',
  CannotStartWithSpace: 'Le nom ne peut pas commencer par un espace',
  PortfolioCreationError:
    'Avertissement ! Erreur de création du portefeuille. Contrôler le fichier sélectionné et essayer à nouveau de le charger.',
  PortfolioCreationFromListError:
    'Avertissement! Erreur de création de portefeuille. Insérez la liste des identifiants et réessayez de télécharger.',
  PortfolioImportCompaniesLessThen:
    'Le modèle a dépassé le nombre maximum de {{maxCompaniesCount}} lignes, contrôler et ressayer.',
  PortfolioImportCompaniesNotEmpty: "Fichier chargé non valable. S'assurer qu'il y au moins une société.",
  PortfolioImportHasCustomVariableSheet: 'Feuille des variables personnalisables absente du fichier chargé.',
  PortfolioImportCustomVariableNames:
    "Les variables personnalisables du fichier chargé doivent contenir des noms d'étiquette uniques.",
  PortfolioImportValidStructure:
    "Fichier chargé non valable. S'assurer que la structure du modèle utilisé est la même que celle proposée.",
  Required: 'Le champ « {{label}} » est obligatoire',
  RequiredWithoutFieldName: 'Le champ est obligatoire',
  ServerError: 'Erreur du serveur',
  MinimumLength8Characters:
    'La longueur minimale du mot de passe est de 8 caractères et la longueur maximale est de 25 caractères',
  MustContainLetter: 'Le mot de passe doit contenir au moins une lettre et un chiffre',
  MustContainNumber: 'Le mot de passe doit contenir au moins une lettre et un chiffre',
  CannotContainUsername:
    "Le mot de passe ne peut pas être le même que l'ancien mot de passe et il ne peut pas contenir le nom d'utilisateur",
  CannotBeSameAsOldPassword: "Le mot de passe ne peut pas être identique à l'ancien mot de passe",
  MustContainCapitalLetter: 'Le mot de passe doit avoir au moins une majuscule',
  CannotContainMoreThan2EqualCharactersInRow:
    'Le mot de passe ne peut pas contenir les trois caractères égaux consécutifs',
  CannotContainMoreThan3EqualCharactersInRow:
    'Le mot de passe ne peut pas contenir les trois caractères égaux consécutifs',
  MaximumLength25Characters:
    'La longueur minimale du mot de passe est de 8 caractères et la longueur maximale est de 25 caractères',
  UniqueName: 'Nom existant. Spécifier un nom différent.',
  Warning: 'Avertissement',
  ForbiddenText: "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
  PortfolioDownloadError: 'Avertissement ! Erreur de téléchargement du portefeuille. Réessayer.',
  PortfolioUpdateError:
    "Avertissement ! Erreur de mise à jour du portefeuille. Contrôler le fichier sélectionné et essayer à nouveau de l'actualiser.",
  PortfolioLockErrorTitle: 'Confirmer opération',
  PortfolioLockError: "Impossible de terminer l'opération car une autre action est en cours sur le portefeuille.",
  PdfReportLockErrorTitle: 'Confirmer opération',
  PdfReportLockError: "Création du PDF en cours pour la société {{companyName}}, attendre la fin de l'opération.",
  CouldNotValidatePortfolioName: 'Erreur du serveur. Impossible de valider le nom du portefeuille.',
  MinMax: 'La valeur doit être comprise entre {{min}} et {{max}}',
  OnlyIntegers: 'Seuls les entiers sont autorisés.',
  ProspectingSaveListError:
    "Avertissement ! Erreur d'enregistrement de la liste. Contrôler les entrées sélectionnées et essayer à nouveau.",
  NotEmail: "Le format de l'e-mail du champ « {{label}} » n'est pas valable.",
  InvalidUrl: "Le format de l'URL du champ « {{label}} » n'est pas valable.",
  InvalidPhone: "Le format du téléphone du champ « {{label}} » n'est pas valable.",
  MustAgreeWithPrivacyPolicy: 'Accepter la politique de confidentialité.',
  ContactError: 'Désolé, nous ne pouvons pas traiter votre message.',
  ResetPasswordError: 'Désolé, nous ne pouvons pas réinitialiser votre mot de passe.',
  DuplicatesNotAllowed: 'Doublons non autorisés.',
  CreateGoal: 'Désolé, nous ne pouvons pas créer votre objectif.',
  SharePortfolio: 'Désolé, nous ne pouvons pas partager le portefeuille.',
  OnlyNumbersOrSpace: 'Le {{label}} ne peut contenir que des chiffres ou des espaces',
  CampaignNameAlreadyExist: 'Nom de la campagne existante. Veuillez choisir un nom différent',
  CampaignTooShort: 'Le nom de la campagne doit comprendre entre 5 et 35 lettres',
  CampaignTooLong: 'Le nom de la campagne doit comprendre entre 5 et 35 lettres',
  CampaignEmpty: 'Nom de la campagne obligatoire',
  CampaignInvalidName: 'Le nom de la campagne ne peut pas contenir de caractères spéciaux',
  UniqueGoalName: "Nom d'objectif existant. Spécifier un nom différent.",
  UniqueTaskName: 'Nom existant. Veuillez spécifier un nom différent.',
  StartCampaignError: "Une erreur s’est produite durant l'arrêt de la campagne. Réessayer plus tard.",
  StopCampaignError: 'Une erreur s’est produite durant le démarrage de la campagne. Réessayer plus tard.',
  DuplicateCompanyError: 'Impossible de dupliquer la société : le statut et le produit ont déjà été affectés.',
  DeleteCampaign: 'Une erreur s’est produite durant la suppression de la campagne. Réessayer plus tard.',
  ChangeCampaignName: 'Une erreur s’est produite durant le changement de nom de la campagne. Réessayer plus tard.',
  ChangeEndDate: 'Une erreur s’est produite durant le changement de date de fin. Réessayer plus tard.',
  ChangeStartDate: 'Une erreur s’est produite durant le changement de date de début. Réessayer plus tard.',
  ChangeCampaignNote: 'Une erreur s’est produite durant la modification de la note. Réessayer plus tard.',
  AddCompanyNote: "Une erreur s’est produite durant l'ajout de la note. Réessayer plus tard.",
  DeleteCompanyNote: 'Une erreur s’est produite durant la suppression de la note. Réessayer plus tard.',
  FetchCompanyNotes: 'Une erreur s’est produite durant la récupération de notes. Réessayer plus tard.',
  AssignTask: "Une erreur s’est produite durant l'affectation de la tâche sélectionnée. Réessayer plus tard.",
  CampaignDownloadError: 'Une erreur est survenue durant le téléchargement du fichier excel. Réessayer plus tard.',
  RenewCampaign: 'Il est impossible de renouveler cette campagne car elle ne contient pas de portefeuille valable.',
  AssignCampaignError: "Une erreur s’est produite durant l'enregistrement de vos affectations. Réessayer plus tard.",
  AssignCampaignErrorCompaniesDuplicated:
    "Une erreur s’est produite durant l'enregistrement de vos affectations. Présence d'une ou de plusieurs sociétés dupliquées.",
  FetchCampaigns: 'Une erreur s’est produite durant la récupération des campagnes. Réessayer plus tard.',
  SaveCampaign: "Une erreur s’est produite durant l'enregistrement de la campagne. Réessayer plus tard.",
  DuringCampaignCreation:
    "Une erreur s'est produite et le processus de création de la campagne ne peut aboutir. Réessayer plus tard.",
  FetchCompanies: 'Une erreur s’est produite durant la récupération des sociétés.',
  EnrichCompany: "Désolé, mais l'enrichissement des sociétés a échoué.",
  NotFound: {
    Title: 'Introuvable',
    Text: "Désolé, la page consultée n'existe pas.",
    BackHome: "Retour à la page d'accueil",
    DocumentNotFound: "Ce document n'est plus disponible.",
    PortfolioNotFound: "Ce portefeuille n'est plus disponible.",
    CampaignNotFound: "Cette campagne n'est plus disponible.",
    GoBack: 'Retour',
    QueryNotFound: 'Requête non disponible.',
    ReportLoadFailed: 'Rapport non disponible ou inexistant.',
    Section: 'Section non disponible',
    SectionForCountry: "Cette section n'est pas disponible dans votre pays ({{country}})",
    ResetPasswordLinkExpired:
      'Lien de réinitialisation du mot de passe expiré. Demander un nouveau lien de réinitialisation à la page de connexion',
    EnrichmentNotPossible: "L'enrichissement n'est pas possible.",
    EnrichmentNotPossibleBody:
      "L'entreprise que vous recherchez n'est pas enrichissante car elle peut appartenir à un portefeuille masqué par un autre utilisateur de votre organisation.",
    ReportNotAvailable: 'Rapport non disponible.',
    ReportNotAvailableBody:
      "L'entreprise que vous recherchez n'est pas accessible, car elle peut appartenir à un portefeuille masqué par un autre utilisateur de votre organisation."
  },
  ErrorPage: {
    Text: 'Une erreur inattendue s’est produite.'
  },
  ConfigGuardErrorPage: {
    Title: 'Nous sommes désolés',
    Text: "Impossible de charger la configuration d'application.",
    Reload: 'Recharger configuration',
    Logout: 'Déconnexion'
  },
  CreateProduct: 'Désolé, nous ne pouvons pas créer votre produit.',
  UniqueProductName: 'Nom de produit existant. Spécifier un nom différent.',
  PasswordSameAsOld: "Le mot de passe ne peut pas être identique à l'ancien mot de passe",
  PasswordCouldNotBeChanged: 'Impossible de modifier le mot de passe. Contrôler les règles à respecter.',
  InvalidOldPassword: 'Le mot de passe saisi est incorrect.',
  UsernameDoesMatch: "Le nom d'utilisateur emprunté est le même que le nom d'utilisateur",
  PasswordContainsUsername: "Le mot de passe ne peut pas contenir le nom d'utilisateur",
  PasswordCharacterAndDigit: 'Le mot de passe doit comporter au moins une lettre et un chiffre',
  PasswordMultipleCharacters: 'Le mot de passe ne peut pas contenir les trois mêmes caractères consécutifs',
  PasswordDoesNotMatch: 'Mot de passe non correspondant',
  DisallowedPassword: 'Mot de passe non admis',
  SelectAtLeastOneCompany: 'Sélectionner au moins une société.',
  TargetingLoadPortfolio: 'Erreur pendant le chargement du portefeuille sélectionné',
  TargetingLoadPortfolioDescription: "Réessayer plus tard ou le portefeuille sélectionné n'est peut-être pas valable",
  TargetingLoadQuery: 'Erreur pendant le chargement de la requête sélectionnée',
  TargetingLoadQueryDescription: "Réessayer plus tard ou la requête sélectionnée n'est peut-être pas valable",
  TargetingDownloadAnalysisError: "Une erreur s'est produite et l'analyse n'a pas pu être téléchargée.",
  GenericMessage: "Une erreur s'est produite pendant le traitement de votre requête.",
  LoginSE: 'Problème de connexion, réessayer plus tard.',
  TooManyWordsInFilter: 'Le nombre maximum de 1 000 mots saisissable simultanément est dépassé ({{wordCount}}/1 000)',
  FailedToLoadImprintPolicy: 'Impossible de charger les mentions légales, réessayer plus tard.',
  FailedToLoadPrivacyPolicy: 'Impossible de charger la politique de confidentialité, réessayer plus tard.',
  FailedToLoadCookiesPolicy: 'Impossible de charger la politique sur les cookies, réessayer plus tard.',
  OneOfRequired: 'Un des champs « {{field}} » ou « {{otherField}} » est requis.',
  NoEmptyValues: 'La requête ne doit pas comprendre de valeurs vides.',
  ProspectingFunnelErrorTitle: 'Erreur de chargement trémie',
  ProspectingFunnelErrorText: 'Erreur de chargement trémie. Réessayer ou contacter le service client.',
  MinValueIsRequired: 'La valeur "Min" est requise',
  MaxValueIsRequired: 'La valeur "Max" est requise',
  MatchingTermDoesNotExist: "{{matchingTerm}} n'existe pas",
  CountryRequired: 'Veuillez sélectionner le pays',
  R_24: 'La mise à jour de l\'entreprise entraînerait une duplicité. Une société avec les mêmes "Propriété en portefeuille" et "Produit" existe déjà en portefeuille.',
  1005: 'Le nombre maximal de tags a dépassé la limite autorisée de 20.'
}
