import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Col, Row } from '../Grid'
import { CreditStatistic } from './CreditStatistic'
import { Statistic } from './Statistic'

const EnrichRow = styled(Row)`
  margin: -10px -10px 10px;

  > ${Col} {
    padding: 10px;
    display: flex;
    flex: 1 1 auto;
  }
`

type EnrichStatProps = {
  count: number
  text: string
  inDialog?: boolean
}

const EnrichStat = ({ inDialog, ...props }: EnrichStatProps) =>
  inDialog ? (
    <Col col={12} md={12} lg={4}>
      <Statistic {...props} />
    </Col>
  ) : (
    <Col col={12} md={4}>
      <Statistic {...props} />
    </Col>
  )

type CommonProps = {
  totalCompanies: number
  enrichedCompanies: number
  notEnrichedCompanies: number
  usedCredits: number
  remainingCredits: number
  inDialog?: boolean
}

type CreditStatisticsProps = {
  totalCompaniesText?: string
  enrichedCompaniesText?: string
  notEnrichedCompaniesText?: string
}

export const EnrichCreditStatistics = ({
  totalCompanies,
  enrichedCompanies,
  notEnrichedCompanies,
  usedCredits,
  remainingCredits,
  inDialog,
  totalCompaniesText,
  enrichedCompaniesText,
  notEnrichedCompaniesText
}: CommonProps & CreditStatisticsProps) => {
  const { t } = useTranslation('portfolio')
  return (
    <div>
      <EnrichRow>
        <EnrichStat
          inDialog={inDialog}
          count={totalCompanies}
          text={totalCompaniesText || t('Enrichment.CompaniesInPortfolio', { count: totalCompanies })}
        />
        <EnrichStat
          inDialog={inDialog}
          count={enrichedCompanies}
          text={enrichedCompaniesText || t('Enrichment.CompaniesEnriched', { count: enrichedCompanies })}
        />
        <EnrichStat
          inDialog={inDialog}
          count={notEnrichedCompanies}
          text={notEnrichedCompaniesText || t('Enrichment.CompaniesNotEnriched', { count: notEnrichedCompanies })}
        />
      </EnrichRow>
      <CreditStatistic
        used={usedCredits}
        usedText={t('Enrichment.CreditsWillBeUsed', { count: usedCredits })}
        remaining={remainingCredits}
        remainingText={t('Enrichment.CreditsRemaining', { count: remainingCredits })}
      />
    </div>
  )
}

export const EnrichCreditProspectingStatistics = ({
  totalCompanies,
  enrichedCompanies,
  notEnrichedCompanies,
  usedCredits,
  remainingCredits,
  inDialog
}: CommonProps) => {
  const { t } = useTranslation('common')
  return (
    <div>
      <EnrichRow>
        <EnrichStat
          inDialog={inDialog}
          count={totalCompanies}
          text={t('SaveList.Enrichment.CompaniesInList', { count: totalCompanies })}
        />
        <EnrichStat
          inDialog={inDialog}
          count={enrichedCompanies}
          text={t('SaveList.Enrichment.CompaniesEnriched', { count: enrichedCompanies })}
        />
        <EnrichStat
          inDialog={inDialog}
          count={notEnrichedCompanies}
          text={t('SaveList.Enrichment.CompaniesNotEnriched', { count: notEnrichedCompanies })}
        />
      </EnrichRow>
      <CreditStatistic
        used={usedCredits}
        usedText={t('SaveList.Enrichment.CreditsWillBeUsed', { count: usedCredits })}
        remaining={remainingCredits}
        remainingText={t('SaveList.Enrichment.CreditsRemaining', { count: remainingCredits })}
      />
    </div>
  )
}
