import _ from 'lodash'
import React, { createContext, useContext } from 'react'
import { CommonModuleContext, CommonModuleContextType } from '../context'

// TODO: these types are temporary here because of Dependency cycle in types.ts
export type TargetingContextTypePure = {}
export type TargetingContextType = TargetingContextTypePure & CommonModuleContextType

export const TargetingContext = createContext<TargetingContextTypePure>({
  setTargetingFilters: _.noop
})

export const TargetingContextProvider = ({
  children,
  querySaved,
  setQuerySaved,
  orderByKeys,
  setOrderByKeys,
  expression,
  setExpression,
  excludedPortfoliosIds,
  setExcludedPortfoliosIds,
  selectedPortfoliosIds,
  setSelectedPortfoliosIds,
  semanticSearch,
  setSemanticSearch,
  basedOn,
  setBasedOn
}: React.PropsWithChildren<TargetingContextType>) => {
  return (
    <CommonModuleContext.Provider
      value={{
        expression,
        setExpression,
        orderByKeys,
        setOrderByKeys,
        excludedPortfoliosIds,
        setExcludedPortfoliosIds,
        selectedPortfoliosIds,
        setSelectedPortfoliosIds,
        semanticSearch,
        setSemanticSearch,
        querySaved,
        setQuerySaved,
        basedOn,
        setBasedOn
      }}
    >
      <TargetingContext.Provider value={{}}>{children}</TargetingContext.Provider>
    </CommonModuleContext.Provider>
  )
}

export const useTargetingContext = () => {
  const commonModuleContext = useContext(CommonModuleContext)
  const targetingContext = useContext(TargetingContext)
  return { ...commonModuleContext, ...targetingContext }
}
