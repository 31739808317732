import { createAction } from '../../../utils/redux'
import { ActionsUnion } from '../../../utils/types'
import { RESET_COMPANIES } from '../actions'

export type ConcreteReducer = 'dashboard' | 'portfolioManagement' | 'views.portfolioDetail' | 'prospecting'

export const SET_COMPANY_IDS = 'companies/SET_COMPANY_IDS'
export const SET_SELECTED_ROWS = 'portfolio-companies/SET_SELECTED_ROWS'
export const SELECT_ALL_ROWS = 'portfolio-companies/SELECT_ALL_ROWS'

export const setPortfolioCompanyIds =
  (reducer: ConcreteReducer) =>
  (ids: string[], portfolioId?: string, reload = true) =>
    createAction(SET_COMPANY_IDS, { reducer, portfolioId, ids, reload })

export const setSelectedRowKeys = (reducer: ConcreteReducer) => (selectedRowKeys: string[], portfolioId?: string) =>
  createAction(SET_SELECTED_ROWS, { reducer, portfolioId, selectedRowKeys })

export const selectAllRows =
  (reducer: ConcreteReducer) => (selected: boolean, portfolioId?: string, selectedRowKeys?: string[]) =>
    createAction(SELECT_ALL_ROWS, { reducer, portfolioId, selected, selectedRowKeys })

const withPortfolioCompanies = (reducer: ConcreteReducer) => ({
  setPortfolioCompanyIds: setPortfolioCompanyIds(reducer),
  setSelectedRowKeys: setSelectedRowKeys(reducer),
  selectAllRows: selectAllRows(reducer),
  reset: () => createAction(RESET_COMPANIES)
})

export const DashboardPortfolioCompaniesActions = withPortfolioCompanies('dashboard')
const PortfolioManagementCompaniesActions = withPortfolioCompanies('portfolioManagement')
export const PortfolioDetailCompaniesActions = withPortfolioCompanies('views.portfolioDetail')
export const ProspectingCompaniesActions = withPortfolioCompanies('prospecting')

export type WithPortfolioCompaniesActions = ActionsUnion<
  typeof DashboardPortfolioCompaniesActions &
    typeof PortfolioManagementCompaniesActions &
    typeof PortfolioDetailCompaniesActions
>
