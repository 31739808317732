import { NavigateFunction } from 'react-router-dom'
import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'

export const PORTFOLIO_COMPANIES_LOAD_START = 'view/portfolio/companies/FETCH_PORTFOLIO_COMPANIES_START'
export const LOAD_PORTFOLIO_DETAIL_WITHOUT_PORTFOLIO = 'view/portfolio/LOAD_PORTFOLIO_DETAIL_WITHOUT_PORTFOLIO'

export const PortfolioDetailViewActions = {
  loadPortfolioDetailWithoutPortfolio: (portfolioId: string, navigate: NavigateFunction) =>
    createAction(LOAD_PORTFOLIO_DETAIL_WITHOUT_PORTFOLIO, { portfolioId, navigate }),
  portfolioCompaniesLoadStart: () => createAction(PORTFOLIO_COMPANIES_LOAD_START)
}

export type PortfolioDetailViewActionType<TAction extends keyof typeof PortfolioDetailViewActions> = ReturnType<
  typeof PortfolioDetailViewActions[TAction]
>

export type PortfolioDetailViewActions = ActionsUnion<typeof PortfolioDetailViewActions>
