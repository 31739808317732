import { createSelector } from 'reselect'
import { PortfolioCompany } from './types'
import { RootState } from '../types'
import { paramSelector } from '../../utils/selectors'

type WithEntityId = { entityId: string }

const selectState = (state: RootState) => state.portfolioCompany

export const getPortfolioCompanies = createSelector(selectState, state => state.portfolioCompanies)
export const getPortfolioCompaniesOrder = createSelector(selectState, state => state.order)

export const getCompany = createSelector(
  getPortfolioCompanies,
  paramSelector<WithEntityId>(),
  (portfolioCompanies, { entityId }): PortfolioCompany | undefined => portfolioCompanies[entityId]
)
