import _ from 'lodash'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ComponentProps } from '../../types'
import { ContentContainer, Link, Paragraph, TextTitle } from './components'

const renderers: { [nodeType: string]: React.ElementType } = {
  root: ContentContainer,
  // eslint-disable-next-line react/display-name
  paragraph: props => {
    // eslint-disable-next-line react/destructuring-assignment
    return <Paragraph {...props} />
  },
  // eslint-disable-next-line react/display-name
  link: (props: ComponentProps<typeof Link>) => {
    // eslint-disable-next-line react/destructuring-assignment
    return <Link {...props} />
  },
  // eslint-disable-next-line react/display-name
  heading: props => {
    // eslint-disable-next-line react/destructuring-assignment
    return <TextTitle id="content" {...props} />
  }
}

type MarkdownProps = {
  children?: string
}

export const Markdown = ({ children }: MarkdownProps) => {
  if (!children) return null
  return (
    <ReactMarkdown renderers={renderers} linkTarget={_.constant('_blank')}>
      {children}
    </ReactMarkdown>
  )
}
