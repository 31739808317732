import React from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlinedStyled } from '../styled'
import DeleteIcon from '../../../assets/icons/icon-s-trash-2.svg'
import { Stack } from '../../../components/Stack'
import { TableReduxless, TableProps } from '../../../components/Table/TableReduxless'
import { IconButton } from '../../../components/Button'
import { GoalTask } from '../../../api/goals/types'
import { BasicTooltip } from '../../../components/Tooltip'

type Props<T> = {
  openEditTask: (taskId: string, taskName: string) => void
  openDeleteTask: (taskId: string, taskName: string) => void
} & TableProps<T>

export function GoalDetailTable({ openEditTask, openDeleteTask, columns, ...props }: Props<GoalTask>) {
  const { t } = useTranslation(['account', 'common'])
  const cols = columns

  cols.push({
    title: '',
    dataIndex: 'id',
    align: 'right',
    width: 50,
    sorter: false,

    // eslint-disable-next-line react/display-name
    render: (id, task) => (
      <Stack gap="10px">
        <BasicTooltip placement="bottom" title={t('account:Goal.EditTask.Title')}>
          <IconButton
            type="transparent"
            onClick={() => {
              openEditTask(id, task.name)
            }}
          >
            <EditOutlinedStyled />
          </IconButton>
        </BasicTooltip>
        <BasicTooltip placement="bottom" title={t('account:Goal.DeleteTask.Title')}>
          <IconButton
            type="transparent"
            onClick={() => {
              openDeleteTask(id, task.name)
            }}
          >
            <img src={DeleteIcon} alt="delete" />
          </IconButton>
        </BasicTooltip>
      </Stack>
    )
  })

  return (
    <TableReduxless {...props} sortDirections={['descend', 'ascend', 'descend']} columns={cols} pagination={false} />
  )
}
