import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components/macro'
import { TreeSelect as AntTreeSelect } from 'antd3'
import { TreeNodeValue, TreeSelectProps } from 'antd3/lib/tree-select/interface'
import _ from 'lodash'
import { IsModalContext } from '../Dialog'

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .ant-select {
    width: 100%;
    display: block;
  }

  .ant-select-selection {
    min-height: 40px;
    overflow: hidden;
    border: none;
    background-color: ${({ theme }) => theme.colors.paleGrey};

    .ant-select-selection__placeholder,
    .ant-select-selection-selected-value {
      font-size: 14px;
      line-height: 1.71;
      color: ${({ theme }) => theme.colors.brownGray};
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      right: 0;
      margin-top: -12px;
    }

    .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center;
      height: 40px !important;
    }

    .ant-select-selection__rendered {
      min-height: 40px;
      height: auto;
      margin-left: 16px;
      margin-right: 40px;
    }

    .ant-select-selection__clear {
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }

    .anticon-loading svg {
      fill: ${({ theme }) => theme.colors.main};
    }
  }

  .down-arrow {
    position: absolute;
    top: 0;
    right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: transform ${({ theme }) => theme.animation.duration} ease;
  }

  .ant-select-open + .down-arrow {
    transform: rotate(180deg);
  }
  && .ant-select-disabled .ant-select-selection__choice {
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
    cursor: not-allowed;
  }

  && .ant-select-selection__choice {
    height: 24px;
    background-color: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
    padding-right: 21px;
    border-radius: 4px;
    border: none;

    .ant-select-selection__choice__content {
      line-height: 22px;
    }

    .ant-select-selection__choice__remove {
      top: 2px;
      svg {
        width: 15px;
        height: 15px;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const PureTreeSelect = <T extends TreeNodeValue>(
  props: PropsWithChildren<TreeSelectProps<T>>,
  ref: React.LegacyRef<any>
) => {
  const { children, ...rest } = props
  const isModal = React.useContext(IsModalContext)
  return (
    <SelectWrapper>
      <AntTreeSelect<T>
        {...rest}
        ref={ref}
        dropdownClassName={`${rest.dropdownClassName ? rest.dropdownClassName : ''} ${
          isModal ? ' dialog-dropdown-select' : ''
        }`}
      >
        {children}
      </AntTreeSelect>
    </SelectWrapper>
  )
}

// eslint-disable-next-line react/display-name
export const TreeSelect = React.forwardRef(PureTreeSelect) as <T extends TreeNodeValue>(
  p: PropsWithChildren<TreeSelectProps<T>> & { ref?: React.LegacyRef<any> }
) => ReactElement

export const { TreeNode } = AntTreeSelect

export const filterTreeNode = (inputValue: string, treeNode: any) => {
  const title = _.toLower(treeNode?.props?.title ?? '')
  const normalizedInputValue = _.toLower(inputValue ?? '')
  if (!treeNode.props.isLeaf) return false

  try {
    return title.search(normalizedInputValue) !== -1
  } catch (error) {
    return _.includes(title, normalizedInputValue)
  }
}
