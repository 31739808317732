import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSalestoolKeys, useArchiveCampaign, useGetCampaign } from '../../../../../api/salestool'
import { Dialog } from '../../../../../components/Dialog'
import { Text } from '../../../../../components/Text'
import { SALESTOOL } from '../../../../../routes'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import SalesToolImage from '../../../../../assets/images/sales-tool.svg'
import { BaseDialog } from '../../../BaseDialog'
import { CampaignStatus } from '../../../../../features/salestool/types'
import { DialogActions } from '../../../../../features/dialog/actions'
import { queryClient } from '../../../../../features/queryclient'
import { getFiltersKeys } from '../../../../../api/filters'

type Props = {
  data: {
    campaignId: string
  }
}

export const StopCampaignDialog = ({ data: { campaignId } }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const closeAndRedirect = useCallback(() => {
    closeDialog()
    navigate({ pathname: SALESTOOL })
  }, [closeDialog, navigate])

  const onSuccessfulCampaignStop = useCallback(() => {
    closeAndRedirect()
    queryClient.invalidateQueries(getFiltersKeys.DomainCodesTree(), { refetchType: 'all' })
    queryClient.invalidateQueries(getSalestoolKeys.GetCampaigns(), { refetchType: 'all' })
  }, [closeAndRedirect])

  const {
    mutate: mutateStopCampaign,
    isLoading,
    isSuccess,
    isError,
    isIdle
  } = useArchiveCampaign(campaignId, { targetCampaignStatus: CampaignStatus.Stopped })

  const { data: campaign } = useGetCampaign(campaignId)

  const props = {
    destroyOnClose: false,
    title: t('StopCampaignBtn'),
    footer: '',
    okText: t(isIdle ? 'common:Continue' : 'common:Ok'),
    cancelText: isIdle ? t('common:Cancel') : undefined,
    onCancel: isSuccess ? closeAndRedirect : closeDialog,
    width: 1024,
    visible: true,
    loading: isLoading
  }

  if (isSuccess) {
    const campaignName = campaign?.campaignName ?? ''
    const successText = <Text block>{t('Management.Campaign.StopCampaignDoneDialogText1', { campaignName })}</Text>
    return (
      <Dialog {...props} onOk={onSuccessfulCampaignStop} onCancel={onSuccessfulCampaignStop}>
        <BaseDialog content={successText} image={<img src={OkImage} alt="ok" />} />
      </Dialog>
    )
  }

  if (isError) {
    const failText = <Text block>{t('error:StopCampaignError')}</Text>
    return (
      <Dialog {...props} onOk={closeDialog}>
        <BaseDialog content={failText} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  const stopText = (
    <>
      <Text block primary bold>
        {t('Management.Campaign.StopCampaignDialogText1')}
      </Text>
      <Text block>{t('Management.Campaign.StopCampaignDialogText2')}</Text>
      <Text block>{t('Management.Campaign.StopCampaignDialogText3')}</Text>
    </>
  )
  return (
    <Dialog {...props} onOk={() => mutateStopCampaign()}>
      <BaseDialog content={stopText} image={<img src={SalesToolImage} alt="salesTool" />} />
    </Dialog>
  )
}
