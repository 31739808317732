import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmSaveQueryDialog } from '../../../containers/SaveQuery/ConfirmSaveQueryDialog'
import { useCanAddWidget } from '../../Dashboard/hooks/useCanAddWidget'
import { useSaveQueryMutation } from './mutations'
import { SaveQueryDialog } from './SaveQueryDialog'
import { SaveQueryDialogData, SubmitMethod } from './types'

type Props = {
  data: SaveQueryDialogData
  close: () => void
}

export const SaveQueryDialogContainer: React.FC<Props> = ({
  data: { contextData, portfolioId, setQuerySaved },
  close
}) => {
  const { t } = useTranslation(['portfolio', 'error', 'common'])

  const [queryId, setQueryId] = useState<string>()
  const [queryName, setQueryName] = useState('')
  const [nameError, setNameError] = useState<string>()
  const [updateError, setUpdateError] = useState<string>()
  const [currentSaveMethod, setCurrentSaveMethod] = useState(SubmitMethod.CreateNew)
  const [addToDashboard, setAddToDashboard] = useState(false)

  const { canAddWidget, isDashboardLoading } = useCanAddWidget()

  const saveQuery = useSaveQueryMutation({
    queryId,
    queryName,
    expression: contextData.expression,
    semanticSearch: contextData.semanticSearch,
    excludedPortfoliosIds: contextData.excludedPortfoliosIds,
    selectedPortfoliosIds: contextData.selectedPortfoliosIds,
    currentSaveMethod,
    portfolioId,
    setUpdateError,
    setNameError,
    addToDashboard
  })

  if (saveQuery.isSuccess) {
    return <ConfirmSaveQueryDialog queryName={queryName} close={close} t={t} addToDashboard={addToDashboard} />
  }
  return (
    <SaveQueryDialog
      submitting={saveQuery.isLoading && !saveQuery.isError}
      portfolioId={portfolioId}
      queryId={queryId}
      queryName={queryName}
      queryNameError={currentSaveMethod === SubmitMethod.CreateNew ? nameError : updateError}
      currentSaveMethod={currentSaveMethod}
      setQueryId={setQueryId}
      setQueryName={setQueryName}
      setCurrentSaveMethod={setCurrentSaveMethod}
      addToDashboard={addToDashboard}
      setAddToDashboard={setAddToDashboard}
      canAddWidget={canAddWidget}
      loading={isDashboardLoading}
      submit={() => {
        setQuerySaved(true)
        saveQuery.mutate()
      }}
      close={close}
      setNameError={setNameError}
    />
  )
}
