/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  SalesTool: {
    Header: 'Sales Tool',
    Management: {
      Sales: 'Sales',
      SalesSgCzsk: 'Sales',
      Campaigns: 'Campaigns',
      SearchCampaign: 'Search campaign',
      SearchCampaignLong: 'Search for name of campaign',
      NewCampaign: 'Create campaign',
      NewCampaignTooltip: 'Plan a commercial campaign starting from a portfolio',
      CampaignArchive: 'Campaign archive',
      CampaignArchiveTooltip: 'Access the archive of completed campaigns and renew them',
      CampaignsToAssign: 'To assign',
      CampaignsToStart: 'Not yet started',
      CampaignsActive: 'Started',
      CampaignsArchived: 'Archived',
      Stats: {
        Companies: 'Company',
        Companies_plural: 'Companies',
        StartDate: 'Start date',
        EndDate: 'End date',
        EnrichedCompanies: 'Enriched companies',
        Clients: 'Clients',
        Suppliers: 'Suppliers',
        Prospects: 'Prospects',
        LastUpdate: 'Last update',
        Competitors: 'Competitors',
        Lead: 'Lead'
      },
      Table: {
        NumberOfCompanies: 'Number of companies',
        GoalName: 'Goal Name',
        Tasks: 'Tasks',
        Status: 'Status',
        CampaignPortfolios: 'Portfolios in this campaign'
      },
      PorfolioBox: 'Portfolio in this campaign',
      ConversionRate: '{{percentage}}% conversion rate',
      CompletedTasks: '{{percentage}}% completed tasks',
      Banner: {
        Title: 'You have to "click" on the Campaign Name to assign the list',
        Subtitle: 'Before starting the campaign, assign Sales and Products to each company in the list.'
      },
      CreatedAtAscending: 'Creation date (Ascending)',
      CreatedAtDescending: 'Creation date (Descending)',
      StartDateAscending: 'Start date (Ascending)',
      StartDateDescending: 'Start date (Descending)',
      EndDateAscending: 'End date (Ascending)',
      EndDateDescending: 'End date (Descending)'
    },
    AddCampaign: {
      EnterName: 'Enter a representative name for the campaign',
      EnterNameDescription: 'You will use one ring per campaign you create',
      EnterDates: 'Select the start date and the end date',
      EnterDatesDescription:
        'The start and end dates will always be displayed associated with the campaign and will help you and your sales to achieve Business goals',
      withCustomVariables: 'with custom variables',
      withoutCustomVariables: 'without custom variables',
      EnterPortfolio: 'Choose portfolio you want to associate to the campaign',
      EnterPortfolioDescription: 'Associate one or more portfolio to the campaign you just created.',
      EnterGoal: 'Choose a goal for the campaign',
      EnterGoalDescription: 'Select a goal, allocated to some defined tasks.',
      NoGoalDescription: 'There is no saved Goal yet. Please, create one in <0>Your account</0> section.',
      InvalidGoal:
        'The selected goal does not have any tasks. Please select another or edit in <0>Your account</0> section.',
      EnterConversionRate: 'Enter a description and set conversion rate',
      EnterConversionRateDescription:
        'Enter a description about the campaign you are creating, it will give more information on the goal you want to achieve.',
      CampaignCreationPendingTitle: 'Campaign creation',
      CampaignCreationPendingText:
        'Your campaign is being created. You will be redirected to Sales Tool and will receive a notification when the operation is complete.',
      SalesPerformanceChartTitle: 'Do you want to make the "Sales performance" chart visible for Sales users?',
      SalesPerformanceChartText: 'Enable the chart',
      RestoreDataQuestion: 'Do you want to restore all the data associated to this campaign before it expired?',
      RestoreDataEnable: 'Enable all previous data',
      RestoreDataEnableDisabledTooltip:
        'The original goal for this campaign cannot be selected due to its deletion. Select a new one among the available goals.'
    }
  },
  ManageCampaign: {
    Header: 'Manage Sales and Product',
    SalesHeader: 'Manage Campaign'
  },
  Management: {
    Message: {
      Header: 'Create a campaign, assign it to your sales and start it',
      SubHeader: 'To create a campaign use the "Create campaign" button.',
      Description:
        'After creating your campaign you can assign the companies to the sales and then start it. On this page you will find all your campaigns.'
    },
    Campaign: {
      ChangeCampaignName: 'Change campaign name',
      ChangeCampaignNameShort: 'Change name',
      ChangeCampaignNote: 'Change campaign note',
      AddCampaignNote: 'Add note',
      ChangeCampaignNoteDescription: 'Change the note to describe the conversione rate.',
      AddCampaignNoteDescription: 'Enter a note to describe the conversione rate.',
      EnterARepresentativeName: 'Enter a representative name for the campaign',
      InsertCampaignName: 'Insert campaign name',
      InsertCampaignNote: 'Insert a note',
      NameChangedCorrectly: 'Name changed correctly in',
      NoteChangedCorrectly: 'Note changed correctly',
      NoteRemovedCorrectly: 'Note removed correctly',
      NoteAddedCorrectly: 'Note added correctly',
      ChangeEndDate: 'Change end date',
      ChangeEndDateDescription: 'Change the current end date.',
      ChangeEndDateSuccess: 'will end on',
      ChangeStartDate: 'Change start date',
      ChangeStartDateDescription: 'Change the current start date.',
      ChangeStartDateSuccess: 'will start on',
      StartCampaignDialogText1: 'Do you want to start this campaign?',
      StartCampaignDialogText2:
        'You will see the progress of the whole campaign in the Sales tool management dashboard.',
      StartCampaignDialogText3:
        'If you click on "continue", each sales will receive a notification about "company assignment".',
      StartCampaignDoneDialogText1: '{{campaignName}} starts on {{startDate}}.',
      StartCampaignDoneDialogText2: 'Each sales will receive a notification about "company assignment"',
      StopCampaignDialogText1: 'Do you want to stop this campaign?',
      StopCampaignDialogText2: 'It will be place in "Campaign archive" area, you could "Renew " it whenever you want.',
      StopCampaignDialogText3: 'To view the "Campaign archive" you have to go back to the dashboard.',
      StopCampaignDoneDialogText1:
        '{{campaignName}} is stopped correctly, you can find it in the "Campaign archive" section.',
      DeleteCampaignDialogText1: 'Are you sure you want to delete {{campaignName}}?',
      DeleteCampaignDialogText2: 'All campaign results will be deleted.',
      DeleteCampaignDoneDialogText1: '{{campaignName}} deleted correctly.'
    }
  },
  AssignCampaign: {
    Header: 'Assign Sales and Product',
    StartDate: 'Start date:',
    EndDate: 'End date:',
    AddPortfolio: 'Add companies/portfolio',
    AddPortfolioTooltip: 'Add new companies to this campaign',
    SelectColumns: 'Select columns',
    GroupActions: 'Group actions',
    SalestoolAssignmentsSelectPlaceholder: 'Select',
    AssignSales: 'Assign sales',
    AssignProduct: 'Assign product',
    NotAssigned: 'Not assigned',
    Sales: 'Sales',
    Product: 'Product',
    Note: 'Notes',
    EditNoteIconTitle: 'Edit note',
    CompaniesCount: 'Company',
    CompaniesCount_plural: 'Companies',
    AssignedCount: 'Assigned',
    NotAssignedCount: 'Not Assigned',
    AppAround: {
      NotAlreadyDone: 'No offer has already been sent',
      InProgress: 'An offer has already been submitted on {{dateTime}}',
      Ko: 'Error in offering on {{dateTime}}',
      Ok: 'Offer sent on {{dateTime}}'
    },
    Inventia: {
      NotAlreadyDone: 'No call has been started',
      InProgress: 'The call has been started on {{dateTime}}',
      Ko: 'Error in the call, on {{dateTime}}',
      Ok: 'Call performed on {{dateTime}}'
    },
    Banner: {
      Title: 'Click on "Assign campaign" to assign Sales and Products',
      Subtitle:
        'Before starting the campaign assign Sales and Products to each companies in the list. At the end click on "Save" to complete the operation, the campaign will not start until you click on "Start campaign".'
    },
    WriteYourNote: 'Write here your notes',
    Dialog: {
      SelectAtLeastOneCompany: 'You must select at least one company.',
      AssignSaleTitle: 'Assign sale',
      AssignProductTitle: 'Assign product',
      AssignSaleOrProductMultiple: '{{item}} assigned to {{count}} company.',
      AssignSaleOrProductMultiple_plural: '{{item}} assigned to {{count}} companies.',
      DuplicateCompanyTitle: 'Duplicate company',
      DuplicateCompanyConfirm: 'Are you sure you want to duplicate {{companyName}}?',
      DuplicateCompanySelection: 'To duplicate a company it is necessary to change its status or its product.',
      DuplicateCompanyDone: '{{companyName}} duplicated correctly inside {{campaignName}}.',
      DuplicateCompanySelectProduct: 'Select product',
      DuplicateCompanySelectStatus: 'Select status',
      DuplicateCompanyProduct: 'Product',
      DuplicateCompanyStatus: 'Status',
      SaveCampaignTitle: 'Save campaign',
      SaveCampaignPending:
        'The save operation is in progress and may take several minutes. You will receive a notification upon completion.',
      AddCompaniesTitle: 'Adding companies',
      AddPortfolioTitle: 'Add companies/portfolio',
      AddPortfolio: 'Add portfolio',
      SelectThePortfolioYouWantToAdd: 'Select the portfolio you want to add',
      AddNewCompaniesFromStartingPortfolio: 'Add new companies from starting portfolio',
      AddedCompanies: 'Added companies',
      AddedPortfolio: 'Added portfolio',
      AddPortfolioSelect: 'Select',
      AddCompaniesPending:
        'Addition of companies to {{campaignName}} in progress. At the end of the operation, you will receive a notification.',
      AddCompaniesWithCustomVariablesStructureChangedPending:
        "Addition of companies to {{campaignName}} in progress. Some Custom Variables will not be inserted due to variables' structure mismatch. At the end of the operation, you will receive a notification.",
      AddPortfolioPending:
        '{{selectedPortfolioName}} is being added to {{campaignName}}. You will be notified at the end of the operation.',
      AddCompanyNoteTitle: 'Notes',
      AddCompanyNote: 'You can add up to five notes to this company',
      AddCompanyNoteTextAreaLabel: 'Insert a new note (max. 250 characters)',
      AddCompanyNoteTextAreaPlaceholder: 'Your note...',
      DeleteNoteConfirmation: 'Are you sure you want to delete this note?',
      DeleteCompany: {
        Title: 'Delete company',
        Text: 'Are you sure you want to delete the company from the campaign?',
        Success: 'Company was successfully deleted from the campaign.',
        Error: 'There was error while deleting company from the campaign.'
      },
      MoveCompany: {
        Title: 'Move company to another campaign',
        Text: 'The company you have selected, will be removed from the current campaign and added to another campaign. Please, select one campaign from the list below.',
        SelectLabel: 'Select campaign',
        Footer: 'Sales and product assigned to the company will be deleted',
        Success: 'Company was successfully moved to the campaign.',
        Error: 'There was error while moving company to the campaign.',
        ErrorIncompatibleDestEntity:
          'The structure of Custom Variables in this campaign is not compatible with the origin one.'
      }
    },
    Errors: {
      DuplicateCampaign: 'An error occurred while duplicating the company.',
      DuplicateCampaignMissingPortfolio:
        'It is not possible to duplicare the company as the portfolio it belongs to does not exist anymore.',
      AddPortfolio: 'An error occurred while add the selected portfolio.',
      AddCompanies: 'An error occurred while adding new companies from the starting portfolio.'
    }
  },
  SalesPerformance: {
    Banner: {
      Title: 'Your sales performance',
      Subtitle: 'for every single task will be visible on this box'
    },
    Tooltip: 'This box shows top 5 sales for the selected task',
    Actions: 'Actions',
    TotalProgressOrder: 'Total progress',
    LastUpdateOrder: 'Last update',
    NumberCompaniesOrder: ' Number of companies assigned'
  },
  CampaignDetail: {
    MapBoxTitle: 'Geographical distribution',
    CompaniesBoxTitle: 'Companies on task',
    SalesBoxTitle: 'Sales performance',
    NoteLabel: 'Note',
    Task: 'Task',
    TasksStatisticsLabel: 'company',
    TasksStatisticsLabel_plural: 'companies',
    AssignTask: 'Assign task',
    SalesPerformanceNCompanies: 'N° of companies',
    WaterfallChartTasksCompleted: 'completed',
    WaterfallChartBoxTitle: '{{count}} company {{percentage}}% completed',
    WaterfallChartBoxTitle_plural: '{{count}} companies {{percentage}}% completed',
    AllCompaniesLabel: 'All',
    AssignedCompaniesLabel: 'Assigned',
    UnassignedCompaniesLabel: 'Unassigned',
    Dialog: {
      AssignTaskTitle: 'Assign task'
    },
    ShowSalesDetail: 'Show sales detail'
  },
  CompaniesOnTask: {
    Void: 'Void',
    Called: 'Called',
    Visited: 'Visited',
    Negotiation: 'Negotiation',
    Active: 'Active',
    Negative: 'Negative'
  },
  CompanyDetail: {
    Actions: {
      DownloadPDF: 'Download PDF',
      AddToCampaign: 'Add to campaign'
    }
  },
  CampaignArchive: {
    Subtitle:
      'Contains stopped and finished campaigns. Click on "Renew campaign" to restart the campaign maintaining the same assignment ',
    SubtitleSale: 'Contains stopped and finished campaigns.',
    RenewCampaign: 'Renew campaign',
    DeleteCampaign: 'Delete'
  },
  EnrichBeforeRenew: 'Before starting the campaign, please enrich the following portfolios:',
  SaleDetail: {
    Area: 'Area',
    CampaignsView: 'Campaigns view',
    Summary: 'Summary',
    UpdateCampaignBtn: 'Update campaign',
    TotalCompanies: 'Total companies',
    TotalCampaigns: 'Total campaigns',
    ActiveCampaigns: 'Active Campaigns',
    ArchivedCampaigns: 'Archived Campaigns',
    CompletedCampaigns: 'Completed Campaigns',
    AssignTaskDialogText1: 'Do you want to change the task?',
    AssignTaskDialogText1_noPrevTask: 'Do you want to assign the task?',
    AssignTaskDialogText2: 'You are changing task from "{{originalTask}}" to "{{newTask}}".',
    AssignTaskDialogText2_noPrevTask: 'You are assigning "{{newTask}}".',
    AssignTaskDoneDialogText1: 'Task has been changed correctly!',
    AssignTaskDoneDialogText1_noPrevTask: 'Task has been assigned correctly!',
    AssignTaskDoneDialogText2: 'Your Manager will receive a notification.',
    AssignTaskMultipleDoneDialogText1: 'The selected tasks are being assigned.',
    AssignTaskMultipleDoneDialogText2: 'When done, your Manager will receive a notification.',
    AssignTasksDialogText: 'You are assigning "{{newTask}}" to {{fCount}} company.',
    AssignTasksDialogText_plural: 'You are assigning "{{newTask}}" to {{fCount}} companies.',
    AssignTaskDialogHidePopupCheckbox: "Don't show this message anymore"
  },
  ItemNotAssignedYet: 'Not assigned yet',
  LoadMore: 'Load more',
  DownloadBtn: 'Download PDF/Excel',
  DownloadBtnTooltip: 'Download a report of this campaign on your device',
  AssignCampaignBtn: 'Assign Campaign',
  AssignCampaignBtnTooltip: 'Assign sales and products to the companies in this campaign',
  ManageCampaignBtn: 'Manage Campaign',
  ManageCampaignBtnTooltip: 'Assign sales and products to the companies in this campaign',
  StartCampaignBtn: 'Start Campaign',
  StartCampaignBtnTooltip: 'Enable campaign visibility to sales and monitor performance',
  StopCampaignBtn: 'Stop Campaign',
  StopCampaignBtnTooltip: 'Halt this campaign and archive it',
  DeleteCampaign: 'Delete Campaign',
  CampaignName: 'Campaign Name',
  StartDate: 'Start date',
  EndDate: 'End date',
  Name: 'Name',
  PortfolioLabel: 'Portfolio',
  GoalLabel: 'Choose goal',
  ConversionRateLabel: 'Set conversion rate percentage',
  Close: 'Close',
  Save: 'Save',
  SaveTooltip: 'Save the changes made on this campaign',
  CreateCampaign: 'Create campaign',
  RenewCampaign: 'Renew campaign',
  DomainFilter: {
    SaveAndClose: 'Save and Close'
  },
  Table: {
    Empty: 'We have not found any companies that match your search'
  },
  Errors: {
    CampaignSave: 'An error occurred while creating the campaign.'
  },
  GenericErrorTitle: 'An error has occurred',
  Download: {
    PDFHeaderTitle: 'Campaign Report',
    DownloadTitle: 'Download PDF/Excel',
    DownloadPDF: 'Download PDF',
    DownloadExcel: 'Download Excel',
    SelectTypeOfList: 'Download PDF/Excel',
    SelectTypeOfListDescription:
      'You can download the complete report about campaign in two different form, select the type of list you want to download.',
    CompaniesInCampaign: 'Companies in this campaign',
    AssignedCompanies: 'Companies assigned',
    ActiveCompanies: 'Companies Became "Active"',
    Pdf: 'PDF',
    PdfDescription: 'Download in .PDF format',
    Excel: 'Excel',
    ExcelDescription: 'Download in .XlS format',
    DownloadExcelTitle:
      'You are downloading a file containing all the {{companyName}} data and the result of this campaign as a list.',
    DownloadExcelDescription:
      'As soon as the Excel file is created you will receive a notification, and you will be able to download it and save it.',
    DownloadPDFPleaseWait: 'Please wait while your PDF is being generated',
    DownloadExcelPendingMessage:
      'The requested file is being processed and may take several minutes.\nYou can follow the progress from the notifications menu.'
  },
  Actions: {
    GoToCampaign: 'Go to campaign'
  },
  EmptyCampaigns: 'No campaign found'
}
