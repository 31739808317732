import _ from 'lodash'
import { AxiosError } from 'axios'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { createPortfolioFromPortfolioSelection, validateNewPortfolioName } from '../../../api/portfolio'
import { CreatePortfolioFromSelectionChange } from '../../../api/portfolio/types'
import { Dialog } from '../../../components/Dialog'
import { HookForm } from '../../../components/Form'
import { InputField } from '../../../components/Form/Input'
import { Text } from '../../../components/Text'
import { TrackingActions } from '../../../features/analytics/actions'
import { EventCategory, GroupActionType } from '../../../features/analytics/types'
import { DialogActions } from '../../../features/dialog/actions'
import {
  areAllSelected,
  getDeselectedRowItems,
  getSelectedRowItems
} from '../../../features/portfolioCompany/withTable/selectors'
import { RootState } from '../../../features/types'
import { validateNameSynchronous } from '../../../utils/validators'
import { CreatePortfolioDialogData } from './types'
import { convertCompoundExpressionForBackend } from '../../../features/filters/utils'
import { getHasPortfolioExclusions } from '../../../features/user/selectors'

export type Props = {
  data: CreatePortfolioDialogData
  close: () => void
}

export type CreatePortfolioFormState = {
  portfolioName?: string
}

export const CreatePortfolioDialog = ({ data, close }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'portfolio'])
  const hasPortfolioExclusions = useSelector(getHasPortfolioExclusions)

  const { control, handleSubmit, formState, watch } = useForm<CreatePortfolioFormState>({
    mode: 'onChange'
  })
  const { isSubmitting, isValidating, errors } = formState

  const allSelected = useSelector((state: RootState) =>
    areAllSelected(state, { reducer: 'views.portfolioDetail', portfolioId: data.portfolioId })
  )
  const selectedRowItems = useSelector((state: RootState) =>
    getSelectedRowItems(state, {
      reducer: 'views.portfolioDetail',
      portfolioId: data.portfolioId,
      dataIndex: 'entityId'
    })
  )
  const deselectedRowItems = useSelector((state: RootState) =>
    getDeselectedRowItems(state, {
      reducer: 'views.portfolioDetail',
      portfolioId: data.portfolioId,
      dataIndex: 'entityId'
    })
  )

  const {
    mutate: mutateCreatePortfolio,
    isIdle,
    isError,
    isLoading
  } = useMutation(
    () => {
      return createPortfolioFromPortfolioSelection({
        selection: {
          portfolioGridQuery: {
            semantic: data.semantic,
            query: convertCompoundExpressionForBackend(data.expression),
            excludedPortfoliosIds: data.excludedPortfoliosIds
          },
          portfolioName: watch('portfolioName')!,
          sourceContainerItemId: data.portfolioId,
          change: CreatePortfolioFromSelectionChange.Duplicate,
          selectedItems: !allSelected ? selectedRowItems : undefined,
          itemsToIgnore: allSelected ? deselectedRowItems : undefined
        }
      })
    },
    {
      onSuccess: () => {
        dispatch(TrackingActions.trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.SavePortfolio))
        close()
      },
      onError: (e: AxiosError) => {
        if (e?.response?.status === 423) {
          dispatch(DialogActions.showDialog('PortfolioErrorLock'))
        }
      }
    }
  )

  const asyncValidatePortfolioName = async (value: any) => {
    const validationResponse = await validateNewPortfolioName(value)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(true)(watch('portfolioName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  const submitForm = () => {
    if (isIdle) {
      return mutateCreatePortfolio()
    }
    return close()
  }

  return (
    <Dialog
      loading={isLoading}
      visible
      destroyOnClose
      width={1160}
      title={t('portfolio:CompanyGroupActions.CreatePortfolio.Title')}
      footer=""
      onCancel={close}
      cancelText={t('common:Cancel')}
      cancelButtonProps={{ disabled: isLoading }}
      okText={isIdle || isLoading ? t('common:Save') : t('common:Ok')}
      onOk={submitForm}
      okButtonProps={{
        disabled: !watch('portfolioName') || isLoading || isValidating || !_.isEmpty(errors),
        loading: isValidating || isLoading
      }}
    >
      <HookForm handleSubmit={handleSubmit} onSubmit={submitForm} isSubmitting={isSubmitting}>
        {(isIdle || isLoading) && (
          <>
            {hasPortfolioExclusions ? (
              <div>
                <Text>{t('portfolio:CompanyGroupActions.CreatePortfolio.InputDescription')}</Text>
                <Text block margin={{ bottom: 20, left: 5 }}>
                  {t('portfolio:CompanyGroupActions.HiddenCompanyMessage')}
                </Text>
              </div>
            ) : (
              <Text block margin={{ bottom: 40 }}>
                {t('portfolio:CompanyGroupActions.CreatePortfolio.InputDescription')}
              </Text>
            )}
            <InputField
              name="portfolioName"
              label={t('portfolio:PortfolioName')}
              placeholder={t('portfolio:CompanyGroupActions.CreatePortfolio.InsertPortfolioName')}
              control={control}
              formState={formState}
              validators={{
                validateNameSynchronous: validateNameSynchronous(true),
                asyncValidatePortfolioName
              }}
            />
          </>
        )}
        {isError && <p>{t('error:PortfolioUpdateError')}</p>}
      </HookForm>
    </Dialog>
  )
}
