import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../types'
import { TargetingState } from './reducer'
import { paramSelector } from '../../utils/selectors'
import { FilterReducerPath } from '../filters/types'

export const REDUCER: FilterReducerPath = 'targeting'

const selectFromTargetingState =
  <T extends keyof TargetingState>(key: T) =>
  (state: RootState): TargetingState[T] =>
    state.targeting[key]

const selectTargetingFiltersObsolete = selectFromTargetingState('targetingFilters')

export const getSelectedPortfolios = createSelector(selectFromTargetingState('selectedPortfolios'), x => x)

export const getSelectedPortfolioIds = createSelector(getSelectedPortfolios, selectedPortfolios =>
  selectedPortfolios ? _.map(selectedPortfolios, 'id') : []
)

export const getPortfoliosList = createSelector(selectFromTargetingState('portfoliosList'), x => x)

export const getAggregationField = createSelector(selectFromTargetingState('aggregationField'), x => x)

export const getStatusCompanyFilter = createSelector(selectFromTargetingState('statusCompanyFilter'), x => x)

export const getActiveTargetingFilter = createSelector(
  selectTargetingFiltersObsolete,
  paramSelector<string>(),
  (filters, filterName) => filters[filterName]
)

export const getDownloadType = createSelector(selectFromTargetingState('download'), x => x)

const getLoadedQueryName = createSelector(selectFromTargetingState('loadedQuery'), x => x?.queryName)

export const getAnalysisNameForDownload = createSelector(
  getLoadedQueryName,
  getSelectedPortfolios,
  (queryName, selectedPortfolios) => {
    if (queryName) return queryName
    if (selectedPortfolios?.length === 1) return selectedPortfolios[0].name
    return undefined
  }
)
