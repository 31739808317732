import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { ReactComponent as UIcon } from '../../assets/icons/single.svg'
import { ReactComponent as UserSecret } from '../../assets/icons/fa-user-secret.svg'
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/icon-s-log-out.svg'
import { UserActions } from '../../features/user/actions'
import {
  getUserFullName,
  getUserAccountInfo,
  hasOriginator,
  getOriginatorUserCode
} from '../../features/user/selectors'
import { UserType } from '../../authorization'
import { ACCOUNT } from '../../routes'
import { Text } from '../Text'
import { IconButton } from '../Button'

const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dustyBlue};
  margin: 0 15px;
`

const UserIcon = styled(UIcon)`
  fill: white;
  margin: 15px;
`

const UserSmallIcon = styled(UIcon)`
  fill: white;
  width: 20px;
  height: 20px;
  margin-right: 16px;
`

const UserSecretIcon = styled(UserSecret)`
  fill: white;
  margin: 15px;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 25px 10px 15px;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const InfoWrapper = styled.div`
  flex: 1 1 auto;
  padding: 10px 0;
`

const LogoutButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  svg {
    width: 18px;
    fill: ${({ theme }) => theme.colors.white};
    margin-right: 5px;
  }
`
const UserCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const AccountInfo = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const name = useSelector(getUserFullName)
  const accountInfo = useSelector(getUserAccountInfo)
  const isImpersonited = useSelector(hasOriginator)
  const originatorUserCode = useSelector(getOriginatorUserCode)

  const logout = React.useCallback(() => {
    dispatch(UserActions.logout(false))
  }, [dispatch])

  return (
    <div id="ta-account-info">
      <Link to={ACCOUNT}>
        <Top>
          {isImpersonited ? <UserSecretIcon /> : <UserIcon />}
          <InfoWrapper>
            <Text block text={isImpersonited ? `${name} (${accountInfo.userCode})` : name} bold color="dustyBlue" />
            <Text block text={UserType[accountInfo.userType]} bold color="white" />
          </InfoWrapper>
          <ActionWrapper>
            <IconButton ghost>
              <ChevronRight />
            </IconButton>
          </ActionWrapper>
        </Top>
      </Link>
      <Divider />
      <Bottom>
        <UserCodeWrapper>
          {isImpersonited && <UserSmallIcon />}
          <Text block color="dustyBlue">
            {isImpersonited ? originatorUserCode : accountInfo.userCode}
          </Text>
        </UserCodeWrapper>
        <LogoutButton onClick={logout} id="ta-logout">
          <LogoutIcon />
          <Text text={t('Logout')} color="white" block />
        </LogoutButton>
      </Bottom>
    </div>
  )
}
