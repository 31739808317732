import { CompoundExpression } from '../../features/operations/types'
import { ApplicationModule } from '../../types'
import { SemanticSearchRequest } from '../filters/types'
import { BaseSelectionWithDestination } from '../portfolio/types'

export type PortfolioEnrichmentProposalRequest = {
  isSelectAll: boolean
  semantic?: SemanticSearchRequest
  expression: CompoundExpression | undefined
  excludedPortfoliosIds: string[] | undefined
  companyUnitIds?: string[]
  ignoredCompanyUnitIdsWhenIsSelectAll?: string[]
}

export type CompanyUnitEnrichmentProposalResponse = {
  noPortfolioUnits: number
  companiesCount: number
  remainingCreditsAfterCompaniesEnrichment: number
  requiredCreditsToEnrichCompanies: number
  currentCredits: number
  companiesToEnrichCount: number
  enrichedCompaniesCount: number
}

export type PortfolioEnrichmentProposalResponse = {
  selectedCompaniesCount: number
  selectedEnrichedCompaniesCount: number
  selectedNotEnrichedCompaniesCount: number
  currentCredits: number
  requiredCreditsToEnrichPortfolioCompanies: number
  remainingCreditsAfterPortfolioCompaniesEnrichment: number
}

export type CompanyProposalEnrichmentDto = {
  freeText?: string
  query: CompoundExpression | undefined
  semantic?: SemanticSearchRequest
  ignoredPortfolios?: string[]
  numberOfCompanies?: number
  orderBy?: string
  selectedIds?: string[]
  deselectedIds?: string[]
}

export type CompanyEnrichmentProposalRequest = {
  queryParameters: CompanyProposalEnrichmentDto
}

export type CompanyEnrichmentProposalResponse = {
  companiesCount: number
  enrichedCompaniesCount: number
  companiesToEnrichCount: number
  currentCredits: number
  requiredCreditsToEnrichCompanies: number
  remainingCreditsAfterCompaniesEnrichment: number
}

export enum TargetActionType {
  None = 'None',
  CreateCampaign = 'CreateCampaign'
}

export type SelectionUnit = {
  entityId: string
  companyUnitId: string
  identificationCode: string
  isEnriched: boolean
  sqlVersion: number
}

export type SelectionEnrichmentDto = {
  targetActionType?: TargetActionType
  query?: string
} & BaseSelectionWithDestination

export type SelectionEnrichmentRequest = {
  selection: SelectionEnrichmentDto
}

export type EnrichmentResp = {
  totalAmount: number
  isPortfolioEnriched: boolean
}

export enum RootActionType {
  None = 'None',
  DownloadExcel = 'DownloadExcel',
  SendListToSalesTool = 'SendListToSalesTool'
}

type ExistingSelection = {
  prospectingGridQuery: {
    query: CompoundExpression | undefined
    freeText?: string
    semantic?: SemanticSearchRequest
    ignoredPortfolios?: string[]
    orderBy?: string
    numberOfCompanies?: number
  }
  rootActionType: RootActionType
  tag?: string
  isMonitored?: boolean
  applicationModule: ApplicationModule
  selectedItems?: SelectionUnit[]
  itemsToIgnore?: SelectionUnit[]
  sourceContainerItemId?: string
  destinationContainerItemId?: string
}

type CreateNewSelection = {
  portfolioName: string
} & ExistingSelection

export type ExistingPortfolioEnrichmentRequest = {
  selection: ExistingSelection
}

export type NewPortfolioEnrichmentRequest = {
  selection: CreateNewSelection
}
