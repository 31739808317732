import { AxiosError, AxiosResponse } from 'axios'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { i18n as i18nFunction, TFunction } from 'i18next'
import { LoginResponse } from '../../../api/login/types'
import { getCountryCode } from '../../../features/config/selectors'
import { LoginError, ValidationError } from '../../../api/users/types'
import { UserActions } from '../../../features/user/actions'
import { DASHBOARD } from '../../../routes'
import { LoginFlow } from './types'

export const isUsernameNotSame = (currentUser: string, onBehalfOf: string) => {
  if (currentUser !== onBehalfOf) return undefined
  return 'UsernameDoesMatch'
}

export const useGetPasswordRules = (t: TFunction, i18n: i18nFunction) => {
  const countryCode = useSelector(getCountryCode)

  const passwordRulesKey = `login:PasswordRules_${countryCode}`
  const passwordRules = i18n.exists(passwordRulesKey) ? t(passwordRulesKey) : t('login:PasswordRules_ita')

  return passwordRules
}

const getErrorText = (t: TFunction, i18n: i18nFunction, validationError: ValidationError) => {
  if (validationError.errorCode && i18n.exists(`error:${validationError.errorCode}`)) {
    return t(`error:${validationError.errorCode}`)
  }

  if (validationError.errorMessage) {
    return validationError.errorMessage
  }

  return t('error:DisallowedPassword')
}

export const getValidationError = (err: AxiosError<LoginError>, t: TFunction, i18n: i18nFunction) => {
  const reason = err.response?.data?.response?.reason
  const status = err.response?.status
  if (reason === 'DisallowedPassword') {
    const validationErrors = err.response?.data?.response?.validationErrors
    const validationError = validationErrors?.[0]
    const errorToDisplay = validationError ? getErrorText(t, i18n, validationError) : t('error:DisallowedPassword')

    return errorToDisplay
  }
  if (reason === 'InvalidUsernameOldPassword' || status === 401) {
    return t('RenewPasswordForm.RenewPasswordFailed')
  }

  return t('error:ServerError')
}

export const useGetCallbacks = (
  goToStep: (index: number) => void,
  setError: (value: string | undefined) => void,
  setFlowStatus: (status: LoginFlow | undefined) => void,
  loginAs: boolean | undefined = false
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setToken = (data: LoginResponse, callback: () => void) => {
    dispatch(UserActions.tokenLoaded(data, navigate, callback, !!loginAs))
  }

  const { getValues } = useFormContext()

  const onError = (err: AxiosError<LoginError>) => {
    const values = getValues()
    const reason = err.response?.data?.response?.reason
    const status = err.response?.status
    if (values.forceLogoutPreviousSessions !== true && reason === 'PreviousActiveSession') {
      setFlowStatus('SessionExists')
      goToStep(2)
      return
    }

    if (reason === 'PasswordExpired') {
      setFlowStatus('RenewPassword')
      goToStep(2)
    }

    const msg = status === 401 ? 'login:LoginErrorMessage' : 'error:LoginSE'
    setError(msg)
  }

  const onSuccess = (response: AxiosResponse<LoginResponse>) => {
    setToken(response.data, () => {
      navigate({ pathname: DASHBOARD })
    })
  }

  return { onError, onSuccess }
}
