import _ from 'lodash'
import React, { useEffect } from 'react'
import { useGetPortfolioEnrichmentProposal } from '../../../api/enrichment'
import { EnrichCreditStatistics } from '../../../components/Statistic/EnrichCreditStatistic'
import { EnrichBeforeCampaignDialogData } from './types'

type Props = {
  dialogData: EnrichBeforeCampaignDialogData
  setRecapLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const EnrichBeforeCampaignRecap = ({ dialogData, setRecapLoading }: Props) => {
  const { portfolioId, expression, semantic, excludedPortfoliosIds } = dialogData

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setRecapLoading(true), [])

  const { data: proposal } = useGetPortfolioEnrichmentProposal(
    portfolioId,
    {
      isSelectAll: true,
      semantic,
      expression,
      excludedPortfoliosIds
    },
    { onSettled: () => setRecapLoading(false) }
  )

  if (!proposal) return null

  const remainingCredits = proposal ? proposal.remainingCreditsAfterPortfolioCompaniesEnrichment : 0
  const remainingOrZero = _.max([remainingCredits, 0])!
  return (
    <EnrichCreditStatistics
      totalCompanies={proposal.selectedCompaniesCount}
      enrichedCompanies={proposal.selectedEnrichedCompaniesCount}
      notEnrichedCompanies={proposal.selectedNotEnrichedCompaniesCount}
      usedCredits={proposal.requiredCreditsToEnrichPortfolioCompanies}
      remainingCredits={remainingOrZero}
    />
  )
}
