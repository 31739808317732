import _ from 'lodash'
import { Dictionary } from '../../utils/types'
import * as T from './types'

export const defaultInstanceConfig: T.DefaultInstanceConfig = {
  instanceCode: '',
  companyName: 'CRIF',
  countryCode: 'en',
  defaultLanguage: 'en-GB',
  ignoreErrorRedirect: false,
  showHiddenFeatures: false,
  showFreeSearchSuggestions: false,
  decimalPrecision: 2,
  mapFilterMaxRadius: 500,
  hideMapView: false,
  hideSemanticSearch: false,
  localUnitsFeatureEnabled: false,
  synonymsFeatureEnabled: true,
  localUnitsMaxCompanies: 300000,
  monitoredPortfolioNotificationTimestampOffsetInMinutes: 120,
  minCountOfSameStatusCompaniesForAnalyze: 20,
  mostPopularFiltersEnabled: false,
  hideForgotPassword: false,
  simplifiedUploadEnabled: false,
  mapZonesBasedOnCountryVisibilityEnabled: false,
  matomo: {
    urlBase: undefined,
    siteId: undefined
  },
  map: {
    default: {
      zoom: 5,
      center: {
        lat: 41.40318431987893,
        lng: 12.63427734375
      }
    }
  },
  auth: {
    automaticLogoutTime: 0,
    hasResetPassword: true,
    resetPasswordContactEmail: 'info@crif.com',
    resetPasswordContactPhone: '+39 051 4176111',
    timeToCancelAutomaticLogout: 60
  },
  availableLanguages: {
    'en-GB': { short: 'EN', full: 'English' }
  },
  languageMap: {
    'en-US': 'en-GB',
    en: 'en-GB',
    it: 'it-IT'
  },
  limits: {
    numberOfNotificationInDrawer: 4,
    maxNotesNumber: 5,
    newsPageSize: 3,
    documentsInPortfolioManagement: 10,
    latestDocumentWidgetPageSize: 5,
    campaignVisibleWaterfallChartsPageSize: 6,
    goalsPageSize: 10,
    productsPageSize: 10,
    queryListPageSize: 10,
    portfoliosPageSize: 20,
    prospectingCompaniesPageSize: 10,
    localUnitsPageSize: 30,
    campaignsPageSize: 20,
    campaignCompaniesPageSize: 20,
    numberOfWidgets: 12,
    notificationsLoadPageSize: 10,
    configurationPageSize: 10
  },
  salesChartColors: ['#802d8c', '#ffba14', '#326296', '#c586cd', '#95adc7'],
  companyStatus: [
    {
      id: 1,
      tKey: 'common:Prospect'
    },
    {
      id: 2,
      tKey: 'common:Lead'
    },
    {
      id: 3,
      tKey: 'common:Client'
    },
    {
      id: 4,
      tKey: 'common:Supplier'
    },
    {
      id: 5,
      tKey: 'common:Competitor'
    }
  ],
  allowedModules: [
    T.AppModule.PortfolioManagement,
    T.AppModule.Targeting,
    T.AppModule.Prospecting,
    T.AppModule.SalesTool,
    T.AppModule.API
  ],
  policyDocuments: [T.PolicyDocument.CookiesPolicy, T.PolicyDocument.PrivacyPolicy],
  chartsAndData: {
    salesChartColors: ['#802d8c', '#ffba14', '#326296', '#c586cd', '#95adc7'],
    showMacroAnalysis: true
  }
}

export const mergeWithDefaultInstance = (
  inst: T.DefaultInstanceConfigDto = {},
  def = defaultInstanceConfig
): T.DefaultInstanceConfig => {
  const policyDocuments = (inst.policyDocuments as T.PolicyDocument[] | undefined) ?? def.policyDocuments
  const allowedModules = (inst.allowedModules as T.AppModule[] | undefined) ?? def.allowedModules
  const availableLanguages =
    (inst.availableLanguages as Dictionary<T.LanguageName> | undefined) ?? def.availableLanguages
  // this is provided by the configuration only
  const chartsAndData = inst.chartsAndData as T.ConfigurationChartAndData
  const languageMap = (inst.languageMap as Dictionary<string> | undefined) ?? def.languageMap
  const map = (inst.map as Dictionary<T.ConfigurationMap> | undefined) ?? def.map
  return {
    ...def,
    ..._.omitBy(inst, _.isUndefined),
    policyDocuments,
    allowedModules,
    auth: { ...def.auth, ...inst.auth },
    availableLanguages,
    chartsAndData,
    languageMap,
    map
  }
}

export const getInitState = (): T.ConfigState => ({
  url: '',
  appConfigLoadStatus: T.LoadStatus.NOT_LOADED,
  userConfigLoadStatus: T.LoadStatus.NOT_LOADED,
  recaptcha: undefined,
  mapsKey: undefined,
  productFruitsKey: undefined,
  googleAnalyticsId: undefined,
  matomo: {
    urlBase: undefined,
    siteId: undefined
  },
  isSignalRStarted: false,
  default: undefined,
  instance: undefined,
  showReactQueryDevTool: false,
  samlAuth: undefined
})
