import _ from 'lodash'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { changeCurrency } from '../../../../api/users'
import { Dialog } from '../../../../components/Dialog'
import { getAvailableCurrenciesSelector } from '../../../../features/currencies/selectors'
import { UserActions } from '../../../../features/user/actions'
import { getCurrency } from '../../../../features/user/selectors'
import { ComponentProps } from '../../../../types'
import { Text } from '../../../../components/Text'
import { Radio } from '../../../../components/Radio'
import { HookRadioGroup, HookForm } from '../../../../components/Form'

type DialogProps = ComponentProps<typeof Dialog>

type Props = {
  close: () => void
}

type FormValues = {
  currency: string
}

export const CurrencyDialog = ({ close }: Props) => {
  const { t } = useTranslation(['account', 'common'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const availableCurrencies = useSelector(getAvailableCurrenciesSelector)
  const currentCurrency = useSelector(getCurrency)

  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    defaultValues: {
      currency: currentCurrency
    }
  })

  const mutation = useMutation(() => changeCurrency(watch('currency')), {
    onSuccess: () => {
      dispatch(UserActions.setUserCurrency(watch('currency')))
      dispatch(UserActions.changeCurrency(watch('currency'), true, location, navigate))
      close()
    }
  })

  const props: DialogProps = {
    loading: mutation.isLoading,
    visible: true,
    width: 900,
    title: t('account:Account.Currency.ChangeCurrencyTitle'),
    footer: '',
    onCancel: close,
    cancelButtonProps: { disabled: mutation.isLoading },
    cancelText: t('common:Cancel'),
    onOk: () => mutation.mutate(),
    okButtonProps: { disabled: mutation.isLoading },
    okText: t('common:Save')
  }

  return (
    <Dialog {...props}>
      <Text block margin={{ bottom: 20 }}>
        {t('Account.Currency.ChangeCurrency')}
      </Text>
      <HookForm handleSubmit={handleSubmit} onSubmit={mutation.mutate} isSubmitting={formState.isSubmitting}>
        <HookRadioGroup name="currency" control={control}>
          {_.map(availableCurrencies, ({ code, text }) => {
            return (
              <Radio key={code} value={code}>
                <Text primary bold>
                  {text}
                </Text>
              </Radio>
            )
          })}
        </HookRadioGroup>
        {/* enables submit on enter */}
        <input type="submit" hidden />
      </HookForm>
    </Dialog>
  )
}
