import axios from 'axios'
import { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { PersonEnrichmentProposal } from '../../../api/person/types'
import { enrichPerson, fetchPersonEnrichmentProposal } from '../../../api/person'
import { EnrichPersonDialogData, EnrichPersonDialogView } from './types'
import { DialogActions } from '../../../features/dialog/actions'
import { getPersonKeys } from '../../../api/person/queries'

export function usePersonEnrichmentProposal(personId: string) {
  const [enrichmentInfo, setEnrichmentInfo] = useState<PersonEnrichmentProposal>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    const cancelToken = axios.CancelToken.source()
    fetchPersonEnrichmentProposal(personId, cancelToken.token)
      .then(({ data: availableCredits }) => {
        setEnrichmentInfo({
          remainingCredits: availableCredits - 1,
          usedCredits: 1
        })
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => cancelToken.cancel()
  }, [personId, setLoading, setEnrichmentInfo])

  return {
    loading,
    enrichmentInfo,
    proposalFailed: isError
  }
}

export function useEnrichPerson(personId: string) {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const updateDialogData = (data: Partial<EnrichPersonDialogData>) =>
    dispatch(DialogActions.updateDialogData('EnrichPerson', data))

  const { mutate, isLoading: isSubmitting } = useMutation(enrichPerson, {
    onSuccess: () => {
      updateDialogData({
        view: EnrichPersonDialogView.Recap
      })
      queryClient.invalidateQueries(getPersonKeys.FetchPerson(personId))
    },
    onError: () => {
      updateDialogData({
        view: EnrichPersonDialogView.Fail
      })
    }
  })

  return {
    enrichPerson: mutate,
    isSubmitting
  }
}
