import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ReactComponent as ManagementIcon } from '../../assets/icons/case.svg'
import { ReactComponent as ProspectingIcon } from '../../assets/icons/ordered.svg'
import { ReactComponent as ApiIcon } from '../../assets/icons/settings.svg'
import { ReactComponent as TargetingIcon } from '../../assets/icons/target-1.svg'
import { ReactComponent as SalesIcon } from '../../assets/icons/up.svg'
import { ReactComponent as OperationsIcon } from '../../assets/icons/icon-s-activity.svg'
import { ReactComponent as PnrrIcon } from '../../assets/icons/pnrr.svg'
import { Claim } from '../../authorization'
import { getCountryCode, getVisibleClaims } from '../../features/config/selectors'
import { isLoggedIn } from '../../features/user/selectors'
import { useBranding } from '../../hooks/useBranding'
import * as routes from '../../routes'
import { MenuItemDef } from './types'

export function useMainMenuItems() {
  const { t } = useTranslation('header')

  const { pathname } = useLocation()
  const countryCode = useSelector(getCountryCode)
  const visibleClaims = useSelector(getVisibleClaims)
  const isUserLogged = useSelector(isLoggedIn)
  const { allowedModules } = useBranding()

  const items = React.useMemo(() => {
    const ret: MenuItemDef[] = []
    if (!isUserLogged) return ret

    const isPortfolioManagementVisible = allowedModules.PortfolioManagement && visibleClaims & Claim.PortfolioManagement
    const isTargetingVisible =
      countryCode === 'deu'
        ? allowedModules.Targeting || visibleClaims & Claim.Targeting
        : allowedModules.Targeting && visibleClaims & Claim.Targeting
    const isProspectingVisible = allowedModules.Prospecting && visibleClaims & Claim.Prospecting
    const isSalesToolVisible = allowedModules.SalesTool && visibleClaims & Claim.SalesTool
    const isApiVisible = allowedModules.API
    const isOperationsVisible = allowedModules.Operations && visibleClaims & Claim.Operations
    const isPnrrVisible = allowedModules.PNRR && visibleClaims & Claim.Pnrr

    if (isPortfolioManagementVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.PORTFOLIO_MANAGEMENT_MODULE),
        icon: ManagementIcon,
        text: t('PortfolioManagement'),
        link: routes.PORTFOLIO_MANAGEMENT
      })
    }
    if (isTargetingVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.TARGETING_MODULE),
        icon: TargetingIcon,
        text: t('Targeting'),
        link: routes.TARGETING
      })
    }
    if (isProspectingVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.PROSPECTING_MODULE),
        icon: ProspectingIcon,
        text: t('Prospecting'),
        link: routes.PROSPECTING
      })
    }
    if (isOperationsVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.OPERATIONS),
        icon: OperationsIcon,
        text: t('Operations'),
        link: routes.OPERATIONS
      })
    }
    if (isSalesToolVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.SALESTOOL_MODULE),
        icon: SalesIcon,
        text: t('SalesTool'),
        link: routes.SALESTOOL
      })
    }
    if (isPnrrVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.PNRR),
        icon: PnrrIcon,
        text: t('Pnrr'),
        link: routes.PNRR
      })
    }
    if (isApiVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.API_MODULE),
        icon: ApiIcon,
        text: t('API'),
        link: routes.API
      })
    }
    return ret
  }, [pathname, allowedModules, visibleClaims, isUserLogged, countryCode, t])

  return items
}
