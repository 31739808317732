import React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Error } from '../../types'
import { ERROR } from '../../routes'
import { canRedirectOnError } from '../../features/config/selectors'
import { DialogActions } from '../../features/dialog/actions'

type Props = {
  children: React.ReactNode
  pushToHistory: (e: Error) => void
  canRedirect: boolean
}

class ErrorBoundaryComponent extends React.PureComponent<Props> {
  componentDidCatch(e: Error) {
    const { pushToHistory, canRedirect } = this.props
    if (canRedirect) pushToHistory(e)
  }

  render() {
    const { children } = this.props
    return children
  }
}

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const canRedirect = useSelector(canRedirectOnError)

  const pushToHistory = (e: Error) =>
    batch(() => {
      dispatch(DialogActions.closeDialog())
      navigate(
        {
          pathname: ERROR
        },
        {
          state: {
            text: e.message
          }
        }
      )
    })

  return (
    <ErrorBoundaryComponent canRedirect={canRedirect} pushToHistory={pushToHistory}>
      {children}
    </ErrorBoundaryComponent>
  )
}
