import { Reducer } from 'redux'
import { FiltersActionType } from '../filters/actions'
import * as actions from './actions'

export type PortfolioDetailPageState = {
  visibleColumns: string[]
}

export const initState: PortfolioDetailPageState = {
  visibleColumns: []
}

export const portfolioDetailPageReducer: Reducer<
  PortfolioDetailPageState,
  actions.PortfolioDetailViewActions | FiltersActionType<'reset'>
> = (state = initState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
