import { useQuery } from '@tanstack/react-query'
import { ApplicationModule, CompanyDetailsType } from '../../types'
import { apiCallWithCancel } from '../../utils/helpers'
import { getCampaignCompanyPopupData, getPortfolioCompanyPopupData, getProspectingCompanyPopupData } from './api'
import { CampaignCompanyPopupDataRequest } from './types'

export const loadCompanyPopupDataKeys = {
  All: () => [{ level1: 'getCompanyPopupData' }] as const,

  ForModule: (module: ApplicationModule) => [{ ...loadCompanyPopupDataKeys.All()[0], module }] as const,
  ForCompany: (companyId: string, module: ApplicationModule) =>
    [{ ...loadCompanyPopupDataKeys.ForModule(module)[0], companyId }] as const,
  ForCompanyWithType: (companyId: string, module: ApplicationModule, type: CompanyDetailsType) =>
    [{ ...loadCompanyPopupDataKeys.ForCompany(companyId, module)[0], type }] as const,

  ForCampaignCompanyData: () =>
    [{ ...loadCompanyPopupDataKeys.All()[0], level2: 'getCampaignCompanyPopupData' }] as const,
  ForCampaign: (campaignId: string) => [{ ...loadCompanyPopupDataKeys.All()[0], campaignId }] as const,
  ForCampaignCompany: (companyId: string, campaignId: string) =>
    [{ ...loadCompanyPopupDataKeys.ForCampaign(campaignId)[0], companyId }] as const,
  ForCampaignCompanyWithType: (campaignId: string, data: CampaignCompanyPopupDataRequest) =>
    [{ ...loadCompanyPopupDataKeys.All()[0], campaignId, ...data }] as const
}

export const useLoadCompanyPopupData = (
  companyId: string,
  module: ApplicationModule,
  pageType = CompanyDetailsType.Map
) =>
  useQuery(
    loadCompanyPopupDataKeys.ForCompanyWithType(companyId, module, pageType),
    ({ queryKey: [queryKeys] }) =>
      apiCallWithCancel(token => {
        const promise =
          queryKeys.module === ApplicationModule.PortfolioManagement
            ? getPortfolioCompanyPopupData({ companyId: queryKeys.companyId, pageType: queryKeys.type }, token)
            : getProspectingCompanyPopupData({ companyId: queryKeys.companyId, pageType: queryKeys.type }, token)
        return promise.then(res => res.data)
      }, 'Company popup request cancelled'),
    {
      refetchOnMount: true
    }
  )

export const useLoadCampaignCompanyPopupData = (campaignId: string, data: CampaignCompanyPopupDataRequest) =>
  useQuery(
    loadCompanyPopupDataKeys.ForCampaignCompanyWithType(campaignId, data),
    ({ queryKey: [queryKeys] }) =>
      apiCallWithCancel(
        token => getCampaignCompanyPopupData(queryKeys.campaignId, data, token).then(res => res.data),
        'Company popup request cancelled'
      ),
    {
      refetchOnMount: true
    }
  )
