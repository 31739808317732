import _ from 'lodash'
import { FilterType, SemanticSearch } from '../filters/types'
import { ChartDataResponse, ChartViewType, RequestCommon } from '../../api/targeting/types'
import { TargetingFilter, TargetingFilterId } from './types'
import { CompoundExpression, Expression, FieldOperator } from '../operations/types'
import { getDefaultRootExpression, getEmptyFieldExpression } from '../operations/utils'
import { convertCompoundExpressionForBackend } from '../filters/utils'
import { CompanyHqFilterData } from '../types'
import { formatSemanticSearchForRequest } from '../mappers'

export const createTargetingFilter = (name: TargetingFilterId, type: FilterType, value: string | number) => ({
  fieldId: name,
  type,
  value
})

export const addInvestmentFilters = (
  expression: CompoundExpression,
  investmentFilters: Expression[]
): CompoundExpression => {
  return {
    ...expression,
    childExpressions: _.concat(expression.childExpressions, investmentFilters)
  }
}

export const addHQFilter = (expression: CompoundExpression): CompoundExpression => {
  const hqFilter = getEmptyFieldExpression(CompanyHqFilterData.fieldId, CompanyHqFilterData.value, FieldOperator.Equals)

  return {
    ...expression,
    childExpressions: _.concat(expression.childExpressions, hqFilter)
  }
}

export const createTargetingQuery = (selectedStatus?: TargetingFilter) =>
  selectedStatus
    ? {
        ...getDefaultRootExpression(),
        childExpressions: [getEmptyFieldExpression(selectedStatus.fieldId, selectedStatus.value)]
      }
    : undefined

export const createFiltersRequest = (
  expression: CompoundExpression,
  excludedPortfoliosIds: string[] | undefined,
  semanticSearch: SemanticSearch,
  selectedPortfoliosIds: string[],
  selectedStatus?: TargetingFilter
) => {
  const requestWithQuery: RequestCommon = {
    selectedPortfolios: selectedPortfoliosIds,
    query: addHQFilter(convertCompoundExpressionForBackend(expression)),
    semantic: formatSemanticSearchForRequest(semanticSearch),
    ignoredPortfolios: _.isEmpty(excludedPortfoliosIds) ? undefined : excludedPortfoliosIds,
    targetingQuery: createTargetingQuery(selectedStatus)
  }

  return requestWithQuery
}

export const chartHasData = (chart: ChartDataResponse | undefined) => {
  if (!chart) return false
  switch (chart.chartType) {
    case ChartViewType.Map:
      return true
    case ChartViewType.HistogramChart:
    case ChartViewType.BarChart:
      return _.some(chart.data.buckets, b => b.count !== 0)
    case ChartViewType.AreaChartMultiChoice:
    case ChartViewType.GroupedBarChart:
      return _.some(chart.data.buckets, b => _.some(b.data, i => i.count !== 0))
    case ChartViewType.PieChart:
    case ChartViewType.TreeMap:
      return !_.isEmpty(chart.data.buckets)
    default:
      return false
  }
}
