import _ from 'lodash'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  getLocalUnitsFilterInfo,
  getFiltersStructure,
  getFiltersDefinitions,
  getDomainCodesTree,
  getDomainNodesDataNotEmpty
} from './api'
import {
  FilterStructureModuleRequest,
  FilterDefinitionRequestType,
  DomainNodesDataRequest,
  SearchDomainNodesRequest,
  SemanticSearchRequest,
  LocalUnitsFilterInfo,
  DomainNodesDataNotEmptyRequest,
  DomainNodeDataNotEmptyResponse,
  DomainNodesDataResponse,
  FiltersDefinitionRequest
} from './types'
import { cacheAllTheTime } from '../../features/queryclient'
import {
  codesWithSelectorsByFieldExpressions,
  codesWithSelectorsByPath,
  codesWithSelectorsByValueOverload
} from '../../utils/treeRequest'
import { getProspectingDomainNodesData, getProspectingSearchDomainNodesDataPath } from '../prospecting'
import { getTargetingDomainNodesData, getTargetingSearchDomainNodesDataPath } from '../targeting/api'
import { getPortfolioDomainNodesData, getPortfolioSearchDomainNodesDataPath } from '../portfolio'
import { getSalestoolDomainNodesData, getSalestoolSearchDomainNodesDataPath } from '../salestool'
import { CodeWithSelector, DomainFilterSelector } from '../../features/filters/types/domainFilter'
import { KeyTree, TreePath } from '../../utils/tree'
import { mapKeyTree } from '../../features/mappers'
import { CompoundExpression, FieldExpression } from '../../features/operations/types'
import { convertCompoundExpressionForBackend } from '../../features/filters/utils'

type WithParentPath = {
  parentPath: TreePath
}

type WithDomainCodeTree = {
  domainCodeTree: KeyTree | undefined
}

type WithSelectedValues = {
  selectedValues: string[]
}

type WithSearchValue = {
  searchValue: string
}

type WithExactSearchOption = {
  exactSearch: boolean | undefined
}

type WithChildExpressions = {
  childExpressions: FieldExpression[] | undefined
}

export type GetDomainNodesDataBase = {
  module: FilterStructureModuleRequest
  fieldId: string | undefined
  filterDefinitionSelectors: DomainFilterSelector[] | undefined
  isSingleSelectionEnabled: boolean
  singleSelectionTargetJsonPath: string | undefined
  entityId: string | undefined
  excludedPortfolios: string[] | undefined
  semanticForRequest: SemanticSearchRequest | undefined
  selectedTargetingPortfolios: string[] | undefined
  expression: CompoundExpression | undefined
  targetingQuery: CompoundExpression | undefined
}

type GetDomainNodesData = GetDomainNodesDataBase & WithParentPath

type DomainNodesDataWithDataType = GetDomainNodesData & WithDomainCodeTree

type GetSelectedDomainNodesData = GetDomainNodesDataBase & WithSelectedValues & WithChildExpressions

type SelectedDomainNodesDataByFieldExpressionType = GetDomainNodesDataBase & WithSelectedValues & WithDomainCodeTree

type SearchDomainNodesData = GetDomainNodesDataBase & WithSearchValue & WithExactSearchOption

export const getFiltersKeys = {
  All: () => [{ level1: 'filters' }] as const,
  FilterStructure: () => [{ ...getFiltersKeys.All()[0], level2: 'getFiltersStructure' }] as const,
  FilterStructureForModuleAndEntity: (module: FilterStructureModuleRequest, entityId: string | undefined) =>
    [{ ...getFiltersKeys.FilterStructure()[0], module, entityId }] as const,

  FiltersDefinitions: () => [{ ...getFiltersKeys.All()[0], level2: 'getFiltersDefinitions' }] as const,
  SpecificFilterDefinition: (
    fieldId: string | undefined,
    fieldDefinitionType: FilterDefinitionRequestType | undefined,
    portfolioIds: string[] | undefined,
    campaignId: string | undefined,
    groupId: string | undefined
  ) =>
    [
      { ...getFiltersKeys.FiltersDefinitions()[0], fieldId, fieldDefinitionType, portfolioIds, campaignId, groupId }
    ] as const,

  DomainCodesTree: () => [{ ...getFiltersKeys.All()[0], level2: 'getDomainCodesTree' }] as const,
  DomainCodesTreeForModuleAndFieldId: (
    module: FilterStructureModuleRequest,
    fieldId: string | undefined,
    filterSelectors: DomainFilterSelector[] | undefined,
    entityId: string | undefined
  ) => [{ ...getFiltersKeys.DomainCodesTree()[0], module, fieldId, filterSelectors, entityId }] as const,

  DomainNodesData: () => [{ ...getFiltersKeys.All()[0], level2: 'getDomainNodesData' }] as const,
  DomainNodesDataWithData: (props: DomainNodesDataWithDataType) =>
    [
      {
        ...getFiltersKeys.DomainNodesData()[0],
        ...props
      }
    ] as const,

  SelectedDomainNodesDataByFieldExpression: (props: SelectedDomainNodesDataByFieldExpressionType) =>
    [
      {
        ...getFiltersKeys.DomainNodesData()[0],
        level3: 'SelectedByFieldExpression',
        ...props
      }
    ] as const,
  SearchDomainNodesData: (props: SearchDomainNodesData) =>
    [
      {
        ...getFiltersKeys.DomainNodesData()[0],
        level3: 'search',
        ...props
      }
    ] as const,
  GetLocalUnitsFilterInfo: () => [{ ...getFiltersKeys.All()[0], level2: 'getLocalUnitsFilterInfo' }] as const,
  DomainNodesDataNotEmpty: (data: DomainNodesDataNotEmptyRequest) =>
    [{ ...getFiltersKeys.All()[0], level2: 'getDomainNodesDataNotEmpty', data }] as const
}

export const useGetFilterStructure = (module: FilterStructureModuleRequest, entityId?: string, enabled = true) =>
  useQuery(
    getFiltersKeys.FilterStructureForModuleAndEntity(module, entityId),
    ({ queryKey: [queryKeys] }) => getFiltersStructure(queryKeys.module, queryKeys.entityId).then(r => r.data),
    {
      enabled,
      ...cacheAllTheTime
    }
  )

export const useGetFiltersDefinition = ({
  fieldId,
  fieldDefinitionType,
  portfolioIds,
  campaignId,
  groupId,
  enabled
}: FiltersDefinitionRequest) => {
  return useQuery(
    getFiltersKeys.SpecificFilterDefinition(fieldId, fieldDefinitionType, portfolioIds, campaignId, groupId),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.fieldId === undefined || queryKeys.fieldDefinitionType === undefined) return undefined

      return getFiltersDefinitions({
        evaluateBounds: true,
        groupsOfFilters: [
          {
            filterDefinitions: [
              {
                id: queryKeys.fieldId,
                definitionType: queryKeys.fieldDefinitionType
              }
            ],
            // temporary solution which will be reworked by GLOB-8288
            groupId: queryKeys.fieldDefinitionType === 'CustomVar' ? 'userFilters' : queryKeys.groupId ?? '0'
          }
        ],
        portfolioIds: queryKeys.portfolioIds,
        campaignId: queryKeys.campaignId
      }).then(r => r.data)
    },
    { ...cacheAllTheTime, enabled, select: data => data?.groupsOfFilters[0].filterDefinitions[0] }
  )
}

export const useGetDomainCodeTree = (
  module: FilterStructureModuleRequest,
  fieldId: string | undefined,
  filterDefinitionSelectors: DomainFilterSelector[] | undefined,
  entityId?: string
) => {
  return useQuery(
    getFiltersKeys.DomainCodesTreeForModuleAndFieldId(module, fieldId, filterDefinitionSelectors, entityId),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.fieldId === undefined || queryKeys.filterSelectors === undefined) return undefined
      const resp = getDomainCodesTree(
        _.map(queryKeys.filterSelectors, filterDefinitionSelector => filterDefinitionSelector.domainType),
        queryKeys.entityId,
        queryKeys.module
      ).then(r => mapKeyTree(r.data))
      return resp
    },
    cacheAllTheTime
  )
}

const isDomainNodesDataDisabled = (
  fieldId?: string,
  filterDefinitionSelectors?: DomainFilterSelector[],
  domainCodeTree?: KeyTree
) => {
  return fieldId === undefined || filterDefinitionSelectors === undefined || domainCodeTree === undefined
}

const getDomainNodesDataBasedOnModule = (
  module: FilterStructureModuleRequest,
  requestData: DomainNodesDataRequest,
  entityId?: string
) => {
  if (module === FilterStructureModuleRequest.Prospecting || module === FilterStructureModuleRequest.Operations)
    return getProspectingDomainNodesData(requestData)
  if (module === FilterStructureModuleRequest.Targeting) return getTargetingDomainNodesData(requestData)
  if (module === FilterStructureModuleRequest.PortfolioManagement) {
    if (entityId === undefined) throw new Error('Get portfolioDomainNodesData: Portfolio id is missing')
    return getPortfolioDomainNodesData(requestData, entityId)
  }
  if (module === FilterStructureModuleRequest.SalesTool) {
    if (entityId === undefined) throw new Error('Get salestoolDomainNodesData: Campaign id is missing')
    return getSalestoolDomainNodesData(requestData, entityId)
  }

  throw new Error('Unexpected module for domainNodesData request')
}

export const useGetDomainNodesData = (
  { module, fieldId, filterDefinitionSelectors, entityId, expression, ...rest }: GetDomainNodesData,
  queryOptions?: UseQueryOptions<
    DomainNodesDataResponse | undefined,
    unknown,
    DomainNodesDataResponse,
    ReturnType<typeof getFiltersKeys.DomainNodesDataWithData>
  >
) => {
  const { data: domainCodeTree } = useGetDomainCodeTree(module, fieldId, filterDefinitionSelectors, entityId)
  const convertedExpression = expression ? convertCompoundExpressionForBackend(expression) : expression

  return useQuery(
    getFiltersKeys.DomainNodesDataWithData({
      module,
      domainCodeTree,
      fieldId,
      filterDefinitionSelectors,
      entityId,
      expression: convertedExpression,
      ...rest
    }),
    ({ queryKey: [queryKeys] }) => {
      if (isDomainNodesDataDisabled(queryKeys.fieldId, queryKeys.filterDefinitionSelectors, queryKeys.domainCodeTree))
        return undefined

      const requestData: DomainNodesDataRequest = {
        filterHierarchy: queryKeys.fieldId as string,
        nodes: codesWithSelectorsByPath(
          queryKeys.domainCodeTree!,
          queryKeys.filterDefinitionSelectors!,
          queryKeys.parentPath
        ) as CodeWithSelector[],
        query: queryKeys.expression,
        ignoredPortfolios: queryKeys.excludedPortfolios ?? [],
        semantic: queryKeys.semanticForRequest,
        selectedPortfolios: queryKeys.selectedTargetingPortfolios,
        isSingleSelectionEnabled: queryKeys.isSingleSelectionEnabled,
        singleSelectionTargetJsonPath: queryKeys.singleSelectionTargetJsonPath,
        targetingQuery: queryKeys.targetingQuery
      }
      const resp = getDomainNodesDataBasedOnModule(queryKeys.module, requestData, queryKeys.entityId).then(r => r.data)
      return resp
    },
    queryOptions
      ? {
          ...cacheAllTheTime,
          ...queryOptions
        }
      : cacheAllTheTime
  )
}

export const useGetSelectedDomainNodesDataByFieldExpression = ({
  module,
  fieldId,
  filterDefinitionSelectors,
  childExpressions,
  entityId,
  expression,
  ...rest
}: GetSelectedDomainNodesData) => {
  const { data: domainCodeTree } = useGetDomainCodeTree(module, fieldId, filterDefinitionSelectors, entityId)
  const convertedExpression = expression ? convertCompoundExpressionForBackend(expression) : expression

  return useQuery(
    getFiltersKeys.SelectedDomainNodesDataByFieldExpression({
      module,
      domainCodeTree,
      fieldId,
      filterDefinitionSelectors,
      entityId,
      expression: convertedExpression,
      ...rest
    }),
    ({ queryKey: [queryKeys] }) => {
      if (isDomainNodesDataDisabled(queryKeys.fieldId, queryKeys.filterDefinitionSelectors, queryKeys.domainCodeTree))
        return undefined

      const nodes = childExpressions
        ? codesWithSelectorsByFieldExpressions(childExpressions, queryKeys.filterDefinitionSelectors!)
        : codesWithSelectorsByValueOverload(queryKeys.selectedValues, queryKeys.filterDefinitionSelectors!)

      if (_.isEmpty(nodes)) return []

      const requestData: DomainNodesDataRequest = {
        filterHierarchy: queryKeys.fieldId as string,
        nodes,
        ignoredPortfolios: queryKeys.excludedPortfolios ?? [],
        semantic: queryKeys.semanticForRequest,
        selectedPortfolios: queryKeys.selectedTargetingPortfolios,
        isSingleSelectionEnabled: queryKeys.isSingleSelectionEnabled,
        singleSelectionTargetJsonPath: queryKeys.singleSelectionTargetJsonPath,
        query: queryKeys.expression,
        targetingQuery: queryKeys.targetingQuery
      }
      const resp = getDomainNodesDataBasedOnModule(queryKeys.module, requestData, queryKeys.entityId).then(r => r.data)
      return resp
    }
  )
}

const getSearchDomainNodesDataDisabled = (
  searchValue: string,
  fieldId?: string,
  filterDefinitionSelectors?: DomainFilterSelector[]
) => {
  return fieldId === undefined || filterDefinitionSelectors === undefined || searchValue === ''
}

const getSearchDomainNodesDataBasedOnModule = (
  module: FilterStructureModuleRequest,
  requestData: SearchDomainNodesRequest,
  entityId?: string
) => {
  if (module === FilterStructureModuleRequest.Prospecting || module === FilterStructureModuleRequest.Operations)
    return getProspectingSearchDomainNodesDataPath(requestData)
  if (module === FilterStructureModuleRequest.Targeting) return getTargetingSearchDomainNodesDataPath(requestData)
  if (module === FilterStructureModuleRequest.PortfolioManagement) {
    if (entityId === undefined) throw new Error('Get portfolioDomainNodesData: Portfolio id is missing')
    return getPortfolioSearchDomainNodesDataPath(entityId, requestData)
  }
  if (module === FilterStructureModuleRequest.SalesTool) {
    if (entityId === undefined) throw new Error('Get salestoolDomainNodesData: Campaign id is missing')
    return getSalestoolSearchDomainNodesDataPath(requestData, entityId)
  }

  throw new Error('Unexpected module for domainNodesData request')
}

export const useGetSearchDomainNodesData = ({ expression, ...rest }: SearchDomainNodesData) => {
  const convertedExpression = expression ? convertCompoundExpressionForBackend(expression) : expression

  return useQuery(
    getFiltersKeys.SearchDomainNodesData({
      expression: convertedExpression,
      ...rest
    }),
    ({ queryKey: [queryKeys] }) => {
      if (
        getSearchDomainNodesDataDisabled(queryKeys.searchValue, queryKeys.fieldId, queryKeys.filterDefinitionSelectors)
      )
        return undefined

      const requestData: SearchDomainNodesRequest = {
        filterHierarchy: queryKeys.fieldId as string,
        domainText: queryKeys.searchValue,
        selectors: queryKeys.filterDefinitionSelectors!,
        ignoredPortfolios: queryKeys.excludedPortfolios ?? [],
        query: queryKeys.expression,
        semantic: queryKeys.semanticForRequest,
        selectedPortfolios: queryKeys.selectedTargetingPortfolios ?? [],
        isSingleSelectionEnabled: queryKeys.isSingleSelectionEnabled,
        singleSelectionTargetJsonPath: queryKeys.singleSelectionTargetJsonPath,
        targetingQuery: queryKeys.targetingQuery,
        exactSearch: queryKeys.exactSearch
      }
      const resp = getSearchDomainNodesDataBasedOnModule(queryKeys.module, requestData, queryKeys.entityId).then(
        r => r.data
      )
      return resp
    },
    cacheAllTheTime
  )
}

export const useGetSetLocalUnitsFilterInfo = (
  options?: UseQueryOptions<
    LocalUnitsFilterInfo | undefined,
    unknown,
    LocalUnitsFilterInfo | undefined,
    ReturnType<typeof getFiltersKeys.GetLocalUnitsFilterInfo>
  >
) => {
  return useQuery(getFiltersKeys.GetLocalUnitsFilterInfo(), () => getLocalUnitsFilterInfo().then(res => res.data), {
    ...options,
    ...cacheAllTheTime
  })
}

export const useGetDomainNodesDataNotEmpty = (
  data: DomainNodesDataNotEmptyRequest,
  options?: UseQueryOptions<
    DomainNodeDataNotEmptyResponse | undefined,
    unknown,
    DomainNodeDataNotEmptyResponse,
    ReturnType<typeof getFiltersKeys.DomainNodesDataNotEmpty>
  >
) => {
  const convertedExpression = data.query ? convertCompoundExpressionForBackend(data.query) : data.query
  const convertedData = { ...data, query: convertedExpression }

  return useQuery(
    getFiltersKeys.DomainNodesDataNotEmpty(convertedData),
    ({ queryKey: [queryKeys] }) => getDomainNodesDataNotEmpty(queryKeys.data),
    {
      ...options,
      ...cacheAllTheTime
    }
  )
}
