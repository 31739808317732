import { AssignmentType } from '../../../features/salestool/table/types'

export enum AddPortfolioViewState {
  AddPortfolioView = 'AddPortfolioView',
  AddPortfolioDone = 'AddPortfolioDone',
  AddPortfolioError = 'AddPortfolioError',
  AddCompaniesDone = 'AddCompaniesDone',
  AddCompaniesWithChangedCustomVariablesStructureDone = 'AddCompaniesWithChangedCustomVariablesStructureDone',
  AddCompaniesError = 'AddCompaniesError'
}

export enum DuplicateCompanyViewState {
  DuplicateCompanyView = 'DuplicateCompanyView',
  DuplicateCompanyViewSelection = 'DuplicateCompanyViewSelection',
  DuplicateCompanyDone = 'DuplicateCompanyDone',
  DuplicateCompanyError = 'DuplicateCompanyError'
}

export type GroupActionItem = {
  assignmentType: AssignmentType
  title: string
  dropDownText: string
  dropDownItems: JSX.Element[]
}
