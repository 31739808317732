/* eslint-disable import/no-default-export */
export default {
  Support: {
    Email: 'info@crif.com',
    Phone: '+39 051 4176111'
  },
  ImprintPolicy: 'Impressum',
  PrivacyPolicy: 'Privacy policy',
  CookiesPolicy: 'Cookies policy',
  Contact: 'Contact Us'
}
