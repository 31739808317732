import React from 'react'
import _ from 'lodash'
import { Switch as AntSwitch } from 'antd3'
import { SwitchProps, SwitchSize } from 'antd3/lib/switch'
import styled, { css } from 'styled-components/macro'
import { Omit } from '../../utils/types'
import { BasicTooltip } from '../Tooltip'

type SwitchSizeEx = SwitchSize | 'large'

type SwitchWrapperProps = {
  opacity?: number
}

const SwitchWrapper = styled.div.withConfig<SwitchWrapperProps>({ shouldForwardProp: p => p !== 'opacity' })`
  display: inline-block;
  ${({ opacity }) => (!_.isNil(opacity) ? `opacity: ${opacity};` : '')}

  .expander {
    height: 0;
    margin-right: 40px;
    color: transparent;
  }
`

const StyledSwitch = styled(AntSwitch).withConfig<SwitchProps & { sizeEx?: SwitchSizeEx }>({
  shouldForwardProp: p => p !== 'sizeEx'
})`
  &.ant-switch {
    width: 100%;
    font-family: 'Lato', sans-serif;
    background-color: ${({ theme }) => theme.colors.almostWhite};

    .ant-switch-inner {
      color: ${({ theme }) => theme.colors.grayishBrown};
    }

    ::after {
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.colors.veryLightPink};
    }

    &.ant-switch-checked {
      background-color: ${({ theme }) => theme.colors.mainPressed};

      .ant-switch-inner {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    ${props =>
      props.sizeEx === 'large' &&
      css`
        height: 32px;

        .ant-switch-inner {
          margin-left: 32px;
          margin-right: 8px;
          font-size: 12px;
          line-height: 1.33;
        }

        ::after {
          top: 3px;
          left: 3px;
          width: 24px;
          height: 24px;
        }

        &.ant-switch-checked {
          .ant-switch-inner {
            margin-left: 8px;
            margin-right: 32px;
          }

          ::after {
            left: 100%;
            margin-left: -3px;
          }
        }

        .ant-switch-loading-icon {
          width: 24px;
          height: 24px;
          svg {
            fill: ${({ theme }) => theme.colors.main};
            position: absolute;
            top: 3px;
            width: 24px;
            height: 24px;
            left: 2px;
          }
        }
      `}
  }
`

type Props = {
  size?: SwitchSizeEx
  tooltip?: string
} & SwitchWrapperProps &
  Omit<SwitchProps, 'size'>

export const Switch: React.FC<Props> = ({
  className,
  size,
  checkedChildren,
  unCheckedChildren,
  opacity,
  tooltip,
  ...props
}) => {
  return (
    <SwitchWrapper opacity={opacity}>
      <div className="expander">{checkedChildren}</div>
      <div className="expander">{unCheckedChildren}</div>
      <BasicTooltip title={tooltip}>
        <StyledSwitch
          {...props}
          className={className}
          size={size === 'large' ? undefined : size}
          sizeEx={size}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
        />
      </BasicTooltip>
    </SwitchWrapper>
  )
}
