/* eslint-disable import/no-default-export */
export default {
  Support: {
    Email: 'helpdesk.cz@crif.com',
    Phone: '+420 737 152 074'
  },
  PrivacyPolicy: 'Zásady ochrany osobních údajů',
  CookiesPolicy: 'Pravidla používání cookies',
  Contact: 'Kontaktujte nás'
}
