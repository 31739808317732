import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { DialogActions } from '../../../../features/dialog/actions'
import { DialogType } from '../../../../features/dialog/types'
import { SemanticSearch } from '../../../../features/filters/types'
import { CompoundExpression } from '../../../../features/operations/types'
import { useTargetingContext } from '../../../../features/targeting/context'

const translationKey: { [key in DialogType]?: string } = {
  LoadTargetingPortfolio: 'LoadPortfolio.DialogTitle',
  LoadTargetingQuery: 'LoadQuery.DialogTitle'
}

type ContextData = {
  expression: CompoundExpression
  semanticSearch?: SemanticSearch
  excludedPortfoliosIds?: string[]
  selectedPortfoliosIds?: string[]
}

export const useSaveBeforeContinuing = (dialogName: DialogType, contextData: ContextData) => {
  const { t } = useTranslation('targeting')
  const { setQuerySaved } = useTargetingContext()

  const [saveSkipped, setSaveSkipped] = useState(false)
  const dispatch = useDispatch()

  const close = () => dispatch(DialogActions.closeDialog())

  const title = translationKey[dialogName]

  const saveBeforeContinuingProps = {
    title: title ? t(title) : '',
    close,
    saveQuery: () => {
      close()
      dispatch(
        DialogActions.showDialogWithData('SaveTargetingQuery', {
          activeDialogBeforeSaving: dialogName,
          noTransition: true,
          setQuerySaved,
          contextData
        })
      )
    },
    skipSaveQuery: () => setSaveSkipped(true)
  }

  return [saveSkipped, saveBeforeContinuingProps] as [boolean, typeof saveBeforeContinuingProps]
}
