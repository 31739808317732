import React from 'react'
import { TFunction } from 'i18next'
import { Dialog } from '../../components/Dialog'
import { Text } from '../../components/Text'
import SuccessImage from '../../assets/images/ok.svg'
import { Content } from './components'

type Props = {
  queryName: string
  addToDashboard?: boolean
  close: () => void
  t: TFunction
}

export const ConfirmSaveQueryDialog = ({ queryName, addToDashboard = false, close, t }: Props) => {
  return (
    <Dialog
      title={t('SaveQuery.DialogTitle')}
      width={900}
      visible
      loading={false}
      footer=""
      onCancel={close}
      okText={t('common:Ok')}
      onOk={close}
    >
      <Content>
        <div className="text">
          <Text size="M" type="primary" bold block>
            &quot;{queryName}&quot; {t(addToDashboard ? 'SaveQuery.SavedQueryAndDashboard' : 'SaveQuery.SavedQuery')}
          </Text>
          <Text size="M" block>
            {t('SaveQuery.SavedQueryDescription')}
          </Text>
        </div>
        <img src={SuccessImage} alt="" />
      </Content>
    </Dialog>
  )
}
