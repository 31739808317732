import React from 'react'
import { useSelector } from 'react-redux'
import { getShowHiddenFeatures } from '../../features/config/selectors'

type Props = {
  children: React.ReactNode
}

export const HiddenFeature = ({ children }: Props) => {
  const showHiddenFeatures = useSelector(getShowHiddenFeatures)
  return showHiddenFeatures ? <>{children}</> : null
}

type Params = {
  render: () => React.ReactNode
}

export const RenderHiddenFeature = ({ render }: Params): React.ReactNode => {
  const showHiddenFeatures = useSelector(getShowHiddenFeatures)
  return showHiddenFeatures ? render() : null
}
