import styled from 'styled-components/macro'
import { Link } from '../../../components/Markdown/components'

export const Notes = styled.div`
  padding: 4px;
`

export const StyledLink = styled(Link)`
  &:hover {
    color: #000000;
  }
`
