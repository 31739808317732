import moment from 'moment'
import { AssignTaskViewState } from '../types'
import {
  SingleAssignTaskDialogData,
  MultipleAssignTaskDialogData,
  SingleAssignTaskDialogDataReq,
  MultipleAssignTaskDialogDataReq
} from './types'

export const assignTaskSingleData = ({
  companyId,
  taskId,
  taskName,
  oldTaskName,
  tasksList,
  campaignId
}: SingleAssignTaskDialogDataReq): SingleAssignTaskDialogData => ({
  quantity: 'single',
  companyId,
  taskId,
  taskName,
  oldTaskName,
  view: AssignTaskViewState.AssignTaskView,
  tasksList,
  campaignId
})

export const assignTaskMultipleData = ({
  taskId,
  taskName,
  selectedItems,
  itemsToIgnore,
  count,
  tasksList,
  campaignId,
  query
}: MultipleAssignTaskDialogDataReq): MultipleAssignTaskDialogData => ({
  quantity: 'multiple',
  taskId,
  taskName,
  selectedItems,
  itemsToIgnore,
  count,
  view: AssignTaskViewState.AssignTaskView,
  tasksList,
  campaignId,
  query
})

export const getExpirationDateFromNow = (days = 7) => moment().add(days, 'days').format()
