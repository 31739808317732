import styled from 'styled-components/macro'
import { Stack } from '../../../components/Stack'

export const SaveQueryButtons = styled(Stack)`
  width: auto;
`

export const GenericContent = styled.div`
  display: flex;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image {
    flex: 1 0 auto;
    svg {
      display: block;
      margin: 0 auto;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  .centered {
    margin: 0 auto;
    width: 70%;
  }
`
