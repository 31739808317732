import { createSelector } from 'reselect'
import _ from 'lodash'
import { paramSelector } from '../../../utils/selectors'
import { TreeKeySelection } from '../../../utils/tree'
import { selectState, getParamOrStoreUserId } from './commonSelectors'
import { getSelectionOfFilters } from './filtersSelectors'
import { WithGroupId, WithEntityIdOptional, WithUserId } from './types'

// Prevents unnecessary run of selectors that receive one of them as an argument, returning the memoized result instead
const refStableObject = {}
const refStableArray: [] = []

const getSelectedFilterGroupsStore = createSelector(selectState, state => state.selectedFilterGroups)

const getTreeSelectionFilterByGroupHelper = createSelector(
  getSelectedFilterGroupsStore,
  paramSelector<WithUserId & WithEntityIdOptional>(),
  (selectedFilterGroups, { userId, entityId }) =>
    ({ groupId }: WithGroupId): TreeKeySelection[] => {
      if (entityId) {
        return _.get(selectedFilterGroups, [userId, entityId, groupId], refStableArray) as any // TODO change type
      }
      return _.get(selectedFilterGroups, [userId, groupId], refStableArray) as any // TODO change type
    }
)

export const getFilterSelectionForTree = createSelector(
  getSelectionOfFilters,
  getTreeSelectionFilterByGroupHelper,
  (groupId, selectTreeSelectionFilterByGroup) => {
    if (!groupId) return []
    return selectTreeSelectionFilterByGroup({ groupId })
  }
)

export const getSelectedFilterGroups = createSelector(
  getSelectedFilterGroupsStore,
  getParamOrStoreUserId,
  paramSelector<WithEntityIdOptional>(),
  (selectedFilterGroups, userId, { entityId }): Record<string, TreeKeySelection[]> => {
    if (entityId) {
      return _.get(selectedFilterGroups, [userId, entityId], refStableObject) as any // TODO change type
    }
    return _.get(selectedFilterGroups, userId, refStableObject) as any // TODO change type
  }
)

export const isAnyFilterGroupSelected = createSelector(
  getSelectedFilterGroups,
  filterGroups => !_.isEmpty(_.concat([], ..._.values(filterGroups)))
)
