import React from 'react'
import _ from 'lodash'
import { Button as AntButton } from 'antd3'
import { ButtonProps } from 'antd3/lib/button'
import styled, { DefaultTheme } from 'styled-components/macro'
import { LoadingOutlined } from '@ant-design/icons'
import { Text } from '../Text'
import { TextSize } from '../Text/common'
import { Stack } from '../Stack'
import { commonStyle } from './common'

type StyledButtonProps = {
  fluid?: boolean
  opacity?: number
}

const StyledButton = styled(AntButton).withConfig<StyledButtonProps>({
  shouldForwardProp: p => !_.includes(['fluid', 'opacity'], p)
})`
  // https://github.com/ant-design/ant-design/issues/9581
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')}
  &.ant-btn {
    ${commonStyle}
    overflow: hidden;
    padding: 8px 16px;
    line-height: 24px;
    ${({ opacity }) => (!_.isNil(opacity) ? `opacity: ${opacity};` : '')}
    ${({ fluid }) => (fluid ? 'width: 100%;' : '')}

    ${Text.Style} {
      display: block;
    }

    ${Stack} {
      justify-content: center;
    }
  }
`

type Props = {
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  fallbackTheme?: DefaultTheme
  textSize?: TextSize
  children: React.ReactNode
} & StyledButtonProps &
  ButtonProps

export const Button: React.FC<Props> = ({
  fallbackTheme,
  children,
  loading,
  iconLeft,
  iconRight,
  textSize,
  disabled,
  ...props
}) => (
  <StyledButton theme={fallbackTheme} {...props} disabled={disabled || !!loading}>
    <Stack center gap={8}>
      {!!loading && <LoadingOutlined />}
      {iconLeft}
      <Text fallbackTheme={fallbackTheme} size={textSize} bold ellipsis>
        {children}
      </Text>
      {iconRight}
    </Stack>
  </StyledButton>
)

export const ButtonWrapper = styled.div`
  > button:not(:first-child) {
    margin-left: 10px;
  }
`
