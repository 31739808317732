import _ from 'lodash'
import { Query } from '../../features/query/types'
import { QueryType } from '../../types'
import { filterQueriesByType } from '../../utils/query'

export const getDefaultQuerySelection = (
  options?: Query[],
  defaultType = QueryType.ProspectingQueries
): QueryType | undefined => {
  if (_.isNil(options) || _.isEmpty(options)) return undefined

  const availableQueryTypes = _.map(_.uniqBy(options, 'type'), 'type')
  if (_.includes(availableQueryTypes, defaultType)) return defaultType
  // monitored queries are part of prospecting queries
  if (defaultType === QueryType.ProspectingQueries && _.includes(availableQueryTypes, QueryType.MonitoredQueries)) {
    return QueryType.ProspectingQueries
  }
  return _.head(availableQueryTypes)
}

export const containsQuery = (type: QueryType, options: Query[]) => {
  const queryTypesMap = (
    {
      [QueryType.PortfolioQueries]: [QueryType.PortfolioQueries],
      [QueryType.TargetingQueries]: [QueryType.TargetingQueries],
      [QueryType.ProspectingQueries]: [QueryType.ProspectingQueries]
    } as Record<QueryType, QueryType[]>
  )[type]

  return _.some(options, option => _.includes(queryTypesMap, option.type))
}

const allowedSelectedQueries = new Set<QueryType>([
  QueryType.PortfolioQueries,
  QueryType.TargetingQueries,
  QueryType.ProspectingQueries,
  QueryType.MonitoredQueries
])

export const getSelectedQueries = (allQueries: Query[] | undefined, selectedType: QueryType | undefined) =>
  selectedType && allowedSelectedQueries.has(selectedType) && allQueries
    ? filterQueriesByType(allQueries, selectedType)
    : []
