/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  PM001_0: 'Spoločnosť bola nahraná správne. Nahrávanie portfólia {{portfolioName}} bolo dokončené!',
  PM001_1: 'Spoločnosti boli nahrané správne. Nahrávanie portfólia {{portfolioName}} bolo dokončené!',
  PM001_2: 'Spoločnosti boli nahrané správne. Nahrávanie portfólia {{portfolioName}} bolo dokončené!',
  PM002: 'Prejdite na detail chýb pri nahrávaní portfólia {{portfolioName}}',
  PM003_0: 'Spoločnosť {{portfolioName}} nebola importovaná z dôvodu nesúladu identifikácie alebo ukončenia činnosti.',
  PM003_1: 'Spoločnosti {{portfolioName}} neboli importované z dôvodu nesúladu identifikácie alebo ukončenia činnosti.',
  PM003_2: 'Spoločnosti {{portfolioName}} neboli importované z dôvodu nesúladu identifikácie alebo ukončenia činnosti.',
  PM004_0: '{{portfolioName}}: spoločnosť nebola importovaná z dôvodu neúplných alebo nesprávnych údajov.',
  PM004_1: '{{portfolioName}}: spoločnosti neboli importované z dôvodu neúplných alebo nesprávnych údajov.',
  PM004_2: '{{portfolioName}}: spoločnosti neboli importované z dôvodu neúplných alebo nesprávnych údajov.',
  PM005: 'Stiahnutie zoznamu {{exportType}} portfólia {{portfolioName}} bolo dokončené',
  PM006: 'Stiahnutie súboru PDF spoločnosti {{companyName}} bolo dokončené',
  PM007: 'Duplikácia spoločnosti {{numberOfCompanies}} v portfóliu {{portfolioName}} bola vykonaná správne',
  PM008: 'Vymazanie {{numberOfCompanies}} spoločností z portfólia {{portfolioName}} bolo vykonané správne',
  PM009_0:
    'Presun {{count}} spoločnosti z portfólia {{sourcePortfolioName}} do portfólia {{targetPortfolioName}} bol úspešne vykonaný.',
  PM009_1:
    'Presun {{count}} spoločností z portfólia {{sourcePortfolioName}} do portfólia {{targetPortfolioName}} bol úspešne vykonaný.',
  PM009_2:
    'Presun {{count}} spoločností z portfólia {{sourcePortfolioName}} do portfólia {{targetPortfolioName}} bol úspešne vykonaný. ',
  PM010_0: 'Obohatenie {{numberOfCompanies}} spoločnosti v portfóliu {{portfolioName}} bolo vykonané správne',
  PM010_1: 'Obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  PM010_2: 'Obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  PM011: 'Zdieľanie spoločnosti {{companyNumber}} z portfólia {{portfolioName}} s {{userName}} sa vykonalo správne',
  PM012: '{{grantorName}} zazdieľal portfólio {{portfolioName}}',
  PM013: 'Odstránenie zdieľania {{portfolioName}} vykonané správne',
  PM014Part1_0:
    'Priradenie {{productName}} {{numberOfCompanies}} spoločnosti v portfóliu {{portfolioName}} prebehlo správne.',
  PM014Part1_1:
    'Priradenie {{productName}} {{numberOfCompanies}} spoločnostiam v portfóliu {{portfolioName}} prebehlo správne.',
  PM014Part1_2:
    'Priradenie {{productName}} {{numberOfCompanies}} spoločnostiam v portfóliu {{portfolioName}} prebehlo správne.',
  PM014Part2_0:
    ' Priradenie produktu nebolo dokončené pre {{numberOfDuplicates}} spoločnosť z dôvodu duplikácie riadku.',
  PM014Part2_1:
    ' Priradenie produktu nebolo dokončené pre {{numberOfDuplicates}} spoločnosti z dôvodu duplikácie riadku.',
  PM014Part2_2:
    ' Priradenie produktu nebolo dokončené pre {{numberOfDuplicates}} spoločností z dôvodu duplikácie riadku.',
  PM015: 'Priradenie {{salesName}} {{numberOfCompanies}} spoločnostiam v portfóliu {{portfolioName}} vykonané správne',
  PM017:
    'Priradenie {{managerName}} {{numberOfCompanies}} spoločnostiam v portfóliu {{portfolioName}} vykonané správne',
  PM014_0:
    'Zrušenie priradenia u {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  PM015_0:
    'Zrušenie priradenia u {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  PM017_0:
    'Zrušenie priradenia u {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  PM019_0: 'Kampaň {{campaignName}} s {{numberOfCompanies}} spoločnosťou bola vytvorená',
  PM019_1: 'Kampaň {{campaignName}} s {{numberOfCompanies}} spoločnosťami bola vytvorená',
  PM019_2: 'Kampaň {{campaignName}} s {{numberOfCompanies}} spoločnosťami bola vytvorená',
  PM020: '{{numberOfCompanies}} úspešne obohatených',
  PM021: '{{initiatorUserName}} zazdieľal {{numberOfCompanies}} spoločností',
  PM022: '{{initiatorUserName}} zastavil zdieľanie {{numberOfCompanies}} spoločností',
  PM023: 'Aktualizácia zdieľania bola vykonaná správne',
  PM024: 'Aktualizácia zdieľania bola vykonaná správne',
  PM025: '{{initiatorUserName}} zazdieľal {{numberOfCompanies}} spoločností {{numberOfSalesUsers}} užívateľom',
  PM026: '{{initiatorUserName}} zastavil zdieľanie {{numberOfCompanies}} spoločností {{numberOfSalesUsers}} užívateľom',
  PM027_0: '{{portfolioName}}: spoločnosť nebola importovaná z dôvodu neúplných alebo nesprávnych údajov.',
  PM027_1: '{{portfolioName}}: spoločnosti neboli importované z dôvodu neúplných alebo nesprávnych údajov.',
  PM027_2: '{{portfolioName}}: spoločnosti neboli importované z dôvodu neúplných alebo nesprávnych údajov.',
  LL001: 'Adding Local Units. The portfolio has been updated with {{numberOfCompanies}} Local Units.',
  ST001_0: 'Kampaň {{campaignName}} s {{numberOfCompanies}} spoločnosťou bola vytvorená',
  ST001_1: 'Kampaň {{campaignName}} s {{numberOfCompanies}} spoločnosťami bola vytvorená',
  ST001_2: 'Kampaň {{campaignName}} s {{numberOfCompanies}} spoločnosťami bola vytvorená',
  ST002_0: '{{userName}} odstránil {{numberOfCompanies}} spoločnosť z kampane {{campaignName}}',
  ST002_1: '{{userName}} odstránil {{numberOfCompanies}} spoločnosti z kampane {{campaignName}}',
  ST002_2: '{{userName}} odstránil {{numberOfCompanies}} spoločností z kampane {{campaignName}}',
  ST003_0: '{{userName}} priradil {{numberOfCompanies}} spoločnosť do kampane {{campaignName}}',
  ST003_1: '{{userName}} priradil {{numberOfCompanies}} spoločnosti do kampane {{campaignName}}',
  ST003_2: '{{userName}} priradil {{numberOfCompanies}} spoločností do kampane {{campaignName}}',
  ST004: 'Kampaň {{campaignName}} sa začne {{startDate}}',
  ST005: 'Kampaň {{campaignName}} sa skončí {{endDate}}',
  ST006: 'Portfólio {{portfolioName}} bolo úspešne pridané do kampane {{campaignName}}.',
  ST007: 'Stiahnutie Excelu {{campaignName}} bolo dokončené',
  ST009_0: 'V kampani {{campaignName}} sa dokončilo priradenie pre {{numberOfCompanies}} spoločnosť.',
  ST009_1: 'V kampani {{campaignName}} sa dokončilo priradenie pre {{numberOfCompanies}} spoločnosti.',
  ST009_2: 'V kampani {{campaignName}} sa dokončilo priradenie pre {{numberOfCompanies}} spoločností.',
  ST010_0: '{{numberOfCompanies}} spoločnosť bola úspešne pridaná do kampane {{campaignName}}.',
  ST010_1: '{{numberOfCompanies}} spoločnosti boli úspešne pridané do kampane {{campaignName}}.',
  ST010_2: '{{numberOfCompanies}} spoločností bolo úspešne pridaných do kampane {{campaignName}}.',
  ST011_0: 'Priradenie úlohy {{taskName}} spoločnosti {{numberOfCompanies}} pre kampaň {{campaignName}} dokončené.',
  ST011_1: 'Priradenie úlohy {{taskName}} spoločnostiam {{numberOfCompanies}} pre kampaň {{campaignName}} dokončené.',
  ST011_2: 'Priradenie úlohy {{taskName}} spoločnostiam {{numberOfCompanies}} pre kampaň {{campaignName}} dokončené.',
  PR001: '{{numberOfCompanies}} nových spoločností vstúpilo do sledovaného portfólia {{portfolioName}}',
  PR002: 'Vloženie a obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  PR003_0:
    'Vytvorenie portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatenej spoločnosti bolo vykonané správne',
  PR003_1:
    'Vytvorenie portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatených spoločností bolo vykonané správne',
  PR003_2:
    'Vytvorenie portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatených spoločností bolo vykonané správne',
  PR004:
    'Vloženie a obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} a monitorovanie vykonané správne',
  PR005:
    'Vytvorenie monitorovaného portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatených spoločností vykonané správne',
  PR006: 'Vaše obohatené portfólio <0> {{portfolioName}} </0> bolo vytvorené. {{exclusionWarning}}',
  PR007: 'Vloženie a obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  TR001_0:
    'Vytvorenie portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatenej spoločnosti bolo vykonané správne',
  TR001_1:
    'Vytvorenie portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatených spoločností bolo vykonané správne',
  TR001_2:
    'Vytvorenie portfólia {{portfolioName}} a vloženie {{numberOfCompanies}} obohatených spoločností bolo vykonané správne',
  TR002: 'Vloženie a obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  TA001: 'Stiahnutie súboru Excel z targetingu dokončené',
  TA002: 'Stiahnutie súboru PDF z targetingu dokončené',
  DO002: 'Súbor pre spoločnosť {{companyName}} bol vytvorený a je pripravený na stiahnutie.',
  PM002Event1: 'Neaktívne spoločnosti sa nenahrali',
  PM002Event2: 'Chyby z dôvodu nesprávnych údajov. Šablónu môžete neskôr nahrať v sekcii "Správa portfólia"',
  OP001:
    'Správne uskutočnené vytvorenie portfólia {{portfolioName}} a vloženie obohatenej spoločnosti {{numberOfCompanies}}',
  OP001_plural:
    'Správne uskutočnené vytvorenie portfólia {{portfolioName}} a vloženie obohatenej spoločnosti {{numberOfCompanies}}',
  OP002: 'Správne uskutočnené vloženie a obohatenie spoločnosti {{numberOfCompanies}} do portfólia {{portfolioName}}',
  OP003: 'Vaše obohatené portfólio {{portfolioName}} bolo správne vytvorené. {{exclusionWarning}}',
  OP004_0: 'Obohatenie {{numberOfCompanies}} spoločnosti v portfóliu {{portfolioName}} bolo vykonané správne',
  OP004_1: 'Obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  OP004_2: 'Obohatenie {{numberOfCompanies}} spoločností v portfóliu {{portfolioName}} bolo vykonané správne',
  MissingMessage: 'Pre typ {{type}} chýba notifikačná správa.',
  ExcludedCompaniesWarning: 'Spoločnosti patriace do skrytých portfólií neboli spracované.',
  SalesTool: 'Obchodné aktivity',
  Portfolio: 'Správa portfólia',
  Prospecting: 'Výber klientov',
  Operations: 'Operácie',
  Targeting: 'Analýza trhu',
  DownloadPDF: 'Stiahnuť PDF',
  NotificationArchive: {
    Title: 'Archív notifikácií',
    Description: 'Na tejto stránke nájdete zoznam prijatých notifikácií',
    Notifications: '{{notificationsNumber}} Notifikácií',
    Welcome: 'Vitajte v Margo:',
    NoNotifications: 'toto je sekcia notifikácií a vyplní sa, keď začnete vykonávať akcie.'
  },
  Table: {
    Header: {
      Date: 'Dátum',
      Section: 'Oddiel',
      Message: 'Správa '
    }
  },
  NotificationPopup: {
    Open: 'Otvoriť',
    ManageErrors: 'Správa chýb',
    DownloadList: 'Stiahnutie zoznamu',
    DownloadFile: 'Stiahnuť súbor'
  },
  PushEvent: {
    UploadProgress: 'Vaše portfólio sa nahráva',
    UploadProgressFinished: 'Nahrávanie bolo dokončené',
    DeleteCompaniesProgress: 'Prebieha mazanie vybraných spoločností',
    DeleteCompaniesProgressFinished: 'Mazanie vybraných spoločností je ukončené',
    EnrichCompaniesProgress: 'Obohacovanie vybraných spoločností práve prebieha',
    EnrichCompaniesProgressFinished: 'Obohacovanie vybraných spoločností je ukončené',
    CreatePortfolioFromSelectionProgress: 'Prebieha vytváranie portfólia z vybraných spoločností',
    CreatePortfolioFromSelectionProgressFinished: 'Vytvorenie portfólia z vybraných spoločností je dokončené',
    DuplicateProgress: 'Vaše portfólio sa duplikuje',
    DuplicateProgressFinished: 'Duplikát dokončený',
    ShareCompaniesProgress: 'Spoločnosti sa zdieľajú',
    ShareCompaniesProgressFinished: 'Zdieľané je dokončené',
    UnshareCompaniesProgress: 'Zdieľanie spoločností sa zastavuje',
    UnshareCompaniesProgressFinished: 'Zdieľanie spoločností bolo zastavené',
    AssignSalesProgress: 'Prebieha priradenie skupine Sales',
    AssignSalesProgressFinished: 'Priradenie skupine Sales dokončené',
    AssignAreaManagerProgress: 'Prebieha priradenie AreaManagerom',
    AssignAreaManagerProgressFinished: 'Priradenie AreaManagerom dokončené',
    AssignProductProgress: 'Prebieha priradenie k produktu',
    AssignProductProgressFinished: 'Priradenie k produktu dokončené',
    ProspectingSaveListProgress: 'Vytváranie portfólia z prebiehajúcich prieskumov',
    ProspectingSaveListProgressFinished: 'Vytváranie portfólia dokončené',
    TargetingSaveListProgress: 'Prebieha tvorba portfólia z analýzy trhu',
    TargetingSaveListProgressFinished: 'Vytváranie portfólia dokončené',
    ProspectingUpdateListProgress: 'Prebieha aktualizácia portfólia z "Výberu klientov"',
    ProspectingUpdateListProgressFinished: 'Aktualizácia portfólia z "Výberu klientov" dokončená',
    TargetingUpdateListProgress: 'Prebieha aktualizácia portfólia z analýzy trhu',
    TargetingUpdateListProgressFinished: 'Aktualizácia portfólia dokončená',
    CreateCampaignProgress: 'Prebieha vytváranie kampane',
    CreateCampaignProgressFinished: 'Vytváranie kampane je dokončené',
    AssignCampaignProgress: 'Prebieha priraďovanie kampane',
    AssignCampaignProgressFinished: 'Priradenie kampane je dokončené',
    UpdateCampaignTaskProgress: 'Prebieha aktualizácia vašej kampane',
    UpdateCampaignTaskProgressFinished: 'Vaša kampaň bola aktualizovaná',
    SetCompaniesTaskProgress: 'Prebieha nastavenie úloh spoločností',
    SetCompaniesTaskProgressFinished: 'Nastavenie úloh spoločností bolo dokončené',
    ExportCampaignProgress: 'Prebieha export kampane',
    ExportCampaignProgressFinished: 'Export kampane dokončený',
    ExportPortfolioProgress: 'Vaše portfólio {{portfolioName}} sa pripravuje na stiahnutie',
    ExportPortfolioProgressFinished: 'Vaše stiahnuté portfólio {{portfolioName}} je pripravené',
    PdfGenerateProgress: 'Prebieha vytváranie súborov PDF pre spoločnosť {{companyName}}',
    AddLocalUnitsToPortfolioProgress: 'Adding Local Units. Portfolio update in progress.'
  },
  Banner: {
    RingsWarningMessage:
      'Pozor! Počet vašich zostávajúcich jednotiek je menší ako 10%. Skontrolujte <1>svoj účet</1> alebo kontaktujte svoj predajný účet.'
  }
}
