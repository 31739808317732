/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: 'Tipo di analisi',
    StartAnalysis: "Per avviare l'analisi è necessario selezionare il tipo di analisi che si desidera elaborare",
    LoadQuery: 'Carica analisi',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    MarketAnalysisBtn: 'Inizia nuova analisi di mercato',
    MarketAnalysisBtnTooltip:
      'Visualizza e segmenta imprese in base alle principali variabili e costruisci il tuo target di riferimento',
    FromTargeting: 'Carica analisi di mercato',
    FromTargetingTooltip: 'Apri una ricerca salvata nel modulo Analizza mercato',
    FromProspecting: 'Carica ricerca da trova clienti',
    FromProspectingTooltip: 'Apri una ricerca salvata nel modulo Trova clienti',
    PortfolioQuery: 'Ricerca da Portafoglio imprese',
    TargetingQuery: 'Ricerca da Analizza mercato',
    ProspectingQuery: 'Ricerca da Trova clienti',
    ChooseQuery: 'Scegli quale ricerca vuoi caricare'
  },
  ChartLabels: {
    Company: 'Impresa',
    Company_plural: 'Imprese',
    ChartDetail: 'Dettaglio grafico',
    PercentageOfCompanies: '% di imprese',
    NumberOfCompanies: 'n° di imprese',
    NumberOfEmployees: 'n° di dipendenti',
    Amount: 'Valore',
    Risk: 'Fasce di rischio',
    Employees: 'Dipendenti',
    Revenue: 'Fatturato',
    Other: 'Altro',
    Years: 'Anni',
    HiddenNDNotice: 'Nota: nel grafico non è mostrata la classe di imprese per la quale non sono disponibili dati'
  },
  Header: {
    Targeting: 'Analyze market',
    Title: 'Analisi di Mercato',
    LoadQuery: 'Carica analisi',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SaveQuery: 'Salva analisi',
    SaveQueryTooltip: 'Salva questa ricerca nel modulo Analizza mercato',
    Download: 'Download PDF/Excel',
    DownloadTooltip: 'Scarica un report di questa ricerca sul tuo dispositivo',
    LoadPortfolio: 'Carica portafoglio',
    LoadPortfolioTooltip: 'Apri ed analizza un portafoglio',
    SendToProspecting: 'Invia analisi al Trova clienti',
    SendToProspectingTooltip: 'Visualizza la lista di imprese appartenenti a questa ricerca',
    SendToProspectingFromPortfolioTooltip:
      'Visualizza le aziende prospect che rispondono ai criteri di ricerca inseriti',
    SendToProspectingMessage: 'Per abilitare questo pulsante applica un filtro alla tua analisi',
    SaveList: 'Salva lista',
    SaveListTooltip: 'Salva questa lista di imprese in un portafoglio'
  },
  Filters: {
    CompanyCount: 'Impresa',
    CompanyCount_plural: 'Imprese',
    ActiveCompanyCount: 'Attiva',
    ActiveCompanyCount_plural: 'Attive',
    OtherCompanyCount: 'Altro',
    StatusSelectLabel: 'Seleziona stato',
    ShowBySelectLabel: 'Mostra per',
    AggregationFieldAmount: 'Valore',
    AggregationFieldNumCompanies: 'Numero di imprese'
  },
  Search: {
    SemanticSearch: 'Ricerca semantica',
    SearchPlaceholder: 'Cerca',
    All: 'Tutti',
    OfficialData: 'Dati ufficiali',
    Website: 'Web & Social',
    Synonyms: 'Sinonimi',
    Advanced: 'Ricerca semantica avanzata',
    AdvancedSwitch: 'Avanzata',
    AdvancedTooltip:
      'Con la Ricerca semantica avanzata puoi utilizzare più parole collegate da operatori logici (AND, OR, NOT). Per abilitarla attiva lo switch su ON. Scarica il documento con le specifiche per maggiori informazioni. ',
    DownloadSsGuide: 'Scarica la guida per la Ricerca Semantica'
  },
  DomainFilter: {
    SaveAndClose: 'Salva filtri e chiudi'
  },
  SaveQuery: {
    DialogTitle: 'Salva analisi',
    Description: 'Inserisci un nome da dare alla tua analisi o seleziona una ricerca salvata per sovrascriverla.',
    InputLabel: 'Nome ricerca',
    InputPlaceholder: 'Nome analisi',
    UpdateExisting: 'Aggiorna esistente',
    CreateNew: 'Crea nuova',
    AddToDashboardConfirmMessage: 'Vuoi aggiungere questa analisi nella dashboard?',
    AddToDashboardCheckboxText: 'Mostra nella dashboard',
    MaximumWidgetCountReached: "È stato raggiunto il numero massimo di box all'interno della Dashboard.",
    SavedQuery: 'salvata correttamente.',
    SavedQueryAndDashboard: 'salvata e aggiunta nella dashboard.',
    SavedQueryDescription: 'Troverai la ricerca salvata nel repository della relativa sezione.'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Scegli quale ricerca di Prospecting vuoi caricare',
    IntroSectionTitleTargeting: 'Scegli quale ricerca di Targeting vuoi caricare',
    IntroSectionDescription: 'Puoi recuperare una ricerca già iniziata.',
    DialogTitle: 'Carica una ricerca salvata',
    DialogSubTitle: 'Seleziona la ricerca salvata',
    DialogDescription: 'Scegli quale analisi intendi continuare; puoi ripartire da una tra quelle salvate.',
    SelectLabel: 'Ricerca salvata',
    Load: 'Carica',
    LoadingMessage: 'Caricamento analisi in corso',
    InvalidPortfolioTitle: 'Portafoglio non valido',
    InvalidPortfolioMessage:
      "Il portafoglio salvato non è più valido.\nL'analisi sarà estesa a tutte le imprese del database {{companyName}}.",
    TargetingRadio: 'Ricerche targeting',
    ProspectingRadio: 'Ricerche prospecting'
  },
  LoadPortfolio: {
    DialogTitle: 'Carica portafoglio',
    DialogSubTitle: 'Seleziona un portafoglio',
    Description: 'Scegli su quale portafoglio vuoi effettuare la tua analisi.',
    SelectLabel: 'Portafoglio salvato',
    Load: 'Carica',
    LoadingMessage: 'Caricamento portafoglio in corso'
  },
  SaveBeforeContinuing: {
    Question: 'Vuoi salvare la ricerca di mercato prima di caricare una ricerca?',
    Description:
      'Salva la tua ricerca di mercato prima di caricare la tua ricerca, per evitare di perdere informazioni.',
    OkBtn: 'Salva ricerca',
    CancelBtn: 'Salta il salvataggio'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: "Seleziona su quale portafoglio effettuare l'analisi",
      Description:
        "Puoi effettuare l'analisi su uno o più dei tuoi portafogli oppure visualizzare l'analisi aggregata di tutte le imprese nel sistema di {{companyName}}.",
      PortfolioOption: 'Seleziona portafoglio',
      AggregateOption: 'Tutte le imprese nel sistema {{companyName}}',
      UploadedPortfolio: 'Portafoglio caricato'
    },
    StepTwo: {
      Header: 'Scegli lo stato che vuoi analizzare',
      DescriptionLine1: "Seleziona lo stato dell'impresa da analizzare.",
      DescriptionLine2: "Durante l'analisi puoi cambiare lo stato usando i filtri.",
      Status: 'Stato'
    }
  },
  Download: {
    ModalTitle: 'Download PDF/Excel',
    SelectType: 'Seleziona formato',
    SelectTypeDescription:
      'Puoi scaricare il report completo della tua analisi in due formati diversi; seleziona quello che preferisci scaricare.',
    DownloadAnalysis: "Scarica l'analisi",
    Pdf: 'PDF',
    PdfDescription: 'Scarica in formato .pdf',
    PdfRecap: 'Stai per scaricare un file contentente il risultato di questa analisi come pdf.',
    Excel: 'Excel',
    ExcelDescription: 'Scarica in formato .xlsx',
    ExcelRecapLine1: 'Stai per scaricare un file contenente il risultato di questa analisi come lista.',
    ExcelRecapLine2: 'Il file verrà salvato nella sezione "Use" del tuo account.',
    CompanyInPortfolio: 'Impresa in portafoglio',
    CompanyInPortfolio_plural: 'Imprese in portafoglio',
    CompanyInAnalysis: 'Impresa analizzata',
    CompanyInAnalysis_plural: 'Imprese analizzate',
    DownloadPdf: 'Scarica PDF',
    DownloadPdfPleaseWait: 'Per favore attendi mentre il report in PDF viene generato',
    DownloadAllChartsMustBeLoaded: 'Per scaricare il report, attendi che tutti i grafici siano stati caricati',
    DownloadPdfDisabledIE:
      'Download PDF non supportato su questa versione di IE. Per scaricare il report, aggiorna il browser.',
    PdfPage: {
      Title: "Report dell'analisi",
      CompanyPortfolioStatus: 'Stato imprese in portafoglio',
      DataShownBy: 'Dati mostrati per',
      ExcludedPortfolios: 'Portafogli esclusi'
    }
  },
  UnavailableForMobile: 'La pagina alla quale stai cercando di accedere è disponibile per tablet e desktop.',
  ChartFetchError: 'Si è verificato un errore durante il caricamento di questo grafico.',
  ChartFetchRetry: 'Riprova',
  ChartEmpty: 'Nessun dato per questo grafico',
  BasedOnHeadquarter: 'Le aggregazioni riportate fanno riferimento alle sedi amministrative',
  VisualizeAs: {
    List: 'Visualizza come lista',
    Map: 'Visualizza su mappa'
  },
  WarningRespectingLocalUnits: {
    Title: 'Arricchimento',
    Text: 'Sono state arricchite le imprese con Tipo di sede "Sede amministrativa", "Sede amministrativa e Sede legale" e "Sede legale" (se selezionate). Per arricchire le aziende con Tipo di sede "Unità locale", attiva l\'add-on Unità locali contattando il customer service.'
  }
}
