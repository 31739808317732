import React from 'react'
import { LoadQueryDialog } from './LoadQueryDialog'

type Props = {
  close: () => void
  data: { onload: (query: string) => void }
}

export const LoadQueryDialogContainer: React.FC<Props> = ({ close, data }) => {
  const submit = React.useCallback(
    (queryId?: string) => {
      if (!queryId) return
      data.onload(queryId)
      close()
    },
    [data, close]
  )

  return <LoadQueryDialog submit={submit} close={close} />
}
