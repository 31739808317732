import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { deleteDocuments, deleteFilterQueriesAsDocument, getDocumentsKeys } from '../../../api/documents'
import { DeleteDocumentDialogData } from './types'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'
import { StyledDialog, StyledDialogBody, ContainerItem, BoldText } from '../components'
import { DeleteDocumentsRequest, DeleteFilterQueriesAsDocumentRequest } from '../../../api/documents/types'
import { QueryType } from '../../../types'
import { DocumentType } from '../../../features/documents/types'
import { getQueriesKeys } from '../../../api/query'

const mapDocumentTypeToI18nKey = (type: QueryType | DocumentType) => {
  switch (type) {
    case QueryType.PortfolioQueries:
    case QueryType.ProspectingQueries:
    case QueryType.TargetingQueries:
    case QueryType.Queries:
    case QueryType.OperationQueries:
    case DocumentType.PortfolioQueries:
    case DocumentType.Queries:
      return 'Queries'
    default:
      return 'PortfolioManagement'
  }
}

type Props = {
  data: DeleteDocumentDialogData
  close: () => void
}

export const DeleteDocumentDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()

  const tr = React.useCallback(
    (key: string) => {
      return t(`account:${mapDocumentTypeToI18nKey(data.documentTypes[0])}.DeleteDialog.${key}`, {
        count: data.documentId ? 1 : 2
      })
    },
    [data.documentId, data.documentTypes, t]
  )

  const {
    mutate: mutateDeleteDocuments,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(
    () => {
      const deleteRequest: DeleteDocumentsRequest =
        !data.selection || data.documentId
          ? {
              filter: {
                types: data.documentTypes,
                selectedDocuments: data.documentId ? [data.documentId] : []
              }
            }
          : {
              filter: {
                types: data.documentTypes,
                selectedDocuments: !data.selection.isSelectAll ? data.selection.selectedDocuments : undefined,
                documentsToIgnore: data.selection.isSelectAll ? data.selection.deSelectedDocuments : undefined
              }
            }

      return deleteDocuments(deleteRequest)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getDocumentsKeys.All())
        if (data.onQueriesDeleted) data.onQueriesDeleted()
      }
    }
  )
  const {
    mutate: mutateDeleteFilterQueries,
    isIdle: isIdleFilterQueries,
    isError: isErrorFilterQueries,
    isSuccess: isSuccessFilterQueries,
    isLoading: isLoadingFilterQueries,
    data: deleteFilterQueryResponse
  } = useMutation(
    () => {
      const deleteRequestForFilterQueries: DeleteFilterQueriesAsDocumentRequest =
        !data.selection || data.documentId
          ? { types: data.documentTypes, selectedDocumentIds: data.documentId ? [data.documentId] : [] }
          : {
              types: data.documentTypes,
              selectedDocumentIds: !data.selection.isSelectAll ? data.selection.selectedDocuments : undefined,
              ignoredDocumentIds: data.selection.isSelectAll ? data.selection.deSelectedDocuments : undefined
            }
      return deleteFilterQueriesAsDocument(deleteRequestForFilterQueries)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueriesKeys.All())
        if (data.onQueriesDeleted) data.onQueriesDeleted()
      }
    }
  )

  const isDocumentTypExportedPortfolio = _.some(data.documentTypes, type => type === DocumentType.ExportedPortfolio)

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  const okButtonTextFilterQueries = isIdleFilterQueries || isLoadingFilterQueries ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isDocumentTypExportedPortfolio ? isLoading : isLoadingFilterQueries}
      visible
      title={tr('Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={isDocumentTypExportedPortfolio ? okButtonText : okButtonTextFilterQueries}
      onOk={() => {
        if (isDocumentTypExportedPortfolio && isIdle) return mutateDeleteDocuments()
        if (!isDocumentTypExportedPortfolio && isIdleFilterQueries) return mutateDeleteFilterQueries()
        return close()
      }}
      okButtonProps={{
        disabled: isDocumentTypExportedPortfolio ? isLoading : isLoadingFilterQueries
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isDocumentTypExportedPortfolio ? isLoading : isLoadingFilterQueries
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {(isDocumentTypExportedPortfolio ? isIdle : isIdleFilterQueries) && (
            <div>
              <BoldText>{tr('Subtitle')}</BoldText>
              <p>{tr('Body')}</p>
            </div>
          )}
          {(isDocumentTypExportedPortfolio ? isSuccess : isSuccessFilterQueries) && <p>{tr('Success')}</p>}
          {(isDocumentTypExportedPortfolio ? isError : isErrorFilterQueries) && <p>{tr('Error')}</p>}
          {deleteFilterQueryResponse?.hasMonitoredQueries && <p>{tr('MonitoredQueryError')}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
