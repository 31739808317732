import { Form } from 'antd3'
import _ from 'lodash'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { Dialog } from '../../../components/Dialog'
import { Input } from '../../../components/Input'
import { Radio, RadioGroup } from '../../../components/Radio'
import { Option, Select } from '../../../components/Select'
import { Stack } from '../../../components/Stack'
import { Text } from '../../../components/Text'
import { useGetFilterQueriesAsDocument } from '../../../api/query'
import { QueryType } from '../../../types'
import { Buttons } from './styled'
import { SubmitMethod } from './types'
import { validateSaveQueryData } from './utils'
import { checkQueryName } from '../../../utils/query'
import { FETCH_QUERIES_TOP } from '../../../features/query/constants'
import { AddToDasboardWrapper } from '../../Prospecting/SaveQueryDialog/components'
import { Checkbox } from '../../../components/Checkbox'

type Props = {
  submitting: boolean
  portfolioId: string
  queryId: string | undefined
  queryName: string
  queryNameError: string | undefined
  currentSaveMethod: SubmitMethod
  setQueryId: (queryId: string) => void
  setQueryName: (queryName: string) => void
  setCurrentSaveMethod: (option: SubmitMethod) => void
  submit: () => void
  close: () => void
  setNameError: (value: string | undefined) => void
  addToDashboard: boolean
  setAddToDashboard: (checked: boolean) => void
  canAddWidget: boolean
  loading: boolean
}

export const SaveQueryDialog: React.FC<Props> = ({
  submitting,
  portfolioId,
  queryId,
  queryName,
  queryNameError,
  currentSaveMethod,
  setQueryId,
  setQueryName,
  setCurrentSaveMethod,
  submit,
  close,
  setNameError,
  addToDashboard,
  setAddToDashboard,
  canAddWidget,
  loading
}) => {
  const { t } = useTranslation(['portfolio', 'common', 'error'])
  const queryTypes = [QueryType.PortfolioQueries]

  const { isLoading, data: items } = useGetFilterQueriesAsDocument({
    documentTypes: queryTypes,
    top: FETCH_QUERIES_TOP
  })

  const onInputChangeHandler = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setNameError(checkQueryName(t, target.value))
      setQueryName(target.value)
    },
    [setNameError, setQueryName, t]
  )

  const isCreateNew = currentSaveMethod === SubmitMethod.CreateNew
  const isUpdateExisting = currentSaveMethod === SubmitMethod.Update

  return (
    <Dialog
      width={900}
      visible
      loading={submitting}
      title={t('SaveQuery.DialogTitle')}
      onCancel={close}
      footer={
        <Stack gap="auto" center>
          <AddToDasboardWrapper>
            {isCreateNew && (
              <>
                <Text size="M" type="primary" bold block>
                  {canAddWidget ? t('SaveQuery.ConfirmMessage') : t('SaveQuery.MaximumWidgetCountReached')}
                </Text>
                <Checkbox
                  disabled={!canAddWidget || loading || submitting}
                  checked={addToDashboard}
                  onChange={evt => setAddToDashboard(evt.target.checked)}
                >
                  <Text type="inherit" size="M">
                    {t('SaveQuery.Fields.AddToDashboard')}
                  </Text>
                </Checkbox>
              </>
            )}
          </AddToDasboardWrapper>
          <Buttons gap="1em">
            <Button disabled={submitting} onClick={close}>
              {t('common:Cancel')}
            </Button>
            <Button
              type="primary"
              disabled={
                submitting ||
                queryNameError !== undefined ||
                !validateSaveQueryData(currentSaveMethod, queryName, queryId)
              }
              onClick={submit}
            >
              {t('common:Save')}
            </Button>
          </Buttons>
        </Stack>
      }
    >
      <Stack orientation="vertical" gap={40}>
        <Text size="M" block>
          {t('SaveQuery.Description')}
        </Text>
        <RadioGroup
          disabled={submitting}
          value={currentSaveMethod}
          onChange={e => setCurrentSaveMethod(e.target.value)}
        >
          <Radio value={SubmitMethod.Update}>
            <Text size="S" type="primary" bold>
              {t('SaveQuery.UpdateExisting')}
            </Text>
          </Radio>

          <Radio value={SubmitMethod.CreateNew}>
            <Text size="S" type="primary" bold>
              {t('SaveQuery.CreateNew')}
            </Text>
          </Radio>
        </RadioGroup>

        <Form.Item validateStatus={queryNameError ? 'error' : 'success'} help={queryNameError}>
          <Text size="S" type="primary" bold>
            {t('SaveQuery.Fields.QueryName')}
          </Text>
          {isUpdateExisting && (
            <Select
              loading={isLoading}
              placeholder={t('SaveQuery.Fields.QueryName')}
              value={queryId}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              onChange={value => {
                const selectedQueryName = _.find(items, { id: value as string })?.name ?? ''
                setQueryName(selectedQueryName)
                setQueryId(value as string)
              }}
            >
              {_.map(items, i => (
                <Option key={i.id} disabled={i.associatedEntityId !== portfolioId}>
                  {i.name}
                </Option>
              ))}
            </Select>
          )}
          {isCreateNew && (
            <Input
              disabled={submitting}
              placeholder={t('SaveQuery.Fields.QueryNamePlaceholder')}
              value={queryName}
              onChange={onInputChangeHandler}
            />
          )}
        </Form.Item>
      </Stack>
    </Dialog>
  )
}
