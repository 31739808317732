/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: 'Zum Start der Analyse bitte gewünschten Analyse-Typ auswählen',
    TypeOfAnalysis: 'Art der Analyse',
    FromTargeting: 'Beginnen Sie mit einer gespeicherten Marktanalyse',
    FromTargetingTooltip: 'Öffnen Sie eine im Modul "Analyze market" gespeicherte Abfrage',
    FromProspecting: 'Beginnen Sie mit einer gespeicherten prospect Suche',
    FromProspectingTooltip: 'Öffnen Sie eine im Prospecting-Modul gespeicherte Abfrage',
    NewResearch: 'Starten Sie eine neue Suche',
    NewResearchTooltip: 'Suche nach Prospects in gesamten Datenbank von margò',
    PortfolioQuery: 'Beginnen Sie mit einer Portfolio Management Suche',
    TargetingQuery: 'Beginnen Sie mit einer gespeicherten Marktanalyse',
    ProspectingQuery: 'Beginnen Sie mit einer gespeicherten prospect Suche',
    SelectOne: 'Abfrage auswählen',
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Öffnen Sie eine gespeicherte Abfrage',
    SavedQuery: 'Gespeicherte Suchen',
    ChooseQuery: 'Wählen Sie die Suche aus, die Sie laden möchten',
    YouCanResumeResearch: 'Sie können eine bereits gestartete Suche fortsetzen.'
  },
  Prospecting: 'Prospecting',
  Actions: {
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Eine gespeicherte Abfrage öffnen',
    SaveQuery: 'Suche speichern',
    SaveQueryTooltip: 'Speichern Sie diese Abfrage im "Prospecting" Modul',
    Download: 'Excel herunterladen',
    DownloadTooltip: 'Laden Sie die angereicherte Liste der Unternehmen auf Ihr Gerät',
    SaveList: 'als Portfolio speichern',
    SaveListTooltip: 'Speichern Sie diese Unternehmensliste in einem Portfolio',
    SaveAndMonitor: 'Liste speichern & überwachen',
    SaveAndMonitorTooltip:
      'Speichern Sie diese Unternehmensliste in einem Portfolio, das dynamisch aktualisiert wird, wenn neue Unternehmen in den Suchbereich aufgenommen werden',
    SendToSales: 'Liste an Sales Tool senden',
    SendToSalesTooltip: 'Zuweisung der Unternehmen an das Sales Team und Planung einer Werbekampagne'
  },
  Indexes: {
    Penetration: 'Durchdringungsindex',
    PenetrationTooltip:
      'Er misst den %-Anteil der Unternehmen im Protfolio im Vergleich zu denen die von Similarity generiert sind. Ein höherer Wert zeigt eine bessere Durchdringung des potenziellen Marktes an.',
    Opportunity: 'Opportunitätsindex',
    OpportunityTooltip:
      'Er misst den %-Anteil der von Similarity generierten Unternehmen, die nicht im Portfolio enthalten sind. Ein höherer Wert deutet auf eine größere Anzahl von potenziellen Kunden hin.'
  },
  CompaniesInList: 'Firma in der Liste',
  CompaniesInList_plural: 'Firmen in der Liste',
  Search: {
    SemanticSearch: 'Semantic search',
    SearchPlaceholder: 'Suche',
    All: 'Alle',
    OfficialData: 'Offizielle Daten',
    Website: 'Web & Social',
    Synonyms: 'Synonyme',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Advanced',
    AdvancedTooltip:
      'Bei der advanced semantic search können Sie mehrere Wörter verwenden, die durch logische Operatoren (AND, OR, NOT) verknüpft sind. Um dies zu aktivieren, stellen Sie den Schalter auf ON. Für weitere Informationen können sie die Dokumentation herunter laden.',
    DownloadSsGuide: 'Anleitung zur semantischen Suche herunterladen'
  },
  EmptySearch: {
    History: {
      Title: 'Kein Filter',
      Subtitle: 'Hier können Sie sehen, wie sich jeder Filter auf Ihre Abfrage auswirkt'
    },
    Banner: {
      Title: 'Recherche mit bevorzugtem Filter starten',
      Subtitle:
        'Bitte stellen Sie vor Start der Recherche sicher, dass die Filter wie gewünscht eingestellt sind. Die Plattform generiert Ihnen basierend auf den gesetzten Filtern eine Liste idealer Interessenten (Prospects).'
    }
  },
  LoadQuery: {
    DialogTitle: 'Gespeicherte Suche laden',
    SaveBefore: {
      Question: 'Möchten Sie die Liste der Prospects speichern, bevor Sie die Suche laden?',
      Description:
        'Speichern Sie Ihre Prospect-Liste in einem Portfolio oder erstellen Sie eine neue, bevor Sie die Abfrage hochladen, um Datenverluste zu vermeiden',
      OkBtn: 'Liste speichern',
      CancelBtn: 'Speichern überspringen'
    }
  },
  SaveQuery: {
    DialogTitle: 'Suche speichern',
    Description:
      'Geben Sie den Namen ein, unter dem Sie Ihre Suche speichern möchten, oder wählen Sie eine vorhandene Abfrage aus, um sie zu überschreiben.',
    ConfirmMessage: 'Möchten Sie die Suche zu Ihrem Dashboard hinzufügen?',
    UpdateExisting: 'Update verfügbar',
    CreateNew: 'Erstelle neu',
    Fields: {
      QueryName: 'Name der Abfrage',
      QueryNamePlaceholder: 'Namen eingeben',
      AddToDashboard: 'Im Dashboard anzeigen'
    },
    SavedQuery: 'wurde korrekt gespeichert',
    SavedQueryAndDashboard: 'im Dashboard gespeichert und ergänzt',
    SavedQueryDescription: 'Die gespeicherte Suche finden Sie im "User Profile" - Prospect Finder'
  },
  DomainFilter: {
    SaveAndClose: 'Filter speichern & schließen'
  },
  LoadMore: 'Weitere laden',
  UnavailableForMobile: 'Die Seite, auf die Sie zuzugreifen versuchen, ist nur für Tablets und Desktops verfügbar.',
  WarningRespectingLocalUnits: {
    Title: 'Anreicherung',
    Text: 'Firmen mit dem Bürotyp "Verwaltungssitz", "Verwaltungssitz und Sitz" und "Sitz" wurden angereichert (falls ausgewählt). Um Unternehmen mit dem Office-Typ "Lokale Einheit" zu bereichern, aktivieren Sie bitte das Addon Lokale Einheiten, indem Sie sich an den Kundenservice wenden.'
  }
}
