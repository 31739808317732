import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getDomainByDomainType, getOrderByOptions } from './api'
import { OrderByResponse } from '../types'
import { DomainType, ModuleName } from './types'

export const getCommonKeys = {
  All: () => [{ level1: 'common' }] as const,
  GetOrderByOptions: (moduleName: ModuleName) =>
    [{ ...getCommonKeys.All()[0], level2: 'getOrderByOptions', moduleName }] as const,
  GetDomainByDomainType: (domainType: DomainType) =>
    [{ ...getCommonKeys.All()[0], level2: 'getDomainByDomainType', domainType }] as const
}

export const useGetOrderByOptions = <SelectReturnType = OrderByResponse>(
  moduleName: ModuleName,
  options?: UseQueryOptions<
    OrderByResponse,
    unknown,
    SelectReturnType,
    ReturnType<typeof getCommonKeys.GetOrderByOptions>
  >
) =>
  useQuery(
    getCommonKeys.GetOrderByOptions(moduleName),
    ({ queryKey: [queryKeys] }) => getOrderByOptions(queryKeys.moduleName),
    options
  )

export const useGetDomainByDomainType = (domainType: DomainType) =>
  useQuery(getCommonKeys.GetDomainByDomainType(domainType), () => getDomainByDomainType(domainType))
