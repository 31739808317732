import axios, { CancelToken, CancelTokenSource } from 'axios'
import { DomainNodesDataRequest, DomainNodesDataResponse, SearchDomainNodesRequest } from '../filters/types'
import * as T from './types'

export const getPortfolioConfiguration = (data: T.PortfolioConfigRequest) =>
  axios.post<T.PortfolioConfigResponse>('/api/Targeting/configuration', data).then(res => res.data)

export const getStatistics = (data: T.StatisticsRequest) =>
  axios.post<T.StatisticsResponse>('/api/Targeting/statistics', data).then(res => res.data)

export const getChartData = (data: T.ChartDataRequest, cancelTokenSource?: CancelTokenSource) => {
  const promise = axios
    .post<T.ChartDataResponse>('/api/Targeting/chart-data', data, {
      cancelToken: cancelTokenSource?.token,
      allowedErrorStatuses: [400, 500]
    })
    .then(res => res.data)

  // @ts-ignore
  promise.cancel = () => {
    cancelTokenSource?.cancel('Chart Data request cancelled')
  }
  return promise
}

export const getTargetingDomainNodesData = (data: DomainNodesDataRequest, cancelToken?: CancelToken) =>
  axios.post<DomainNodesDataResponse>('/api/Targeting/domain-nodes-data', data, { cancelToken })

export const getTargetingSearchDomainNodesDataPath = (data: SearchDomainNodesRequest) =>
  axios.post<DomainNodesDataResponse>('/api/Targeting/search-domain-nodes-data', data)

export const getTargetingLayout = () => axios.get<T.LayoutConfigResponse>('/api/Targeting/get-layout')

export const downloadAnalysis = (data: T.ExportDataRequest) =>
  axios.post<T.ExportDataResponse>('/api/Targeting/export-data', data)
