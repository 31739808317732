import { MAX_FILE_NAME_LENGTH } from '../../pages/CompanySurveys/constants'

/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Nom de la société',
  Actions: 'Actions',
  DownloadPDF: 'Télécharger PDF',
  AddToCampaign: ' Ajouter à campagne',
  CompanyData: {
    CompanyName: 'Nom de la société',
    CompanyId: 'ID de la société',
    Title: 'Aperçu',
    Town: 'Ville',
    OfficeClassification: 'Type de filiales',
    Ateco2007: 'Ateco primaire (2007)',
    NaceCode: 'Activité prévalente (NACE)',
    LegalForm: 'Forme juridique',
    LastUpdate: 'Dernière actualisation',
    TaxCode: 'Code fiscal',
    TaxNumber: 'Numéro fiscal',
    VatCode: 'Code TVA',
    VatNumber: 'Numéro de TVA',
    ZipCity: 'Code postal, ville',
    OnrNumber: 'Numéro ONR',
    MainIndustrialSectors: 'Principaux secteurs industriels',
    IdentificationNumber: 'Numéro d’identification commercial'
  },
  CompanySummary: {
    Evaluation: 'Évaluation',
    Profile: 'Profil',
    AffidabilityScore: 'Taux de fiabilité',
    CompanyGroup: 'Groupe de société',
    PublicContracts: "Appels d'offres publics",
    LocalUnits: 'Filiales',
    CompanyStatus: 'Statut de société',
    Title: 'Synthèse de la société',
    Address: 'Adresse',
    Town: 'Ville',
    State: 'Pays',
    TaxCode: 'Code fiscal',
    TaxNumber: 'Numéro fiscal',
    VatCode: 'Numéro de TVA',
    VatNumber: 'Numéro de TVA',
    CrifNumber: 'Numéro CRIF',
    OnrNumber: 'Numéro ONR',
    CCIAAREA: 'CCIAA / REA',
    TotalNumberBranches: 'Nombre de filiales',
    TotalNumberLocalUnit: 'Nombre de filiales',
    DetailedLegalForm: 'Forme juridique détaillée',
    Ateco2007: 'Ateco primaire (2007)',
    SIC: 'SIC',
    NACE: 'Nace',
    StartDate: 'Date de début',
    IncorporationDate: "Date d'incorporation",
    RegistrationCamComDate: "Date d'enregistrement à la Chambre du Commerce",
    HoldingCompanyName: 'Nom de la société de portefeuille',
    NationalParentCompany: 'Société mère nationale',
    TenderWon3y: "Appels d'offres publics gagnés (3 dernières années)",
    TenderParticipated3y: "Appels d'offres publics participés (3 dernières années)",
    TenderValueWon3y: "Valeur des appels d'offres publics gagnés (3 dernières années)",
    TypeOffice: 'Type de filiale',
    CountOffice: 'Nombre de filiales par type',
    Turnover: "Chiffre d'affaire",
    GroupTurnover: "Chiffre d'affaire du groupe",
    Estimated: 'Estimé',
    Profit: 'BÉNÉFICE',
    Employees: 'Employés',
    Mol: 'MOL',
    DetectionDate: 'Date de bilan comptable',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'TBA du groupe de société',
    MainIndustrialSectors: 'Principaux secteurs industriels',
    SecondaryIndustrialSectors: 'Secteurs industriels secondaires',
    StartOfBusiness: "Démarrage de l'activité",
    DateOfFoundation: 'Date de fondation',
    TotalNumberOfVehicles: 'Nombre total de véhicules',
    NumberOfPassengerCars: 'Nombre de voitures particulières',
    CarpoolNumberOfLorries: 'Co-voiturage - nombre de camions',
    tlcFix: 'Dépenses en TLC (téléphone fixe) (€)',
    tlcMob: 'Dépenses en TLC (téléphone portable) (€)',
    tlcFixTrend: 'Tendance des dépenses en TLC (téléphone fixe) (%)',
    tlcMobTrend: 'Tendance des dépenses en TLC (téléphone mobile) (%)',
    SemanticClusters: 'Margo Semantic Clusters',
    BankInformation: 'Coordonnées bancaires',
    Grants: 'Subventions',
    Vehicles: 'Véhicules',
    ratingScore: 'Cote de crédit',
    ImportExport: 'Import/Export',
    LineOfBusiness: "Secteur d'activité",
    BankAndInsurance: 'Banque et assurance',
    Registrations: 'Enregistrements',
    AuditingCompany: "Société d'audit",
    Group: 'Groupe'
  },
  FinancialStatement: {
    Title: 'Bilan financier',
    Subtitle: 'Principaux indicateurs budgétaires sur les dernières 3 années',
    Text: 'Tous les détails et éléments de bilan comptable sont disponibles en téléchargeant la liste complète.',
    ProfitAndLossStatement: 'Éléments des déclarations de pertes et profits (performances)',
    BalanceSheet: 'Éléments de bilan comptable (stabilité financière)',
    RentabilityIndicators: 'Indicateurs de rentabilité',
    EconomicData: 'Données économiques',
    FinancialData: 'Données financières',
    FinancialRatios: 'Indicateurs budgétaires',
    ProductionValue: 'Valeur de production',
    ProductionCosts: 'Coûts de production',
    BalanceOfRevenues: 'Solde des recettes et charges financières',
    ProfitLoss: 'Profits / Pertes',
    CashFlow: 'Flux de trésorerie',
    Assets: 'Actifs',
    TotalAssets: 'Totalité des actifs',
    FixedAssets: 'Actifs immobilisés',
    TangAssets: 'Biens corporels',
    CurrentAssets: 'Actifs actuels',
    TotalLiabilities: 'Passif total',
    Liabilities: 'Passif',
    LiabilitiesAccruals: 'Dettes de régularisation',
    TotalLiabilitiesAndEquity: 'Total du passif et capitaux propres',
    ShareholdersEquity: 'Capitaux propres',
    Provisions: 'Des provisions',
    DebtRatio: "Ratio d'endettement",
    PFN: 'PFN',
    TotalCredits: 'Total des crédits',
    TotalDebts: 'Total des dettes',
    NetWorth: 'Avoir net',
    EBITDA: 'EBITDA',
    ROE: 'ROE',
    ROI: 'ROI',
    ROS: 'ROS',
    TotalEmployees: 'Total des employés',
    Turnover: "Chiffre d'affaire",
    Profit: 'Bénéfice',
    RegisterCapital: 'Capital social',
    BalanceSheetRatios: 'Ratios de bilan comptable',
    Sales: 'Commercial',
    Cost: 'Coûts',
    FinancialAssets: 'Actifs financiers',
    BalanceSheetProfitLost: 'Profit/perte du bilan comptable (+/-)',
    Equity: 'Équité',
    LiquidityRatio: 'Ratios de liquidités',
    ReturnOnTotalCapital: 'Rendement du capital total',
    BalanceSheetRatio: 'Ratio de bilan comptable',
    TurnoverAndTurnoverTrend: "Chiffre d'affaire et tendance du chiffre d'affaire",
    TurnoverTrend: "Tendance du chiffre d'affaire",
    EmployeeEmployeetrend: 'Employés & tendance des employés',
    EmployeesTrend: 'Tendance des employés',
    RequestBalance: 'Consultation de solde',
    InvestmentAssets: "Actifs d'investissement",
    ImmaterialAssets: 'Actifs immatériels',
    PropertyEquipment: 'Equipements immobiliers',
    Inventories: 'Inventaires',
    ReceivablesAndOtherAssets: 'Créances et autres actifs',
    CashInhandBalancesWithCreditInstitutions: 'Avoirs en caisse auprès des établissements de crédit',
    FinancialInvestments: 'Investissements financiers',
    Securities: 'Titres',
    Accruals: 'Accumulations',
    CapitalReserves: 'Réserves de capital',
    ProfitReserves: 'Réserves bénéficiaires',
    AccumulatedProfitLoss: 'Perte de profit cumulée',
    UntaxedReserves: 'Réserves immunisées'
  },
  People: {
    Title: 'Personnes',
    From: 'De',
    Amount: 'Montant',
    Exponents: 'Exposants',
    Shareholders: 'Actionnaires',
    Partners: 'Société affiliée',
    SupervisoryBoard: "Conseil d'administration & organe statutaire",
    Managers: 'Responsables',
    RelatedCompanies: 'Sociétés connexes',
    Owners: 'Propriétaires',
    OwnershipInterests: 'Participation dans les sociétés',
    Years: 'années',
    OfficialSourceData: 'Données de sources officielles',
    UnofficialSourceData: 'Données de sources non officielles',
    DateOfBirth: 'Date de naissance',
    TypeOfConnection: 'Type de connexion',
    GoToLinkedin: 'Aller sur LinkedIn',
    Visualize: {
      PersonData: ' Visualiser les données sur la personne',
      CompanyData: 'Visualiser les données sur la société'
    }
  },
  ContactsAndSocial: {
    Title: 'Contacts & Réseaux sociaux',
    Social: 'Réseaux sociaux',
    RepresentativeCompany: 'Société représentative',
    CertifiedMail: 'E-mail certifié',
    PublicEmail: 'E-mail public de ESL',
    Website: 'URL de site web',
    Ecommerce: 'E-commerce',
    Fax: 'Fax',
    Telephone: 'Téléphone',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Charger plus',
    ManagerContacts: 'Contacts de responsables'
  },
  ContactsAndNews: {
    Title: 'Contacts et actualités'
  },
  Analytics: {
    Title: 'Analytiques',
    Seasonalit: 'Résultat saisonnier',
    Propensity: 'Coefficient de propension',
    AnalyticsMarketing: 'Marketing analytique',
    AnalyticsFinance: 'Finance analytique',
    AnalyticsMarketingMobile: 'Marketing ',
    AnalyticsFinanceMobile: 'Finance',
    SeasonalitWinter: 'Hiver',
    SeasonalitSpring: 'Printemps',
    SeasonalitSummer: 'Été',
    SeasonalitAutumn: 'Automne',
    PropensityShortTerm: 'Court terme',
    PropensityMiddTerm: 'Moyen terme',
    PropensityCarLeas: 'Location de voiture',
    PropensityInstrLeas: "Location d'équipement",
    PropensityRealEstateLeas: 'Location immobilière',
    PropensityFactoring: 'Affacturage',
    Internazionalization: "Résultat d'internationalisation",
    Social: 'Résultat social',
    DigitalAttitude: 'Résultat de comportement numérique',
    Innovation: "Résultat d'innovation",
    Supplier: 'Résultat de fournisseur',
    EstimatedTurnover: "Estimer chiffre d'affaire",
    AnalyticsMarketingTooltip: 'Marketing analytique',
    AnalyticsFinanceTooltip: 'Finance analytique',
    AnalyticsMarketingMobileTooltip: 'Marketing ',
    AnalyticsFinanceMobileTooltip: 'Finance',
    SeasonalitTooltip:
      "Affiche la saison de l'année où les sociétés sont enclines à souscrire à un crédit à court terme. Aide à identifier des prospects ou clients et à planifier des campagnes commerciales à certaines périodes.",
    PropensityTooltip:
      "Indique la propension d'une société à souscrire à un produit de crédit : crédit à court terme, crédit à long terme, affacturage, location (automobile, équipement et machines, immobilier). Le coefficient de propension de chaque produit est constitué de 3 niveaux. (1 = élevé, 2 = moyen, 3 = faible)",
    InternazionalizationTooltip:
      "Indique la propension d'une société au commerce international.\n Le résultat va de 1 (Propension élevée) à 5 (Propension faible)",
    SocialTooltip: 'Résultat sur les réseaux sociaux',
    DigitalAttitudeTooltip:
      "Estime l'affinité numérique d'une société, la propension à adopter des processus numériques (par ex : accueil numérique) et sa réactivité aux campagnes de marketing numérique.\n Le résultat va de 1 (Propension élevée) à 5 (Propension faible)",
    InnovationTooltip:
      "Estime le niveau d'innovation d'une société, corrélé à sa croissance.\nLe résultat va de 1 (Haute innovation) à 5 (Faible innovation)",
    SupplierTooltip: 'Résultat des fournisseurs',
    EstimatedTurnoverTooltip:
      "Pour les sociétés qui ne disposent pas d'un bilan de l’exercice, un modèle d'estimation a été mis au point pour permettre de reconstruire le dernier chiffre d'affaire.",
    TelcoFixScore: 'Résultat de la consommation téléphonique fixe',
    TelcoMobScore: 'Résultat de la consommation téléphonique mobile'
  },
  Mastercard: {
    Title: 'Détail',
    Business: 'Activité',
    BusinessTooltip:
      "Affiche le chiffre d'affaire d'une société sur la base d'une tendance observée sur 12 mois. Le résultat va de 1 (élevé) à 3 (faible).",
    Competitiveness: 'Compétitivité',
    CompetitivenessTooltip:
      "Affiche le potentiel d'une société, sur la base du paysage concurrentiel dans lequel elle opère. Lorsqu'elle est élevée, cela signifie qu'elle compte parmi les meilleures dans son secteur industriel et géographique. Le résultat va de 1 (élevé) à 3 (faible).",
    Digitalization: 'Numérisation',
    DigitalizationTooltip:
      "Affiche le niveau de développement des canaux numériques d'une société. Le résultat va de 1 (élevé) à 3 (faible).",
    Premium: 'Premium',
    PremiumTooltip:
      "Affiche l'impact de clients au pouvoir d'achat élevé pour la société, comparé à la moyenne pour cette industrie. Lorsque cette valeur est élevée, cela indique que cette société attire des transactions supérieures à la moyenne par rapport à des sociétés analogues. Le résultat va de 1 (élevé) à 3 (faible).",
    CommercialPotential: 'Potentiel commercial',
    CommercialPotentialTooltip:
      "Affiche le potentiel commercial d'une région géographique par rapport aux moyennes industrielles. Le résultat va de 1 (élevé) à 3 (faible).",
    CompetitionLevel: 'Niveau de compétitivité',
    CompetitionLevelTooltip:
      'Affiche la concentration de sociétés du même secteur industriel dans la région géographique au sein de laquelle la société opère. Le résultat va de 1 (élevé) à 3 (faible).',
    Performance: 'Performances',
    PerformanceTooltip:
      "Elle indique la façon dont le détaillant réagit à l'urgence en termes de production de recettes par rapport aux performances obtenues avant le confinement. Elle va de 1 (bonnes performances) à 5 (mauvaises performances).",
    Restart: 'Reprise',
    RestartTooltip:
      'Indique si les activités commerciales du détaillant ont repris comme avant le confinement. Elle va de 1 (tendance normale) à 5 (fermeture).'
  },
  Portfolio: {
    Title: 'Portefeuille',
    NumberOfCompanies: 'Nombre de sociétés',
    CreationDate: 'Date de création',
    LastUpdateDate: 'Date de la dernière actualisation'
  },
  SalesTransaction: {
    Title: 'Commentaires sur les ventes',
    Name: 'Nom',
    Product: 'Produit',
    Sales: 'Commercial',
    Task: 'Tâche',
    startDate: 'Date de début',
    EndDate: 'Date de fin',
    Notes: 'Notes'
  },
  UnlockView: {
    Title: 'Acheter les données de {{companyName}} pour déverrouiller cette section',
    MissingPortfoliosTitle: 'Ici se trouvent tous les portefeuilles dans lesquels la société est présente',
    MissingSalesTransactionsTitle: 'Ici se trouvent toutes les campagnes dans lesquelles la société est présente',
    Subtitle: 'Il est possible de déverrouiller cette section en achetant les données de {{companyName}}',
    Button: 'Déverrouiller les données de {{companyName}}',
    Tooltip: "Merci de fournir un message d'info..."
  },
  ModalWindow: {
    Notes: 'Notes',
    Title: "Il est possible d'ajouter jusqu'à cinq notes de cette société",
    InsertNote: '  Insérer une nouvelle note (250 caractères maxi)',
    NewNote: 'Saisir une nouvelle note ici',
    Add: 'Ajouter',
    AreYouSure: 'Voulez-vous vraiment supprimer cette note ?',
    Yes: 'Oui',
    No: 'Non'
  },
  CompanyProfile: 'Profil de société',
  CompanyProfileMobile: 'Société',
  FinancialStatementSummary: 'Récapitulatif des données de bilan',
  FinancialStatementMobile: 'Financier',
  Contacts: {
    Title: 'Contacts'
  },
  News: 'Actualités',
  Close: 'Fermer',
  LocalUnits: {
    Title: 'Unités locales',
    StartDate: 'Date de début'
  },
  Branches: {
    Title: 'Filiales',
    CbNumber: 'Numéro CB',
    Address: 'Adresse',
    Telephone: 'Téléphone'
  },
  Score: {
    Title: 'Index personnalisé',
    Tabs: {
      Index: 'Index personnalisé',
      Relevant: 'Événements pertinents',
      Telco: 'Télécommunication analytique',
      Analytics: 'Services publics analytiques'
    }
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'Investissement notation PNRR',
      Mission: 'Mission',
      Component: 'Composant',
      Scope: 'Portée',
      Investment: 'Investissement'
    }
  },
  CustomIndexes: {
    Title: 'Index personnalisé',
    Tabs: {
      Index: 'Index personnalisé',
      Warnings: 'Avertissements'
    },
    NegativeEventsPresent: 'Événements négatifs présents',
    NegativeEventsNotPresent: 'Événements négatifs non présents',
    cbiRiskTooltip: "Estime la probabilité d'un défaut. Le résultat va de 1 (risque faible) à 8 (risque élevé).",
    commercialCreditAppraisalCribisDBTooltip:
      "Indique l'exposition maximum recommandée pour les marques commerciales, sur la base des caractéristiques de la société.",
    cribisDBRatingTooltip:
      'Indique le profil de la société en matière de risque crédit. Le résultat va de 1 (risque faible) à 4 (risque élevé).',
    cribisScorePATooltip:
      'Un taux de risque crédit pour les entreprises publiques. Le résultat va de 1 (risque élevé) à 5 (risque faible).',
    delinquencyScoreTooltip:
      'Évalue la probabilité de retard de paiement. Le résultat va de 1 (risque élevé) à 100 (risque faible).',
    delinquencySectorScoreTooltip:
      "Indique la probabilité de retard de paiement d'un secteur industriel spécifique. Le résultat va de 1 (risque élevé) à 100 (risque faible).",
    failScoreTooltip:
      "Indique la fiabilité d'une société et son comportement en matière de remboursement de ses dettes pour les 12 prochains mois. Le résultat va de 1 (risque élevé) à 100 (risque faible).",
    itpRiskTooltip: 'Décrit les jours de retard (de 0 à 240) de règlement des factures à ses fournisseurs.',
    governanceRiskIndexGriTooltip:
      "Indique le risque relatif à la gouvernance d'une société. Analyse les Opérations extraordinaires, le Taux de légalité, l'Évaluation sur le Groupe de société, les Exposants et Actionnaires, les Organes de surveillance et de Contrôle, etc. Le résultat va de 1 (risque faible) à 5 (risque élevé)",
    paydexCribisDBTooltip:
      'Évalue la performance historique des paiements aux fournisseurs et indique la capacité de la société à respecter les délais de règlement. Le résultat va de 1 (risque élevé) à 100 (risque faible).',
    sectorsFailureScoreTooltip:
      "Indique le risque de défaut de paiement d'un secteur d'entreprise. Le résultat va de 1 (risque élevé) à 100 (risque faible).",
    sectorsPaydexTooltip:
      'Évalue la performance historique des paiements aux fournisseurs et indique le risque de retard de règlement du secteur industriel. Le résultat va de 1 (risque élevé) à 100 (risque faible).',
    supplierTooltip:
      "Indice d'évaluation d'un fournisseur. Mesure la qualité des fournisseurs en termes de certifications environnementale, les liens avec l'administration publique, les tendances par rapport aux marchés de référence. Le résultat va de 1 (Qualité élevée) à 5 (Faible qualité).",
    telcoPhoneConsumptionScoreTooltip:
      "Indique l'intensité d'utilisation du trafic téléphonique fixe. Le résultat va de 1 (Très élevé) à 5 (Très faible).",
    tlcPhoneFastwebTooltip:
      "Indique l'intensité d'utilisation du trafic téléphonique fixe. Le résultat va de 1 (plus de 250K €) à 5 (jusqu'à 1,5K €).",
    telcoMobilePhoneConsumptionScoreTooltip:
      "Indique l'intensité d'utilisation du trafic téléphonique mobile. Le résultat va de 1 (Très élevé) à 5 (Très faible).",
    electricityConsumptionScoreTooltip:
      "Indique le niveau de consommation d'électricité. Le résultat va de 1 (Consommation élevée) à 5 (Faible consommation).",
    gasConsumptionScoreTooltip:
      'Indique le niveau de consommation de gaz. Le résultat va de 1 (Consommation élevée) à 5 (Faible consommation).',
    warningCzSkDescription:
      "La couleur de l'avertissement indique sa signification : les informations négatives modérées sont affichées en jaune et les informations sensiblement négatives affichées en rouge.",
    cribisIndexTooltip:
      "Exprime la stabilité de l'entité calculée sur la base de critères financiers et/ou non-financiers. L'évaluation fournit des valeurs dans l'échelle 1a (Sujet exceptionnellement stable) à e (sujet très instable ou insolvable).",
    electricityScoreA2aTooltip:
      "Indique le niveau de consommation d'électricité, mesurée en Watt heure (Wh). Le résultat va de 1 (Consommation élevée) à 5 (Faible consommation).",
    gasScoreA2aTooltip:
      'Indique le niveau de consommation de gaz, mesurée en mètres cube (m3). Le résultat va de 1 (Consommation élevée) à 5 (Faible consommation).'
  },
  CompanyPopup: {
    Title: 'Message système',
    CompanyNotFound: 'Impossible de récupérer des données de la société sélectionnée'
  },
  PersonPopup: {
    Title: 'Message système',
    Desc: 'Impossible de récupérer des données de la personne sélectionnée'
  },
  PdfPopup: {
    Title: 'Confirmer opération',
    TextEnriched:
      "Une notification est envoyée dès la création du fichier PDF afin de pouvoir le télécharger et de l'enregistrer. Continuer l'opération ?",
    TextNotEnriched:
      "Enrichir d'abord la société sélectionnée avant de pouvoir télécharger le PDF. Le processus peut prendre quelques instants. Confirmer pour continuer."
  },
  AccessPurchasePage: "Requête de rapport d'affectation",
  CheckSubsidizedFinancing: 'Contrôle de facilité de financement',
  CompanySurveys: {
    FillOutCheckList: 'Remplir une liste de contrôles',
    SelectTitle: 'Sélectionner une liste de contrôles à renseigner',
    SurveyInfo: {
      Grey: 'Les listes de contrôles grises sont des questionnaires prêts à être renseignés.',
      Green: 'Les listes de contrôles vertes sont des questionnaires déjà renseignés.',
      Yellow: "Les listes de contrôles jaunes sont des questionnaires à l'état d'ébauche."
    },
    UploadFile: 'Charger fichier',
    UploadFileTooltip: 'Format accepté : {{acceptedFormat}} Taille maxi : {{maxSize}}Mo',
    SaveAsDraft: 'Enregistrer comme ébauche',
    SaveAndSend: 'Enregistrer et envoyer',
    ErrorInUploadDialog: {
      Title: 'Erreur de chargement',
      FormatFileError: 'Format de fichier non accepté.',
      MaxSizeError: 'Taille maxi de fichier dépassée.',
      NameTooLongError: `Nom de fichier trop long. Caract. maxi : ${MAX_FILE_NAME_LENGTH}.`
    },
    SavingFailedDialog: {
      Title: "Échec d'enregistrement",
      Text: "Erreur d'enregistrement de la liste de contrôles. Ressayer plus tard ou contacter l'assistance clientèle"
    },
    Confirmation: {
      Title: 'Envoyer une liste de contrôles à CRM',
      Text1:
        'Attention ! Un clic sur le bouton « Enregistrer et envoyer » envoie le questionnaire et ses pièces jointes au CRM.',
      Text2: "Le questionnaire n'est plus modifiable. Voulez-vous exécuter l'opération ?"
    }
  },
  Appointments: {
    Title: 'Rendez-vous'
  }
}
