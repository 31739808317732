import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useGetFilterQueriesAsDocument } from '../../../api/query/queries'
import { FETCH_QUERIES_TOP } from '../../../features/query/constants'
import { Text } from '../../../components/Text'
import { Dialog } from '../../../components/Dialog'
import { Select, Option } from '../../../components/Select'
import { QueryType } from '../../../types'

type Props = {
  submit: (queryId?: string, queryType?: QueryType) => void
  close: () => void
}

const Content = styled.div`
  display: flex;

  .text {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const LoadQueryDialog = ({ submit, close }: Props) => {
  const { t } = useTranslation('common')

  const [selectedQueryId, setSelectedQueryId] = useState<string | undefined>()

  const { data: allQueries, isLoading } = useGetFilterQueriesAsDocument({
    documentTypes: [QueryType.Queries, QueryType.OperationQueries],
    top: FETCH_QUERIES_TOP
  })

  return (
    <Dialog
      title={t('queries.loadQuery.dialogTitle')}
      width={900}
      visible
      loading={isLoading}
      footer=""
      cancelText={t('Cancel')}
      onCancel={close}
      okText={t('queries.loadQuery.OkBtn')}
      okButtonProps={{ disabled: !selectedQueryId }}
      onOk={() => submit(selectedQueryId)}
    >
      <Content>
        <div className="text">
          <Text size={24} bold block center>
            {t('queries.loadQuery.title')}
          </Text>
          <Text size="M" block center margin={{ bottom: '2em' }}>
            {t('queries.loadQuery.description')}
          </Text>
          <Text type="primary" size="M" bold block margin={{ top: '1em', bottom: '8px' }}>
            {t('queries.loadQuery.selectTitle')}
          </Text>
          <Select
            placeholder={t('Select')}
            disabled={isLoading}
            showSearch
            optionFilterProp="title"
            value={selectedQueryId}
            onChange={e => setSelectedQueryId(e as string)}
          >
            {_.map(allQueries, x => (
              <Option key={x.id} title={x.name} value={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
      </Content>
    </Dialog>
  )
}
