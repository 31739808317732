import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/icon-s-chevron-right-generic.svg'
import { ReactComponent as ClearIcon } from '../../assets/icons/close.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/circle-info-solid.svg'
import { Claim } from '../../authorization'
import { PROSPECTING_ANALYSIS } from '../../routes'
import { ClaimsWrapper } from '../ClaimsWrapper'
import { IconButton, Divider, LinkMenuButton } from './StyledComponents'
import { getHideSemanticSearch } from '../../features/config/selectors'
import { DialogActions } from '../../features/dialog/actions'

type Props = {
  searchText: string
  toggleSearch: () => void
}

export const ExpandedSearchBar = ({ toggleSearch, searchText }: Props) => {
  const { t } = useTranslation('header')
  const dispatch = useDispatch()

  const hideSemanticSearch = useSelector(getHideSemanticSearch)
  const [semanticSearchText, setSemanticSearchText] = useState(searchText)

  useEffect(() => {
    setSemanticSearchText(searchText)
  }, [searchText])

  const openInfoDialog = () => dispatch(DialogActions.openSearchInformationDialog())

  return (
    <>
      <IconButton onClick={openInfoDialog}>
        <InfoIcon />
      </IconButton>
      <IconButton onClick={toggleSearch}>
        <ClearIcon />
      </IconButton>
      {!hideSemanticSearch && (
        <ClaimsWrapper claims={Claim.Prospecting}>
          <Divider className="pc tablet" />
          <LinkMenuButton
            className="pc tablet"
            to={{
              pathname: PROSPECTING_ANALYSIS,
              search: window.location.search
            }}
            state={{
              shouldPreloadFilters: true,
              semanticPreFilled: semanticSearchText
            }}
          >
            {t('SemanticSearch')}
            <ChevronRightIcon height={16} width={16} />
          </LinkMenuButton>
        </ClaimsWrapper>
      )}
    </>
  )
}
