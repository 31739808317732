import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../../types'
import { FilterStateWrapper } from '../types'
import { WithReducer, WithUserIdOptional } from './types'
import { getUserId } from '../../user/selectors'
import { paramSelector } from '../../../utils/selectors'

export const selectState = (state: RootState, { reducer }: WithReducer): FilterStateWrapper['filters'] =>
  _.get(state, [..._.toPath(reducer), 'filters'])

export const getParamOrStoreUserId = createSelector(
  getUserId,
  paramSelector<WithUserIdOptional>(),
  (storeUserId, { userId: paramUserId }) => paramUserId || storeUserId
)
