/* eslint-disable max-classes-per-file */
import React from 'react'
import { ViewportDimensions } from '../../types'
import { gridTheme, BREAKPOINT_MOBILE } from '../../utils/theme'

export const ViewportSizeContext = React.createContext<ViewportDimensions>({
  viewportHeight: 0,
  viewportWidth: 0,
  isExtraSmall: false,
  isMobile: false,
  isMedium: false,
  isTablet: false,
  isLarge: false,
  isExtraLarge: false
})

type Props = {
  children: React.ReactNode
}

type State = Pick<ViewportDimensions, 'viewportHeight' | 'viewportWidth'>

export class ViewportSizeContextProvider extends React.PureComponent<Props, State> {
  state = {
    viewportHeight: window.innerHeight,
    viewportWidth: window.innerWidth
  }

  componentDidMount() {
    window.addEventListener('resize', this.setViewport)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setViewport)
  }

  setViewport = () => {
    this.setState({
      viewportHeight: window.innerHeight,
      viewportWidth: window.innerWidth
    })
  }

  render() {
    const { children } = this.props
    const { viewportWidth, viewportHeight } = this.state
    const { breakpoints } = gridTheme
    const isExtraSmall = viewportWidth <= BREAKPOINT_MOBILE
    const isMobile = viewportWidth <= breakpoints.xs
    const isTablet = viewportWidth < breakpoints.md
    const isMedium = viewportWidth >= breakpoints.md && viewportWidth < breakpoints.lg
    const isLarge = viewportWidth >= breakpoints.lg && viewportWidth < breakpoints.xl
    const isExtraLarge = viewportWidth >= breakpoints.xl
    return (
      <ViewportSizeContext.Provider
        value={{ viewportHeight, viewportWidth, isExtraSmall, isMobile, isTablet, isMedium, isLarge, isExtraLarge }}
      >
        {children}
      </ViewportSizeContext.Provider>
    )
  }
}

export const ViewportSizeContextConsumer = ViewportSizeContext.Consumer

export function withViewportSize<TProps>(WrappedComponent: React.ComponentType<TProps & ViewportDimensions>) {
  return class WithViewportSize extends React.PureComponent<TProps> {
    render() {
      return (
        <ViewportSizeContextConsumer>
          {({ viewportWidth, viewportHeight, isExtraSmall, isMobile, isTablet, isMedium, isLarge, isExtraLarge }) => {
            return (
              <WrappedComponent
                viewportWidth={viewportWidth}
                viewportHeight={viewportHeight}
                isExtraSmall={isExtraSmall}
                isMobile={isMobile}
                isTablet={isTablet}
                isMedium={isMedium}
                isLarge={isLarge}
                isExtraLarge={isExtraLarge}
                {...this.props}
              />
            )
          }}
        </ViewportSizeContextConsumer>
      )
    }
  }
}
