import moment, { Moment } from 'moment'
import { FormatNumberFunction } from './types'

export const TIME_FORMAT = 'HH:mm'

export const getLocalizedDateFormatPattern = () =>
  moment().locale(navigator.language).creationData().locale.longDateFormat('l')

export const getLocalizedDateFormatWithHourAndMinutesPattern = () => `${getLocalizedDateFormatPattern()} ${TIME_FORMAT}`

export const toLocalizedDateFormat = (date: Moment | Date | string | undefined) =>
  date !== undefined ? moment(date).locale(navigator.language).format('L') : ''

export const toLocalizedTimeFormat = (date: Moment | Date | string) =>
  moment(date).locale(navigator.language).format('LT')

export const toNamedDateFormat = (date: Moment | Date | string) => moment(date).format('dddd LL')

export const formatDateTime = (date: Moment | Date | string) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')

export const formatNumberThousands = (value: number | undefined, formatter: FormatNumberFunction) => {
  if (value === undefined) return ''

  if (Math.abs(value) < 1000) return formatter(value)
  return `${formatter(Math.round(value / 100) / 10)} K`
}

// BE is not sending timezone in dateTime either it is sending dates in UTC
export const convertDateTimeFromBackendWithoutTimezone = (backendDatetime: string | Date) =>
  moment(backendDatetime).utc(true)
