import styled from 'styled-components/macro'

export const Content = styled.div`
  display: flex;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
