import React from 'react'
import { Dropdown, Menu } from 'antd3'
import { DropDownProps } from 'antd3/lib/dropdown'
import styled from 'styled-components/macro'
import { Omit } from '../../utils/types'
import { IconButton, IconButtonType, IconButtonSize } from './IconButton'
import { ReactComponent as DotsIcon } from '../../assets/icons/icon-s-more-horizontal.svg'
import { SvgComponent } from '../../types/common'

const MenuButtonWrapper = styled.div`
  svg {
    vertical-align: middle;
    cursor: pointer;
  }
`

type Props = {
  type?: IconButtonType
  size?: IconButtonSize
  style?: React.CSSProperties
  onClick?: () => void
  children: React.ReactNode
  customIcon?: SvgComponent
} & Omit<DropDownProps, 'overlay'>

export const MenuButton = ({ type, size, style, onClick, children, customIcon: CustomIcon, ...rest }: Props) => (
  <MenuButtonWrapper style={style}>
    <Dropdown trigger={['click']} placement="bottomRight" {...rest} overlay={<Menu onClick={onClick}>{children}</Menu>}>
      <IconButton type={type} size={size} aria-label="menu-button">
        {CustomIcon ? <CustomIcon /> : <DotsIcon />}
      </IconButton>
    </Dropdown>
  </MenuButtonWrapper>
)

export const MenuItem = Menu.Item
