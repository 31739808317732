import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { EditProductDialogData } from './types'
import { HookForm } from '../../../components/Form'
import { InputField } from '../../../components/Form/Input'
import { Text } from '../../../components/Text'
import { StyledDialog } from '../components'
import { validateNameSynchronous } from '../../../utils/validators'
import { editProduct, productKeys } from '../../../api/products'
import { validateNewProductName } from '../../../api/products/validators'

export type EditProductFormState = {
  productName?: string
}

type Props = {
  data: EditProductDialogData
  close: () => void
}

export const EditProductDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()
  const { control, handleSubmit, formState, watch } = useForm<EditProductFormState>({
    mode: 'onChange'
  })

  const { isSubmitting, isValidating, errors } = formState

  const {
    mutate: mutateEditProduct,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(() => editProduct(data.productId, watch('productName')!), {
    onSuccess: () => {
      queryClient.invalidateQueries(productKeys.All())
    }
  })

  const asyncValidateProductName = async (value: any) => {
    const validationResponse = await validateNewProductName(value as string)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(false)(watch('productName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:Products.EditProduct.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={isIdle || isLoading ? t('common:Save') : t('common:Ok')}
      onOk={() => {
        if (isIdle) {
          return mutateEditProduct()
        }
        return close()
      }}
      okButtonProps={{
        disabled: !watch('productName') || isLoading || isValidating || !_.isEmpty(errors),
        loading: isValidating
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <HookForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} onSubmit={mutateEditProduct}>
        {(isIdle || isLoading) && (
          <>
            <Text block margin={{ bottom: 40 }}>
              {t('account:Products.EditProduct.Body')}
            </Text>
            <InputField
              name="productName"
              label={t('account:Products.EditProduct.NameInputLabel')}
              placeholder={t('account:Products.EditProduct.NameInputPlaceholder')}
              defaultValue={data.productName}
              control={control}
              formState={formState}
              validators={{
                validateNameSynchronous: validateNameSynchronous(false),
                asyncValidateProductName
              }}
            />
          </>
        )}
        {isSuccess && <p>{t('account:Products.EditProduct.Success')}</p>}
        {isError && <p>{t('account:Products.EditProduct.Error')}</p>}
      </HookForm>
    </StyledDialog>
  )
}
