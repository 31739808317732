import { GeoJsonProperties } from 'geojson'
import _ from 'lodash'
import { MapCountryCustomization } from './CountryCustomization'
import { IMapCountryCustomizations } from './types'

export const mapConfig = {
  level1Key: 'region',
  level2Key: 'country',
  dataKey: 'count',
  regionNameKey: 'NAME'
}

export class MapCountryCustomizationFareast extends MapCountryCustomization implements IMapCountryCustomizations {
  // eslint-disable-next-line class-methods-use-this
  getGeoJsonType = (zoom: number) => {
    if (zoom <= 4) return 'country'
    return 'regions'
  }

  // eslint-disable-next-line class-methods-use-this
  getPathFromGeoJsonFeature = (featureProps: GeoJsonProperties): string | undefined => {
    if (featureProps === null) return undefined

    const { level1Key, level2Key } = mapConfig
    const regionCode = _.toLower(_.get(featureProps, `${level1Key}`))
    const countryCode = _.toLower(_.get(featureProps, `${level2Key}`))

    if (regionCode) {
      return `${countryCode}.sub.${regionCode}`
    }
    return countryCode
  }
}
