import React from 'react'
import { Text, TextProps } from './Text'

type Props = {
  to?: string
  newTab?: boolean
} & TextProps

export const ExternalLinkText = ({ to, newTab, ...textProps }: Props) => {
  return (
    <a href={to} target={newTab ? '_blank' : undefined} rel="noreferrer">
      <Text {...textProps} primary hoverTextDecoration="underline" />
    </a>
  )
}
