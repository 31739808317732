import * as ApiTypes from '../../api/targeting/types'
import { FilterType } from '../filters/types'

export enum TargetingFilterId {
  companyPortfolioStatus = 'portfolioInfo.customerInfo.companyPortfolioStatus'
}

export type SelectedPortfolio = {
  id: string
  name: string
}

export type LayoutConfig = ApiTypes.LayoutConfigDto

export type TargetingFilter = {
  fieldId: TargetingFilterId
  type: FilterType
  value: string | number
}

export type DownloadType = 'PDFView' | 'DownloadModal' | null
