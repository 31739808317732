import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteCustomTrackDialogData } from './types'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'
import { StyledDialog, StyledDialogBody, ContainerItem, BoldText } from '../components'
import { DeleteCustomTracksRequest } from '../../../api/portfolioDownload/types'
import { deleteCustomTrack, getPortfolioDownloadKeys } from '../../../api/portfolioDownload'

type Props = {
  data: DeleteCustomTrackDialogData
  close: () => void
}

export const DeleteCustomTrackDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()

  const tr = React.useCallback(
    (key: string) => {
      return t(`account:PortfolioManagement.CustomTracks.DeleteDialog.${key}`, {
        count: data.itemId ? 1 : 2
      })
    },
    [data.itemId, t]
  )

  const {
    mutate: mutateDeleteCustomTracks,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation(
    () => {
      const deleteRequestForCustomTracks: DeleteCustomTracksRequest =
        !data.selection || data.itemId
          ? { selectedTrackIds: data.itemId ? [data.itemId] : [] }
          : {
              selectedTrackIds: !data.selection.isSelectAll ? data.selection.selectedIds : undefined,
              ignoredTrackIds: data.selection.isSelectAll ? data.selection.deselectedIds : undefined
            }
      return deleteCustomTrack(deleteRequestForCustomTracks)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPortfolioDownloadKeys.All())
        if (data.onCustomTracksDeleted) data.onCustomTracksDeleted()
      }
    }
  )

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={tr('Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) return mutateDeleteCustomTracks()
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {isIdle && (
            <div>
              <BoldText>{tr('Subtitle')}</BoldText>
              <p>{tr('Body')}</p>
            </div>
          )}
          {isSuccess && <p>{tr('Success')}</p>}
          {isError && <p>{tr('Error')}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
