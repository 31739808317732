import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as dialogSelectors from '../../../features/dialog/selectors'
import { DialogActions } from '../../../features/dialog/actions'
import { PortfolioChangeNameDialog } from './ChangeNameDialog'

export const PortfolioChangeNameDialogContainer = () => {
  const { state: view } = useSelector(dialogSelectors.getVisibleDialogData)

  const dispatch = useDispatch()
  const close = () => dispatch(DialogActions.closeDialog())

  return <PortfolioChangeNameDialog view={view} close={close} />
}
