import React from 'react'
import styled, { DefaultTheme } from 'styled-components/macro'
import { Spin as SpinAntd } from 'antd3'
import { SpinProps } from 'antd3/lib/spin'

export type Type = 'light' | 'dark'

export const colorByType = (t: DefaultTheme, type?: Type) => {
  switch (type) {
    case 'light':
      return t.colors.white
    default:
      return t.colors.main
  }
}

export enum Size {
  Small,
  ExtraLarge
}

export const SmallSpin = styled(SpinAntd)<{ color?: string; type?: Type; role?: string }>`
  &.ant-spin {
    .ant-spin-dot {
      i {
        background-color: ${({ theme, color, type = 'dark' }) => color || colorByType(theme, type)};
      }
    }
  }
`

const DOT_SIZE = 25

export const ExtraLargeSpin = styled(SpinAntd)<{ color?: string; type?: Type; role?: string }>`
  &.ant-spin {
    .ant-spin-dot {
      font-size: ${DOT_SIZE * 2}px;
      i {
        width: ${DOT_SIZE}px;
        height: ${DOT_SIZE}px;
        background-color: ${({ theme, color, type = 'dark' }) => color || colorByType(theme, type)};
      }
    }
  }
`

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
`

type Props = {
  color?: string
  size?: Size
  type?: Type
  role?: string
} & Omit<SpinProps, 'size'>

export const Spin = ({ size, color, type = 'dark', delay = 150, role = 'progressbar', ...rest }: Props) => {
  const def = <ExtraLargeSpin data-testid="spin" color={color} type={type} delay={delay} {...rest} role={role} />
  if (size === undefined) return def
  switch (size) {
    case Size.Small:
      return <SmallSpin data-testid="spin" color={color} type={type} delay={delay} {...rest} role={role} />
    default:
      return def
  }
}

export const CenteredSpin = (props: Props) => {
  return (
    <SpinWrapper>
      <Spin {...props} />
    </SpinWrapper>
  )
}
