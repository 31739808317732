import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import { Text, Title } from '../Text'
import { Col } from '../Grid'

export const Paragraph = styled(Text).attrs(props => ({
  block: true,
  ...props
}))`
  line-height: 24px;
  margin-bottom: 10px;
`

export const TextTitle = styled(Title).attrs(props => ({
  level: 2,
  ...props
}))`
  line-height: 36px;
  margin-bottom: 10px;
`

export const ContentContainer = styled(Col).attrs(props => ({
  ...props
}))`
  max-width: 620px;
  ${media.xl`
    max-width: 40vw;
    margin: 0 30px;
  `}
  ${media.sm`
    padding-left: 0;
    padding-right: 0;
  `}
`
export const Link = styled.a`
  color: ${({ theme }) => theme.colors.main};
  font-weight: bold;
  word-break: break-word;
  &:hover {
    color: ${({ theme }) => theme.colors.pumpkinOrange};
  }
`
