import { PortfolioStats } from '../../api/portfolio/types'

export type PortfolioActionsItem = {
  text: string
  action?: () => void
  loading?: boolean
  disabled?: boolean
  tooltip?: string
}

export enum PortfolioChangeNameViewState {
  ChangeNameView = 'ChangeNameView',
  ChangeNameDone = 'ChangeNameDone'
}

export enum DeletePortfolioViewState {
  DeletePortfolioView = 'DeletePortfolioView',
  DeletePortfolioDone = 'DeletePortfolioDone'
}

export type CompanyStatusInfo = {
  status: keyof PortfolioStats
  tKey: string
}
