import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'
import { Persistor } from 'redux-persist'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { logout as logoutApi } from '../../api/login'
import { LoginResponse } from '../../api/login/types'
import { getReleaseNotes, setLastSeenReleaseNote } from '../../api/releaseNotes'
import { ReleaseNotesResponse } from '../../api/releaseNotes/types'
import { refreshToken } from '../../api/users'
import { Claim, ClaimKey } from '../../authorization'
import { LOGIN } from '../../routes'
import { generateRefreshUrl, parseClaims } from '../../utils/helpers'
import { history as historyObject } from '../../utils/history'
import { SignalR } from '../../utils/signalR'
import { RehydrateAction } from '../../utils/types'
import { setAuthorizationHeader, setCurrency } from '../axios/helpers'
import { ConfigActions } from '../config/actions'
import { startSignalR } from '../config/sagas'
import { DialogActions } from '../dialog/actions'
import { GlobalActions } from '../globalActions'
import { queryClient } from '../queryclient'
import { LocalStorageKeys } from '../storage/types'
import { getParsedValueFromLocalStorage } from '../storage/utils'
import {
  CHANGE_CURRENCY,
  DISPLAY_NEW_RELEASE_NOTES,
  FORCE_LOGOUT,
  LOGOUT,
  REFRESH_TOKEN,
  TOKEN_LOADED,
  UserActions
} from './actions'
import { FORCED_LOGOUT_HASH } from './constants'
import { UserState } from './reducer'
import { getToken } from './selectors'

export function* processToken(data?: LoginResponse, callback?: () => void, persistor?: Persistor) {
  if (!data) return
  const validClaims = _.intersection(_.keys(Claim), data.roles) as Array<ClaimKey>
  const claimFlags = parseClaims(validClaims)

  yield call(setAuthorizationHeader, axios, `Bearer ${data.token}`)
  yield put(UserActions.setToken(data.token, claimFlags, data.userId, data.organizationId))
  if (persistor) {
    yield call(persistor.flush)
  }
  if (callback) callback()
}

export const disconnectWebsockets = () => SignalR.stop()

export const clearSessionStorage = () => {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem('operationsListViewColumns:usePersistedState')
    window.sessionStorage.removeItem('operationQuery:usePersistedState')
  }
}

type TokenContainer = {
  token?: string
}

const isTokenContainer = (obj: any): obj is TokenContainer => {
  return obj && 'token' in obj
}

export const tryGetJwtTokenFromLocalStorage = () => {
  try {
    const obj = getParsedValueFromLocalStorage<TokenContainer | null>(LocalStorageKeys.USER, null)
    if (isTokenContainer(obj)) {
      // token in local storage is wrapped in quotes, so we need to remove them
      return obj.token?.replaceAll('"', '') ?? null
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error parsing user json', e)
  }
  return null
}

export const clearQueryClient = () => queryClient.clear()

export function* logout(
  persistor: Persistor | undefined,
  action: ReturnType<typeof UserActions.logout>,
  redirect = true
) {
  yield put(GlobalActions.clearStore())
  yield call(disconnectWebsockets)
  if (!action.payload.clientSideOnly) {
    try {
      yield call(logoutApi)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  yield call(setAuthorizationHeader, axios, undefined)
  yield call(setCurrency, axios, undefined)
  yield call(clearQueryClient)

  if (redirect) {
    yield put(ConfigActions.resetAfterLogout())
  }
  yield put(ConfigActions.setSignalRStarted(false))
  yield put(DialogActions.closeDialog())

  if (persistor) {
    yield call(persistor.purge)
    yield call(persistor.persist)
  }

  if (redirect) {
    historyObject.push(LOGIN)
  }

  yield call(clearSessionStorage)
}

export function* forceLogout(persistor: Persistor | undefined) {
  if (persistor) {
    const localStorageToken: ReturnType<typeof tryGetJwtTokenFromLocalStorage> = yield call(
      tryGetJwtTokenFromLocalStorage
    )
    const storeToken: ReturnType<typeof getToken> = yield select(getToken)
    const isActiveSession = localStorageToken === storeToken
    if (isActiveSession) {
      yield call(persistor.purge)
      // to prevent persisting any new values while the user is redirected to the login page
      yield call(persistor.pause)
      yield call(clearSessionStorage)
    }
  }
  window.location.href = `${LOGIN}#${FORCED_LOGOUT_HASH}`
}

export function* reloadToken(persistor: Persistor | undefined, action: ReturnType<typeof UserActions.tokenLoaded>) {
  if (action.payload.loginAs) {
    // @ts-ignore
    yield* logout(persistor, UserActions.logout(false, action.payload.navigate), false)
  }
  // @ts-ignore
  yield* processToken(action.payload.data, action.payload.callback, persistor)

  if (action.payload.loginAs) {
    yield put(ConfigActions.resetAfterLogout())
  }
}

export function* rehydrateUser(action: RehydrateAction<UserState>) {
  if (action.key === 'user') {
    const token = _.get(action, 'payload.token')
    if (token) {
      yield call(setAuthorizationHeader, axios, `Bearer ${token}`)
    }
  }
}

export function* handleRefreshToken() {
  const response: AxiosResponse<LoginResponse> = yield call(refreshToken)
  yield call(processToken, response.data)
  yield call(startSignalR)
}

export function* handleCurrencyChange(action: ReturnType<typeof UserActions.changeCurrency>) {
  const { currency, reload, location, navigate } = action.payload

  yield call(setCurrency, axios, currency)
  yield put(UserActions.setCurrency(currency))

  if (reload) {
    const { search, state, pathname } = location
    navigate(
      {
        search,
        pathname: generateRefreshUrl(pathname)
      },
      { replace: true, state }
    )
  }
}

export function* displayReleaseNotes(action: ReturnType<typeof UserActions.displayNewReleaseNotes>) {
  const { data }: { data: ReleaseNotesResponse } = yield call(getReleaseNotes, action.payload)

  if (!_.isEmpty(data.items)) {
    yield put(DialogActions.showDialogWithData('ReleaseNotes', data.items))
    const lastId = _.head(_.head(data.items)?.releaseNoteContents)?.releaseNoteId
    if (lastId) yield call(setLastSeenReleaseNote, lastId)
  }
}

export function* rootSaga(persistor?: Persistor) {
  yield all([
    takeEvery(TOKEN_LOADED, reloadToken, persistor),
    takeEvery('persist/REHYDRATE', rehydrateUser),
    // @ts-ignore
    takeLatest(LOGOUT, logout, persistor),
    takeLatest(FORCE_LOGOUT, forceLogout, persistor),
    takeLatest(REFRESH_TOKEN, handleRefreshToken),
    takeLatest(CHANGE_CURRENCY, handleCurrencyChange),
    takeLatest(DISPLAY_NEW_RELEASE_NOTES, displayReleaseNotes)
  ])
}
