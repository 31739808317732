import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { DeleteProductDialogData } from './types'
import PortfolioImage from '../../../assets/images/portfolio-management-small.svg'
import { StyledDialog, StyledDialogBody, ContainerItem, BoldText } from '../components'
import { deleteProduct, productKeys } from '../../../api/products'
import { WithErrorCode } from '../../../api/common/types'

type Props = {
  data: DeleteProductDialogData
  close: () => void
}

export const DeleteProductDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['common', 'account'])
  const queryClient = useQueryClient()
  const [errorMessageCode, setErrorMessageCode] = useState<string>('Error')

  const {
    mutate: mutateDeleteProduct,
    isIdle,
    isError,
    isSuccess,
    isLoading
  } = useMutation<AxiosResponse, AxiosError<WithErrorCode>>(() => deleteProduct(data.productId), {
    onSuccess: () => {
      queryClient.invalidateQueries(productKeys.All())
    },
    onError: e => {
      if (e?.response?.status === 403) {
        if (e?.response?.data?.errorCode?.code === 'ActiveCampaignsError')
          setErrorMessageCode('ErrorAssignedToActiveCampaign')
        if (e?.response?.data?.errorCode?.code === 'ActiveActionsError')
          setErrorMessageCode('ErrorActiveActionsForSameOrganization')
      }
    }
  })

  const okButtonText = isIdle || isLoading ? t('common:Delete') : t('common:Ok')

  return (
    <StyledDialog
      loading={isLoading}
      visible
      title={t('account:Products.DeleteProduct.Title')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) {
          return mutateDeleteProduct()
        }
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      cancelButtonProps={{
        disabled: isLoading
      }}
      footer
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          {(isIdle || isLoading) && (
            <div>
              <BoldText>{t('account:Products.DeleteProduct.Subtitle', { productName: data.productName })}</BoldText>
              <p>{t('account:Products.DeleteProduct.Body')}</p>
            </div>
          )}
          {isSuccess && <p>{t('account:Products.DeleteProduct.Success')}</p>}
          {isError && <p>{t(`account:Products.DeleteProduct.${errorMessageCode}`)}</p>}
        </ContainerItem>
        <div>
          <img src={PortfolioImage} alt="portfolio" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
