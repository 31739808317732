import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg'
import { APPOINTMENT_CREATE, ACCOUNT_APPOINTMENTS } from '../../routes'
import { Text } from '../Text'
import { IconButton } from '../Button'
import { ActionWrapper, InfoWrapper, MenuDivider, LinkBoxWrapper } from './StyledComponents'

export const AppointmentMenu = () => {
  const { t } = useTranslation('appointment')

  return (
    <div>
      <Link to={APPOINTMENT_CREATE} role="link" aria-label={t('CreateAppointmentButton')}>
        <LinkBoxWrapper>
          <InfoWrapper>
            <Text block text={t('CreateAppointmentButton')} bold color="white" />
          </InfoWrapper>
          <ActionWrapper>
            <IconButton ghost>
              <ChevronRight />
            </IconButton>
          </ActionWrapper>
        </LinkBoxWrapper>
      </Link>
      <MenuDivider />
      <Link to={ACCOUNT_APPOINTMENTS} role="link" aria-label={t('ManageAppointmentsButton')}>
        <LinkBoxWrapper>
          <InfoWrapper>
            <Text block text={t('ManageAppointmentsButton')} bold color="white" />
          </InfoWrapper>
          <ActionWrapper>
            <IconButton ghost>
              <ChevronRight />
            </IconButton>
          </ActionWrapper>
        </LinkBoxWrapper>
      </Link>
    </div>
  )
}
