import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { RootState } from '../../../features/types'
import { FilterType } from '../../../features/filters/types'
import { getActiveTargetingFilter } from '../../../features/targeting/selectors'
import { TargetingActions } from '../../../features/targeting/actions'
import { TargetingFilterId } from '../../../features/targeting/types'
import { createTargetingFilter } from '../../../features/targeting/helpers'

export const useLoadPortfolioStatus = () => {
  const dispatch = useDispatch()

  const selectedStatus = useSelector((state: RootState) =>
    getActiveTargetingFilter(state, TargetingFilterId.companyPortfolioStatus)
  )

  const setSelectedStatus = (value: string) => {
    const filter = createTargetingFilter(TargetingFilterId.companyPortfolioStatus, FilterType.Text, value)
    dispatch(TargetingActions.setActiveFilters(filter))
  }
  const clearSelectedStatus = () => {
    if (!_.isNil(selectedStatus)) {
      dispatch(TargetingActions.unsetActiveFilter(TargetingFilterId.companyPortfolioStatus))
    }
  }

  return {
    selectedStatus,
    setSelectedStatus,
    clearSelectedStatus
  }
}
