import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useUpdateCampaign } from '../../../../../api/salestool'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import { Dialog } from '../../../../../components/Dialog'
import { BaseDialog } from '../../../BaseDialog'
import { DialogActions } from '../../../../../features/dialog/actions'
import { queryClient } from '../../../../../features/queryclient'
import { getFiltersKeys } from '../../../../../api/filters'
import { validateNewCampaignName } from '../../../../../api/salestool/validators'
import { validateNameSynchronous } from '../../../../../utils/validators'
import { HookForm, InputField } from '../../../../../components/Form'
import { Text } from '../../../../../components/Text'

type Props = {
  data: {
    campaignId: string
  }
}

const Label = styled.div`
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.33;
  color: ${({ theme: { colors } }) => colors.main};
`

export const ChangeCampaignNameDialog = ({ data: { campaignId } }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const dispatch = useDispatch()

  const { control, handleSubmit, formState, watch } = useForm({
    mode: 'onChange'
  })
  const { isSubmitting, isValidating, errors } = formState

  const {
    mutate: mutateChangeName,
    isLoading,
    isSuccess,
    isError,
    isIdle
  } = useUpdateCampaign(campaignId, {
    campaignName: watch('campaignName')
  })

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const onSuccessfulNameChange = useCallback(() => {
    closeDialog()
    queryClient.invalidateQueries(getFiltersKeys.DomainNodesData(), { refetchType: 'all' })
  }, [closeDialog])

  const asyncValidateCampaignName = async (value: any) => {
    const validationResponse = await validateNewCampaignName(value)

    if (validationResponse === undefined) {
      const syncValidation = validateNameSynchronous(false)(watch('campaignName'))
      if (syncValidation) return syncValidation
    }

    return validationResponse
  }

  const props = {
    destroyOnClose: false,
    width: 800,
    visible: true,
    loading: isLoading,
    onCancel: closeDialog
  }

  return (
    <Dialog
      {...props}
      title={t('Management.Campaign.ChangeCampaignName')}
      footer=""
      cancelText={isIdle ? t('common:Cancel') : undefined}
      okText={isIdle ? t('common:Continue') : t('common:Ok')}
      okButtonProps={{
        disabled: !watch('campaignName') || isLoading || isValidating || !_.isEmpty(errors),
        loading: isValidating
      }}
      onOk={() => {
        if (isIdle) return mutateChangeName()
        return onSuccessfulNameChange()
      }}
    >
      {(isIdle || isLoading) && (
        <HookForm handleSubmit={handleSubmit} onSubmit={mutateChangeName} isSubmitting={isSubmitting}>
          <>
            <Text block margin={{ bottom: 40 }}>
              {t('salestool:Management.Campaign.EnterARepresentativeName')}
            </Text>
            <Label>{t('salestool:CampaignName')}</Label>
            <InputField
              hideLabel
              control={control}
              formState={formState}
              name="campaignName"
              label={t('salestool:CampaignName')}
              placeholder={t('salestool:Management.Campaign.InsertCampaignName')}
              validators={{
                validateNameSynchronous: validateNameSynchronous(false),
                asyncValidateCampaignName
              }}
            />
          </>
        </HookForm>
      )}
      {isSuccess && (
        <BaseDialog
          content={t('Management.Campaign.NameChangedCorrectly')}
          boldText={watch('campaignName')}
          image={<img src={OkImage} alt="ok" />}
        />
      )}
      {isError && <BaseDialog content={t('error:ChangeCampaignName')} image={<img src={KoImage} alt="error" />} />}
    </Dialog>
  )
}
