import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { DrawerNotification } from './DrawerNotification'
import { Button } from '../Button'
import * as notificationSelectors from '../../features/notifications/selectors'
import * as loadingSelectors from '../../features/loading/selectors'
import { NotificationActions } from '../../features/notifications/actions'
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg'
import { LoadingOverlayContainer } from '../Loading'
import { theme, HEADER_HEIGHT } from '../../utils/theme'
import * as routes from '../../routes'
import { hiddenScrollMixin } from '../../utils/cssMixins'
import { Text } from '../Text'
import { DrawerNotificationProgress } from './DrawerNotificationProgress'
import { ConditionallyVisible } from '../HideFeature'
import { getNumberOfNotificationInDrawer } from '../../features/config/selectors'

export const Wrapper = styled.div`
  max-height: calc(100vh - ${HEADER_HEIGHT}px);
  min-height: 400px;
  overflow: auto;
  ${hiddenScrollMixin}
`

export const NotificationsWrapper = styled.div`
  min-height: 400px;
`

export const NoNotificationWrapper = styled.div`
  height: 352px;
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const NoNotificationTitle = styled(Text)`
  margin-bottom: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px;
`

export const PushEventsWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
`

type NotificationsProps = {
  onClose: () => void
}

export const DrawerNotifications = ({ onClose }: NotificationsProps) => {
  const { t } = useTranslation('header')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const pushEvents = useSelector(notificationSelectors.getPushEvents)
  const notificationsLoading = useSelector(loadingSelectors.areNotificationsLoading)
  const notifications = useSelector(notificationSelectors.getNotificationsForDrawer)
  const numberOfNotificationsToLoad = useSelector(getNumberOfNotificationInDrawer)

  const fetchNotifications = (limit: number) => {
    dispatch(NotificationActions.fetchNotifications(limit))
  }
  const fetchSetNotificationRead = (notificationId: string) => {
    dispatch(NotificationActions.fetchSetNotificationRead(notificationId, true, true))
  }
  const redirectToArchive = useCallback(() => {
    navigate({ pathname: routes.NOTIFICATIONS_ARCHIVE })
  }, [navigate])

  useEffect(() => {
    fetchNotifications(numberOfNotificationsToLoad)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const redirectToArchiveCallback = useCallback(() => {
    redirectToArchive()
    onClose()
  }, [onClose, redirectToArchive])

  return (
    <Wrapper>
      <NotificationsWrapper>
        <PushEventsWrapper>
          {_.map(pushEvents, (event, index) => (
            <DrawerNotificationProgress pushEvent={event} key={index} />
          ))}
        </PushEventsWrapper>
        <ConditionallyVisible
          condition={notificationsLoading || notifications.length > 0}
          fallback={
            <NoNotificationWrapper>
              <NoNotificationTitle bold size="L" type="ghost">
                {t('notification:NotificationArchive.Welcome')}
              </NoNotificationTitle>
              <Text size="L" type="ghost">
                {t('notification:NotificationArchive.NoNotifications')}
              </Text>
            </NoNotificationWrapper>
          }
        >
          <LoadingOverlayContainer loading={notificationsLoading} overlayColor={theme.colors.main} type="light">
            <NotificationsWrapper>
              {_.map(notifications, (notification, index) => (
                <DrawerNotification
                  onClose={onClose}
                  notification={notification}
                  key={index}
                  onRead={fetchSetNotificationRead}
                />
              ))}
            </NotificationsWrapper>
          </LoadingOverlayContainer>
          <ConditionallyVisible condition={!notificationsLoading}>
            <ButtonWrapper>
              <Button onClick={redirectToArchiveCallback} type="ghost" iconRight={<ChevronRight />}>
                {t('Notification.ViewAll')}
              </Button>
            </ButtonWrapper>
          </ConditionallyVisible>
        </ConditionallyVisible>
      </NotificationsWrapper>
    </Wrapper>
  )
}
