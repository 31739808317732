import styled from 'styled-components/macro'

export const WizardNavWrapper = styled.div`
  text-align: center;

  .nav-buttons {
    display: flex;
    padding: 0.5em 0.5em 1.5em 0.5em;

    > *:not(:first-child) {
      margin-left: 1em;
    }

    .ant-btn {
      min-width: 100px;
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;

      &.btn-back {
        background-color: ${({ theme: { colors } }) => colors.paleGrey};
        color: ${({ theme: { colors } }) => colors.main};
      }
    }
  }
`
