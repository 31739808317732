import { Menu } from 'antd3'
import React from 'react'
import styled from 'styled-components/macro'
import { ComponentProps } from '../../types'
import { DropdownMenuButton } from './DropdownMenuButton'

const MenuStyled = styled(Menu)`
  min-height: 30px;
  max-height: 350px;
  overflow: hidden auto;
`

const DropdownMenuButtonStyled = styled(DropdownMenuButton)`
  && .ant-btn > div > span {
    color: ${({ theme }) => theme.colors.brownGray};
    font-weight: normal;
  }
`

export const DropdownMenuButtonWithScrollbar: React.FC<ComponentProps<typeof DropdownMenuButton>> = props => (
  <DropdownMenuButtonStyled MenuComponent={MenuStyled} {...props} />
)
