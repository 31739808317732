import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useGetAvailableCountries } from '../../api/config'
import { contactUs, ContactUsData } from '../../api/messages'
import Logo from '../../assets/images/logo-blue.svg'
import { Button } from '../../components/Button'
import { InputField, TextAreaInputField, SelectField, HookForm } from '../../components/Form'
import { CheckboxField } from '../../components/Form/Checkbox'
import { Image } from '../../components/Image'
import { Option } from '../../components/Select'
import { Text } from '../../components/Text'
import { getCompanyName, getInstanceCode } from '../../features/config/selectors'
import { DialogActions } from '../../features/dialog/actions'
import {
  getOrganizationCode,
  getOrganizationCountryCode,
  getOrganizationName,
  getSelectedLanguage,
  getUserEmail,
  getUserFirstName,
  getUserLastName,
  isLoggedIn
} from '../../features/user/selectors'
import { PRIVACY_POLICY } from '../../routes'
import { required, length, emptyOrLength, isEmail, onlyNumbersOrSpace } from '../../utils/validators'
import { ContactViewType } from './ContactDialog/types'

const FormRow = styled.div.withConfig<{ margin?: string }>({ shouldForwardProp: p => p !== 'margin' })`
  width: 100%;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.main};
  font-weight: bold;
  word-break: break-word;
  &:hover {
    color: ${({ theme }) => theme.colors.pumpkinOrange};
  }

  font-size: ${({ theme }) => theme.fontSizes.small};
`

const recaptchaActionName = 'contactus'

export type ContactUsFormValues = {
  agree: boolean
} & ContactUsData

const useContactForm = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation(['contact'])
  const instanceCode = useSelector(getInstanceCode)
  const isUserLoggedIn = useSelector(isLoggedIn)

  const name = useSelector(getUserFirstName)
  const surname = useSelector(getUserLastName)
  const email = useSelector(getUserEmail)
  const company = useSelector(getOrganizationName)
  const companyCode = useSelector(getOrganizationCode)
  const language = useSelector(getSelectedLanguage)
  const countryCode = useSelector(getOrganizationCountryCode)

  const { data: availableCountries } = useGetAvailableCountries(instanceCode, i18n.language, isUserLoggedIn)

  const methods = useForm<ContactUsFormValues>({
    defaultValues: {
      name,
      surname,
      email,
      company,
      companyCode,
      language,
      countryCode,
      message: '',
      agree: false
    }
  })

  useEffect(() => {
    if (methods.getValues('countryCode') === '' && availableCountries?.length === 1) {
      methods.setValue('countryCode', availableCountries[0].code)
    }
  }, [availableCountries, dispatch, methods])

  const mutation = useMutation(
    () => {
      const values = methods.getValues()
      return contactUs({ ..._.omit(values, 'agree'), instanceCode })
    },
    {
      onSuccess: () => {
        dispatch(DialogActions.showDialogWithData('Contact', { state: ContactViewType.DONE }))
      },
      onError: () => {
        dispatch(
          DialogActions.showDialogWithData('WarningDialog', {
            title: t('error:Warning'),
            text: t('error:ContactError')
          })
        )
      }
    }
  )

  return { methods, mutation }
}

export const ContactForm = () => {
  const { t, i18n } = useTranslation(['contact'])
  const { executeRecaptcha } = useGoogleReCaptcha()

  const instanceCode = useSelector(getInstanceCode)
  const companyName = useSelector(getCompanyName)
  const isUserLoggedIn = useSelector(isLoggedIn)

  const { data: availableCountries, isLoading } = useGetAvailableCountries(instanceCode, i18n.language, isUserLoggedIn)

  const { methods, mutation } = useContactForm()

  const onSubmit = async () => {
    const isValid = await methods.trigger()

    if (isValid) {
      if (!isUserLoggedIn) {
        const result = executeRecaptcha ? await executeRecaptcha(recaptchaActionName) : undefined
        methods.setValue('recaptcha', result)
      }
      mutation.mutate()
    }
  }

  return (
    <HookForm
      handleSubmit={methods.handleSubmit}
      onSubmit={onSubmit}
      isSubmitting={methods.formState.isSubmitting || isLoading}
    >
      <FormRow>
        <Image src={Logo} alt="logo" height={40} />
      </FormRow>
      <FormRow margin="24px 0 40px 0">
        <Text size="L" color="grayishBrown" text={t('ContactUsText', { companyName })} />
      </FormRow>
      <FormRow margin="15px 0">
        <InputField
          name="name"
          label={t('Name')}
          placeholder={t('InsertName')}
          formState={methods.formState}
          validators={{ required, length: length({ min: 3 }) }}
          control={methods.control}
        />
      </FormRow>
      <FormRow margin="15px 0">
        <InputField
          name="surname"
          label={t('Surname')}
          placeholder={t('InsertSurname')}
          formState={methods.formState}
          validators={{ required, length: length({ min: 3 }) }}
          control={methods.control}
        />
      </FormRow>
      <FormRow margin="15px 0">
        <InputField
          name="company"
          label={t('Company')}
          placeholder={t('InsertCompany')}
          formState={methods.formState}
          validators={{ required, length: length({ min: 3 }) }}
          control={methods.control}
        />
      </FormRow>
      {!isUserLoggedIn && availableCountries && availableCountries.length > 1 && (
        <FormRow margin="15px 0">
          <SelectField
            name="countryCode"
            label={t('Country')}
            placeholder={t('InsertCountry')}
            formState={methods.formState}
            validators={{ required }}
            control={methods.control}
          >
            {_.map(availableCountries, ({ name: countryName, code }) => (
              <Option key={code} value={code}>
                {countryName}
              </Option>
            ))}
          </SelectField>
        </FormRow>
      )}
      <FormRow margin="15px 0">
        <InputField
          name="jobTitle"
          label={t('JobTitle')}
          placeholder={t('InsertJobTitle')}
          formState={methods.formState}
          validators={{ emptyOrLength: emptyOrLength({ min: 3 }) }}
          control={methods.control}
        />
      </FormRow>
      <FormRow margin="15px 0">
        <InputField
          name="email"
          label={t('Email')}
          placeholder={t('InsertEmail')}
          formState={methods.formState}
          validators={{ required, isEmail }}
          control={methods.control}
        />
      </FormRow>
      <FormRow margin="15px 0">
        <InputField
          name="phoneNumber"
          label={t('Telephone')}
          placeholder={t('InsertTelephone')}
          formState={methods.formState}
          validators={{
            emptyOrLength: emptyOrLength({ max: 16 }),
            onlyNumbersOrSpace: onlyNumbersOrSpace({ canBeEmpty: true })
          }}
          control={methods.control}
        />
      </FormRow>
      <FormRow>
        <TextAreaInputField
          name="message"
          label={t('Message')}
          rows={4}
          placeholder={t('InsertMessage')}
          formState={methods.formState}
          validators={{ required, length: length({ min: 5 }) }}
          control={methods.control}
        />
      </FormRow>
      <FormRow margin="24px 0 40px 0">
        <CheckboxField
          name="agree"
          formState={methods.formState}
          control={methods.control}
          validators={{
            agree: (value: boolean) => {
              if (!value) {
                return 'MustAgreeWithPrivacyPolicy'
              }
              return undefined
            }
          }}
        >
          <span>
            <Text color="grayishBrown">{`${t('IAgreeWith')} `}</Text>
            <Link href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
              {t('PrivacyPolicy')}
            </Link>
          </span>
        </CheckboxField>
      </FormRow>
      <FormRow>
        <Button fluid type="primary" size="large" htmlType="submit">
          {t('Send')}
        </Button>
      </FormRow>
    </HookForm>
  )
}
