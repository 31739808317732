import _ from 'lodash'
import React from 'react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SelectProps as AntSelectProps, OptionProps, SelectValue } from 'antd3/lib/select'
import styled from 'styled-components/macro'
import { Form } from 'antd3'
import { Select, Option, StyledSelect } from '../Select'
import { getError, getErrorMessage } from '../../features/form/utils'
import { FormError } from '../../utils/validators'
import { Text } from '../Text'
import { FormItem as OperationFormItem } from '../../pages/Operations/QueryBuilder/components'

const FormItem = styled(Form.Item)`
  &&& {
    label {
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: normal;
      color: ${({ theme }) => theme.colors.main} !important;
    }
  }
`

export const HookSelect = <T extends FieldValues>({
  control,
  name,
  rules,
  value,
  placeholder,
  defaultValue,
  ...props
}: React.PropsWithChildren<AntSelectProps & UseControllerProps<T>>) => {
  const { t } = useTranslation('common')
  return (
    <Controller<T>
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange: fieldOnChange, ref, onBlur, value: fieldValue } }) => (
        <Select
          ref={ref}
          onBlur={onBlur}
          placeholder={placeholder || t('Select')}
          {...props}
          onChange={(e, option) => {
            if (props.onChange) props.onChange(e, option)
            fieldOnChange(e)
          }}
          value={value ?? (fieldValue as SelectValue)}
          defaultValue={defaultValue}
        />
      )}
    />
  )
}

type SelectProps = {
  placeholder?: string
  loading?: boolean
  showSearch?: boolean
  optionFilterProp?: string
  ignoreErrorName?: string
  useOperationForm?: boolean
} & AntSelectProps

type SelectFieldProps<T extends FieldValues> = {
  label?: string
  required?: boolean
  disabled?: boolean
  hideLabel?: boolean
  formState: FormState<any>
  colon?: boolean
  validators?: Record<
    string,
    (value: any, values: T) => Promise<FormError | FormError[]> | FormError | FormError[] | undefined
  >
} & SelectProps &
  UseControllerProps<T>

export const SelectField = <T extends FieldValues>(props: React.PropsWithChildren<SelectFieldProps<T>>) => {
  const { t } = useTranslation(['common', 'error'])
  const {
    required,
    disabled,
    formState,
    label = '',
    placeholder,
    hideLabel,
    colon = false,
    name,
    ignoreErrorName,
    useOperationForm
  } = props
  const { validators, rules } = props
  const { errors, isSubmitting } = formState
  const errorMessage = getError(errors, name)
  // eslint-disable-next-line lodash/path-style
  const ignoreErrorMessage = _.get(errors, `${ignoreErrorName}.message`)

  const isDisabled = disabled || isSubmitting

  let rulesOverride
  if (validators) {
    rulesOverride = _.reduce(validators, (acc, v, key) => ({ ...acc, [key]: getErrorMessage(v, label ?? '', t) }), {})
  }

  const FormComponent = useOperationForm ? OperationFormItem : FormItem

  return (
    <FormComponent
      className="rf-field"
      label={!hideLabel ? label : undefined}
      colon={colon}
      required={required}
      validateStatus={errorMessage && !ignoreErrorMessage ? 'error' : 'success'}
      help={ignoreErrorMessage ? undefined : errorMessage}
    >
      <HookSelect<T>
        aria-label={label}
        {...props}
        rules={validators ? { validate: { ...rulesOverride } } : rules}
        placeholder={placeholder || t('common:Select')}
        disabled={isDisabled}
      />
    </FormComponent>
  )
}

type MultiSelectProps<T extends FieldValues> = {
  placeholder?: string
  showSearch?: boolean
  optionFilterProp?: string
  maxTagCount?: number
  onSelectAll?: (checked: boolean) => void
  selectAllText?: string
  filterOption?: boolean | ((inputValue: string, option: React.ReactElement<OptionProps>) => boolean)
} & AntSelectProps &
  UseControllerProps<T>

export const HookMultiSelect = <T extends FieldValues>({
  placeholder,
  onSelectAll,
  selectAllText,
  children,
  name,
  control,
  onChange,
  value,
  defaultValue,
  ...restProps
}: React.PropsWithChildren<MultiSelectProps<T>>) => {
  const { t } = useTranslation('common')

  const [allChecked, setAllChecked] = React.useState(false)

  let options = React.Children.toArray(children)

  if (onSelectAll && !_.isEmpty(options)) {
    options = [
      <Option key="all" value="all">
        <Text bold={allChecked}>{selectAllText ?? t('SelectAll')}</Text>
      </Option>,
      ...options
    ]
  }

  return (
    <Controller<T>
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: fieldOnChange, ref, value: fieldValue } }) => {
        return (
          <StyledSelect
            mode="multiple"
            placeholder={placeholder || t('Select')}
            ref={ref}
            onChange={(v, option) => {
              if (onChange) onChange(v as string[], option)
              if (!onSelectAll) {
                fieldOnChange(v as any, option as any)
                return
              }
              if (_.includes(v as string[], 'all')) {
                setAllChecked(prev => {
                  onSelectAll(!prev)
                  return !prev
                })
                return
              }

              fieldOnChange(v as any, option as any)
            }}
            value={value ?? (fieldValue as string[])}
            defaultValue={defaultValue}
            {...restProps}
          >
            {options}
          </StyledSelect>
        )
      }}
    />
  )
}

type MultiSelectFieldProps<T extends FieldValues> = {
  label?: string
  required?: boolean
  disabled?: boolean
  hideLabel?: boolean
} & MultiSelectProps<T>

export const MultiSelectField = <T extends FieldValues>({
  required,
  label = '',
  hideLabel,
  ...rest
}: React.PropsWithChildren<MultiSelectFieldProps<T>>) => {
  return (
    <FormItem className="rf-field" label={!hideLabel ? label : undefined} colon={false} required={required}>
      <HookMultiSelect {...rest} />
    </FormItem>
  )
}
