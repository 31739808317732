import { SvgComponent } from '../../types'

export enum VisibleDrawer {
  None,
  Notification,
  Account,
  MobileMenu,
  Appointment
}

export type MenuItemDef = {
  icon: SvgComponent
  text: string
  link: string
  active: boolean
}
