import axios from 'axios'
import { ProductsResponse } from './types'

export const createProduct = (productName: string) =>
  axios.post('/api/products', { name: productName }, { allowedErrorStatuses: [400] })

export const getProducts = (top?: number, skip?: number, sortProperty?: string, sortOrder?: 'Asc' | 'Desc') =>
  axios.get<ProductsResponse>('/api/products', { params: { top, skip, sortProperty, sortOrder } }).then(d => d.data)

export const editProduct = (productId: string, name: string) => axios.patch(`/api/products/${productId}`, { name })

export const deleteProduct = (productId: string) => axios.delete(`/api/products/${productId}`)

export const validateProduct = (productName: string) =>
  axios.post('/api/products/validation', { name: productName }, { allowedErrorStatuses: [400] })
