import _ from 'lodash'
import { createSelector } from 'reselect'
import { paramSelector } from '../../../utils/selectors'
import { RootState } from '../../types'
import { getValueForSelect } from './helpers'
import { AssignmentType } from './types'

type WithCompanyId = { companyId: string }
type WithCompanyIdOptional = { companyId?: string }
type WithAssignmentType = { assignmentType: AssignmentType }

const selectState = (state: RootState) => state.views.salestoolTable

export const areAllCompaniesSelected = createSelector(selectState, state => state.isSelectAllActive)

export const getSelectedRows = createSelector(selectState, state => state.selectedRows)

export const getAssignmentsByStatus = createSelector(selectState, state => state.assignmentsByStatus)

export const getCompaniesAssignmentStatusDict = createSelector(selectState, state => state.companiesAssignmentStatus)

export const areAssignmentsEmpty = createSelector(
  getAssignmentsByStatus,
  assignmentsByStatus => !_.some(_.values(assignmentsByStatus), ({ assignments }) => !_.isEmpty(assignments))
)

const getPendingAssignments = createSelector(selectState, state => state.pendingAssignments)

export const getAssignment = createSelector(
  getAssignmentsByStatus,
  getPendingAssignments,
  getCompaniesAssignmentStatusDict,
  paramSelector<WithCompanyIdOptional & WithAssignmentType>(),
  (assignmentsByStatus, pendingAssignments, companyStatusDict, { companyId, assignmentType }) => {
    if (companyId === undefined) return undefined
    const status = companyStatusDict[companyId]
    const value = getValueForSelect(assignmentsByStatus, companyId, status, assignmentType)
    const pendingValue = getValueForSelect(pendingAssignments, companyId, status, assignmentType)
    return value || pendingValue
  }
)

export const getAssignmentLoadingStatus = createSelector(
  (state: RootState) => state.views.salestoolTable.loadingAssignments,
  paramSelector<WithCompanyId>(),
  (loadingIds, { companyId }) => _.includes(loadingIds, companyId)
)
