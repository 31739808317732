import { GeoJsonProperties } from 'geojson'
import _ from 'lodash'
import { MapChartMetadata } from '../../../api/targeting/types'
import { MapCountryCustomization } from './CountryCustomization'
import { IMapCountryCustomizations } from './types'

export const mapConfig = {
  level1Key: 'region',
  level2Key: 'district',
  dataKey: 'count',
  regionNameKey: 'NAME'
}

export class MapCountryCustomizationChe extends MapCountryCustomization implements IMapCountryCustomizations {
  constructor(mapConfiguration?: MapChartMetadata) {
    super(mapConfiguration)
    this.minZoomLevel = mapConfiguration?.minZoomLevel ? parseInt(mapConfiguration?.minZoomLevel, 10) : 6
    this.maxZoomLevel = mapConfiguration?.maxZoomLevel ? parseInt(mapConfiguration?.maxZoomLevel, 10) : 9
  }

  // eslint-disable-next-line class-methods-use-this
  getGeoJsonType = (zoom: number) => {
    if (zoom <= 7) return 'regions'
    return 'districts'
  }

  getMaxZoom = (withGeoData: boolean) => (withGeoData ? this.maxZoomLevel : 19)

  getMinZoom = (withGeoData: boolean) => (withGeoData ? this.minZoomLevel : 1)

  // eslint-disable-next-line class-methods-use-this
  getPathFromGeoJsonFeature = (featureProps: GeoJsonProperties) => {
    if (featureProps === null) return undefined

    const { level1Key, level2Key } = mapConfig
    const district = _.toLower(featureProps[level2Key])
    const regionCode = _.toLower(featureProps[level1Key])

    let key: string
    if (district) {
      key = `${regionCode}.sub.${district}`
    } else {
      key = `${regionCode}`
    }
    return key
  }
}
