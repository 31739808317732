import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog } from '../../../components/Dialog'
import { Progress } from '../../../components/Progress'
import { UserActions } from '../../../features/user/actions'
import { getTimeToCancelAutomaticLogout } from '../../../features/config/selectors'
import { StyledText } from '../components'

type Props = {
  close: () => void
}

export const LogoutCancel = ({ close }: Props) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()

  const timeToCancel = useSelector(getTimeToCancelAutomaticLogout)

  const [timeLeft, setTimeLeft] = useState(timeToCancel)

  useEffect(() => {
    if (!timeLeft) {
      close()
      dispatch(UserActions.logout(false))
      return
    }
    const intervalId = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  return (
    <Dialog
      loading={false}
      visible
      title={t('AutoLogout.Title')}
      okText={t('Cancel')}
      onOk={close}
      onCancel={close}
      footer
      maskClosable={false}
      width={600}
    >
      <StyledText>{t('AutoLogout.Text', { count: timeLeft })}</StyledText>
      <Progress percent={((timeToCancel - timeLeft) / timeToCancel) * 100} showInfo={false} />
    </Dialog>
  )
}

export const LogoutCancelDialog = React.memo(LogoutCancel)
