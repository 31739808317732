import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { DialogActions } from '../../../features/dialog/actions'
import * as dialogSelectors from '../../../features/dialog/selectors'
import { DeletePortfolioDialog } from './DeletePortfolioDialog'
import { PORTFOLIO_MANAGEMENT } from '../../../routes'
import { deletePortfolio, getPortfolioKeys } from '../../../api/portfolio'
import { queryClient } from '../../../features/queryclient'
import { DeletePortfolioViewState } from '../../../features/portfolio/types'
import { getFiltersKeys } from '../../../api/filters'
import { getDashboardKeys } from '../../../api/dashboard/queries'

export const DeletePortfolioDialogContainer = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { state, portfolioId } = useSelector(dialogSelectors.getVisibleDialogData)

  const mutateDeletePortfolio = useMutation(() => deletePortfolio(portfolioId), {
    onSuccess: async () => {
      queryClient.resetQueries(getDashboardKeys.GetUserDashboard())

      queryClient.removeQueries(getPortfolioKeys.PortfolioById(portfolioId))
      queryClient.removeQueries(getPortfolioKeys.PortfolioCompaniesForPortfolioId(portfolioId))

      /* necessary for proper portfolio deletion, without await there is a chance that queries do not refetch if the component unmounts
       causing portfolio-preview request fail */
      await Promise.all([
        queryClient.invalidateQueries(getPortfolioKeys.Options(), { refetchType: 'all' }),
        queryClient.invalidateQueries(getPortfolioKeys.GetPortfolios(), { refetchType: 'all' }),
        queryClient.invalidateQueries(getFiltersKeys.DomainNodesData(), { refetchType: 'all' })
      ])

      dispatch(
        DialogActions.updateDialogData('DeletePortfolio', {
          state: DeletePortfolioViewState.DeletePortfolioDone
        })
      )
    }
  })

  const close = () => dispatch(DialogActions.closeDialog())
  return (
    <DeletePortfolioDialog
      isLoading={mutateDeletePortfolio.isLoading}
      view={state}
      deletePortfolio={() => mutateDeletePortfolio.mutate()}
      reload={() => {
        close()
        if (location.pathname !== PORTFOLIO_MANAGEMENT) {
          navigate({ pathname: PORTFOLIO_MANAGEMENT }, { replace: true })
        }
      }}
      close={close}
    />
  )
}
