import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SALESTOOL, SALESTOOL_CAMPAIGN_DETAIL } from '../../../routes'
import { Dialog } from '../../../components/Dialog'
import { Text } from '../../../components/Text'
import KoImage from '../../../assets/images/ko.svg'
import { BaseDialog } from '../BaseDialog'
import { ErrorTypes } from '../../../features/salestool/types'

export type ErrorSalesToolDialogData = {
  type: ErrorTypes
  campaignId: string
}

type Props = {
  data: ErrorSalesToolDialogData
  isLoading: boolean
  close: () => void
}

export const SalestoolDialog = ({ close, data }: Props) => {
  const { t } = useTranslation(['salestool', 'error', 'common'])
  const navigate = useNavigate()

  const closeAndRedirectToSalestool = () => {
    close()
    navigate({ pathname: SALESTOOL })
  }

  const closeAndRedirectToCampaign = () => {
    close()
    if (data.campaignId) {
      const campaignDetail = generatePath(SALESTOOL_CAMPAIGN_DETAIL, { campaignId: data.campaignId })
      navigate({ pathname: campaignDetail })
    }
  }

  const errorMessages = {
    [ErrorTypes.renewCampaign]: {
      text: ErrorTypes.renewCampaign,
      onDismiss: close
    },
    [ErrorTypes.saveCampaign]: {
      text: ErrorTypes.saveCampaign,
      onDismiss: closeAndRedirectToSalestool
    },
    [ErrorTypes.duringCampaignCreation]: {
      text: ErrorTypes.duringCampaignCreation,
      onDismiss: closeAndRedirectToSalestool
    },
    [ErrorTypes.saveAssignmentsGeneric]: {
      text: ErrorTypes.saveAssignmentsGeneric,
      onDismiss: closeAndRedirectToCampaign
    },
    [ErrorTypes.saveAssignmentsDuplicate]: {
      text: ErrorTypes.saveAssignmentsDuplicate,
      onDismiss: close
    },
    [ErrorTypes.fetchCampaigns]: {
      text: ErrorTypes.fetchCampaigns,
      onDismiss: close
    },
    [ErrorTypes.fetchCompanies]: {
      text: ErrorTypes.fetchCompanies,
      onDismiss: close
    }
  }

  const fallbackError = {
    text: 'ErrorPage.Text',
    onDismiss: close
  }

  const { onDismiss, text } = errorMessages[data.type] || fallbackError

  const props = {
    destroyOnClose: false,
    title: t('GenericErrorTitle'),
    footer: '',
    okText: t('common:Ok'),
    onCancel: onDismiss,
    onOk: onDismiss,
    width: 800,
    visible: true,
    loading: false
  }

  return (
    <Dialog {...props}>
      <BaseDialog content={<Text>{t(`error:${text}`)}</Text>} image={<img src={KoImage} alt="error" />} />
    </Dialog>
  )
}
