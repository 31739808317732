/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  PM001: 'Société chargée correctement. Chargement du portefeuille {{portfolioName}} terminé !',
  PM001_plural: 'Sociétés chargées correctement. Chargement du portefeuille {{portfolioName}} terminé !',
  PM002: 'Aller dans les détails des erreurs de chargement du portefeuille {{portfolioName}}',
  PM003:
    "Société {{portfolioName}} non importée en raison d'une incohérence d'identification ou de cessation d'activité.",
  PM003_plural:
    "Sociétés de {{portfolioName}} non importées en raison d'une incohérence d'identification ou de cessation d'activité.",
  PM004: 'Sociétés de {{portfolioName}} non importées en raison de données incomplètes ou erronées.',
  PM004_plural: 'Sociétés {{portfolioName}} non importées en raison de données incomplètes ou erronées.',
  PM005: 'Téléchargement du {{exportType}} de liste du portefeuille {{portfolioName}} terminé',
  PM006: 'Téléchargement du PDF de la société {{companyName}} terminé',
  PM007: 'Duplication de {{numberOfCompanies}} société dans le portefeuille {{portfolioName}} exécutée correctement',
  PM008: 'Suppression de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} exécutée correctement',
  PM009:
    'Déplacement de {{count}} société du portefeuille {{sourcePortfolioName}} vers le portefeuille {{targetPortfolioName}} exécuté avec succès.',
  PM009_plural:
    'Déplacement de {{count}} sociétés du portefeuille {{sourcePortfolioName}} vers le portefeuille {{targetPortfolioName}} exécuté avec succès.',
  PM010: 'Enrichissement de {{numberOfCompanies}} société dans le portefeuille {{portfolioName}} exécuté correctement',
  PM010_plural:
    'Enrichissement de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} exécuté correctement',
  PM011:
    'Partage de {{companyNumber}} société du portefeuille {{portfolioName}} avec {{userName}} exécuté correctement',
  PM012: '{{grantorName}} a partagé le portefeuille {{portfolioName}}',
  PM013: 'Retrait du partage de {{portfolioName}} exécuté correctement',
  PM014Part1:
    "L'attribution de {{productName}} à {{numberOfCompanies}} société  du portefeuille {{portfolioName}} s'est correctement déroulée.",
  PM014Part1_plural:
    "L'attribution de {{productName}} aux {{numberOfCompanies}} entreprises du portefeuille {{portfolioName}} s'est correctement déroulée.",
  PM014Part2:
    " L'attribution du produit n'a pas été effectuée pour {{numberOfDuplicates}} société en raison d'une duplication de la ligne.",
  PM014Part2_plural:
    " L'attribution de produit n'a pas été effectuée pour {{numberOfDuplicates}} sociétés en raison d'une duplication de la ligne.",
  PM015: '{{salesName}} affecté à {{numberOfCompanies}} société dans {{portfolioName}} exécuté correctement',
  PM017: '{{managerName}} affecté à {{numberOfCompanies}} société dans {{portfolioName}} exécuté correctement',
  PM014_0: 'Non affectation de {{numberOfCompanies}} sociétés dans {{portfolioName}} exécutée correctement',
  PM015_0: 'Non affectation de {{numberOfCompanies}} sociétés dans {{portfolioName}} exécutée correctement',
  PM017_0: 'Non affectation de {{numberOfCompanies}} sociétés dans {{portfolioName}} exécutée correctement',
  PM019: 'La campagne {{campaignName}} avec {{numberOfCompanies}} société a été créée',
  PM019_plural: 'La campagne {{campaignName}} avec {{numberOfCompanies}} sociétés a été créée',
  PM020: '{{numberOfCompanies}} enrichies avec succès',
  PM021: '{{initiatorUserName}} a partagé {{numberOfCompanies}} sociétés',
  PM022: '{{initiatorUserName}} a arrêté le partage de {{numberOfCompanies}} sociétés',
  PM023: 'Actualisation du partage exécutée correctement',
  PM024: 'Actualisation du partage exécutée correctement',
  PM025: '{{initiatorUserName}} a partagé {{numberOfCompanies}} sociétés avec {{numberOfSalesUsers}} utilisateurs',
  PM026:
    '{{initiatorUserName}} a arrêté le partage de {{numberOfCompanies}} sociétés avec {{numberOfSalesUsers}} utilisateurs',
  PM027: 'Sociétés de {{portfolioName}} non importées en raison de données incomplètes ou erronées.',
  PM027_plural: 'Sociétés {{portfolioName}} non importées en raison de données incomplètes ou erronées.',
  LL001: "Ajout d'unités locales. Le portefeuille a été actualisé avec {{numberOfCompanies}} Unités Locales.",
  ST001: 'La campagne {{campaignName}} avec {{numberOfCompanies}} société a été créée',
  ST001_plural: 'La campagne {{campaignName}} avec {{numberOfCompanies}} sociétés a été créée',
  ST002: '{{userName}} a retiré {{numberOfCompanies}} société de la campagne {{campaignName}}',
  ST002_plural: '{{userName}} a retiré {{numberOfCompanies}} sociétés de la campagne {{campaignName}}',
  ST003: '{{userName}} a affecté {{numberOfCompanies}} société à la campagne {{campaignName}}',
  ST003_plural: '{{userName}} a affecté {{numberOfCompanies}} sociétés à la campagne {{campaignName}}',
  ST004: 'La campagne {{campaignName}} commencera le {{startDate}}',
  ST005: 'La campagne {{campaignName}} se terminera le {{endDate}}',
  ST006: 'Le portefeuille {{portfolioName}} a été ajouté avec succès à la campagne {{campaignName}}.',
  ST007: 'Téléchargement excel de la campagne {{campaignName}} terminé',
  ST008: 'La campagne {{campaignName}} a été supprimée',
  ST009: 'Nouvelles affectations de {{numberOfCompanies}} société à la campagne {{campaignName}} terminées.',
  ST009_plural: 'Nouvelles affectations de {{numberOfCompanies}} sociétés à la campagne {{campaignName}} terminées.',
  ST010: 'la société {{numberOfCompanies}} a été ajoutée avec succès à la campagne {{campaignName}}.',
  ST010_plural: 'les sociétés {{numberOfCompanies}} a été ajoutées avec succès à la campagne {{campaignName}}.',
  ST011:
    'Affectation de la tâche {{taskName}} aux {{numberOfCompanies}} société pour la campagne {{campaignName}} terminée.',
  ST011_plural:
    'Affectation de la tâche {{taskName}} aux {{numberOfCompanies}} sociétés pour la campagne {{campaignName}} terminée.',
  PR001: '{{numberOfCompanies}} nouvelles sociétés ont intégré le portefeuille surveillé {{portfolioName}}',
  PR002:
    'Insertion et enrichissement de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} exécutés correctement',
  PR003:
    'Création du portefeuille {{portfolioName}} et insertion de {{numberOfCompanies}} société enrichie exécutées correctement',
  PR003_plural:
    'Création du portefeuille {{portfolioName}} et insertion de {{numberOfCompanies}} sociétés enrichies exécutées correctement',
  PR004:
    'Insertion et enrichissement de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} et surveillance exécutés correctement',
  PR005:
    'Création du portefeuille surveillé {{portfolioName}} et insertion de {{numberOfCompanies}} sociétés enrichies exécutées correctement ',
  PR006: 'Le portefeuille enrichi <0>{{portfolioName}}</0> a été créé correctement. {{exclusionWarning}}',
  PR007:
    'Insertion et enrichissement de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} exécutés correctement',
  TR001:
    'Création du portefeuille {{portfolioName}} et insertion de {{numberOfCompanies}} société enrichie exécutées correctement',
  TR001_plural:
    'Création du portefeuille {{portfolioName}} et insertion de {{numberOfCompanies}} sociétés enrichies exécutées correctement',
  TR002:
    'Insertion et enrichissement de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} exécutés correctement',
  TA001: 'Télécharger Excel depuis Ciblage terminé',
  TA002: 'Télécharger PDF depuis Ciblage terminé',
  DO002: 'Le fichier de la société {{companyName}} a été créée et est prêt au téléchargement.',
  PM002Event1: 'Sociétés en cessation pas téléchargées',
  PM002Event2:
    'Erreurs dues à des données erronées. Le modèle peut être chargé ultérieurement dans la section « Gestion des portefeuilles »',
  OP001:
    'Création du portefeuille {{portfolioName}} et insertion de {{numberOfCompanies}} société enrichie exécutées correctement',
  OP001_plural:
    'Création du portefeuille {{portfolioName}} et insertion de {{numberOfCompanies}} sociétés enrichies exécutées correctement',
  OP002:
    'Insertion et enrichissement de {{numberOfCompanies}} société dans le portefeuille {{portfolioName}} exécutés correctement',
  OP003: 'Le portefeuille enrichi {{portfolioName}} a été créé correctement. {{exclusionWarning}}',
  OP004: 'Enrichissement de {{numberOfCompanies}} société dans le portefeuille {{portfolioName}} exécuté correctement',
  OP004_plural:
    'Enrichissement de {{numberOfCompanies}} sociétés dans le portefeuille {{portfolioName}} exécuté correctement',
  MissingMessage: 'Message de notifications absent pour le type {{type}}.',
  ExcludedCompaniesWarning: "Les sociétés appartenant à des portefeuilles cachés n'ont pas été traitées.",
  SalesTool: 'Outil de vente',
  Portfolio: 'Gestion des portefeuilles',
  Prospecting: 'Prospection',
  Operations: 'Opérations',
  Targeting: 'Analyser marché',
  DownloadPDF: 'Télécharger PDF',
  NotificationArchive: {
    Title: 'Archive de notification',
    Description: 'Cette page fournit la liste des notifications reçues',
    Notifications: '{{notificationsNumber}} Notifications',
    Welcome: 'Bienvenue dans Margò :',
    NoNotifications: 'voici la section de notification qui se remplira une fois des actions exécutées'
  },
  Table: {
    Header: {
      Date: 'Date',
      Section: 'Section',
      Message: 'Message'
    }
  },
  NotificationPopup: {
    Open: 'Ouvrir',
    ManageErrors: 'Gérer les erreurs',
    DownloadList: 'Télécharger une liste',
    DownloadFile: 'Télécharger un fichier'
  },
  PushEvent: {
    UploadProgress: 'Chargement de portefeuille en cours',
    UploadProgressFinished: 'Chargement terminé',
    DeleteCompaniesProgress: 'Suppression des sociétés sélectionnées en cours',
    DeleteCompaniesProgressFinished: 'Suppression des sociétés sélectionnées terminée',
    MoveCompaniesProgress: 'Déplacement des sociétés sélectionnées en cours',
    MoveCompaniesProgressFinished: 'Déplacement des sociétés sélectionnées terminé',
    EnrichCompaniesProgress: 'Enrichissement des sociétés sélectionnées en cours',
    EnrichCompaniesProgressFinished: 'Enrichissement des sociétés sélectionnées terminé',
    CreatePortfolioFromSelectionProgress: 'Création de portefeuille parmi les sociétés sélectionnées en cours',
    CreatePortfolioFromSelectionProgressFinished: 'Création de portefeuille parmi les sociétés sélectionnées terminée',
    DuplicateProgress: 'Portefeuille en cours de duplication',
    DuplicateProgressFinished: 'Duplication terminée',
    ShareCompaniesProgress: 'Sociétés en cours de partage',
    ShareCompaniesProgressFinished: 'Partage terminé',
    UnshareCompaniesProgress: 'Arrêt du partage de sociétés en cours',
    UnshareCompaniesProgressFinished: 'Des sociétés ne sont plus partagées',
    AssignSalesProgress: 'Affectation à commercial en cours',
    AssignSalesProgressFinished: 'Affectation à commercial terminé',
    AssignAreaManagerProgress: 'Affectation aux responsables de secteur en cours',
    AssignAreaManagerProgressFinished: 'Affectation aux responsables de secteur terminée',
    AssignProductProgress: 'Affectation à produit en cours',
    AssignProductProgressFinished: 'Affectation à produit terminée',
    ProspectingSaveListProgress: 'Création de portefeuille à partir de la prospection en cours',
    ProspectingSaveListProgressFinished: 'Création de portefeuille terminé',
    TargetingSaveListProgress: "Création de portefeuille à partir d'Analyser marché en cours",
    TargetingSaveListProgressFinished: 'Création de portefeuille terminé',
    ProspectingUpdateListProgress: 'Actualisation de portefeuille à partir de la prospection en cours',
    ProspectingUpdateListProgressFinished: 'Actualisation de portefeuille à partir de la prospection terminée',
    TargetingUpdateListProgress: "Actualisation de portefeuille à partir d'Analyser marché en cours",
    TargetingUpdateListProgressFinished: "Actualisation de portefeuille à partir d'analyser marché terminée",
    CreateCampaignProgress: 'Création de campagne en cours',
    CreateCampaignProgressFinished: 'Création de campagne terminée',
    AssignCampaignProgress: 'Affectation de campagne en cours',
    AssignCampaignProgressFinished: 'Affectation de campagne terminée',
    DeleteCampaignProgress: 'Suppression de campagne en cours',
    DeleteCampaignProgressFinished: 'Suppression de campagne terminée',
    UpdateCampaignTaskProgress: 'Votre campagne est en cours de mise à jour',
    UpdateCampaignTaskProgressFinished: 'Votre campagne a été mise à jour',
    SetCompaniesTaskProgress: 'Définition de tâche de sociétés en cours',
    SetCompaniesTaskProgressFinished: 'Définition de tâche de sociétés terminée',
    ExportCampaignProgress: 'Exportation de campagne en cours',
    ExportCampaignProgressFinished: 'Exportation de campagne terminée',
    ExportPortfolioProgress: 'Portefeuille {{portfolioName}} en cours de préparation au téléchargement',
    ExportPortfolioProgressFinished: 'Téléchargement du portefeuille {{portfolioName}} prêt',
    PdfGenerateProgress: 'Création de PDF pour la société {{companyName}} en cours',
    AddLocalUnitsToPortfolioProgress: "Ajout d'unités locales. Actualisation du portefeuille en cours."
  },
  Banner: {
    RingsWarningMessage:
      "Attention ! Votre crédit d'appel restant est inférieur à 10%. Veuillez vérifier votre compte ou <1>Contactez-nous</1> !"
  }
}
