import { Method as RequestMethod } from 'axios'

export type ArrayResponse<T> = {
  items: T[]
  totalCount: number
}

export type UserResponse = {
  id: string // <- User Id
  organization: OrganizationResponse
  userCode: string
  firstName: string
  lastName: string
  email: string
  authUserDistinguishName: string
  impersonationAllowed?: boolean
  organizationUserDistinguishName: string
  isReleaseNotesEditor?: boolean
  userType: string // <- UserType / Level
  language: string // <- Language code
  currency: string
  isActive: boolean
  channel: Channel
}

export type OrganizationResponse = {
  id: string // <- Organization Id
  code: string
  name: string // <- Organization Name
  countryCode: string
  authUserDistinguishName: string
  organizationUserDistinguishName: string
  identificationCode: string
}

export type UrlConfig = {
  url: string
  method: RequestMethod
}

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderByResponseDto = {
  jsonPath: string
  text: string
  direction: OrderByDirection
}

export type OrderByResponse = {
  available: OrderByResponseDto[]
  default: OrderByResponseDto[]
}

export type OrderByRequest = {
  fieldName: string
  sortOrder: OrderByDirection
}

export enum ResultCode {
  IncompatibleDestEntity = 'INDESTEN'
}

export enum Channel {
  None = 'None',
  Web = 'Web',
  Api = 'Api',
  Integrations = 'Integrations'
}

export enum CustomVariableType {
  String = 'String',
  Numeric = 'Numeric',
  Date = 'Date'
}

export type CustomVariable = {
  name: string
  position: number
  type: CustomVariableType
}

export type CustomVariablesResponse = {
  customVars: CustomVariable[]
}

export type RedirectUrlResponse = {
  externalUrl: string
}
