import _ from 'lodash'
import React from 'react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckboxProps as CheckboxGroupProps, CheckboxProps } from 'antd3/lib/checkbox'
import styled from 'styled-components/macro'
import { Form } from 'antd3'
import { FormItemProps } from 'antd'
import { Checkbox } from '../Checkbox'
import { FormError } from '../../utils/validators'
import { getError, getErrorMessage } from '../../features/form/utils'

const FormItem = styled(Form.Item)<React.PropsWithChildren<FormItemProps>>`
  &&& {
    label {
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: normal;
      color: ${({ theme }) => theme.colors.main} !important;
    }
  }
`

export type HookCheckboxProps<T extends FieldValues = any> = CheckboxGroupProps & UseControllerProps<T>

const HookCheckbox = <T extends FieldValues>({ control, name, rules, ...props }: HookCheckboxProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ...rest } }) => {
        return (
          <Checkbox
            defaultChecked={value as boolean | undefined}
            {...rest}
            {...props}
            onChange={e => {
              if (props.onChange) props.onChange(e)
              onChange(e)
            }}
          />
        )
      }}
    />
  )
}

type CheckboxFieldProps<T extends FieldValues = any> = {
  label?: string
  required?: boolean
  disabled?: boolean
  hideLabel?: boolean
  formState: FormState<any>
  colon?: boolean
  validators?: Record<string, (value: any) => Promise<FormError | FormError[]> | FormError | FormError[] | undefined>
  checked?: boolean
} & UseControllerProps<T> &
  CheckboxProps

// eslint-disable-next-line react/display-name
export const CheckboxField = <T extends FieldValues>(props: CheckboxFieldProps<T>) => {
  const { t } = useTranslation('error')
  const { required, disabled, formState, label = '', hideLabel, colon = false, name, validators, rules } = props
  const { errors, isSubmitting } = formState
  const errorMessage = getError(errors, name)

  let rulesOverride
  if (validators) {
    rulesOverride = _.reduce(validators, (acc, v, key) => ({ ...acc, [key]: getErrorMessage(v, label ?? '', t) }), {})
  }

  const isDisabled = disabled || isSubmitting

  return (
    <FormItem
      className="rf-field"
      label={!hideLabel ? label : undefined}
      colon={colon}
      required={required}
      validateStatus={errorMessage ? 'error' : 'success'}
      help={errorMessage}
    >
      <HookCheckbox {...props} disabled={isDisabled} rules={validators ? { validate: { ...rulesOverride } } : rules} />
    </FormItem>
  )
}
