import { Modal } from 'antd3'
import { ModalProps } from 'antd3/lib/modal'
import _ from 'lodash'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg'
import { Button } from '../Button'
import { SmallSpin } from '../Loading/Spin'
import { Stack } from '../Stack'
import { Text, Title } from '../Text'

export const ContentFooter = styled.div`
  position: relative;
  margin-top: 40px;
  margin-bottom: -20px;
  padding-top: 20px;

  ::before {
    position: absolute;
    top: 0;
    left: -40px;
    display: block;
    content: '';
    width: calc(100% + 80px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.veryLightPink};
  }
`

const FooterLine = styled(Stack)`
  && {
    button:first-of-type {
      margin-left: auto;
    }

    .buttons {
      margin-left: auto;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;

      .buttons {
        margin: 10px 0;
      }
    }
  }
`

const BodyPadding = styled.div`
  padding: 20px 40px 40px;
`

type Props = {
  loading?: boolean
  children?: React.ReactNode
  spinDelay?: number
} & ModalProps

const Footer = ({ loading, okText, okButtonProps, onOk, cancelText, cancelButtonProps, onCancel, footer }: Props) => {
  if (React.isValidElement(footer)) {
    return footer
  }
  return (
    <FooterLine center gap={16}>
      {!!footer && (
        <Text size="M" bold primary block>
          {footer}
        </Text>
      )}
      <div className="buttons">
        {!!cancelText && (
          <Button disabled={loading} onClick={onCancel} {...cancelButtonProps}>
            {cancelText}
          </Button>
        )}
        {!!okText && (
          <Button type="primary" disabled={loading} onClick={onOk} {...okButtonProps}>
            {okText}
          </Button>
        )}
      </div>
    </FooterLine>
  )
}

export const IsModalContext = React.createContext<boolean | undefined>(undefined)

export const Dialog = (props: Props) => {
  const { wrapClassName, loading, title, closeIcon, children, footer, spinDelay, ...restProps } = props

  useEffect(() => {
    /*
     * This fixes scrolling of the page when a dialog is open by setting overflow: hidden to body element.
     * The page scrolling makes elements like select's options list box inside a dialog move with the whole page
     * (see GLOB-5443 for more info)
     */
    if (restProps.visible) {
      document.body.classList.add('with-dialog-open')
    }
    return () => {
      if (document.body.classList.contains('with-dialog-open')) {
        document.body.classList.remove('with-dialog-open')
      }
    }
  }, [restProps.visible])

  return (
    <IsModalContext.Provider value>
      <Modal
        {...restProps}
        wrapClassName={`margo-dialog ${wrapClassName}`}
        title={_.isString(title) ? <Title level={3} center text={title} /> : title}
        closeIcon={closeIcon || <ExitIcon />}
        footer={!_.isNil(footer) ? <Footer {...props} /> : null}
      >
        <SmallSpin spinning={loading} delay={spinDelay}>
          <BodyPadding className="dialog-body-wrapper">{children}</BodyPadding>
        </SmallSpin>
      </Modal>
    </IsModalContext.Provider>
  )
}
