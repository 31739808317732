import _ from 'lodash'
import { GeoJsonProperties } from 'geojson'
import { CompanyFieldConfig } from '../../../api/companyPopup/types'
import { PortfolioCompany } from '../../portfolioCompany/types'
import { formatField } from '../../../utils/helpers'
import { MapCountryCustomization, mapConfig } from './CountryCustomization'
import { Field, IMapCountryCustomizations } from './types'

export { mapConfig }

export class MapCountryCustomizationDeu extends MapCountryCustomization implements IMapCountryCustomizations {
  // eslint-disable-next-line class-methods-use-this
  formatCompanyFields = (company?: PortfolioCompany, fields?: CompanyFieldConfig[]): Field<any>[] => {
    if (!company || !fields) return []

    return _.map(fields, field => {
      const fieldValue = _.get(company.fields, field.jsonPath)
      const cellValue = _.get(company.cells, field.jsonPath)

      return {
        label: field.text,
        value: formatField(field.fieldType, cellValue ?? fieldValue)
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getPathFromGeoJsonFeature = (featureProps: GeoJsonProperties): string | undefined => {
    if (featureProps === null) return undefined

    const { level1Key, level2Key } = mapConfig
    const regionCode = _.toLower(_.get(featureProps, `${level1Key}`))
    const provinceCode = _.toLower(_.get(featureProps, `${level2Key}`))

    return provinceCode ? `deu.sub.${regionCode}.sub.${provinceCode}` : `deu.sub.${regionCode}`
  }
}
