import { SorterResult } from 'antd3/lib/table'
import { ActionsUnion } from '../../utils/types'
import { createAction } from '../../utils/redux'
import { TableKey } from './types'

export const SORTING_CHANGED = 'tables/SORTING_CHANGED'
export const tableActions = (tableKey: TableKey) => ({
  setSorting: (sorterResult?: SorterResult<any>) => createAction(SORTING_CHANGED, { tableKey, sorterResult })
})

export const ProductsTableActions = tableActions(TableKey.Products)

export type TableActions = ActionsUnion<typeof ProductsTableActions>
