import React, { HTMLAttributes } from 'react'
import styled, { DefaultTheme } from 'styled-components/macro'
import { Type, colorByType } from '../../utils/theme'
import { getThemeColorByName } from '../../utils/helpers'
import { textMixin, TextMixinProps, Offset, TextSize, textSizeProps } from './common'

type TextStyleProps = {
  primary?: boolean
  color?: string
  type?: Type
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'initial' | 'inherit'
  hoverTextDecoration?:
    | 'underline'
    | 'overline'
    | 'line-through'
    | 'none'
    | 'auto'
    | 'dashed'
    | 'dotted'
    | 'solid'
    | 'initial'
    | 'inherit'
    | 'revert'
    | 'blink'
    | 'unset'
}

export const TextStyle = styled.span<TextMixinProps & TextStyleProps>`
  ${textMixin}
  font-family: 'Lato', sans-serif;
  color: ${({ theme: t, color, primary, type }) =>
    getThemeColorByName(t, color) || (primary ? t.colors.main : colorByType(t, type))};
  white-space: ${({ whiteSpace }) => whiteSpace ?? 'normal'};
  &:hover {
    text-decoration: ${({ hoverTextDecoration }) => hoverTextDecoration ?? 'none'};
  }
`

export type TextProps = {
  className?: string
  /**
   * * L: font-size: 16px, line-height: 1.33
   * * M: font-size: 14px, line-height: 1.71
   * * S: font-size: 12px, line-height: 1.5
   * * number: font-size: {number}px
   */
  size?: TextSize
  /**
   * @deprecated
   */
  bold?: boolean
  lineHeight?: number
  center?: boolean
  ellipsis?: boolean
  block?: boolean
  margin?: Offset
  padding?: Offset
  text?: React.ReactNode
  children?: React.ReactNode
  onClick?: () => void
  fallbackTheme?: DefaultTheme
  role?: HTMLAttributes<any>['role']
} & TextStyleProps

const defaultProps = {
  bold: false,
  center: false,
  ellipsis: false
}

export const Text = ({ fallbackTheme, size, block: asBlock, text, children, ...props }: TextProps) => {
  return (
    <TextStyle
      theme={fallbackTheme}
      as={asBlock ? 'div' : undefined}
      {...defaultProps}
      {...textSizeProps(size)}
      {...props}
    >
      {children || text}
    </TextStyle>
  )
}

Text.Style = TextStyle
