import _ from 'lodash'
import { useGetPortfolio, useGetPortfolioCompanies } from '../../api/portfolio'
import { mapPortfolioResponse } from '../../api/portfolio/mappers'
import { CompanyDetailsType } from '../../types'
import { companyStatuses } from '../portfolio/constants'
import { PortfolioDetail } from './types'

export const usePortfolio = (portfolioId: string) => {
  const { data: portfolio, isLoading } = useGetPortfolio(portfolioId)

  return {
    isLoading,
    portfolio: portfolio ? mapPortfolioResponse(portfolio) : undefined
  }
}

export const usePortfolioDetail = (portfolioId: string): PortfolioDetail => {
  const { data: portfolioCompanies, isLoading } = useGetPortfolioCompanies({
    portfolioId,
    expression: undefined,
    pageType: CompanyDetailsType.Preview,
    size: 0,
    excludedPortfoliosIds: undefined
  })

  if (!portfolioCompanies) return {}

  const companyStatistics = portfolioCompanies?.statistics

  const allStatuses = _.reduce(
    companyStatistics?.statuses,
    (statuses: Record<string, number>, status) => ({
      ...statuses,
      [_.toLower(status.value)]: status.count
    }),
    {}
  )

  const statuses = _.map(
    _.filter(companyStatuses, ({ status }) => _.get(allStatuses, [status]) > 0),
    'status'
  )

  return {
    stats: companyStatistics,
    statuses,
    isLoading
  }
}
