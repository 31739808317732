import moment from 'moment'
import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useUpdateCampaign } from '../../../../../api/salestool'
import OkImage from '../../../../../assets/images/ok.svg'
import KoImage from '../../../../../assets/images/ko.svg'
import { DatePicker } from '../../../../../components/DatePicker'
import { Dialog } from '../../../../../components/Dialog'
import { Text } from '../../../../../components/Text'
import { BaseDialog } from '../../../BaseDialog'
import { DialogActions } from '../../../../../features/dialog/actions'
import { formatDateTime, getLocalizedDateFormatPattern, toLocalizedDateFormat } from '../../../../../utils/formatters'
import { BoldText, DatePickerWrapper } from '../components'

type Props = {
  data: {
    campaignId: string
    campaignName: string
    endDate: string
    startDate: string
  }
}

export const ChangeEndDateDialog = ({ data: { campaignId, campaignName, endDate, startDate } }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])
  const dispatch = useDispatch()

  const [newEndDate, setNewEndDate] = useState<string>('')

  const getDate = (date: moment.Moment | null) => {
    const dateString = date && formatDateTime(date.endOf('day'))
    setNewEndDate(dateString === null ? '' : dateString)
  }

  const disabledDate = (current: moment.Moment | null): boolean =>
    current ? current <= moment(startDate).endOf('day') || current <= moment().endOf('day') : false

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  const {
    mutate: mutateChangeEndDate,
    isLoading,
    isSuccess,
    isError
  } = useUpdateCampaign(campaignId, {
    endDate: newEndDate
  })

  const props = {
    destroyOnClose: false,
    width: 600,
    visible: true,
    loading: isLoading,
    onCancel: closeDialog
  }

  if (isSuccess) {
    return (
      <Dialog
        {...props}
        title={t('Management.Campaign.ChangeEndDate')}
        footer=""
        okText={t('common:Ok')}
        onOk={closeDialog}
      >
        <BaseDialog
          content={
            <>
              <BoldText>{campaignName}&nbsp;</BoldText>
              {t('Management.Campaign.ChangeEndDateSuccess')}
              <BoldText>&nbsp;{toLocalizedDateFormat(newEndDate)}</BoldText>
            </>
          }
          image={<img src={OkImage} alt="ok" />}
        />
      </Dialog>
    )
  }

  if (isError) {
    return (
      <Dialog
        {...props}
        title={t('Management.Campaign.ChangeEndDate')}
        footer=""
        okText={t('common:Ok')}
        onOk={closeDialog}
      >
        <BaseDialog content={<Text>{t('error:ChangeEndDate')}</Text>} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  return (
    <Dialog
      {...props}
      title={t('Management.Campaign.ChangeEndDate')}
      footer=""
      cancelText={t('common:Cancel')}
      okText={t('common:Continue')}
      okButtonProps={{ disabled: !newEndDate }}
      onOk={() => mutateChangeEndDate()}
    >
      <DatePickerWrapper>
        <Text block>{t('Management.Campaign.ChangeEndDateDescription')}</Text>
        <DatePicker
          defaultValue={moment(endDate)}
          format={getLocalizedDateFormatPattern()}
          style={{ width: 250 }} // eslint-disable-line
          dropdownClassName="dialog-date-picker"
          onChange={getDate}
          disabledDate={disabledDate}
        />
      </DatePickerWrapper>
    </Dialog>
  )
}
