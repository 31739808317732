import { createAction } from '../../utils/redux'
import { TreeKeySelection } from '../../utils/tree'
import { ActionsUnion } from '../../utils/types'
import { FilterReducerPath } from './types'

export const RESET = 'filters/RESET'
export const SET_FILTER_SELECTION = 'filters/SET_FILTER_SELECTION'
export const SET_SELECTED_FILTER_GROUP = 'filters/SET_SELECTED_FILTER_GROUP'
export const RESET_SELECTED_FILTER_GROUPS = 'filters/RESET_SELECTED_FILTER_GROUPS'

export const filterActions = (filterReducerPath: FilterReducerPath) => ({
  reset: () => createAction(RESET, { reducer: filterReducerPath }),
  setFilterSelection: (groupId?: string) => createAction(SET_FILTER_SELECTION, { groupId, reducer: filterReducerPath }),
  setSelectedFilterGroup: (params: {
    filters: TreeKeySelection[]
    groupId: string
    userId: string
    entityId?: string
  }) => createAction(SET_SELECTED_FILTER_GROUP, { reducer: filterReducerPath, ...params }),
  resetSelectedFilterGroups: (userId?: string, entityId?: string) =>
    createAction(RESET_SELECTED_FILTER_GROUPS, { reducer: filterReducerPath, userId, entityId })
})

export type FiltersActionType<T extends keyof ReturnType<typeof filterActions>> = ReturnType<
  ReturnType<typeof filterActions>[T]
>

export const PortfolioDetailFilterActions = filterActions('views.portfolioDetail')
export type PortfolioDetailFilterActionType = typeof PortfolioDetailFilterActions

export const ProspectingFilterActions = filterActions('prospecting')
export type ProspectingFilterActionType = typeof ProspectingFilterActions

export const SalestoolAssignmentsFilterActions = filterActions('views.salestoolAssignments')
export type SalestoolAssignmentsFilterActionsType = typeof SalestoolAssignmentsFilterActions

export const TargetingFilterActions = filterActions('targeting')
export type TargetingFilterActionsType = typeof TargetingFilterActions

export type FiltersActions = ActionsUnion<
  PortfolioDetailFilterActionType &
    ProspectingFilterActionType &
    SalestoolAssignmentsFilterActionsType &
    TargetingFilterActionsType
>
