import _ from 'lodash'
import { useQuery, useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  getCampaign,
  getCampaignStats,
  getCampaigns,
  getCampaignsStatusCount,
  getCompanies,
  getSaleInfo,
  getCampaignMapCompanies,
  getMapDistribution,
  getCompanyNotes,
  getProductsList,
  getSalesList,
  getCampaingCustomVariables,
  getBestSalesByTask
} from './api'
import { CampaignStatus, Sale } from '../../features/salestool/types'
import { OrderByDirection, OrderByRequest } from '../types'
import {
  CampaignFields,
  CampaignCompaniesReq,
  CampaignRequestParams,
  PageType,
  SelectListResponse,
  SalesListType
} from './types'
import { cacheAllTheTime } from '../../features/queryclient'
import { createCampaignCompaniesMapReq, mapCampaign } from '../../features/campaignDetailPage/mappers'
import { MapPositionData, Pagination } from '../../types'
import { convertCompoundExpressionForBackend } from '../../features/filters/utils'
import { CompoundExpression } from '../../features/operations/types'

export const getSalestoolKeys = {
  All: () => [{ level1: 'salestool' }] as const,
  AllBasedOnCampaignId: (campaignId: string) => [{ ...getSalestoolKeys.All()[0], campaignId }] as const,

  GetCampaign: (campaignId: string) => [{ ...getSalestoolKeys.All()[0], level2: 'getCampaign', campaignId }] as const,

  GetCampaignStats: (campaignId: string) =>
    [{ ...getSalestoolKeys.GetCampaign(campaignId)[0], level3: 'getCampaignStats' }] as const,
  GetCampaignStatsWithSalesId: (campaignId: string, salesId?: string) =>
    [{ ...getSalestoolKeys.GetCampaignStats(campaignId)[0], salesId }] as const,

  GetCampaigns: () => [{ ...getSalestoolKeys.All()[0], level2: 'getCampaigns' }] as const,

  GetCampaignsWithData: (params: CampaignRequestParams) => [{ ...getSalestoolKeys.GetCampaigns()[0], params }] as const,

  GetCampaignsStatusCount: () =>
    [{ ...getSalestoolKeys.GetCampaigns()[0], level3: 'getCampaignsStatusCount' }] as const,

  GetInfiniteCampaignsWithData: (props: GetInfiniteCampaignsProps) =>
    [
      {
        ...getSalestoolKeys.GetCampaigns()[0],
        level3: 'Infinite',
        ...props
      }
    ] as const,

  GetCampaignCompaniesForCampaignId: (campaignId: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getCampaignCompanies', campaignId }] as const,
  GetCampaignCompaniesWithData: (
    campaignId: string,
    size: number,
    query: CompoundExpression | undefined,
    sortBy: OrderByRequest[],
    pageType: PageType
  ) =>
    [{ ...getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId)[0], size, query, sortBy, pageType }] as const,

  GetInfiniteCampaignCompaniesWithData: (
    campaignId: string,
    pagination: Pagination,
    query: CompoundExpression | undefined,
    sortBy: OrderByRequest[],
    pageType: PageType
  ) =>
    [
      {
        ...getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId)[0],
        level3: 'Infinite',
        pagination,
        query,
        sortBy,
        pageType
      }
    ] as const,

  CampaignMapCompaniesForCampaignId: (campaignId: string) => [
    { ...getSalestoolKeys.GetCampaignCompaniesForCampaignId(campaignId)[0], level3: 'mapCompanies' }
  ],
  CampaignMapCompaniesWithData: (
    campaignId: string,
    mapPositionData: MapPositionData | undefined,
    mapMaxZoom: number,
    query: CompoundExpression | undefined
  ) => [{ ...getSalestoolKeys.CampaignMapCompaniesForCampaignId(campaignId)[0], mapPositionData, mapMaxZoom, query }],

  GetSaleInfo: (salesId: string, campaignId?: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getSaleInfo', salesId, campaignId }] as const,

  GetMapDistribution: (campaignId: string, query: CompoundExpression | undefined) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getMapDistribution', campaignId, query }] as const,

  GetCompanyNotes: (companyId: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getCompanyNotes', companyId }] as const,
  GetProductsList: () => [{ ...getSalestoolKeys.All()[0], level2: 'getProductsList' }] as const,
  GetSalesList: (listType: SalesListType) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getSalesList', listType }] as const,
  CampaignCustomVariables: (campaignId: string) =>
    [{ ...getSalestoolKeys.All()[0], level2: 'getCampaignCustomVariables', campaignId }] as const,

  GetBestSalesByTask: (campaignId: string, taskId: string | undefined) =>
    [{ ...getSalestoolKeys.GetCampaign(campaignId)[0], level3: 'getBestSalesByTask', taskId }] as const
}

// TODO: it is called multiple times - neeed to add cacheAllTheTime and properly invalid when needed
export const useGetCampaign = (campaignId: string) =>
  useQuery(
    getSalestoolKeys.GetCampaign(campaignId ?? ''),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.campaignId !== '') return getCampaign(queryKeys.campaignId).then(mapCampaign)
      return undefined
    },
    {
      ...cacheAllTheTime
    }
  )

export const useGetCampaignStats = (campaignId: string, salesId?: string) =>
  useQuery(
    getSalestoolKeys.GetCampaignStatsWithSalesId(campaignId, salesId),
    ({ queryKey: [queryKeys] }) => getCampaignStats(queryKeys.campaignId, queryKeys.salesId),
    cacheAllTheTime
  )

export const useGetCampaigns = (params: CampaignRequestParams, enabled = true) =>
  useQuery(
    getSalestoolKeys.GetCampaignsWithData(params),
    ({ queryKey: [queryKeys] }) => getCampaigns(queryKeys.params).then(resp => resp.data),
    { ...cacheAllTheTime, enabled }
  )

export const useGetCampaignsStatusCount = () =>
  useQuery(getSalestoolKeys.GetCampaignsStatusCount(), getCampaignsStatusCount, cacheAllTheTime)

export type GetInfiniteCampaignsProps = {
  top: number
  campaignStatus: CampaignStatus | undefined
  isArchived: boolean | undefined
  nameLike: string | undefined
  saleUserName: string | undefined
  sortOrder: OrderByDirection | undefined
  sortPropertyName: CampaignFields | undefined
}

export const useGetInfiniteCampaigns = (props: GetInfiniteCampaignsProps) =>
  useInfiniteQuery(
    getSalestoolKeys.GetInfiniteCampaignsWithData(props),
    ({ queryKey: [queryKeys], pageParam = 0 }) =>
      getCampaigns({
        top: queryKeys.top,
        skip: pageParam,
        nameLike: queryKeys.nameLike,
        campaignStatus: queryKeys.campaignStatus,
        saleUserName: queryKeys.saleUserName,
        isArchive: queryKeys.isArchived,
        sortOrder: queryKeys.sortOrder,
        sortPropertyName: queryKeys.sortPropertyName
      }).then(resp => resp.data),
    {
      getNextPageParam: (lastResp, allPages) => {
        const count = _.sum(_.map(allPages, 'campaigns.length'))
        return lastResp?.campaignsCount <= count ? undefined : count
      },
      refetchOnMount: true
    }
  )

export const useGetCampaignCompanies = (campaignId: string, data: CampaignCompaniesReq) => {
  const convertedQuery = data.query ? convertCompoundExpressionForBackend(data.query) : undefined

  return useQuery(
    getSalestoolKeys.GetCampaignCompaniesWithData(
      campaignId,
      data.pagination.size,
      convertedQuery,
      data.sortBy,
      data.pageType
    ),
    ({ queryKey: [queryKeys] }) =>
      getCompanies(queryKeys.campaignId, {
        pageType: queryKeys.pageType,
        query: queryKeys.query,
        pagination: { position: 0, size: queryKeys.size },
        sortBy: queryKeys.sortBy
      }).then(res => res.data),
    cacheAllTheTime
  )
}

export const useGetInfiniteCampaignCompanies = (campaignId: string, data: CampaignCompaniesReq) => {
  const convertedQuery = data.query ? convertCompoundExpressionForBackend(data.query) : undefined

  return useInfiniteQuery(
    getSalestoolKeys.GetInfiniteCampaignCompaniesWithData(
      campaignId,
      data.pagination,
      convertedQuery,
      data.sortBy,
      data.pageType
    ),
    ({ queryKey: [queryKeys], pageParam = 0 }) =>
      getCompanies(queryKeys.campaignId, {
        pageType: queryKeys.pageType,
        query: queryKeys.query,
        pagination: { ...queryKeys.pagination, position: pageParam },
        sortBy: queryKeys.sortBy
      }).then(res => res.data),
    {
      getNextPageParam: (lastResp, allPages) => {
        const count = _.sum(_.map(allPages, 'data.length'))
        return lastResp?.totalCount <= count ? undefined : allPages.length
      },
      ...cacheAllTheTime
    }
  )
}

export const useGetCampaignMapCompanies = (
  campaignId: string,
  mapPositionData: MapPositionData | undefined,
  mapMaxZoom: number,
  query: CompoundExpression | undefined
) => {
  const convertedQuery = query ? convertCompoundExpressionForBackend(query) : undefined

  return useQuery(
    getSalestoolKeys.CampaignMapCompaniesWithData(campaignId, mapPositionData, mapMaxZoom, convertedQuery),
    ({ queryKey: [queryKeys] }) => {
      if (!queryKeys.mapPositionData) return (async () => undefined)()
      return getCampaignMapCompanies(
        queryKeys.campaignId,
        createCampaignCompaniesMapReq(queryKeys.mapPositionData, queryKeys.mapMaxZoom, queryKeys.query)
      ).then(res => res.data)
    },
    { keepPreviousData: true }
  )
}

export const useGetSaleInfo = (salesId: string, campaignId?: string) =>
  useQuery(getSalestoolKeys.GetSaleInfo(salesId, campaignId), ({ queryKey: [queryKeys] }) =>
    getSaleInfo(queryKeys.salesId, queryKeys.campaignId)
  )

export const useGetMapDistribution = (campaignId: string, query: CompoundExpression | undefined) =>
  useQuery(getSalestoolKeys.GetMapDistribution(campaignId, query), ({ queryKey: [queryKeys] }) =>
    getMapDistribution(queryKeys.campaignId, queryKeys.query)
  )

export const useGetCompanyNotes = (companyId: string) =>
  useQuery(
    getSalestoolKeys.GetCompanyNotes(companyId),
    ({ queryKey: [queryKeys] }) => getCompanyNotes(queryKeys.companyId),
    cacheAllTheTime
  )

export const useGetProductsList = <SelectType = SelectListResponse>(
  options?: UseQueryOptions<
    SelectListResponse,
    AxiosError,
    SelectType,
    ReturnType<typeof getSalestoolKeys.GetProductsList>
  >
) => useQuery(getSalestoolKeys.GetProductsList(), getProductsList, { ...cacheAllTheTime, ...options })

export const useGetSalesList = <SelectType = Sale[]>(
  listType: SalesListType = 'Simple',
  options?: UseQueryOptions<Sale[], AxiosError, SelectType, ReturnType<typeof getSalestoolKeys.GetSalesList>>
) =>
  useQuery(getSalestoolKeys.GetSalesList(listType), ({ queryKey: [queryKeys] }) => getSalesList(queryKeys.listType), {
    ...cacheAllTheTime,
    ...options
  })

export const useGetCampaignCustomVariablesTypes = (campaignId: string) =>
  useQuery(
    getSalestoolKeys.CampaignCustomVariables(campaignId),
    ({ queryKey: [queryKeys] }) => getCampaingCustomVariables(queryKeys.campaignId),
    {
      select: data => _.map(data.customVars, ({ type }) => type)
    }
  )

export const useGetBestSalesByTask = (campaignId: string, taskId: string | undefined) =>
  useQuery(
    getSalestoolKeys.GetBestSalesByTask(campaignId, taskId),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.taskId !== undefined) return getBestSalesByTask(queryKeys.campaignId, queryKeys.taskId)
      return undefined
    },
    {
      enabled: taskId !== undefined,
      ...cacheAllTheTime
    }
  )
