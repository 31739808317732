import { useSelector } from 'react-redux'
import enUS from 'antd3/es/locale/en_US'
import itIT from 'antd3/es/locale/it_IT'
import csCZ from 'antd3/es/locale/cs_CZ'
import skSK from 'antd3/es/locale/sk_SK'
import frFR from 'antd3/es/locale/fr_FR'
import deDE from 'antd3/es/locale/de_DE'
import { getSelectedLanguage } from '../../features/user/selectors'

export const useAntdLocale = () => {
  const selectedLanguage = useSelector(getSelectedLanguage)

  if (selectedLanguage === 'it-IT') return itIT
  if (selectedLanguage === 'cs-CZ') return csCZ
  if (selectedLanguage === 'sk-SK') return skSK
  if (selectedLanguage === 'de-DE') return deDE
  if (selectedLanguage === 'fr-FR') return frFR

  return enUS
}
