import { MiddlewareAPI, Dispatch } from 'redux'
import GA from 'react-ga'

import { RootState } from '../types'
import { ActionTrackingMap } from './types'
import { ActionWithPayload } from '../../utils/types'
import { LoadStatus } from '../config/types'
import { getAppConfigStatus } from '../config/selectors'
import { UserType } from '../../authorization'
import { Matomo } from './helpers'

const setGADimensions = (state: any) => {
  if (state.user?.user?.userCode) {
    GA.set({ userId: state.user.user.userCode })
  }
  if (state.user?.organization?.code) {
    GA.set({ dimension1: state.user.organization.code })
  }
  if (state.user?.user?.userType) {
    GA.set({ dimension2: UserType[state.user.user.userType] })
  }
  if (state.user?.organization?.name) {
    GA.set({ dimension3: state.user.organization.name })
  }
  if (state.user?.user?.userCode) {
    GA.set({ dimension4: state.user.user.userCode })
  }
  if (state.user?.user?.firstName && state.user?.user?.lastName) {
    const fullName = state.user.user.firstName.concat(' ', state.user.user.lastName)
    GA.set({ dimension5: fullName })
  }
  if (state.user?.user?.email) {
    GA.set({ dimension6: state.user.user.email })
  }
}

export const analyticsMiddleware = (tracking: ActionTrackingMap) => {
  // buffer used to cumulated all actions until app config is loaded
  let actionBuffer: ActionWithPayload<string, any>[] = []

  return (store: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch) => (action: ActionWithPayload<string, any>) => {
    next(action)

    const state = store.getState()
    const isConfigLoaded = getAppConfigStatus(state) === LoadStatus.LOADED

    const trackGA = !!state.config.googleAnalyticsId
    const trackMatomo = !!(state.config.matomo?.siteId && state.config.matomo?.urlBase)
    // If GA Id AND Matomo tracker info is not provided in config then no tracking will occur
    if (isConfigLoaded && !trackGA && !trackMatomo) {
      actionBuffer = []
      return
    }

    actionBuffer.push(action)

    if (!isConfigLoaded) return

    // once app config is loaded, pass all buffered actions trough tracking
    while (actionBuffer.length) {
      const bufferedAction = actionBuffer.shift()!
      const trackingFunc = tracking[bufferedAction.type]
      if (trackingFunc) {
        if (trackGA) {
          setGADimensions(state)
        }
        if (trackMatomo) {
          Matomo.tracker?.pushInstruction('setUserId', state.user.user.userCode)
          Matomo.tracker?.pushInstruction('rememberConsentGiven')
        }
        try {
          trackingFunc(state, bufferedAction, !!trackGA, !!trackMatomo)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(`Tracking function [${bufferedAction.type}] crashed:`, e)
        }
      }
    }
  }
}
