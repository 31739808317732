import React from 'react'
import { Dialog } from '../../../components/Dialog'
import { PersonPopup } from '../../../components/PersonPopup'
import { PersonPopupDialogData } from '../../../features/dialog/dataTypes'

type Props = {
  close: () => void
  data: PersonPopupDialogData
}

export const PersonPopupDialog: React.FC<Props> = ({ close, data }) => {
  return (
    <Dialog visible loading={false} width={900} onCancel={close}>
      <PersonPopup personId={data.personId} personData={data.personData} openPersonReport={data.openPersonReport} />
    </Dialog>
  )
}
