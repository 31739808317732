/* eslint-disable import/no-default-export */
export default {
  Support: {
    Email: 'customer_service.de@crif.com',
    Phone: '+49 40 89803-0'
  },
  ImprintPolicy: 'Impressum',
  PrivacyPolicy: 'Datenschutzbestimmungen',
  CookiesPolicy: 'Cookie-Richtlinie',
  Contact: 'Kontaktieren Sie uns'
}
