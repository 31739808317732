import { ApplicationModule } from '../../types'

export const getRenderMinWidth =
  (minWidth: number, noPadding = false): ((text: any) => React.ReactNode) | undefined =>
  children => ({
    props: {
      style: { minWidth, ...(noPadding ? { padding: 0 } : {}) }
    },
    children,
    type: 'div',
    key: null
  })

export const getPortfolioRedirectState = (portfolioId: string) => {
  return {
    entityItemId: portfolioId,
    module: ApplicationModule.PortfolioManagement
  }
}

export const getProspectingRedirectState = () => {
  return {
    entityItemId: undefined,
    module: ApplicationModule.Prospecting
  }
}

export const getSalestoolRedirectState = (campaignId: string) => {
  return {
    entityItemId: campaignId,
    module: ApplicationModule.Salestool
  }
}

export const getOperationsRedirectState = () => {
  return {
    entityItemId: undefined,
    module: ApplicationModule.Operations
  }
}

export const getRedirectStateBasedOnModule = (module: ApplicationModule, portfolioId?: string, campaignId?: string) => {
  if (module === ApplicationModule.Prospecting) return getProspectingRedirectState()
  if (module === ApplicationModule.PortfolioManagement && portfolioId) return getPortfolioRedirectState(portfolioId)
  if (module === ApplicationModule.Salestool && campaignId) return getSalestoolRedirectState(campaignId)
  if (module === ApplicationModule.Operations) return getOperationsRedirectState()

  return undefined
}
