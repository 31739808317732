import _ from 'lodash'
import { PortfolioCompany, PortfolioCompanySelectionItem } from './types'

export const IDENTIFICATION_CODE = 'identificationCode'

export function formatPortfolioCompanyToPortfolioSelectionItem(
  company: PortfolioCompany
): PortfolioCompanySelectionItem {
  return {
    companyUnitId: company.companyUnitId,
    entityId: company.entityId,
    identificationCode: _.get(company.fields, IDENTIFICATION_CODE, undefined),
    sqlVersion: 0,
    isSharedWithAreaManager: company.isSharedWithAreaManager,
    isSharedWithSales: company.isSharedWithSales,
    isEnriched: company.isEnriched
  }
}
