import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { notificationActionUrl, notificationActionCallback } from '../../utils/notifications'
import { Notification } from '../../features/notifications/types'
import { NotificationText } from './NotificationText'
import { NavigationButtonContainer, NavigationButton } from './NotificationAction'
import { NotificationSection } from './NotificationSection'
import * as Drawer from './DrawerStyledComponents'

type NotificationProps = {
  notification: Notification
  onClose: () => void
  onRead: (notificationId: string) => void
}

export const DrawerNotification = ({ notification, onClose, onRead }: NotificationProps) => {
  const route = notificationActionUrl(notification)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!notification.read && !notificationActionUrl(notification)) onRead(notification.id)
  }, [notification, onRead])

  const onClickCallback = useCallback(() => {
    if (!notification.read) onRead(notification.id)
    if (!route) return
    onClose()
    notificationActionCallback(notification, dispatch, navigate, route)
  }, [notification, onRead, route, onClose, dispatch, navigate])
  const NotificationWrapper = !route ? Drawer.NotificationWrapper : Drawer.NotificationWrapperClickable
  return (
    <NotificationWrapper onClick={onClickCallback}>
      <Drawer.DescriptionWrapper>
        <Drawer.NotificationDate notification={notification} />
        <NotificationSection t={t} notification={notification} type={Drawer.TEXT_COLOR} bold block />
        <NotificationText t={t} type={Drawer.TEXT_COLOR} notification={notification} block />
      </Drawer.DescriptionWrapper>
      {!!route && (
        <NavigationButtonContainer>
          <NavigationButton type={Drawer.TEXT_COLOR} role="button" aria-label="navigation action" />
        </NavigationButtonContainer>
      )}
    </NotificationWrapper>
  )
}
