/* eslint-disable import/no-default-export */
export default {
  DashboardTitle: 'Votre tableau de bord',
  DashboardSubtitle: 'Utiliser les cases pour voir rapidement toutes les données !',
  OrganizeBox: 'Organiser les cases',
  ChangeBox: 'Changer',
  RemoveBox: 'Supprimer la case',
  ChooseWhatDisplay: 'Choisir les éléments à afficher dans cette case',
  PortfolioTitle: 'Portefeuilles',
  PortfolioSubtitle: "Vos portefeuilles en un coup d'œil",
  PortfolioAndCampaignTitle: 'Portefeuilles et campagnes',
  PortfolioAndCampaignSubtitle: "Vos portefeuilles et campagnes en un coup d'œil",
  AnalysisTitle: 'Requêtes de portefeuille, analyses et recherches',
  AnalysisSubtitle: 'Aperçu de vos requêtes de portefeuille, analyses et recherches enregistrées',
  SuggestedProspects: 'Prospect suggéré',
  CreatedOn: 'Créé le {{date}}',
  EmptyWidget: {
    SelectPortfolio: {
      BoxTitlePortfolio: 'Sélectionner un portefeuille',
      BoxTitlePortfolioAndCampaign: 'Sélectionner un portefeuille ou une campagne',
      Title: 'Portefeuille',
      Description: 'Sélectionner un portefeuille dans « Gestion des portefeuilles »',
      SelectLabel: 'Sélectionner portefeuille',
      SelectPlaceholder: 'Cliquer pour sélectionner un portefeuille',
      Button: 'Sélectionner',
      Banner: {
        Message:
          'Si vous ne souhaitez pas sélectionner de portefeuille, il est possible d\'en créer un à partir de "Gestion des portefeuilles"',
        Button: 'Créer'
      }
    },
    SelectCampaign: {
      BoxTitle: 'Sélectionner un portefeuille ou une campagne',
      Title: 'Campagnes',
      Description: 'Sélectionner une campagne dans « Outil de vente »',
      SelectLabel: 'Sélectionner campagne',
      SelectPlaceholder: 'Cliquer pour sélectionner une campagne',
      Button: 'Sélectionner',
      Banner: {
        Message:
          'Si vous ne souhaitez pas sélectionner de campagne, il est possible d\'en créer une à partir d\'"Outil de vente"',
        Button: 'Créer'
      }
    },
    SelectProspectingAnalysis: {
      BoxTitle: 'Sélectionner les requêtes de portefeuille, analyses et recherches',
      Title: 'Recherche',
      Description: 'Sélectionner une recherche enregistrée',
      SelectLabel: 'Sélectionner une recherche',
      SelectPlaceholder: 'Cliquez pour sélectionner une recherche',
      SelectPlaceholderNoQuery: 'Aucune requête enregistrée',
      Button: 'Sélectionner',
      Banner: {
        Message: "Si vous ne souhaitez pas sélectionner d'analyse une recherche, vous pouvez en créer une nouvelle",
        Button: 'Créer'
      }
    },
    SelectTargetingAnalysis: {
      BoxTitle: 'Sélectionner les requêtes de portefeuille, analyses et recherches',
      Title: 'Analyse',
      Description: 'Sélectionner une analyse enregistrée',
      SelectLabel: 'Sélectionner une analyse',
      SelectPlaceholder: 'Cliquez pour sélectionner une analyse',
      SelectPlaceholderNoQuery: 'Aucune requête enregistrée',
      Button: 'Sélectionner',
      Banner: {
        Message: "Si vous ne souhaitez pas sélectionner d'analyse, vous pouvez en créer une nouvelle",
        Button: 'Créer'
      }
    },
    SelectPortfolioAnalysis: {
      BoxTitle: 'Sélectionner les requêtes de portefeuille, analyses et recherches',
      Title: 'Requête de portefeuille',
      Description: 'Sélectionner une requête de portefeuille enregistrée',
      SelectLabel: 'Sélectionner une requête de portefeuille',
      SelectPlaceholder: 'Cliquez pour sélectionner une requête de portefeuille',
      SelectPlaceholderNoQuery: 'Aucune requête enregistrée',
      Button: 'Sélectionner',
      Banner: {
        Message: 'Si vous ne souhaitez pas sélectionner de requête de portefeuille, vous pouvez en créer une nouvelle',
        Button: 'Créer'
      }
    },
    UploadingPortfolio: {
      BoxTitle: 'Charger portefeuille',
      Title: 'Chargement de portefeuille',
      Description: "Cette case fournit une liste d'aperçu",
      BannerMessage: "Modifier son contenu à tout moment en cliquant sur l'icône en haut à droite"
    }
  },
  PortfolioWidget: {
    NoData: "Les données n'existent pas.",
    NumberOfCompanies: '{{companiesCount}} société',
    NumberOfCompanies_plural: '{{companiesCount}} sociétés',
    Monitored: 'Surveillée',
    Actions: 'Actions',
    Enrich: 'Enrichir',
    EnrichTooltip: 'Améliore les données de ces sociétés',
    Analyze: 'Analyser',
    AnalyzeTooltip: 'Affiche les données de portefeuille par rapport aux variables principales',
    Similarity: 'Analogie',
    SimilarityTooltip: 'Applique un algorithme par analogies pour trouver de nouveaux prospects'
  },
  ProspectingWidget: {
    NoData: "Les données n'existent pas.",
    NumberOfCompanies: '{{companiesCount}} Société',
    NumberOfCompanies_plural: '{{companiesCount}} Sociétés',
    Monitored: 'Surveillée',
    MoreFilters: '{{filtersCount}} filtre en plus',
    MoreFilters_plural: '{{filtersCount}} filtres en plus',
    ViewDetailsTooltip: 'Voir les filtres appliqués à cette liste de prospection'
  },
  TargetingWidget: {
    NoData: "Les données n'existent pas.",
    Companies: 'Société',
    Companies_plural: 'Sociétés'
  },
  CampaignWidget: {
    NoData: "Les données n'existent pas.",
    NumberOfCompanies: '{{companiesCount}} société',
    NumberOfCompanies_plural: '{{companiesCount}} sociétés'
  },
  AppointmentsWidget: {
    Title: 'Vos rendez-vous',
    NoAppointments: "Vous n'avez pas de rendez-vous",
    MoreEvents: '{{eventsCount}} rendez-vous de plus',
    MoreEvents_plural: '{{eventsCount}} mautres rendez-vous',
    Today: "Aujourd'hui",
    Tomorrow: 'Demain'
  },
  LatestDocuments: {
    Title: 'Derniers documents',
    NoDocuments:
      'Cette case permet de voir tous vos derniers téléchargements. Vos documents sont déposés sur votre profil.',
    Info: 'Le tableau indique les derniers documents téléchargés',
    Table: {
      Name: 'Nom',
      Type: 'Type',
      CreationDate: 'Date de création',
      ExpirationDate: "Date d'expiration",
      DownloadExcel: 'Excel téléchargé',
      Campaign: 'Campagne',
      Report: 'Rapport'
    }
  },
  PortfolioQueryWidget: {
    MoreFilters: '{{filtersCount}} filtre en plus',
    MoreFilters_plural: '{{filtersCount}} filtres en plus',
    ViewDetailsTooltip: 'Voir les filtres appliqués à ce portefeuille'
  }
}
