import React from 'react'
import ErrorImage from '../../../assets/images/ko.svg'
import { Text } from '../../../components/Text'
import { GenericContent } from './styled'

type Props = {
  message: string
  description?: string
}

export const ErrorContent = ({ description, message = 'Error' }: Props) => (
  <GenericContent>
    <div className="text">
      <Text size="M" type="primary" bold block>
        {message}
      </Text>
      {description && (
        <Text size="M" block>
          {description}
        </Text>
      )}
    </div>
    <img src={ErrorImage} alt="error" />
  </GenericContent>
)
