import styled from 'styled-components/macro'
import { Form, InputNumber as AntInputNumber } from 'antd3'
import { RadioButtonGroup } from '../../../components/Radio'
import { Input } from '../../../components/Input'
import { SmallSpin } from '../../../components/Loading/Spin'

export const CompoundExpressionBox = styled.div`
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;

  &.bright {
    background-color: #fff2e5;
  }

  &.dark {
    background-color: #ffdebe;
  }
`

export const ButtonsBox = styled.div`
  padding-left: 10px;
  margin-left: auto;

  button {
    margin-left: 16px;
  }
`

export const ExpressionHeader = styled.div`
  display: flex;
  margin: 5px;
`

export const FilterExpressionBox = styled.div`
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  margin: 5px;
  display: flex;
  gap: 16px;
`

export const RuleBox = styled.div`
  display: flex;
  flex: 1;
  max-width: 100%;
  margin-right: auto;
  gap: 16px;
  > div,
  > span {
    display: flex;
    flex: 1;
    max-width: 18%;
    max-height: 40px;
  }

  .rangeField {
    max-width: 60px;
  }
`

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f1f5f8;
  border-radius: 5px;
  width: auto;
`
export const InputNumber = styled(AntInputNumber)`
  &.ant-input-number {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 4px;
    background-color: #f1f5f8;
    > div {
      height: 100%;
      input {
        height: 100%;
      }
    }
  }
`

export const DomainSelectBox = styled.div`
  display: flex;
  min-width: 40%;
  max-width: 40% !important;
  > div {
    overflow: visible;
    width: 70%;
  }

  > div:nth-child(2) {
    .ant-tag {
      width: 40%;
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
      }
    }
  }

  > div:nth-child(3) {
    flex-direction: column;
    width: auto;
    min-width: 80px;
    margin-left: 5px;
    > div {
      margin-left: 0px;
      z-index: 1;
    }
  }
`

export const SwitchBox = styled.div`
  flex-direction: column;
  align-items: center;
  min-width: 80px;
  max-width: 10% !important;

  .ant-switch {
    max-width: 60px;
  }
`
export const StyledInput = styled(Input)`
  margin: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
`

export const Spinner = styled(SmallSpin)`
  margin-top: 10px;
`

export const FormItem = styled(Form.Item)`
  .ant-form-item-control-wrapper {
    width: 100%;
  }

  .ant-form-item-control {
    height: 100%;

    &.has-error {
      border-radius: 4px;
      box-shadow: ${({ theme }) => `0 0 0 2px ${theme.colors.watermelonLight}`};
    }

    .ant-calendar-picker {
      width: 100%;
    }
  }

  .ant-form-explain {
    padding-top: 2px;
    overflow: visible;
    white-space: nowrap;
  }
`
