import React, { useState } from 'react'
import { useCanAddWidget } from '../../../Dashboard/hooks/useCanAddWidget'
import { DialogPropsType } from '../types'
import { useSaveQueryMutation } from './mutations'
import { SaveQueryConfirmedDialog } from './SaveQueryConfirmedDialog'
import { SaveQueryDialog } from './SaveQueryDialog'
import { SubmitMethod } from './types'

export const SaveQueryDialogContainer = ({ close, data: { setQuerySaved, contextData } }: DialogPropsType) => {
  const [queryName, setQueryName] = useState('')
  const [addToDashboard, setAddToDashboard] = useState(false)
  const [queryId, setQueryId] = useState<string>()
  const [nameError, setNameError] = useState<string>()
  const [updateError, setUpdateError] = useState<string>()
  const [currentSaveMethod, setCurrentSaveMethod] = useState(SubmitMethod.CreateNew)

  const { canAddWidget, isDashboardLoading } = useCanAddWidget()

  const saveQuery = useSaveQueryMutation({
    queryId,
    queryName,
    expression: contextData.expression,
    semanticSearch: contextData.semanticSearch,
    excludedPortfoliosIds: contextData.excludedPortfoliosIds,
    selectedPortfoliosIds: contextData.selectedPortfoliosIds,
    currentSaveMethod,
    addToDashboard,
    setUpdateError,
    setNameError
  })

  if (saveQuery.isSuccess) {
    return <SaveQueryConfirmedDialog queryName={queryName} addToDashboard={addToDashboard} close={close} />
  }

  return (
    <SaveQueryDialog
      canAddWidget={canAddWidget}
      loading={isDashboardLoading}
      submitting={saveQuery.isLoading}
      queryName={queryName}
      queryNameError={currentSaveMethod === SubmitMethod.CreateNew ? nameError : updateError}
      addToDashboard={addToDashboard}
      setQueryName={setQueryName}
      setCurrentSaveMethod={setCurrentSaveMethod}
      currentSaveMethod={currentSaveMethod}
      queryId={queryId}
      setQueryId={setQueryId}
      setAddToDashboard={setAddToDashboard}
      close={close}
      submit={() => {
        setQuerySaved(true)
        saveQuery.mutate()
      }}
      setNameError={setNameError}
    />
  )
}
