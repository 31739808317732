import React from 'react'
import styled, { css } from 'styled-components/macro'
import CheckedIcon from '../../assets/icons/check-icon-white.svg'

const baseStepStyle = css`
  position: relative;
  min-width: 24px;
  min-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.veryLightPink};
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 60, 125, 0.1);
`

const centerInStep = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Step = styled.div<{ active: number }>`
  ${baseStepStyle}
  background-color: ${({ theme }) => theme.colors.white};

  ::after {
    ${centerInStep}
    display: block;
    content: '';
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background-color: ${({ theme: { colors }, active }) => (active ? colors.main : colors.whiteGray)};
  }
`

const StepCheckedWrapper = styled.div`
  ${baseStepStyle}
  background-color: ${({ theme }) => theme.colors.main};

  .check {
    ${centerInStep}
    width: 18px;
    height: 18px;
  }
`

export const StepChecked = () => (
  <StepCheckedWrapper>
    <img className="check" src={CheckedIcon} alt="checked" />
  </StepCheckedWrapper>
)
