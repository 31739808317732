/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: 'Typ analýzy',
    StartAnalysis: 'Pro zahájení analýzy musíte zvolit typ analýzy, kterou chcete zpracovat',
    MarketAnalysisBtn: 'Spustit novou Analýzu trhu',
    MarketAnalysisBtnTooltip:
      'Vizualizujte a segmentujte skupinu společností podle hlavních proměnných a vytvořte si cíl',
    LoadQuery: 'Načíst dotaz',
    LoadQueryTooltip: 'Otevřete uložený dotaz',
    FromTargeting: 'Načíst dotaz z Analýzy trhu',
    FromTargetingTooltip: 'Otevřete dotaz uložený v modulu Analyzovat trh',
    FromProspecting: 'Načíst dotaz z Výběru klientů',
    FromProspectingTooltip: 'Otevřete dotaz uložený v modulu Prospekting',
    PortfolioQuery: 'Načtěte dotaz ze správy portfolia',
    TargetingQuery: 'Načíst dotaz z Analýzy trhu',
    ProspectingQuery: 'Načíst dotaz z Výběru klientů',
    ChooseQuery: 'Vyberte, který dotaz chcete načíst'
  },
  ChartLabels: {
    Company_0: 'Společnost',
    Company_1: 'Společnosti',
    Company_2: 'Společností',
    ChartDetail: 'Podrobnosti grafu',
    PercentageOfCompanies: '% společností',
    NumberOfCompanies: 'počet společností',
    NumberOfEmployees: 'počet zaměstnanců',
    Amount: 'Suma',
    Risk: 'Úroveň rizika',
    Employees: 'Zaměstnanci',
    Revenue: 'Příjmy',
    RevenueCz: 'Příjmy',
    RevenueSk: 'Příjmy',
    Other: 'Jiné',
    Years: 'Roky',
    HiddenNDNotice: 'Poznámka: tento graf nezobrazuje třídu společností bez údajů'
  },
  Header: {
    Targeting: 'Analýza trhu',
    Title: 'Analýza trhu',
    LoadQuery: 'Načíst dotaz',
    LoadQueryTooltip: 'Otevřete uložený dotaz',
    SaveQuery: 'Uložit dotaz',
    SaveQueryTooltip: 'Uložte tento dotaz do modulu Analyzovat trh',
    Download: 'Stáhnout PDF/Excel',
    DownloadTooltip: 'Stáhněte si report o tomto dotazu do svého zařízení',
    LoadPortfolio: 'Načíst portfolio',
    LoadPortfolioTooltip: 'Otevřete a analyzujte portfolio',
    SendToProspecting: 'Poslat dotaz do Výběru klientů',
    SendToProspectingTooltip: 'Zobrazit seznam společností, které patří do tohoto dotazu',
    SendToProspectingFromPortfolioTooltip: 'Zobrazit potenciální společnosti, které splňují kritéria vyhledávání',
    SendToProspectingMessage: 'Chcete-li toto tlačítko aktivovat, použijte na svou analýzu filtr',
    SaveList: 'Uložit seznam',
    SaveListTooltip: 'Uložte seznam těchto společností do portfolia'
  },
  Filters: {
    CompanyCount_0: 'Společnost',
    CompanyCount_1: 'Společnosti',
    CompanyCount_2: 'Společností',
    ActiveCompanyCount_0: 'Aktivní',
    ActiveCompanyCount_1: 'Aktivní',
    ActiveCompanyCount_2: 'Aktivních',
    OtherCompanyCount_0: 'Jiná',
    OtherCompanyCount_1: 'Jiné',
    OtherCompanyCount_2: 'Jiných',
    StatusSelectLabel: 'Zvolit status',
    ShowBySelectLabel: 'Zobrazit podle',
    AggregationFieldAmount: 'Suma',
    AggregationFieldNumCompanies: 'Počet společností'
  },
  Search: {
    SemanticSearch: 'Sémantické vyhledávání',
    SearchPlaceholder: 'Hledat',
    All: 'Vše',
    OfficialData: 'Oficiální údaje',
    Website: 'Web a sociální sítě',
    Synonyms: 'Synonyma',
    Advanced: 'Pokročilé sémantické vyhledávání',
    AdvancedSwitch: 'Rozšířené',
    AdvancedTooltip:
      'S pokročilým sémantickým vyhledáváním můžete použít více slov propojených logickými operátory (AND, OR, NOT). Pokud ho chcete povolit, přepněte přepínač do polohy ZAP. Pro více informací si stáhněte dokument.',
    DownloadSsGuide: 'Stáhnout průvodce sémantickým vyhledáváním'
  },
  DomainFilter: {
    SaveAndClose: 'Uložit filtry a zavřít '
  },
  SaveQuery: {
    DialogTitle: 'Uložit vyhledávání',
    Description:
      'Zadejte název, který chcete použít k uložení výzkumu, nebo vyberte existující dotaz, který chcete přepsat.',
    InputLabel: 'Název dotazu',
    InputPlaceholder: 'Název vyhledávání',
    UpdateExisting: 'Aktualizovat stávající',
    CreateNew: 'Vytvořit nový',
    AddToDashboardConfirmMessage: 'Chcete přidat dotaz na svůj dashboardu?',
    AddToDashboardCheckboxText: 'Zobrazit na dashboardu',
    SavedQuery: 'správně uložený.',
    SavedQueryAndDashboard: 'uložený a přidaný do dashboardu.',
    SavedQueryDescription: 'Dotaz najdete uložený v úložišti souvisejícím s referenční oblastí.'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Vyberte, který dotaz z Výběru klientů chcete načíst',
    IntroSectionTitleTargeting: 'Vyberte, který dotaz na Analýzu trhu dotaz chcete načíst',
    IntroSectionDescription: 'Můžete pokračovat ve vyhledávání, které jste už začali.',
    DialogTitle: 'Načíst uložené vyhledávání',
    DialogSubTitle: 'Vyberte uložené vyhledávání',
    DialogDescription:
      'Vyberte, ve které analýze chcete pokračovat. Můžete pokračovat jednou ze svých uložených analýz.',
    SelectLabel: 'Uložený dotaz',
    Load: 'Načíst',
    LoadingMessage: 'Načítá se vaše analýza',
    InvalidPortfolioTitle: 'Neplatné portfolio',
    InvalidPortfolioMessage:
      'Portfolio uložené v dotazu již není platné. Vaše analýza bude založena na všech společnostech v databázi {{companyName}}.',
    TargetingRadio: 'Analýza trhu',
    ProspectingRadio: 'Výběr klientů'
  },
  LoadPortfolio: {
    DialogTitle: 'Načíst portfolio',
    DialogSubTitle: 'Vybrat portfolio',
    Description: 'Vyberte, na kterém portfoliu chcete provést svou analýzu.',
    SelectLabel: 'Uložené portfolio',
    Load: 'Načíst',
    LoadingMessage: 'Načítá se portfolio'
  },
  SaveBeforeContinuing: {
    Question: 'Chcete před načtením vyhledávání, uložit svoje vyhledávání?',
    Description: 'Před načtením dotazu uložte svoje vyhledávání, aby nedošlo ke ztrátě dat.',
    OkBtn: 'Uložit hledání',
    CancelBtn: 'Přeskočit uložení'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: 'Vyberte portfolio, které chcete analyzovat',
      Description:
        'Analýzu můžete zpracovat z jednoho nebo více svých portfolií nebo zobrazit souhrnnou analýzu všech společností v systému {{companyName}}.',
      PortfolioOption: 'Vybrat portfolio',
      AggregateOption: 'Všechny společnosti v systému {{companyName}}',
      UploadedPortfolio: 'Nahrané portfolio'
    },
    StepTwo: {
      Header: 'Vyberte status, který chcete analyzovat',
      DescriptionLine1: 'Vyberte počáteční status společností, které mají být analyzovány.',
      DescriptionLine2: 'Během analýzy můžete změnit status pomocí filtrů.',
      Status: 'Status'
    }
  },
  Download: {
    ModalTitle: 'Stáhnout PDF/Excel',
    SelectType: 'Zvolit formát',
    SelectTypeDescription:
      'Můžete stáhnout kompletní report své analýzy ve dvou různých formátech; vyberte typ, který chcete stáhnout.',
    DownloadAnalysis: 'Stáhnout svou analýzu',
    Pdf: 'PDF',
    PdfDescription: 'Stažení ve formátu .pdf',
    PdfRecap: 'Stahujete soubor obsahující všechny výsledky této analýzy ve formátu pdf.',
    Excel: 'Excel',
    ExcelDescription: 'Stažení ve formátu .xlsx',
    ExcelRecapLine1: 'Stahujete soubor, který obsahuje všechny výsledky této analýzy v podobě seznamu.',
    ExcelRecapLine2: 'Uloží se do části "Analýza trhu" ve vašem účtu.',
    CompanyInPortfolio_0: 'Společnost v tomto portfoliu',
    CompanyInPortfolio_1: 'Společnosti v tomto portfoliu',
    CompanyInPortfolio_2: 'Společností v tomto portfoliu',
    CompanyInAnalysis: 'Analyzována společnost',
    CompanyInAnalysis_0: 'Analyzovaná společnost',
    CompanyInAnalysis_1: 'Analyzované společnosti',
    CompanyInAnalysis_2: 'Analyzovaných společností',
    DownloadPdf: 'Stáhnout PDF',
    DownloadPdfPleaseWait: 'Počkejte, prosím, než se vygeneruje váš PDF report',
    DownloadAllChartsMustBeLoaded: 'Pokud si chcete report stáhnout, počkejte prosím, než se načtou všechny grafy',
    DownloadPdfDisabledIE:
      'Stažení PDF není v této verzi prohlížeče podporováno. Pokud chcete stáhnout PDF, aktualizujte prohlížeč.',
    PdfPage: {
      Title: 'Report analýzy',
      CompanyPortfolioStatus: 'Status společností v portfoliu',
      DataShownBy: 'Údaje zobrazené podle',
      ExcludedPortfolios: 'Vyloučená portfolia'
    }
  },
  UnavailableForMobile: 'Stránka, na kterou se pokoušíte dostat, je k dispozici pouze pro tablety a počítače.',
  ChartFetchError: 'Při načítání tohoto grafu došlo k chybě.',
  ChartFetchRetry: 'Zkusit znovu',
  ChartEmpty: 'Pro tento graf neexistují data',
  BasedOnHeadquarter: 'Zobrazená čísla se týkají administrativního sídla',
  VisualizeAs: {
    List: 'Vizualizovat jako seznam',
    Map: 'Vizualizovat jako mapu'
  }
}
