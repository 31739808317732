import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dialog } from '../../../components/Dialog'
import { ContactViewType } from './types'
import { CenterChildren } from '../../../components/Center'
import OkImage from '../../../assets/images/ok.svg'
import { ContactForm } from '../ContactForm'
import { getCompanyName, getCountryCode } from '../../../features/config/selectors'
import { gridTheme } from '../../../utils/theme'

type Props = {
  isLoading: boolean
  view: ContactViewType
  close: () => void
}

const CenterText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
`

const ContainerItem = styled.div`
  flex: 1 1 auto;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    &:not(:first-child) {
      padding-top: 40px;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    flex-direction: column;
  }
`

export const ContactDialog = ({ isLoading, view, close }: Props) => {
  const { t } = useTranslation(['common', 'contact'])
  const companyName = useSelector(getCompanyName)
  const countryCode = useSelector(getCountryCode)

  const props = {
    destroyOnClose: false,
    width: 800,
    visible: true,
    loading: isLoading,
    onCancel: close,
    footer: '',
    title: t('contact:ContactUs', { companyName })
  }

  if (view === ContactViewType.INIT) {
    return (
      <Dialog {...props} footer={null}>
        <Container>
          <ContainerItem>
            <ContactForm />
          </ContainerItem>
        </Container>
      </Dialog>
    )
  }

  if (view === ContactViewType.DONE) {
    return (
      <Dialog {...props} okText={t('common:Ok')} onOk={close}>
        <Container>
          <ContainerItem>
            <CenterText>
              {countryCode === 'ita' ? t('contact:SuccessITA') : t('contact:Success', { companyName })}
            </CenterText>
          </ContainerItem>
          <ContainerItem>
            <CenterChildren>
              <img src={OkImage} alt="ok" />
            </CenterChildren>
          </ContainerItem>
        </Container>
      </Dialog>
    )
  }
  return null
}
