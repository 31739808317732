import React from 'react'
import { WithT } from 'i18next'
import { EnrichDoneStatistic } from '../../../components/Statistic/EnrichDoneStatistic'
import { Text } from '../../../components/Text'
import { CompanyEnrichProposal } from './types'

type Props = {
  enrichmentInfo?: CompanyEnrichProposal
} & WithT

export const EnrichCompanyComplete = ({ enrichmentInfo, t }: Props) => (
  <>
    <Text text={t('Enrich.EnrichmentComplete')} block padding="0 0 20px 0" />
    {enrichmentInfo && (
      <EnrichDoneStatistic
        enrichedCompanies={enrichmentInfo.companiesToEnrichCount}
        remainingCredits={enrichmentInfo.remainingCreditsAfterCompaniesEnrichment}
        usedCredits={enrichmentInfo.requiredCreditsToEnrichCompanies}
      />
    )}
  </>
)
