import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { getUserDashboard } from './api'
import { DocumentType } from '../../features/documents/types'
import { mapUserDashboardResponse } from '../../features/mappers'
import { cacheAllTheTime } from '../../features/queryclient'
import { getUserId } from '../../features/user/selectors'
import { useAppSelector } from '../../hooks/useAppSelector'
import { getDocuments } from '../documents'

export const getDashboardKeys = {
  All: () => [{ level1: 'dashboard' }] as const,
  GetUserDashboard: () => [{ ...getDashboardKeys.All()[0], level2: 'getUserDashboard' }] as const,
  GetInfiniteDocuments: (userId: string, top: number, filterTypes: DocumentType) =>
    [{ ...getDashboardKeys.All()[0], level2: 'getInfiniteDocuments', userId, top, filterTypes }] as const
}

export const useGetUserDashboard = (enabled?: boolean) => {
  return useQuery(getDashboardKeys.GetUserDashboard(), () => getUserDashboard(), {
    select: data => {
      return mapUserDashboardResponse(data)
    },
    enabled,
    ...cacheAllTheTime
  })
}

export const useGetInfiniteDocuments = (top: number) => {
  const userId = useAppSelector(getUserId)

  return useInfiniteQuery(
    getDashboardKeys.GetInfiniteDocuments(userId, top, DocumentType.ExportedPortfolio),
    ({ queryKey: [queryKeys], pageParam = 0 }) =>
      getDocuments({
        'filter.userId': queryKeys.userId,
        top: queryKeys.top,
        skip: pageParam,
        'filter.types': queryKeys.filterTypes
      }),
    {
      getNextPageParam: (lastResp, allPages) => {
        const count = _.sum(_.map(allPages, 'items.length'))
        return lastResp?.totalCount <= count ? undefined : count
      },
      ...cacheAllTheTime
    }
  )
}
