import React, { ReactNode } from 'react'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { Tooltip, TooltipProps } from '../Tooltip'
import { ReactComponent as Icon } from '../../assets/icons/icon-s-info-full.svg'

const InfoIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.pumpkinOrange};
`

type Props = {
  icon?: ReactNode
  iconSize?: number
} & Omit<TooltipProps, 'title' | 'overlay'>

export const InfoTooltip = ({ children, placement = 'left', icon, iconSize = 22, ...rest }: Props) => (
  <Tooltip
    {...rest}
    placement={placement}
    title={_.isString(children) ? children : undefined}
    overlay={_.isString(children) ? undefined : children}
  >
    {icon || <InfoIcon width={iconSize} height={iconSize} role="img" />}
  </Tooltip>
)
