/* eslint-disable import/no-default-export */
export default {
  DashboardTitle: 'Ihr Dashboard',
  DashboardSubtitle: 'Verwenden Sie Widgets, um die für Sie relevanten Daten auf einen Blick zu sehen!',
  OrganizeBox: 'Widgets anordnen',
  ChangeBox: 'Ändern',
  RemoveBox: 'Löschen',
  ChooseWhatDisplay: 'Bitte wählen Sie aus',
  PortfolioTitle: 'Portfolio',
  PortfolioSubtitle: 'Ihr Portfolio auf einen Blick',
  PortfolioAndCampaignTitle: 'Portfolios und Kampagnen',
  PortfolioAndCampaignSubtitle: 'Ihre Portfolios und Kampagnen auf einen Blick',
  AnalysisTitle: 'Portfolioabfragen, Analysen und Recherchen',
  AnalysisSubtitle: 'Vorschau Ihrer gespeicherten Portfolioabfragen, Analysen oder Recherchen an',
  SuggestedProspects: 'Vorschlagsliste Prospets',
  CreatedOn: 'Erstellt am {{date}}',
  EmptyWidget: {
    SelectPortfolio: {
      BoxTitlePortfolio: 'Portfolio auswählen',
      BoxTitlePortfolioAndCampaign: 'Wählen Sie ein Portfolio oder eine Kampagne aus',
      Title: 'Portfolio',
      Description: 'Portfolio aus "Portfolio Management" auswählen',
      SelectLabel: 'Portfolio auswählen',
      SelectPlaceholder: 'Klicken um ein Portfolio auszuwählen',
      Button: 'Auswählen',
      Banner: {
        Message:
          'Wenn Sie kein Portfolio auswählen möchten, können Sie über "Portfolio Management" ein Portfolio erstellen',
        Button: 'Erstellen'
      }
    },
    SelectCampaign: {
      BoxTitle: 'Wählen Sie ein Portfolio oder eine Kampagne aus',
      Title: 'Kampagnen',
      Description: 'Wählen Sie eine Kampagne aus dem „Sales Tool“ aus',
      SelectLabel: 'Kampagne auswählen',
      SelectPlaceholder: 'Klicken Sie, um eine Kampagne auszuwählen',
      Button: 'Auswählen',
      Banner: {
        Message: 'Wenn Sie keine Kampagne auswählen möchten, können Sie eine über "Sales Tool" erstellen.',
        Button: 'Erstellen'
      }
    },
    SelectProspectingAnalysis: {
      BoxTitle: 'Wählen Sie Portfolioabfragen, Analysen und Recherchen aus',
      Title: 'Recherche',
      Description: 'Wählen Sie eine gespeicherte Recherche',
      SelectLabel: 'Recherche auswählen',
      SelectPlaceholder: 'Klicken Sie, um eine Studie auszuwählen',
      SelectPlaceholderNoQuery: 'Keine Suche gespeichert',
      Button: 'Auswählen',
      Banner: {
        Message: 'Wenn Sie keine auswählen möchten eine Forschung, können Sie eine neue erstellen',
        Button: 'Erstellen'
      }
    },
    SelectTargetingAnalysis: {
      BoxTitle: 'Wählen Sie Portfolioabfragen, Analysen und Recherchen aus',
      Title: 'Analyse',
      Description: 'Wählen Sie eine gespeicherte Analyse',
      SelectLabel: 'Analyse auswählen',
      SelectPlaceholder: 'Klicken Sie, um eine Analyse auszuwählen',
      SelectPlaceholderNoQuery: 'Keine Analyse gespeichert',
      Button: 'Auswählen',
      Banner: {
        Message: 'Wenn Sie keine Analyse auswählen möchten, können Sie eine neue erstellen',
        Button: 'Erstellen'
      }
    },
    SelectPortfolioAnalysis: {
      BoxTitle: 'Wählen Sie Portfolioabfragen, Analysen und Recherchen aus',
      Title: 'Portfolioabfrage',
      Description: 'Wählen Sie eine gespeicherte Portfolio-Abfrage',
      SelectLabel: 'Portfolio-Abfrage auswählen',
      SelectPlaceholder: 'Klicken Sie, um eine Portfolio-Abfrage auszuwählen',
      SelectPlaceholderNoQuery: 'Keine Portfolio-Abfrage gespeichert',
      Button: 'Auswählen',
      Banner: {
        Message: 'Wenn Sie keine Portfolio-Abfrage auswählen möchten, können Sie eine neue erstellen',
        Button: 'Erstellen'
      }
    },
    UploadingPortfolio: {
      BoxTitle: 'Portfolio hochladen',
      Title: 'Portfolio hochladen',
      Description: 'Hier sehen Sie eine Vorschau der Liste',
      BannerMessage: 'Den Inhalt können Sie jederzeit ändern, indem Sie oben rechts auf das Symbol klicken'
    }
  },
  PortfolioWidget: {
    NoData: 'Daten existieren nicht.',
    NumberOfCompanies: '{{companiesCount}} Unternehmen',
    Monitored: 'Überwacht',
    Actions: 'Aktionen',
    Enrich: 'Anreichern',
    EnrichTooltip: 'Verbessern Sie die Daten dieser Unternehmen',
    Analyze: 'Analyse',
    AnalyzeTooltip: 'Visualisieren Sie die Portfoliodaten in Bezug auf die Hauptvariablen',
    Similarity: 'Similarity erzeugen',
    SimilarityTooltip: 'Wenden Sie den Ähnlichkeitsalgorithmus an, um neue Interessenten zu finden'
  },
  ProspectingWidget: {
    NoData: 'Daten existieren nicht.',
    NumberOfCompanies: '{{companiesCount}} Unternehmen',
    Monitored: 'Überwacht',
    MoreFilters: '{{filtersCount}} weiterer Filter',
    MoreFilters_plural: '{{filtersCount}} weitere Filter',
    ViewDetailsTooltip: 'Sehen Sie sich die Filter an, die auf diese Interessentenliste angewendet wurden'
  },
  TargetingWidget: {
    NoData: 'Daten existieren nicht.',
    Companies: 'Unternehmen'
  },
  CampaignWidget: {
    NoData: 'Daten existieren nicht.',
    NumberOfCompanies: '{{companiesCount}} Unternehmen'
  },
  AppointmentsWidget: {
    Title: 'Ihre Termine',
    NoAppointments: 'Sie haben heute keine Termine',
    MoreEvents: '{{eventsCount}} weiter Termin',
    MoreEvents_plural: '{{eventsCount}} weitere Termine',
    Today: 'Heute',
    Tomorrow: 'Morgen'
  },
  LatestDocuments: {
    Title: 'Neueste Dokumente',
    NoDocuments:
      'In diesem Feld sehen Sie Ihre neuesten Downloads. Ihre Dokumente befinden sich im Repository in Ihrem Profil.',
    Info: 'Die Tabelle zeigt die neuesten heruntergeladenen Dokumente',
    Table: {
      Name: 'Name',
      Type: 'Typ',
      CreationDate: 'Erstellungsdatum',
      ExpirationDate: 'Ablauf',
      DownloadExcel: 'Heruntergeladene Excel',
      Campaign: 'Kampagne',
      Report: 'Report'
    }
  },
  PortfolioQueryWidget: {
    MoreFilters: '{{filtersCount}} weiterer Filter',
    MoreFilters_plural: '{{filtersCount}} weitere Filter',
    ViewDetailsTooltip: 'Sehen Sie sich die auf dieses Portfolio angewendeten Filter an'
  }
}
