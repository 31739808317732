import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { AxiosError, AxiosResponse } from 'axios'
import { EventCategory, CallToActionType } from '../../../../../features/analytics/types'
import { Option } from '../../../../../components/Select'
import { MoveCompanyDialogData } from './types'
import { TrackingActions } from '../../../../../features/analytics/actions'
import PortfolioImage from '../../../../../assets/images/portfolio-management-small.svg'
import { SelectField } from '../../../../../components/Form/Select'
import { moveCompany, getSalestoolKeys, useGetCampaigns } from '../../../../../api/salestool'
import { Button } from '../../../../../components/Button'
import { Stack } from '../../../../../components/Stack'
import { Text } from '../../../../../components/Text'
import { useViewportSize } from '../../../../../hooks/useViewportSize'
import {
  StyledDialog,
  StyledDialogBody,
  StackCustomVariables,
  Wrapper
} from '../../../../../containers/Dialog/components'
import { required } from '../../../../../utils/validators'
import { queryClient } from '../../../../../features/queryclient'
import { ResultCode } from '../../../../../api/types'
import { WithErrorCode } from '../../../../../api/common/types'

export const StyledContentWrapper = styled.div`
  display: block;
  width: 100%;
`

type Props = {
  close: () => void
  data: MoveCompanyDialogData
}

export type MoveCompanyFormState = {
  targetPortfolioId?: string
}

export const MoveCompanyDialog = ({ close, data }: Props) => {
  const dispatch = useDispatch()
  const { isMobile } = useViewportSize()
  const { t } = useTranslation(['common', 'salestool'])

  const { control, handleSubmit, formState } = useForm<MoveCompanyFormState>({
    mode: 'onChange',
    defaultValues: {}
  })
  const { isValid } = formState

  const { data: campaigns, isLoading: campaignsLoading } = useGetCampaigns({
    isArchive: false
  })

  const {
    mutate: mutateMoveCompany,
    isIdle,
    isError,
    isSuccess,
    isLoading,
    error
  } = useMutation<AxiosResponse, AxiosError<WithErrorCode>, string>(
    targetPortfolioId => moveCompany(data.campaignCompanyId, targetPortfolioId),
    {
      onSuccess: (__, targetPortfolioId) => {
        queryClient.resetQueries(getSalestoolKeys.AllBasedOnCampaignId(data.campaignId))
        if (targetPortfolioId) queryClient.resetQueries(getSalestoolKeys.AllBasedOnCampaignId(targetPortfolioId))

        dispatch(TrackingActions.trackCTAClick(EventCategory.SalesTool, CallToActionType.MoveCompany))
      }
    }
  )

  const submit = handleSubmit((form: MoveCompanyFormState) => {
    if (isError || isSuccess) {
      return close()
    }
    if (form.targetPortfolioId) return mutateMoveCompany(form.targetPortfolioId)

    return close()
  })

  const getErrorText = () =>
    error?.response?.data?.errorCode?.code === ResultCode.IncompatibleDestEntity
      ? t('salestool:AssignCampaign.Dialog.MoveCompany.ErrorIncompatibleDestEntity')
      : t('salestool:AssignCampaign.Dialog.MoveCompany.Error')

  const okButtonText = isSuccess || isIdle || isLoading ? t('common:Cancel') : t('common:Ok')

  const dialogFooter = isIdle && (
    <Stack center orientation={isMobile ? 'vertical' : 'horizontal'} gap="1.2em">
      <StackCustomVariables isMobile>
        <Text size="M" type="primary" bold block>
          {t('salestool:AssignCampaign.Dialog.MoveCompany.Footer')}
        </Text>
      </StackCustomVariables>
      <Wrapper isMobile>
        <Button onClick={close}>{okButtonText}</Button>
        <Button type="primary" disabled={!isValid} onClick={submit}>
          {t('common:Save')}
        </Button>
      </Wrapper>
    </Stack>
  )

  return (
    <StyledDialog
      loading={campaignsLoading || isLoading}
      visible
      title={t('salestool:AssignCampaign.Dialog.MoveCompany.Title')}
      footer={dialogFooter}
      okText={t('common:Ok')}
      onOk={close}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
    >
      <StyledDialogBody>
        <StyledContentWrapper>
          {isIdle && (
            <>
              <p>
                <b>{t('salestool:AssignCampaign.Dialog.MoveCompany.Text')}</b>
              </p>
              <p>{t('salestool:AssignCampaign.Dialog.MoveCompany.SelectLabel')}</p>
              <SelectField
                name="targetPortfolioId"
                control={control}
                formState={formState}
                showSearch
                validators={{ required }}
                optionFilterProp="title"
                placeholder={t('common:Select')}
                loading={campaignsLoading}
              >
                {_.map(_.reject(campaigns?.campaigns, { id: data.campaignId }), campaignOption => (
                  <Option key={campaignOption.id} value={campaignOption.id} title={campaignOption.campaignName}>
                    {campaignOption.campaignName}
                  </Option>
                ))}
              </SelectField>
            </>
          )}
          {isSuccess && <p>{t('salestool:AssignCampaign.Dialog.MoveCompany.Success')}</p>}
          {isError && <p>{getErrorText()}</p>}
        </StyledContentWrapper>
        <img src={PortfolioImage} alt="portfolio" />
      </StyledDialogBody>
    </StyledDialog>
  )
}
