import axios from 'axios'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { cacheAllTheTime } from '../../features/queryclient'
import { chartHasData } from '../../features/targeting/helpers'
import { getAggregationField, getStatusCompanyFilter } from '../../features/targeting/selectors'
import { updateChartsStatusAtom } from '../../pages/Targeting/atoms'
import { getChartData, getPortfolioConfiguration, getStatistics, getTargetingLayout } from './api'
import { ChartDataRequest, RequestCommon, StatisticsRequest } from './types'

export const getTargetingKeys = {
  All: () => [{ level1: 'targeting' }] as const,
  GetAllChartData: () => [{ ...getTargetingKeys.All()[0], level2: 'chartsData' }] as const,
  GetChartByData: (data: ChartDataRequest) =>
    [{ ...getTargetingKeys.GetAllChartData()[0], level2: 'chartByData', data }] as const,
  GetChartByCode: (chartCode: string | undefined) =>
    [{ ...getTargetingKeys.GetAllChartData()[0], ...{ data: { chartCode } } }] as const,
  GetStatisticsByData: (request: StatisticsRequest) =>
    [{ ...getTargetingKeys.All()[0], level2: 'statistics', request }] as const,
  GetChartLayoutConfig: () => [{ ...getTargetingKeys.All()[0], level2: 'chartLayoutConfig' }] as const,
  GetPortfolioConfiguration: (selectedPortfolios: string[]) =>
    [{ ...getTargetingKeys.All()[0], level2: 'getPortfolioConfiguration', selectedPortfolios }] as const
}

export const useGetChartData = (
  chartCode: string | undefined,
  isAmountChartWithNoAmount: boolean,
  requestCommon: RequestCommon
) => {
  const aggregationField = useSelector(getAggregationField)
  const tabularFilter = useSelector(getStatusCompanyFilter)

  const [, setChartsStatus] = useAtom(updateChartsStatusAtom)

  const cancelTokenSource = axios.CancelToken.source()

  const request = {
    chartCode,
    aggregationField,
    statusCompanyFilter: tabularFilter,
    ...requestCommon
  }

  const {
    isLoading,
    isFetching,
    data: chartData,
    isError
  } = useQuery(
    getTargetingKeys.GetChartByData(request),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.data.chartCode) {
        setChartsStatus({ chartCode: queryKeys.data.chartCode, loaded: false, hasData: false })
      }

      return getChartData(queryKeys.data, cancelTokenSource)
    },
    {
      enabled: !isAmountChartWithNoAmount && !!chartCode,
      onSettled: (data, error) => {
        if (chartCode) setChartsStatus({ chartCode, loaded: true, hasData: error ? false : chartHasData(data) })
      },
      ...cacheAllTheTime
    }
  )

  const chartInfo = {
    hasData: chartHasData(chartData),
    title: chartData?.data?.chartLabel,
    tooltip: chartData?.data?.chartTooltip,
    hideNotDefinedInChart: !!chartData?.metadata?.settings?.hideNotDefinedInChart
  }

  return { isLoading: isLoading && isFetching, isError, chartData, chartInfo }
}

export const useGetStatistics = (commonRequest: RequestCommon) =>
  useQuery(
    getTargetingKeys.GetStatisticsByData(commonRequest),
    ({ queryKey: [queryKeys] }) => getStatistics(queryKeys.request),
    {
      enabled: !_.isEmpty(commonRequest),
      ...cacheAllTheTime
    }
  )

export const useGetChartLayoutConfig = () => {
  const { data } = useQuery(getTargetingKeys.GetChartLayoutConfig(), () => getTargetingLayout())
  return data?.data?.charts ?? []
}

export const useGetPortfolioConfiguration = (selectedPortfolios: (string | undefined)[]) => {
  const clearedPortfolioList = _.compact(selectedPortfolios)

  return useQuery(
    getTargetingKeys.GetPortfolioConfiguration(clearedPortfolioList),
    ({ queryKey: [queryKeys] }) => {
      if (queryKeys.selectedPortfolios) {
        return getPortfolioConfiguration({
          selectedPortfolios: queryKeys.selectedPortfolios
        })
      }
      return undefined
    },
    {
      enabled: !_.isEmpty(clearedPortfolioList),
      ...cacheAllTheTime
    }
  )
}
