import axios from 'axios'
import i18nInstance, { i18n } from 'i18next'
import _ from 'lodash'
import GA from 'react-ga'
import { all, call, delay, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { queryClient } from '../queryclient'
import { fetchInstanceConfig, fetchSession } from '../../api/config'
import { UserResponse } from '../../api/types'
import { UserType, UserTypeKey } from '../../authorization'
import { ADD_PORTFOLIO } from '../../routes'
import { PromisedType } from '../../types'
import { SignalR } from '../../utils/signalR'
import { Dictionary } from '../../utils/types'
import { CurrencyActions } from '../currencies'
import { NotificationActions } from '../notifications/actions'
import { User } from '../sharedTypes'
import { CHANGE_LANGUAGE, UserActions } from '../user/actions'
import {
  getSelectedCurrency,
  getSelectedLanguage,
  hasPortfolioActions,
  hasPortfolioManagementClaim,
  isLoggedIn
} from '../user/selectors'
import { ConfigActions, HANDLE_USER_CONFIG, ON_APP_CONFIG } from './actions'
import { getBaseUrl, getLanguageMap } from './selectors'
import { InstanceConfigDto, LoadStatus, LocalStorageConfigState } from './types'
import { generateRefreshUrl } from '../../utils/helpers'
import { Matomo } from '../analytics/helpers'
import { getParsedValueFromLocalStorage, saveStringifiedToLocalStorage } from '../storage/utils'
import { LocalStorageKeys } from '../storage/types'

export const mapLanguage = (language: string, LanguageMap: Dictionary<string>) => LanguageMap[language] || language

export function* startSignalR() {
  const baseUrl: ReturnType<typeof getBaseUrl> = yield select(getBaseUrl)
  if (!baseUrl) return
  yield call(SignalR.start, baseUrl)
  yield put(ConfigActions.signalRStarted())
}

export const setBaseURL = (baseURL: string) => {
  axios.defaults.baseURL = baseURL
}

export const setLanguage = (i18nInst: i18n, lng: string) => {
  i18nInst.changeLanguage(lng)
  axios.defaults.headers['Accept-Language'] = lng
}
export const setGoogleAnalytics = (id?: string) => {
  if (!id) return

  GA.initialize(id)
  GA.plugin.require('ecommerce')
}

export function* onAppConfig(action: ReturnType<typeof ConfigActions.setAppConfig>) {
  const { url, googleAnalyticsId, matomo, default: def } = action.payload.config

  yield call(setGoogleAnalytics, googleAnalyticsId)
  yield call(setBaseURL, url ?? '')
  yield call(Matomo.setMatomo, matomo?.urlBase, matomo?.siteId)

  const lang: ReturnType<typeof getSelectedLanguage> = yield select(getSelectedLanguage)
  yield call(setLanguage, i18nInstance, lang ?? def?.defaultLanguage ?? 'en-GB')
  yield put(ConfigActions.setAppConfig(action.payload.config))
}

const MAX_RELOAD_ATTEMPTS = 3

export function* handleLanguageChange(action: ReturnType<typeof UserActions.changeLanguage>) {
  const { lang, reload, location, navigate } = action.payload
  const isUserLogged: ReturnType<typeof isLoggedIn> = yield select(isLoggedIn)

  yield call(setLanguage, i18nInstance, lang)
  yield put(UserActions.setLanguage(lang))
  if (isUserLogged) yield put(CurrencyActions.fetchCurrencies())

  setTimeout(() => queryClient.resetQueries([]), 1)

  if (reload) {
    const { search, state, pathname } = location
    navigate(
      {
        search,
        pathname: generateRefreshUrl(pathname)
      },
      { replace: true, state }
    )
  }
}

const mapUser = (user: UserResponse): User => {
  return {
    ..._.omit(user, 'organization', 'userType'),
    userType: UserType[user.userType as UserTypeKey]
  }
}

export function* shouldRedirectToWizard(config: Record<string, LocalStorageConfigState>, userId: string) {
  const hasPortfolioManagement: boolean = yield select(hasPortfolioManagementClaim)
  const showPortfolioWizard = _.get(config, [userId, 'showPortfolioWizard'])
  return hasPortfolioManagement && showPortfolioWizard === undefined
}

export function* handleUserConfig(action: ReturnType<typeof ConfigActions.handleUserConfig>) {
  const { location, navigate } = action.payload
  for (let i = 0; i < MAX_RELOAD_ATTEMPTS; i += 1) {
    try {
      yield put(ConfigActions.setUserConfig({ userConfigLoadStatus: LoadStatus.LOADING }))
      let res: PromisedType<typeof fetchSession>
      try {
        res = yield call(fetchSession)
      } catch (error: any) {
        if (error.response?.status === 404) {
          yield put(UserActions.logout(false))
          return
        }
        throw error
      }

      const { language, hasPortfolio, hasUnreadNotification, instanceCode, user } = res.data
      const { currency } = user

      const selectedLanguage: ReturnType<typeof getSelectedLanguage> = yield select(getSelectedLanguage)
      const languageMap: ReturnType<typeof getLanguageMap> = yield select(getLanguageMap)
      yield call(
        handleLanguageChange,
        UserActions.changeLanguage(mapLanguage(selectedLanguage || language, languageMap), false, location, navigate)
      )

      const selectedCurrency: ReturnType<typeof getSelectedCurrency> = yield select(getSelectedCurrency)
      yield put(UserActions.changeCurrency(selectedCurrency ?? currency, false, location, navigate))
      yield put(
        UserActions.setUserData({
          hasPortfolio: res.data.hasPortfolio,
          user: mapUser(res.data.user),
          organization: res.data.user.organization,
          branch: res.data.branch,
          instanceCode,
          originatorUser: _.isUndefined(res.data.originatorUser) ? undefined : mapUser(res.data.originatorUser),
          subscription: {
            ..._.omit(res.data.subscription, 'subscriptionDetails'),
            details: res.data.subscription.subscriptionDetails[0]
          },
          extensionsManager: res.data.extensionsManager
        })
      )
      let instance: InstanceConfigDto = {}
      try {
        const userConfigResp: PromisedType<typeof fetchInstanceConfig> = yield call(fetchInstanceConfig, instanceCode)
        instance = userConfigResp.data

        // Notify other sagas
      } catch (error) {
        // we do not have to fail, because we have default configuration loaded already
        // eslint-disable-next-line no-console
        console.log(`Application config for instance ${res.data.user.organization.countryCode} does not exists`)
      } finally {
        yield put(
          ConfigActions.setUserConfig({
            userConfigLoadStatus: LoadStatus.LOADED,
            instance
          })
        )
      }

      yield put(UserActions.setAvailableRedirects(res.data.availableRedirects))
      yield put(UserActions.setBlacklistedFeatures(res.data.blacklistedFeatures))
      yield put(UserActions.setHasPortfolioExclusions(res.data.hasPortfolioExclusions))
      yield put(UserActions.setCustCompVisibility(res.data.customerCompanyVisibilityAccountEnabled))
      yield put(UserActions.setCustCompConfigurationApplied(res.data.customerCompanyConfigurationApplied))
      yield put(NotificationActions.setHasNewNotifications(hasUnreadNotification))

      const portfolioActionsAllowed: ReturnType<typeof hasPortfolioActions> = yield select(hasPortfolioActions)

      if (
        !hasPortfolio &&
        portfolioActionsAllowed &&
        !_.includes(res.data.blacklistedFeatures, 'portfolio.uploadPortfolio')
      ) {
        const config = getParsedValueFromLocalStorage<Record<string, LocalStorageConfigState>>(
          LocalStorageKeys.CONFIG,
          {}
        )
        const redirectToWizard: boolean = yield call(shouldRedirectToWizard, config, user.id)
        if (redirectToWizard) {
          // fixes race condition with LoginForm/utils.onSuccess callback and redirect from Login
          yield new Promise<void>(resolve =>
            setTimeout(() => {
              navigate({ pathname: ADD_PORTFOLIO }, { replace: true, state: { firstTime: true } })
              resolve()
            }, 1)
          )

          saveStringifiedToLocalStorage(LocalStorageKeys.CONFIG, {
            ...config,
            [user.id]: { ..._.get(config, user.id), showPortfolioWizard: false }
          })
        }
      }

      yield call(startSignalR)
      yield put(UserActions.displayNewReleaseNotes({ filter: { notSeenOnly: true } }))
      return
    } catch (e: any) {
      if (i < MAX_RELOAD_ATTEMPTS - 1) {
        yield delay((i + 1) * 2000)
      } else {
        yield put(
          ConfigActions.setUserConfig({
            userConfigLoadStatus: e?.response?.status === 401 ? LoadStatus.NOT_LOADED : LoadStatus.FAILED
          })
        )
        throw e
      }
    }
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(ON_APP_CONFIG, onAppConfig),
    // takeLeading, because when handleUserConfig is using SignalR, and this need to be properly finished.
    takeLeading(HANDLE_USER_CONFIG, handleUserConfig),
    takeEvery(CHANGE_LANGUAGE, handleLanguageChange)
  ])
}
