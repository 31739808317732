import React from 'react'
import { useTranslation } from 'react-i18next'
import SuccessImage from '../../../../assets/images/ok.svg'
import { Dialog } from '../../../../components/Dialog'
import { Text } from '../../../../components/Text'
import { GenericContent } from '../styled'
import { disableTransitions } from '../helpers'

type Props = {
  queryName: string
  addToDashboard: boolean
  close: () => void
}

export const SaveQueryConfirmedDialog = ({ queryName, addToDashboard, close }: Props) => {
  const { t } = useTranslation(['targeting', 'common'])
  return (
    <Dialog
      title={t('SaveQuery.DialogTitle')}
      width={900}
      visible
      loading={false}
      footer=""
      onCancel={close}
      okText={t('common:Ok')}
      onOk={close}
      {...disableTransitions(true)}
    >
      <GenericContent>
        <div className="text">
          <Text size="M" type="primary" bold block>
            &quot;{queryName && `${queryName}`}&quot;{' '}
            {t(addToDashboard ? 'SaveQuery.SavedQueryAndDashboard' : 'SaveQuery.SavedQuery')}
          </Text>
          <Text size="M" block>
            {t('SaveQuery.SavedQueryDescription')}
          </Text>
        </div>
        <img src={SuccessImage} alt="success" />
      </GenericContent>
    </Dialog>
  )
}
