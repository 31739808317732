import _ from 'lodash'
import moment from 'moment'
import { CompareFn } from 'antd3/lib/table'

export function compareValues<T>(left: T, right: T): number {
  if (left === right) return 0
  return _.gt(left, right) ? 1 : -1
}

export function compareDates(left: string, right: string): number {
  return moment(left).diff(moment(right))
}

export function compareStrings(left: string, right: string): number {
  return left < right ? -1 : 1
}

export function compareStringsLocale(left: string, right: string): number {
  return left.localeCompare(right)
}

export function createPropComparator<T extends object>() {
  return function f<K extends keyof T>(propName: K, compareFn: CompareFn<T[K]> = compareValues): CompareFn<T> {
    return function compare(left: T, right: T): number {
      const leftValue = left[propName]
      const rightValue = right[propName]
      return compareFn(leftValue, rightValue)
    }
  }
}
