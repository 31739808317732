import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Box } from '../Box'
import { IconButton, IconButtonSize, iconButtonSizes } from '../Button/IconButton'
import { lg } from '../../utils/helpers'
import { AnimationOverflow, containerWidthMixin, WIDE_WIDTH, NARROW_WIDTH } from './common'
import { ReactComponent as SliderIcon } from '../../assets/icons/slider-white.svg'
import { ReactComponent as ChevronIcon } from '../../assets/icons/icon-s-chevron-left-white.svg'
import { StyledVerticalScrollWrapper } from '../StyledScrollWrapper'

const SCROLLBAR_SPACE = 15

const DrawerComponent = styled.div<{ open: boolean }>`
  position: absolute;
  flex: 0 0 auto;
  margin-right: 20px;
  z-index: 900;
  transition: width ${({ theme }) => theme.animation.duration};
  width: ${({ open }) => (open ? 'auto' : '0 !important')};
  ${containerWidthMixin}
`

const DrawerButton = styled(IconButton)<{ open: boolean; offset: number }>`
  && {
    display: block;
    position: absolute;
    right: ${({ open }) => (open ? '-20px' : '-40px')};
    top: ${({ offset }) => offset}px;
    z-index: 440001;
    margin-left: auto;
  }
`

const DrawerContentWrapper = styled(Box)<{ open: boolean }>`
  && {
    position: relative;
    && {
      left: ${({ open }) => (open ? 0 : `-${NARROW_WIDTH}`)};
    }
    ${({ open }) => lg`
      && {
        left: ${open ? 0 : `-${WIDE_WIDTH}`};
      }
    `}
    transition: all ${({ theme }) => theme.animation.duration};
  }
`

const ScrollbarContentWrapper = styled.div<{ maxHeight: number }>`
  max-height: ${({ maxHeight }) => maxHeight}px;
`

export type DrawerProps = {
  className?: string
  style?: React.CSSProperties
  buttonOffset: number
  buttonSize?: IconButtonSize
  open: boolean
  height: number
  extra?: React.ReactNode
  drawerContent: React.ReactNode
  onToggle: (isOpen: boolean) => void
}

export const Drawer: React.FC<DrawerProps> = props => {
  const [isMouseOver, setMouseOver] = useState(false)

  const { className, style, open, buttonOffset, buttonSize, height, extra, drawerContent, onToggle } = props
  const { btnSize: buttonHeight } = iconButtonSizes(buttonSize)

  return (
    <DrawerComponent className={className} style={style} open={open}>
      <DrawerButton type="primary" size={buttonSize} offset={buttonOffset} open={open} onClick={() => onToggle(!open)}>
        {open ? <ChevronIcon /> : <SliderIcon />}
      </DrawerButton>
      <AnimationOverflow open={open} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
        <DrawerContentWrapper open={open} padding={0}>
          <StyledVerticalScrollWrapper
            customTrackProps={{
              trackWidth: 8,
              trackSpacing: 7,
              trackMarginStart: buttonOffset + buttonHeight + SCROLLBAR_SPACE,
              hideForDesktop: !isMouseOver,
              maxThumbSizePercentage: 0.2,
              thumbColor: theme => theme.colors.dustyBlue,
              trackColor: theme => theme.colors.almostWhite
            }}
          >
            <ScrollbarContentWrapper maxHeight={height}>
              {extra}
              {drawerContent}
            </ScrollbarContentWrapper>
          </StyledVerticalScrollWrapper>
        </DrawerContentWrapper>
      </AnimationOverflow>
    </DrawerComponent>
  )
}
