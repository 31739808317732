import React from 'react'
import { WithT } from 'i18next'
import { Location } from 'react-router-dom'
import { Notification } from '../../../features/notifications/types'
import { notificationActionUrl } from '../../../utils/notifications'
import { NotificationEventDefaultLayout } from './NotificationEventDefaultLayout'
import { NotificationTextWithoutFormatter } from '../NotificationText'
import { NotificationSection } from '../NotificationSection'
import { OpenButton } from './OpenButton'
import { FormatNumberFunction } from '../../../utils/types'

const TEXT_COLOR = 'ghost'

type Props = {
  notificationPopupKey: string
  notification: Notification
  navigationCallback: (to: Partial<Location>) => void
  formatNumber: FormatNumberFunction
  fallbackTheme?: any
  notificationTextSuffix?: string
} & WithT

export const NotificationEventDefault = ({
  notification,
  notificationPopupKey,
  navigationCallback,
  fallbackTheme,
  notificationTextSuffix,
  formatNumber,
  t
}: Props) => {
  const actionUrl = notificationActionUrl(notification)
  return (
    <NotificationEventDefaultLayout
      fallbackTheme={fallbackTheme}
      notificationPopupKey={notificationPopupKey}
      message={
        <>
          <NotificationSection
            t={t}
            fallbackTheme={fallbackTheme}
            notification={notification}
            type={TEXT_COLOR}
            bold
            block
          />
          <NotificationTextWithoutFormatter
            t={t}
            fallbackTheme={fallbackTheme}
            type={TEXT_COLOR}
            formatNumber={formatNumber}
            notification={notification}
            notificationTextSuffix={notificationTextSuffix}
            block
          />
        </>
      }
      button={
        actionUrl && (
          <OpenButton fallbackTheme={fallbackTheme} navigationCallback={() => navigationCallback(actionUrl)} />
        )
      }
    />
  )
}
