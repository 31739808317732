import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ProspectingImage from '../../../assets/images/prospecting-analysis.svg'
import { useBranding } from '../../../hooks/useBranding'
import { canSeeSalesTool, getCountryCode } from '../../../features/config/selectors'
import { getVisibilityCountries } from '../../../features/user/selectors'
import { getSearchByCompaniesItems } from '../../../features/search/utils'
import { Text } from '../../../components/Text'
import { ContainerItem, StyledDialog, StyledDialogBody } from '../components'
import { SearchInformationContent } from './components'

type Props = {
  close?: () => void
}

export const SearchInformationDialog = ({ close }: Props) => {
  const { t } = useTranslation(['companyReport', 'search'])
  const { allowedModules } = useBranding()

  const countryCode = useSelector(getCountryCode) ?? 'ita'
  const visibilityCountries = useSelector(getVisibilityCountries)
  const hasAccessSalesTool = useSelector(canSeeSalesTool)

  const canSeeCampaigns = hasAccessSalesTool && allowedModules.SalesTool

  // Only in case of swiss instance we should display info for each visible country
  const croppedVisibilityCountries = countryCode === 'che' ? visibilityCountries : [visibilityCountries[0]]

  return (
    <StyledDialog
      visible
      title={t('search:SearchInformationDialog.Title')}
      loading={false}
      onCancel={close}
      width="500px"
    >
      <StyledDialogBody>
        <ContainerItem>
          <div>
            <p>
              <Text
                size="M"
                primary
                bold
                text={t(`search:SearchInformationDialog.${canSeeCampaigns ? 'SubTitleWithCampaigns' : 'SubTitle'}`)}
              />
            </p>
            <SearchInformationContent>
              {_.map(croppedVisibilityCountries, visibilityCountry => (
                <div key={visibilityCountry}>
                  {croppedVisibilityCountries.length > 1 && (
                    <Text
                      size="M"
                      primary
                      bold
                      block
                      text={t(`search:SearchInformationDialog.Countries.${_.toLower(visibilityCountry)}`)}
                    />
                  )}
                  <Trans i18nKey="search:SearchInformationDialog.CompaniesBy" />
                  <ul>
                    {_.map(
                      getSearchByCompaniesItems(t, _.toLower(countryCode), _.toLower(visibilityCountry)),
                      (searchByItem, index) => (
                        <li key={index}>{searchByItem}</li>
                      )
                    )}
                  </ul>

                  <p>
                    <Trans
                      i18nKey={`search:SearchInformationDialog.${
                        canSeeCampaigns ? 'PortfoliosCampaignsHelpText' : 'PortfoliosHelpText'
                      }`}
                    />
                  </p>
                </div>
              ))}
            </SearchInformationContent>
          </div>
        </ContainerItem>
        <div>
          <img src={ProspectingImage} alt="prospecting-search" />
        </div>
      </StyledDialogBody>
    </StyledDialog>
  )
}
