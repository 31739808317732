import React from 'react'
import { To } from 'react-router-dom'
import { Title, TitleProps } from './Title'
import { Link } from './Link'
import { titleLevelProps } from './common'

type Props = {
  to?: To
  state?: any
  stretch?: boolean
} & TitleProps

export const LinkTitle = ({ to, state, stretch, level, ellipsis, ...textProps }: Props) => {
  const { size } = titleLevelProps(level)
  return (
    <Link to={to} state={state} stretch={stretch} iconSize={size} ellipsis={ellipsis}>
      <Title {...textProps} level={level} ellipsis={ellipsis} />
    </Link>
  )
}
