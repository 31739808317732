import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'
import { FilterReducerPath, SemanticSearch } from '../filters/types'
import { CallToActionType, EventCategory, GroupActionType } from './types'

export const TRACK_GROUP_ACTION = 'analytics/TRACK_GROUP_ACTION'
export const TRACK_CTA_CLICK = 'analytics/TRACK_CTA_CLICK'
export const SEARCH_PROSPECTING_COMPANIES = 'analytics/SEARCH_PROSPECTING_COMPANIES'
export const TRACK_PAGE_VIEW = 'analytics/TRACK_PAGE_VIEW'
export const TRACK_SEMANTIC_SEARCH = 'analytics/TRACK_SEMANTIC_SEARCH'

export const TrackingActions = {
  trackGroupAction: (source: EventCategory, type: GroupActionType) =>
    createAction(TRACK_GROUP_ACTION, { source, type }),
  trackCTAClick: (source: EventCategory, type: CallToActionType) => createAction(TRACK_CTA_CLICK, { source, type }),
  trackBasicSearch: (freeText: string) => createAction(SEARCH_PROSPECTING_COMPANIES, { freeText }),
  trackPageView: (path: string) => createAction(TRACK_PAGE_VIEW, { path }),
  trackSemanticSearch: (reducer: FilterReducerPath, semantic: SemanticSearch) =>
    createAction(TRACK_SEMANTIC_SEARCH, { reducer, semantic })
}

export type TrackingActions = ActionsUnion<typeof TrackingActions>
