import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useGetFilterQueriesAsDocument } from '../../api/query'
import { Text } from '../../components/Text'
import { Dialog } from '../../components/Dialog'
import { RadioGroup } from '../../components/Radio'
import { Select, Option } from '../../components/Select'
import { QueryType } from '../../types'
import { Query, queryTypes } from '../../features/query/types'
import { QueryRadio } from './components'
import { getDefaultQuerySelection, getSelectedQueries } from './utils'
import { mapQueries } from '../../features/mappers'
import {
  portfolioQueriesEnabled,
  prospectingQueriesEnabled,
  targetingQueriesEnabled
} from '../../features/user/selectors'
import { FETCH_QUERIES_TOP } from '../../features/query/constants'

type Props = {
  submit: (query?: Query, queryType?: QueryType) => void
  defaultQueryType?: QueryType
  close: () => void
}

const Content = styled.div`
  display: flex;

  .text {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const LoadQueryDialog = ({ submit, close, defaultQueryType }: Props) => {
  const { t } = useTranslation('common')
  const canPortfolioLoadQueriesInner = useSelector(portfolioQueriesEnabled)
  const canTargetingLoadQueriesInner = useSelector(targetingQueriesEnabled)
  const canProspectingLoadQueriesInner = useSelector(prospectingQueriesEnabled)

  const [selectedQuery, setSelectedQuery] = useState<Query>()
  const { data: allQueries, isLoading } = useGetFilterQueriesAsDocument({
    documentTypes: queryTypes,
    top: FETCH_QUERIES_TOP
  })
  const mappedQueries = mapQueries(allQueries)

  const [selectedType, setSelectedType] = useState<QueryType | undefined>(() =>
    getDefaultQuerySelection(mappedQueries, defaultQueryType)
  )

  useEffect(() => {
    setSelectedType(getDefaultQuerySelection(mappedQueries, defaultQueryType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultQueryType])

  const selectedQueries = getSelectedQueries(mappedQueries, selectedType)

  return (
    <Dialog
      title={t('queries.loadQuery.dialogTitle')}
      width={900}
      visible
      loading={isLoading}
      footer=""
      cancelText={t('Cancel')}
      onCancel={close}
      okText={t('queries.loadQuery.OkBtn')}
      okButtonProps={{ disabled: !selectedQuery }}
      onOk={() => submit(selectedQuery, selectedType)}
    >
      <Content>
        <div className="text">
          <Text size={24} bold block center>
            {t('queries.loadQuery.title')}
          </Text>
          <Text size="M" block center margin={{ bottom: '2em' }}>
            {t('queries.loadQuery.description')}
          </Text>
          <RadioGroup value={selectedType} onChange={evt => setSelectedType(evt.target.value as QueryType)}>
            {canPortfolioLoadQueriesInner && (
              <QueryRadio value={QueryType.PortfolioQueries} options={mappedQueries ?? []}>
                {t('queries.loadQuery.portfolioRadio')}
              </QueryRadio>
            )}
            {canTargetingLoadQueriesInner && (
              <QueryRadio value={QueryType.TargetingQueries} options={mappedQueries ?? []}>
                {t('queries.loadQuery.targetingRadio')}
              </QueryRadio>
            )}
            {canProspectingLoadQueriesInner && (
              <QueryRadio value={QueryType.ProspectingQueries} options={mappedQueries ?? []}>
                {t('queries.loadQuery.prospectingRadio')}
              </QueryRadio>
            )}
          </RadioGroup>
          <Text type="primary" size="M" bold block margin={{ top: '1em', bottom: '8px' }}>
            {t('queries.loadQuery.selectTitle')}
          </Text>
          <Select
            placeholder={t('Select')}
            disabled={isLoading}
            showSearch
            optionFilterProp="title"
            value={selectedQuery?.id}
            onChange={e => setSelectedQuery(_.find(mappedQueries, ['id', e]))}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          >
            {_.map(selectedQueries, x => (
              <Option key={x.id} title={x.name} value={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
      </Content>
    </Dialog>
  )
}
