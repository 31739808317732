import _ from 'lodash'
import { createSelector, createStructuredSelector } from 'reselect'
import { Claim, ClaimKey, claimKeys, EMPTY_CLAIM } from '../../authorization'
import { PolicyDocument } from '../../types'
import { RootState } from '../types'
import {
  getCurrentInstance,
  hasOperationsClaim,
  hasPortfolioManagementClaim,
  hasProspectingClaim,
  hasSalesToolClaim,
  hasTargetingClaim,
  hasPnrrClaim,
  isLoggedIn,
  getVisibilityCountries
} from '../user/selectors'
import {
  AppModule,
  AvailablePolicyDocuments,
  CanUseAppModules,
  ConfigurationAuthorization,
  ConfigurationLimits,
  ConfigurationMap,
  DefaultInstanceConfig,
  LoadStatus
} from './types'
import { defaultInstanceConfig } from './utils'

export const selectState = (state: RootState) => state.config || {}

export const getAppConfigStatus = createSelector(selectState, s => s.appConfigLoadStatus)
export const showReactQueryDevTool = createSelector(selectState, s => s.showReactQueryDevTool)
export const getUserConfigStatus = createSelector(selectState, s => s.userConfigLoadStatus)
export const getRecaptchaKey = createSelector(selectState, s => s.recaptcha)
export const getGoogleMapsKey = createSelector(selectState, s => s.mapsKey)
export const getProductFruitsKey = createSelector(selectState, s => s.productFruitsKey)
export const getSamlAuthConfig = createSelector(selectState, s => s.samlAuth)

export const getInstanceConfig = createSelector(selectState, getCurrentInstance, state => state.instance)
export const getAppConfig = createSelector(selectState, getCurrentInstance, state => state.default)

export const getBaseUrl = createSelector(selectState, state => state.url ?? '')

const createFallbackSelector = <T extends keyof DefaultInstanceConfig>(field: T) =>
  createSelector(
    getInstanceConfig,
    getAppConfig,
    (s, d) => (_.get(s, field) ?? _.get(d, field) ?? _.get(defaultInstanceConfig, field)) as DefaultInstanceConfig[T]
  )

export const getInstanceCode = createFallbackSelector('instanceCode')
export const getDecimalPrecision = createFallbackSelector('decimalPrecision')
export const getMapConfig = createFallbackSelector('map')
export const getMaxMapRadius = createFallbackSelector('mapFilterMaxRadius')
export const getCompanyStatus = createFallbackSelector('companyStatus')
export const canRedirectOnError = createFallbackSelector('ignoreErrorRedirect')
export const getSalesChartColors = createFallbackSelector('salesChartColors')
export const getLanguageMap = createFallbackSelector('languageMap')
export const getIsLocalUnitsFeatureEnabled = createFallbackSelector('localUnitsFeatureEnabled')
export const getIsSynonymsFeatureEnabled = createFallbackSelector('synonymsFeatureEnabled')
export const getLocalUnitsMaxCompanies = createFallbackSelector('localUnitsMaxCompanies')
export const getMonitoredPortfolioNotificationTimestampOffsetInMinutes = createFallbackSelector(
  'monitoredPortfolioNotificationTimestampOffsetInMinutes'
)
const getAllowedModulesSelector = createFallbackSelector('allowedModules')
const getPolicyDocumentsSelector = createFallbackSelector('policyDocuments')

export const getMapConfigBasedOnCountryVisibility = createSelector(
  getMapConfig,
  getVisibilityCountries,
  (mapConfig, visibilityCountries): ConfigurationMap => {
    if (visibilityCountries.length === 1 && mapConfig[_.toLower(visibilityCountries[0])]) {
      return mapConfig[_.toLower(visibilityCountries[0])]
    }

    return mapConfig.default ?? defaultInstanceConfig.map.default
  }
)

const getAllowedModules = createSelector(getAllowedModulesSelector, (allowedModules): CanUseAppModules => {
  return _.reduce(
    allowedModules,
    (acc: CanUseAppModules, module: AppModule) => {
      const appModule: AppModule | undefined = AppModule[module]
      if (appModule) {
        acc[appModule] = true
      }
      return acc
    },
    {
      [AppModule.PortfolioManagement]: false,
      [AppModule.PortfolioManagementCompanyEdit]: false,
      [AppModule.Targeting]: false,
      [AppModule.Prospecting]: false,
      [AppModule.SalesTool]: false,
      [AppModule.API]: false,
      [AppModule.Operations]: false,
      [AppModule.PNRR]: false,
      [AppModule.Appointment]: false
    }
  )
})

const getPolicyDocuments = createSelector(getPolicyDocumentsSelector, (policyDocuments): AvailablePolicyDocuments => {
  return _.reduce(
    policyDocuments,
    (acc: AvailablePolicyDocuments, document: PolicyDocument) => {
      const doc: PolicyDocument | undefined = PolicyDocument[document]
      if (doc) {
        acc[doc] = true
      }
      return acc
    },
    {
      [PolicyDocument.ImprintPolicy]: false,
      [PolicyDocument.CookiesPolicy]: false,
      [PolicyDocument.PrivacyPolicy]: false
    }
  )
})

export const getBrandingConfig = createSelector(
  getAllowedModules,
  getPolicyDocuments,
  (allowedModules, policyDocuments) => ({
    allowedModules,
    policyDocuments
  })
)

export const getShowHiddenFeatures = createFallbackSelector('showHiddenFeatures')
export const getShowFreeSearchSuggestions = createFallbackSelector('showFreeSearchSuggestions')
export const getAvailableLanguages = createFallbackSelector('availableLanguages')
export const getCompanyName = createFallbackSelector('companyName')
export const getHideMapView = createFallbackSelector('hideMapView')
export const getHideSemanticSearch = createFallbackSelector('hideSemanticSearch')
export const getCountryCode = createFallbackSelector('countryCode')
export const getMinCountOfSameStatusCompaniesForAnalyze = createFallbackSelector(
  'minCountOfSameStatusCompaniesForAnalyze'
)
export const getMostPopularFiltersEnabled = createFallbackSelector('mostPopularFiltersEnabled')
export const getSimplifiedUploadEnabled = createFallbackSelector('simplifiedUploadEnabled')
export const getHideForgotPassword = createFallbackSelector('hideForgotPassword')
export const getMapZonesBasedOnCountryVisibilityEnabled = createFallbackSelector(
  'mapZonesBasedOnCountryVisibilityEnabled'
)

// CLAIMS Section

// in case we need to hide the Prospecting, Targeting, Salestool or Portfolio module
export const canSeeProspecting = hasProspectingClaim
export const canSeeTargeting = hasTargetingClaim
export const canSeeSalesTool = hasSalesToolClaim
export const canSeePortfolioManagement = hasPortfolioManagementClaim
export const canSeeOperations = hasOperationsClaim
export const canSeePnrr = hasPnrrClaim

const getClaimsVisibility = createStructuredSelector<RootState, Record<ClaimKey, boolean>>({
  PortfolioManagement: canSeePortfolioManagement,
  Prospecting: canSeeProspecting,
  Targeting: canSeeTargeting,
  SalesTool: canSeeSalesTool,
  Operations: canSeeOperations,
  Pnrr: canSeePnrr
})

// returns all claims that the user has and are visible, as a number with respective bits set to 1
export const getVisibleClaims = createSelector(getClaimsVisibility, claimVisibility => {
  return _.reduce(
    _.map(claimKeys, c => (claimVisibility[c] ? Claim[c].valueOf() : EMPTY_CLAIM)),
    (acc, value) => acc | value,
    EMPTY_CLAIM
  )
})
export const canLoadUserConfig = createSelector(
  getAppConfigStatus,
  getUserConfigStatus,
  isLoggedIn,
  (appConfigStatus, userConfigState, loggedIn): boolean => {
    return appConfigStatus === LoadStatus.LOADED && loggedIn && _.includes([LoadStatus.NOT_LOADED], userConfigState)
  }
)

// AUTH SECTION
const createAuthFallbackSelector = <T = number>(field: keyof ConfigurationAuthorization) => {
  return createSelector(
    getInstanceConfig,
    getAppConfig,
    (s, d) =>
      (_.get(s, ['auth', field]) ?? _.get(d, ['auth', field]) ?? _.get(defaultInstanceConfig, ['auth', field])) as T
  )
}

export const getAutomaticLogoutTime = createAuthFallbackSelector('automaticLogoutTime')
export const getTimeToCancelAutomaticLogout = createAuthFallbackSelector('timeToCancelAutomaticLogout')
export const getHasResetPassword = createAuthFallbackSelector<boolean>('hasResetPassword')
export const getResetPasswordEmail = createAuthFallbackSelector<string>('resetPasswordContactEmail')
export const getResetPasswordPhone = createAuthFallbackSelector<string>('resetPasswordContactPhone')

// LIMITS SECTION
const createLimitsFallbackSelector = <T = number>(field: keyof ConfigurationLimits) => {
  return createSelector(
    getInstanceConfig,
    getAppConfig,
    (s, d) =>
      (_.get(s, ['limits', field]) ??
        _.get(d, ['limits', field]) ??
        _.get(defaultInstanceConfig, ['limits', field])) as T
  )
}

export const getNewsPageSize = createLimitsFallbackSelector('newsPageSize')
export const getLatestDocumenstWidgetPageSize = createLimitsFallbackSelector('latestDocumentWidgetPageSize')
export const getNotificationLoadPageSize = createLimitsFallbackSelector('notificationsLoadPageSize')
export const getMaxNotesNumber = createLimitsFallbackSelector('maxNotesNumber')
export const getCampaignVisibleWaterfallChartsPageSize = createLimitsFallbackSelector(
  'campaignVisibleWaterfallChartsPageSize'
)
export const getNumberOfNotificationInDrawer = createLimitsFallbackSelector('numberOfNotificationInDrawer')
export const getGoalsPageSize = createLimitsFallbackSelector('goalsPageSize')
export const getDocumentsInPortfolioManagement = createLimitsFallbackSelector('documentsInPortfolioManagement')
export const getPortfoliosPageSize = createLimitsFallbackSelector('portfoliosPageSize')
export const getProspectingCompaniesPageSize = createLimitsFallbackSelector('prospectingCompaniesPageSize')
export const getLocalUnitsPageSize = createLimitsFallbackSelector('localUnitsPageSize')
export const getCampaignsPageSize = createLimitsFallbackSelector('campaignsPageSize')
export const getCampaignCompaniesPageSize = createLimitsFallbackSelector('campaignCompaniesPageSize')
export const getMaxNumberOfWidgets = createLimitsFallbackSelector('numberOfWidgets')
export const getConfigurationPageSize = createLimitsFallbackSelector('configurationPageSize')
