import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dialog } from '../../components/Dialog'
import { Text } from '../../components/Text'
import { getResetPasswordEmail, getResetPasswordPhone } from '../../features/config/selectors'

const Content = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
`

type EmailLinkProps = {
  email: string
  children?: React.ReactNode
}

const EmailLink: React.FunctionComponent<EmailLinkProps> = ({ email, children }) => (
  <a href={`mailto:${email}`}>
    <Text primary>{children ?? email}</Text>
  </a>
)

type Props = {
  close: () => void
}

export const ResetPasswordDialog = ({ close }: Props) => {
  const { t } = useTranslation(['common', 'login'])
  let email = useSelector(getResetPasswordEmail)
  let phone = useSelector(getResetPasswordPhone)

  if (email == null && phone == null) {
    email = t('login:ResetPasswordDialog.Email')
    phone = t('login:ResetPasswordDialog.Phone')
  }

  return (
    <Dialog
      visible
      footer
      onCancel={close}
      title={t('login:ResetPasswordDialog.Title')}
      okText={t('common:Close')}
      onOk={close}
      width={600}
      loading={false}
    >
      <Content>
        <Trans
          i18nKey="login:ResetPasswordDialog.Description"
          components={[<EmailLink key="contact-email" email={email || ''} />]}
          values={{ email, phone }}
        />
      </Content>
    </Dialog>
  )
}
