/* eslint-disable lodash/prefer-lodash-method */
import React, { useMemo } from 'react'
import _ from 'lodash'
import { generatePath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import { AutoComplete as AntAutoComplete } from 'antd'
import { ReactComponent as Clock } from '../../../assets/icons/clock-regular.svg'
import { ReactComponent as Search } from '../../../assets/icons/search.svg'
import { ReactComponent as CompanyIcon } from '../../../assets/icons/building-main.svg'
import { ReactComponent as PortfolioIcon } from '../../../assets/icons/case.svg'
import { ReactComponent as CampaignIcon } from '../../../assets/icons/up.svg'
import { Text } from '../../Text'
import { getParsedValueFromLocalStorage } from '../../../features/storage/utils'
import { LocalStorageKeys } from '../../../features/storage/types'
import { HighlightBounds, Suggestion, SuggestionsResponse, SuggestionType } from '../../../api/suggestions/types'
import { Size, Spin } from '../../Loading/Spin'
import { theme as defaultTheme } from '../../../utils/theme'
import { COMPANY_REPORT, PORTFOLIO, SALESTOOL_CAMPAIGN_DETAIL } from '../../../routes'
import { MAX_SEARCH_LENGTH, MIN_SEARCH_LENGTH } from '../../../pages/Search/constants'
import { IconWrapper, SpinWrapper } from './styled'
import { useGetSuggestions } from '../../../api/suggestions/queries'
import { getShowFreeSearchSuggestions } from '../../../features/config/selectors'
import { useViewportSize } from '../../../hooks/useViewportSize'

export const DEFAULT_SUGGESTION_SIZE = 6

const SuggestionIcon = ({ type }: { type: SuggestionType }) => {
  let Icon = null
  switch (type) {
    case 'Company':
      Icon = CompanyIcon
      break
    case 'Portfolio':
      Icon = PortfolioIcon
      break
    case 'Campaign':
      Icon = CampaignIcon
      break
    default:
      return null
  }

  return (
    <IconWrapper>
      <Icon />
    </IconWrapper>
  )
}

export const getRecentSearchOptions = (value: string | undefined) => {
  const recentResearches = getParsedValueFromLocalStorage<string[]>(LocalStorageKeys.RECENT_RESEARCHES, [])

  // Filtering based on search value
  let filteredRecentResearches: string[] = _.isEmpty(value)
    ? recentResearches
    : _.filter(
        recentResearches,
        recentResearch => recentResearch !== value && _.startsWith(_.lowerCase(recentResearch), _.lowerCase(value))
      )

  filteredRecentResearches = _.slice(filteredRecentResearches, 0, 5)
  return _.map(filteredRecentResearches, (recentResearch, index) => {
    return (
      <AntAutoComplete.Option key={index} value={recentResearch}>
        <IconWrapper>
          <Clock />
        </IconWrapper>
        <Text color="white">{recentResearch}</Text>
      </AntAutoComplete.Option>
    )
  })
}

export const shouldDisplayCurrentSearchAndSuggestions = (value: string | undefined) =>
  value !== undefined && value.length >= MIN_SEARCH_LENGTH && value.length <= MAX_SEARCH_LENGTH

const wrapperMixin = css`
  overflow: hidden;
  margin-right: 12px;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.white};
`

const ResultWrapper = styled.div<{ isTablet: boolean }>`
  ${wrapperMixin}
  width: ${({ isTablet }) => (isTablet ? '100%' : '50%')};
  max-width: ${({ isTablet }) => (isTablet ? '100%' : '50%')};
`

const InfoWrapper = styled.div<{ isTablet: boolean }>`
  ${wrapperMixin}
  width: 25%;
  max-width: 25%;
  display: ${({ isTablet }) => (isTablet ? 'none' : 'block')};
`

const SearchComponent = ({ value }: { value: string | undefined }) => {
  const { isTablet } = useViewportSize()
  const { t } = useTranslation('search')

  return (
    <>
      <IconWrapper>
        <Search />
      </IconWrapper>
      <ResultWrapper isTablet={isTablet}>
        <Text color="white">{value}</Text>
      </ResultWrapper>
      <InfoWrapper isTablet={isTablet}>
        <Text color="white">{t('Search')}</Text>
      </InfoWrapper>
      <InfoWrapper isTablet={isTablet} />
    </>
  )
}

const getCurrentSearchOption = (value: string | undefined) => {
  // Currently search option if more than 3 characters
  return shouldDisplayCurrentSearchAndSuggestions(value) ? (
    <AntAutoComplete.Option key="AS" value={value?.trimEnd()}>
      <SearchComponent value={value?.trimEnd()} />
    </AntAutoComplete.Option>
  ) : undefined
}

const getSuggestionUri = (suggestion: Suggestion) => {
  switch (suggestion.type) {
    case 'Company':
      return generatePath(COMPANY_REPORT, {
        identificationCode: suggestion.identificationCode,
        companyUnitId: suggestion.companyUnitId
      })
    case 'Portfolio':
      return generatePath(PORTFOLIO, { portfolioId: suggestion.portfolioId })
    case 'Campaign':
      return generatePath(SALESTOOL_CAMPAIGN_DETAIL, { campaignId: suggestion.campaignId })
    default:
      return undefined
  }
}

const getSuggestionKey = (suggestion: Suggestion) => {
  switch (suggestion.type) {
    case 'Company':
      return `company-${suggestion.companyUnitId}`
    case 'Portfolio':
      return `portfolio-${suggestion.portfolioId}`
    case 'Campaign':
      return `campaign-${suggestion.campaignId}`
    default:
      return undefined
  }
}

export const getUriByIdFromSuggestions = (value: string, suggestions: SuggestionsResponse | undefined) => {
  const suggestion = suggestions?.results.find(s => getSuggestionKey(s) === value)
  return suggestion ? getSuggestionUri(suggestion) : undefined
}

export const areBoundsValid = (bounds: HighlightBounds[] | undefined, text: string | undefined) => {
  if (!bounds || bounds.length === 0 || !text) {
    return false
  }

  let isValid = true
  _.forEach(bounds, bound => {
    if (
      !_.isNumber(bound.from) ||
      !_.isNumber(bound.to) ||
      bound.from < 0 ||
      bound.to > text.length ||
      bound.from > bound.to
    ) {
      isValid = false
      return false
    }
    return true
  })
  return isValid
}

export const getSuggestionWithEmphasis = (suggestion: Suggestion) => {
  const { text, bounds } = suggestion
  if (!areBoundsValid(bounds, text)) {
    return [{ text, hasEmphasis: false }]
  }

  const labelParts: { text: string; hasEmphasis: boolean }[] = []
  bounds!
    .sort((a, b) => a.from - b.from)
    .forEach((bound, index) => {
      // not emphasised parts
      if (index === 0) {
        if (bound.from !== 0) {
          labelParts.push({ text: text.slice(0, bound.from), hasEmphasis: false })
        }
      } else if (bounds![index - 1].to !== bound.from) {
        labelParts.push({ text: text.slice(bounds![index - 1].to, bound.from), hasEmphasis: false })
      }

      // actual highlight
      labelParts.push({ text: text.slice(bound.from, bound.to), hasEmphasis: true })

      if (index === bounds!.length - 1 && bound.to !== text.length) {
        // not emphasised after last bounds
        labelParts.push({ text: text.slice(bound.to, text.length), hasEmphasis: false })
      }
    })

  return labelParts
}

export const SuggestionComponent = ({ suggestion }: { suggestion: Suggestion }) => {
  const { t } = useTranslation('search')
  const { isTablet } = useViewportSize()

  const suggestionText = getSuggestionWithEmphasis(suggestion).map((labelPart, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Text key={index} bold={labelPart.hasEmphasis} whiteSpace="pre" color="white" ellipsis>
      {labelPart.text}
    </Text>
  ))
  const suggestionType = (
    <Text color="white" ellipsis>
      {t(`search:${suggestion.type}`)}
    </Text>
  )
  const destination = (
    <Text color="white" ellipsis>
      {t(`search:GoTo.${suggestion.type}`)}
    </Text>
  )

  return (
    <>
      <SuggestionIcon type={suggestion.type} />
      <ResultWrapper isTablet={isTablet}>{suggestionText}</ResultWrapper>
      <InfoWrapper isTablet={isTablet}>{suggestionType}</InfoWrapper>
      <InfoWrapper isTablet={isTablet}>{destination}</InfoWrapper>
    </>
  )
}

const useGetSuggestedOptions = (value: string | undefined) => {
  const shouldShowSuggestions = useSelector(getShowFreeSearchSuggestions)
  const { data: suggestions, isFetching } = useGetSuggestions(
    { query: value!, size: DEFAULT_SUGGESTION_SIZE },
    { enabled: shouldDisplayCurrentSearchAndSuggestions(value) && shouldShowSuggestions }
  )

  let suggestedOptions: JSX.Element[] | undefined

  if (isFetching) {
    suggestedOptions = [
      <AntAutoComplete.Option key="spinner" value={value}>
        <SpinWrapper>
          <Spin key="spinner" color={defaultTheme.colors.white} size={Size.Small} />
        </SpinWrapper>
      </AntAutoComplete.Option>
    ]
  } else {
    suggestedOptions = suggestions
      ? _.map(suggestions?.results, (suggestion, index) => {
          const prevSuggestions = suggestions?.results.slice(0, index)
          const prevSuggestionsCount = _.countBy(prevSuggestions, s => s.text)[suggestion.text] || 0
          // add extra space for current suggestion
          const whiteSpaceCount = prevSuggestionsCount + 1
          // the key and value are used for the backfill feature
          // however it must be unique so append a whitespace to each non unique suggestion result
          const uniqueWhitespace = _.repeat(' ', whiteSpaceCount)
          return (
            <AntAutoComplete.Option key={getSuggestionKey(suggestion)} value={suggestion.text + uniqueWhitespace}>
              <SuggestionComponent suggestion={suggestion} />
            </AntAutoComplete.Option>
          )
        })
      : undefined
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ suggestedOptions, isFetching }), [isFetching, suggestions])
}

export const useGetOptions = (value: string | undefined) => {
  const currentSearchOption = useMemo(() => getCurrentSearchOption(value), [value])
  const recentResearchesOptions = useMemo(() => getRecentSearchOptions(value), [value])
  const { suggestedOptions, isFetching } = useGetSuggestedOptions(value)

  const additionalOptions =
    shouldDisplayCurrentSearchAndSuggestions(value) && suggestedOptions ? suggestedOptions : recentResearchesOptions

  return useMemo(
    () => _.compact([isFetching ? null : currentSearchOption, ...additionalOptions]),
    [additionalOptions, currentSearchOption, isFetching]
  )
}
