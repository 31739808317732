// eslint-disable-next-line import/no-default-export
export default {
  title: 'Operations',
  CompaniesInTheList: 'Companies in the list',
  Editor: {
    AddRule: 'Add rule',
    AddGroup: 'Add group',
    DeleteGroup: 'Delete group',
    StartNewSearch: 'Start new search'
  },
  ShowQuery: 'Show Query',
  NoMobileDesc: 'The page you are trying to access is only available for tablets and desktops.',
  Search: 'Search',
  Actions: {
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    SaveQuery: 'Save Query',
    SaveQueryTooltip: 'Save this query in the Operations module',
    Download: 'Download Excel',
    DownloadTooltip: 'Download the enriched list of companies on your device',
    SaveList: 'Save list',
    SaveListTooltip: 'Save this companies list into a portfolio'
  },
  SaveQuery: {
    DialogTitle: 'Save research',
    Description: 'Insert the name you want to use to save your research or select an existing query to override it.',
    InputLabel: 'Query name',
    InputPlaceholder: 'Research name',
    UpdateExisting: 'Update existing',
    CreateNew: 'Create New',
    SavedQuery: 'saved correctly.',
    SavedQueryDescription: 'You will find the query saved in the repository related to the reference area.'
  },
  filters: {
    selectGroup: 'Select Group',
    selectFilter: 'Select Filter',
    booleanTrue: 'True',
    booleanFalse: 'False',
    booleanEmpty: 'Empty',
    searchField: 'Search Filters',
    searchText: 'Search Text',
    searchForSynonyms: 'Search for synonyms',
    selectSynonyms: 'Select synonyms',
    operatorsEquals: 'Equal to',
    operatorsNotEquals: 'Not equal to',
    operatorsGreater: 'Greater than',
    operatorsLower: 'Less than',
    operatorsGreaterOrEqual: 'Greater than or equal to',
    operatorsLowerOrEqual: 'Lower than or equal to',
    operatorsNotExists: 'Null',
    operatorsExists: 'Not null',
    operatorsBetween: 'Between',
    operatorsIsOneOf: 'Is one Of',
    operatorsIsNotOneOf: 'Is not one Of',
    operatorsIn: 'In',
    operatorsNotIn: 'Not In',
    operatorsContains: 'Contains',
    operatorsNotContains: "Doesn't contain",
    fromDate: 'From Date',
    toDate: 'To Date',
    minValue: 'Min value',
    maxValue: 'Max value'
  },
  CustomizeQuery: {
    Title: 'Customize your query.',
    Message:
      'Click on "Add rule" to start your research. Select the operators, choose your relevant filters and confirm.'
  },
  WarningRespectingLocalUnits: {
    Title: 'Enrichment',
    Text: 'The query will contain companies with Office type "Administrative Headquarter", "Administrative Headquarter and Registered office" and "Registered office" (if selected). To include companies with Office type "Local unit", please activate the Local Units addon by contacting the customer service.'
  }
}
