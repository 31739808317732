import _ from 'lodash'
import React from 'react'
import { WithT } from 'i18next'
import { SelectedPortfolio } from '../../../../features/targeting/types'
import { Dialog } from '../../../../components/Dialog'
import { disableTransitions } from '../helpers'
import { ErrorContent } from '../ErrorContent'
import { ContentWrapper } from '../styled'
import { Wizard } from '../../../../components/Wizard'
import { LoadPortfolioStepOne } from './LoadPortfolioStepOne'
import { LoadPortfolioStepTwo } from './LoadPortfolioStepTwo'

type Props = {
  loading: boolean
  portfolios: any[]
  selectedPortfolios: SelectedPortfolio[] | null
  setSelectedPortfolios: (portfolios: SelectedPortfolio[]) => void
  noTransition: boolean
  error?: boolean
  close: () => void
  onSubmit: () => void
} & WithT

export const LoadPortfolioDialog = ({
  t,
  loading,
  portfolios,
  close,
  onSubmit,
  selectedPortfolios,
  setSelectedPortfolios,
  noTransition,
  error
}: Props) => {
  const dialogProps = {
    title: t('LoadPortfolio.DialogTitle'),
    width: 900,
    visible: true,
    footer: '',
    onCancel: close,
    ...disableTransitions(noTransition)
  }

  if (error) {
    return (
      <Dialog {...dialogProps} loading={false} okText={t('common:Ok')} onOk={close}>
        <ErrorContent
          message={t('error:TargetingLoadPortfolio')}
          description={t('error:TargetingLoadPortfolioDescription')}
        />
      </Dialog>
    )
  }

  return (
    <Dialog
      {...dialogProps}
      loading={loading}
      okButtonProps={{ disabled: !portfolios.length || _.isEmpty(selectedPortfolios) }}
      onOk={onSubmit}
      footer={null}
    >
      <ContentWrapper>
        <div className="centered">
          <Wizard initialStep={1} stepCount={2} isLazyMount>
            <LoadPortfolioStepOne
              key="step-one-portfolio"
              close={close}
              selectedPortfolios={selectedPortfolios}
              setSelectedPortfolios={setSelectedPortfolios}
              previousStepText={t('common:Cancel')}
            />
            <LoadPortfolioStepTwo
              key="step-two-status"
              onSubmit={onSubmit}
              nextStepText={t('LoadPortfolio.Load')}
              selectedPortfolios={selectedPortfolios}
            />
          </Wizard>
        </div>
      </ContentWrapper>
    </Dialog>
  )
}
