import React from 'react'
import { Button } from 'antd3'
import { ButtonProps, ButtonType, ButtonSize } from 'antd3/lib/button'
import styled from 'styled-components/macro'
import { Omit } from '../../utils/types'
import { commonStyle } from './common'

export type IconButtonType = ButtonType | 'transparent'

export type IconButtonSize = ButtonSize

type IconButtonSizes = {
  btnSize: number
  iconSize: number
}

export const iconButtonSizes = (size?: ButtonSize): IconButtonSizes => {
  if (size === 'small') {
    return { btnSize: 26, iconSize: 16 }
  }
  return { btnSize: 40, iconSize: 24 }
}

type Props = {
  type?: IconButtonType
  children?: any
} & Omit<ButtonProps, 'type' | 'children'>

export const StyledButton = styled(Button)<Props>`
  &.ant-btn {
    ${commonStyle}
    ${({ size }) => {
      const { btnSize, iconSize } = iconButtonSizes(size)
      const padding = (btnSize - iconSize) / 2
      return `
        width: ${btnSize}px;
        height: ${btnSize}px;
        padding: ${padding}px;
      `
    }}

    &.transparent {
      width: auto;
      height: auto;
      padding: 0;
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }
`

export const IconButton = ({ className, type, size, children, ...props }: Props) => {
  const { iconSize } = iconButtonSizes(size)

  const icon = React.cloneElement(children, { width: iconSize, height: iconSize })

  return (
    <StyledButton
      {...props}
      className={`${className} ${type === 'transparent' ? 'transparent' : ''}`}
      type={type !== 'transparent' ? type : undefined}
      size={size}
    >
      {icon}
    </StyledButton>
  )
}
