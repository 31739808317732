import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useGetPortfolioList } from '../../../../../api/portfolio'
import { addPortfolio, updateCampaignByPortfolio, useGetCampaign } from '../../../../../api/salestool'
import KoImage from '../../../../../assets/images/ko.svg'
import OkImage from '../../../../../assets/images/ok.svg'
import { Dialog } from '../../../../../components/Dialog'
import { Col, Row } from '../../../../../components/Grid'
import { Option } from '../../../../../components/Select'
import { Text } from '../../../../../components/Text'
import { DialogActions } from '../../../../../features/dialog/actions'
import { LoadingActions } from '../../../../../features/loading/actions'
import { ComponentProps } from '../../../../../types'
import { BaseDialog } from '../../../BaseDialog'
import { AddPortfolioViewState } from '../../types'
import { StyledRadio, StyledSelect, StyledText } from './components'
import { useIsCustomVarStructureChanged } from './useIsCustomVarStructureChanged'
import { PortfolioListOrderBy } from '../../../../../api/portfolio/types'

type Props = {
  isLoading: boolean
  view: AddPortfolioViewState
  campaignId: string
  currentPortfolios: string[]
  close: () => void
}

enum SelectedOption {
  AddCompanies,
  AddPortfolio
}

export const AddPortfolioDialog = ({ isLoading, view, close, currentPortfolios, campaignId }: Props) => {
  const { t } = useTranslation(['salestool', 'common'])
  const dispatch = useDispatch()

  const [selectedPortfolio, setSelectedPortfolio] = useState('')
  const [selectedOption, setSelectedOption] = useState(SelectedOption.AddCompanies)

  const { isLoading: isLoadingCampaign, data: campaign } = useGetCampaign(campaignId)
  const { data: portfolios, isLoading: portfoliosLoading } = useGetPortfolioList({
    withEnrichedCompanies: true,
    orderBy: PortfolioListOrderBy.UpdatedAtDesc,
    minCountOfCompanies: 0
  })

  const campaignName = campaign?.campaignName
  const currentPortfolio = _.find(portfolios, ['id', selectedPortfolio])
  const selectedPortfolioId = currentPortfolio?.id ?? ''
  const selectedPortfolioName = currentPortfolio?.name ?? ''

  const isStructureChanged = useIsCustomVarStructureChanged(campaignId, currentPortfolio?.id)

  const addPortfolioMutation = useMutation(
    () => {
      dispatch(LoadingActions.setDialogLoading(true))
      return addPortfolio(selectedPortfolioId, campaignId)
    },
    {
      onSuccess: () => {
        dispatch(LoadingActions.setDialogLoading(false))
        dispatch(
          DialogActions.setDialogData('AddPortfolio', {
            campaignId,
            selectedPortfolioId,
            selectedPortfolioName,
            state: AddPortfolioViewState.AddPortfolioDone
          })
        )
      },
      onError: () => {
        dispatch(LoadingActions.setDialogLoading(false))
        dispatch(
          DialogActions.setDialogData('AddPortfolio', {
            state: AddPortfolioViewState.AddPortfolioError
          })
        )
      }
    }
  )

  const updateCampaignByPortfoliosMutation = useMutation(
    () => {
      dispatch(LoadingActions.setDialogLoading(true))
      return updateCampaignByPortfolio(selectedPortfolioId, campaignId)
    },
    {
      onSuccess: () => {
        dispatch(LoadingActions.setDialogLoading(false))
        dispatch(
          DialogActions.setDialogData('AddPortfolio', {
            campaignId,
            selectedPortfolioId,
            selectedPortfolioName,
            state: isStructureChanged
              ? AddPortfolioViewState.AddCompaniesWithChangedCustomVariablesStructureDone
              : AddPortfolioViewState.AddCompaniesDone
          })
        )
      },
      onError: () => {
        dispatch(LoadingActions.setDialogLoading(false))
        dispatch(
          DialogActions.setDialogData('AddPortfolio', {
            state: AddPortfolioViewState.AddCompaniesError
          })
        )
      }
    }
  )

  const missingPortfoliosArray = _.reject(portfolios, p => _.includes(currentPortfolios, p.id))
  const currentPortfoliosArray = _.filter(portfolios, p => _.includes(currentPortfolios, p.id))

  const onOkHandler = async () => {
    if (selectedOption === SelectedOption.AddCompanies) {
      await updateCampaignByPortfoliosMutation.mutateAsync()
    }
    if (selectedOption === SelectedOption.AddPortfolio) {
      await addPortfolioMutation.mutateAsync()
    }
  }

  const props: Partial<ComponentProps<typeof Dialog>> = {
    destroyOnClose: false,
    width: 800,
    visible: true,
    footer: '',
    loading: isLoading || portfoliosLoading || isLoadingCampaign,
    onCancel: close
  }

  switch (view) {
    case AddPortfolioViewState.AddPortfolioView:
      return (
        <Dialog
          {...props}
          title={t('AssignCampaign.Dialog.AddPortfolioTitle')}
          cancelText={t('common:Cancel')}
          okText={t('common:Continue')}
          okButtonProps={{ disabled: !selectedPortfolio || isLoading || portfoliosLoading }}
          onOk={onOkHandler}
        >
          <Col>
            <Row padding="20px 0">
              <StyledRadio
                checked={selectedOption === SelectedOption.AddCompanies}
                onChange={() => setSelectedOption(SelectedOption.AddCompanies)}
                disabled={false}
              >
                <Text>{t('AssignCampaign.Dialog.AddNewCompaniesFromStartingPortfolio')}</Text>
                <Col>
                  <Row padding="20px 0 0 20px">
                    <StyledText>{t('AssignCampaign.Dialog.AddedCompanies')}</StyledText>
                  </Row>

                  <StyledSelect
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    dropdownClassName="dialog-select-menu"
                    placeholder={t('AssignCampaign.Dialog.AddPortfolioSelect')}
                    onSelect={value => setSelectedPortfolio(value as string)}
                    showSearch
                    optionFilterProp="title"
                    disabled={selectedOption === SelectedOption.AddPortfolio}
                  >
                    {_.map(currentPortfoliosArray, ({ id, name }) => (
                      <Option key={id} title={name} value={id}>
                        {name}
                      </Option>
                    ))}
                  </StyledSelect>
                </Col>
              </StyledRadio>
            </Row>

            <Row padding="20px 0">
              <StyledRadio
                checked={selectedOption === SelectedOption.AddPortfolio}
                onChange={() => setSelectedOption(SelectedOption.AddPortfolio)}
                disabled={campaign?.hasCustomVariables}
              >
                <Text>{t('AssignCampaign.Dialog.AddPortfolio')}</Text>
                <Col>
                  <Row padding="20px 0 0 20px">
                    <StyledText>{t('AssignCampaign.Dialog.AddedPortfolio')}</StyledText>
                  </Row>

                  <StyledSelect
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    dropdownClassName="dialog-select-menu"
                    placeholder={t('AssignCampaign.Dialog.AddPortfolioSelect')}
                    onSelect={value => setSelectedPortfolio(value as string)}
                    showSearch
                    optionFilterProp="title"
                    disabled={selectedOption === SelectedOption.AddCompanies || campaign?.hasCustomVariables}
                  >
                    {_.map(missingPortfoliosArray, ({ id, name }) => (
                      <Option key={id} title={name} value={id}>
                        {name}
                      </Option>
                    ))}
                  </StyledSelect>
                </Col>
              </StyledRadio>
            </Row>
          </Col>
        </Dialog>
      )
    case AddPortfolioViewState.AddPortfolioDone:
      return (
        <Dialog {...props} title={t('AssignCampaign.Dialog.AddPortfolioTitle')} okText={t('common:Ok')} onOk={close}>
          <BaseDialog
            content={t('AssignCampaign.Dialog.AddPortfolioPending', { selectedPortfolioName, campaignName })}
            image={<img src={OkImage} alt="ok" />}
          />
        </Dialog>
      )
    case AddPortfolioViewState.AddPortfolioError:
      return (
        <Dialog {...props} title={t('AssignCampaign.Dialog.AddPortfolioTitle')} okText={t('common:Ok')} onOk={close}>
          <BaseDialog content={t('AssignCampaign.Errors.AddPortfolio')} image={<img src={KoImage} alt="error" />} />
        </Dialog>
      )
    case AddPortfolioViewState.AddCompaniesDone:
      return (
        <Dialog {...props} title={t('AssignCampaign.Dialog.AddCompaniesTitle')} okText={t('common:Ok')} onOk={close}>
          <BaseDialog
            content={t('AssignCampaign.Dialog.AddCompaniesPending', { campaignName })}
            image={<img src={OkImage} alt="ok" />}
          />
        </Dialog>
      )
    case AddPortfolioViewState.AddCompaniesWithChangedCustomVariablesStructureDone:
      return (
        <Dialog {...props} title={t('AssignCampaign.Dialog.AddCompaniesTitle')} okText={t('common:Ok')} onOk={close}>
          <BaseDialog
            content={t('AssignCampaign.Dialog.AddCompaniesWithCustomVariablesStructureChangedPending', {
              campaignName
            })}
            image={<img src={OkImage} alt="ok" />}
          />
        </Dialog>
      )
    case AddPortfolioViewState.AddCompaniesError:
      return (
        <Dialog {...props} title={t('AssignCampaign.Dialog.AddCompaniesTitle')} okText={t('common:Ok')} onOk={close}>
          <BaseDialog content={t('AssignCampaign.Errors.AddCompanies')} image={<img src={KoImage} alt="error" />} />
        </Dialog>
      )
    default:
      return null
  }
}
