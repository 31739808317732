import React, { useState } from 'react'
import _ from 'lodash'
import { CompanyNote } from './CompanyNote'
import { CompanyNote as CompanyNoteType } from '../../features/salestool/types'

type Props = {
  notes: CompanyNoteType[]
  userId: string
  onDelete: (noteId: string) => void
}

const CompanyNotesCmp = ({ notes = [], userId, onDelete }: Props) => {
  const [selectedNote, setSelectedNote] = useState('')

  return (
    <div>
      {_.map(notes, note => {
        const { id, createUser, createTimestamp, note: noteText, createUserId } = note
        return (
          <CompanyNote
            key={id}
            noteId={id}
            authorId={createUserId}
            author={createUser}
            date={createTimestamp}
            text={noteText}
            deleteConfirmation={selectedNote === id}
            userId={userId}
            onClickRemove={setSelectedNote}
            onDelete={onDelete}
          />
        )
      })}
    </div>
  )
}

export const CompanyNotes = React.memo(CompanyNotesCmp)
