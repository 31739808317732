import React from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import { Dialog } from '../../../components/Dialog'
import { TrackingActions } from '../../../features/analytics/actions'
import { DialogActions } from '../../../features/dialog/actions'
import { EventCategory, GroupActionType } from '../../../features/analytics/types'
import { AssignmentType, AssignCompaniesDialogData } from './types'
import { assignProduct, assignSales, assignAreaManager } from '../../../api/portfolio'
import { AssignEntityActionType, AssignEntityRequest } from '../../../api/portfolio/types'
import { convertCompoundExpressionForBackend } from '../../../features/filters/utils'

function getDialogText(t: TFunction, type: AssignmentType, entityId: string | undefined, entityName: string) {
  if (entityId) {
    switch (type) {
      case AssignmentType.AssignProduct:
        return t('portfolio:CompanyAssignment:ProductAssignment', { product: entityName })
      case AssignmentType.AssignSales:
        return t('portfolio:CompanyAssignment:SalesAssignment', { sales: entityName })
      case AssignmentType.AssignAreaManager:
        return t('portfolio:CompanyAssignment:AreaManagerAssignment', { manager: entityName })
      default:
        throw new Error('Unknown assignment type')
    }
  } else {
    switch (type) {
      case AssignmentType.AssignProduct:
        return t('portfolio:CompanyAssignment:ProductUnassignment')
      case AssignmentType.AssignSales:
        return t('portfolio:CompanyAssignment:SalesUnassignment')
      case AssignmentType.AssignAreaManager:
        return t('portfolio:CompanyAssignment:AreaManagerUnassignment')
      default:
        throw new Error('Unknown assignment type')
    }
  }
}

export type AssignCompaniesFormBaseProps = {
  close: () => void
  data: AssignCompaniesDialogData
}

export const AssignCompaniesDialog = ({ close, data }: AssignCompaniesFormBaseProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'portfolio'])

  const {
    mutate: mutateAssignCompanies,
    isIdle,
    isError,
    isLoading
  } = useMutation(
    () => {
      const assignRequest: AssignEntityRequest = {
        selection: {
          portfolioGridQuery: {
            semantic: data.semantic,
            query: convertCompoundExpressionForBackend(data.expression),
            excludedPortfoliosIds: data.excludedPortfoliosIds
          },

          productId: data.assignmentType === AssignmentType.AssignProduct ? data.entityId : undefined,
          salesId: data.assignmentType === AssignmentType.AssignSales ? data.entityId : undefined,
          managerId: data.assignmentType === AssignmentType.AssignAreaManager ? data.entityId : undefined,

          action: AssignEntityActionType.Add,
          selectedItems: !data.isSelectAll ? data.selectedCompanies : undefined,
          itemsToIgnore: data.isSelectAll ? data.deSelectedCompanies : undefined,
          sourceContainerItemId: data.portfolioId,
          destinationContainerItemId: data.portfolioId
        }
      }
      if (data.assignmentType === AssignmentType.AssignProduct) {
        return assignProduct(assignRequest)
      }
      if (data.assignmentType === AssignmentType.AssignSales) {
        return assignSales(assignRequest)
      }
      return assignAreaManager(assignRequest)
    },
    {
      onSuccess: () => {
        if (data.assignmentType === AssignmentType.AssignProduct) {
          dispatch(TrackingActions.trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.AssignProduct))
        }
        if (data.assignmentType === AssignmentType.AssignSales) {
          dispatch(TrackingActions.trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.AssignSales))
        }
        if (data.assignmentType === AssignmentType.AssignAreaManager) {
          dispatch(TrackingActions.trackGroupAction(EventCategory.PortfolioDetail, GroupActionType.AssignAreaManager))
        }
        close()
      },

      onError: (e: AxiosError) => {
        if (e?.response?.status === 423) {
          dispatch(DialogActions.showDialog('PortfolioErrorLock'))
        }
      }
    }
  )

  const okButtonText = isIdle || isLoading ? t('common:Confirm') : t('common:Ok')

  return (
    <Dialog
      loading={isLoading}
      visible
      title={t('portfolio:CompanyAssignment:ConfirmOperation')}
      cancelText={isIdle && t('common:Cancel')}
      okText={okButtonText}
      onOk={() => {
        if (isIdle) {
          return mutateAssignCompanies()
        }
        return close()
      }}
      okButtonProps={{
        disabled: isLoading
      }}
      onCancel={close}
      footer
    >
      <div>
        {(isIdle || isLoading) && <p>{getDialogText(t, data.assignmentType, data.entityId, data.displayName)}</p>}
        {isError && <p>{t('portfolio:DialogError')}</p>}
      </div>
    </Dialog>
  )
}
