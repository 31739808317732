/* eslint-disable import/no-default-export */
export default {
  Username: "Nom d'utilisateur",
  Password: 'Mot de passe',
  Login: 'Connexion',
  LoginErrorMessage: 'Utilisateur ou mot de passe saisis incorrect',
  SignInMessage: 'Connexion à Margò',
  EnterDetails: 'Saisir vos coordonnées ci-dessous',
  InsertUserName: "Saisir votre nom d'utilisateur",
  InsertUserNamaInBehalf: 'Pour le compte de',
  InsertPassword: 'Saisir votre mot de passe',
  ForgotPassword: 'Mot de passe oublié ?',
  ResetPasswordDialog: {
    Title: 'Mot de passe oublié',
    Description:
      'Contacter notre service client pour réinitialiser votre mot de passe par e-mail <0>{{email}}</0> ou par téléphone {{phone}}.',
    Email: 'customerserviceitaly@cribis.com',
    Phone: '800 821 058'
  },
  ResetPasswordForm: {
    Description:
      "Saisir votre nom d’utilisateur et l'adresse e-mail associée à votre compte. Un e-mail avec un lien pour terminer la réinitialisation du mot de passe va vous être envoyé.",
    SuccessDesription:
      'Un e-mail avec un lien de réinitialisation du mot de passe vous a été envoyé. Aller à la page de connexion.',
    Email: 'E-mail',
    ResetPassword: 'Réinitialiser le mot de passe',
    ReturnToLoginpage: 'Retour à la page de connexion',
    InsertUserName: "Saisir votre nom d'utilisateur",
    InsertEmail: 'Saisir votre e-mail',
    InvalidUsernameOrPassword:
      "Le mot de passe n'a pas été réinitialisé. Nom d'utilisateur/e-mail invalide. Réessayer ou contacter le service client.",
    ResetFailed: 'Échec de réinitialisation du mot de passe.'
  },
  RenewPasswordForm: {
    Title: 'Réinitialiser le mot de passe',
    Description: 'Votre mot de passe a expiré. Pour accéder aux services de Margò, changer votre mot de passe.',
    OldPassword: 'Ancien mot de passe',
    NewPassword: 'Nouveau mot de passe',
    ConfirmPassword: 'Confirmer le nouveau mot de passe',
    ChangePassword: 'Changer mot de passe',
    PasswordChangeSuccessTitle: 'Mot de passe changé',
    PasswordChangeSuccessText: 'Le mot de passe a été changé avec succès',
    RenewPasswordFailed: 'Le mot de passe saisi est incorrect'
  },
  PasswordExpiration90Days: 'Le mot de passe restera valide pendant 90 jours',
  PasswordRules_che: `La longueur minimale du mot de passe est de 8 caractères et la longueur maximale est de 25 caractères
  Le mot de passe doit contenir au moins une lettre et un chiffre
  Le mot de passe ne peut pas être le même que l'ancien mot de passe et il ne peut pas contenir le nom d'utilisateur
  Le mot de passe doit avoir au moins une majuscule
  Le mot de passe ne peut pas contenir les trois caractères égaux consécutifs`,
  Cs: {
    Title: 'Utilisateur déjà connecté',
    Info: 'Votre utilisateur est déjà connecté, la session en cours prendra fin si vous continuez. Toutes les opérations en arrière-plan sont conservées.',
    Question: 'Voulez-vous quitter la session en cours et en démarrer une nouvelle ?',
    NewSession: 'Démarrer nouvelle session'
  },
  Sso: {
    error: 'Problème de connexion par la méthode « Connexion simple »',
    back: "Se connecter avec le nom d'utilisateur et mot de passe"
  }
}
