import axios from 'axios'
import { OrderByResponse, RedirectUrlResponse } from '../types'
import { DomainByDomainTypeResponse, EntityDispatchDataRequest, EntityDispatchDataResponse, ModuleName } from './types'

export const getOrderByOptions = (moduleName: ModuleName) =>
  axios
    .get<OrderByResponse>('/api/Common/order-by', {
      params: {
        moduleName,
        pageType: 'FullDetails'
      }
    })
    .then(res => res.data)

export const getDomainByDomainType = (domainType: string) =>
  axios
    .get<DomainByDomainTypeResponse>(`/api/Common/domain-by-domain-type?domainType=${domainType}`)
    .then(res => res.data)

export const entityDispatchDataRequest = (requestData: EntityDispatchDataRequest) =>
  axios.post<EntityDispatchDataResponse>('/api/extensionsManager/dispatch', requestData)

export const getEntityRedirectUrl = (entityAdditionalItemId: string) =>
  axios
    .get<RedirectUrlResponse>(`/api/extensions-manager/${encodeURIComponent(entityAdditionalItemId)}/redirect-url`)
    .then(res => res.data)
