import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import Leaflet from 'leaflet'
import qs from 'qs'
import axios from 'axios'
import { Root } from './components/App/Root'
import { ConfigActions } from './features/config/actions'
import { store } from './features/store'
import * as serviceWorker from './serviceWorker'
import './i18n'
import 'antd3/dist/antd.css'
import 'antd/dist/reset.css'
import 'leaflet/dist/leaflet.css'
import { consoleOverride } from './console'
import { initIntlNumberFormatter } from './utils/numberFormat'
import { fetchApplicationConfig } from './api/config'

axios.defaults.paramsSerializer = { serialize: params => qs.stringify(params, { allowDots: true }) }
axios.defaults.timeout = 0

Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/'

fetchApplicationConfig().then(res => {
  store.dispatch(ConfigActions.onAppConfig(res.data))
})

consoleOverride()

initIntlNumberFormatter().then(() => {
  const container = document.getElementById('root')
  if (container) {
    const root = ReactDOM.createRoot(container)
    root.render(<Root />)
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
