import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { getProducts } from './api'
import { SortItem } from '../../types'
import { mapProducts } from '../../features/products/mappers'

export const productKeys = {
  All: () => [{ level1: 'products' }] as const,
  GetProducts: (top: number | undefined, skip: number | undefined, sorting: SortItem | undefined) =>
    [{ ...productKeys.All()[0], level2: 'getProducts', top, skip, sorting }] as const,
  GetInfiniteProducts: (sorting?: SortItem) =>
    [{ ...productKeys.All()[0], level2: 'getInfiniteProducts', sorting }] as const
}

export const useGetProducts = (top?: number, skip?: number, sorting?: SortItem) =>
  useQuery(productKeys.GetProducts(top, skip, sorting), ({ queryKey: [queryKeys] }) =>
    getProducts(queryKeys.top, queryKeys.skip, queryKeys.sorting?.property, queryKeys.sorting?.order).then(mapProducts)
  )

export const useGetInfiniteProducts = (sorting?: SortItem) =>
  useInfiniteQuery(
    productKeys.GetInfiniteProducts(sorting),
    ({ queryKey: [queryKeys], pageParam = 0 }) =>
      getProducts(10, pageParam, queryKeys.sorting?.property, queryKeys.sorting?.order),
    {
      getNextPageParam: (lastResp, allPages): number | undefined => {
        const count = _.sum(_.map(allPages, 'items.length'))
        return lastResp?.totalCount <= count ? undefined : count
      }
    }
  )
