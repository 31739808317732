/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: 'Typ analýzy',
    StartAnalysis: 'Pre začatie analýzy musíte zvoliť typ analýzy, ktorú chcete spracovať',
    LoadQuery: 'Načítať dopyt',
    LoadQueryTooltip: 'Otvorte uložený dopyt',
    MarketAnalysisBtn: 'Spustiť novú Analýzu trhu',
    MarketAnalysisBtnTooltip:
      'Vizualizujte a segmentujte skupinu spoločností podľa hlavných premenných a vytvorte si cieľ',
    FromTargeting: 'Načítať dopyt na Analýzu trhu',
    FromTargetingTooltip: 'Otvorte dopyt uložený v module Analyzovať trh',
    FromProspecting: 'Načítať dopyt z Výberu klientov',
    FromProspectingTooltip: 'Otvorte dopyt uložený v module Prospekting',
    PortfolioQuery: 'Načítajte dopyt zo správy portfólia',
    TargetingQuery: 'Načítať dopyt na Analýzu trhu',
    ProspectingQuery: 'Načítať dopyt z Výberu klientov',
    ChooseQuery: 'Vyberte, ktorý dopyt chcete načítať'
  },
  ChartLabels: {
    Company_0: 'Spoločnosť',
    Company_1: 'Spoločnosti',
    Company_2: 'Spoločností',
    ChartDetail: 'Podrobnosti grafu',
    PercentageOfCompanies: '% spoločností',
    NumberOfCompanies: 'n° spoločností',
    NumberOfEmployees: 'n° zamestnancov',
    Amount: 'Suma',
    Risk: 'Úroveň rizika',
    Employees: 'Zamestnanci',
    Revenue: 'Príjmy',
    RevenueCz: 'Príjmy',
    RevenueSk: 'Príjmy',
    Other: 'Iné',
    Years: 'Roky',
    HiddenNDNotice: 'Poznámka: tento graf nezobrazuje triedu spoločností bez údajov'
  },
  Header: {
    Targeting: 'Analýza trhu',
    Title: 'Analýza trhu',
    LoadQuery: 'Načítať dopyt',
    LoadQueryTooltip: 'Otvorte uložený dopyt',
    SaveQuery: 'Uložiť dopyt',
    SaveQueryTooltip: 'Tento dopyt uložte do modulu Analyzovať trh',
    Download: 'Stiahnuť PDF/Excel',
    DownloadTooltip: 'Stiahnite si report o tomto dopyte do svojho zariadenia',
    LoadPortfolio: 'Načítať portfólio',
    LoadPortfolioTooltip: 'Otvorte a analyzujte portfólio',
    SendToProspecting: 'Poslat dopyt do Výberu klientov',
    SendToProspectingTooltip: 'Zobraziť zoznam spoločností, ktoré patria do tohto dopytu',
    SendToProspectingMessage: 'Ak chcete toto tlačidlo povoliť, použite na svoju analýzu filter',
    SendToProspectingFromPortfolioTooltip: 'Zobrazte potenciálne spoločnosti, ktoré zodpovedajú kritériám vyhľadávania',
    SaveList: 'Uložit zoznam',
    SaveListTooltip: 'Uložte zoznam týchto spoločností do portfólia'
  },
  Filters: {
    CompanyCount_0: 'Spoločnosť',
    CompanyCount_1: 'Spoločnosti',
    CompanyCount_2: 'Spoločností',
    ActiveCompanyCount_0: 'Aktívna',
    ActiveCompanyCount_1: 'Aktívne',
    ActiveCompanyCount_2: 'Aktívnych',
    OtherCompanyCount_0: 'Iná',
    OtherCompanyCount_1: 'Iné',
    OtherCompanyCount_2: 'Iných',
    StatusSelectLabel: 'Zvoliť status',
    ShowBySelectLabel: 'Zobraziť podľa',
    AggregationFieldAmount: 'Suma',
    AggregationFieldNumCompanies: 'Počet spoločností'
  },
  Search: {
    SemanticSearch: 'Sémantické vyhľadávanie',
    SearchPlaceholder: 'Hľadať',
    All: 'Všetko',
    OfficialData: 'Oficiálne údaje',
    Website: 'Web a sociálne siete',
    Synonyms: 'Synonymá',
    Advanced: 'Pokročilé sémantické vyhľadávanie',
    AdvancedSwitch: 'Rozšírené',
    AdvancedTooltip:
      'S pokročilým sémantickým vyhľadávaním môžete použiť viac slov prepojených logickými operátormi (AND, OR, NOT). Ak ju chcete povoliť, prepnite prepínač do polohy ZAP. Pre viac informácií si stiahnite dokument.',
    DownloadSsGuide: 'Stiahnuť sprievodcu sémantickým vyhľadávaním'
  },
  DomainFilter: {
    SaveAndClose: 'Uložiť filtre a zavrieť'
  },
  SaveQuery: {
    DialogTitle: 'Uložiť vyhľadávanie',
    Description:
      'Zadajte názov, ktorý chcete použiť na uloženie výskumu, alebo vyberte existujúci dopyt, ktorý chcete prepísať.',
    InputLabel: 'Názov dopytu',
    InputPlaceholder: 'Názov vyhľadávania',
    UpdateExisting: 'Aktualizovať existujúce',
    CreateNew: 'Vytvor nový',
    AddToDashboardConfirmMessage: 'Chcete pridať dopyt na svoj dashboard?',
    AddToDashboardCheckboxText: 'Zobraziť na dashboarde',
    SavedQuery: 'správne uložené.',
    SavedQueryAndDashboard: 'uložený a pridaný do dashboardu.',
    SavedQueryDescription: 'Dopyt nájdete uložený v úložisku súvisiacom s referenčnou oblasťou.'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Vyberte, ktorý dopyt z Výberu klientov chcete načítať',
    IntroSectionTitleTargeting: 'Vyberte, ktorý dopyt z Analýzy trhu chcete načítať',
    IntroSectionDescription: 'Môžete pokračovať vo vyhľadávaní, ktoré ste už začali.',
    DialogTitle: 'Načítať uložené vyhľadávanie',
    DialogSubTitle: 'Vyberte uložené vyhľadávanie',
    DialogDescription:
      'Vyberte, v ktorej analýze chcete pokračovať, môžete pokračovať jednou zo svojich uložených analýz.',
    SelectLabel: 'Uložený dopyt',
    Load: 'Načítať',
    LoadingMessage: 'Načítava sa vaša analýza',
    InvalidPortfolioTitle: 'Neplatné portfólio',
    InvalidPortfolioMessage:
      'Portfólio uložené v dopyte už nie je platné. \nVaša analýza bude založená na všetkých spoločnostiach v databáze {{companyName}}.',
    TargetingRadio: 'Analýza trhu',
    ProspectingRadio: 'Výber klientov'
  },
  LoadPortfolio: {
    DialogTitle: 'Načítajte portfólia',
    DialogSubTitle: 'Vyberte portfólio',
    Description: 'Vyberte, na ktorom portfóliu chcete vykonať svoju analýzu.',
    SelectLabel: 'Uložené portfólio',
    Load: 'Načítať',
    LoadingMessage: 'Načítava sa portfólio'
  },
  SaveBeforeContinuing: {
    Question: 'Chcete pred načítaním vyhľadávania, uložiť svoje vyhľadávanie?',
    Description: 'Pred načítaním dopytu uložte svoje vyhľadávanie, aby ste predišli strate údajov.',
    OkBtn: 'Uložiť hľadanie',
    CancelBtn: 'Preskočiť uloženie'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: 'Vyberte portfólio, ktoré chcete analyzovať',
      Description:
        'Analýzu môžete spracovať z jedného alebo viacerých svojich portfólií alebo zobraziť súhrnnú analýzu všetkých spoločností v systéme {{companyName}}.',
      PortfolioOption: 'Vybrať portfólio',
      AggregateOption: 'Všetky spoločnosti v systéme {{companyName}}',
      UploadedPortfolio: 'Nahrané portfólio'
    },
    StepTwo: {
      Header: 'Vyberte status, ktorý chcete analyzovať',
      DescriptionLine1: 'Vyberte počiatočný status spoločností, ktoré sa majú analyzovať.',
      DescriptionLine2: 'Počas analýzy môžete zmeniť status pomocou filtrov.',
      Status: 'Status'
    }
  },
  Download: {
    ModalTitle: 'Stiahnuť PDF/Excel',
    SelectType: 'Zvoliť formát',
    SelectTypeDescription:
      'Môžete si stiahnuť kompletný report o svojej analýze v dvoch rôznych formátoch; vyberte typ zoznamu, ktorý chcete stiahnuť.',
    DownloadAnalysis: 'Stiahnuť svoju analýzu',
    Pdf: 'PDF',
    PdfDescription: 'Stiahnutie vo formáte .pdf',
    PdfRecap: 'Sťahujete súbor obsahujúci všetky výsledky tejto analýzy vo formáte pdf.',
    Excel: 'Excel',
    ExcelDescription: 'Stiahnutie vo formáte .xlsx',
    ExcelRecapLine1: 'Sťahujete súbor, ktorý obsahuje všetky výsledky tejto analýzy ako zoznam.',
    ExcelRecapLine2: 'Uloží sa do sekcie "Analýza trhu" vo vašom účte.',
    CompanyInPortfolio_0: 'Spoločnosť v tomto portfóliu',
    CompanyInPortfolio_1: 'Spoločnosti v tomto portfóliu',
    CompanyInPortfolio_2: 'Spoločností v tomto portfóliu',
    CompanyInAnalysis_0: 'Analyzovaná spoločnosť',
    CompanyInAnalysis_1: 'Analyzované spoločnosti',
    CompanyInAnalysis_2: 'Analyzovaných spoločností',
    DownloadPdf: 'Stiahnuť PDF',
    DownloadPdfPleaseWait: 'Počkajte, prosím, kým sa vygeneruje váš PDF report',
    DownloadAllChartsMustBeLoaded: 'Ak si chcete report stiahnuť, počkajte prosím, kým sa načítajú všetky grafy',
    DownloadPdfDisabledIE:
      'Stiahnutie PDF nie je v tejto verzii prehliadača podporované. Ak chcete stiahnuť PDF, aktualizujte prehliadač.',
    PdfPage: {
      Title: 'Report analýzy',
      CompanyPortfolioStatus: 'Status spoločností v portfóliu',
      DataShownBy: 'Údaje zobrazené podľa',
      ExcludedPortfolios: 'Vylúčené portfóliá'
    }
  },
  UnavailableForMobile: 'Stránka, na ktorú sa pokúšate dostať, je k dispozícii iba pre tablety a počítače.',
  ChartFetchError: 'Pri načítaní tohto grafu sa vyskytla chyba.',
  ChartFetchRetry: 'Skúsiť znova',
  ChartEmpty: 'Pre tento graf neexistujú dáta',
  BasedOnHeadquarter: 'Zobrazené čísla sa týkajú administratívneho ústredia',
  VisualizeAs: {
    List: 'Vizualizovať ako zoznam',
    Map: 'Vizualizovať ako mapu'
  }
}
