export enum AppointmentColor {
  Green = 'Green',
  Yellow = 'Yellow',
  Orange = 'Orange',
  Red = 'Red',
  Violet = 'Violet',
  Blue = 'Blue',
  LightBlue = 'LightBlue',
  Turquoise = 'Turquoise',
  Pink = 'Pink',
  Black = 'Black'
}

export type AppointmentBase = {
  id: string
  matchingTerm: string
  companyUnitId: string
  name: string
  start: string
  end: string
  color: AppointmentColor
  place: string
  permission: {
    edit: boolean
    delete: boolean
  }
}

export type AppointmentListItem = AppointmentBase

export type AppointmentItem = AppointmentBase & {
  ownerUserDistinguishName: string
  description: string
  externalRecipientsEmails: string
  recipientsDistinguishNames: string[]
}

export type GetAppointmentsRequest = {
  companyUnitId?: string
  from?: string
  to?: string
  top?: number
  excludeExpired?: boolean
}

export type GetAppointmentsResponse = {
  appointments: AppointmentListItem[]
  totalCount: number
}

export type GetAppointmentTargetResponse = {
  companyUnitId: string
  label: string
  matchingTerm: string
}

export type BaseAppointmentRequest = {
  name: string
  start: string
  end: string
  color: AppointmentColor
  place: string
  description: string
  recipientsDistinguishNames: string[]
  externalRecipientsEmails: string
  notifyRecipients: boolean
}

export type CreateAppointmentRequest = {
  matchingTerm: string
  companyUnitId: string
} & BaseAppointmentRequest

export type AppointmentTargetRequest = {
  matchingTerm: string | undefined
  companyUnitId: string | undefined
  countryCode: string | undefined
}
