import React, { useState } from 'react'
import _ from 'lodash'
import { Empty } from 'antd3'
import { TableProps as AntTableProps, ColumnProps } from 'antd3/lib/table'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TableColumnSorting } from '../../features/tables/types'
import { getDecimalPrecision } from '../../features/config/selectors'
import { StyledTable } from './StyledTable'
import { Text } from '../Text'
import { CenterChildren } from '../Center'
import { Button } from '../Button'

export const TABLE_ROW_HEIGHT_WITH_COLAPSE = 70

type ColumnHeaderTextProps = { text: string }
export const ColumnHeaderText = ({ text }: ColumnHeaderTextProps) => <Text primary bold center size="S" text={text} />

type LoadMoreButtonProps = { isLoading: boolean; onClick: () => void }
export const LoadMoreButton = ({ isLoading, onClick }: LoadMoreButtonProps) => {
  const { t } = useTranslation('common')
  return (
    <CenterChildren marginTop="3em">
      <Button type="primary" loading={isLoading} disabled={isLoading} onClick={onClick}>
        {t('LoadMore')}
      </Button>
    </CenterChildren>
  )
}

export type TableProps<T> = {
  emptyText?: string
  columns: ColumnProps<T>[]
  backendSorting?: boolean | string[]
  onSortingChange?: (value?: TableColumnSorting) => void
  dontRoundNumbers?: boolean
  rowHeight?: number
} & AntTableProps<T>

export function TableReduxless<T>({
  emptyText,
  columns,
  loading,
  rowKey,
  backendSorting,
  onSortingChange = _.noop,
  dontRoundNumbers,
  ...props
}: TableProps<T>) {
  const [sorting, setSorting] = useState<TableColumnSorting | undefined>()

  const decimalPrecision = useSelector(getDecimalPrecision)

  const cols = _.map<ColumnProps<T>, ColumnProps<T>>(columns, c => ({
    ...c,
    sorter:
      (_.isArray(backendSorting) && _.includes(backendSorting, c.dataIndex)) || backendSorting === true
        ? true
        : c.sorter,
    sortOrder: sorting && sorting.field === c.dataIndex ? sorting.order : c.sortOrder,
    render: (text, record, index) => {
      const formattedValue = !dontRoundNumbers && _.isNumber(text) ? _.round(text, decimalPrecision) : text
      return c.render ? c.render(formattedValue, record, index) : formattedValue
    }
  }))

  return (
    <StyledTable<T>
      {...props}
      columns={cols}
      loading={loading}
      rowKey={rowKey || (() => _.uniqueId())}
      locale={{
        emptyText: !loading ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} /> : <span />
      }}
      onChange={(pagination, filters, sorter) => {
        const sort = _.has(sorter, 'field') && _.has(sorter, 'order') ? sorter : undefined

        setSorting(sort)
        onSortingChange(sort)
      }}
    />
  )
}
