import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { Dialog } from '../../../../components/Dialog'
import { Text } from '../../../../components/Text'
import { DeleteCampaignViewState } from '../types'
import OkImage from '../../../../assets/images/ok.svg'
import KoImage from '../../../../assets/images/ko.svg'
import SalesToolImage from '../../../../assets/images/sales-tool.svg'
import { BaseDialog } from '../../BaseDialog'
import { deleteCampaign, getSalestoolKeys } from '../../../../api/salestool'
import { getDashboardKeys } from '../../../../api/dashboard/queries'
import { queryClient } from '../../../../features/queryclient'

type Props = {
  data: {
    campaignId: string
    campaignName: string
  }
  close: () => void
}

export const DeleteCampaignDialog = ({ data, close }: Props) => {
  const { t } = useTranslation(['salestool', 'common', 'error'])

  const { campaignId, campaignName } = data
  const { DeleteCampaignView, DeleteCampaignDoneView, DeleteCampaignErrorView } = DeleteCampaignViewState

  const [view, setView] = React.useState(DeleteCampaignView)

  const mutation = useMutation(() => deleteCampaign(campaignId), {
    onSuccess: () => {
      setView(DeleteCampaignDoneView)
      queryClient.invalidateQueries(getSalestoolKeys.GetCampaigns())
      queryClient.resetQueries(getDashboardKeys.GetUserDashboard())
    },
    onError: () => {
      setView(DeleteCampaignErrorView)
    }
  })

  const props = {
    destroyOnClose: false,
    title: t('DeleteCampaign'),
    okText: t(view === DeleteCampaignView ? 'common:Delete' : 'common:Ok'),
    cancelText: view === DeleteCampaignView ? t('common:Cancel') : undefined,
    onCancel: close,
    footer: '',
    width: 800,
    visible: true,
    loading: mutation.isLoading
  }

  if (view === DeleteCampaignView) {
    const deleteText = (
      <>
        <Text block primary bold>
          {t('Management.Campaign.DeleteCampaignDialogText1', { campaignName })}
        </Text>
        <Text block>{t('Management.Campaign.DeleteCampaignDialogText2')}</Text>
      </>
    )
    return (
      <Dialog {...props} onOk={() => mutation.mutate()}>
        <BaseDialog content={deleteText} image={<img src={SalesToolImage} alt="salesTool" />} />
      </Dialog>
    )
  }

  if (view === DeleteCampaignDoneView) {
    const successText = <Text block>{t('Management.Campaign.DeleteCampaignDoneDialogText1', { campaignName })}</Text>
    return (
      <Dialog {...props} onOk={close}>
        <BaseDialog content={successText} image={<img src={OkImage} alt="ok" />} />
      </Dialog>
    )
  }

  if (view === DeleteCampaignErrorView) {
    const failText = <Text block>{t('error:DeleteCampaign')}</Text>
    return (
      <Dialog {...props} onOk={close}>
        <BaseDialog content={failText} image={<img src={KoImage} alt="error" />} />
      </Dialog>
    )
  }

  return null
}
