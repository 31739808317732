/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Firmenname',
  Actions: 'Aktionen',
  DownloadPDF: 'PDF herunterladen',
  AddToCampaign: ' Der Kampagne hinzufügen',
  CompanyData: {
    CompanyName: 'Firmenname',
    Title: 'Vorschau',
    Town: 'Stadt',
    OfficeClassification: 'Branchentyp',
    Ateco2007: 'Hauptbranche WZ2008',
    LegalForm: 'Rechtsform',
    LastUpdate: 'Letztes Update',
    TaxNumber: 'Steuernummer',
    VatNumber: 'Umsatzsteuer-Identifikationsnummer',
    ZipCity: 'Postleitzahl Stadt',
    OnrNumber: 'ONR Number',
    MainIndustrialSectors: 'Hauptbranchen'
  },
  CompanySummary: {
    Evaluation: 'Bewertung',
    Profile: 'Profil',
    ratingScore: 'Bonitätsindex',
    CompanyGroup: 'Unternehmensgruppe',
    PublicContracts: 'Public tenders',
    LocalUnits: 'Filialen',
    CompanyStatus: 'Firmenstatus',
    Title: 'Firmen Übersicht',
    Address: 'Adresse',
    Town: 'Stadt',
    State: 'Bundesland',
    TaxNumber: 'Steuernummer',
    VatNumber: 'Umsatzsteuer-Identifikationsnummer',
    CrifNumber: 'CB Nummer',
    OnrNumber: 'ONR Number',
    CCIAAREA: 'CCIAA / REA',
    TotalNumberBranches: 'Anzahl Niederlassungen',
    TotalNumberLocalUnit: 'Anzahl der Filialen',
    DetailedLegalForm: 'Detaillierte Rechtsform',
    Ateco2007: 'Hauptbranche WZ2008',
    SIC: 'SIC',
    NACE: 'Branchen NACE Code',
    StartDate: 'Startdatum',
    IncorporationDate: 'Gründungsdatum',
    RegistrationCamComDate: 'Registrierungsdatum bei der Handelskammer',
    HoldingCompanyName: 'Name der Holdinggesellschaft',
    NationalParentCompany: 'Nationale Muttergesellschaft',
    TenderWon3y: 'Public tenders won (Last 3Y)',
    TenderParticipated3y: 'Public tenders participated (Last 3Y)',
    TenderValueWon3y: 'Public tenders value won  (Last 3Y)',
    TypeOffice: 'Niederlassungtyp',
    CountOffice: 'Anzahl Niederlassungen nach Typ',
    Turnover: 'Umsatz',
    GroupTurnover: 'Group Turnover',
    Estimated: 'Geschätzt',
    Profit: 'Ertrag',
    Employees: 'Mitarbeiter',
    Mol: 'Bruttobetriebsmarge',
    DetectionDate: 'Jahresabschlussdatum',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'Gruppen-Mehrwertsteuercode',
    MainIndustrialSectors: 'Hauptbranchen',
    SecondaryIndustrialSectors: 'Nebenbranche',
    StartOfBusiness: 'Geschäftsbeginn',
    DateOfFoundation: 'Gründungsdatum',
    TotalNumberOfVehicles: 'Anzahl Fuhrpark',
    NumberOfPassengerCars: 'Anzahl PKW',
    CarpoolNumberOfLorries: 'Anzahl LKW',
    BankInformation: 'Bank Informationen',
    ImportExport: 'Import/Export'
  },
  FinancialStatement: {
    Title: 'Finanzbericht',
    Subtitle: 'Hauptbudgetindikatoren für die letzten 3 Jahre',
    Text: 'Vollständige Details, Jahresabschlusspositionen stehen mit Download der vollständigen Liste zur Verfügung.',
    EconomicData: 'Wirtschaftsdaten',
    FinancialData: 'Finanzdaten',
    FinancialRatios: 'Budgetindikatoren',
    ProductionValue: 'Produktionswert',
    ProductionCosts: 'Produktionskosten',
    BalanceOfRevenues: 'Saldo aus Einnahmen und finanziellen Belastungen',
    ProfitLoss: 'Gewinn / Verlust',
    CashFlow: 'Cashflow',
    Assets: 'Vermögenswerte',
    TotalAssets: 'Bilanzsumme',
    FixedAssets: 'Anlagevermögen',
    TangAssets: 'Sachanlagen',
    CurrentAssets: 'Umlaufvermögen',
    TotalLiabilities: 'Verbindlichkeiten gesamt',
    Liabilities: 'Verbindlichkeiten',
    LiabilitiesAccruals: 'Passivabgrenzung',
    TotalLiabilitiesAndEquity: 'Summe der Passiva',
    ShareholdersEquity: 'Eigenkapital der Aktionäre',
    Provisions: 'Rückstellungen',
    DebtRatio: 'Verschuldungsquote',
    PFN: 'Nettofinanzanlage',
    TotalCredits: 'HABEN insgesamt',
    TotalDebts: 'Verbindlichkeiten insgesamt',
    NetWorth: 'Eigenkapital',
    EBITDA: 'EBITDA',
    ROE: 'ROE',
    ROI: 'ROI',
    ROS: 'ROS',
    TotalEmployees: 'Anzahl Mitarbeiter',
    Turnover: 'Umsatz',
    Profit: 'Erlös',
    RegisterCapital: 'Grundkapital',
    BalanceSheetRatios: 'Bilanzgrad',
    Sales: 'Verkaufserlöse',
    Cost: 'Kosten',
    FinancialAssets: 'Vermögenswerte',
    BalanceSheetProfitLost: 'Gewinn / Verlust (+/-)',
    Equity: 'Eigenkapital',
    LiquidityRatio: 'Liquiditätsgrad',
    ReturnOnTotalCapital: 'Kapitalrendite',
    BalanceSheetRatio: 'Bilanzrelationen',
    TurnoverAndTurnoverTrend: 'Umsatz und Umsatztrend',
    EmployeeEmployeetrend: 'Mitarbeiter & Mitarbeiter Trend',
    InvestmentAssets: 'Anlagevermögen',
    ImmaterialAssets: 'Immaterielle Vermögenswerte',
    PropertyEquipment: 'Immobilienausstattung',
    Inventories: 'Vorräte',
    ReceivablesAndOtherAssets: 'Forderungen und sonstige Vermögensgegenstände',
    CashInhandBalancesWithCreditInstitutions: 'Kassenbestand bei Kreditinstituten',
    FinancialInvestments: 'Finanzielle Investitionen',
    Securities: 'Wertpapiere',
    Accruals: 'Rückstellungen für Verbindlichkeiten',
    CapitalReserves: 'Kapitalrücklagen',
    ProfitReserves: 'Gewinnrücklagen',
    AccumulatedProfitLoss: 'Kumulierter Gewinnverlust',
    UntaxedReserves: 'Unversteuerte Reserven'
  },
  People: {
    Title: 'Personen',
    From: 'Ab',
    Amount: 'Menge',
    Exponents: 'Exponenten',
    Shareholders: 'Manager und Anteilseigner',
    Partners: 'Partnerunternehmen',
    Years: 'Jahre',
    OfficialSourceData: 'Daten aus offizieller Quelle',
    UnofficialSourceData: 'Daten aus inoffiziellen Quellen',
    DateOfBirth: 'Geburtsdatum',
    TypeOfConnection: 'Funktion',
    GoToLinkedin: 'Gehen Sie zu LinkedIn',
    Visualize: {
      PersonData: 'Personendaten visualisieren',
      CompanyData: 'Unternehmensdaten visualisieren'
    }
  },
  DecisionMakersShareholders: {
    Title: 'Entscheider und Anteilseigner',
    DecisionMakers: 'Entscheider',
    ShareholderCompanies: 'Anteilseigner (Unternehmen)',
    ShareholderPersons: 'Anteilseigner (Person)'
  },
  ContactsAndSocial: {
    Title: 'Contacts & Social',
    Social: 'Social',
    RepresentativeCompany: 'Repräsentative Firma',
    CertifiedMail: 'Zertifizierte E-Mail',
    PublicEmail: 'öffentliche E-Mail-Adresse',
    Website: 'Website URL',
    Fax: 'Fax',
    Telephone: 'Telefon',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Mehr laden'
  },
  Analytics: {
    Title: 'Analytics',
    Seasonalit: 'Seasonality Score',
    Propensity: 'Neigungswert',
    AnalyticsMarketing: 'Analytics Marketing',
    AnalyticsFinance: 'Analytics Finance',
    AnalyticsMarketingMobile: 'Marketing ',
    AnalyticsFinanceMobile: 'Finance',
    SeasonalitWinter: 'Winter',
    SeasonalitSpring: 'Frühling',
    SeasonalitSummer: 'Sommer',
    SeasonalitAutumn: 'Herbst',
    PropensityShortTerm: 'kurzfristig',
    PropensityMiddTerm: 'mittelfristig',
    PropensityCarLeas: 'Autoleasing',
    PropensityInstrLeas: 'Geräteleasing',
    PropensityRealEstateLeas: 'Immobilienleasing',
    PropensityFactoring: 'Factoring',
    Internazionalization: 'Internationalization Score',
    Social: 'Social Score',
    DigitalAttitude: 'Digital attitude Score',
    Innovation: 'Innovation Score',
    Supplier: 'Supplier Score',
    EstimatedTurnover: 'voraussichtlicher Umsatz',
    AnalyticsMarketingTooltip: 'Analytics Marketing',
    AnalyticsFinanceTooltip: 'Analytics Finance',
    AnalyticsMarketingMobileTooltip: 'Marketing ',
    AnalyticsFinanceMobileTooltip: 'Finance',
    SeasonalitTooltip:
      'Identifiziert Unternehmen mit einer starken Saisonalität und kurzfristigen Kreditlinien. Nützlich zum Starten von Initiativen zur Unterstützung dieser Unternehmen, die von Natur aus einen volatilen Einsatz von Betriebskapital haben',
    PropensityTooltip:
      'Zeigt die Neigung eines Unternehmens an, ein Finanzprodukt zu kaufen: kurzfristiger Kredit, langfristiger Kredit, Factoring, Leasing (Auto, Ausstattung und Maschinen, Immobilien). Der Neigungswert jedes Produkts ist in 3 Stufen eingeteilt. (1 = hoch, 2 = mittel, 3 = niedrig)',
    InternazionalizationTooltip: 'Zeigt die Neigung von Unternehmen, Geschäftsbeziehungen im Ausland zu betreiben',
    SocialTooltip: 'Score social',
    DigitalAttitudeTooltip:
      'Bei mittelgroßen und kleinen Unternehmen zeigt der Score die Unternehmen, welche die größte Affinität zur Digitalisierung haben',
    InnovationTooltip:
      'Zeigt die Innovationsbereitschaft des Unternehmens. Es basiert auf der Analyse von Patenten, Marken, Zertifizierungen und Beteiligungen von Business Accelerators',
    SupplierTooltip: 'Lieferanten Score',
    EstimatedTurnoverTooltip:
      'Für Unternehmen ohne Jahresabschluss wurde eine Schätzung entwickelt, mit dem der jüngste Umsatz konstruiert werden kann.'
  },
  Mastercard: {
    Title: 'Handel',
    Business: 'Business',
    BusinessTooltip:
      'Zeigt den Unternehmensumsatz basierend auf einem 12-Monats-Trend. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    Competitiveness: 'Wettbewerbsfähigkeit',
    CompetitivenessTooltip:
      'Zeigt das Potenzial eines Unternehmens innerhalb des Wettbewerbumfeldes, in dem es tätig ist. "Hoch" bedeutet es, dass es zu den besten in seiner Branche und seinem geografischen Gebiet gehört. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    Digitalization: 'Digitalisierung',
    DigitalizationTooltip:
      'Zeigt den Entwicklungsstand der digitalen Kanäle eines Unternehmens an. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    Premium: 'Premium',
    PremiumTooltip:
      'Zeigt den Einfluss des Unternehmens auf konsumfreudige Kunden, im Vergleich zum Branchendurchschnitt. "Hoch" bedeutet, dass dieses Unternehmen eine höhere Anziehungskraft auf diese Kunden auswirkt, als vergleichbare Unternehmen. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    CommercialPotential: 'Kommerzielles Potenzial',
    CommercialPotentialTooltip:
      'Zeigt das kommerzielle Potenzial des geografischen Gebiets im Vergleich zum Branchendurchschnitt. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    CompetitionLevel: 'Wettbewerbsniveau',
    CompetitionLevelTooltip:
      'Zeigt die Dichte an Unternehmen derselben Branche im geografischen Gebiet, in dem das Unternehmen tätig ist. Der Score reicht von 1 (hoch) bis 3 (niedrig).'
  },
  Portfolio: {
    Title: 'Portfolio',
    NumberOfCompanies: 'Anzahl Unternehmen',
    CreationDate: 'Erstellungsdatum',
    LastUpdateDate: 'Letztes Update'
  },
  SalesTransaction: {
    Title: 'Feedback von Sales',
    Name: 'Name',
    Product: 'Produkt',
    Sales: 'Sales',
    Task: 'Aufgabe',
    startDate: 'Startdatum',
    EndDate: 'Enddatum',
    Notes: 'Anmerkungen'
  },
  UnlockView: {
    Title: 'Um diesen Abschnitt freizuschalten ordern Sie bitte die erforderlichen {{companyName}}-Daten zu',
    MissingPortfoliosTitle: 'Hier finden Sie alle Portfolios, in denen das Unternehmen präsent ist',
    MissingSalesTransactionsTitle: 'Hier finden Sie alle Kampagnen, in denen das Unternehmen vertreten ist',
    Subtitle: 'Sie können diesen Abschnitt entsperren, indem Sie {{companyName}}-Daten erwerben',
    Button: 'Entsperren Sie {{companyName}}-Daten',
    Tooltip: 'Bitte Nachricht eingeben...'
  },
  ModalWindow: {
    Notes: 'Anmerkungen',
    Title: 'Sie können bis zu fünf Anmerkungen dieser Firma hinzufügen',
    InsertNote: '  Neue Anmerkung einfügen (max. 250 Zeichen)',
    NewNote: 'Neue Anmerkung hier erfassen',
    Add: 'Hinzufügen',
    AreYouSure: 'Möchten Sie diese Anmerkung wirklich löschen?',
    Yes: 'Ja',
    No: 'Nein'
  },
  CompanyProfile: 'Unternehmensprofil',
  CompanyProfileMobile: 'Unternehmen',
  FinancialStatementSummary: 'Zusammenfassung des Jahresabschlusses',
  FinancialStatementMobile: 'Finanziell',
  Contacts: {
    Title: 'Kontakte'
  },
  News: 'News',
  Close: 'Schließen',
  LocalUnits: {
    Title: 'Filialen',
    StartDate: 'Anfangsdatum'
  },
  Branches: {
    Title: 'Niederlassung',
    CbNumber: 'ONR Nummer',
    Address: 'Adresse',
    Telephone: 'Telefon'
  },
  Score: {
    Title: 'Ergebnis benutzerdefiniert',
    Tabs: {
      Index: 'Bewertungsrisiko',
      Relevant: 'Relevante Ereignisse',
      Telco: 'Analytik Telco',
      Analytics: 'Analytics Utilities'
    }
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'PNRR Score Investment',
      Mission: 'Mission',
      Component: 'Component',
      Scope: 'Scope',
      Investment: 'Investment'
    }
  },
  CompanyPopup: {
    Title: 'Systemnachricht',
    CompanyNotFound: 'Für das ausgewählte Unternehmen konnten keine Daten wiederhergestellt werden'
  },
  PersonPopup: {
    Title: 'Systemnachricht',
    CompanyNotFound: 'Für das ausgewählte Person konnten keine Daten wiederhergestellt werden'
  },
  PdfPopup: {
    Title: 'Vorgang bestätigen',
    TextEnriched:
      'Sobald die PDF-Datei erstellt ist, erhalten Sie eine Benachrichtigung und können diese herunterladen und speichern. Mit der Operation fortfahren?',
    TextNotEnriched:
      'Um das PDF herunterzuladen, muss zuerst das ausgewählte Unternehmen angereichert werden. Der Vorgang kann einige Minuten dauern. Bestätigen Sie, um fortzufahren.'
  },
  Appointments: {
    Title: 'Termine'
  }
}
