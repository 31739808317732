import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { DeleteOutlined } from '@ant-design/icons'
import { Text, Title } from '../Text'
import { Button } from '../Button'
import { theme as themeUtils, gridTheme } from '../../utils/theme'
import { toLocalizedDateFormat } from '../../utils/formatters'

type Props = {
  author?: string
  date?: string
  userId: string
  authorId: string
  noteId: string
  text: string
  deleteConfirmation?: boolean
  onClickRemove: (arg: any) => void
  onDelete: (noteId: string) => void
}

const CompanyNoteContainer = styled.div`
  margin-bottom: 30px;
`

const NoteWrapper = styled.div`
  display: flex;
  margin-bottom: 6px;

  > div:first-child {
    flex: 1;
    max-width: calc(100% - 60px);
  }
  .remove-icon {
    flex: 0 0 60px;
    display: flex;
    > * {
      margin: auto;
    }
  }
  .date {
    margin-left: 10px;
  }
  .note-info {
    display: flex;
    margin-bottom: 6px;
  }
`

const DeleteConfirmationDialogWrapper = styled.div`
  padding: 2em;
  border: 1px solid ${({ theme }) => theme.colors.veryLightPink};
  border-radius: 4px;
  display: flex;
  align-items: center;
  .confirmation-buttons {
    margin-left: auto;
    > *:first-child {
      margin-right: 10px;
    }
  }
  @media (max-width: ${gridTheme.breakpoints.xs}px) {
    padding: 1.6em;
    flex-direction: column;
    .confirmation-buttons {
      margin: 0 auto;
    }
  }
`

const DeleteConfirmationDialog = ({ onClickNo, onClickYes }: { onClickNo: () => void; onClickYes: () => void }) => {
  const { t } = useTranslation(['salestool', 'common'])
  return (
    <DeleteConfirmationDialogWrapper>
      <Text>{t('AssignCampaign.Dialog.DeleteNoteConfirmation')}</Text>
      <div className="confirmation-buttons">
        <Button onClick={onClickNo}>{t('common:No')}</Button>
        <Button type="primary" onClick={onClickYes}>
          {t('common:Yes')}
        </Button>
      </div>
    </DeleteConfirmationDialogWrapper>
  )
}

const CompanyNoteCmp = ({
  author,
  date,
  text,
  noteId,
  userId,
  authorId,
  onClickRemove,
  onDelete,
  deleteConfirmation = false
}: Props) => {
  const closeConfirmationDialog = useCallback(() => onClickRemove(''), [onClickRemove])
  const deleteNote = useCallback(() => onDelete(noteId), [noteId, onDelete])

  return (
    <CompanyNoteContainer>
      <NoteWrapper>
        <div>
          <div className="note-info">
            <Title level={4}>{author}</Title>
            {!!date && (
              <Text className="date" color="brownGray">
                {toLocalizedDateFormat(date)}
              </Text>
            )}
          </div>
          <Text>{text}</Text>
        </div>
        <div className="remove-icon" hidden={authorId !== userId}>
          <DeleteOutlined
            style={{ color: themeUtils.colors.main, fontSize: 20 }} // eslint-disable-line
            onClick={() => onClickRemove(noteId)}
          />
        </div>
      </NoteWrapper>
      {deleteConfirmation && <DeleteConfirmationDialog onClickNo={closeConfirmationDialog} onClickYes={deleteNote} />}
    </CompanyNoteContainer>
  )
}

export const CompanyNote = React.memo(CompanyNoteCmp)
