import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetOrderByOptions } from '../../api/common/queries'
import { useGetDomainNodesDataNotEmpty, useGetFiltersDefinition } from '../../api/filters'
import { useCommonModuleContext } from '../context'
import { ModuleName } from '../../api/common/types'
import { filterActions } from './actions'
import { TreeKeySelection } from '../../utils/tree'
import { formatSemanticSearchForRequest, mapOrderByOptions } from '../mappers'
import { getSelectedPortfolioIds, getActiveTargetingFilter } from '../targeting/selectors'
import { Filter, FilterReducerPath, FilterType, OrderByOptions, SemanticSearch } from './types'
import {
  SEMANTIC_CLUSTERS_FILTER,
  CUSTOM_FILTERS_GROUP,
  CAMPAIGN_IDS_FILTER,
  ENRICHED_BY_SUBSCRIPTIONS
} from './constants'
import {
  getFieldIdFromExpression,
  getHierarchyExpressionIndexBasedOnFieldId
} from '../../components/ContextFilters/utils'
import { convertKeySelectionToFieldExpressions, hasCampaignIdsFilter } from './utils'
import { CompoundExpression, Expression, HierarchyExpression } from '../operations/types'
import {
  addExpressionBasedOnFilterDefinition,
  getEmptyFieldExpression,
  updateChildExpression
} from '../operations/utils'
import { FilterDefinitionRequestType } from '../../api/filters/types'
import { isFieldExpression, isHierarchyExpression } from '../operations/guards'
import { useAppSelector } from '../../hooks/useAppSelector'
import { getUserId } from '../user/selectors'
import { getFilterSelectionForTree } from './selectors'
import { useGetCampaigns } from '../../api/salestool'
import { mapSemanticClusterResponseToTreeKeySelection } from './mappers'
import { RootState } from '../types'
import { TargetingFilterId } from '../targeting/types'
import { createTargetingQuery } from '../targeting/helpers'

export const useGetOrderBy = ({ moduleName }: { moduleName: ModuleName }) => {
  const { data: orderByOptions, isLoading } = useGetOrderByOptions<OrderByOptions>(moduleName, {
    select: response => mapOrderByOptions(response?.available)
  })
  const { orderByKeys } = useCommonModuleContext()
  const keys = orderByKeys
  const orderBy = _.filter(orderByOptions, option => _.includes(keys, option.key))
  return { orderBy, orderByOptions, areOptionsLoading: isLoading }
}

export const useSetSemanticClustersAsActive = (
  reducer: FilterReducerPath,
  filterGroupKeys: TreeKeySelection[] | undefined,
  userId: string,
  entityId: string | undefined
) => {
  const dispatch = useDispatch()

  const setSemanticClustersAsActive = useCallback(() => {
    const newFilters = filterGroupKeys
      ? _.uniqBy([{ key: SEMANTIC_CLUSTERS_FILTER, parentPath: [] }, ...filterGroupKeys], 'key')
      : [{ key: SEMANTIC_CLUSTERS_FILTER, parentPath: [] }]
    dispatch(
      filterActions(reducer).setSelectedFilterGroup({
        filters: newFilters,
        groupId: CUSTOM_FILTERS_GROUP,
        userId,
        entityId
      })
    )
  }, [dispatch, entityId, filterGroupKeys, reducer, userId])

  return { setSemanticClustersAsActive }
}

type SemanticClusterPrefilledRequest = {
  reducer: FilterReducerPath
  portfolioId: string | undefined
  includeEmpty: boolean
  expression: CompoundExpression
  semanticSearch: SemanticSearch | undefined
  excludedPortfoliosIds: string[] | undefined
}

export const useSemanticClusterPrefilled = ({
  reducer,
  portfolioId,
  includeEmpty,
  expression,
  semanticSearch,
  excludedPortfoliosIds
}: SemanticClusterPrefilledRequest) => {
  const { setExpression } = useCommonModuleContext()

  const selectedPortfolioForRequest = useSelector(getSelectedPortfolioIds)
  const selectedStatus = useSelector((state: RootState) =>
    getActiveTargetingFilter(state, TargetingFilterId.companyPortfolioStatus)
  )
  const portfolioIds = selectedPortfolioForRequest.concat(portfolioId || [])

  const { data: filterDefinition, isLoading: isFilterDataDefinitionLoading } = useGetFiltersDefinition({
    fieldId: SEMANTIC_CLUSTERS_FILTER,
    fieldDefinitionType: FilterDefinitionRequestType.Hierarchy,
    portfolioIds,
    campaignId: undefined,
    groupId: CUSTOM_FILTERS_GROUP
  })

  const setActiveSemanticClustersFilter = useCallback(
    (newValues: TreeKeySelection[]) => {
      setExpression(e => {
        let newExpression = e
        let childExpressionIndex = getHierarchyExpressionIndexBasedOnFieldId(newExpression, SEMANTIC_CLUSTERS_FILTER)

        const newChildExpressions = convertKeySelectionToFieldExpressions(
          newValues,
          filterDefinition?.parameters.selectors,
          false
        )

        if (filterDefinition && childExpressionIndex === -1) {
          newExpression = addExpressionBasedOnFilterDefinition(newExpression, filterDefinition)
          childExpressionIndex = newExpression.childExpressions.length - 1
        }

        const updatedChildExpression = newExpression.childExpressions[childExpressionIndex] as HierarchyExpression
        updatedChildExpression.childExpressions = newChildExpressions

        return updateChildExpression(newExpression, updatedChildExpression, childExpressionIndex)
      })
    },
    [filterDefinition, setExpression]
  )

  const {
    data: semanticClusterData,
    refetch,
    isLoading,
    isFetching
  } = useGetDomainNodesDataNotEmpty(
    {
      query: expression,
      filterHierarchy: SEMANTIC_CLUSTERS_FILTER,
      useSingleSelection: false,
      includeEmpty,
      semantic: formatSemanticSearchForRequest(semanticSearch),
      ignoredClusterCodes: ['FAM_', 'CAT_'],
      ignoredPortfolios: excludedPortfoliosIds ?? [],
      selectedPortfolios: reducer === 'targeting' ? selectedPortfolioForRequest : undefined,
      portfolioId,
      targetingQuery: reducer === 'targeting' ? createTargetingQuery(selectedStatus) : undefined
    },
    {
      enabled: false,
      onSuccess: data => setActiveSemanticClustersFilter(mapSemanticClusterResponseToTreeKeySelection(data.perLevel))
    }
  )

  const [isDataStale, setDataStale] = useState<boolean>(false)
  useEffect(() => {
    if (!isFilterDataDefinitionLoading && isDataStale) {
      refetch()
      setDataStale(false)
    }
  }, [isFilterDataDefinitionLoading, isDataStale, refetch, setActiveSemanticClustersFilter])

  const prefillSemanticCluster = useCallback(() => setDataStale(true), [setDataStale])

  const prefillCachedSemanticClusterData = () => {
    if (semanticClusterData)
      setActiveSemanticClustersFilter(mapSemanticClusterResponseToTreeKeySelection(semanticClusterData.perLevel))
    else prefillSemanticCluster()
  }

  return {
    prefillSemanticCluster,
    prefillCachedSemanticClusterData,
    isLoadingSemanticClusters: isLoading,
    isFetchingSemanticClusters: isFetching
  }
}

/**
 * Used to modify displaying of special filter fields, where the definition returned from the server doesn't coresponds with the requirements for the display.
 * Mind, that these customizations are meant only for displaying - there is no handling for update/create
 */
export const useFilterFieldDisplayCustomization = () => {
  const { t } = useTranslation('common')
  return useCallback(
    ({
      filterDefinition,
      expression
    }: {
      filterDefinition: Filter | undefined
      expression: Expression | undefined
    }): { filterDefinition: Filter | undefined; expression: Expression | undefined } => {
      if (!filterDefinition || !expression) {
        return { filterDefinition, expression }
      }

      if (filterDefinition.fieldId === CAMPAIGN_IDS_FILTER) {
        return {
          filterDefinition: { ...filterDefinition, name: t('common:ExcludePortfolio.ExcludeCampaign') },
          expression
        }
      }
      if (filterDefinition.fieldId === ENRICHED_BY_SUBSCRIPTIONS && isFieldExpression(expression)) {
        return {
          filterDefinition: {
            ...filterDefinition,
            name: t('common:ExcludePortfolio.ExcludeEnrichedCompanies'),
            type: FilterType.BooleanToggle
          },
          expression: getEmptyFieldExpression(filterDefinition.fieldId, !!(expression.value as string[]).length)
        }
      }
      return { filterDefinition, expression }
    },
    [t]
  )
}

export const useFilterSelection = (reducerPath: FilterReducerPath, groupId: string, entityId?: string) => {
  const actions = filterActions(reducerPath)
  const dispatch = useDispatch()
  const userId = useAppSelector(getUserId)
  const values = useAppSelector(state => getFilterSelectionForTree(state, { reducer: reducerPath, userId, entityId }))
  const { expression, setExpression } = useCommonModuleContext()

  const setFilterSelectionFnRef = useRef(_.noop)

  setFilterSelectionFnRef.current = (selectedFilters: TreeKeySelection[]) => {
    dispatch(actions.setSelectedFilterGroup({ filters: selectedFilters, groupId, userId, entityId }))

    // Remove from expression if some filter was deselected
    const removedFilters = _.filter(values, value => {
      return !_.some(selectedFilters, { key: value.key })
    })
    if (removedFilters.length > 0) {
      const removedFiltersIds = _.map(removedFilters, removedFilter => removedFilter.key)
      setExpression({
        ...expression,
        childExpressions: _.reject(expression.childExpressions, childExpression =>
          _.includes(removedFiltersIds, getFieldIdFromExpression(childExpression))
        )
      })
    }
  }

  const setFilterSelection = useCallback(
    (selectedFilters: TreeKeySelection[]) => setFilterSelectionFnRef.current(selectedFilters),
    []
  )

  return {
    setFilterSelection,
    values
  }
}

export const useAreExcludedCampaignsExisting = (childExpressions: Expression[] | undefined) => {
  const hasExcludedCampaigns = hasCampaignIdsFilter(childExpressions)
  const { data: campaigns, isLoading } = useGetCampaigns({}, childExpressions !== undefined && hasExcludedCampaigns)

  if (!hasExcludedCampaigns || childExpressions === undefined)
    return { useAreExcludedCampaignsExisting: false, isLoading: false }

  const excludedCampaignsExpression = _.find(
    childExpressions,
    childExpression =>
      isHierarchyExpression(childExpression) && getFieldIdFromExpression(childExpression) === CAMPAIGN_IDS_FILTER
  )

  const excludedCampaignsIds = isHierarchyExpression(excludedCampaignsExpression)
    ? (excludedCampaignsExpression?.childExpressions?.[0].value as string[])
    : []

  const existingExcludedCampaigns = _.filter(campaigns?.campaigns, ({ id }) =>
    _.some(excludedCampaignsIds, excludedCampaign => excludedCampaign === id)
  )

  return { areExcludedCampaignsExisting: !_.isEmpty(existingExcludedCampaigns), isLoading }
}
